import { ActionIcon, Button, Input, Space, Text } from "@mantine/core";
import { ErrorBoundary } from "common_ui/ErrorBoundary";
import { Feature, View } from "ol";
import { Point as OlPoint } from "ol/geom";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import Map from "ol/Map";
import { fromLonLat, transform } from "ol/proj";
import { OSM } from "ol/source";
import VectorSource from "ol/source/Vector";
import PlacesAutocompleteText from "pages/main/PlaceAutocompleteTextInput";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { FiTrash } from "react-icons/fi";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import {
  COMMON_LAT_LANG_COORDINATE_PROJECTION,
  OPEN_LAYERS_DEFAULT_MAP_PROJECTION,
} from "shared/OpenLayersConstants";
import type { Coordinates, GeographicPlace } from "types/geo";
import type { Nullable } from "types/utils";
import { arrayRemoveFirstStrict, arrayReplaceFirstStrict, coalesceEmptyArray } from "utils/arrays";
import { getUserCoordinates } from "utils/geo";
import { assertDefined, noop } from "utils/misc";
import { v4 as uuid } from "uuid";
import "./HeatmapPointChooser.css";
import { makePage } from "shared/makePage";
import { CloudFunctions } from "apis/internal/cloudFunctions";
import { HeatmapTransportationMode, type HeatmapRequest } from "types/internal_apis/get_heatmap";
import { useNavigate } from "react-router-dom";

const MAP_ELEMENT_ID = "point-map";
const MAP_PADDING = 16;

interface PartialPlace {
  id: string;
  name?: string;
  points?: Coordinates[];
}

interface PartialGroup {
  id: string;
  max_bucket_in_s?: number;
  places: PartialPlace[];
}

const HeatmapPointChooserImpl = () => {
  const mapRef = useRef<Map>();
  const pointSourceRef = useRef<VectorSource>();
  const [groups, setGroups] = useState<PartialGroup[]>([]);
  const userCoordinates = useRef<Nullable<Coordinates>>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const pointSource = new VectorSource({});
    pointSourceRef.current = pointSource;

    mapRef.current = new Map({
      target: MAP_ELEMENT_ID,
      layers: [
        new TileLayer({
          source: new OSM({}),
        }),
        new VectorLayer({
          //Points
          source: pointSource,
          style: {
            "circle-stroke-color": "black",
            "circle-radius": 7,
            "circle-fill-color": "#ffcc33",
          },
        }),
      ],
      view: new View({
        projection: OPEN_LAYERS_DEFAULT_MAP_PROJECTION,
        center: transform(
          [0, 0],
          COMMON_LAT_LANG_COORDINATE_PROJECTION,
          OPEN_LAYERS_DEFAULT_MAP_PROJECTION
        ),
        zoom: 12,
      }),
    });
  }, []);

  const addPlaceGroup = useCallback(() => {
    setGroups([{ id: uuid(), places: [] }, ...groups]);
  }, [groups]);

  const onEditPlaceGroup = useCallback(
    (editedGroup: PartialGroup) => {
      setGroups(arrayReplaceFirstStrict(groups, (x) => x.id === editedGroup.id, editedGroup));
    },
    [groups]
  );

  const onDeletePlaceGroup = useCallback(
    (group: PartialGroup) => {
      setGroups(arrayRemoveFirstStrict(groups, (x) => x.id === group.id));
    },
    [groups]
  );

  const getHeatmap = useCallback(async () => {
    const args = { groups: [], places: [] } as HeatmapRequest;
    for (const group of groups) {
      args.groups.push({
        id: group.id,
        max_bucket_in_s: assertDefined(group.max_bucket_in_s),
        transportation_mode: HeatmapTransportationMode.PUBLIC_TRANSPORTATION,
      });

      for (const place of group.places) {
        args.places.push({
          name: assertDefined(place.name),
          points: assertDefined(place.points?.map((coord) => [coord.longitude, coord.latitude])),
          group_id: group.id,
          id: place.id,
        });
      }
    }
    const heatmap = await CloudFunctions.ApiGetHeatmap(args);
    navigate("/heatmap/view", { state: { heatmap: heatmap } });
  }, [groups, navigate]);

  useEffect(() => {
    const onSuccess = (point: Coordinates) => {
      userCoordinates.current = point;
      if (pointSourceRef.current?.isEmpty()) {
        mapRef.current?.getView().setCenter(fromLonLat([point.longitude, point.latitude]));
      }
    };
    getUserCoordinates(onSuccess, noop);
  }, []);

  useEffect(() => {
    if (!pointSourceRef?.current) return;
    const addedPoints = [];
    pointSourceRef.current.clear();
    for (const group of groups) {
      for (const place of group.places) {
        for (const point of coalesceEmptyArray(place.points)) {
          addedPoints.push(point);
          const feature = new Feature({
            geometry: new OlPoint(fromLonLat([point.longitude, point.latitude])),
            name: place.name,
          });
          pointSourceRef.current?.addFeature(feature);
        }
      }
      if (!pointSourceRef.current.isEmpty()) {
        mapRef.current?.getView().fit(pointSourceRef.current.getExtent(), {
          padding: [MAP_PADDING, MAP_PADDING, MAP_PADDING, MAP_PADDING],
        });
      } else if (userCoordinates.current !== null) {
        const point = userCoordinates.current;
        mapRef.current?.getView().setCenter(fromLonLat([point.longitude, point.latitude]));
      }
    }
  }, [groups]);

  return (
    <ErrorBoundary>
      <PanelGroup autoSaveId="devIsochroneTesterPanelGroup" direction="horizontal">
        <Panel>
          <div className="col heatmap-creation-sidepanel-parent">
            <Button onClick={addPlaceGroup}>Add Criterion</Button>
            {groups.map((g) => (
              <GroupListElement
                group={g}
                key={g.id}
                onGroupEdit={onEditPlaceGroup}
                onDeleteDelete={onDeletePlaceGroup}
              />
            ))}
            <Button onClick={getHeatmap}>Get Heatmap</Button>
          </div>
        </Panel>
        <PanelResizeHandle />
        <Panel maxSize={80} minSize={20}>
          <div className="w-100 h-full" id={MAP_ELEMENT_ID} />
        </Panel>
      </PanelGroup>
    </ErrorBoundary>
  );
};

interface GroupListElementProps {
  group: PartialGroup;
  onDeleteDelete: (group: PartialGroup) => void;
  onGroupEdit: (group: PartialGroup) => void;
}

export const GroupListElement = memo((props: GroupListElementProps) => {
  const { group, onGroupEdit: propsOnGroupEdit, onDeleteDelete: propsOnGroupDelete } = props;

  const onGroupEdit = useCallback(
    (newGroup: PartialGroup) => {
      propsOnGroupEdit(newGroup);
    },
    [propsOnGroupEdit]
  );

  const onDeleteGroup = useCallback(() => {
    propsOnGroupDelete(group);
  }, [group, propsOnGroupDelete]);

  const onChangeDuration = useCallback(
    (duration: number) => {
      onGroupEdit({ ...group, max_bucket_in_s: duration * 60 });
    },
    [group, onGroupEdit]
  );

  const onAddLocation = useCallback(() => {
    onGroupEdit({
      ...group,
      places: [{ id: uuid() }, ...group.places],
    });
  }, [group, onGroupEdit]);

  const onChangeLocation = useCallback(
    (place: PartialPlace) => {
      onGroupEdit({
        ...group,
        places: arrayReplaceFirstStrict(group.places, (x) => x.id === place.id, place),
      });
    },
    [group, onGroupEdit]
  );

  const onDeleteLocation = useCallback(
    (placeId: string) => {
      onGroupEdit({
        ...group,
        places: arrayRemoveFirstStrict(group.places, (x) => x.id === placeId),
      });
    },
    [group, onGroupEdit]
  );

  return (
    <div className="col">
      <div className="row w100">
        <Text>How many minutes away?</Text>
        <Input
          placeholder="How many minutes"
          type="number"
          onChange={(e) => onChangeDuration(e.target.valueAsNumber)}
          value={(group.max_bucket_in_s ?? 0) / 60}
        />
      </div>
      <Space w="sm" />
      <Text>Where?</Text>
      <Button onClick={onAddLocation}>Add Place</Button>
      <Space w="sm" />
      <div className="col">
        {group.places.map((place) => (
          <GroupPlaceListElement
            key={place.id}
            placeId={place.id}
            onPlaceDelete={onDeleteLocation}
            onPlaceEdit={onChangeLocation}
          />
        ))}
      </div>
      <span className="button-panel">
        <ActionIcon onClick={onDeleteGroup} color="red" variant="light">
          <FiTrash />
        </ActionIcon>
      </span>
    </div>
  );
});

interface GroupPlaceListElementProps {
  placeId: string;
  onPlaceEdit: (place: PartialPlace) => void;
  onPlaceDelete: (placeId: string) => void;
}

const GroupPlaceListElement = memo((props: GroupPlaceListElementProps) => {
  const { placeId, onPlaceEdit: propsOnPlaceEdit, onPlaceDelete: propsOnPlaceDelete } = props;

  const onPlaceEdit = useCallback(
    (place: GeographicPlace) => {
      propsOnPlaceEdit({ id: placeId, name: place.name, points: [place.coords] });
    },
    [placeId, propsOnPlaceEdit]
  );

  const onPlaceDelete = useCallback(() => {
    propsOnPlaceDelete(placeId);
  }, [placeId, propsOnPlaceDelete]);

  return (
    <div className="row w100">
      <PlacesAutocompleteText onLocationChosen={onPlaceEdit} />
      <span className="button-panel">
        <ActionIcon onClick={onPlaceDelete} color="red" variant="light">
          <FiTrash />
        </ActionIcon>
      </span>
    </div>
  );
});

export const HeatmapPointChooser = makePage(HeatmapPointChooserImpl);
