import { ActionIcon, Button, Divider, Space, Title } from "@mantine/core";
import { memo, useCallback, useEffect, useState } from "react";
import { FiInfo, FiSettings } from "react-icons/fi";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import PlacesAutocompleteText from "./PlaceAutocompleteTextInput";
import { QueryListElement } from "./QueryListItem";
import { SupportEmailRedactor } from "./SupportEmailRedactor";
import { TransportSettingsModal } from "./TransportSettingsModal";
import { AddressSpecificQuery, OpenQuery, type Query } from "./locationQueryTypes";

import { ColorModeSwitcher } from "../../common_ui/ColorModeSwitcher";
import { ErrorBoundary } from "../../common_ui/ErrorBoundary";
import { EmbeddedMap } from "../../geomasters/maps/EmbeddedMap";
import { useSmallScreen } from "../../shared/ScreenSizeContext";
import {
  getPreferredModeOfTransport,
  getSavedQueries,
  setSavedQueries,
} from "../../storage/localStorage";
import type { GeographicPlace } from "../../types/geo";
import type { Nullable, Undefined } from "../../types/utils";
import { useBooleanState } from "../../utils/hooks";
import "./Main.css";

const MainImpl = () => {
  const [referencePlace, setReferencePlace] = useState<Nullable<GeographicPlace>>(null);
  const [queries, setQueries] = useState<Array<Query>>(getSavedQueries());
  const [isLoading, setIsLoading, setIsNotLoading] = useBooleanState(false);
  const [isSettingsModalOpen, openSettingModal, closeSettingModal] = useBooleanState(false);
  const [queryForSidepanel, setQueryForSidepanel] = useState<Undefined<Query>>();
  const smallScreen = useSmallScreen();

  useEffect(() => {
    setSavedQueries(queries);
  }, [queries]);

  const addSpecificAddressQuery = useCallback(() => {
    setQueries([new AddressSpecificQuery(), ...queries]);
  }, [queries]);

  const addOpenQuery = useCallback(() => {
    setQueries([new OpenQuery(), ...queries]);
  }, [queries]);

  const requestDirections = useCallback(async () => {
    if (!referencePlace) return;
    setIsLoading();
    const promises: Promise<void>[] = [];
    for (const query of queries) {
      promises.push(query.getDirections(referencePlace));
    }
    await Promise.allSettled(promises);
    setQueries([...queries]); //For rerender
    setIsNotLoading();
  }, [queries, referencePlace, setIsLoading, setIsNotLoading]);

  const onQueryChanged = useCallback(
    (key: string) => {
      const query = queries.find((x) => x.key === key);
      if (query) query.reset();
      setQueries([...queries]); //For rerender
    },
    [queries]
  );

  const onQueryRemoved = useCallback(
    (key: string) => {
      setQueries(queries.filter((x) => x.key !== key));
      if (queryForSidepanel?.key === key) setQueryForSidepanel(undefined);
    },
    [queries, queryForSidepanel?.key]
  );

  const onQueryChosenForSidepanel = useCallback(
    (key: string) => {
      const query = queries.find((x) => x.key === key);
      setQueryForSidepanel(query);
    },
    [queries]
  );

  return (
    <ErrorBoundary>
      <TransportSettingsModal isOpen={isSettingsModalOpen} onClose={closeSettingModal} />
      <div className="main-page-container">
        <PanelGroup autoSaveId="mainPagePanelGroup" direction="horizontal">
          <Panel>
            <div className="controls-sidepanel-parent">
              <div className="w-100 items-center flex gap-1 px-2 bg-cyan-800 text-neutral-300">
                <FiInfo />{" "}
                <span>
                  This project is is still in active development! Please share your feedback to{" "}
                  <SupportEmailRedactor />
                </span>
              </div>
              <div className="controls-sidepanel-inner">
                <ColorModeSwitcher className="color-switcher-button" />
                <Title order={3}>Address you're curious about</Title>
                <Space h="sm" />
                <div className="row w100">
                  <ErrorBoundary>
                    <PlacesAutocompleteText onLocationChosen={setReferencePlace} />
                  </ErrorBoundary>
                </div>
                <Space h="sm" />
                <div className="button-panel">
                  <Button onClick={requestDirections} disabled={isLoading || !referencePlace}>
                    What's it looking like?
                  </Button>
                  <ActionIcon onClick={openSettingModal} color="primary" variant="light" size="lg">
                    <FiSettings />
                  </ActionIcon>
                </div>

                <div className="spacer-major" />

                <Title order={3}>Places you care about</Title>
                <Space h="sm" />
                <div className="button-panel">
                  <Button onClick={addSpecificAddressQuery}>Add Specific Address</Button>
                  <Button onClick={addOpenQuery}>Add Open Search</Button>
                </div>
                <Space h="sm" />

                <div className="query-list">
                  {queries.map((option) => (
                    <ErrorBoundary key={option.key}>
                      <div className="col">
                        <QueryListElement
                          query={option}
                          onQueryChanged={onQueryChanged}
                          onQueryRemoved={onQueryRemoved}
                          onQueryChosenForSidepanel={onQueryChosenForSidepanel}
                        />
                        <Divider className="mt-4"></Divider>
                      </div>
                    </ErrorBoundary>
                  ))}
                </div>
              </div>
            </div>
          </Panel>
          {!smallScreen && (
            <>
              <PanelResizeHandle className="sidepanel-handle" />
              <Panel defaultSize={25} maxSize={40} minSize={10} className="map-sidepanel">
                <EmbeddedMap
                  height={"100%"}
                  width={"100%"}
                  origin={queryForSidepanel?.route?.origin ?? referencePlace}
                  destination={queryForSidepanel?.route?.destination}
                  modeOfTransport={getPreferredModeOfTransport()}
                />
              </Panel>
            </>
          )}
        </PanelGroup>
      </div>
    </ErrorBoundary>
  );
};

export const Main = memo(MainImpl);
