import { Text } from "@mantine/core";
import { useBooleanState } from "../../utils/hooks";
import { getEnvVariable } from "utils/env";

export const SupportEmailRedactor = () => {
  const [isShowing, setShowingTrue] = useBooleanState(false);

  return (
    <span onClick={setShowingTrue}>
      {isShowing ? (
        <Text span>{getEnvVariable("REACT_APP_SUPPORT_EMAIL")}</Text>
      ) : (
        <Text span className="bg-black text text-neutral-200">
          [click to see email]
        </Text>
      )}
    </span>
  );
};
