import { getApiKey } from "apis/external/GooglePlacesApi";
import classNames from "classnames";
import { IframeContainer } from "common_ui/IframeWithSkeleton";
import { useState, useEffect, memo } from "react";
import { TransportationMode, type Coordinates } from "types/geo";
import { getUserCoordinates } from "utils/geo";
import { noop } from "utils/misc";
import { getMapRenderMode, MAPS_RENDER_MODE } from "./EmbeddedMap";
import type { EmbeddedMapProps } from "./types";

const TRANSPORT_MODE_MAP = {
  [TransportationMode.BIKING]: "bicycling",
  [TransportationMode.DRIVING]: "driving",
  [TransportationMode.PUBLIC_TRANSPORTATION]: "transit",
  [TransportationMode.WALKING]: "walking",
};

// https://developers.google.com/maps/documentation/embed/embedding-map
const AlfredMapIframeImpl = (props: EmbeddedMapProps) => {
  const renderMode = getMapRenderMode(props);
  const [userPosition, setUserPosition] = useState<Coordinates>({ latitude: 0, longitude: 0 });

  useEffect(() => {
    if (renderMode === MAPS_RENDER_MODE.USER_COORDS) {
      getUserCoordinates(setUserPosition, noop);
    }
  }, [renderMode]);

  let url = ``;
  if (renderMode === MAPS_RENDER_MODE.DIRECTIONS) {
    url = `https://www.google.com/maps/embed/v1/directions?key=${encodeURIComponent(getApiKey())}`;
    url += `&origin=${props.origin?.coords.latitude},${props.origin?.coords.longitude}`;
    if (props.destination?.geoMasterProvidedId)
      url += `&destination=place_id:${encodeURIComponent(props.destination.geoMasterProvidedId)}`;
    else
      url += `&destination=${props.destination?.coords.latitude},${props.destination?.coords.longitude}`;
    if (props.modeOfTransport) url += `&mode=${TRANSPORT_MODE_MAP[props.modeOfTransport]}`;
  } else {
    let location = null;
    if (renderMode === MAPS_RENDER_MODE.USER_COORDS) location = userPosition;
    else if (renderMode === MAPS_RENDER_MODE.ORIGIN) location = props.origin?.coords;
    else location = props.destination?.coords;
    if (location) {
      url = `https://www.google.com/maps/embed/v1/place?key=${encodeURIComponent(getApiKey())}`;
      url += `&q=${location.latitude},${location.longitude}&zoom=12`;
    }
  }

  return (
    <IframeContainer
      title={`Embedded map to a place`}
      width={`${props.width}`}
      height={`${props.height}`}
      allowFullScreen
      referrerPolicy="no-referrer-when-downgrade"
      src={url}
      className={classNames({ rounded: props.rounded, "border-none": true })}
    />
  );
};

export const AlfredMapIframe = memo(AlfredMapIframeImpl);
