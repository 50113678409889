import { getApp, initializeApp } from "firebase/app";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import type { Heatmap, HeatmapRequest } from "types/internal_apis/get_heatmap";
import { getEnvVariable } from "utils/env";
import { isInDevMode } from "utils/misc";

initializeApp(JSON.parse(getEnvVariable("REACT_APP_PROD_FIREBASE_CONFIG")));
const functions = getFunctions(getApp());
if (isInDevMode()) {
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}

const ApiGetHeatmap = async (args: HeatmapRequest) => {
  const heatmapFunction = httpsCallable(functions, "get_heatmap");
  const result = await heatmapFunction(args);
  return result.data as Heatmap;
};

export const CloudFunctions = { ApiGetHeatmap };
