import { Slider } from "@mantine/core";
import { interpolateRgbBasis } from "d3-interpolate";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import type { FeatureLike } from "ol/Feature";
import Fill from "ol/style/Fill";
import Style from "ol/style/Style";

import Color from "color";
import type {
  GroupID,
  Heatmap,
  HeatmapPartProperties,
  PartID,
} from "types/internal_apis/get_heatmap";
import { assertDefined, recordEntries } from "utils/misc";
import { MAP_ELEMENT_ID, useHeatmapMap } from "./HeatmapConfigurationMap";

interface HeatmapConfigurationProps {
  heatmap: Heatmap;
  relative_importances: Record<GroupID, number>;
}

const HeatmapConfigurationInnerImpl = (props: HeatmapConfigurationProps) => {
  const heatmapRef = useRef<Heatmap>(props.heatmap);
  const [_relativeImportances, setRelativeImportances] = useState<Record<GroupID, number>>(
    props.relative_importances
  );
  const getGroupImportance = useCallback(
    (groupId: GroupID) => {
      return _relativeImportances[groupId] ?? 0;
    },
    [_relativeImportances]
  );

  const partScoresRef = useRef<Record<PartID, number>>({});
  const minPartScoreRef = useRef<number>(0);
  const maxPartScoreRef = useRef<number>(0);

  // This refreshes score refs for us when relativeImportances change
  useEffect(() => {
    const scores: Record<PartID, number> = {};
    const heatmap = heatmapRef.current;

    for (const part of heatmap.feature_collection.features) {
      const partId = part.properties.id;
      const bucketsPerPlace = part.properties.buckets_per_place_group;
      recordEntries(bucketsPerPlace).forEach((entry) => {
        const groupId = entry[0];
        const bucketInSeconds = entry[1].bucket_in_s;
        const maxBucketInSeconds = assertDefined(heatmap.groups[groupId]).max_bucket_in_s;
        const groupImportance = getGroupImportance(groupId);
        if (scores[partId] === undefined) scores[partId] = 0;
        scores[partId] += (1 - bucketInSeconds / maxBucketInSeconds) * groupImportance;
      });
    }

    partScoresRef.current = scores;
    minPartScoreRef.current = Math.min(...Object.values(scores));
    maxPartScoreRef.current = Math.max(...Object.values(scores));
  }, [getGroupImportance]);

  const styleFunction = useCallback((feature: FeatureLike) => {
    const properties = feature.getProperties() as HeatmapPartProperties;
    const rawScore = assertDefined(partScoresRef.current[properties.id]);
    let normalizedScore = 0;
    if (maxPartScoreRef.current > 0) {
      normalizedScore = (rawScore - minPartScoreRef.current) / maxPartScoreRef.current;
    }

    const colorScale = interpolateRgbBasis(["red", "yellow", "green"]);
    const color = colorScale(normalizedScore);
    return [
      new Style({
        fill: new Fill({
          color: Color(color).alpha(0.5).toString(),
        }),
      }),
    ];
  }, []);

  const { mapRef } = useHeatmapMap(styleFunction, props.heatmap);

  const updateImportance = useCallback(
    (groupId: GroupID, importance: number) => {
      setRelativeImportances((prev) => ({ ...prev, [groupId]: importance }));
      if (!mapRef.current) return;
      const vectorLayer = assertDefined(mapRef.current.getAllLayers().at(1));
      vectorLayer.changed(); // We need to rerender since the styling will need to change
    },
    [mapRef]
  );

  return (
    <PanelGroup autoSaveId="devIsochroneTesterPanelGroup" direction="horizontal">
      <Panel>
        {Object.values(heatmapRef.current.groups).map((group, index) => (
          <Slider
            key={index}
            min={1}
            max={20}
            value={getGroupImportance(group.id)}
            onChange={(value) => {
              updateImportance(group.id, value);
            }}
          />
        ))}
      </Panel>

      <PanelResizeHandle />

      <Panel maxSize={80} minSize={20}>
        <div className="w-100 h-full" id={MAP_ELEMENT_ID} />
      </Panel>
    </PanelGroup>
  );
};

export const HeatmapConfigurationInner = memo(HeatmapConfigurationInnerImpl);
