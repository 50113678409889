import type { FeatureCollection, Feature as FeatureType } from "geojson";
import type { FeatureLike } from "ol/Feature";
import Feature from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import { pointerMove } from "ol/events/condition";
import GeoJSON from "ol/format/GeoJSON";
import { Point } from "ol/geom";
import { Select } from "ol/interaction";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat, transform } from "ol/proj";
import OSM from "ol/source/OSM.js";
import VectorSource from "ol/source/Vector";
import type Style from "ol/style/Style";
import { useCallback, useEffect, useRef } from "react";
import {
  COMMON_LAT_LANG_COORDINATE_PROJECTION,
  OPEN_LAYERS_DEFAULT_MAP_PROJECTION,
} from "shared/OpenLayersConstants";
import type { Heatmap, HeatmapPlace } from "types/internal_apis/get_heatmap";
import type { Undefined } from "types/utils";

export const MAP_ELEMENT_ID = "heatmap-configuration-map";
const DEFAULT_CENTER = [-73.9964, 40.7057];
const DEFAULT_ZOOM = 12;

export const useHeatmapMap = (
  styleFunction: (feature: FeatureLike) => Style[],
  heatmap: Heatmap
) => {
  const mapRef = useRef<Map>();
  const polygonSourceRef = useRef<VectorSource>();
  const pointSourceRef = useRef<VectorSource>();

  useEffect(() => {
    const tileLayer = new TileLayer({
      //Tiles
      source: new OSM({
        attributions: "",
      }),
    });
    //https://stackoverflow.com/questions/59819792/change-openlayers-map-color-dark-and-light-style
    tileLayer.on("prerender", (evt) => {
      if (evt.context) {
        const context = evt.context as CanvasRenderingContext2D;
        context.filter = "grayscale(65%)";
        context.globalCompositeOperation = "source-over";
      }
    });
    tileLayer.on("postrender", (evt) => {
      if (evt.context) {
        const context = evt.context as CanvasRenderingContext2D;
        context.filter = "none";
      }
    });

    const polygonSource = new VectorSource({});
    const pointSource = new VectorSource({});
    polygonSourceRef.current = polygonSource;
    const polygonLayer = new VectorLayer({
      //Polygons
      source: polygonSource,
      style: styleFunction,
    });

    pointSourceRef.current = pointSource;
    const pointLayer = new VectorLayer({
      //Points
      source: pointSource,
      style: {
        "circle-stroke-color": "black",
        "circle-radius": 7,
        "circle-fill-color": "#ffcc33",
      },
    });

    const map = new Map({
      target: MAP_ELEMENT_ID,
      layers: [tileLayer, polygonLayer, pointLayer],
      view: new View({
        projection: OPEN_LAYERS_DEFAULT_MAP_PROJECTION,
        center: transform(
          DEFAULT_CENTER,
          COMMON_LAT_LANG_COORDINATE_PROJECTION,
          OPEN_LAYERS_DEFAULT_MAP_PROJECTION
        ),
        zoom: DEFAULT_ZOOM,
      }),
    });
    mapRef.current = map;

    const hoverInteraction = new Select({
      layers: [polygonLayer],
      condition: pointerMove,
      filter: () => true,
    });

    map.addInteraction(hoverInteraction);
    return () => {
      map.removeInteraction(hoverInteraction);
    };
  }, [styleFunction]);

  const addPolygons = useCallback(
    (features: FeatureType[] | FeatureCollection, projection?: Undefined<string>) => {
      let featuresArray: FeatureType[] = [];
      if (Array.isArray(features)) {
        featuresArray = [...features];
      } else {
        featuresArray = features.features;
      }

      featuresArray.forEach((feature) => {
        polygonSourceRef.current?.addFeature(
          new GeoJSON().readFeature(feature, {
            dataProjection: projection,
            featureProjection: OPEN_LAYERS_DEFAULT_MAP_PROJECTION,
          })
        );
      });
    },
    [polygonSourceRef]
  );

  const addPoints = useCallback(
    (places: HeatmapPlace[]) => {
      for (const place of places) {
        for (const point of place.points) {
          pointSourceRef.current?.addFeature(
            new Feature({
              geometry: new Point(fromLonLat(point)),
            })
          );
        }
      }
    },
    [pointSourceRef]
  );

  const clearMap = useCallback(() => {
    polygonSourceRef.current?.clear();
  }, [polygonSourceRef]);

  // This is for loading in the heatmap
  useEffect(() => {
    clearMap();
    addPolygons(heatmap.feature_collection);
    addPoints(Object.values(heatmap.places));
  }, [addPoints, addPolygons, clearMap, heatmap]);

  return {
    mapRef,
  };
};
