export const defaultGeojsonObject = JSON.stringify({
    'type': 'FeatureCollection',
    'crs': {
        'type': 'name',
        'properties': {
            'name': 'EPSG:3857',
        },
    },
    'features': [
        {
            'type': 'Feature',
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [-5e6, 6e6],
                        [-5e6, 8e6],
                        [-3e6, 8e6],
                        [-3e6, 6e6],
                        [-5e6, 6e6],
                    ],
                ],
            },
        },
        {
            'type': 'Feature',
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [-2e6, 6e6],
                        [-2e6, 8e6],
                        [0, 8e6],
                        [0, 6e6],
                        [-2e6, 6e6],
                    ],
                ],
            },
        },
        {
            'type': 'Feature',
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [1e6, 6e6],
                        [1e6, 8e6],
                        [3e6, 8e6],
                        [3e6, 6e6],
                        [1e6, 6e6],
                    ],
                ],
            },
        },
        {
            'type': 'Feature',
            'geometry': {
                'type': 'Polygon',
                'coordinates': [
                    [
                        [-2e6, -1e6],
                        [-1e6, 1e6],
                        [0, -1e6],
                        [-2e6, -1e6],
                    ],
                ],
            },
        },
    ],
});
