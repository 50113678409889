import { useEffect, useState, type ReactNode } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { logError } from "utils/errors";

interface ErrorState {
  error?: Error;
  message?: string;
  version: number;
}

const UnhandledErrorContext = createContext<ErrorState>({ version: 0 });

function UnhandledErrorContextWrapper(props: { children: ReactNode }) {
  const [errorState, setErrorState] = useState<ErrorState>({ version: 0 });

  useEffect(() => {
    const callback = (event: PromiseRejectionEvent) => {
      const error = event.reason;
      const isError = error instanceof Error;
      if (isError) {
        setErrorState((state) => ({
          error: error,
          message: error.message,
          version: state.version + 1,
        }));
      } else {
        setErrorState((state) => ({ message: String(error), version: state.version + 1 }));
      }
      logError(error);

      // I tried to find a better way of doing this by checking
      // https://github.com/facebook/create-react-app/blob/dd420a6d25d037decd7b81175626dfca817437ff/packages/react-error-overlay/src/listenToRuntimeErrors.js
      // and reading https://stackoverflow.com/questions/46589819/disable-error-overlay-in-development-mode/47398520#47398520
      // so I was messing around with react-error-overlay's stopReportingRuntimeErrors and startReportingRuntimeErrors functions,
      // it wasn't very fruitful
      const errorOverlay = document.getElementById("webpack-dev-server-client-overlay");
      errorOverlay?.remove();
    };

    window.addEventListener("unhandledrejection", callback);

    return () => {
      window.removeEventListener("unhandledrejection", callback);
    };
  }, []);

  return (
    <UnhandledErrorContext.Provider value={errorState}>
      {props.children}
    </UnhandledErrorContext.Provider>
  );
}

export default UnhandledErrorContextWrapper;

export const useGlobalErrorState = () => {
  const val = useContextSelector(UnhandledErrorContext, (c) => c);
  return val;
};
