import { ErrorBoundary } from "common_ui/ErrorBoundary";
import { memo } from "react";
import UnhandledErrorContextWrapper from "./CrashHandlerContext";
import { CrashHandlerNotice } from "./CrashHandlerNotice";

export const makePage = <T extends JSX.IntrinsicAttributes>(Component: React.ComponentType<T>) => {
  const wrapped = (props: T) => (
    <UnhandledErrorContextWrapper>
      <CrashHandlerNotice />
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    </UnhandledErrorContextWrapper>
  );
  return memo(wrapped);
};
