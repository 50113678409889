import { Modal, Select, Title } from "@mantine/core";
import { memo, useCallback, useMemo, useState } from "react";
import { getCurrentGeoMaster } from "../../geomasters/geomasterGetter";
import {
  getPreferredModeOfTransport,
  setPreferredModeOfTransport,
} from "../../storage/localStorage";
import { TransportationMode } from "../../types/geo";
import type { Nullable } from "../../types/utils";

interface TransportSettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

type TransportOption = { value: TransportationMode; label: string };
const options: TransportOption[] = [
  { value: TransportationMode.BIKING, label: "Biking" },
  { value: TransportationMode.DRIVING, label: "Driving" },
  { value: TransportationMode.WALKING, label: "Walking" },
  { value: TransportationMode.PUBLIC_TRANSPORTATION, label: "Public Transportation" },
];

const TransportSettingsModalImpl = (props: TransportSettingsModalProps) => {
  const { isOpen, onClose } = props;
  const [chosenTransportationMode, setChosenTransportationMode] = useState<
    Nullable<TransportationMode>
  >(getPreferredModeOfTransport());
  const geomaster = getCurrentGeoMaster();
  const filteredOptions = useMemo(
    () => options.filter((x) => geomaster.supportedModesOfTransport.includes(x.value)),
    [geomaster.supportedModesOfTransport]
  );
  const setter = useCallback((value: Nullable<TransportationMode>) => {
    if (value) setPreferredModeOfTransport(value);
    setChosenTransportationMode(value);
  }, []);

  return (
    <Modal.Root opened={isOpen} onClose={onClose}>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Title order={3}>Transportation Settings</Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Select
            withinPortal
            data={filteredOptions}
            value={chosenTransportationMode}
            onChange={setter}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

export const TransportSettingsModal = memo(TransportSettingsModalImpl);
