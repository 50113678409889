import { makePage } from "shared/makePage";
import type { Heatmap } from "types/internal_apis/get_heatmap";

import { HeatmapConfigurationInner } from "pages/heatmap_configuration/HeatmapConfigurationInner";
import { useLocation } from "react-router-dom";
import { Text } from "@mantine/core";

const HeatmapConfigurationImpl = () => {
  const location = useLocation();
  const heatmap = location.state.heatmap as Heatmap;

  if (!heatmap) {
    return <Text>No heatmap!</Text>;
  }

  return <HeatmapConfigurationInner heatmap={heatmap} relative_importances={{}} />;
};

export const HeatmapConfiguration = makePage(HeatmapConfigurationImpl);
