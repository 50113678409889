import { useCallback, useState } from "react";

export const useBooleanState = (initialValue: boolean) => {
  const [value, setValue] = useState(initialValue);
  const setTrue = useCallback(() => setValue(true), [setValue]);
  const setFalse = useCallback(() => setValue(false), [setValue]);
  return [value, setTrue, setFalse] as [boolean, () => void, () => void];
};

export const useToggleState = (initialValue: boolean) => {
  const [value, setValue] = useState(initialValue);
  const toggle = useCallback(() => setValue(!value), [value, setValue]);
  return [value, toggle] as [boolean, () => void];
};

export const useRenderVersion = (): [number, () => void] => {
  const [version, setVersion] = useState(0);
  const incrementVersion = useCallback(() => {
    setVersion((prevVersion) => prevVersion + 1);
  }, [setVersion]);
  return [version, incrementVersion];
};
