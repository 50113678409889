import { DevHeatmapConfiguration } from "pages/dev_pages/DevHeatmapConfiguration";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DevIsochroneTester } from "./pages/dev_pages/DevIsochroneTester";
import { HeatmapPointChooser } from "./pages/heatmap_point_chooser/HeatmapPointChooser";
import { Main } from "./pages/main/Main";
import * as serviceWorker from "./serviceWorker";
import ScreenSizeContextWrapper from "./shared/ScreenSizeContext";
import { ThemeProvider } from "./styling/themeProvider";
import { isInDevMode } from "./utils/misc";
import { HeatmapConfiguration } from "pages/heatmap_configuration/HeatmapConfiguration";

import "./styling/globalStyles.css";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <ScreenSizeContextWrapper>
    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          {isInDevMode() && (
            <>
              <Route path="dev/isochrones" element={<DevIsochroneTester />} />
              <Route path="dev/heatmap" element={<DevHeatmapConfiguration />} />
            </>
          )}
          <Route path="heatmap/view" element={<HeatmapConfiguration />} />
          <Route path="heatmap/create" element={<HeatmapPointChooser />} />
          <Route path="*" element={<Main />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </ScreenSizeContextWrapper>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (isInDevMode()) {
  //reportWebVitals(console.log);
}
