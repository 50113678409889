import type { FeatureCollection, Feature as FeatureType } from "geojson";
import type { FeatureLike } from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import GeoJSON from "ol/format/GeoJSON";
import type Point from "ol/geom/Point";
import { Draw, Modify, Snap } from "ol/interaction.js";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { transform } from "ol/proj";
import OSM from "ol/source/OSM.js";
import VectorSource from "ol/source/Vector";
import type Style from "ol/style/Style";
import { useCallback, useEffect, useRef } from "react";
import {
  COMMON_LAT_LANG_COORDINATE_PROJECTION,
  OPEN_LAYERS_DEFAULT_MAP_PROJECTION,
} from "shared/OpenLayersConstants";
import type { Undefined } from "types/utils";

export const DEV_MAP_ELEMENT_ID = "dev-map";

export const useDevMap = (styleFunction: (feature: FeatureLike) => Style[]) => {
  const mapRef = useRef<Map>();
  const polygonSourceRef = useRef<VectorSource>();
  const pointSourceRef = useRef<VectorSource>();

  useEffect(() => {
    const polygonSource = new VectorSource({});
    const pointSource = new VectorSource({});
    polygonSourceRef.current = polygonSource;
    pointSourceRef.current = pointSource;

    mapRef.current = new Map({
      target: DEV_MAP_ELEMENT_ID,
      layers: [
        new TileLayer({
          //Tiles
          source: new OSM({
            attributions: "",
          }),
        }),
        new VectorLayer({
          //Isochrone polygons
          source: polygonSource,
          style: styleFunction,
        }),
        new VectorLayer({
          //Points
          source: pointSource,
          style: {
            "circle-stroke-color": "black",
            "circle-radius": 7,
            "circle-fill-color": "#ffcc33",
          },
        }),
      ],
      view: new View({
        projection: OPEN_LAYERS_DEFAULT_MAP_PROJECTION,
        center: transform(
          [-73.9964, 40.7057],
          COMMON_LAT_LANG_COORDINATE_PROJECTION,
          OPEN_LAYERS_DEFAULT_MAP_PROJECTION
        ),
        zoom: 12,
      }),
    });

    //Allow us to modify points
    const modify = new Modify({ source: pointSourceRef.current });

    //Allow us to add new points
    const draw = new Draw({
      source: pointSourceRef.current,
      type: "Point",
    });

    //Allow us to snap to points when editing
    const snap = new Snap({ source: pointSourceRef.current });
    mapRef.current.addInteraction(modify);
    mapRef.current.addInteraction(draw);
    mapRef.current.addInteraction(snap);

    const map = mapRef.current;
    return () => {
      map.removeInteraction(modify);
      map.removeInteraction(draw);
      map.removeInteraction(snap);
    };
  }, [styleFunction]);

  const addPolygons = useCallback(
    (features: FeatureType[] | FeatureCollection, projection?: Undefined<string>) => {
      let featuresArray: FeatureType[] = [];
      if (Array.isArray(features)) {
        featuresArray = [...features];
      } else {
        featuresArray = features.features;
      }

      featuresArray.forEach((feature) => {
        polygonSourceRef.current?.addFeature(
          new GeoJSON().readFeature(feature, {
            dataProjection: projection,
            featureProjection: OPEN_LAYERS_DEFAULT_MAP_PROJECTION,
          })
        );
      });
    },
    [polygonSourceRef]
  );

  const clearPolygons = useCallback(() => {
    polygonSourceRef.current?.clear();
  }, [polygonSourceRef]);

  const getDevMapPoints = useCallback(() => {
    let points = pointSourceRef.current
      ?.getFeatures()
      .map((x) => (x.getGeometry() as Point).getCoordinates());
    points = points?.map((point) =>
      transform(point, OPEN_LAYERS_DEFAULT_MAP_PROJECTION, COMMON_LAT_LANG_COORDINATE_PROJECTION)
    );
    return points ?? [];
  }, [pointSourceRef]);

  return {
    mapRef,
    polygonSourceRef,
    pointSourceRef,
    addPolygons,
    clearPolygons,
    getDevMapPoints,
  };
};
