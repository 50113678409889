import { makePage } from "shared/makePage";
import type { Heatmap } from "types/internal_apis/get_heatmap";

import { HeatmapConfigurationInner } from "pages/heatmap_configuration/HeatmapConfigurationInner";
import { sampleHeatmap } from "./data/exampleHeatmap";

const DevHeatmapConfigurationImpl = () => {
  return (
    <HeatmapConfigurationInner
      heatmap={sampleHeatmap as unknown as Heatmap}
      relative_importances={{}}
    />
  );
};

export const DevHeatmapConfiguration = makePage(DevHeatmapConfigurationImpl);
