export const sampleHeatmap = {
    "feature_collection": {
        "type": "FeatureCollection",
        "features": [
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03087593283955,
                                40.6166219775339
                            ],
                            [
                                -74.0308759277422,
                                40.61662199020322
                            ],
                            [
                                -74.03087591607863,
                                40.61662198173792
                            ],
                            [
                                -74.0308759169229,
                                40.61662197963852
                            ],
                            [
                                -74.03087591376193,
                                40.616621980056486
                            ],
                            [
                                -74.03087593283955,
                                40.6166219775339
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "717a9bbe-cd69-44ea-a1da-896ec1389098"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03087591607863,
                                40.61662198173792
                            ],
                            [
                                -74.03087591376193,
                                40.616621980056486
                            ],
                            [
                                -74.0308759169229,
                                40.61662197963852
                            ],
                            [
                                -74.03087591607863,
                                40.61662198173792
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3141361f-3ffd-46a1-9480-c291f33d8a4d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03087593283955,
                                40.6166219775339
                            ],
                            [
                                -74.03103386094507,
                                40.61622945195685
                            ],
                            [
                                -74.0310699139307,
                                40.61643498021139
                            ],
                            [
                                -74.03087593283955,
                                40.6166219775339
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "439752bd-d303-4694-86cb-9978f4a75958"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01892376422802,
                                40.70475965234498
                            ],
                            [
                                -74.01452513886723,
                                40.71775439469368
                            ],
                            [
                                -74.01318211976249,
                                40.716860013612596
                            ],
                            [
                                -74.01393355814604,
                                40.71675348866182
                            ],
                            [
                                -74.01396444366574,
                                40.713632133072586
                            ],
                            [
                                -74.01650534048137,
                                40.70797728210467
                            ],
                            [
                                -74.01631194847205,
                                40.70712361505955
                            ],
                            [
                                -74.01403102586212,
                                40.706903196250366
                            ],
                            [
                                -74.01403179254888,
                                40.70682571327412
                            ],
                            [
                                -74.01811999572924,
                                40.70510139927339
                            ],
                            [
                                -74.01816744833582,
                                40.70469649752004
                            ],
                            [
                                -74.01788085701409,
                                40.704830704601065
                            ],
                            [
                                -74.01824284842918,
                                40.70462554727024
                            ],
                            [
                                -74.01818117728828,
                                40.70461333428025
                            ],
                            [
                                -74.01787715236185,
                                40.70483154222402
                            ],
                            [
                                -74.0178343853678,
                                40.7048726327241
                            ],
                            [
                                -74.01726798949633,
                                40.70471849708064
                            ],
                            [
                                -74.01824194792118,
                                40.70450244814119
                            ],
                            [
                                -74.01892376422802,
                                40.70475965234498
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e0dbda50-ff73-412b-99f6-9059769cb247"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01452513886723,
                                40.71775439469368
                            ],
                            [
                                -74.01892376422802,
                                40.70475965234498
                            ],
                            [
                                -74.00967470559664,
                                40.745367891770755
                            ],
                            [
                                -74.00945897246072,
                                40.745344959576094
                            ],
                            [
                                -74.009528272095,
                                40.74501425956346
                            ],
                            [
                                -74.0086881739295,
                                40.74492176143271
                            ],
                            [
                                -74.00883175357524,
                                40.742332486342406
                            ],
                            [
                                -74.0092171199351,
                                40.74233417362545
                            ],
                            [
                                -74.00925710125344,
                                40.74221165610841
                            ],
                            [
                                -74.0090952814331,
                                40.74225067081328
                            ],
                            [
                                -74.0092128816836,
                                40.74208469600343
                            ],
                            [
                                -74.00884237188632,
                                40.74214099869622
                            ],
                            [
                                -74.00885659656403,
                                40.74188447484507
                            ],
                            [
                                -74.00921140305663,
                                40.74149295599931
                            ],
                            [
                                -74.00909823958534,
                                40.74044444968074
                            ],
                            [
                                -74.00893788363801,
                                40.740418566535254
                            ],
                            [
                                -74.00897997403689,
                                40.7396595200784
                            ],
                            [
                                -74.00942530270555,
                                40.73935842147837
                            ],
                            [
                                -74.00900663558298,
                                40.739178713228796
                            ],
                            [
                                -74.00955728254654,
                                40.729248500217096
                            ],
                            [
                                -74.01060951132042,
                                40.72911021557171
                            ],
                            [
                                -74.0109421556735,
                                40.725060853433774
                            ],
                            [
                                -74.01152866751914,
                                40.72660679059688
                            ],
                            [
                                -74.01411331501549,
                                40.71897103498636
                            ],
                            [
                                -74.01528660781351,
                                40.71826149352569
                            ],
                            [
                                -74.01452513886723,
                                40.71775439469368
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8b34748c-37c7-4070-8854-c4e47fd84484"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01452513886723,
                                40.71775439469368
                            ],
                            [
                                -74.01411331501549,
                                40.71897103498636
                            ],
                            [
                                -74.00998887607041,
                                40.721465263569804
                            ],
                            [
                                -74.00978133431668,
                                40.72520801338893
                            ],
                            [
                                -74.00315728175947,
                                40.7155279718431
                            ],
                            [
                                -74.00742951294389,
                                40.71538501650481
                            ],
                            [
                                -74.00770287657305,
                                40.71763675875073
                            ],
                            [
                                -74.01318211976249,
                                40.716860013612596
                            ],
                            [
                                -74.01452513886723,
                                40.71775439469368
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ad974483-34d2-4d94-8012-0a6229e76506"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01411331501549,
                                40.71897103498636
                            ],
                            [
                                -74.01452513886723,
                                40.71775439469368
                            ],
                            [
                                -74.01528660781351,
                                40.71826149352569
                            ],
                            [
                                -74.01411331501549,
                                40.71897103498636
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "11a3b260-480c-4117-b142-b0c6f9fac80f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01152866751914,
                                40.72660679059688
                            ],
                            [
                                -74.0109421556735,
                                40.725060853433774
                            ],
                            [
                                -74.01060951132042,
                                40.72911021557171
                            ],
                            [
                                -74.00955728254654,
                                40.729248500217096
                            ],
                            [
                                -74.00965792838086,
                                40.72743348144876
                            ],
                            [
                                -74.00971591129323,
                                40.727249989990575
                            ],
                            [
                                -74.00976972397201,
                                40.72677571839926
                            ],
                            [
                                -74.00966341638328,
                                40.72733451235148
                            ],
                            [
                                -74.00970602545925,
                                40.72656611221722
                            ],
                            [
                                -74.0104732359935,
                                40.72621912205984
                            ],
                            [
                                -74.00978133431668,
                                40.72520801338893
                            ],
                            [
                                -74.00998887607041,
                                40.721465263569804
                            ],
                            [
                                -74.01411331501549,
                                40.71897103498636
                            ],
                            [
                                -74.01152866751914,
                                40.72660679059688
                            ]
                        ],
                        [
                            [
                                -74.01183887824462,
                                40.72475376587922
                            ],
                            [
                                -74.01173577680284,
                                40.72495756497383
                            ],
                            [
                                -74.01180174548404,
                                40.72496424623117
                            ],
                            [
                                -74.01183887824462,
                                40.72475376587922
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "21be62ad-72a8-4ebb-88e9-b5d16fe54c77"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00955728254654,
                                40.729248500217096
                            ],
                            [
                                -74.0070770553489,
                                40.729574453419495
                            ],
                            [
                                -74.00743493736138,
                                40.73103979407239
                            ],
                            [
                                -74.00533026960083,
                                40.728545157272244
                            ],
                            [
                                -74.00970602545925,
                                40.72656611221722
                            ],
                            [
                                -74.00966341638328,
                                40.72733451235148
                            ],
                            [
                                -74.00962440620346,
                                40.72753956499929
                            ],
                            [
                                -74.00965792838086,
                                40.72743348144876
                            ],
                            [
                                -74.00955728254654,
                                40.729248500217096
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9c3834a9-ca2a-4c95-a4b8-d3c16d1b66bf"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00743493736138,
                                40.73103979407239
                            ],
                            [
                                -74.0070770553489,
                                40.729574453419495
                            ],
                            [
                                -74.00955728254654,
                                40.729248500217096
                            ],
                            [
                                -74.00900663558298,
                                40.739178713228796
                            ],
                            [
                                -74.00507117211669,
                                40.73748945889605
                            ],
                            [
                                -74.0092974760339,
                                40.733247438243716
                            ],
                            [
                                -74.00743493736138,
                                40.73103979407239
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "4237a34a-94c0-45f3-a0a8-51691306f9d4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0081814738036,
                                40.734096472417534
                            ],
                            [
                                -74.00376177428383,
                                40.736792137899336
                            ],
                            [
                                -74.00754814413753,
                                40.74232686620358
                            ],
                            [
                                -73.99713322437992,
                                40.742281265654704
                            ],
                            [
                                -74.0000367982859,
                                40.7482047425537
                            ],
                            [
                                -73.99963675017585,
                                40.74828762807221
                            ],
                            [
                                -73.9904717242714,
                                40.74610186554434
                            ],
                            [
                                -73.99539983700433,
                                40.75380415011235
                            ],
                            [
                                -73.99525654099246,
                                40.75378309657919
                            ],
                            [
                                -73.9900901253286,
                                40.7517210649767
                            ],
                            [
                                -73.98927426622217,
                                40.752904160601624
                            ],
                            [
                                -73.98604472414333,
                                40.7524296653915
                            ],
                            [
                                -73.98762264004722,
                                40.755299220834665
                            ],
                            [
                                -73.98746579888976,
                                40.75552665972177
                            ],
                            [
                                -73.98479448690989,
                                40.75588088337317
                            ],
                            [
                                -73.98206125454743,
                                40.75426548742774
                            ],
                            [
                                -73.9819458534357,
                                40.754041871164745
                            ],
                            [
                                -73.98389297517022,
                                40.75488393445451
                            ],
                            [
                                -73.98210448962668,
                                40.753910187891556
                            ],
                            [
                                -73.98369287454237,
                                40.754555934411556
                            ],
                            [
                                -73.98273347561558,
                                40.75329193473071
                            ],
                            [
                                -73.98669311374235,
                                40.75108881832227
                            ],
                            [
                                -73.98444918684133,
                                40.75015621619326
                            ],
                            [
                                -73.98672521324241,
                                40.751046218370746
                            ],
                            [
                                -73.98434560120761,
                                40.74995087189079
                            ],
                            [
                                -73.98712831336154,
                                40.750465218563036
                            ],
                            [
                                -73.97810021062014,
                                40.74560886273422
                            ],
                            [
                                -73.97846902613752,
                                40.74511261693504
                            ],
                            [
                                -73.98141481345719,
                                40.74389551901502
                            ],
                            [
                                -73.98016593101151,
                                40.74282941062822
                            ],
                            [
                                -73.98360201354464,
                                40.738206119464
                            ],
                            [
                                -73.97977807132354,
                                40.734756520519944
                            ],
                            [
                                -73.97978207512263,
                                40.734757334751
                            ],
                            [
                                -73.98083502057389,
                                40.733300771460684
                            ],
                            [
                                -73.99983279475937,
                                40.738616888132334
                            ],
                            [
                                -73.98500214391615,
                                40.72777887216095
                            ],
                            [
                                -73.97655359925722,
                                40.72824990598887
                            ],
                            [
                                -73.97600960996846,
                                40.7277555159767
                            ],
                            [
                                -73.9791305244893,
                                40.726477911601606
                            ],
                            [
                                -73.97959004406098,
                                40.72667194040341
                            ],
                            [
                                -73.97998986173606,
                                40.7268399871251
                            ],
                            [
                                -73.9791306039291,
                                40.72647787908145
                            ],
                            [
                                -73.98477701263121,
                                40.72416641671578
                            ],
                            [
                                -73.97824153898306,
                                40.718552453830334
                            ],
                            [
                                -73.97770536836366,
                                40.71731466151501
                            ],
                            [
                                -73.97847271771396,
                                40.71594182804669
                            ],
                            [
                                -73.976867144097,
                                40.71537955444599
                            ],
                            [
                                -73.97667981565999,
                                40.71494709192123
                            ],
                            [
                                -73.97681578846223,
                                40.71467569388468
                            ],
                            [
                                -73.9791312645639,
                                40.71530673699879
                            ],
                            [
                                -73.98088506615434,
                                40.712823256963894
                            ],
                            [
                                -73.9809571559559,
                                40.71346674684575
                            ],
                            [
                                -73.9837924222489,
                                40.71321206574088
                            ],
                            [
                                -73.9849810447632,
                                40.712029527640354
                            ],
                            [
                                -73.99215126206643,
                                40.71192833421959
                            ],
                            [
                                -73.99285400090955,
                                40.7125749455533
                            ],
                            [
                                -73.99140969078474,
                                40.71318799541407
                            ],
                            [
                                -73.98997414024902,
                                40.71238308844929
                            ],
                            [
                                -73.98894867202968,
                                40.71407667441684
                            ],
                            [
                                -73.99045575941219,
                                40.71447151814293
                            ],
                            [
                                -73.99076985046452,
                                40.71412204107879
                            ],
                            [
                                -73.99481881292837,
                                40.71438282871279
                            ],
                            [
                                -73.99619015413003,
                                40.71564464136492
                            ],
                            [
                                -73.99663496679888,
                                40.714499804475125
                            ],
                            [
                                -74.01089297650496,
                                40.715418141617064
                            ],
                            [
                                -74.01333390125285,
                                40.70721692025466
                            ],
                            [
                                -74.00288025975249,
                                40.7079148009347
                            ],
                            [
                                -74.00776758788518,
                                40.70716774937701
                            ],
                            [
                                -74.00863518949741,
                                40.707334632602475
                            ],
                            [
                                -74.00837395773917,
                                40.70707506283507
                            ],
                            [
                                -74.00896711070669,
                                40.706984396557644
                            ],
                            [
                                -74.00783938397318,
                                40.70654389009777
                            ],
                            [
                                -74.00742312349296,
                                40.70613027792153
                            ],
                            [
                                -74.00749642978847,
                                40.70606831127901
                            ],
                            [
                                -74.0084821462174,
                                40.706688120535716
                            ],
                            [
                                -74.00816746429967,
                                40.70550107820047
                            ],
                            [
                                -74.0092125115777,
                                40.70461768789069
                            ],
                            [
                                -74.00795543342359,
                                40.704169333301905
                            ],
                            [
                                -74.00840621072811,
                                40.7036464954309
                            ],
                            [
                                -74.00753723531949,
                                40.70355526775394
                            ],
                            [
                                -74.00772458705684,
                                40.70303076747769
                            ],
                            [
                                -74.00794254309368,
                                40.70292874205865
                            ],
                            [
                                -74.00950450806248,
                                40.703609631864424
                            ],
                            [
                                -74.00929280569274,
                                40.7025775220363
                            ],
                            [
                                -74.01127452424379,
                                40.70285396341357
                            ],
                            [
                                -74.0104221847359,
                                40.70195991819631
                            ],
                            [
                                -74.0110883156353,
                                40.70180385575222
                            ],
                            [
                                -74.01320736318034,
                                40.70260323225752
                            ],
                            [
                                -74.01406877705418,
                                40.70308798137091
                            ],
                            [
                                -74.01405325793893,
                                40.704656375803445
                            ],
                            [
                                -74.0135785933531,
                                40.705507960174266
                            ],
                            [
                                -74.01375323961629,
                                40.7054110433013
                            ],
                            [
                                -74.01373913936236,
                                40.705431483889505
                            ],
                            [
                                -74.01375325842608,
                                40.70541103286315
                            ],
                            [
                                -74.01404740584302,
                                40.70524780092608
                            ],
                            [
                                -74.01403179254888,
                                40.70682571327412
                            ],
                            [
                                -74.01388218798651,
                                40.70688881318136
                            ],
                            [
                                -74.01403102586212,
                                40.706903196250366
                            ],
                            [
                                -74.01396444366574,
                                40.713632133072586
                            ],
                            [
                                -74.0131996219825,
                                40.71533426934556
                            ],
                            [
                                -74.01130368176129,
                                40.71560907131078
                            ],
                            [
                                -74.01318211976249,
                                40.716860013612596
                            ],
                            [
                                -74.00770287657305,
                                40.71763675875073
                            ],
                            [
                                -74.00742951294389,
                                40.71538501650481
                            ],
                            [
                                -74.00315728175947,
                                40.7155279718431
                            ],
                            [
                                -74.00978133431668,
                                40.72520801338893
                            ],
                            [
                                -74.00970602545925,
                                40.72656611221722
                            ],
                            [
                                -74.00533026960083,
                                40.728545157272244
                            ],
                            [
                                -74.00743493736138,
                                40.73103979407239
                            ],
                            [
                                -74.0081814738036,
                                40.734096472417534
                            ]
                        ],
                        [
                            [
                                -74.01329393520587,
                                40.703082352805765
                            ],
                            [
                                -74.01313173629647,
                                40.703038562348304
                            ],
                            [
                                -74.0130856877567,
                                40.70307341588276
                            ],
                            [
                                -74.01329393520587,
                                40.703082352805765
                            ]
                        ],
                        [
                            [
                                -74.00986289094507,
                                40.703622787551616
                            ],
                            [
                                -74.011064217856,
                                40.703208529935786
                            ],
                            [
                                -74.01103740853466,
                                40.70319255750524
                            ],
                            [
                                -74.01088901493455,
                                40.703123767865584
                            ],
                            [
                                -74.01034965386155,
                                40.70298745437651
                            ],
                            [
                                -74.01047641423298,
                                40.70311477987225
                            ],
                            [
                                -74.00999109041759,
                                40.70318409321873
                            ],
                            [
                                -74.00986289094507,
                                40.703622787551616
                            ]
                        ],
                        [
                            [
                                -73.9964427639812,
                                40.715522362032345
                            ],
                            [
                                -73.99656144580328,
                                40.71531826441711
                            ],
                            [
                                -73.99646508262443,
                                40.71539102736916
                            ],
                            [
                                -73.9964427639812,
                                40.715522362032345
                            ]
                        ],
                        [
                            [
                                -73.99853200548188,
                                40.72066115909123
                            ],
                            [
                                -74.00188966367215,
                                40.717110149067146
                            ],
                            [
                                -73.9980123112254,
                                40.71703511048568
                            ],
                            [
                                -73.99853200548188,
                                40.72066115909123
                            ]
                        ],
                        [
                            [
                                -74.00573688572598,
                                40.72107249087171
                            ],
                            [
                                -74.00430462710834,
                                40.72028882580046
                            ],
                            [
                                -74.00536094886442,
                                40.722195479552944
                            ],
                            [
                                -74.00573688572598,
                                40.72107249087171
                            ]
                        ],
                        [
                            [
                                -73.99545242617768,
                                40.72162095221274
                            ],
                            [
                                -73.99658920913544,
                                40.72355198956997
                            ],
                            [
                                -73.99851723448366,
                                40.721940020303
                            ],
                            [
                                -73.99545242617768,
                                40.72162095221274
                            ]
                        ],
                        [
                            [
                                -73.99620402681298,
                                40.72529693509946
                            ],
                            [
                                -73.99631465883179,
                                40.72526557949837
                            ],
                            [
                                -73.99608217214131,
                                40.72520991983208
                            ],
                            [
                                -73.99620402681298,
                                40.72529693509946
                            ]
                        ],
                        [
                            [
                                -73.99529318523143,
                                40.72636628508677
                            ],
                            [
                                -73.99576650145553,
                                40.72656504635241
                            ],
                            [
                                -73.99572816679526,
                                40.72635827721349
                            ],
                            [
                                -73.99877979766816,
                                40.726302097652436
                            ],
                            [
                                -73.9954951956659,
                                40.72510167967316
                            ],
                            [
                                -73.99548935142744,
                                40.72507015707339
                            ],
                            [
                                -73.99543893194867,
                                40.72508111706688
                            ],
                            [
                                -73.99370506460951,
                                40.72444744348142
                            ],
                            [
                                -73.99400285348429,
                                40.725393286311885
                            ],
                            [
                                -73.9933263447672,
                                40.72554034318983
                            ],
                            [
                                -73.99415927608455,
                                40.7258901188248
                            ],
                            [
                                -73.99431486258395,
                                40.72638429569788
                            ],
                            [
                                -73.99529318523143,
                                40.72636628508677
                            ]
                        ],
                        [
                            [
                                -74.0035408390296,
                                40.72634872661691
                            ],
                            [
                                -74.00362066242742,
                                40.72652166754718
                            ],
                            [
                                -74.00408516958275,
                                40.726284074140395
                            ],
                            [
                                -74.00389958752652,
                                40.7258350784576
                            ],
                            [
                                -74.0035408390296,
                                40.72634872661691
                            ]
                        ],
                        [
                            [
                                -74.00010224981874,
                                40.73219536911694
                            ],
                            [
                                -74.00022380175815,
                                40.732711288040655
                            ],
                            [
                                -74.00036325646543,
                                40.732519186092404
                            ],
                            [
                                -74.00059744611912,
                                40.7324287012635
                            ],
                            [
                                -74.00047113291446,
                                40.73237058389438
                            ],
                            [
                                -74.00073175054229,
                                40.73201157733927
                            ],
                            [
                                -74.00010224981874,
                                40.73219536911694
                            ]
                        ],
                        [
                            [
                                -74.00017987863232,
                                40.73876042249244
                            ],
                            [
                                -74.0054861668285,
                                40.741007891176615
                            ],
                            [
                                -74.0037923116136,
                                40.738620306349965
                            ],
                            [
                                -74.00017987863232,
                                40.73876042249244
                            ]
                        ],
                        [
                            [
                                -73.99827170335695,
                                40.74073621742092
                            ],
                            [
                                -73.99735462136822,
                                40.74077148750291
                            ],
                            [
                                -73.99759835047448,
                                40.74141470447628
                            ],
                            [
                                -73.99848749395956,
                                40.741245848550314
                            ],
                            [
                                -73.99827170335695,
                                40.74073621742092
                            ]
                        ],
                        [
                            [
                                -73.98522943066953,
                                40.71755628394011
                            ],
                            [
                                -73.98272646067151,
                                40.71679037559179
                            ],
                            [
                                -73.9854932951028,
                                40.72186469100917
                            ],
                            [
                                -73.99140515649661,
                                40.71944605488089
                            ],
                            [
                                -73.98745451183504,
                                40.71823715835798
                            ],
                            [
                                -73.98825798256055,
                                40.71668314089393
                            ],
                            [
                                -73.98508515633324,
                                40.71724366456141
                            ],
                            [
                                -73.98522943066953,
                                40.71755628394011
                            ]
                        ],
                        [
                            [
                                -73.9936534240571,
                                40.7208377667759
                            ],
                            [
                                -73.9936558821638,
                                40.72083660820348
                            ],
                            [
                                -73.99361240069302,
                                40.72094033450339
                            ],
                            [
                                -73.99461031758258,
                                40.72051113614592
                            ],
                            [
                                -73.9945695223149,
                                40.7204261693894
                            ],
                            [
                                -73.99502067429258,
                                40.72056796328588
                            ],
                            [
                                -73.9943213430304,
                                40.719264968979665
                            ],
                            [
                                -73.9929412837173,
                                40.71991442543926
                            ],
                            [
                                -73.99357136944057,
                                40.72011245695469
                            ],
                            [
                                -73.9936534240571,
                                40.7208377667759
                            ]
                        ],
                        [
                            [
                                -73.98954856787473,
                                40.72367340723626
                            ],
                            [
                                -73.99118058306549,
                                40.723538608389404
                            ],
                            [
                                -73.99073738933859,
                                40.722311627458836
                            ],
                            [
                                -73.98954856787473,
                                40.72367340723626
                            ]
                        ],
                        [
                            [
                                -73.99010388123055,
                                40.729642229340605
                            ],
                            [
                                -73.99058452751827,
                                40.730519529931094
                            ],
                            [
                                -73.99079123525503,
                                40.729999962962644
                            ],
                            [
                                -73.99074262112681,
                                40.73048993512106
                            ],
                            [
                                -73.99102353541356,
                                40.73013313555337
                            ],
                            [
                                -73.99107079171519,
                                40.730111837098924
                            ],
                            [
                                -73.9921592490585,
                                40.73073063874041
                            ],
                            [
                                -73.99253254487313,
                                40.7305809434596
                            ],
                            [
                                -73.99274529737345,
                                40.73019323612687
                            ],
                            [
                                -73.99010388123055,
                                40.729642229340605
                            ]
                        ],
                        [
                            [
                                -73.98718953264984,
                                40.74041457818506
                            ],
                            [
                                -73.98588875954202,
                                40.739864517050854
                            ],
                            [
                                -73.98614437785105,
                                40.74057956752837
                            ],
                            [
                                -73.98718953264984,
                                40.74041457818506
                            ]
                        ],
                        [
                            [
                                -73.98898415907667,
                                40.74214967139198
                            ],
                            [
                                -73.99059092981129,
                                40.74184587248453
                            ],
                            [
                                -73.99011805664574,
                                40.740838202493
                            ],
                            [
                                -73.98898415907667,
                                40.74214967139198
                            ]
                        ],
                        [
                            [
                                -73.9927511481009,
                                40.74275186375211
                            ],
                            [
                                -73.99274202750581,
                                40.7429869563501
                            ],
                            [
                                -73.99296710668845,
                                40.74267823503271
                            ],
                            [
                                -73.9927511481009,
                                40.74275186375211
                            ]
                        ],
                        [
                            [
                                -73.98431443595545,
                                40.74309103558941
                            ],
                            [
                                -73.9841684435521,
                                40.74329154022325
                            ],
                            [
                                -73.98446816465002,
                                40.74288011028838
                            ],
                            [
                                -73.98431443595545,
                                40.74309103558941
                            ]
                        ],
                        [
                            [
                                -73.98881842170339,
                                40.74540236714166
                            ],
                            [
                                -73.98863568730661,
                                40.745241467121716
                            ],
                            [
                                -73.98850556633771,
                                40.74591940799816
                            ],
                            [
                                -73.98862991293596,
                                40.745974432380436
                            ],
                            [
                                -73.98881842170339,
                                40.74540236714166
                            ]
                        ],
                        [
                            [
                                -73.98802050932194,
                                40.74959604527387
                            ],
                            [
                                -73.9878017434991,
                                40.74971534046483
                            ],
                            [
                                -73.98794196782002,
                                40.74975661762903
                            ],
                            [
                                -73.98802050932194,
                                40.74959604527387
                            ]
                        ],
                        [
                            [
                                -73.98312253057523,
                                40.75335313601675
                            ],
                            [
                                -73.98331761231559,
                                40.75312951654991
                            ],
                            [
                                -73.98314208600065,
                                40.753211220753165
                            ],
                            [
                                -73.98312253057523,
                                40.75335313601675
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "cecbc1c9-f44c-40cc-b535-a468188949fc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00754814413753,
                                40.74232686620358
                            ],
                            [
                                -74.00376177428383,
                                40.736792137899336
                            ],
                            [
                                -74.0081814738036,
                                40.734096472417534
                            ],
                            [
                                -74.00743493736138,
                                40.73103979407239
                            ],
                            [
                                -74.0092974760339,
                                40.733247438243716
                            ],
                            [
                                -74.00507117211669,
                                40.73748945889605
                            ],
                            [
                                -74.00900663558298,
                                40.739178713228796
                            ],
                            [
                                -74.00897997403689,
                                40.7396595200784
                            ],
                            [
                                -74.00806557855555,
                                40.74027776727128
                            ],
                            [
                                -74.00893788363801,
                                40.740418566535254
                            ],
                            [
                                -74.00885659656403,
                                40.74188447484507
                            ],
                            [
                                -74.00858927211166,
                                40.742179459754375
                            ],
                            [
                                -74.00884237188632,
                                40.74214099869622
                            ],
                            [
                                -74.00883175357524,
                                40.742332486342406
                            ],
                            [
                                -74.00754814413753,
                                40.74232686620358
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "a9f283cb-a491-46d2-b516-9c87e3534311"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00754814413753,
                                40.74232686620358
                            ],
                            [
                                -74.00872856487963,
                                40.74405234690004
                            ],
                            [
                                -73.99994732456821,
                                40.74354776553401
                            ],
                            [
                                -74.00342788576408,
                                40.747502146948605
                            ],
                            [
                                -74.0000367982859,
                                40.7482047425537
                            ],
                            [
                                -73.99713322437992,
                                40.742281265654704
                            ],
                            [
                                -74.00754814413753,
                                40.74232686620358
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "8cd882ba-0ec2-4440-b274-aa822acfe2ac"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00342788576408,
                                40.747502146948605
                            ],
                            [
                                -73.99994732456821,
                                40.74354776553401
                            ],
                            [
                                -74.00872856487963,
                                40.74405234690004
                            ],
                            [
                                -74.00754814413753,
                                40.74232686620358
                            ],
                            [
                                -74.00883175357524,
                                40.742332486342406
                            ],
                            [
                                -74.0086881739295,
                                40.74492176143271
                            ],
                            [
                                -74.009528272095,
                                40.74501425956346
                            ],
                            [
                                -74.00945897246072,
                                40.745344959576094
                            ],
                            [
                                -74.00967470559664,
                                40.745367891770755
                            ],
                            [
                                -74.00963037158002,
                                40.74556254145712
                            ],
                            [
                                -74.00235392558741,
                                40.74533266558066
                            ],
                            [
                                -74.00702933594269,
                                40.748867847669054
                            ],
                            [
                                -73.9985790259988,
                                40.75032966484404
                            ],
                            [
                                -73.9926471124112,
                                40.76513019848273
                            ],
                            [
                                -73.97288405956482,
                                40.767414157680825
                            ],
                            [
                                -73.97056132507532,
                                40.76495246531552
                            ],
                            [
                                -73.96793819509467,
                                40.76798573701813
                            ],
                            [
                                -73.96591516831143,
                                40.76821953240721
                            ],
                            [
                                -73.96495195698039,
                                40.766664259527595
                            ],
                            [
                                -73.96273050751397,
                                40.7676945814845
                            ],
                            [
                                -73.96509024204936,
                                40.765896572870126
                            ],
                            [
                                -73.96530593003325,
                                40.76636951260955
                            ],
                            [
                                -73.96753809887716,
                                40.763136993012054
                            ],
                            [
                                -73.9683419194866,
                                40.76304084931984
                            ],
                            [
                                -73.96795005039218,
                                40.76254042463455
                            ],
                            [
                                -73.96976202543783,
                                40.75991640949285
                            ],
                            [
                                -73.97941572148852,
                                40.76102826678906
                            ],
                            [
                                -73.97393328710028,
                                40.757179273477824
                            ],
                            [
                                -73.98096582380008,
                                40.753618065564865
                            ],
                            [
                                -73.98206125454743,
                                40.75426548742774
                            ],
                            [
                                -73.98301657428746,
                                40.7561166397003
                            ],
                            [
                                -73.98479448690989,
                                40.75588088337317
                            ],
                            [
                                -73.98600821481355,
                                40.75659822126268
                            ],
                            [
                                -73.97931878038031,
                                40.75773484813263
                            ],
                            [
                                -73.97867815074135,
                                40.75744703700119
                            ],
                            [
                                -73.97846967152684,
                                40.757510147222284
                            ],
                            [
                                -73.97989102729187,
                                40.760636395134384
                            ],
                            [
                                -73.98148008386792,
                                40.76043118357584
                            ],
                            [
                                -73.98263982848273,
                                40.76172140639767
                            ],
                            [
                                -73.97906357830388,
                                40.761773732742476
                            ],
                            [
                                -73.98193724812002,
                                40.76295028296003
                            ],
                            [
                                -73.98050457842756,
                                40.76237832068964
                            ],
                            [
                                -73.97948446237069,
                                40.76628261229804
                            ],
                            [
                                -73.9829020130376,
                                40.7657795365511
                            ],
                            [
                                -73.98287311073454,
                                40.76333344784437
                            ],
                            [
                                -73.98466886714911,
                                40.76406867413528
                            ],
                            [
                                -73.98286214507728,
                                40.76178848445602
                            ],
                            [
                                -73.9935833419902,
                                40.75896837967721
                            ],
                            [
                                -73.98713898534712,
                                40.75600057938439
                            ],
                            [
                                -73.98746579888976,
                                40.75552665972177
                            ],
                            [
                                -73.98772854607482,
                                40.75549181868935
                            ],
                            [
                                -73.98762264004722,
                                40.755299220834665
                            ],
                            [
                                -73.98927426622217,
                                40.752904160601624
                            ],
                            [
                                -73.99525654099246,
                                40.75378309657919
                            ],
                            [
                                -73.99683871890055,
                                40.75441457903424
                            ],
                            [
                                -73.99569282433087,
                                40.74910476563747
                            ],
                            [
                                -73.99963675017585,
                                40.74828762807221
                            ],
                            [
                                -74.00013576326705,
                                40.74840663745931
                            ],
                            [
                                -74.0000367982859,
                                40.7482047425537
                            ],
                            [
                                -74.00342788576408,
                                40.747502146948605
                            ]
                        ],
                        [
                            [
                                -73.98477486897046,
                                40.7617449736154
                            ],
                            [
                                -73.98433080505656,
                                40.761549040689545
                            ],
                            [
                                -73.98518315893749,
                                40.7635706553224
                            ],
                            [
                                -73.98797793726231,
                                40.763158253376446
                            ],
                            [
                                -73.9862559749341,
                                40.76239847734733
                            ],
                            [
                                -73.98477044504818,
                                40.76173408105879
                            ],
                            [
                                -73.98477486897046,
                                40.7617449736154
                            ]
                        ],
                        [
                            [
                                -73.97816511479775,
                                40.76473285012868
                            ],
                            [
                                -73.97737677970328,
                                40.76407921080297
                            ],
                            [
                                -73.97772618717013,
                                40.76360460557853
                            ],
                            [
                                -73.9773025222538,
                                40.7640176410599
                            ],
                            [
                                -73.97622501878705,
                                40.763124240976104
                            ],
                            [
                                -73.976396331105,
                                40.76542788263016
                            ],
                            [
                                -73.97816511479775,
                                40.76473285012868
                            ]
                        ],
                        [
                            [
                                -73.9727463395617,
                                40.764883873536235
                            ],
                            [
                                -73.97580955425636,
                                40.765497353571014
                            ],
                            [
                                -73.97348031974269,
                                40.76342692956702
                            ],
                            [
                                -73.97303282937379,
                                40.763540724814455
                            ],
                            [
                                -73.97249127820814,
                                40.76478333304868
                            ],
                            [
                                -73.9726422670411,
                                40.76484775488146
                            ],
                            [
                                -73.97295451424732,
                                40.76437586733695
                            ],
                            [
                                -73.9727463395617,
                                40.764883873536235
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "1ca96870-2984-4497-af86-9817590e2c45"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99963675017585,
                                40.74828762807221
                            ],
                            [
                                -74.0000367982859,
                                40.7482047425537
                            ],
                            [
                                -74.00013576326705,
                                40.74840663745931
                            ],
                            [
                                -73.99963675017585,
                                40.74828762807221
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "bad0cbd9-cf50-46e1-8e8e-ba2bbaf5133d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99963675017585,
                                40.74828762807221
                            ],
                            [
                                -73.99569282433087,
                                40.74910476563747
                            ],
                            [
                                -73.99683871890055,
                                40.75441457903424
                            ],
                            [
                                -73.99525654099246,
                                40.75378309657919
                            ],
                            [
                                -73.99539983700433,
                                40.75380415011235
                            ],
                            [
                                -73.9904717242714,
                                40.74610186554434
                            ],
                            [
                                -73.99963675017585,
                                40.74828762807221
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c032d41f-dd93-4eb6-9489-9103295268cd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98927426622217,
                                40.752904160601624
                            ],
                            [
                                -73.9900901253286,
                                40.7517210649767
                            ],
                            [
                                -73.99525654099246,
                                40.75378309657919
                            ],
                            [
                                -73.98927426622217,
                                40.752904160601624
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "829eb9f6-e15a-4182-929f-f2a55c928ea3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98927426622217,
                                40.752904160601624
                            ],
                            [
                                -73.98762264004722,
                                40.755299220834665
                            ],
                            [
                                -73.98604472414333,
                                40.7524296653915
                            ],
                            [
                                -73.98927426622217,
                                40.752904160601624
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "2f37f124-520e-4739-a022-ff94c26fd34c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98746579888976,
                                40.75552665972177
                            ],
                            [
                                -73.98762264004722,
                                40.755299220834665
                            ],
                            [
                                -73.98772854607482,
                                40.75549181868935
                            ],
                            [
                                -73.98746579888976,
                                40.75552665972177
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "d51a4bf2-c49d-4f47-9f7f-1576db40b275"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98713898534712,
                                40.75600057938439
                            ],
                            [
                                -73.9935833419902,
                                40.75896837967721
                            ],
                            [
                                -73.98286214507728,
                                40.76178848445602
                            ],
                            [
                                -73.98280705662049,
                                40.76171895957858
                            ],
                            [
                                -73.98263982848273,
                                40.76172140639767
                            ],
                            [
                                -73.98148008386792,
                                40.76043118357584
                            ],
                            [
                                -73.98446302269333,
                                40.760045965368604
                            ],
                            [
                                -73.97931878038031,
                                40.75773484813263
                            ],
                            [
                                -73.98600821481355,
                                40.75659822126268
                            ],
                            [
                                -73.98479448690989,
                                40.75588088337317
                            ],
                            [
                                -73.98746579888976,
                                40.75552665972177
                            ],
                            [
                                -73.98713898534712,
                                40.75600057938439
                            ]
                        ],
                        [
                            [
                                -73.98869876520921,
                                40.758730073368184
                            ],
                            [
                                -73.99215607081902,
                                40.75836226305081
                            ],
                            [
                                -73.98789760901128,
                                40.756547160535064
                            ],
                            [
                                -73.98869876520921,
                                40.758730073368184
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "dd3aa5af-3e53-4130-807c-31db9570bda5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98286214507728,
                                40.76178848445602
                            ],
                            [
                                -73.98273110585984,
                                40.76182295302574
                            ],
                            [
                                -73.98263982848273,
                                40.76172140639767
                            ],
                            [
                                -73.98280705662049,
                                40.76171895957858
                            ],
                            [
                                -73.98286214507728,
                                40.76178848445602
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b378f3bf-fc4c-4c5c-aa10-6f98d6ab34ac"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98263982848273,
                                40.76172140639767
                            ],
                            [
                                -73.98273110585984,
                                40.76182295302574
                            ],
                            [
                                -73.98286214507728,
                                40.76178848445602
                            ],
                            [
                                -73.98466886714911,
                                40.76406867413528
                            ],
                            [
                                -73.98287311073454,
                                40.76333344784437
                            ],
                            [
                                -73.98287299745392,
                                40.76332386056578
                            ],
                            [
                                -73.98193724812002,
                                40.76295028296003
                            ],
                            [
                                -73.97906357830388,
                                40.761773732742476
                            ],
                            [
                                -73.98263982848273,
                                40.76172140639767
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "592d2346-8b35-4775-b5e1-07407d70a7b9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98148008386792,
                                40.76043118357584
                            ],
                            [
                                -73.97909118532918,
                                40.75777351966057
                            ],
                            [
                                -73.97931878038031,
                                40.75773484813263
                            ],
                            [
                                -73.98446302269333,
                                40.760045965368604
                            ],
                            [
                                -73.98148008386792,
                                40.76043118357584
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "78630e1c-c015-45e0-9d28-3ccf8b69c2f3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97931878038031,
                                40.75773484813263
                            ],
                            [
                                -73.97909118532918,
                                40.75777351966057
                            ],
                            [
                                -73.98148008386792,
                                40.76043118357584
                            ],
                            [
                                -73.97989102729187,
                                40.760636395134384
                            ],
                            [
                                -73.97846967152684,
                                40.757510147222284
                            ],
                            [
                                -73.97867815074135,
                                40.75744703700119
                            ],
                            [
                                -73.97931878038031,
                                40.75773484813263
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "cdd0c32e-f21d-41a5-8146-e18ccbdd6737"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98206125454743,
                                40.75426548742774
                            ],
                            [
                                -73.98479448690989,
                                40.75588088337317
                            ],
                            [
                                -73.98301657428746,
                                40.7561166397003
                            ],
                            [
                                -73.98206125454743,
                                40.75426548742774
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ab737254-da68-4ce1-90ad-3e1c519bf7d5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98096582380008,
                                40.753618065564865
                            ],
                            [
                                -73.97393328710028,
                                40.757179273477824
                            ],
                            [
                                -73.9713231638099,
                                40.75534681249886
                            ],
                            [
                                -73.9705003998135,
                                40.7552867321028
                            ],
                            [
                                -73.9705759852715,
                                40.75062001943348
                            ],
                            [
                                -73.9770149137046,
                                40.747027117972024
                            ],
                            [
                                -73.97577244898373,
                                40.746226749792875
                            ],
                            [
                                -73.97846902613752,
                                40.74511261693504
                            ],
                            [
                                -73.97810021062014,
                                40.74560886273422
                            ],
                            [
                                -73.98712831336154,
                                40.750465218563036
                            ],
                            [
                                -73.98434560120761,
                                40.74995087189079
                            ],
                            [
                                -73.98672521324241,
                                40.751046218370746
                            ],
                            [
                                -73.98444918684133,
                                40.75015621619326
                            ],
                            [
                                -73.98669311374235,
                                40.75108881832227
                            ],
                            [
                                -73.98273347561558,
                                40.75329193473071
                            ],
                            [
                                -73.98369287454237,
                                40.754555934411556
                            ],
                            [
                                -73.98210448962668,
                                40.753910187891556
                            ],
                            [
                                -73.98389297517022,
                                40.75488393445451
                            ],
                            [
                                -73.9819458534357,
                                40.754041871164745
                            ],
                            [
                                -73.98206125454743,
                                40.75426548742774
                            ],
                            [
                                -73.98096582380008,
                                40.753618065564865
                            ]
                        ],
                        [
                            [
                                -73.97386940434676,
                                40.75052343902135
                            ],
                            [
                                -73.97488284681937,
                                40.75304328487285
                            ],
                            [
                                -73.97556728401412,
                                40.7522107669164
                            ],
                            [
                                -73.97634892341419,
                                40.7534555290938
                            ],
                            [
                                -73.97685851970857,
                                40.75280826661237
                            ],
                            [
                                -73.9777945390818,
                                40.75320896081484
                            ],
                            [
                                -73.97831762358017,
                                40.75250836601852
                            ],
                            [
                                -73.97899992547949,
                                40.75299223978845
                            ],
                            [
                                -73.97918857618072,
                                40.75276957181321
                            ],
                            [
                                -73.97386940434676,
                                40.75052343902135
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "d4381241-67af-4baa-8aae-eb8e3a6530e7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97393328710028,
                                40.757179273477824
                            ],
                            [
                                -73.97044110443916,
                                40.758947680676705
                            ],
                            [
                                -73.9705003998135,
                                40.7552867321028
                            ],
                            [
                                -73.9713231638099,
                                40.75534681249886
                            ],
                            [
                                -73.97393328710028,
                                40.757179273477824
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9d551d87-2490-4b20-89cf-5c5ca272bcef"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97044110443916,
                                40.758947680676705
                            ],
                            [
                                -73.97393328710028,
                                40.757179273477824
                            ],
                            [
                                -73.97941572148852,
                                40.76102826678906
                            ],
                            [
                                -73.96976202543783,
                                40.75991640949285
                            ],
                            [
                                -73.97017893022517,
                                40.75931266802451
                            ],
                            [
                                -73.96670103357589,
                                40.75956386202114
                            ],
                            [
                                -73.96539970336913,
                                40.75941398227793
                            ],
                            [
                                -73.96456441271856,
                                40.75575659709341
                            ],
                            [
                                -73.96465247483432,
                                40.75571103435051
                            ],
                            [
                                -73.96507194540527,
                                40.75489033205098
                            ],
                            [
                                -73.9705003998135,
                                40.7552867321028
                            ],
                            [
                                -73.97044110443916,
                                40.758947680676705
                            ]
                        ],
                        [
                            [
                                -73.96924072345422,
                                40.75792410093769
                            ],
                            [
                                -73.96978615983349,
                                40.757866721193494
                            ],
                            [
                                -73.96813138582503,
                                40.757174918694375
                            ],
                            [
                                -73.96924072345422,
                                40.75792410093769
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "050cf605-9834-4ab8-b460-b293cd025e07"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9705759852715,
                                40.75062001943348
                            ],
                            [
                                -73.9705003998135,
                                40.7552867321028
                            ],
                            [
                                -73.96507194540527,
                                40.75489033205098
                            ],
                            [
                                -73.96577809948906,
                                40.75350872790392
                            ],
                            [
                                -73.9664358714218,
                                40.75325563556916
                            ],
                            [
                                -73.96624858705856,
                                40.75303469378886
                            ],
                            [
                                -73.9705759852715,
                                40.75062001943348
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "8ef2a123-725c-4bf4-b9c5-ac3abf6895d7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9705759852715,
                                40.75062001943348
                            ],
                            [
                                -73.97061261258983,
                                40.74835861657794
                            ],
                            [
                                -73.97275544043895,
                                40.74747327387153
                            ],
                            [
                                -73.97279057530123,
                                40.74750403481582
                            ],
                            [
                                -73.97283840390084,
                                40.74743899622486
                            ],
                            [
                                -73.97577244898373,
                                40.746226749792875
                            ],
                            [
                                -73.9770149137046,
                                40.747027117972024
                            ],
                            [
                                -73.9705759852715,
                                40.75062001943348
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "182e58ec-97b3-437f-97bd-5918837da004"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97061261258983,
                                40.74835861657794
                            ],
                            [
                                -73.9705759852715,
                                40.75062001943348
                            ],
                            [
                                -73.96624858705856,
                                40.75303469378886
                            ],
                            [
                                -73.96610622390091,
                                40.75286674613452
                            ],
                            [
                                -73.967482005424,
                                40.75017500292803
                            ],
                            [
                                -73.96937246857716,
                                40.74912413588355
                            ],
                            [
                                -73.96837327945222,
                                40.74843120802288
                            ],
                            [
                                -73.96891556272048,
                                40.74737022030147
                            ],
                            [
                                -73.96898472336119,
                                40.74728116471046
                            ],
                            [
                                -73.96927242410229,
                                40.74692996350243
                            ],
                            [
                                -73.96928782661615,
                                40.7469124600843
                            ],
                            [
                                -73.96942761076257,
                                40.74677463284085
                            ],
                            [
                                -73.9702099768997,
                                40.748020334544364
                            ],
                            [
                                -73.97147385799543,
                                40.746351234860164
                            ],
                            [
                                -73.97275544043895,
                                40.74747327387153
                            ],
                            [
                                -73.97061261258983,
                                40.74835861657794
                            ]
                        ],
                        [
                            [
                                -73.96969117826357,
                                40.74862483320381
                            ],
                            [
                                -73.96965495639468,
                                40.74858205949539
                            ],
                            [
                                -73.96915804880369,
                                40.74839796863181
                            ],
                            [
                                -73.96969117826357,
                                40.74862483320381
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "080a359a-32cc-4ffb-bfaf-84258a942999"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97275544043895,
                                40.74747327387153
                            ],
                            [
                                -73.97283840390084,
                                40.74743899622486
                            ],
                            [
                                -73.97279057530123,
                                40.74750403481582
                            ],
                            [
                                -73.97275544043895,
                                40.74747327387153
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "0b75d664-e148-4835-b0e1-2c9764715ef8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97283840390084,
                                40.74743899622486
                            ],
                            [
                                -73.97275544043895,
                                40.74747327387153
                            ],
                            [
                                -73.97147385799543,
                                40.746351234860164
                            ],
                            [
                                -73.9702099768997,
                                40.748020334544364
                            ],
                            [
                                -73.96942761076257,
                                40.74677463284085
                            ],
                            [
                                -73.96928782661615,
                                40.7469124600843
                            ],
                            [
                                -73.96927242410229,
                                40.74692996350243
                            ],
                            [
                                -73.96898472336119,
                                40.74728116471046
                            ],
                            [
                                -73.96891556272048,
                                40.74737022030147
                            ],
                            [
                                -73.97361572090445,
                                40.73817426974628
                            ],
                            [
                                -73.97415650689679,
                                40.73817385048541
                            ],
                            [
                                -73.9738094065805,
                                40.74137388002877
                            ],
                            [
                                -73.97146524315187,
                                40.74345214345516
                            ],
                            [
                                -73.97344560858161,
                                40.74472785088287
                            ],
                            [
                                -73.97320571374348,
                                40.74693951861501
                            ],
                            [
                                -73.97283840390084,
                                40.74743899622486
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a9be9326-15dc-4c51-bcf0-7b93abf8dc68"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97577244898373,
                                40.746226749792875
                            ],
                            [
                                -73.97283840390084,
                                40.74743899622486
                            ],
                            [
                                -73.97320571374348,
                                40.74693951861501
                            ],
                            [
                                -73.97344560858161,
                                40.74472785088287
                            ],
                            [
                                -73.97577244898373,
                                40.746226749792875
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "31e18b63-8443-43b1-8fa5-f8838c6a4e90"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97577244898373,
                                40.746226749792875
                            ],
                            [
                                -73.97344560858161,
                                40.74472785088287
                            ],
                            [
                                -73.9738094065805,
                                40.74137388002877
                            ],
                            [
                                -73.97609113970387,
                                40.739350965396994
                            ],
                            [
                                -73.98016593101151,
                                40.74282941062822
                            ],
                            [
                                -73.97846902613752,
                                40.74511261693504
                            ],
                            [
                                -73.97577244898373,
                                40.746226749792875
                            ]
                        ],
                        [
                            [
                                -73.97621071670942,
                                40.740743811856596
                            ],
                            [
                                -73.97606529293965,
                                40.74052839512751
                            ],
                            [
                                -73.97563776044142,
                                40.740862762195626
                            ],
                            [
                                -73.97621071670942,
                                40.740743811856596
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "1ca7db7b-3077-4c84-bea8-e2a074a836a8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98016593101151,
                                40.74282941062822
                            ],
                            [
                                -73.98141481345719,
                                40.74389551901502
                            ],
                            [
                                -73.97846902613752,
                                40.74511261693504
                            ],
                            [
                                -73.98016593101151,
                                40.74282941062822
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "57c11162-6bb3-4662-bfe6-efda788d1e36"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98016593101151,
                                40.74282941062822
                            ],
                            [
                                -73.97609113970387,
                                40.739350965396994
                            ],
                            [
                                -73.97666434424549,
                                40.738842779824196
                            ],
                            [
                                -73.97678886883301,
                                40.73904786327658
                            ],
                            [
                                -73.97893003385543,
                                40.73792844585644
                            ],
                            [
                                -73.97832575602843,
                                40.73693605941154
                            ],
                            [
                                -73.9779574216091,
                                40.7374363673773
                            ],
                            [
                                -73.97573318757574,
                                40.7373092286729
                            ],
                            [
                                -73.97625718827103,
                                40.73817222186798
                            ],
                            [
                                -73.97471171549164,
                                40.73817342004298
                            ],
                            [
                                -73.97420360165876,
                                40.7377396687155
                            ],
                            [
                                -73.97430213098738,
                                40.73683129511944
                            ],
                            [
                                -73.97441601302747,
                                40.73660848270149
                            ],
                            [
                                -73.9747166104517,
                                40.733727198478846
                            ],
                            [
                                -73.97977807132354,
                                40.734756520519944
                            ],
                            [
                                -73.98360201354464,
                                40.738206119464
                            ],
                            [
                                -73.98016593101151,
                                40.74282941062822
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "6774ac59-ce9b-47e8-a4ca-6dec2ae7399b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97609113970387,
                                40.739350965396994
                            ],
                            [
                                -73.97471171549164,
                                40.73817342004298
                            ],
                            [
                                -73.97625718827103,
                                40.73817222186798
                            ],
                            [
                                -73.97666434424549,
                                40.738842779824196
                            ],
                            [
                                -73.97609113970387,
                                40.739350965396994
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "bd170254-fa2d-4c26-bc42-ca9f03f099c2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97471171549164,
                                40.73817342004298
                            ],
                            [
                                -73.97609113970387,
                                40.739350965396994
                            ],
                            [
                                -73.9738094065805,
                                40.74137388002877
                            ],
                            [
                                -73.97415650689679,
                                40.73817385048541
                            ],
                            [
                                -73.97471171549164,
                                40.73817342004298
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "69f5daa3-16ce-44cd-acaa-4e749729e253"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97420360165876,
                                40.7377396687155
                            ],
                            [
                                -73.97471171549164,
                                40.73817342004298
                            ],
                            [
                                -73.97415650689679,
                                40.73817385048541
                            ],
                            [
                                -73.97420360165876,
                                40.7377396687155
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "6e60d0fa-1fbe-4864-b4a0-406db4a4b855"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97420360165876,
                                40.7377396687155
                            ],
                            [
                                -73.97394895541402,
                                40.73752228997171
                            ],
                            [
                                -73.97430213098738,
                                40.73683129511944
                            ],
                            [
                                -73.97420360165876,
                                40.7377396687155
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "854f038c-4796-43b2-a8d7-1e4a6d296cef"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97394895541402,
                                40.73752228997171
                            ],
                            [
                                -73.97420360165876,
                                40.7377396687155
                            ],
                            [
                                -73.97415650689679,
                                40.73817385048541
                            ],
                            [
                                -73.97361572090445,
                                40.73817426974628
                            ],
                            [
                                -73.97394895541402,
                                40.73752228997171
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "89a715c3-e5bb-4fc1-8ca5-caba0c64a328"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97394895541402,
                                40.73752228997171
                            ],
                            [
                                -73.97346437554471,
                                40.7371086284101
                            ],
                            [
                                -73.97429638797433,
                                40.73364174009656
                            ],
                            [
                                -73.97464050445403,
                                40.73371172121194
                            ],
                            [
                                -73.97430213098738,
                                40.73683129511944
                            ],
                            [
                                -73.97394895541402,
                                40.73752228997171
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f11902ec-538a-4397-a413-d11ca9822bfd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97464050445403,
                                40.73371172121194
                            ],
                            [
                                -73.97429638797433,
                                40.73364174009656
                            ],
                            [
                                -73.97346437554471,
                                40.7371086284101
                            ],
                            [
                                -73.97394895541402,
                                40.73752228997171
                            ],
                            [
                                -73.97361572090445,
                                40.73817426974628
                            ],
                            [
                                -73.97263867288164,
                                40.73817502723262
                            ],
                            [
                                -73.97160308963221,
                                40.728231999846436
                            ],
                            [
                                -73.97610934035781,
                                40.72017005021375
                            ],
                            [
                                -73.97539909034853,
                                40.72671807367057
                            ],
                            [
                                -73.9719532789811,
                                40.727697733058925
                            ],
                            [
                                -73.97301988300357,
                                40.72865992231203
                            ],
                            [
                                -73.9728121968518,
                                40.729623845940615
                            ],
                            [
                                -73.97377733881365,
                                40.72934322729195
                            ],
                            [
                                -73.97499518047223,
                                40.73044184883396
                            ],
                            [
                                -73.97464050445403,
                                40.73371172121194
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "6632769a-2a78-4cb5-9471-835c9d07082c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97464050445403,
                                40.73371172121194
                            ],
                            [
                                -73.9747166104517,
                                40.733727198478846
                            ],
                            [
                                -73.97441601302747,
                                40.73660848270149
                            ],
                            [
                                -73.97430213098738,
                                40.73683129511944
                            ],
                            [
                                -73.97464050445403,
                                40.73371172121194
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "4fe81d8e-c767-4792-aeb0-da2daf231ff0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9747166104517,
                                40.733727198478846
                            ],
                            [
                                -73.97464050445403,
                                40.73371172121194
                            ],
                            [
                                -73.97499518047223,
                                40.73044184883396
                            ],
                            [
                                -73.97505384213386,
                                40.73049476783595
                            ],
                            [
                                -73.9747166104517,
                                40.733727198478846
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "fb1f5a2d-6803-4300-a078-e47a43130ad5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97977807132354,
                                40.734756520519944
                            ],
                            [
                                -73.9747166104517,
                                40.733727198478846
                            ],
                            [
                                -73.97505384213386,
                                40.73049476783595
                            ],
                            [
                                -73.97977807132354,
                                40.734756520519944
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "0a540ad0-1085-4351-9cb1-48bebd61714f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98083502057389,
                                40.733300771460684
                            ],
                            [
                                -73.97978207512263,
                                40.734757334751
                            ],
                            [
                                -73.97977807132354,
                                40.734756520519944
                            ],
                            [
                                -73.97505384213386,
                                40.73049476783595
                            ],
                            [
                                -73.97544934614154,
                                40.726703785726684
                            ],
                            [
                                -73.9810791130355,
                                40.72510321813786
                            ],
                            [
                                -73.97824153898306,
                                40.718552453830334
                            ],
                            [
                                -73.98477701263121,
                                40.72416641671578
                            ],
                            [
                                -73.9791306039291,
                                40.72647787908145
                            ],
                            [
                                -73.97905035061132,
                                40.726444058763896
                            ],
                            [
                                -73.9791305244893,
                                40.726477911601606
                            ],
                            [
                                -73.97600960996846,
                                40.7277555159767
                            ],
                            [
                                -73.97655359925722,
                                40.72824990598887
                            ],
                            [
                                -73.97565877203591,
                                40.72829979551522
                            ],
                            [
                                -73.97564712088668,
                                40.7283154671568
                            ],
                            [
                                -73.97562242260626,
                                40.72835070025574
                            ],
                            [
                                -73.97575031485495,
                                40.72835662077059
                            ],
                            [
                                -73.97554267017533,
                                40.728499868886324
                            ],
                            [
                                -73.97565591180005,
                                40.72879227445754
                            ],
                            [
                                -73.97782061358123,
                                40.72957841873689
                            ],
                            [
                                -73.97712160481424,
                                40.732261652286205
                            ],
                            [
                                -73.98083502057389,
                                40.733300771460684
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "099ada56-a92b-420e-ab7a-df157ad542e5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98134101416164,
                                40.73260081907077
                            ],
                            [
                                -73.97655359925722,
                                40.72824990598887
                            ],
                            [
                                -73.98500214391615,
                                40.72777887216095
                            ],
                            [
                                -73.99983279475937,
                                40.738616888132334
                            ],
                            [
                                -73.98083502057389,
                                40.733300771460684
                            ],
                            [
                                -73.98134101416164,
                                40.73260081907077
                            ]
                        ],
                        [
                            [
                                -73.98055437833196,
                                40.73049383299144
                            ],
                            [
                                -73.98095987515626,
                                40.73083719798922
                            ],
                            [
                                -73.98197162789783,
                                40.731265983269765
                            ],
                            [
                                -73.98252270120553,
                                40.731343887309215
                            ],
                            [
                                -73.98055437833196,
                                40.73049383299144
                            ]
                        ],
                        [
                            [
                                -73.98631894386695,
                                40.73293464057805
                            ],
                            [
                                -73.98559338359513,
                                40.73262372224362
                            ],
                            [
                                -73.98631885583205,
                                40.73293475970175
                            ],
                            [
                                -73.98665145886264,
                                40.73307496678096
                            ],
                            [
                                -73.98631894386695,
                                40.73293464057805
                            ]
                        ],
                        [
                            [
                                -73.9897891241314,
                                40.73448933706499
                            ],
                            [
                                -73.98985220742392,
                                40.73409506814385
                            ],
                            [
                                -73.98934694460247,
                                40.73431741882122
                            ],
                            [
                                -73.98947634152927,
                                40.73440093976056
                            ],
                            [
                                -73.9897891241314,
                                40.73448933706499
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "e1d3a6d6-8dd1-4e3b-b822-bd27ed527c07"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97655359925722,
                                40.72824990598887
                            ],
                            [
                                -73.98134101416164,
                                40.73260081907077
                            ],
                            [
                                -73.98083502057389,
                                40.733300771460684
                            ],
                            [
                                -73.97712160481424,
                                40.732261652286205
                            ],
                            [
                                -73.97782061358123,
                                40.72957841873689
                            ],
                            [
                                -73.97565591180005,
                                40.72879227445754
                            ],
                            [
                                -73.97554267017533,
                                40.728499868886324
                            ],
                            [
                                -73.97575031485495,
                                40.72835662077059
                            ],
                            [
                                -73.97562242260626,
                                40.72835070025574
                            ],
                            [
                                -73.97564712088668,
                                40.7283154671568
                            ],
                            [
                                -73.97565877203591,
                                40.72829979551522
                            ],
                            [
                                -73.97655359925722,
                                40.72824990598887
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "d2be8a72-628c-4114-a3a7-115a541730b3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9791305244893,
                                40.726477911601606
                            ],
                            [
                                -73.9791306039291,
                                40.72647787908145
                            ],
                            [
                                -73.97998986173606,
                                40.7268399871251
                            ],
                            [
                                -73.97959004406098,
                                40.72667194040341
                            ],
                            [
                                -73.9791305244893,
                                40.726477911601606
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "5cea17e2-a9dd-40c2-a897-85d19605a7af"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9791306039291,
                                40.72647787908145
                            ],
                            [
                                -73.9791305244893,
                                40.726477911601606
                            ],
                            [
                                -73.97905035061132,
                                40.726444058763896
                            ],
                            [
                                -73.9791306039291,
                                40.72647787908145
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "bcce2bd2-7994-418a-86fe-b410e3886782"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97824153898306,
                                40.718552453830334
                            ],
                            [
                                -73.97741186175604,
                                40.717839762232124
                            ],
                            [
                                -73.97770536836366,
                                40.71731466151501
                            ],
                            [
                                -73.97824153898306,
                                40.718552453830334
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "0330afc4-2b93-42d0-90f2-03f3bd24aa05"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97741186175604,
                                40.717839762232124
                            ],
                            [
                                -73.97824153898306,
                                40.718552453830334
                            ],
                            [
                                -73.9810791130355,
                                40.72510321813786
                            ],
                            [
                                -73.97544934614154,
                                40.726703785726684
                            ],
                            [
                                -73.97613596429932,
                                40.7201224184044
                            ],
                            [
                                -73.97741186175604,
                                40.717839762232124
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "abb1f848-5ddc-4e17-a592-8e405919bd55"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97741186175604,
                                40.717839762232124
                            ],
                            [
                                -73.97645945993857,
                                40.7170216503793
                            ],
                            [
                                -73.97663910730952,
                                40.71529969571873
                            ],
                            [
                                -73.976867144097,
                                40.71537955444599
                            ],
                            [
                                -73.97770536836366,
                                40.71731466151501
                            ],
                            [
                                -73.97741186175604,
                                40.717839762232124
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a6584c4f-5666-4861-a9c4-879d49510485"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97645945993857,
                                40.7170216503793
                            ],
                            [
                                -73.97741186175604,
                                40.717839762232124
                            ],
                            [
                                -73.97613596429932,
                                40.7201224184044
                            ],
                            [
                                -73.97645945993857,
                                40.7170216503793
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ee1dea6e-e6dd-4aba-87ad-e6a1d8e95188"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97645945993857,
                                40.7170216503793
                            ],
                            [
                                -73.97645157516452,
                                40.71701487736923
                            ],
                            [
                                -73.9766376717564,
                                40.71529919298648
                            ],
                            [
                                -73.97663910730952,
                                40.71529969571873
                            ],
                            [
                                -73.97645945993857,
                                40.7170216503793
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c1595904-6b07-4e6a-bdc8-0b32328d3ae5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97645157516452,
                                40.71701487736923
                            ],
                            [
                                -73.97645945993857,
                                40.7170216503793
                            ],
                            [
                                -73.97613596429932,
                                40.7201224184044
                            ],
                            [
                                -73.97610934035781,
                                40.72017005021375
                            ],
                            [
                                -73.97645157516452,
                                40.71701487736923
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8ae153c7-87e5-4f29-9c83-2de47b863318"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97645157516452,
                                40.71701487736923
                            ],
                            [
                                -73.97588686776129,
                                40.71652979448564
                            ],
                            [
                                -73.9765234504882,
                                40.71525919257599
                            ],
                            [
                                -73.9766376717564,
                                40.71529919298648
                            ],
                            [
                                -73.97645157516452,
                                40.71701487736923
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "eff2f874-5a68-40e8-94c6-76bd88cb6ca5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9765234504882,
                                40.71525919257599
                            ],
                            [
                                -73.97588686776129,
                                40.71652979448564
                            ],
                            [
                                -73.97645157516452,
                                40.71701487736923
                            ],
                            [
                                -73.97610934035781,
                                40.72017005021375
                            ],
                            [
                                -73.97160308963221,
                                40.728231999846436
                            ],
                            [
                                -73.97263867288164,
                                40.73817502723262
                            ],
                            [
                                -73.97361572090445,
                                40.73817426974628
                            ],
                            [
                                -73.96891556272048,
                                40.74737022030147
                            ],
                            [
                                -73.96864752544793,
                                40.74771536195252
                            ],
                            [
                                -73.96812603096762,
                                40.74825974362319
                            ],
                            [
                                -73.96837327945222,
                                40.74843120802288
                            ],
                            [
                                -73.967482005424,
                                40.75017500292803
                            ],
                            [
                                -73.96499595286973,
                                40.7515569450705
                            ],
                            [
                                -73.96610622390091,
                                40.75286674613452
                            ],
                            [
                                -73.96592933305547,
                                40.753212836500985
                            ],
                            [
                                -73.9645571209349,
                                40.75397852647326
                            ],
                            [
                                -73.96183725085363,
                                40.755025056789975
                            ],
                            [
                                -73.96242496406468,
                                40.75516826318406
                            ],
                            [
                                -73.96041926287259,
                                40.75628743803111
                            ],
                            [
                                -73.96027093754446,
                                40.75653812735901
                            ],
                            [
                                -73.96098895818332,
                                40.756716281595
                            ],
                            [
                                -73.95659073026826,
                                40.75792312925352
                            ],
                            [
                                -73.95659073907602,
                                40.75792318315695
                            ],
                            [
                                -73.95543857025328,
                                40.757419060218545
                            ],
                            [
                                -73.95933301014958,
                                40.75290428013759
                            ],
                            [
                                -73.9537279714189,
                                40.756100518718654
                            ],
                            [
                                -73.94912008344735,
                                40.75465425633863
                            ],
                            [
                                -73.95619528379535,
                                40.7496885756938
                            ],
                            [
                                -73.95590052872657,
                                40.74945114421136
                            ],
                            [
                                -73.95692255016391,
                                40.74887348088607
                            ],
                            [
                                -73.95703992384293,
                                40.74892896591773
                            ],
                            [
                                -73.95714599990826,
                                40.74874718341553
                            ],
                            [
                                -73.95772492158643,
                                40.74841996736166
                            ],
                            [
                                -73.96075753157767,
                                40.743227043732446
                            ],
                            [
                                -73.96050161067427,
                                40.74311560182881
                            ],
                            [
                                -73.9611064652052,
                                40.74196013593947
                            ],
                            [
                                -73.96240938246821,
                                40.73972732725202
                            ],
                            [
                                -73.96227010554946,
                                40.73973721016729
                            ],
                            [
                                -73.96248115246951,
                                40.739334042951555
                            ],
                            [
                                -73.96242029457078,
                                40.73933619037482
                            ],
                            [
                                -73.96251381790819,
                                40.73915525796691
                            ],
                            [
                                -73.96057571348021,
                                40.7394012780042
                            ],
                            [
                                -73.95339646771134,
                                40.73965460386243
                            ],
                            [
                                -73.95499444728242,
                                40.73836720353201
                            ],
                            [
                                -73.96199746628287,
                                40.73449435443757
                            ],
                            [
                                -73.96146942606231,
                                40.73315068514114
                            ],
                            [
                                -73.96368701048273,
                                40.731364104782244
                            ],
                            [
                                -73.961194081757,
                                40.72821013487947
                            ],
                            [
                                -73.95946190714444,
                                40.72536327197997
                            ],
                            [
                                -73.96562183158606,
                                40.720703555190774
                            ],
                            [
                                -73.9661886486536,
                                40.71594083084083
                            ],
                            [
                                -73.97014473196509,
                                40.71302535851573
                            ],
                            [
                                -73.9765234504882,
                                40.71525919257599
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3f2c36f9-b42f-4ac3-9058-a4de3d4ac94f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9765234504882,
                                40.71525919257599
                            ],
                            [
                                -73.97667477130341,
                                40.71495716032057
                            ],
                            [
                                -73.9766376717564,
                                40.71529919298648
                            ],
                            [
                                -73.9765234504882,
                                40.71525919257599
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "7456e3da-587f-44d7-ac28-d1ec6ffd6a5b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97667477130341,
                                40.71495716032057
                            ],
                            [
                                -73.9765234504882,
                                40.71525919257599
                            ],
                            [
                                -73.97014473196509,
                                40.71302535851573
                            ],
                            [
                                -73.97023346262068,
                                40.7129599676353
                            ],
                            [
                                -73.97667665488098,
                                40.714939795012675
                            ],
                            [
                                -73.97667477130341,
                                40.71495716032057
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "936cbd5a-bc68-4656-893b-cd646c298581"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97667477130341,
                                40.71495716032057
                            ],
                            [
                                -73.97667486210932,
                                40.714956979074444
                            ],
                            [
                                -73.97663910730952,
                                40.71529969571873
                            ],
                            [
                                -73.9766376717564,
                                40.71529919298648
                            ],
                            [
                                -73.97667477130341,
                                40.71495716032057
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "3d6e9be3-8e8b-4d36-afba-d464565072e6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97667486210932,
                                40.714956979074444
                            ],
                            [
                                -73.97667477130341,
                                40.71495716032057
                            ],
                            [
                                -73.97667665488098,
                                40.714939795012675
                            ],
                            [
                                -73.97667486210932,
                                40.714956979074444
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "bd415787-3603-4de8-8edf-82dcb0cdfc55"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97667486210932,
                                40.714956979074444
                            ],
                            [
                                -73.97667981565999,
                                40.71494709192123
                            ],
                            [
                                -73.976867144097,
                                40.71537955444599
                            ],
                            [
                                -73.97663910730952,
                                40.71529969571873
                            ],
                            [
                                -73.97667486210932,
                                40.714956979074444
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c2bd68cc-9c7f-4139-aca1-d77188e5a538"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97667981565999,
                                40.71494709192123
                            ],
                            [
                                -73.97667486210932,
                                40.714956979074444
                            ],
                            [
                                -73.97667665488098,
                                40.714939795012675
                            ],
                            [
                                -73.97667981565999,
                                40.71494709192123
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "82a4b3ca-be2b-48b2-b689-9a6258bbbff2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97681578846223,
                                40.71467569388468
                            ],
                            [
                                -73.97667981565999,
                                40.71494709192123
                            ],
                            [
                                -73.97667665488098,
                                40.714939795012675
                            ],
                            [
                                -73.97044370775684,
                                40.71301133359369
                            ],
                            [
                                -73.9706108024119,
                                40.712984631853246
                            ],
                            [
                                -73.97681578846223,
                                40.71467569388468
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c5b29370-a868-45bd-b5a3-92a29ef29ab0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97809283623305,
                                40.712126741065646
                            ],
                            [
                                -73.9849810447632,
                                40.712029527640354
                            ],
                            [
                                -73.9837924222489,
                                40.71321206574088
                            ],
                            [
                                -73.9809571559559,
                                40.71346674684575
                            ],
                            [
                                -73.98088506615434,
                                40.712823256963894
                            ],
                            [
                                -73.9791312645639,
                                40.71530673699879
                            ],
                            [
                                -73.97681578846223,
                                40.71467569388468
                            ],
                            [
                                -73.97809283623305,
                                40.712126741065646
                            ]
                        ],
                        [
                            [
                                -73.97794516499538,
                                40.71272873942712
                            ],
                            [
                                -73.97769478566107,
                                40.71322380936986
                            ],
                            [
                                -73.97878301647435,
                                40.71351517490267
                            ],
                            [
                                -73.97794516499538,
                                40.71272873942712
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "778c64c6-3e9f-4369-8dee-9bc0a12d5bae"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97809283623305,
                                40.712126741065646
                            ],
                            [
                                -73.97681578846223,
                                40.71467569388468
                            ],
                            [
                                -73.9706108024119,
                                40.712984631853246
                            ],
                            [
                                -73.97577458742752,
                                40.712159458557586
                            ],
                            [
                                -73.97809283623305,
                                40.712126741065646
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "31a02c46-9967-4781-9b52-deecee624058"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97809283623305,
                                40.712126741065646
                            ],
                            [
                                -73.97827677254662,
                                40.71175960915742
                            ],
                            [
                                -73.98972727366169,
                                40.709929818081015
                            ],
                            [
                                -73.98983042597322,
                                40.710054361152835
                            ],
                            [
                                -73.98928734946821,
                                40.710120474348635
                            ],
                            [
                                -73.9897287268016,
                                40.71012876070917
                            ],
                            [
                                -73.98908535968332,
                                40.71020586078772
                            ],
                            [
                                -73.9894442294528,
                                40.71054865769008
                            ],
                            [
                                -73.98861335339201,
                                40.7106296910439
                            ],
                            [
                                -73.98953652955161,
                                40.711171058021705
                            ],
                            [
                                -73.98642762384665,
                                40.71059035341893
                            ],
                            [
                                -73.9849810447632,
                                40.712029527640354
                            ],
                            [
                                -73.97809283623305,
                                40.712126741065646
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a819a1cd-16d8-429c-9bd5-5a596d435c6d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97827677254662,
                                40.71175960915742
                            ],
                            [
                                -73.97809283623305,
                                40.712126741065646
                            ],
                            [
                                -73.97577458742752,
                                40.712159458557586
                            ],
                            [
                                -73.97827677254662,
                                40.71175960915742
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "93ad0739-07a6-46b3-9568-69f430b8a6a2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97827677254662,
                                40.71175960915742
                            ],
                            [
                                -73.97891162198538,
                                40.71049246684407
                            ],
                            [
                                -73.99181915567263,
                                40.70901618044565
                            ],
                            [
                                -73.99211603380513,
                                40.709548094001846
                            ],
                            [
                                -73.98994198114677,
                                40.70989550780618
                            ],
                            [
                                -73.98992065276038,
                                40.709875882910666
                            ],
                            [
                                -73.98970798829495,
                                40.709906533495555
                            ],
                            [
                                -73.98972727366169,
                                40.709929818081015
                            ],
                            [
                                -73.97827677254662,
                                40.71175960915742
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4a1c38b7-9d02-43a3-a5bb-0cbf736979e1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99181915567263,
                                40.70901618044565
                            ],
                            [
                                -73.97891162198538,
                                40.71049246684407
                            ],
                            [
                                -73.97827677254662,
                                40.71175960915742
                            ],
                            [
                                -73.97577458742752,
                                40.712159458557586
                            ],
                            [
                                -73.96999685521855,
                                40.71224099980826
                            ],
                            [
                                -73.96913720850549,
                                40.70978121078506
                            ],
                            [
                                -73.96965096338324,
                                40.70995294378738
                            ],
                            [
                                -73.97026522688294,
                                40.70876116087873
                            ],
                            [
                                -73.96974574656056,
                                40.70540393671473
                            ],
                            [
                                -73.97251645439229,
                                40.70883674129107
                            ],
                            [
                                -73.97076417068978,
                                40.70438757943334
                            ],
                            [
                                -73.97276108989867,
                                40.70513369733053
                            ],
                            [
                                -73.96882765922197,
                                40.699470651342715
                            ],
                            [
                                -73.97586568010422,
                                40.70122884383257
                            ],
                            [
                                -73.97633543341358,
                                40.70430353667821
                            ],
                            [
                                -73.97847284176765,
                                40.701880147960985
                            ],
                            [
                                -73.98066982981886,
                                40.70242898519187
                            ],
                            [
                                -73.9809759397285,
                                40.70479794944961
                            ],
                            [
                                -73.97934149010334,
                                40.70474550262944
                            ],
                            [
                                -73.99048760129116,
                                40.706630447888195
                            ],
                            [
                                -73.99181915567263,
                                40.70901618044565
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "262533d7-0d9c-4deb-862d-fed94581a4d1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99181915567263,
                                40.70901618044565
                            ],
                            [
                                -73.99762834813727,
                                40.70835175980347
                            ],
                            [
                                -73.99880453679437,
                                40.70891587231729
                            ],
                            [
                                -73.99827723894072,
                                40.71027300550401
                            ],
                            [
                                -73.99445394282495,
                                40.71189583643981
                            ],
                            [
                                -73.99215126206643,
                                40.71192833421959
                            ],
                            [
                                -73.98994198114677,
                                40.70989550780618
                            ],
                            [
                                -73.99211603380513,
                                40.709548094001846
                            ],
                            [
                                -73.99181915567263,
                                40.70901618044565
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "eadc7987-2f03-4779-8d1c-1d2bdd676e29"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99762834813727,
                                40.70835175980347
                            ],
                            [
                                -73.99181915567263,
                                40.70901618044565
                            ],
                            [
                                -73.99048760129116,
                                40.706630447888195
                            ],
                            [
                                -73.99597383516273,
                                40.707558237890495
                            ],
                            [
                                -73.99762834813727,
                                40.70835175980347
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e34dd206-aaed-4889-a12d-5c2041bee3cb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99762834813727,
                                40.70835175980347
                            ],
                            [
                                -73.99943670817731,
                                40.70814493041257
                            ],
                            [
                                -73.99974964507443,
                                40.708393330410985
                            ],
                            [
                                -73.99896070938323,
                                40.70851392301722
                            ],
                            [
                                -73.99880453679437,
                                40.70891587231729
                            ],
                            [
                                -73.99762834813727,
                                40.70835175980347
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "09ea1376-0ff3-4600-9bc5-bf4a2efcc93d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99943670817731,
                                40.70814493041257
                            ],
                            [
                                -73.99762834813727,
                                40.70835175980347
                            ],
                            [
                                -73.99597383516273,
                                40.707558237890495
                            ],
                            [
                                -73.99943498264726,
                                40.70814356073815
                            ],
                            [
                                -73.99943670817731,
                                40.70814493041257
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "57a622f2-af7a-44cb-b9ef-e3d66b24da27"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99943670817731,
                                40.70814493041257
                            ],
                            [
                                -73.99944051035627,
                                40.708144495542115
                            ],
                            [
                                -73.99943498264726,
                                40.70814356073815
                            ],
                            [
                                -73.99560256222684,
                                40.7051014994309
                            ],
                            [
                                -73.99560890125056,
                                40.70510283254795
                            ],
                            [
                                -73.99944060467938,
                                40.708144430850886
                            ],
                            [
                                -73.99944911686256,
                                40.70814386258114
                            ],
                            [
                                -73.99976007820133,
                                40.70839154627428
                            ],
                            [
                                -73.9997596631377,
                                40.708391799101946
                            ],
                            [
                                -73.99974964507443,
                                40.708393330410985
                            ],
                            [
                                -73.99943670817731,
                                40.70814493041257
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 750
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "2b701ab5-40dd-4c08-ab02-3243b4983aa2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99943498264726,
                                40.70814356073815
                            ],
                            [
                                -73.99944051035627,
                                40.708144495542115
                            ],
                            [
                                -73.99943670817731,
                                40.70814493041257
                            ],
                            [
                                -73.99943498264726,
                                40.70814356073815
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 750
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "11991bdd-e522-4a87-9340-fbbac82a5518"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99943498264726,
                                40.70814356073815
                            ],
                            [
                                -73.99597383516273,
                                40.707558237890495
                            ],
                            [
                                -73.98714133028814,
                                40.70332207518137
                            ],
                            [
                                -73.99560256222684,
                                40.7051014994309
                            ],
                            [
                                -73.99943498264726,
                                40.70814356073815
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "abc9e0a8-4bd7-4bec-b1a0-7b46d777b678"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99597383516273,
                                40.707558237890495
                            ],
                            [
                                -73.99048760129116,
                                40.706630447888195
                            ],
                            [
                                -73.98935336576012,
                                40.70459824952523
                            ],
                            [
                                -73.98066982981886,
                                40.70242898519187
                            ],
                            [
                                -73.98060768208158,
                                40.70194802798105
                            ],
                            [
                                -73.98714133028814,
                                40.70332207518137
                            ],
                            [
                                -73.99597383516273,
                                40.707558237890495
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a6b79947-71a1-47e3-907d-3ddba0834f33"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99048760129116,
                                40.706630447888195
                            ],
                            [
                                -73.97934149010334,
                                40.70474550262944
                            ],
                            [
                                -73.9809759397285,
                                40.70479794944961
                            ],
                            [
                                -73.98066982981886,
                                40.70242898519187
                            ],
                            [
                                -73.98935336576012,
                                40.70459824952523
                            ],
                            [
                                -73.99048760129116,
                                40.706630447888195
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b9ae05c7-fc7b-4c96-a736-3b2e1e671ee6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98060768208158,
                                40.70194802798105
                            ],
                            [
                                -73.98066982981886,
                                40.70242898519187
                            ],
                            [
                                -73.97847284176765,
                                40.701880147960985
                            ],
                            [
                                -73.97875636493556,
                                40.701558690039825
                            ],
                            [
                                -73.98060768208158,
                                40.70194802798105
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "93bc9cf7-de79-47c9-bb54-b6a73d3c1fef"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98060768208158,
                                40.70194802798105
                            ],
                            [
                                -73.98036529590486,
                                40.70007221745949
                            ],
                            [
                                -73.98714133028814,
                                40.70332207518137
                            ],
                            [
                                -73.98060768208158,
                                40.70194802798105
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "2c1a084f-a0d8-4da7-af8e-c0f5381cc514"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98036529590486,
                                40.70007221745949
                            ],
                            [
                                -73.98060768208158,
                                40.70194802798105
                            ],
                            [
                                -73.97875636493556,
                                40.701558690039825
                            ],
                            [
                                -73.98015596904989,
                                40.69997182208699
                            ],
                            [
                                -73.98036529590486,
                                40.70007221745949
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9e37055a-a4fd-4eea-b5ca-a45ac494d8cc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98032723212584,
                                40.69977764440205
                            ],
                            [
                                -73.98015596904989,
                                40.69997182208699
                            ],
                            [
                                -73.97958926791658,
                                40.69970002623267
                            ],
                            [
                                -73.98670939301194,
                                40.69986060391781
                            ],
                            [
                                -73.98222948888511,
                                40.68909086834752
                            ],
                            [
                                -73.98577304405494,
                                40.68849562367353
                            ],
                            [
                                -73.98986976948773,
                                40.68982098887079
                            ],
                            [
                                -73.98846447435074,
                                40.69226634435197
                            ],
                            [
                                -73.98826386652567,
                                40.692262153744906
                            ],
                            [
                                -73.98829613850225,
                                40.69191556148019
                            ],
                            [
                                -73.98841925979846,
                                40.69197044934013
                            ],
                            [
                                -73.98821340995279,
                                40.69180246785285
                            ],
                            [
                                -73.98811013423784,
                                40.69181006310479
                            ],
                            [
                                -73.98773967350624,
                                40.69171174365601
                            ],
                            [
                                -73.98742246580301,
                                40.69217855404629
                            ],
                            [
                                -73.98745559567068,
                                40.691597268351984
                            ],
                            [
                                -73.98634626702463,
                                40.69213935468923
                            ],
                            [
                                -73.98639085849703,
                                40.69147860117264
                            ],
                            [
                                -73.98627606638013,
                                40.69213725410272
                            ],
                            [
                                -73.9863133392779,
                                40.691417353865056
                            ],
                            [
                                -73.98557701269739,
                                40.69217240145634
                            ],
                            [
                                -73.98734120779567,
                                40.6939011383718
                            ],
                            [
                                -73.9879494165255,
                                40.69316259699168
                            ],
                            [
                                -73.98731746622101,
                                40.69426225435205
                            ],
                            [
                                -73.99462489914542,
                                40.70262997810267
                            ],
                            [
                                -73.99382083285427,
                                40.70368343611843
                            ],
                            [
                                -73.9920499645126,
                                40.702262753049524
                            ],
                            [
                                -73.99140306521863,
                                40.70175795352445
                            ],
                            [
                                -73.99122326561789,
                                40.70160755359863
                            ],
                            [
                                -73.99085106484958,
                                40.70131015311239
                            ],
                            [
                                -73.9906356632175,
                                40.70114085149711
                            ],
                            [
                                -73.99061306340158,
                                40.70112505223392
                            ],
                            [
                                -73.9902748647651,
                                40.70084125258908
                            ],
                            [
                                -73.9900586214116,
                                40.700721859544565
                            ],
                            [
                                -73.990209736907,
                                40.70083964875149
                            ],
                            [
                                -73.99061293853575,
                                40.701125149621525
                            ],
                            [
                                -73.99063553745337,
                                40.70114094888468
                            ],
                            [
                                -73.99085093549218,
                                40.70131024709457
                            ],
                            [
                                -73.9914029340646,
                                40.70175804682496
                            ],
                            [
                                -73.99204983515521,
                                40.702262847030354
                            ],
                            [
                                -73.99424303509046,
                                40.70402234723881
                            ],
                            [
                                -73.99560256222684,
                                40.7051014994309
                            ],
                            [
                                -73.98714133028814,
                                40.70332207518137
                            ],
                            [
                                -73.98036529590486,
                                40.70007221745949
                            ],
                            [
                                -73.98032723212584,
                                40.69977764440205
                            ]
                        ],
                        [
                            [
                                -73.99053105530098,
                                40.69933705321722
                            ],
                            [
                                -73.99077541232961,
                                40.69957123540446
                            ],
                            [
                                -73.99096507543219,
                                40.69945750913745
                            ],
                            [
                                -73.99053105530098,
                                40.69933705321722
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "09e0821a-e0c3-477e-a3b4-e7d069f796b5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98015596904989,
                                40.69997182208699
                            ],
                            [
                                -73.98032723212584,
                                40.69977764440205
                            ],
                            [
                                -73.98036529590486,
                                40.70007221745949
                            ],
                            [
                                -73.98015596904989,
                                40.69997182208699
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "941b2f25-738b-4593-8cef-71ee50ffe8db"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97875636493556,
                                40.701558690039825
                            ],
                            [
                                -73.97582178544056,
                                40.70094153854777
                            ],
                            [
                                -73.97556229207609,
                                40.699243067509215
                            ],
                            [
                                -73.96882765922197,
                                40.699470651342715
                            ],
                            [
                                -73.96951633778237,
                                40.703921346017
                            ],
                            [
                                -73.96813147452083,
                                40.703403913333695
                            ],
                            [
                                -73.96773860914516,
                                40.70291716765663
                            ],
                            [
                                -73.96769987288619,
                                40.70324265207554
                            ],
                            [
                                -73.967496384717,
                                40.703166621876505
                            ],
                            [
                                -73.96756257148554,
                                40.7043963377381
                            ],
                            [
                                -73.96748451606298,
                                40.70505220447704
                            ],
                            [
                                -73.96511876026781,
                                40.698282842077276
                            ],
                            [
                                -73.96773433226609,
                                40.69780684538531
                            ],
                            [
                                -73.96503177406582,
                                40.69803394017188
                            ],
                            [
                                -73.9648599940099,
                                40.6975424095355
                            ],
                            [
                                -73.98045582865372,
                                40.693611465398384
                            ],
                            [
                                -73.97336647715143,
                                40.69057967274794
                            ],
                            [
                                -73.98222948888511,
                                40.68909086834752
                            ],
                            [
                                -73.98670939301194,
                                40.69986060391781
                            ],
                            [
                                -73.97958926791658,
                                40.69970002623267
                            ],
                            [
                                -73.98015596904989,
                                40.69997182208699
                            ],
                            [
                                -73.97875636493556,
                                40.701558690039825
                            ]
                        ],
                        [
                            [
                                -73.98182408410399,
                                40.69063497585984
                            ],
                            [
                                -73.98217563170559,
                                40.69116626383623
                            ],
                            [
                                -73.98159966787804,
                                40.69001833614539
                            ],
                            [
                                -73.98182408410399,
                                40.69063497585984
                            ]
                        ],
                        [
                            [
                                -73.98264685736092,
                                40.69371855198958
                            ],
                            [
                                -73.98270073741335,
                                40.693720815311025
                            ],
                            [
                                -73.98270199505475,
                                40.693703067681255
                            ],
                            [
                                -73.98264685736092,
                                40.69371855198958
                            ]
                        ],
                        [
                            [
                                -73.97411593978995,
                                40.697894632021416
                            ],
                            [
                                -73.97483636080004,
                                40.697923407700394
                            ],
                            [
                                -73.9746664112264,
                                40.697791479851624
                            ],
                            [
                                -73.97411593978995,
                                40.697894632021416
                            ]
                        ],
                        [
                            [
                                -73.97659452191455,
                                40.69761922503633
                            ],
                            [
                                -73.97529952587233,
                                40.69794162209738
                            ],
                            [
                                -73.97724076633669,
                                40.698206907850235
                            ],
                            [
                                -73.97746472981176,
                                40.69803262317697
                            ],
                            [
                                -73.97659452191455,
                                40.69761922503633
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9553c5a4-95f2-4a96-bba7-55a1e25fbac4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97875636493556,
                                40.701558690039825
                            ],
                            [
                                -73.97847284176765,
                                40.701880147960985
                            ],
                            [
                                -73.97586568010422,
                                40.70122884383257
                            ],
                            [
                                -73.97582178544056,
                                40.70094153854777
                            ],
                            [
                                -73.97875636493556,
                                40.701558690039825
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "60f31300-e5ca-4474-9939-780fa7d3e559"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97847284176765,
                                40.701880147960985
                            ],
                            [
                                -73.97633543341358,
                                40.70430353667821
                            ],
                            [
                                -73.97586568010422,
                                40.70122884383257
                            ],
                            [
                                -73.97847284176765,
                                40.701880147960985
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "be223daa-ff00-4224-aa0d-c43b8c66570d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97582178544056,
                                40.70094153854777
                            ],
                            [
                                -73.97586568010422,
                                40.70122884383257
                            ],
                            [
                                -73.96882765922197,
                                40.699470651342715
                            ],
                            [
                                -73.97582178544056,
                                40.70094153854777
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "7ccecd3d-023a-426c-b770-bb151be820a7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96882765922197,
                                40.699470651342715
                            ],
                            [
                                -73.97556229207609,
                                40.699243067509215
                            ],
                            [
                                -73.97582178544056,
                                40.70094153854777
                            ],
                            [
                                -73.96882765922197,
                                40.699470651342715
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "2d6606a6-b369-4fa0-aba5-8e9362b2cd6f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96951633778237,
                                40.703921346017
                            ],
                            [
                                -73.96882765922197,
                                40.699470651342715
                            ],
                            [
                                -73.97076417068978,
                                40.70438757943334
                            ],
                            [
                                -73.96951633778237,
                                40.703921346017
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "e9b5c1f9-65d4-4723-914c-04d5147076bc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96951633778237,
                                40.703921346017
                            ],
                            [
                                -73.96974574656056,
                                40.70540393671473
                            ],
                            [
                                -73.96813147452083,
                                40.703403913333695
                            ],
                            [
                                -73.96951633778237,
                                40.703921346017
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "645217f9-ad44-47ab-a9a1-e07e598bc299"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96974574656056,
                                40.70540393671473
                            ],
                            [
                                -73.96951633778237,
                                40.703921346017
                            ],
                            [
                                -73.97076417068978,
                                40.70438757943334
                            ],
                            [
                                -73.97251645439229,
                                40.70883674129107
                            ],
                            [
                                -73.96974574656056,
                                40.70540393671473
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "de3a7267-38f9-487b-ab38-de8068b206d5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96974574656056,
                                40.70540393671473
                            ],
                            [
                                -73.97026522688294,
                                40.70876116087873
                            ],
                            [
                                -73.96965096338324,
                                40.70995294378738
                            ],
                            [
                                -73.96913720850549,
                                40.70978121078506
                            ],
                            [
                                -73.96761850794776,
                                40.70543560821996
                            ],
                            [
                                -73.96756257148554,
                                40.7043963377381
                            ],
                            [
                                -73.96769987288619,
                                40.70324265207554
                            ],
                            [
                                -73.96813147452083,
                                40.703403913333695
                            ],
                            [
                                -73.96974574656056,
                                40.70540393671473
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "01f55179-7ba6-4dca-9bc9-1cbc25182eab"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96913720850549,
                                40.70978121078506
                            ],
                            [
                                -73.96844784160307,
                                40.7095507758968
                            ],
                            [
                                -73.96776215683404,
                                40.708104530088136
                            ],
                            [
                                -73.96761850794776,
                                40.70543560821996
                            ],
                            [
                                -73.96913720850549,
                                40.70978121078506
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "e8ed1f06-5636-4691-9413-05eeeef85de0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96844784160307,
                                40.7095507758968
                            ],
                            [
                                -73.96913720850549,
                                40.70978121078506
                            ],
                            [
                                -73.96999685521855,
                                40.71224099980826
                            ],
                            [
                                -73.96798632048306,
                                40.71226937452535
                            ],
                            [
                                -73.96789144549676,
                                40.71050664644401
                            ],
                            [
                                -73.96872879154012,
                                40.71014335529662
                            ],
                            [
                                -73.96844784160307,
                                40.7095507758968
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "821c3be2-23fd-4869-a6e4-8f3e078533c5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96844784160307,
                                40.7095507758968
                            ],
                            [
                                -73.96782886155539,
                                40.70934386924431
                            ],
                            [
                                -73.96776215683404,
                                40.708104530088136
                            ],
                            [
                                -73.96844784160307,
                                40.7095507758968
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "3777f83f-0d12-474f-9c07-c4c5dffda535"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96782886155539,
                                40.70934386924431
                            ],
                            [
                                -73.96844784160307,
                                40.7095507758968
                            ],
                            [
                                -73.96872879154012,
                                40.71014335529662
                            ],
                            [
                                -73.96789144549676,
                                40.71050664644401
                            ],
                            [
                                -73.96782886155539,
                                40.70934386924431
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "36a6c5bd-c750-4d47-8477-dd8b025f4476"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96782886155539,
                                40.70934386924431
                            ],
                            [
                                -73.96715707755945,
                                40.70911931179965
                            ],
                            [
                                -73.96703916727701,
                                40.70879428217488
                            ],
                            [
                                -73.96724985298434,
                                40.70702397918653
                            ],
                            [
                                -73.96776215683404,
                                40.708104530088136
                            ],
                            [
                                -73.96782886155539,
                                40.70934386924431
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "93ba8fd0-d471-4dbc-865e-65232c6b07a8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96703916727701,
                                40.70879428217488
                            ],
                            [
                                -73.96715707755945,
                                40.70911931179965
                            ],
                            [
                                -73.96782886155539,
                                40.70934386924431
                            ],
                            [
                                -73.96789144549676,
                                40.71050664644401
                            ],
                            [
                                -73.96677787881386,
                                40.71098977874175
                            ],
                            [
                                -73.96703916727701,
                                40.70879428217488
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "3e7d84fa-65db-4fd3-9fca-a161be9a686d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96703916727701,
                                40.70879428217488
                            ],
                            [
                                -73.96361820255618,
                                40.699364104784294
                            ],
                            [
                                -73.96724985298434,
                                40.70702397918653
                            ],
                            [
                                -73.96703916727701,
                                40.70879428217488
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "c8b491e1-e08d-4e68-a230-de16a2b8e867"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96703916727701,
                                40.70879428217488
                            ],
                            [
                                -73.96677787881386,
                                40.71098977874175
                            ],
                            [
                                -73.95662725690327,
                                40.715393729903724
                            ],
                            [
                                -73.95317358294491,
                                40.71501249097023
                            ],
                            [
                                -73.95366993409345,
                                40.718297972301215
                            ],
                            [
                                -73.95132775345628,
                                40.71795263427501
                            ],
                            [
                                -73.9515049694912,
                                40.71751137969638
                            ],
                            [
                                -73.95043965658567,
                                40.71782169065115
                            ],
                            [
                                -73.94691428019965,
                                40.71730189875147
                            ],
                            [
                                -73.94947800725701,
                                40.715921661571294
                            ],
                            [
                                -73.94980037851033,
                                40.7161249840078
                            ],
                            [
                                -73.94991197716057,
                                40.71568802461129
                            ],
                            [
                                -73.96190049494459,
                                40.70923375018562
                            ],
                            [
                                -73.96027272671498,
                                40.70857873406742
                            ],
                            [
                                -73.9607570393009,
                                40.708329533331614
                            ],
                            [
                                -73.95705037299797,
                                40.707282054611
                            ],
                            [
                                -73.9504759934779,
                                40.70463651547685
                            ],
                            [
                                -73.950539115067,
                                40.70437017396489
                            ],
                            [
                                -73.95532659135606,
                                40.6999299499356
                            ],
                            [
                                -73.95182755208236,
                                40.69893361465704
                            ],
                            [
                                -73.95225575484893,
                                40.697126813309
                            ],
                            [
                                -73.95744278590851,
                                40.694429910201755
                            ],
                            [
                                -73.95299833490441,
                                40.69399349715596
                            ],
                            [
                                -73.95365987244925,
                                40.69120213983387
                            ],
                            [
                                -73.95442444655386,
                                40.69110875498925
                            ],
                            [
                                -73.95423980413543,
                                40.69310839573022
                            ],
                            [
                                -73.9560357079666,
                                40.69404230834099
                            ],
                            [
                                -73.95978944684038,
                                40.692608855229196
                            ],
                            [
                                -73.96146730485671,
                                40.69524848253191
                            ],
                            [
                                -73.96316157307487,
                                40.69268255593607
                            ],
                            [
                                -73.9634567964663,
                                40.69352730674658
                            ],
                            [
                                -73.96261291106717,
                                40.69376148016203
                            ],
                            [
                                -73.96404856492562,
                                40.695220590206375
                            ],
                            [
                                -73.96443762117133,
                                40.6963338339006
                            ],
                            [
                                -73.96158369687312,
                                40.69832368059416
                            ],
                            [
                                -73.96306587404352,
                                40.698199133893134
                            ],
                            [
                                -73.96361820255618,
                                40.699364104784294
                            ],
                            [
                                -73.96703916727701,
                                40.70879428217488
                            ]
                        ],
                        [
                            [
                                -73.95814393446248,
                                40.694669955504985
                            ],
                            [
                                -73.958654602447,
                                40.69437486907678
                            ],
                            [
                                -73.95806875984626,
                                40.6942931675359
                            ],
                            [
                                -73.95790684749944,
                                40.69446445221008
                            ],
                            [
                                -73.95814393446248,
                                40.694669955504985
                            ]
                        ],
                        [
                            [
                                -73.96104652858773,
                                40.696357211101784
                            ],
                            [
                                -73.96169893904597,
                                40.697282750713036
                            ],
                            [
                                -73.96193527411911,
                                40.69625664057635
                            ],
                            [
                                -73.96104652858773,
                                40.696357211101784
                            ]
                        ],
                        [
                            [
                                -73.96168688904476,
                                40.69861981879297
                            ],
                            [
                                -73.96170684152553,
                                40.69856814828185
                            ],
                            [
                                -73.96171833187631,
                                40.698538121095666
                            ],
                            [
                                -73.96168688904476,
                                40.69861981879297
                            ]
                        ],
                        [
                            [
                                -73.95644006752376,
                                40.699797270099864
                            ],
                            [
                                -73.95705444870276,
                                40.69972175570604
                            ],
                            [
                                -73.95698083446018,
                                40.6993255529985
                            ],
                            [
                                -73.95644006752376,
                                40.699797270099864
                            ]
                        ],
                        [
                            [
                                -73.95214665496326,
                                40.71783104621754
                            ],
                            [
                                -73.9522439290338,
                                40.717820458823105
                            ],
                            [
                                -73.95221294524133,
                                40.71762855366646
                            ],
                            [
                                -73.95214665496326,
                                40.71783104621754
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "1efad71f-0326-481b-9e7b-5174e8c0dc32"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96361820255618,
                                40.699364104784294
                            ],
                            [
                                -73.96334317671446,
                                40.69860597288701
                            ],
                            [
                                -73.96511876026781,
                                40.698282842077276
                            ],
                            [
                                -73.96748451606298,
                                40.70505220447704
                            ],
                            [
                                -73.96724985298434,
                                40.70702397918653
                            ],
                            [
                                -73.96361820255618,
                                40.699364104784294
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "16ebf8f7-287f-4ed7-85cf-520c5b9121a6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96511876026781,
                                40.698282842077276
                            ],
                            [
                                -73.96334317671446,
                                40.69860597288701
                            ],
                            [
                                -73.96361820255618,
                                40.699364104784294
                            ],
                            [
                                -73.96306587404352,
                                40.698199133893134
                            ],
                            [
                                -73.96503177406582,
                                40.69803394017188
                            ],
                            [
                                -73.96511876026781,
                                40.698282842077276
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "aefebc82-cfce-49db-bc11-e2efa84f2ed6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96503177406582,
                                40.69803394017188
                            ],
                            [
                                -73.96773433226609,
                                40.69780684538531
                            ],
                            [
                                -73.96511876026781,
                                40.698282842077276
                            ],
                            [
                                -73.96503177406582,
                                40.69803394017188
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "11840ec0-243c-49fd-bf3e-e047708a3479"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96503177406582,
                                40.69803394017188
                            ],
                            [
                                -73.96306587404352,
                                40.698199133893134
                            ],
                            [
                                -73.96297926110803,
                                40.698016449953535
                            ],
                            [
                                -73.9648599940099,
                                40.6975424095355
                            ],
                            [
                                -73.96503177406582,
                                40.69803394017188
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "6740702d-7758-4d85-baa8-117162a9cfc7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96306587404352,
                                40.698199133893134
                            ],
                            [
                                -73.96158369687312,
                                40.69832368059416
                            ],
                            [
                                -73.96443762117133,
                                40.6963338339006
                            ],
                            [
                                -73.9648599940099,
                                40.6975424095355
                            ],
                            [
                                -73.96297926110803,
                                40.698016449953535
                            ],
                            [
                                -73.96306587404352,
                                40.698199133893134
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "848dc1cd-7203-4eea-a68b-55445b6f8acd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96443762117133,
                                40.6963338339006
                            ],
                            [
                                -73.96485653074438,
                                40.69604175683697
                            ],
                            [
                                -73.96404856492562,
                                40.695220590206375
                            ],
                            [
                                -73.9634567964663,
                                40.69352730674658
                            ],
                            [
                                -73.96522492624914,
                                40.693036660752895
                            ],
                            [
                                -73.96486444855562,
                                40.692007839376416
                            ],
                            [
                                -73.97336647715143,
                                40.69057967274794
                            ],
                            [
                                -73.98045582865372,
                                40.693611465398384
                            ],
                            [
                                -73.9648599940099,
                                40.6975424095355
                            ],
                            [
                                -73.96443762117133,
                                40.6963338339006
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "68c879ac-3368-4f4e-bfd9-23098df83fa8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96404856492562,
                                40.695220590206375
                            ],
                            [
                                -73.96485653074438,
                                40.69604175683697
                            ],
                            [
                                -73.96443762117133,
                                40.6963338339006
                            ],
                            [
                                -73.96404856492562,
                                40.695220590206375
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "e20d4f97-aa21-4997-bb6b-e3f334389fa5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96404856492562,
                                40.695220590206375
                            ],
                            [
                                -73.96261291106717,
                                40.69376148016203
                            ],
                            [
                                -73.9634567964663,
                                40.69352730674658
                            ],
                            [
                                -73.96404856492562,
                                40.695220590206375
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "daa7e09c-788c-4d37-9704-d3ee88d09274"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96486444855562,
                                40.692007839376416
                            ],
                            [
                                -73.96522492624914,
                                40.693036660752895
                            ],
                            [
                                -73.9634567964663,
                                40.69352730674658
                            ],
                            [
                                -73.96316157307487,
                                40.69268255593607
                            ],
                            [
                                -73.96345022492221,
                                40.69224539997453
                            ],
                            [
                                -73.96486444855562,
                                40.692007839376416
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "417f7339-705c-4118-afb4-771a108a4430"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96442854729187,
                                40.690763755356095
                            ],
                            [
                                -73.96345022492221,
                                40.69224539997453
                            ],
                            [
                                -73.9630332732128,
                                40.69231543931989
                            ],
                            [
                                -73.96316157307487,
                                40.69268255593607
                            ],
                            [
                                -73.96146730485671,
                                40.69524848253191
                            ],
                            [
                                -73.95978944684038,
                                40.692608855229196
                            ],
                            [
                                -73.9560357079666,
                                40.69404230834099
                            ],
                            [
                                -73.95423980413543,
                                40.69310839573022
                            ],
                            [
                                -73.95442444655386,
                                40.69110875498925
                            ],
                            [
                                -73.95365987244925,
                                40.69120213983387
                            ],
                            [
                                -73.95299833490441,
                                40.69399349715596
                            ],
                            [
                                -73.94499130708091,
                                40.69320726475133
                            ],
                            [
                                -73.9541452465703,
                                40.685136854732825
                            ],
                            [
                                -73.95286952860604,
                                40.68491620474808
                            ],
                            [
                                -73.9657326351831,
                                40.682103032039784
                            ],
                            [
                                -73.96959165391323,
                                40.68620724556638
                            ],
                            [
                                -73.97390018511997,
                                40.6827588354491
                            ],
                            [
                                -73.9925446146373,
                                40.68365935779927
                            ],
                            [
                                -73.99404498980797,
                                40.686905125131595
                            ],
                            [
                                -73.98440991491073,
                                40.688054626599964
                            ],
                            [
                                -73.98139235557508,
                                40.687078391265246
                            ],
                            [
                                -73.98192191380554,
                                40.68835145469463
                            ],
                            [
                                -73.97115877390952,
                                40.68963553865316
                            ],
                            [
                                -73.97336647715143,
                                40.69057967274794
                            ],
                            [
                                -73.96486444855562,
                                40.692007839376416
                            ],
                            [
                                -73.96442854729187,
                                40.690763755356095
                            ]
                        ],
                        [
                            [
                                -73.96599087926771,
                                40.68429788759031
                            ],
                            [
                                -73.96700451308149,
                                40.68350408177022
                            ],
                            [
                                -73.96461781693236,
                                40.683006284713734
                            ],
                            [
                                -73.96599087926771,
                                40.68429788759031
                            ]
                        ],
                        [
                            [
                                -73.9908585415277,
                                40.68614624000281
                            ],
                            [
                                -73.9908600471041,
                                40.68614416851833
                            ],
                            [
                                -73.99088143868597,
                                40.68611315482545
                            ],
                            [
                                -73.9908585415277,
                                40.68614624000281
                            ]
                        ],
                        [
                            [
                                -73.97467148580942,
                                40.687965704649834
                            ],
                            [
                                -73.97232839208755,
                                40.68505686461042
                            ],
                            [
                                -73.97204479574899,
                                40.68606780079024
                            ],
                            [
                                -73.97467148580942,
                                40.687965704649834
                            ]
                        ],
                        [
                            [
                                -73.97982099463232,
                                40.68651216832579
                            ],
                            [
                                -73.97946538305237,
                                40.68660132539344
                            ],
                            [
                                -73.97948292894651,
                                40.686622835711916
                            ],
                            [
                                -73.97982099463232,
                                40.68651216832579
                            ]
                        ],
                        [
                            [
                                -73.95338731640553,
                                40.689647428799795
                            ],
                            [
                                -73.95352302130225,
                                40.68963221736754
                            ],
                            [
                                -73.953640474229,
                                40.68961892336848
                            ],
                            [
                                -73.95352201339249,
                                40.68962706719483
                            ],
                            [
                                -73.95338731640553,
                                40.689647428799795
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "a99af4e6-a8f8-4606-85bf-241a3c682026"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96345022492221,
                                40.69224539997453
                            ],
                            [
                                -73.96442854729187,
                                40.690763755356095
                            ],
                            [
                                -73.96486444855562,
                                40.692007839376416
                            ],
                            [
                                -73.96345022492221,
                                40.69224539997453
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "2dbc0ee8-cc50-4179-b0ac-7dc8e273f8bd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96345022492221,
                                40.69224539997453
                            ],
                            [
                                -73.96316157307487,
                                40.69268255593607
                            ],
                            [
                                -73.9630332732128,
                                40.69231543931989
                            ],
                            [
                                -73.96345022492221,
                                40.69224539997453
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "03eede26-a2c0-4d3d-8d7e-a538be804b48"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95225575484893,
                                40.697126813309
                            ],
                            [
                                -73.94977228280067,
                                40.698418049742095
                            ],
                            [
                                -73.9493367430539,
                                40.69850101616226
                            ],
                            [
                                -73.94952974935289,
                                40.697524756069996
                            ],
                            [
                                -73.94259680980655,
                                40.695318331228734
                            ],
                            [
                                -73.94499130708091,
                                40.69320726475133
                            ],
                            [
                                -73.95299833490441,
                                40.69399349715596
                            ],
                            [
                                -73.95225575484893,
                                40.697126813309
                            ]
                        ],
                        [
                            [
                                -73.9492187867375,
                                40.69543369917892
                            ],
                            [
                                -73.9504763643549,
                                40.694409908317226
                            ],
                            [
                                -73.94885151322855,
                                40.693591441472655
                            ],
                            [
                                -73.94772285553253,
                                40.694727083269164
                            ],
                            [
                                -73.9492187867375,
                                40.69543369917892
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "f82271f5-a74a-4a67-9f49-509ba8f51876"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95225575484893,
                                40.697126813309
                            ],
                            [
                                -73.95299833490441,
                                40.69399349715596
                            ],
                            [
                                -73.95744278590851,
                                40.694429910201755
                            ],
                            [
                                -73.95225575484893,
                                40.697126813309
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "5f920c43-f694-4d54-9cba-a95eb0abf937"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95225575484893,
                                40.697126813309
                            ],
                            [
                                -73.95182755208236,
                                40.69893361465704
                            ],
                            [
                                -73.94991886988959,
                                40.69839012621479
                            ],
                            [
                                -73.94977228280067,
                                40.698418049742095
                            ],
                            [
                                -73.95225575484893,
                                40.697126813309
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "fca52fc1-3c2f-4cb4-843d-fe7eaaf65ee5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95182755208236,
                                40.69893361465704
                            ],
                            [
                                -73.950539115067,
                                40.70437017396489
                            ],
                            [
                                -73.95031973859354,
                                40.704573638301866
                            ],
                            [
                                -73.9504759934779,
                                40.70463651547685
                            ],
                            [
                                -73.95030103836427,
                                40.70537473848667
                            ],
                            [
                                -73.95306817990516,
                                40.70615671389101
                            ],
                            [
                                -73.95148141746108,
                                40.70665195801474
                            ],
                            [
                                -73.95509254159697,
                                40.707455521455365
                            ],
                            [
                                -73.95397926591468,
                                40.70641418060898
                            ],
                            [
                                -73.95705037299797,
                                40.707282054611
                            ],
                            [
                                -73.95818900769947,
                                40.70774024274163
                            ],
                            [
                                -73.95728854517422,
                                40.70926825796074
                            ],
                            [
                                -73.95904862688681,
                                40.70881228348666
                            ],
                            [
                                -73.9583597080912,
                                40.707808932808135
                            ],
                            [
                                -73.96027272671498,
                                40.70857873406742
                            ],
                            [
                                -73.95662469646747,
                                40.71045581066701
                            ],
                            [
                                -73.94769218255716,
                                40.70681949483772
                            ],
                            [
                                -73.94818896144112,
                                40.704306699132864
                            ],
                            [
                                -73.94822810937083,
                                40.70432797843254
                            ],
                            [
                                -73.94819666023153,
                                40.70426775728549
                            ],
                            [
                                -73.94845433404944,
                                40.70296439741291
                            ],
                            [
                                -73.94984535266504,
                                40.702738822833545
                            ],
                            [
                                -73.94846055356201,
                                40.702932938015216
                            ],
                            [
                                -73.94930512629662,
                                40.69866093932823
                            ],
                            [
                                -73.94977228280067,
                                40.698418049742095
                            ],
                            [
                                -73.94991886988959,
                                40.69839012621479
                            ],
                            [
                                -73.95182755208236,
                                40.69893361465704
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "07975d96-0896-4c78-8719-828e5a333add"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.950539115067,
                                40.70437017396489
                            ],
                            [
                                -73.95182755208236,
                                40.69893361465704
                            ],
                            [
                                -73.95532659135606,
                                40.6999299499356
                            ],
                            [
                                -73.950539115067,
                                40.70437017396489
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "810d4270-ae56-4a35-b340-6304ce565546"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.950539115067,
                                40.70437017396489
                            ],
                            [
                                -73.9504759934779,
                                40.70463651547685
                            ],
                            [
                                -73.95031973859354,
                                40.704573638301866
                            ],
                            [
                                -73.950539115067,
                                40.70437017396489
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "10c808d7-88cd-4ac7-9211-120f0c0d1569"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95306817990516,
                                40.70615671389101
                            ],
                            [
                                -73.95030103836427,
                                40.70537473848667
                            ],
                            [
                                -73.9504759934779,
                                40.70463651547685
                            ],
                            [
                                -73.95705037299797,
                                40.707282054611
                            ],
                            [
                                -73.95397926591468,
                                40.70641418060898
                            ],
                            [
                                -73.95354493480528,
                                40.70600791401009
                            ],
                            [
                                -73.95306817990516,
                                40.70615671389101
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "1d18cd74-e7c7-4158-88a1-a0d862c670ad"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95306817990516,
                                40.70615671389101
                            ],
                            [
                                -73.95397926591468,
                                40.70641418060898
                            ],
                            [
                                -73.95509254159697,
                                40.707455521455365
                            ],
                            [
                                -73.95148141746108,
                                40.70665195801474
                            ],
                            [
                                -73.95306817990516,
                                40.70615671389101
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "d2662e34-f9bb-4a2a-98d1-ca74766c57a4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95397926591468,
                                40.70641418060898
                            ],
                            [
                                -73.95306817990516,
                                40.70615671389101
                            ],
                            [
                                -73.95354493480528,
                                40.70600791401009
                            ],
                            [
                                -73.95397926591468,
                                40.70641418060898
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "31f69ed3-b7f0-425c-b616-33c40daf2e9c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95705037299797,
                                40.707282054611
                            ],
                            [
                                -73.9607570393009,
                                40.708329533331614
                            ],
                            [
                                -73.96027272671498,
                                40.70857873406742
                            ],
                            [
                                -73.9583597080912,
                                40.707808932808135
                            ],
                            [
                                -73.95824606482546,
                                40.707643421214456
                            ],
                            [
                                -73.95818900769947,
                                40.70774024274163
                            ],
                            [
                                -73.95705037299797,
                                40.707282054611
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "b8aadcba-3bac-490f-a495-26925910217f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94769218255716,
                                40.70681949483772
                            ],
                            [
                                -73.95662469646747,
                                40.71045581066701
                            ],
                            [
                                -73.96027272671498,
                                40.70857873406742
                            ],
                            [
                                -73.96190049494459,
                                40.70923375018562
                            ],
                            [
                                -73.94991197716057,
                                40.71568802461129
                            ],
                            [
                                -73.9511193101633,
                                40.71096076761372
                            ],
                            [
                                -73.94689695164685,
                                40.714293766682964
                            ],
                            [
                                -73.94947800725701,
                                40.715921661571294
                            ],
                            [
                                -73.94691428019965,
                                40.71730189875147
                            ],
                            [
                                -73.946631070984,
                                40.717260141548806
                            ],
                            [
                                -73.94665946860518,
                                40.71743908201192
                            ],
                            [
                                -73.94500908997499,
                                40.7183275985723
                            ],
                            [
                                -73.94267065341653,
                                40.7150568848437
                            ],
                            [
                                -73.94077749442965,
                                40.711992132637214
                            ],
                            [
                                -73.94207093244596,
                                40.71181745370773
                            ],
                            [
                                -73.94041229208852,
                                40.711159907120326
                            ],
                            [
                                -73.94032110843592,
                                40.71125330933131
                            ],
                            [
                                -73.93780454390416,
                                40.70717935317921
                            ],
                            [
                                -73.93445246387313,
                                40.70837557151161
                            ],
                            [
                                -73.93223999451557,
                                40.70825225975901
                            ],
                            [
                                -73.93205883400697,
                                40.70787784658331
                            ],
                            [
                                -73.93105271991605,
                                40.70818608713103
                            ],
                            [
                                -73.92968693760214,
                                40.70810996539392
                            ],
                            [
                                -73.92887465666773,
                                40.70241031913789
                            ],
                            [
                                -73.93948522891867,
                                40.706169657989776
                            ],
                            [
                                -73.94013225670723,
                                40.70295085970945
                            ],
                            [
                                -73.94073731243978,
                                40.703134210498526
                            ],
                            [
                                -73.94629872217665,
                                40.70213682814896
                            ],
                            [
                                -73.94646850646028,
                                40.70027992682527
                            ],
                            [
                                -73.94641436230316,
                                40.70028613791706
                            ],
                            [
                                -73.94515540756845,
                                40.70111183203634
                            ],
                            [
                                -73.9450821894829,
                                40.70104510513976
                            ],
                            [
                                -73.94631603810205,
                                40.70021506174376
                            ],
                            [
                                -73.94930512629662,
                                40.69866093932823
                            ],
                            [
                                -73.94846055356201,
                                40.702932938015216
                            ],
                            [
                                -73.9448628613147,
                                40.7034372470393
                            ],
                            [
                                -73.94618102311148,
                                40.70437824570406
                            ],
                            [
                                -73.94625120309475,
                                40.70332166821635
                            ],
                            [
                                -73.94634792548034,
                                40.70330598322752
                            ],
                            [
                                -73.94818896144112,
                                40.704306699132864
                            ],
                            [
                                -73.94769218255716,
                                40.70681949483772
                            ]
                        ],
                        [
                            [
                                -73.94277227144279,
                                40.70530549347592
                            ],
                            [
                                -73.94299171549365,
                                40.70451348173264
                            ],
                            [
                                -73.94262351043174,
                                40.70444836744254
                            ],
                            [
                                -73.9421726028715,
                                40.70459641968722
                            ],
                            [
                                -73.94277227144279,
                                40.70530549347592
                            ]
                        ],
                        [
                            [
                                -73.9397828874847,
                                40.707323711907065
                            ],
                            [
                                -73.939270603431,
                                40.707797377804674
                            ],
                            [
                                -73.94002201811502,
                                40.70808584644115
                            ],
                            [
                                -73.94032559568045,
                                40.707696266840884
                            ],
                            [
                                -73.9397828874847,
                                40.707323711907065
                            ]
                        ],
                        [
                            [
                                -73.94401624677013,
                                40.71290525648695
                            ],
                            [
                                -73.94455174508741,
                                40.71618072957227
                            ],
                            [
                                -73.94674956056659,
                                40.71430770873874
                            ],
                            [
                                -73.94401624677013,
                                40.71290525648695
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "5d661c03-bba8-487a-8956-1fb09593f795"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94818896144112,
                                40.704306699132864
                            ],
                            [
                                -73.94819666023153,
                                40.70426775728549
                            ],
                            [
                                -73.94822810937083,
                                40.70432797843254
                            ],
                            [
                                -73.94818896144112,
                                40.704306699132864
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "1b92bd93-08a7-4e95-a1ac-61422965d941"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94819666023153,
                                40.70426775728549
                            ],
                            [
                                -73.94818896144112,
                                40.704306699132864
                            ],
                            [
                                -73.94634792548034,
                                40.70330598322752
                            ],
                            [
                                -73.94669876721981,
                                40.70324908896534
                            ],
                            [
                                -73.94767298106795,
                                40.70326497753975
                            ],
                            [
                                -73.94819666023153,
                                40.70426775728549
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "ddfa9eee-36c5-4de3-b72f-0363bc2ac5bd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94845433404944,
                                40.70296439741291
                            ],
                            [
                                -73.94819666023153,
                                40.70426775728549
                            ],
                            [
                                -73.94767298106795,
                                40.70326497753975
                            ],
                            [
                                -73.94669876721981,
                                40.70324908896534
                            ],
                            [
                                -73.94845433404944,
                                40.70296439741291
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "635f38cf-7eef-40fc-b4a8-f046f971f7b1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94845433404944,
                                40.70296439741291
                            ],
                            [
                                -73.94846055356201,
                                40.702932938015216
                            ],
                            [
                                -73.94984535266504,
                                40.702738822833545
                            ],
                            [
                                -73.94845433404944,
                                40.70296439741291
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "d506dd55-9a44-4ab6-8e4c-166a2f9578a4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94846055356201,
                                40.702932938015216
                            ],
                            [
                                -73.94845433404944,
                                40.70296439741291
                            ],
                            [
                                -73.94669876721981,
                                40.70324908896534
                            ],
                            [
                                -73.94629109458157,
                                40.703242440181754
                            ],
                            [
                                -73.94630379945463,
                                40.7032819980275
                            ],
                            [
                                -73.94634792548034,
                                40.70330598322752
                            ],
                            [
                                -73.94625120309475,
                                40.70332166821635
                            ],
                            [
                                -73.94618102311148,
                                40.70437824570406
                            ],
                            [
                                -73.9448628613147,
                                40.7034372470393
                            ],
                            [
                                -73.94846055356201,
                                40.702932938015216
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "7bdec711-8aff-41db-9bac-e65a9838103b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94930512629662,
                                40.69866093932823
                            ],
                            [
                                -73.9493367430539,
                                40.69850101616226
                            ],
                            [
                                -73.94977228280067,
                                40.698418049742095
                            ],
                            [
                                -73.94930512629662,
                                40.69866093932823
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "13c6b912-7dfe-49c1-b3ba-cf48fefb526e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94930512629662,
                                40.69866093932823
                            ],
                            [
                                -73.94631603810205,
                                40.70021506174376
                            ],
                            [
                                -73.9450821894829,
                                40.70104510513976
                            ],
                            [
                                -73.94515540756845,
                                40.70111183203634
                            ],
                            [
                                -73.94641436230316,
                                40.70028613791706
                            ],
                            [
                                -73.94646850646028,
                                40.70027992682527
                            ],
                            [
                                -73.94629872217665,
                                40.70213682814896
                            ],
                            [
                                -73.94073731243978,
                                40.703134210498526
                            ],
                            [
                                -73.94013225670723,
                                40.70295085970945
                            ],
                            [
                                -73.94069587046077,
                                40.70014702498584
                            ],
                            [
                                -73.9470714414479,
                                40.698932535825506
                            ],
                            [
                                -73.94713284385055,
                                40.69901566746373
                            ],
                            [
                                -73.9472028493918,
                                40.69890750378936
                            ],
                            [
                                -73.9493367430539,
                                40.69850101616226
                            ],
                            [
                                -73.94930512629662,
                                40.69866093932823
                            ]
                        ],
                        [
                            [
                                -73.941127616956,
                                40.70026958045263
                            ],
                            [
                                -73.94112594788619,
                                40.70026005541385
                            ],
                            [
                                -73.94111312263888,
                                40.70026184042229
                            ],
                            [
                                -73.941127616956,
                                40.70026958045263
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "ada9db7d-cfc6-4637-9176-7216005d953a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94259680980655,
                                40.695318331228734
                            ],
                            [
                                -73.94952974935289,
                                40.697524756069996
                            ],
                            [
                                -73.9493367430539,
                                40.69850101616226
                            ],
                            [
                                -73.9472028493918,
                                40.69890750378936
                            ],
                            [
                                -73.94735478162465,
                                40.69867275739257
                            ],
                            [
                                -73.9470463136308,
                                40.698590667470754
                            ],
                            [
                                -73.94691688885626,
                                40.69872328977678
                            ],
                            [
                                -73.9470714414479,
                                40.698932535825506
                            ],
                            [
                                -73.94069587046077,
                                40.70014702498584
                            ],
                            [
                                -73.94013225670723,
                                40.70295085970945
                            ],
                            [
                                -73.92439240745472,
                                40.69818119366863
                            ],
                            [
                                -73.93738662787105,
                                40.695169159758215
                            ],
                            [
                                -73.93743872519612,
                                40.69421366880238
                            ],
                            [
                                -73.9403131270643,
                                40.69482406059482
                            ],
                            [
                                -73.9415074165936,
                                40.68978622609004
                            ],
                            [
                                -73.94304595307557,
                                40.69301624501327
                            ],
                            [
                                -73.94499130708091,
                                40.69320726475133
                            ],
                            [
                                -73.94259680980655,
                                40.695318331228734
                            ]
                        ],
                        [
                            [
                                -73.92940276993507,
                                40.697795635717696
                            ],
                            [
                                -73.92942491520344,
                                40.69778562200062
                            ],
                            [
                                -73.92934228906192,
                                40.69779260974051
                            ],
                            [
                                -73.92940276993507,
                                40.697795635717696
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "3025106d-e8bc-488d-a3ec-e516dd66c24c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95286952860604,
                                40.68491620474808
                            ],
                            [
                                -73.9541452465703,
                                40.685136854732825
                            ],
                            [
                                -73.94499130708091,
                                40.69320726475133
                            ],
                            [
                                -73.94304595307557,
                                40.69301624501327
                            ],
                            [
                                -73.9415074165936,
                                40.68978622609004
                            ],
                            [
                                -73.94306397993981,
                                40.68322022317201
                            ],
                            [
                                -73.9440215439205,
                                40.68338584479344
                            ],
                            [
                                -73.94456015331848,
                                40.68609254555132
                            ],
                            [
                                -73.94905404891374,
                                40.68425627398007
                            ],
                            [
                                -73.95032763053877,
                                40.68447655446056
                            ],
                            [
                                -73.9506864533504,
                                40.68539364522426
                            ],
                            [
                                -73.95286952860604,
                                40.68491620474808
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "c6d336a0-363d-4ee6-b932-f482e4d710fa"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95286952860604,
                                40.68491620474808
                            ],
                            [
                                -73.95032763053877,
                                40.68447655446056
                            ],
                            [
                                -73.95007777458956,
                                40.683837964672385
                            ],
                            [
                                -73.95582651369313,
                                40.68148894566146
                            ],
                            [
                                -73.97400642347873,
                                40.68244938173357
                            ],
                            [
                                -73.99252380696036,
                                40.68350572962272
                            ],
                            [
                                -73.9944535139548,
                                40.687037482561585
                            ],
                            [
                                -73.98577304405494,
                                40.68849562367353
                            ],
                            [
                                -73.98440991491073,
                                40.688054626599964
                            ],
                            [
                                -73.99404498980797,
                                40.686905125131595
                            ],
                            [
                                -73.9925446146373,
                                40.68365935779927
                            ],
                            [
                                -73.97390018511997,
                                40.6827588354491
                            ],
                            [
                                -73.96959165391323,
                                40.68620724556638
                            ],
                            [
                                -73.9657326351831,
                                40.682103032039784
                            ],
                            [
                                -73.95286952860604,
                                40.68491620474808
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "6f7f8fbc-57b0-4677-9736-38d16d93066b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95032763053877,
                                40.68447655446056
                            ],
                            [
                                -73.95286952860604,
                                40.68491620474808
                            ],
                            [
                                -73.9506864533504,
                                40.68539364522426
                            ],
                            [
                                -73.95032763053877,
                                40.68447655446056
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "ad122830-691a-4b59-a443-b0cab845d184"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95032763053877,
                                40.68447655446056
                            ],
                            [
                                -73.94905404891374,
                                40.68425627398007
                            ],
                            [
                                -73.95007777458956,
                                40.683837964672385
                            ],
                            [
                                -73.95032763053877,
                                40.68447655446056
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "709ce971-fe03-4975-af16-b01f579797ab"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9440215439205,
                                40.68338584479344
                            ],
                            [
                                -73.94356664805528,
                                40.68109983384055
                            ],
                            [
                                -73.94306397993981,
                                40.68322022317201
                            ],
                            [
                                -73.9274295376925,
                                40.680516067911945
                            ],
                            [
                                -73.93020153212277,
                                40.67749204477729
                            ],
                            [
                                -73.92908288701328,
                                40.67666584844579
                            ],
                            [
                                -73.93286367940183,
                                40.67610208412483
                            ],
                            [
                                -73.93333946868275,
                                40.677328055857416
                            ],
                            [
                                -73.93861054112315,
                                40.67698164821458
                            ],
                            [
                                -73.93937439962008,
                                40.675131252779
                            ],
                            [
                                -73.94626892704862,
                                40.67410319088756
                            ],
                            [
                                -73.95007777458956,
                                40.683837964672385
                            ],
                            [
                                -73.94905404891374,
                                40.68425627398007
                            ],
                            [
                                -73.9440215439205,
                                40.68338584479344
                            ]
                        ],
                        [
                            [
                                -73.94359937996929,
                                40.68016025853013
                            ],
                            [
                                -73.94306869572361,
                                40.680129851768626
                            ],
                            [
                                -73.9433092879129,
                                40.68030673248967
                            ],
                            [
                                -73.94359937996929,
                                40.68016025853013
                            ]
                        ],
                        [
                            [
                                -73.93831985438204,
                                40.681710878702894
                            ],
                            [
                                -73.93786428905707,
                                40.68154574138513
                            ],
                            [
                                -73.93751755193381,
                                40.681804295110325
                            ],
                            [
                                -73.9378417862592,
                                40.68199343280711
                            ],
                            [
                                -73.93831985438204,
                                40.681710878702894
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "dcfa79c6-30e5-4656-9708-58001f16e30e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9440215439205,
                                40.68338584479344
                            ],
                            [
                                -73.94905404891374,
                                40.68425627398007
                            ],
                            [
                                -73.94456015331848,
                                40.68609254555132
                            ],
                            [
                                -73.9440215439205,
                                40.68338584479344
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "4c34b25d-fc8f-4d60-bbeb-7a691ce30061"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9440215439205,
                                40.68338584479344
                            ],
                            [
                                -73.94306397993981,
                                40.68322022317201
                            ],
                            [
                                -73.94356664805528,
                                40.68109983384055
                            ],
                            [
                                -73.9440215439205,
                                40.68338584479344
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "186e9f76-12b8-4cb0-b841-ae87e0e1d5ee"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94306397993981,
                                40.68322022317201
                            ],
                            [
                                -73.9415074165936,
                                40.68978622609004
                            ],
                            [
                                -73.9380732335989,
                                40.68257646786283
                            ],
                            [
                                -73.93743872519612,
                                40.69421366880238
                            ],
                            [
                                -73.93115195328663,
                                40.69287864532601
                            ],
                            [
                                -73.9302342837997,
                                40.6882398089659
                            ],
                            [
                                -73.9253863223497,
                                40.69129103383992
                            ],
                            [
                                -73.92078687712811,
                                40.68688341600033
                            ],
                            [
                                -73.91955858908057,
                                40.69204745464828
                            ],
                            [
                                -73.92574468726599,
                                40.69520083262076
                            ],
                            [
                                -73.91376552856192,
                                40.69127791899819
                            ],
                            [
                                -73.9001205340742,
                                40.68108398257891
                            ],
                            [
                                -73.90098515881002,
                                40.681349347954246
                            ],
                            [
                                -73.90248634785436,
                                40.68138364468672
                            ],
                            [
                                -73.90257831108495,
                                40.68076080796182
                            ],
                            [
                                -73.90252702564332,
                                40.680748415682565
                            ],
                            [
                                -73.90252885433698,
                                40.680719858593356
                            ],
                            [
                                -73.90233126571519,
                                40.68070111353172
                            ],
                            [
                                -73.89985279517347,
                                40.680102232092906
                            ],
                            [
                                -73.90032838703107,
                                40.67969236728078
                            ],
                            [
                                -73.89929965738344,
                                40.67996857548897
                            ],
                            [
                                -73.89830619518703,
                                40.67972852177052
                            ],
                            [
                                -73.89531794999058,
                                40.677496056116894
                            ],
                            [
                                -73.87742993068856,
                                40.68216505360607
                            ],
                            [
                                -73.90282023457749,
                                40.67284312555929
                            ],
                            [
                                -73.91669748986212,
                                40.678512668978975
                            ],
                            [
                                -73.91624054928924,
                                40.678580804640845
                            ],
                            [
                                -73.91732217702898,
                                40.678767884505646
                            ],
                            [
                                -73.92584152745025,
                                40.68224845945582
                            ],
                            [
                                -73.9274295376925,
                                40.680516067911945
                            ],
                            [
                                -73.94306397993981,
                                40.68322022317201
                            ]
                        ],
                        [
                            [
                                -73.90280895173751,
                                40.67581194263769
                            ],
                            [
                                -73.90302845559076,
                                40.675706926980766
                            ],
                            [
                                -73.90343036043699,
                                40.67575021288465
                            ],
                            [
                                -73.90467938360833,
                                40.67491708530954
                            ],
                            [
                                -73.90527192473574,
                                40.674633600100385
                            ],
                            [
                                -73.90064078964586,
                                40.675486303187675
                            ],
                            [
                                -73.90280895173751,
                                40.67581194263769
                            ]
                        ],
                        [
                            [
                                -73.8979798099927,
                                40.67777854020317
                            ],
                            [
                                -73.89968712197056,
                                40.67696593383257
                            ],
                            [
                                -73.89697440744189,
                                40.677233680071254
                            ],
                            [
                                -73.8979798099927,
                                40.67777854020317
                            ]
                        ],
                        [
                            [
                                -73.89114508774794,
                                40.67810953888813
                            ],
                            [
                                -73.89223130902995,
                                40.67795021749909
                            ],
                            [
                                -73.89158173904463,
                                40.6776731652399
                            ],
                            [
                                -73.89114508774794,
                                40.67810953888813
                            ]
                        ],
                        [
                            [
                                -73.90040976002277,
                                40.67723046785309
                            ],
                            [
                                -73.90100726929104,
                                40.67757734936617
                            ],
                            [
                                -73.90422851711948,
                                40.68111815582699
                            ],
                            [
                                -73.90339045996701,
                                40.68122212596314
                            ],
                            [
                                -73.90837278962059,
                                40.684044174656464
                            ],
                            [
                                -73.90767471240656,
                                40.68069061769055
                            ],
                            [
                                -73.90586237987544,
                                40.68091545732047
                            ],
                            [
                                -73.91587148543792,
                                40.67855904715355
                            ],
                            [
                                -73.9079548870622,
                                40.675945326999
                            ],
                            [
                                -73.9006669171459,
                                40.67720323314205
                            ],
                            [
                                -73.90078127011397,
                                40.67732893030965
                            ],
                            [
                                -73.90040976002277,
                                40.67723046785309
                            ]
                        ],
                        [
                            [
                                -73.90918565535824,
                                40.68354334615367
                            ],
                            [
                                -73.91212195876452,
                                40.6838332250231
                            ],
                            [
                                -73.91208673801877,
                                40.68381202038195
                            ],
                            [
                                -73.91218487137712,
                                40.683867248675284
                            ],
                            [
                                -73.91191870325338,
                                40.68249290301742
                            ],
                            [
                                -73.90883286718324,
                                40.68198078036334
                            ],
                            [
                                -73.90921559945414,
                                40.682196177558346
                            ],
                            [
                                -73.90918565535824,
                                40.68354334615367
                            ]
                        ],
                        [
                            [
                                -73.91983778277591,
                                40.68600565956078
                            ],
                            [
                                -73.91422188572551,
                                40.685143210968654
                            ],
                            [
                                -73.91485059323665,
                                40.688385813559634
                            ],
                            [
                                -73.91983778277591,
                                40.68600565956078
                            ]
                        ],
                        [
                            [
                                -73.92998448926865,
                                40.6869740752474
                            ],
                            [
                                -73.92990356723122,
                                40.686587585978714
                            ],
                            [
                                -73.92994394111335,
                                40.686780721564034
                            ],
                            [
                                -73.92998448926865,
                                40.6869740752474
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "3cec551b-7ce3-4532-8cfb-ef5c828bb68b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91980998285058,
                                40.67919817892179
                            ],
                            [
                                -73.92147401016702,
                                40.6788632963933
                            ],
                            [
                                -73.91850342345626,
                                40.67870149769562
                            ],
                            [
                                -73.91905522773041,
                                40.679067635407925
                            ],
                            [
                                -73.91732217702898,
                                40.678767884505646
                            ],
                            [
                                -73.91669748986212,
                                40.678512668978975
                            ],
                            [
                                -73.92908288701328,
                                40.67666584844579
                            ],
                            [
                                -73.93020153212277,
                                40.67749204477729
                            ],
                            [
                                -73.9274295376925,
                                40.680516067911945
                            ],
                            [
                                -73.91980998285058,
                                40.67919817892179
                            ]
                        ],
                        [
                            [
                                -73.9217854776356,
                                40.67839719730384
                            ],
                            [
                                -73.9217440895555,
                                40.678513003989586
                            ],
                            [
                                -73.92176665703207,
                                40.67862735189195
                            ],
                            [
                                -73.9217854776356,
                                40.67839719730384
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b1fd6347-b8fa-41c4-82ca-18dff8655c1f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91980998285058,
                                40.67919817892179
                            ],
                            [
                                -73.9274295376925,
                                40.680516067911945
                            ],
                            [
                                -73.92584152745025,
                                40.68224845945582
                            ],
                            [
                                -73.91732217702898,
                                40.678767884505646
                            ],
                            [
                                -73.91905522773041,
                                40.679067635407925
                            ],
                            [
                                -73.91938182848041,
                                40.67928434422487
                            ],
                            [
                                -73.91980998285058,
                                40.67919817892179
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "2ac8a09e-65c1-4520-aca6-9951cb20e38e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91980998285058,
                                40.67919817892179
                            ],
                            [
                                -73.91905522773041,
                                40.679067635407925
                            ],
                            [
                                -73.91850342345626,
                                40.67870149769562
                            ],
                            [
                                -73.92147401016702,
                                40.6788632963933
                            ],
                            [
                                -73.91980998285058,
                                40.67919817892179
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "28086594-4c76-4f08-9401-7960c473fb05"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91905522773041,
                                40.679067635407925
                            ],
                            [
                                -73.91980998285058,
                                40.67919817892179
                            ],
                            [
                                -73.91938182848041,
                                40.67928434422487
                            ],
                            [
                                -73.91905522773041,
                                40.679067635407925
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "9899cc02-11ce-4284-9770-9b8936c639a4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91732217702898,
                                40.678767884505646
                            ],
                            [
                                -73.91624054928924,
                                40.678580804640845
                            ],
                            [
                                -73.91669748986212,
                                40.678512668978975
                            ],
                            [
                                -73.91732217702898,
                                40.678767884505646
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "42f8c7c1-a0c3-4301-92c0-18c7e4a1d7d4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91669748986212,
                                40.678512668978975
                            ],
                            [
                                -73.90282023457749,
                                40.67284312555929
                            ],
                            [
                                -73.87742993068856,
                                40.68216505360607
                            ],
                            [
                                -73.89531794999058,
                                40.677496056116894
                            ],
                            [
                                -73.89830619518703,
                                40.67972852177052
                            ],
                            [
                                -73.89564696335327,
                                40.679085962353355
                            ],
                            [
                                -73.88579222104543,
                                40.68359524727468
                            ],
                            [
                                -73.88040141382571,
                                40.6850426491322
                            ],
                            [
                                -73.87661595553153,
                                40.68239105947644
                            ],
                            [
                                -73.87403509033037,
                                40.686709238622754
                            ],
                            [
                                -73.86921279374803,
                                40.68449681457991
                            ],
                            [
                                -73.87702662126198,
                                40.681202066977605
                            ],
                            [
                                -73.8795839497044,
                                40.6769000561543
                            ],
                            [
                                -73.86124894937825,
                                40.67663960170464
                            ],
                            [
                                -73.88696792527006,
                                40.67059496195513
                            ],
                            [
                                -73.8876632987011,
                                40.66889055361166
                            ],
                            [
                                -73.89483092566435,
                                40.66783296222441
                            ],
                            [
                                -73.89029908854934,
                                40.665311987173325
                            ],
                            [
                                -73.89134077869397,
                                40.66362238797993
                            ],
                            [
                                -73.8917379246315,
                                40.66350696280311
                            ],
                            [
                                -73.89178884885253,
                                40.66289562774095
                            ],
                            [
                                -73.89760991160648,
                                40.653453988128234
                            ],
                            [
                                -73.89532244319811,
                                40.650117461051885
                            ],
                            [
                                -73.89694851619876,
                                40.64613184373363
                            ],
                            [
                                -73.89914547476538,
                                40.65660807339238
                            ],
                            [
                                -73.89896278430582,
                                40.65682577701808
                            ],
                            [
                                -73.8991682996376,
                                40.65671691413562
                            ],
                            [
                                -73.89932424481658,
                                40.65746054104519
                            ],
                            [
                                -73.90079561159014,
                                40.65712188699313
                            ],
                            [
                                -73.90011366901692,
                                40.65718625701219
                            ],
                            [
                                -73.90243231059677,
                                40.655329894121685
                            ],
                            [
                                -73.9030719541982,
                                40.65496524147148
                            ],
                            [
                                -73.90385549353509,
                                40.65469942648519
                            ],
                            [
                                -73.9049006501305,
                                40.66147644322426
                            ],
                            [
                                -73.907423501796,
                                40.66160624568882
                            ],
                            [
                                -73.90763197100111,
                                40.662804776683984
                            ],
                            [
                                -73.90535647417028,
                                40.66316813774602
                            ],
                            [
                                -73.90878758098624,
                                40.66944860873047
                            ],
                            [
                                -73.90886364975307,
                                40.66988594322679
                            ],
                            [
                                -73.90901382926388,
                                40.669862744990056
                            ],
                            [
                                -73.91127196745279,
                                40.673996155033805
                            ],
                            [
                                -73.92476286728801,
                                40.6771966547341
                            ],
                            [
                                -73.92696882125176,
                                40.675104465517556
                            ],
                            [
                                -73.92908288701328,
                                40.67666584844579
                            ],
                            [
                                -73.91669748986212,
                                40.678512668978975
                            ]
                        ],
                        [
                            [
                                -73.8991424788037,
                                40.65882352548321
                            ],
                            [
                                -73.89923191148011,
                                40.65873301331057
                            ],
                            [
                                -73.8990984568632,
                                40.65865311621516
                            ],
                            [
                                -73.8991424788037,
                                40.65882352548321
                            ]
                        ],
                        [
                            [
                                -73.89924115334777,
                                40.663979494940534
                            ],
                            [
                                -73.90082400014974,
                                40.665250196594414
                            ],
                            [
                                -73.90018303332991,
                                40.66289347241547
                            ],
                            [
                                -73.89924115334777,
                                40.663979494940534
                            ]
                        ],
                        [
                            [
                                -73.9019065042031,
                                40.66937711687831
                            ],
                            [
                                -73.90175292103558,
                                40.66878205684252
                            ],
                            [
                                -73.90197371922346,
                                40.66964971279114
                            ],
                            [
                                -73.90205874499601,
                                40.66999454626805
                            ],
                            [
                                -73.90145645273732,
                                40.667625533056025
                            ],
                            [
                                -73.90000042739221,
                                40.66995934585973
                            ],
                            [
                                -73.90235596637403,
                                40.67116361553847
                            ],
                            [
                                -73.90210185831172,
                                40.67016412509475
                            ],
                            [
                                -73.90198208845109,
                                40.66966998717151
                            ],
                            [
                                -73.9019065284576,
                                40.669377211586884
                            ],
                            [
                                -73.90190656055965,
                                40.66937734178109
                            ],
                            [
                                -73.9019065042031,
                                40.66937711687831
                            ]
                        ],
                        [
                            [
                                -73.89260451950463,
                                40.67237777267403
                            ],
                            [
                                -73.89090793790284,
                                40.671393085827866
                            ],
                            [
                                -73.88811074310989,
                                40.67304305556965
                            ],
                            [
                                -73.89063898320508,
                                40.67398293285992
                            ],
                            [
                                -73.89260451950463,
                                40.67237777267403
                            ]
                        ],
                        [
                            [
                                -73.87963205448786,
                                40.67428997045101
                            ],
                            [
                                -73.87992355689923,
                                40.67442149176259
                            ],
                            [
                                -73.88184885855551,
                                40.673971120313226
                            ],
                            [
                                -73.88060571057653,
                                40.67332708876042
                            ],
                            [
                                -73.87963205448786,
                                40.67428997045101
                            ]
                        ],
                        [
                            [
                                -73.89616237174754,
                                40.67444985244427
                            ],
                            [
                                -73.89662928279456,
                                40.674863052997516
                            ],
                            [
                                -73.89685655656145,
                                40.67434641257878
                            ],
                            [
                                -73.8964506042913,
                                40.674169076599426
                            ],
                            [
                                -73.89616237174754,
                                40.67444985244427
                            ]
                        ],
                        [
                            [
                                -73.87408752858676,
                                40.67509781446202
                            ],
                            [
                                -73.87241018350848,
                                40.67423626849226
                            ],
                            [
                                -73.87051658339857,
                                40.675612895029
                            ],
                            [
                                -73.87218260790765,
                                40.67649485355499
                            ],
                            [
                                -73.87408752858676,
                                40.67509781446202
                            ]
                        ],
                        [
                            [
                                -73.87422641082463,
                                40.68359145331967
                            ],
                            [
                                -73.87474048252574,
                                40.68292438530454
                            ],
                            [
                                -73.87274966763411,
                                40.68349069730986
                            ],
                            [
                                -73.87422641082463,
                                40.68359145331967
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "a74c90c9-7718-4346-9b4c-62de2d87912e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93286367940183,
                                40.67610208412483
                            ],
                            [
                                -73.92908288701328,
                                40.67666584844579
                            ],
                            [
                                -73.92696882125176,
                                40.675104465517556
                            ],
                            [
                                -73.92976746418401,
                                40.672450153297085
                            ],
                            [
                                -73.93138920876841,
                                40.672302798622624
                            ],
                            [
                                -73.93286367940183,
                                40.67610208412483
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "b4cec161-c55e-46bd-81a4-a61dd8ae24f6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93286367940183,
                                40.67610208412483
                            ],
                            [
                                -73.93937439962008,
                                40.675131252779
                            ],
                            [
                                -73.93861054112315,
                                40.67698164821458
                            ],
                            [
                                -73.93333946868275,
                                40.677328055857416
                            ],
                            [
                                -73.93286367940183,
                                40.67610208412483
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "fbfefdac-1a68-4fd4-bf99-59255d44eeb4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93937439962008,
                                40.675131252779
                            ],
                            [
                                -73.93286367940183,
                                40.67610208412483
                            ],
                            [
                                -73.93138920876841,
                                40.672302798622624
                            ],
                            [
                                -73.93911688641452,
                                40.67160064771993
                            ],
                            [
                                -73.9407533935879,
                                40.67179073335819
                            ],
                            [
                                -73.93937439962008,
                                40.675131252779
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "d3321958-8f0c-49ae-a61d-addff2997e7d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94626892704862,
                                40.67410319088756
                            ],
                            [
                                -73.93937439962008,
                                40.675131252779
                            ],
                            [
                                -73.9407533935879,
                                40.67179073335819
                            ],
                            [
                                -73.94558366860038,
                                40.67235178553301
                            ],
                            [
                                -73.94626892704862,
                                40.67410319088756
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "e9643e7e-1788-4752-bb11-4b0dc46c1af3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95722657579266,
                                40.67246926583462
                            ],
                            [
                                -73.95924247793525,
                                40.67746866618389
                            ],
                            [
                                -73.97164865577668,
                                40.679125140775994
                            ],
                            [
                                -73.97061272814858,
                                40.678082348625686
                            ],
                            [
                                -73.97192475477588,
                                40.67837984072643
                            ],
                            [
                                -73.9707484047749,
                                40.67279386287366
                            ],
                            [
                                -73.97084212316176,
                                40.672682990348974
                            ],
                            [
                                -73.9722565397004,
                                40.6732262735201
                            ],
                            [
                                -73.98077637269992,
                                40.68046513538063
                            ],
                            [
                                -73.97755861210568,
                                40.67526282119274
                            ],
                            [
                                -73.98953717274536,
                                40.679863835273196
                            ],
                            [
                                -73.98514791174068,
                                40.67039401416778
                            ],
                            [
                                -73.98432354068055,
                                40.670297176387
                            ],
                            [
                                -73.98424689252755,
                                40.66895878370414
                            ],
                            [
                                -73.99542711613097,
                                40.6736544312205
                            ],
                            [
                                -73.99606219450772,
                                40.674231087833626
                            ],
                            [
                                -73.99639704072453,
                                40.674061795524686
                            ],
                            [
                                -73.997721672095,
                                40.67461813520316
                            ],
                            [
                                -73.99712618518137,
                                40.6738437220889
                            ],
                            [
                                -73.99801136080782,
                                40.67407333849323
                            ],
                            [
                                -73.99846192984846,
                                40.67259672790469
                            ],
                            [
                                -73.99965261347042,
                                40.674459425336394
                            ],
                            [
                                -73.99818718177018,
                                40.677848971304
                            ],
                            [
                                -74.00232029535812,
                                40.68174255387524
                            ],
                            [
                                -73.99634938141247,
                                40.69067727187861
                            ],
                            [
                                -73.99713580472086,
                                40.691923125944584
                            ],
                            [
                                -73.99386128253575,
                                40.695857773283926
                            ],
                            [
                                -73.995042290808,
                                40.70208312693195
                            ],
                            [
                                -73.99462489914542,
                                40.70262997810267
                            ],
                            [
                                -73.98731746622101,
                                40.69426225435205
                            ],
                            [
                                -73.9879494165255,
                                40.69316259699168
                            ],
                            [
                                -73.98868373280126,
                                40.692270924562195
                            ],
                            [
                                -73.98846447435074,
                                40.69226634435197
                            ],
                            [
                                -73.98986976948773,
                                40.68982098887079
                            ],
                            [
                                -73.98577304405494,
                                40.68849562367353
                            ],
                            [
                                -73.9944535139548,
                                40.687037482561585
                            ],
                            [
                                -73.99252380696036,
                                40.68350572962272
                            ],
                            [
                                -73.97400642347873,
                                40.68244938173357
                            ],
                            [
                                -73.95582651369313,
                                40.68148894566146
                            ],
                            [
                                -73.95007777458956,
                                40.683837964672385
                            ],
                            [
                                -73.94626892704862,
                                40.67410319088756
                            ],
                            [
                                -73.95722657579266,
                                40.67246926583462
                            ]
                        ],
                        [
                            [
                                -73.99591646620907,
                                40.67858929317235
                            ],
                            [
                                -73.99587063990079,
                                40.67860060067131
                            ],
                            [
                                -73.99607120910159,
                                40.678276492797885
                            ],
                            [
                                -73.9924401028405,
                                40.67946457078206
                            ],
                            [
                                -73.99372463530631,
                                40.68206841618201
                            ],
                            [
                                -73.99391093578625,
                                40.68176736571098
                            ],
                            [
                                -73.99391860403402,
                                40.68178215718641
                            ],
                            [
                                -73.99591646620907,
                                40.67858929317235
                            ]
                        ],
                        [
                            [
                                -73.95537004376466,
                                40.67963908183098
                            ],
                            [
                                -73.95515548565062,
                                40.680936157628096
                            ],
                            [
                                -73.95619578864856,
                                40.68115531851593
                            ],
                            [
                                -73.95537004376466,
                                40.67963908183098
                            ]
                        ],
                        [
                            [
                                -73.94997600633491,
                                40.6804469318747
                            ],
                            [
                                -73.95076069102379,
                                40.680454880623756
                            ],
                            [
                                -73.94988967733778,
                                40.68040757438052
                            ],
                            [
                                -73.94997600633491,
                                40.6804469318747
                            ]
                        ],
                        [
                            [
                                -73.95674434218185,
                                40.68135477943517
                            ],
                            [
                                -73.95684919533845,
                                40.681376915457335
                            ],
                            [
                                -73.95698200137173,
                                40.68140483855303
                            ],
                            [
                                -73.95674434218185,
                                40.68135477943517
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "771cffe5-b506-47ad-8509-6e4955f2dc31"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95722657579266,
                                40.67246926583462
                            ],
                            [
                                -73.94626892704862,
                                40.67410319088756
                            ],
                            [
                                -73.94558366860038,
                                40.67235178553301
                            ],
                            [
                                -73.9407533935879,
                                40.67179073335819
                            ],
                            [
                                -73.9439197955564,
                                40.664120339203684
                            ],
                            [
                                -73.9479165682404,
                                40.66875795567562
                            ],
                            [
                                -73.95311969756244,
                                40.66953206713695
                            ],
                            [
                                -73.95446987795104,
                                40.67269246618681
                            ],
                            [
                                -73.95653388174387,
                                40.67075141132881
                            ],
                            [
                                -73.95755180275644,
                                40.67211111545574
                            ],
                            [
                                -73.96069249078239,
                                40.67078183871586
                            ],
                            [
                                -73.96102309064365,
                                40.67116959700799
                            ],
                            [
                                -73.96101670413829,
                                40.67119218981487
                            ],
                            [
                                -73.96064825870988,
                                40.67109012020636
                            ],
                            [
                                -73.96096025002953,
                                40.671391901034866
                            ],
                            [
                                -73.95924247793525,
                                40.67746866618389
                            ],
                            [
                                -73.95722657579266,
                                40.67246926583462
                            ]
                        ],
                        [
                            [
                                -73.95054893655175,
                                40.66934613694687
                            ],
                            [
                                -73.9498875914497,
                                40.66969796150283
                            ],
                            [
                                -73.95046343939458,
                                40.67026298025147
                            ],
                            [
                                -73.95054893655175,
                                40.66934613694687
                            ]
                        ],
                        [
                            [
                                -73.94497836220236,
                                40.670586561661864
                            ],
                            [
                                -73.94496457396107,
                                40.67058284765401
                            ],
                            [
                                -73.9448990894718,
                                40.67099200457144
                            ],
                            [
                                -73.94497836220236,
                                40.670586561661864
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f515812d-4f77-4135-81e4-4a3ba21d35ca"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95924247793525,
                                40.67746866618389
                            ],
                            [
                                -73.9687071556165,
                                40.67771274099286
                            ],
                            [
                                -73.96865139512892,
                                40.67763763121818
                            ],
                            [
                                -73.97061272814858,
                                40.678082348625686
                            ],
                            [
                                -73.97164865577668,
                                40.679125140775994
                            ],
                            [
                                -73.95924247793525,
                                40.67746866618389
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b443451e-adcd-4a14-96d2-30501a7ced77"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96865139512892,
                                40.67763763121818
                            ],
                            [
                                -73.9687071556165,
                                40.67771274099286
                            ],
                            [
                                -73.95924247793525,
                                40.67746866618389
                            ],
                            [
                                -73.96096025002953,
                                40.671391901034866
                            ],
                            [
                                -73.96303279199353,
                                40.67339661524203
                            ],
                            [
                                -73.96364096733284,
                                40.6765015546633
                            ],
                            [
                                -73.96865139512892,
                                40.67763763121818
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ebdeae81-c2de-4449-b2f4-6cac5bde68fb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96865139512892,
                                40.67763763121818
                            ],
                            [
                                -73.96690856312767,
                                40.67529002407599
                            ],
                            [
                                -73.97087266049155,
                                40.67205046286454
                            ],
                            [
                                -73.97203848653182,
                                40.67304100501007
                            ],
                            [
                                -73.97070697971212,
                                40.67259715315033
                            ],
                            [
                                -73.9707484047749,
                                40.67279386287366
                            ],
                            [
                                -73.96827082950995,
                                40.67572493151378
                            ],
                            [
                                -73.97061272814858,
                                40.678082348625686
                            ],
                            [
                                -73.96865139512892,
                                40.67763763121818
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a47be656-b064-499d-9839-9598aed0f5bb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97087266049155,
                                40.67205046286454
                            ],
                            [
                                -73.96690856312767,
                                40.67529002407599
                            ],
                            [
                                -73.96865139512892,
                                40.67763763121818
                            ],
                            [
                                -73.96364096733284,
                                40.6765015546633
                            ],
                            [
                                -73.96303279199353,
                                40.67339661524203
                            ],
                            [
                                -73.96304463779946,
                                40.67340807337212
                            ],
                            [
                                -73.96744282176986,
                                40.672972402731894
                            ],
                            [
                                -73.96490506357968,
                                40.672269373287136
                            ],
                            [
                                -73.96267536037168,
                                40.671571806734754
                            ],
                            [
                                -73.96081224975826,
                                40.662060001148745
                            ],
                            [
                                -73.96057626551685,
                                40.662390296896895
                            ],
                            [
                                -73.94728421435902,
                                40.6595659376407
                            ],
                            [
                                -73.94643879600157,
                                40.65752136781694
                            ],
                            [
                                -73.94724993206131,
                                40.65723045582358
                            ],
                            [
                                -73.94627030311064,
                                40.64701068601902
                            ],
                            [
                                -73.94872827425304,
                                40.642941512422254
                            ],
                            [
                                -73.9502700697098,
                                40.64457209487142
                            ],
                            [
                                -73.95112820518659,
                                40.64752195386415
                            ],
                            [
                                -73.95118545940588,
                                40.64802334894785
                            ],
                            [
                                -73.95452564756268,
                                40.64907273763019
                            ],
                            [
                                -73.95598380869939,
                                40.65061486953047
                            ],
                            [
                                -73.95308416913312,
                                40.65531595607896
                            ],
                            [
                                -73.95931812955689,
                                40.65351935738498
                            ],
                            [
                                -73.96062058531024,
                                40.63444837665347
                            ],
                            [
                                -73.9610597527347,
                                40.63198525519339
                            ],
                            [
                                -73.96498608790212,
                                40.63288330307843
                            ],
                            [
                                -73.96644636600337,
                                40.63524613192678
                            ],
                            [
                                -73.96538208111821,
                                40.63627902886098
                            ],
                            [
                                -73.96770925669324,
                                40.643930747176945
                            ],
                            [
                                -73.96994911167631,
                                40.642724844930505
                            ],
                            [
                                -73.97018142754166,
                                40.64365843397939
                            ],
                            [
                                -73.9656310267413,
                                40.651854535115184
                            ],
                            [
                                -73.97455543784777,
                                40.647655050815594
                            ],
                            [
                                -73.97376879572988,
                                40.61285598113665
                            ],
                            [
                                -73.97632506387468,
                                40.618503047821925
                            ],
                            [
                                -73.9814320889584,
                                40.634954992114764
                            ],
                            [
                                -73.98534661872746,
                                40.63843261662014
                            ],
                            [
                                -73.98741097365557,
                                40.64299299498451
                            ],
                            [
                                -73.97902078795364,
                                40.65249741233733
                            ],
                            [
                                -73.98390647684896,
                                40.65420069945425
                            ],
                            [
                                -73.98409593036524,
                                40.65652603766183
                            ],
                            [
                                -73.98408908907486,
                                40.65652829253059
                            ],
                            [
                                -73.98409649316119,
                                40.65653294537701
                            ],
                            [
                                -73.98410558932999,
                                40.6566445910685
                            ],
                            [
                                -73.99049329102475,
                                40.66059083247911
                            ],
                            [
                                -73.99513998942693,
                                40.65957893206996
                            ],
                            [
                                -73.99435084819591,
                                40.65798085526754
                            ],
                            [
                                -73.992143861816,
                                40.65832046174255
                            ],
                            [
                                -73.99145484590825,
                                40.65738925495952
                            ],
                            [
                                -73.99117002336932,
                                40.65740286614067
                            ],
                            [
                                -73.99086272498197,
                                40.65750356548835
                            ],
                            [
                                -73.99049746549588,
                                40.657538763134035
                            ],
                            [
                                -73.990245654247,
                                40.657288554076324
                            ],
                            [
                                -73.99050333205778,
                                40.65696614719839
                            ],
                            [
                                -73.99176430684666,
                                40.655594727708994
                            ],
                            [
                                -73.9969171391299,
                                40.65454144966071
                            ],
                            [
                                -73.99666934402366,
                                40.65026007420116
                            ],
                            [
                                -73.99702497828652,
                                40.64987329108425
                            ],
                            [
                                -73.99708029378704,
                                40.649906402496036
                            ],
                            [
                                -73.99738957391762,
                                40.65051806165527
                            ],
                            [
                                -74.00107226862883,
                                40.65367915559847
                            ],
                            [
                                -74.0020827107284,
                                40.65290080886914
                            ],
                            [
                                -73.9978226112919,
                                40.65027770876925
                            ],
                            [
                                -73.99815912918048,
                                40.64962364146397
                            ],
                            [
                                -73.9917890703979,
                                40.64577454228578
                            ],
                            [
                                -73.9918158753679,
                                40.645375537790585
                            ],
                            [
                                -73.99458701258916,
                                40.646305183795334
                            ],
                            [
                                -73.99289900989834,
                                40.644618067399065
                            ],
                            [
                                -73.99230806935836,
                                40.64517704352105
                            ],
                            [
                                -73.99204055735495,
                                40.64541844808596
                            ],
                            [
                                -73.99182410996602,
                                40.645252961952565
                            ],
                            [
                                -73.99255460480157,
                                40.63437920521798
                            ],
                            [
                                -73.99713533362421,
                                40.63709494224194
                            ],
                            [
                                -73.99781322411721,
                                40.64758385805235
                            ],
                            [
                                -73.99518930783434,
                                40.64600216760249
                            ],
                            [
                                -73.99446978963111,
                                40.64668859269663
                            ],
                            [
                                -73.99508808926139,
                                40.64670139224434
                            ],
                            [
                                -73.99788537140982,
                                40.64870018419343
                            ],
                            [
                                -73.99789060118249,
                                40.648781103819616
                            ],
                            [
                                -73.99407978874547,
                                40.647073191486534
                            ],
                            [
                                -73.99834518195392,
                                40.64965147289725
                            ],
                            [
                                -73.99974400168344,
                                40.6514086321053
                            ],
                            [
                                -73.99833936266751,
                                40.649579248821496
                            ],
                            [
                                -74.00096960286996,
                                40.65145923167615
                            ],
                            [
                                -74.00192188534308,
                                40.6508260326309
                            ],
                            [
                                -74.00228797397419,
                                40.65098899693508
                            ],
                            [
                                -74.0022156928315,
                                40.65289223187681
                            ],
                            [
                                -74.00513591010595,
                                40.64972430279937
                            ],
                            [
                                -74.00373910091345,
                                40.64654961422801
                            ],
                            [
                                -74.00397487462672,
                                40.64556798459601
                            ],
                            [
                                -74.00578251115927,
                                40.64601660788172
                            ],
                            [
                                -74.00407566198744,
                                40.645148362499654
                            ],
                            [
                                -74.00410945463813,
                                40.64500766883712
                            ],
                            [
                                -74.01039861136772,
                                40.642292091114136
                            ],
                            [
                                -74.01439691096623,
                                40.641186007516865
                            ],
                            [
                                -74.0140232452801,
                                40.640729782393066
                            ],
                            [
                                -74.02083589029402,
                                40.63829823194307
                            ],
                            [
                                -74.02035236214878,
                                40.637363753449684
                            ],
                            [
                                -74.02189901050018,
                                40.637327906114216
                            ],
                            [
                                -74.01830147055043,
                                40.63340014944895
                            ],
                            [
                                -74.01807098364733,
                                40.63295470473996
                            ],
                            [
                                -74.0182555879198,
                                40.632818359883466
                            ],
                            [
                                -74.02424066871421,
                                40.63284873607063
                            ],
                            [
                                -74.0227499948427,
                                40.629498884885486
                            ],
                            [
                                -74.02456243926332,
                                40.62816025132545
                            ],
                            [
                                -74.02884252480243,
                                40.62987029857741
                            ],
                            [
                                -74.02905435224962,
                                40.63018347952625
                            ],
                            [
                                -74.02388408425881,
                                40.6337239758856
                            ],
                            [
                                -74.0255163665987,
                                40.63470267669797
                            ],
                            [
                                -74.02150028876974,
                                40.638094291781734
                            ],
                            [
                                -74.02164211526949,
                                40.6418650095329
                            ],
                            [
                                -74.02066594519816,
                                40.64262601285724
                            ],
                            [
                                -74.0199942101953,
                                40.6427441675719
                            ],
                            [
                                -74.02004543498593,
                                40.643109750628106
                            ],
                            [
                                -74.01836588887701,
                                40.6444190923195
                            ],
                            [
                                -74.02071806028914,
                                40.64791016876552
                            ],
                            [
                                -74.02083176433193,
                                40.64872165615194
                            ],
                            [
                                -74.00487598796839,
                                40.66087678722672
                            ],
                            [
                                -74.00580424679588,
                                40.660615355005405
                            ],
                            [
                                -74.0063112361793,
                                40.661723737750684
                            ],
                            [
                                -74.00844730146584,
                                40.662637131532726
                            ],
                            [
                                -74.00894010106104,
                                40.662363631983666
                            ],
                            [
                                -74.00951041807873,
                                40.6626915494706
                            ],
                            [
                                -74.00534385911928,
                                40.663495721407884
                            ],
                            [
                                -74.00127241237583,
                                40.66071206781977
                            ],
                            [
                                -73.99843706486855,
                                40.664828775894115
                            ],
                            [
                                -73.99683288883436,
                                40.66513839190141
                            ],
                            [
                                -73.9978029394089,
                                40.66574947763992
                            ],
                            [
                                -73.99651845153547,
                                40.66761445594908
                            ],
                            [
                                -73.99792814116867,
                                40.67007317445539
                            ],
                            [
                                -73.99763323385359,
                                40.67010105370962
                            ],
                            [
                                -73.99925021304311,
                                40.6725756839542
                            ],
                            [
                                -73.99856698767567,
                                40.67225243105761
                            ],
                            [
                                -73.9985138916667,
                                40.67242643797326
                            ],
                            [
                                -73.99563381123491,
                                40.66804281846359
                            ],
                            [
                                -73.99592953130656,
                                40.668097533844126
                            ],
                            [
                                -73.99570276424889,
                                40.665529673135175
                            ],
                            [
                                -74.0005910096901,
                                40.65789892102727
                            ],
                            [
                                -73.99956579964721,
                                40.658647858282905
                            ],
                            [
                                -73.99582723295863,
                                40.6590728796823
                            ],
                            [
                                -73.99619000987302,
                                40.661113942912706
                            ],
                            [
                                -73.99304119676304,
                                40.66341421636932
                            ],
                            [
                                -73.99039595137718,
                                40.66405854683935
                            ],
                            [
                                -73.99064607686546,
                                40.66516390126117
                            ],
                            [
                                -73.98775248078725,
                                40.66727773336881
                            ],
                            [
                                -73.98369398755501,
                                40.664541085606174
                            ],
                            [
                                -73.98368947505779,
                                40.66447459255248
                            ],
                            [
                                -73.98441888936175,
                                40.6634903709876
                            ],
                            [
                                -73.98362007609195,
                                40.663451977150295
                            ],
                            [
                                -73.98319931856571,
                                40.657251983796876
                            ],
                            [
                                -73.97391355744128,
                                40.65689014187097
                            ],
                            [
                                -73.97402301253807,
                                40.65749808700339
                            ],
                            [
                                -73.97380740442247,
                                40.657497550763644
                            ],
                            [
                                -73.97514123200254,
                                40.66370899997483
                            ],
                            [
                                -73.97619400984176,
                                40.669556431311285
                            ],
                            [
                                -73.98435731611714,
                                40.67088694659566
                            ],
                            [
                                -73.98459567960629,
                                40.67504913364423
                            ],
                            [
                                -73.97840927171389,
                                40.67516467783641
                            ],
                            [
                                -73.97203848653182,
                                40.67304100501007
                            ],
                            [
                                -73.97087266049155,
                                40.67205046286454
                            ]
                        ],
                        [
                            [
                                -73.98001315415307,
                                40.64501504517238
                            ],
                            [
                                -73.9801793002598,
                                40.64670091513255
                            ],
                            [
                                -73.98040446924871,
                                40.64641286537322
                            ],
                            [
                                -73.9802406309392,
                                40.64702598765421
                            ],
                            [
                                -73.98216709681209,
                                40.644518161083724
                            ],
                            [
                                -73.98144373223766,
                                40.644737961383676
                            ],
                            [
                                -73.98001315415307,
                                40.64501504517238
                            ]
                        ],
                        [
                            [
                                -74.01038984718231,
                                40.6486600601281
                            ],
                            [
                                -74.00971965547134,
                                40.64930444643432
                            ],
                            [
                                -74.00977489019853,
                                40.64925135274838
                            ],
                            [
                                -74.00947261966651,
                                40.649541980489
                            ],
                            [
                                -74.01005314334246,
                                40.6495058148992
                            ],
                            [
                                -74.01063709588786,
                                40.648422357312526
                            ],
                            [
                                -74.01031138764466,
                                40.648735519995604
                            ],
                            [
                                -74.01038984718231,
                                40.6486600601281
                            ]
                        ],
                        [
                            [
                                -73.97709384327872,
                                40.65318304003054
                            ],
                            [
                                -73.97709223259942,
                                40.65318053679985
                            ],
                            [
                                -73.97679711411584,
                                40.65326162514227
                            ],
                            [
                                -73.97727508018392,
                                40.64899743080581
                            ],
                            [
                                -73.97234203232787,
                                40.65048805350183
                            ],
                            [
                                -73.9720853268363,
                                40.65041951023395
                            ],
                            [
                                -73.97270357447323,
                                40.652331670067554
                            ],
                            [
                                -73.97276533982827,
                                40.65261511912304
                            ],
                            [
                                -73.97279685446732,
                                40.652620173002816
                            ],
                            [
                                -73.97288042120269,
                                40.652878634087436
                            ],
                            [
                                -73.97413808372933,
                                40.65283526070948
                            ],
                            [
                                -73.97536369045984,
                                40.653031806481636
                            ],
                            [
                                -73.9741057331317,
                                40.65357491271795
                            ],
                            [
                                -73.97627923840265,
                                40.6537769021057
                            ],
                            [
                                -73.97709384327872,
                                40.65318304003054
                            ]
                        ],
                        [
                            [
                                -73.96149501249332,
                                40.65529193250747
                            ],
                            [
                                -73.96152909367689,
                                40.655180192902556
                            ],
                            [
                                -73.96151551653968,
                                40.65517882989917
                            ],
                            [
                                -73.96149501249332,
                                40.65529193250747
                            ]
                        ],
                        [
                            [
                                -74.00274456876849,
                                40.65422845196769
                            ],
                            [
                                -74.00261233973009,
                                40.65425159617025
                            ],
                            [
                                -74.00264466083753,
                                40.654141249799636
                            ],
                            [
                                -74.0012011642957,
                                40.65375766903923
                            ],
                            [
                                -74.0016701028403,
                                40.6568615229349
                            ],
                            [
                                -74.00187074504048,
                                40.65630147254437
                            ],
                            [
                                -74.00191756354825,
                                40.656623607407276
                            ],
                            [
                                -74.0023251481234,
                                40.655232087062174
                            ],
                            [
                                -74.00246254178671,
                                40.656099691135
                            ],
                            [
                                -74.00274456876849,
                                40.65422845196769
                            ]
                        ],
                        [
                            [
                                -74.00217330762143,
                                40.65984618047486
                            ],
                            [
                                -74.00296625681094,
                                40.65974745543131
                            ],
                            [
                                -74.00186664074975,
                                40.65915244031462
                            ],
                            [
                                -74.00217330762143,
                                40.65984618047486
                            ]
                        ],
                        [
                            [
                                -73.96274815437953,
                                40.66087667302427
                            ],
                            [
                                -73.96270893108958,
                                40.660611787634615
                            ],
                            [
                                -73.9622460894108,
                                40.66161399257867
                            ],
                            [
                                -73.96288945383412,
                                40.662326994299214
                            ],
                            [
                                -73.96295038835648,
                                40.66224241327332
                            ],
                            [
                                -73.96282695924006,
                                40.661408863368735
                            ],
                            [
                                -73.9627482000439,
                                40.660876605962756
                            ],
                            [
                                -73.96274815437953,
                                40.66087667302427
                            ]
                        ],
                        [
                            [
                                -73.99672486911639,
                                40.66508027527355
                            ],
                            [
                                -73.99669431112537,
                                40.66506208460191
                            ],
                            [
                                -73.99677416776088,
                                40.6651130451809
                            ],
                            [
                                -73.99672486911639,
                                40.66508027527355
                            ]
                        ],
                        [
                            [
                                -73.96884507845365,
                                40.672105766343776
                            ],
                            [
                                -73.96890489195937,
                                40.672142084486275
                            ],
                            [
                                -73.96920476479173,
                                40.67243821443795
                            ],
                            [
                                -73.96905729757135,
                                40.672234623598776
                            ],
                            [
                                -73.9695113076959,
                                40.67251029385424
                            ],
                            [
                                -73.96865980451956,
                                40.671686722623996
                            ],
                            [
                                -73.96884507845365,
                                40.672105766343776
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5a94ca16-948f-457b-a670-d53246be3d9c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97203848653182,
                                40.67304100501007
                            ],
                            [
                                -73.9722565397004,
                                40.6732262735201
                            ],
                            [
                                -73.97084212316176,
                                40.672682990348974
                            ],
                            [
                                -73.9707484047749,
                                40.67279386287366
                            ],
                            [
                                -73.97070697971212,
                                40.67259715315033
                            ],
                            [
                                -73.97203848653182,
                                40.67304100501007
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "d7f1abf9-2294-4eab-bf6b-cd47993530d2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9722565397004,
                                40.6732262735201
                            ],
                            [
                                -73.97203848653182,
                                40.67304100501007
                            ],
                            [
                                -73.97840927171389,
                                40.67516467783641
                            ],
                            [
                                -73.97750831606933,
                                40.675181505079934
                            ],
                            [
                                -73.97755861210568,
                                40.67526282119274
                            ],
                            [
                                -73.9722565397004,
                                40.6732262735201
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "1722f9fa-f109-4c2c-b78b-d00c008e9ee9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97755861210568,
                                40.67526282119274
                            ],
                            [
                                -73.98077637269992,
                                40.68046513538063
                            ],
                            [
                                -73.9722565397004,
                                40.6732262735201
                            ],
                            [
                                -73.97755861210568,
                                40.67526282119274
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "04c0c4ad-c4cc-4835-ad58-b99d9f1a7727"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97755861210568,
                                40.67526282119274
                            ],
                            [
                                -73.97750831606933,
                                40.675181505079934
                            ],
                            [
                                -73.97840927171389,
                                40.67516467783641
                            ],
                            [
                                -73.98581167241254,
                                40.67763223509414
                            ],
                            [
                                -73.98469151277912,
                                40.670941416408716
                            ],
                            [
                                -73.98435731611714,
                                40.67088694659566
                            ],
                            [
                                -73.98432354068055,
                                40.670297176387
                            ],
                            [
                                -73.98514791174068,
                                40.67039401416778
                            ],
                            [
                                -73.98953717274536,
                                40.679863835273196
                            ],
                            [
                                -73.97755861210568,
                                40.67526282119274
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "e63a4711-15af-4df1-bc90-dabe501daced"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97840927171389,
                                40.67516467783641
                            ],
                            [
                                -73.98459567960629,
                                40.67504913364423
                            ],
                            [
                                -73.98435731611714,
                                40.67088694659566
                            ],
                            [
                                -73.98469151277912,
                                40.670941416408716
                            ],
                            [
                                -73.98581167241254,
                                40.67763223509414
                            ],
                            [
                                -73.97840927171389,
                                40.67516467783641
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f6e16c0c-b419-4ab6-85d5-d1332b6c8758"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98432354068055,
                                40.670297176387
                            ],
                            [
                                -73.98435731611714,
                                40.67088694659566
                            ],
                            [
                                -73.97619400984176,
                                40.669556431311285
                            ],
                            [
                                -73.97514123200254,
                                40.66370899997483
                            ],
                            [
                                -73.97635495021144,
                                40.66936111657814
                            ],
                            [
                                -73.98432354068055,
                                40.670297176387
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "28b15f55-b4e0-43af-a128-c83c51124e78"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98424689252755,
                                40.66895878370414
                            ],
                            [
                                -73.98432354068055,
                                40.670297176387
                            ],
                            [
                                -73.97635495021144,
                                40.66936111657814
                            ],
                            [
                                -73.97514123200254,
                                40.66370899997483
                            ],
                            [
                                -73.97402301253807,
                                40.65749808700339
                            ],
                            [
                                -73.98291641392993,
                                40.65752020581501
                            ],
                            [
                                -73.98136698804977,
                                40.66334368563962
                            ],
                            [
                                -73.98362007609195,
                                40.663451977150295
                            ],
                            [
                                -73.98368947505779,
                                40.66447459255248
                            ],
                            [
                                -73.98323258767226,
                                40.66509108501105
                            ],
                            [
                                -73.98771048095445,
                                40.66732773355656
                            ],
                            [
                                -73.99065152753568,
                                40.66518798885998
                            ],
                            [
                                -73.99110804769954,
                                40.667205442519915
                            ],
                            [
                                -73.99553984091618,
                                40.668025431677094
                            ],
                            [
                                -73.99846192984846,
                                40.67259672790469
                            ],
                            [
                                -73.99801136080782,
                                40.67407333849323
                            ],
                            [
                                -73.99712618518137,
                                40.6738437220889
                            ],
                            [
                                -73.997721672095,
                                40.67461813520316
                            ],
                            [
                                -73.99639704072453,
                                40.674061795524686
                            ],
                            [
                                -73.99640209904491,
                                40.674059238127604
                            ],
                            [
                                -73.99527286373218,
                                40.67351436871939
                            ],
                            [
                                -73.99542711613097,
                                40.6736544312205
                            ],
                            [
                                -73.98424689252755,
                                40.66895878370414
                            ]
                        ],
                        [
                            [
                                -73.9815582241024,
                                40.66104679148942
                            ],
                            [
                                -73.98094749008345,
                                40.65955247382472
                            ],
                            [
                                -73.97769487549259,
                                40.659176354825156
                            ],
                            [
                                -73.97776169936996,
                                40.65970089363695
                            ],
                            [
                                -73.9815582241024,
                                40.66104679148942
                            ]
                        ],
                        [
                            [
                                -73.9788532198138,
                                40.66635706663857
                            ],
                            [
                                -73.9778519737678,
                                40.665084049551105
                            ],
                            [
                                -73.9783128714923,
                                40.66698932447328
                            ],
                            [
                                -73.9797840298567,
                                40.66687712953821
                            ],
                            [
                                -73.9788532198138,
                                40.66635706663857
                            ]
                        ],
                        [
                            [
                                -73.99623764895742,
                                40.673979763407644
                            ],
                            [
                                -73.99607865254202,
                                40.67326525791574
                            ],
                            [
                                -73.99544189343098,
                                40.67359567468362
                            ],
                            [
                                -73.99623764895742,
                                40.673979763407644
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c963896b-3430-40ab-9fb6-f8c26065124c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99542711613097,
                                40.6736544312205
                            ],
                            [
                                -73.99639704072453,
                                40.674061795524686
                            ],
                            [
                                -73.99606219450772,
                                40.674231087833626
                            ],
                            [
                                -73.99542711613097,
                                40.6736544312205
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "d20289ad-1416-4717-a690-b6941e9131c1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99639704072453,
                                40.674061795524686
                            ],
                            [
                                -73.99542711613097,
                                40.6736544312205
                            ],
                            [
                                -73.99527286373218,
                                40.67351436871939
                            ],
                            [
                                -73.99640209904491,
                                40.674059238127604
                            ],
                            [
                                -73.99639704072453,
                                40.674061795524686
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "1772ab3e-6859-4bdd-8933-da657ea7fc28"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99846192984846,
                                40.67259672790469
                            ],
                            [
                                -73.9985138916667,
                                40.67242643797326
                            ],
                            [
                                -73.99973078680105,
                                40.67427861029221
                            ],
                            [
                                -73.99965261347042,
                                40.674459425336394
                            ],
                            [
                                -73.99846192984846,
                                40.67259672790469
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "4e246516-158a-4275-9b51-f84489afb576"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9985138916667,
                                40.67242643797326
                            ],
                            [
                                -73.99846192984846,
                                40.67259672790469
                            ],
                            [
                                -73.99553984091618,
                                40.668025431677094
                            ],
                            [
                                -73.99563381123491,
                                40.66804281846359
                            ],
                            [
                                -73.9985138916667,
                                40.67242643797326
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f85d6fe0-65c0-499c-af81-74bfe6305a2f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9985138916667,
                                40.67242643797326
                            ],
                            [
                                -73.99856698767567,
                                40.67225243105761
                            ],
                            [
                                -73.99925021304311,
                                40.6725756839542
                            ],
                            [
                                -73.99763323385359,
                                40.67010105370962
                            ],
                            [
                                -73.99792814116867,
                                40.67007317445539
                            ],
                            [
                                -73.99912285888529,
                                40.67215694842271
                            ],
                            [
                                -73.99990078855927,
                                40.671897359963424
                            ],
                            [
                                -74.0004858511063,
                                40.672532145294404
                            ],
                            [
                                -73.99973078680105,
                                40.67427861029221
                            ],
                            [
                                -73.9985138916667,
                                40.67242643797326
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b0f1ef2c-ad27-4a88-9677-9fd7ca35c05e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99792814116867,
                                40.67007317445539
                            ],
                            [
                                -73.99819614066851,
                                40.67004783894891
                            ],
                            [
                                -73.99990078855927,
                                40.671897359963424
                            ],
                            [
                                -73.99912285888529,
                                40.67215694842271
                            ],
                            [
                                -73.99792814116867,
                                40.67007317445539
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "0d656854-3d8a-45b6-9bd7-95f69083ed7f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99990078855927,
                                40.671897359963424
                            ],
                            [
                                -73.99819614066851,
                                40.67004783894891
                            ],
                            [
                                -73.99792814116867,
                                40.67007317445539
                            ],
                            [
                                -73.99651845153547,
                                40.66761445594908
                            ],
                            [
                                -74.00471275530344,
                                40.67029164793699
                            ],
                            [
                                -73.99990078855927,
                                40.671897359963424
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4c587e3a-1d3c-44f7-b9e6-2bc7b87c67da"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0004858511063,
                                40.672532145294404
                            ],
                            [
                                -73.99990078855927,
                                40.671897359963424
                            ],
                            [
                                -74.00471275530344,
                                40.67029164793699
                            ],
                            [
                                -74.01060953460158,
                                40.67221820701248
                            ],
                            [
                                -74.01085606652656,
                                40.6727422497421
                            ],
                            [
                                -74.01156790507875,
                                40.67253131985715
                            ],
                            [
                                -74.01272392631031,
                                40.67290900792762
                            ],
                            [
                                -74.01313644257026,
                                40.67469284277122
                            ],
                            [
                                -74.01180870563087,
                                40.673783667902754
                            ],
                            [
                                -74.00730158655922,
                                40.67797731701375
                            ],
                            [
                                -74.00828840296681,
                                40.678749731664404
                            ],
                            [
                                -74.01604175116867,
                                40.67455812219344
                            ],
                            [
                                -74.00605878982152,
                                40.68338599419687
                            ],
                            [
                                -74.00709379725085,
                                40.684074531947914
                            ],
                            [
                                -74.00684436653749,
                                40.683760245708804
                            ],
                            [
                                -74.00764790237261,
                                40.68363613191641
                            ],
                            [
                                -74.00803194383467,
                                40.68398859429196
                            ],
                            [
                                -74.00713169896933,
                                40.68492633158167
                            ],
                            [
                                -74.00843661061565,
                                40.68415419286456
                            ],
                            [
                                -74.00755368257403,
                                40.683360366940285
                            ],
                            [
                                -74.00718117907347,
                                40.683510468135104
                            ],
                            [
                                -74.00791134410946,
                                40.68302105350479
                            ],
                            [
                                -74.00843671032865,
                                40.68415413223726
                            ],
                            [
                                -74.00893958108152,
                                40.68387540948737
                            ],
                            [
                                -74.00814454130077,
                                40.684917041301944
                            ],
                            [
                                -74.00253383365968,
                                40.68590646374635
                            ],
                            [
                                -74.00529146327888,
                                40.6827422245809
                            ],
                            [
                                -73.99973078680105,
                                40.67427861029221
                            ],
                            [
                                -74.0004858511063,
                                40.672532145294404
                            ]
                        ],
                        [
                            [
                                -74.0096182033364,
                                40.6755438677559
                            ],
                            [
                                -74.00938839003223,
                                40.675729897449436
                            ],
                            [
                                -74.00946009625315,
                                40.67578013179546
                            ],
                            [
                                -74.0096182033364,
                                40.6755438677559
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3484f2b8-ddf6-4379-a1fb-b3be112959d8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99965261347042,
                                40.674459425336394
                            ],
                            [
                                -73.99973078680105,
                                40.67427861029221
                            ],
                            [
                                -74.00529146327888,
                                40.6827422245809
                            ],
                            [
                                -74.00253383365968,
                                40.68590646374635
                            ],
                            [
                                -74.0024289394052,
                                40.68592496136488
                            ],
                            [
                                -74.00238850599843,
                                40.68607321981626
                            ],
                            [
                                -73.99720071063233,
                                40.69202595007612
                            ],
                            [
                                -73.99713580472086,
                                40.691923125944584
                            ],
                            [
                                -74.00487299747681,
                                40.68262615888492
                            ],
                            [
                                -73.99965261347042,
                                40.674459425336394
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "6da2da39-8ab5-43f6-8e5d-24093fa96def"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99713580472086,
                                40.691923125944584
                            ],
                            [
                                -73.99634938141247,
                                40.69067727187861
                            ],
                            [
                                -74.00232029535812,
                                40.68174255387524
                            ],
                            [
                                -73.99818718177018,
                                40.677848971304
                            ],
                            [
                                -73.99965261347042,
                                40.674459425336394
                            ],
                            [
                                -74.00487299747681,
                                40.68262615888492
                            ],
                            [
                                -73.99713580472086,
                                40.691923125944584
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c1b24c36-83ba-47da-8bcf-3fb3383d384d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99713580472086,
                                40.691923125944584
                            ],
                            [
                                -73.99720071063233,
                                40.69202595007612
                            ],
                            [
                                -73.99386128253575,
                                40.695857773283926
                            ],
                            [
                                -73.99713580472086,
                                40.691923125944584
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "21edc489-0047-4700-a323-bebf0aadde03"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99720071063233,
                                40.69202595007612
                            ],
                            [
                                -73.99969842333702,
                                40.695982833723384
                            ],
                            [
                                -73.995042290808,
                                40.70208312693195
                            ],
                            [
                                -73.99386128253575,
                                40.695857773283926
                            ],
                            [
                                -73.99720071063233,
                                40.69202595007612
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9d4e646f-3fa1-40f7-bb8d-4ecb7dd6da59"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99720071063233,
                                40.69202595007612
                            ],
                            [
                                -74.00238850599843,
                                40.68607321981626
                            ],
                            [
                                -74.0015378834069,
                                40.68919222457374
                            ],
                            [
                                -74.00086882357317,
                                40.68910116578158
                            ],
                            [
                                -74.00079191880171,
                                40.689763770394244
                            ],
                            [
                                -74.00052678374054,
                                40.6897834249257
                            ],
                            [
                                -73.99981098905417,
                                40.69126850809678
                            ],
                            [
                                -73.99986117164089,
                                40.691612858724746
                            ],
                            [
                                -74.00068125354522,
                                40.6918930413972
                            ],
                            [
                                -73.99997381049604,
                                40.69118190062693
                            ],
                            [
                                -74.00051103717193,
                                40.69013885182687
                            ],
                            [
                                -74.00075998279503,
                                40.69006723285292
                            ],
                            [
                                -74.00149484691826,
                                40.69005224493217
                            ],
                            [
                                -74.00093738389397,
                                40.689380724862524
                            ],
                            [
                                -74.00198247042078,
                                40.6892265642701
                            ],
                            [
                                -74.0040264711784,
                                40.69031238497897
                            ],
                            [
                                -74.0037435370955,
                                40.69068307478507
                            ],
                            [
                                -74.0007600519653,
                                40.69006735545906
                            ],
                            [
                                -74.00271412415165,
                                40.692031773678636
                            ],
                            [
                                -73.99969842333702,
                                40.695982833723384
                            ],
                            [
                                -73.99720071063233,
                                40.69202595007612
                            ]
                        ],
                        [
                            [
                                -74.00038197082516,
                                40.69256506401663
                            ],
                            [
                                -74.00020148670818,
                                40.69282991823629
                            ],
                            [
                                -74.00119251711277,
                                40.69326387346813
                            ],
                            [
                                -74.00038197082516,
                                40.69256506401663
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b8650ac5-14f1-46e4-97a9-93529eba6c1d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99969842333702,
                                40.695982833723384
                            ],
                            [
                                -74.0003151337319,
                                40.696959828103886
                            ],
                            [
                                -73.99509552550067,
                                40.702363738673675
                            ],
                            [
                                -73.995042290808,
                                40.70208312693195
                            ],
                            [
                                -73.99969842333702,
                                40.695982833723384
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "89c74aaa-dbe5-409c-ac0f-1a014e9f5f02"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0003151337319,
                                40.696959828103886
                            ],
                            [
                                -73.99969842333702,
                                40.695982833723384
                            ],
                            [
                                -74.00271412415165,
                                40.692031773678636
                            ],
                            [
                                -74.00391198337913,
                                40.69323597509319
                            ],
                            [
                                -74.0003151337319,
                                40.696959828103886
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ae13f0af-df79-4832-9563-1994ccc70040"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0003151337319,
                                40.696959828103886
                            ],
                            [
                                -74.00094008602635,
                                40.69794987932536
                            ],
                            [
                                -73.99514376284166,
                                40.70261800827847
                            ],
                            [
                                -73.99509552550067,
                                40.702363738673675
                            ],
                            [
                                -74.0003151337319,
                                40.696959828103886
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "7955eaed-a8c0-443b-85ae-bb3436de0c8b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99514376284166,
                                40.70261800827847
                            ],
                            [
                                -73.99483300536617,
                                40.70286828003222
                            ],
                            [
                                -73.99472625845159,
                                40.70274604439877
                            ],
                            [
                                -73.99509552550067,
                                40.702363738673675
                            ],
                            [
                                -73.99514376284166,
                                40.70261800827847
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "58be6c56-cd52-4f99-887f-e6279e34e02e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99483300536617,
                                40.70286828003222
                            ],
                            [
                                -73.99514376284166,
                                40.70261800827847
                            ],
                            [
                                -73.99529066092693,
                                40.70339234030687
                            ],
                            [
                                -73.99483300536617,
                                40.70286828003222
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b975861f-5e34-444b-9482-25e657c01825"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99483300536617,
                                40.70286828003222
                            ],
                            [
                                -73.99382083754257,
                                40.703683439879626
                            ],
                            [
                                -73.9938208350875,
                                40.70368343791005
                            ],
                            [
                                -73.99472625845159,
                                40.70274604439877
                            ],
                            [
                                -73.99483300536617,
                                40.70286828003222
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "220eebfd-696d-418a-a7d9-bebc0464e449"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99382083754257,
                                40.703683439879626
                            ],
                            [
                                -73.99483300536617,
                                40.70286828003222
                            ],
                            [
                                -73.99529066092693,
                                40.70339234030687
                            ],
                            [
                                -73.99561537844494,
                                40.705103997568564
                            ],
                            [
                                -73.99448506458421,
                                40.70420915278238
                            ],
                            [
                                -73.99441038521448,
                                40.70415145298292
                            ],
                            [
                                -73.99382454851101,
                                40.70368641701233
                            ],
                            [
                                -73.99382083754257,
                                40.703683439879626
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "03fabae9-5c39-443b-ae47-9ebba8214f2d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99382083754257,
                                40.703683439879626
                            ],
                            [
                                -73.99382081792376,
                                40.70368345567984
                            ],
                            [
                                -73.9938208350875,
                                40.70368343791005
                            ],
                            [
                                -73.99382083754257,
                                40.703683439879626
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 750
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "44ad3a0c-5320-4818-8378-0a4f4f9ef5f5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99382081792376,
                                40.70368345567984
                            ],
                            [
                                -73.99382083754257,
                                40.703683439879626
                            ],
                            [
                                -73.99382454851101,
                                40.70368641701233
                            ],
                            [
                                -73.99382081792376,
                                40.70368345567984
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 750
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ed89d8b5-3232-42d3-873d-87e518bc325c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99382081792376,
                                40.70368345567984
                            ],
                            [
                                -73.99382454851101,
                                40.70368641701233
                            ],
                            [
                                -73.99424316444787,
                                40.70402225257947
                            ],
                            [
                                -73.99441038521448,
                                40.70415145298292
                            ],
                            [
                                -73.99560890125056,
                                40.70510283254795
                            ],
                            [
                                -73.99560256222684,
                                40.7051014994309
                            ],
                            [
                                -73.99424303509046,
                                40.70402234723881
                            ],
                            [
                                -73.99204983515521,
                                40.702262847030354
                            ],
                            [
                                -73.9914029340646,
                                40.70175804682496
                            ],
                            [
                                -73.99085093549218,
                                40.70131024709457
                            ],
                            [
                                -73.99063553745337,
                                40.70114094888468
                            ],
                            [
                                -73.99061293853575,
                                40.701125149621525
                            ],
                            [
                                -73.990209736907,
                                40.70083964875149
                            ],
                            [
                                -73.9900586214116,
                                40.700721859544565
                            ],
                            [
                                -73.9902748647651,
                                40.70084125258908
                            ],
                            [
                                -73.99061306340158,
                                40.70112505223392
                            ],
                            [
                                -73.9906356632175,
                                40.70114085149711
                            ],
                            [
                                -73.99085106484958,
                                40.70131015311239
                            ],
                            [
                                -73.99122326561789,
                                40.70160755359863
                            ],
                            [
                                -73.99140306521863,
                                40.70175795352445
                            ],
                            [
                                -73.9920499645126,
                                40.702262753049524
                            ],
                            [
                                -73.99382083285427,
                                40.70368343611843
                            ],
                            [
                                -73.99382081792376,
                                40.70368345567984
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 750
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "e1415f3c-33d5-4395-a909-fadfb9256688"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99382454851101,
                                40.70368641701233
                            ],
                            [
                                -73.99441038521448,
                                40.70415145298292
                            ],
                            [
                                -73.99424316444787,
                                40.70402225257947
                            ],
                            [
                                -73.99382454851101,
                                40.70368641701233
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "e8d44577-9dd0-4dd4-a9e8-0b965ec50839"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99560890125056,
                                40.70510283254795
                            ],
                            [
                                -73.99441038521448,
                                40.70415145298292
                            ],
                            [
                                -73.99448506458421,
                                40.70420915278238
                            ],
                            [
                                -73.99561537844494,
                                40.705103997568564
                            ],
                            [
                                -73.99561541740115,
                                40.705104202915344
                            ],
                            [
                                -73.99560890125056,
                                40.70510283254795
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 750
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "40e615f7-94a2-42c0-a3d0-109f0c08000b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99944060467938,
                                40.708144430850886
                            ],
                            [
                                -73.99560890125056,
                                40.70510283254795
                            ],
                            [
                                -73.99561541740115,
                                40.705104202915344
                            ],
                            [
                                -73.99561537844494,
                                40.705103997568564
                            ],
                            [
                                -73.99846016541198,
                                40.70735615325179
                            ],
                            [
                                -73.99941952447591,
                                40.70812029196093
                            ],
                            [
                                -73.99944060467938,
                                40.708144430850886
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 750
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1322371e-efc0-461a-ae80-8c68fd927454"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99944060467938,
                                40.708144430850886
                            ],
                            [
                                -73.9994451938266,
                                40.708140737845305
                            ],
                            [
                                -73.99944911686256,
                                40.70814386258114
                            ],
                            [
                                -73.99944060467938,
                                40.708144430850886
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 750
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "71a973c2-6b29-408c-8895-1a0c21f637bd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9994451938266,
                                40.708140737845305
                            ],
                            [
                                -73.99944060467938,
                                40.708144430850886
                            ],
                            [
                                -73.99941952447591,
                                40.70812029196093
                            ],
                            [
                                -73.9994451938266,
                                40.708140737845305
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 750
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ff0b4dc6-cb98-4959-9088-73e4ab8405bc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9994451938266,
                                40.708140737845305
                            ],
                            [
                                -74.00852254345573,
                                40.700835959584374
                            ],
                            [
                                -74.01067705528082,
                                40.701648714417274
                            ],
                            [
                                -74.00794254309368,
                                40.70292874205865
                            ],
                            [
                                -74.00776433121995,
                                40.70285105616267
                            ],
                            [
                                -74.0071077355108,
                                40.70343081627555
                            ],
                            [
                                -74.0082291105648,
                                40.7038191970626
                            ],
                            [
                                -74.00757791013724,
                                40.70427549514929
                            ],
                            [
                                -74.00808331040092,
                                40.704702095303986
                            ],
                            [
                                -74.00798402030442,
                                40.70480909122489
                            ],
                            [
                                -74.00797553412642,
                                40.70477707968567
                            ],
                            [
                                -74.00631119485679,
                                40.70532304665679
                            ],
                            [
                                -74.00712694212227,
                                40.70583598089572
                            ],
                            [
                                -74.00742312349296,
                                40.70613027792153
                            ],
                            [
                                -74.00722002492131,
                                40.706301959446286
                            ],
                            [
                                -74.00783938397318,
                                40.70654389009777
                            ],
                            [
                                -74.00837395773917,
                                40.70707506283507
                            ],
                            [
                                -74.00776758788518,
                                40.70716774937701
                            ],
                            [
                                -74.00320875073368,
                                40.706290856760056
                            ],
                            [
                                -74.00025502295523,
                                40.70809006063165
                            ],
                            [
                                -73.99944911686256,
                                40.70814386258114
                            ],
                            [
                                -73.9994451938266,
                                40.708140737845305
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "af64f013-4eb1-4544-9cf2-6e03714e1f9e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01067705528082,
                                40.701648714417274
                            ],
                            [
                                -74.0110883156353,
                                40.70180385575222
                            ],
                            [
                                -74.0104221847359,
                                40.70195991819631
                            ],
                            [
                                -74.01127452424379,
                                40.70285396341357
                            ],
                            [
                                -74.00929280569274,
                                40.7025775220363
                            ],
                            [
                                -74.00950450806248,
                                40.703609631864424
                            ],
                            [
                                -74.00794254309368,
                                40.70292874205865
                            ],
                            [
                                -74.01067705528082,
                                40.701648714417274
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "47d9b0e9-42dc-4f26-a451-e6b4363f47a6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0110883156353,
                                40.70180385575222
                            ],
                            [
                                -74.01067705528082,
                                40.701648714417274
                            ],
                            [
                                -74.01302600638417,
                                40.70054916826983
                            ],
                            [
                                -74.01399950543927,
                                40.70290205514714
                            ],
                            [
                                -74.01320736318034,
                                40.70260323225752
                            ],
                            [
                                -74.011593358796,
                                40.701694972920066
                            ],
                            [
                                -74.01116267500777,
                                40.70178643469366
                            ],
                            [
                                -74.0110883156353,
                                40.70180385575222
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ab13fd87-c829-4aa3-9389-17ff3a531557"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01320736318034,
                                40.70260323225752
                            ],
                            [
                                -74.0110883156353,
                                40.70180385575222
                            ],
                            [
                                -74.01116267500777,
                                40.70178643469366
                            ],
                            [
                                -74.011593358796,
                                40.701694972920066
                            ],
                            [
                                -74.01320736318034,
                                40.70260323225752
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "6c463f56-82d8-4d6e-b0c3-527db19f4832"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01399950543927,
                                40.70290205514714
                            ],
                            [
                                -74.01444697587364,
                                40.70398356345212
                            ],
                            [
                                -74.01519826220537,
                                40.70335426680074
                            ],
                            [
                                -74.01824194792118,
                                40.70450244814119
                            ],
                            [
                                -74.01726798949633,
                                40.70471849708064
                            ],
                            [
                                -74.0178343853678,
                                40.7048726327241
                            ],
                            [
                                -74.01787715236185,
                                40.70483154222402
                            ],
                            [
                                -74.01818117728828,
                                40.70461333428025
                            ],
                            [
                                -74.01824284842918,
                                40.70462554727024
                            ],
                            [
                                -74.01788085701409,
                                40.704830704601065
                            ],
                            [
                                -74.01816744833582,
                                40.70469649752004
                            ],
                            [
                                -74.01811999572924,
                                40.70510139927339
                            ],
                            [
                                -74.01403179254888,
                                40.70682571327412
                            ],
                            [
                                -74.01404740584302,
                                40.70524780092608
                            ],
                            [
                                -74.0147784856545,
                                40.70484210103921
                            ],
                            [
                                -74.01430487227715,
                                40.704204960542405
                            ],
                            [
                                -74.01405325793893,
                                40.704656375803445
                            ],
                            [
                                -74.01406877705418,
                                40.70308798137091
                            ],
                            [
                                -74.01320736318034,
                                40.70260323225752
                            ],
                            [
                                -74.01399950543927,
                                40.70290205514714
                            ]
                        ],
                        [
                            [
                                -74.0180572286437,
                                40.70505013557534
                            ],
                            [
                                -74.01809011596626,
                                40.705048776315245
                            ],
                            [
                                -74.01790168984384,
                                40.704950394684474
                            ],
                            [
                                -74.0180572286437,
                                40.70505013557534
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ace37ce7-5458-4d20-9319-5db442669d9e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01399950543927,
                                40.70290205514714
                            ],
                            [
                                -74.01519826220537,
                                40.70335426680074
                            ],
                            [
                                -74.01444697587364,
                                40.70398356345212
                            ],
                            [
                                -74.01399950543927,
                                40.70290205514714
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "188c8025-a8d4-476d-ae8d-c58ab46ecf8f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01824194792118,
                                40.70450244814119
                            ],
                            [
                                -74.01519826220537,
                                40.70335426680074
                            ],
                            [
                                -74.01630406575543,
                                40.70242801745777
                            ],
                            [
                                -74.01851031821177,
                                40.70444291673289
                            ],
                            [
                                -74.01824194792118,
                                40.70450244814119
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "86051f2a-f75a-4a0f-9877-9a36d67040ab"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00967470559664,
                                40.745367891770755
                            ],
                            [
                                -74.01892376422802,
                                40.70475965234498
                            ],
                            [
                                -74.00993348629892,
                                40.74539539987428
                            ],
                            [
                                -74.00967470559664,
                                40.745367891770755
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8db775a2-5544-4d75-92b4-2b9836928255"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00963037158002,
                                40.74556254145712
                            ],
                            [
                                -74.00967470559664,
                                40.745367891770755
                            ],
                            [
                                -74.00993348629892,
                                40.74539539987428
                            ],
                            [
                                -74.00989466057423,
                                40.745570890816715
                            ],
                            [
                                -74.00963037158002,
                                40.74556254145712
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ad1b9d21-8091-448e-af0b-b328e40bd1f4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00647570345926,
                                40.75941319626016
                            ],
                            [
                                -73.98289842516634,
                                40.77180516500009
                            ],
                            [
                                -73.98678547811089,
                                40.77892910102237
                            ],
                            [
                                -73.98572666124393,
                                40.78049974979117
                            ],
                            [
                                -73.98803879185986,
                                40.78122609236542
                            ],
                            [
                                -73.98811268096166,
                                40.78136151147122
                            ],
                            [
                                -73.98697772036937,
                                40.782478612210824
                            ],
                            [
                                -73.97817322299149,
                                40.78612200733769
                            ],
                            [
                                -73.97830919557111,
                                40.78551172305005
                            ],
                            [
                                -73.97880811360676,
                                40.78507951766982
                            ],
                            [
                                -73.97845465326375,
                                40.78485886683025
                            ],
                            [
                                -73.97912701373599,
                                40.781841118450615
                            ],
                            [
                                -73.97363832441802,
                                40.779632065742256
                            ],
                            [
                                -73.97415224132325,
                                40.78350270421537
                            ],
                            [
                                -73.96580048673313,
                                40.790374381083325
                            ],
                            [
                                -73.97462215392729,
                                40.790622471646344
                            ],
                            [
                                -73.97788976679011,
                                40.79418925048275
                            ],
                            [
                                -73.97543502954642,
                                40.797135595954096
                            ],
                            [
                                -73.96516502478937,
                                40.79352786554688
                            ],
                            [
                                -73.96174571351563,
                                40.79667102788684
                            ],
                            [
                                -73.95963123081411,
                                40.796585552531916
                            ],
                            [
                                -73.95963467858066,
                                40.79559676285219
                            ],
                            [
                                -73.96768461231174,
                                40.78661678417973
                            ],
                            [
                                -73.96749761693025,
                                40.785840835972465
                            ],
                            [
                                -73.96782211024575,
                                40.78565779964913
                            ],
                            [
                                -73.96738860978448,
                                40.78538850448355
                            ],
                            [
                                -73.96717998190425,
                                40.784522790965084
                            ],
                            [
                                -73.96661909767073,
                                40.78491047525265
                            ],
                            [
                                -73.96500113930611,
                                40.7839053820529
                            ],
                            [
                                -73.9655175201136,
                                40.78388753089021
                            ],
                            [
                                -73.9645294577427,
                                40.783598659659404
                            ],
                            [
                                -73.96436340000824,
                                40.78350921151755
                            ],
                            [
                                -73.96373884608118,
                                40.78312123187685
                            ],
                            [
                                -73.96357982709628,
                                40.78272120764909
                            ],
                            [
                                -73.97396222007855,
                                40.777337269321784
                            ],
                            [
                                -73.97088719523283,
                                40.772311146969344
                            ],
                            [
                                -73.97699781247653,
                                40.7717740163652
                            ],
                            [
                                -73.97288405956482,
                                40.767414157680825
                            ],
                            [
                                -73.9926471124112,
                                40.76513019848273
                            ],
                            [
                                -73.9985790259988,
                                40.75032966484404
                            ],
                            [
                                -74.00702933594269,
                                40.748867847669054
                            ],
                            [
                                -74.00235392558741,
                                40.74533266558066
                            ],
                            [
                                -74.00963037158002,
                                40.74556254145712
                            ],
                            [
                                -74.00647570345926,
                                40.75941319626016
                            ]
                        ],
                        [
                            [
                                -74.00817329015136,
                                40.75032570416613
                            ],
                            [
                                -74.00748895626286,
                                40.74999705962956
                            ],
                            [
                                -74.00675762162038,
                                40.749726003768735
                            ],
                            [
                                -74.00817329015136,
                                40.75032570416613
                            ]
                        ],
                        [
                            [
                                -74.00067914259354,
                                40.75533860507223
                            ],
                            [
                                -74.00137299044998,
                                40.75689079593079
                            ],
                            [
                                -74.00331387413225,
                                40.75644249350558
                            ],
                            [
                                -74.00362682487027,
                                40.75657361225329
                            ],
                            [
                                -74.00339500293312,
                                40.756259944402316
                            ],
                            [
                                -74.00264581247774,
                                40.75489252213955
                            ],
                            [
                                -74.00045458524284,
                                40.755244521003924
                            ],
                            [
                                -74.00067914259354,
                                40.75533860507223
                            ]
                        ],
                        [
                            [
                                -74.00277667455674,
                                40.76006923468847
                            ],
                            [
                                -74.00279072241116,
                                40.760048634012584
                            ],
                            [
                                -74.002724286606,
                                40.760046718603924
                            ],
                            [
                                -74.00277667455674,
                                40.76006923468847
                            ]
                        ],
                        [
                            [
                                -74.00289258866943,
                                40.76011911216714
                            ],
                            [
                                -74.0029279571388,
                                40.760134040089206
                            ],
                            [
                                -74.00292943935901,
                                40.76011494998508
                            ],
                            [
                                -74.00289258866943,
                                40.76011911216714
                            ]
                        ],
                        [
                            [
                                -74.00297388799927,
                                40.76015411204586
                            ],
                            [
                                -74.00300167468764,
                                40.76016603450899
                            ],
                            [
                                -74.0030135270595,
                                40.760149339521774
                            ],
                            [
                                -74.00297388799927,
                                40.76015411204586
                            ]
                        ],
                        [
                            [
                                -73.98219829146814,
                                40.768378178958265
                            ],
                            [
                                -73.9823845987053,
                                40.76809777077855
                            ],
                            [
                                -73.9823756278901,
                                40.768095985933826
                            ],
                            [
                                -73.98238078176367,
                                40.768087784645246
                            ],
                            [
                                -73.98088110188901,
                                40.767783168078566
                            ],
                            [
                                -73.98198411178534,
                                40.76871900016011
                            ],
                            [
                                -73.98219829146814,
                                40.768378178958265
                            ]
                        ],
                        [
                            [
                                -73.9750776033696,
                                40.77743450452126
                            ],
                            [
                                -73.97912491347486,
                                40.776741417972126
                            ],
                            [
                                -73.97997259342135,
                                40.78112896320108
                            ],
                            [
                                -73.98468458676827,
                                40.77976978133107
                            ],
                            [
                                -73.98283259752066,
                                40.77189553038225
                            ],
                            [
                                -73.98082488621684,
                                40.77321206584013
                            ],
                            [
                                -73.98191755560595,
                                40.776266340778754
                            ],
                            [
                                -73.98036214784432,
                                40.77562550311566
                            ],
                            [
                                -73.98188405563236,
                                40.776936440980755
                            ],
                            [
                                -73.97466947537983,
                                40.77531391021031
                            ],
                            [
                                -73.9750776033696,
                                40.77743450452126
                            ]
                        ],
                        [
                            [
                                -73.97294767896632,
                                40.78077353300057
                            ],
                            [
                                -73.97302687354346,
                                40.78026399193574
                            ],
                            [
                                -73.97236449627714,
                                40.78088820118948
                            ],
                            [
                                -73.97191321501775,
                                40.781782876447146
                            ],
                            [
                                -73.97294767896632,
                                40.78077353300057
                            ]
                        ],
                        [
                            [
                                -73.97927504531503,
                                40.78368043387832
                            ],
                            [
                                -73.97949049545615,
                                40.784379056793725
                            ],
                            [
                                -73.98022991673281,
                                40.78395464745753
                            ],
                            [
                                -73.97989687622268,
                                40.78362504007438
                            ],
                            [
                                -73.97927504531503,
                                40.78368043387832
                            ]
                        ],
                        [
                            [
                                -73.96884228559142,
                                40.785990397146236
                            ],
                            [
                                -73.96907944082643,
                                40.78566430612765
                            ],
                            [
                                -73.96892764620466,
                                40.78587280353445
                            ],
                            [
                                -73.96884228559142,
                                40.785990397146236
                            ]
                        ],
                        [
                            [
                                -73.97107972216453,
                                40.795317299389026
                            ],
                            [
                                -73.97521558100398,
                                40.79515150467569
                            ],
                            [
                                -73.97370773993497,
                                40.792193952191646
                            ],
                            [
                                -73.97023603343284,
                                40.79388426189567
                            ],
                            [
                                -73.97107972216453,
                                40.795317299389026
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "36aadd29-de19-4cff-b890-8031d9a76a11"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00647570345926,
                                40.75941319626016
                            ],
                            [
                                -74.00963037158002,
                                40.74556254145712
                            ],
                            [
                                -74.00989466057423,
                                40.745570890816715
                            ],
                            [
                                -74.00687908642321,
                                40.759201182429834
                            ],
                            [
                                -74.00647570345926,
                                40.75941319626016
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b89e1bb7-6376-4c69-82ab-550060c0ba0e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00647570345926,
                                40.75941319626016
                            ],
                            [
                                -74.00645104977728,
                                40.759521438910596
                            ],
                            [
                                -74.00574862907092,
                                40.76004698920068
                            ],
                            [
                                -73.98289842516634,
                                40.77180516500009
                            ],
                            [
                                -74.00647570345926,
                                40.75941319626016
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "25928176-7734-42ea-89f8-e07d8fbddd31"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00645104977728,
                                40.759521438910596
                            ],
                            [
                                -74.00647570345926,
                                40.75941319626016
                            ],
                            [
                                -74.00687908642321,
                                40.759201182429834
                            ],
                            [
                                -74.00645104977728,
                                40.759521438910596
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "108ae626-bfee-462c-9813-eb7b82d59d3e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00645104977728,
                                40.759521438910596
                            ],
                            [
                                -74.00640871147246,
                                40.7597073263709
                            ],
                            [
                                -74.00574862907092,
                                40.76004698920068
                            ],
                            [
                                -74.00645104977728,
                                40.759521438910596
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "640a336c-67e3-4ea1-af00-11f244888571"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00574862907092,
                                40.76004698920068
                            ],
                            [
                                -73.99104962486672,
                                40.77104476568073
                            ],
                            [
                                -73.99155036916228,
                                40.77957645633036
                            ],
                            [
                                -73.96674518293369,
                                40.81004347902882
                            ],
                            [
                                -73.96153722971842,
                                40.811103868936975
                            ],
                            [
                                -73.9595529392907,
                                40.80619410586995
                            ],
                            [
                                -73.96556541379296,
                                40.80536681771283
                            ],
                            [
                                -73.96092185230775,
                                40.801864655437456
                            ],
                            [
                                -73.95509158668783,
                                40.80758078161528
                            ],
                            [
                                -73.96015152090048,
                                40.8173027679111
                            ],
                            [
                                -73.95110297081402,
                                40.81347823319368
                            ],
                            [
                                -73.95569307524588,
                                40.812601962677824
                            ],
                            [
                                -73.95321319864895,
                                40.80757949380406
                            ],
                            [
                                -73.94751792825666,
                                40.81032768318239
                            ],
                            [
                                -73.94920037512051,
                                40.81267406642513
                            ],
                            [
                                -73.94380512471206,
                                40.81039366544385
                            ],
                            [
                                -73.94598568624957,
                                40.816376903750935
                            ],
                            [
                                -73.93595006984967,
                                40.81490853903237
                            ],
                            [
                                -73.93613561693839,
                                40.81478150139749
                            ],
                            [
                                -73.93222987413539,
                                40.810091315274946
                            ],
                            [
                                -73.9306500232149,
                                40.81019977483999
                            ],
                            [
                                -73.93217050986816,
                                40.81187840297701
                            ],
                            [
                                -73.93215571192047,
                                40.81198598527283
                            ],
                            [
                                -73.93144091346575,
                                40.81358788040694
                            ],
                            [
                                -73.93143932493884,
                                40.81359262467117
                            ],
                            [
                                -73.92818116175998,
                                40.81176558731914
                            ],
                            [
                                -73.9282560065566,
                                40.81214617139373
                            ],
                            [
                                -73.91156649000015,
                                40.80431144002574
                            ],
                            [
                                -73.91219998973897,
                                40.80468899777245
                            ],
                            [
                                -73.91030012057108,
                                40.803766922485075
                            ],
                            [
                                -73.92958119780837,
                                40.809382332286575
                            ],
                            [
                                -73.933449620155,
                                40.807765424161296
                            ],
                            [
                                -73.93566902748825,
                                40.809913110192895
                            ],
                            [
                                -73.94691252124734,
                                40.80837646670656
                            ],
                            [
                                -73.94199658607641,
                                40.801236580986455
                            ],
                            [
                                -73.9537852330416,
                                40.79959347987811
                            ],
                            [
                                -73.95391656334675,
                                40.79964667230956
                            ],
                            [
                                -73.95299478047414,
                                40.79778395409055
                            ],
                            [
                                -73.95178445453764,
                                40.79781695261813
                            ],
                            [
                                -73.94862568664759,
                                40.795017980977896
                            ],
                            [
                                -73.96127081815123,
                                40.781588066287775
                            ],
                            [
                                -73.96373884608118,
                                40.78312123187685
                            ],
                            [
                                -73.96376492675721,
                                40.78318683978078
                            ],
                            [
                                -73.96436340000824,
                                40.78350921151755
                            ],
                            [
                                -73.96500113930611,
                                40.7839053820529
                            ],
                            [
                                -73.96430175727605,
                                40.78392955952468
                            ],
                            [
                                -73.96653101648512,
                                40.784971357141735
                            ],
                            [
                                -73.96661909767073,
                                40.78491047525265
                            ],
                            [
                                -73.96738860978448,
                                40.78538850448355
                            ],
                            [
                                -73.96749761693025,
                                40.785840835972465
                            ],
                            [
                                -73.9596532676332,
                                40.790265583065
                            ],
                            [
                                -73.95963467858066,
                                40.79559676285219
                            ],
                            [
                                -73.95877917272577,
                                40.79655110913474
                            ],
                            [
                                -73.95963123081411,
                                40.796585552531916
                            ],
                            [
                                -73.9596241325736,
                                40.798621266629176
                            ],
                            [
                                -73.96174571351563,
                                40.79667102788684
                            ],
                            [
                                -73.97540560433096,
                                40.79722321212311
                            ],
                            [
                                -73.97800199691183,
                                40.794130103920175
                            ],
                            [
                                -73.97469186768491,
                                40.79052908753345
                            ],
                            [
                                -73.96580048673313,
                                40.790374381083325
                            ],
                            [
                                -73.97470779930647,
                                40.78768696840666
                            ],
                            [
                                -73.97415224132325,
                                40.78350270421537
                            ],
                            [
                                -73.9750711211265,
                                40.78274666606551
                            ],
                            [
                                -73.97845465326375,
                                40.78485886683025
                            ],
                            [
                                -73.97830919557111,
                                40.78551172305005
                            ],
                            [
                                -73.9743056864948,
                                40.788979904272075
                            ],
                            [
                                -73.97409687753962,
                                40.788936234519134
                            ],
                            [
                                -73.97417950123578,
                                40.789089216712114
                            ],
                            [
                                -73.97415327916306,
                                40.7891119325093
                            ],
                            [
                                -73.97423876321602,
                                40.78919894343847
                            ],
                            [
                                -73.97484579580546,
                                40.790322896748016
                            ],
                            [
                                -73.97526653088727,
                                40.79024506861554
                            ],
                            [
                                -73.9752698437387,
                                40.79024844063958
                            ],
                            [
                                -73.97910822277687,
                                40.79022427460707
                            ],
                            [
                                -73.98697772036937,
                                40.782478612210824
                            ],
                            [
                                -73.9876231683021,
                                40.78221151895703
                            ],
                            [
                                -73.98787297990114,
                                40.781861030804706
                            ],
                            [
                                -73.98793808350473,
                                40.78175952562301
                            ],
                            [
                                -73.98816259585043,
                                40.78126498467911
                            ],
                            [
                                -73.98803879185986,
                                40.78122609236542
                            ],
                            [
                                -73.98678547811089,
                                40.77892910102237
                            ],
                            [
                                -73.98797890744282,
                                40.77715876814484
                            ],
                            [
                                -73.98289842516634,
                                40.77180516500009
                            ],
                            [
                                -74.00574862907092,
                                40.76004698920068
                            ]
                        ],
                        [
                            [
                                -73.96931742161318,
                                40.79868560940837
                            ],
                            [
                                -73.96897052296069,
                                40.79850907020504
                            ],
                            [
                                -73.96924700806795,
                                40.797949080549515
                            ],
                            [
                                -73.96845236536906,
                                40.798245376103694
                            ],
                            [
                                -73.96841617871955,
                                40.798226960459154
                            ],
                            [
                                -73.96697757979264,
                                40.798521431390775
                            ],
                            [
                                -73.96701710562849,
                                40.79878053622136
                            ],
                            [
                                -73.96648159656807,
                                40.798980209551324
                            ],
                            [
                                -73.96782597569643,
                                40.80082722265313
                            ],
                            [
                                -73.96796887755863,
                                40.80053779091706
                            ],
                            [
                                -73.96931742161318,
                                40.79868560940837
                            ]
                        ],
                        [
                            [
                                -73.95545083731552,
                                40.79961212253381
                            ],
                            [
                                -73.95544632600364,
                                40.79961242153956
                            ],
                            [
                                -73.95729299258215,
                                40.80031906726311
                            ],
                            [
                                -73.96073877744949,
                                40.801641420212626
                            ],
                            [
                                -73.95890012459057,
                                40.79935269530892
                            ],
                            [
                                -73.95539145331281,
                                40.799589333353936
                            ],
                            [
                                -73.95545083731552,
                                40.79961212253381
                            ]
                        ],
                        [
                            [
                                -73.95022494366681,
                                40.80164144068288
                            ],
                            [
                                -73.95015728725755,
                                40.80151438321356
                            ],
                            [
                                -73.95014687646523,
                                40.801514046591045
                            ],
                            [
                                -73.9500641562171,
                                40.801379059417535
                            ],
                            [
                                -73.94786741602131,
                                40.801395541403345
                            ],
                            [
                                -73.94795060450821,
                                40.801516073025255
                            ],
                            [
                                -73.95083897830249,
                                40.80264345411715
                            ],
                            [
                                -73.95022494366681,
                                40.80164144068288
                            ]
                        ],
                        [
                            [
                                -73.9655965961131,
                                40.802998422510214
                            ],
                            [
                                -73.96529314880338,
                                40.803577688765834
                            ],
                            [
                                -73.96619732469642,
                                40.80470087273073
                            ],
                            [
                                -73.96836937379831,
                                40.80416655260925
                            ],
                            [
                                -73.9655965961131,
                                40.802998422510214
                            ]
                        ],
                        [
                            [
                                -73.95646146808878,
                                40.80536902759984
                            ],
                            [
                                -73.95279502839263,
                                40.804224132402524
                            ],
                            [
                                -73.95491864406615,
                                40.80515982342537
                            ],
                            [
                                -73.95651823352989,
                                40.80586585320865
                            ],
                            [
                                -73.95651799464476,
                                40.80586451683926
                            ],
                            [
                                -73.95658648932181,
                                40.80589469643118
                            ],
                            [
                                -73.95646146808878,
                                40.80536902759984
                            ]
                        ],
                        [
                            [
                                -73.96315274914559,
                                40.80758976841549
                            ],
                            [
                                -73.96350889971471,
                                40.80839308430139
                            ],
                            [
                                -73.96453075850323,
                                40.80698444007338
                            ],
                            [
                                -73.96315274914559,
                                40.80758976841549
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8e8b00ef-061e-4532-beb5-0fe026c33b5f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98289842516634,
                                40.77180516500009
                            ],
                            [
                                -73.98797890744282,
                                40.77715876814484
                            ],
                            [
                                -73.98678547811089,
                                40.77892910102237
                            ],
                            [
                                -73.98289842516634,
                                40.77180516500009
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6fe1b882-ec3f-46b3-9642-7bd69995e880"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98803879185986,
                                40.78122609236542
                            ],
                            [
                                -73.98572666124393,
                                40.78049974979117
                            ],
                            [
                                -73.98678547811089,
                                40.77892910102237
                            ],
                            [
                                -73.98803879185986,
                                40.78122609236542
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "54e222ed-4e34-47c4-be6f-34948d7c55f5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98803879185986,
                                40.78122609236542
                            ],
                            [
                                -73.98816259585043,
                                40.78126498467911
                            ],
                            [
                                -73.98793808350473,
                                40.78175952562301
                            ],
                            [
                                -73.98787297990114,
                                40.781861030804706
                            ],
                            [
                                -73.9876231683021,
                                40.78221151895703
                            ],
                            [
                                -73.98697772036937,
                                40.782478612210824
                            ],
                            [
                                -73.98811268096166,
                                40.78136151147122
                            ],
                            [
                                -73.98803879185986,
                                40.78122609236542
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e91611ce-52c8-4ca2-953a-bb12aa16466d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97830919557111,
                                40.78551172305005
                            ],
                            [
                                -73.97817322299149,
                                40.78612200733769
                            ],
                            [
                                -73.98697772036937,
                                40.782478612210824
                            ],
                            [
                                -73.97910822277687,
                                40.79022427460707
                            ],
                            [
                                -73.9752698437387,
                                40.79024844063958
                            ],
                            [
                                -73.97526653088727,
                                40.79024506861554
                            ],
                            [
                                -73.97796677307122,
                                40.789745574241316
                            ],
                            [
                                -73.9743056864948,
                                40.788979904272075
                            ],
                            [
                                -73.97830919557111,
                                40.78551172305005
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "809552d4-3a52-4737-92cb-b250ed3a84a2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97830919557111,
                                40.78551172305005
                            ],
                            [
                                -73.97845465326375,
                                40.78485886683025
                            ],
                            [
                                -73.97880811360676,
                                40.78507951766982
                            ],
                            [
                                -73.97830919557111,
                                40.78551172305005
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e8559dc2-6f91-46f2-bb23-b0625911fc2d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97415224132325,
                                40.78350270421537
                            ],
                            [
                                -73.97363832441802,
                                40.779632065742256
                            ],
                            [
                                -73.97912701373599,
                                40.781841118450615
                            ],
                            [
                                -73.97845465326375,
                                40.78485886683025
                            ],
                            [
                                -73.9750711211265,
                                40.78274666606551
                            ],
                            [
                                -73.97415224132325,
                                40.78350270421537
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "44220942-8fdc-479a-8ef2-12c151b08a33"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97415224132325,
                                40.78350270421537
                            ],
                            [
                                -73.97470779930647,
                                40.78768696840666
                            ],
                            [
                                -73.96580048673313,
                                40.790374381083325
                            ],
                            [
                                -73.97415224132325,
                                40.78350270421537
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ef8c4fbd-1523-47cb-bbf2-cc7bd6120fc6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96580048673313,
                                40.790374381083325
                            ],
                            [
                                -73.97469186768491,
                                40.79052908753345
                            ],
                            [
                                -73.97800199691183,
                                40.794130103920175
                            ],
                            [
                                -73.97540560433096,
                                40.79722321212311
                            ],
                            [
                                -73.96174571351563,
                                40.79667102788684
                            ],
                            [
                                -73.96516502478937,
                                40.79352786554688
                            ],
                            [
                                -73.97543502954642,
                                40.797135595954096
                            ],
                            [
                                -73.97788976679011,
                                40.79418925048275
                            ],
                            [
                                -73.97462215392729,
                                40.790622471646344
                            ],
                            [
                                -73.96580048673313,
                                40.790374381083325
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ab001461-77e7-416d-beb7-5cf86f11e0a0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95963123081411,
                                40.796585552531916
                            ],
                            [
                                -73.96174571351563,
                                40.79667102788684
                            ],
                            [
                                -73.9596241325736,
                                40.798621266629176
                            ],
                            [
                                -73.95963123081411,
                                40.796585552531916
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ef2083c5-2600-4661-925a-5065a7ad1b7c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95963123081411,
                                40.796585552531916
                            ],
                            [
                                -73.95877917272577,
                                40.79655110913474
                            ],
                            [
                                -73.95963467858066,
                                40.79559676285219
                            ],
                            [
                                -73.95963123081411,
                                40.796585552531916
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "42c11d17-792d-43da-b8b7-fcf3a856472c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96749761693025,
                                40.785840835972465
                            ],
                            [
                                -73.96768461231174,
                                40.78661678417973
                            ],
                            [
                                -73.95963467858066,
                                40.79559676285219
                            ],
                            [
                                -73.9596532676332,
                                40.790265583065
                            ],
                            [
                                -73.96749761693025,
                                40.785840835972465
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3c61d8ec-c761-4997-a6b2-bc65bb525be6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96749761693025,
                                40.785840835972465
                            ],
                            [
                                -73.96738860978448,
                                40.78538850448355
                            ],
                            [
                                -73.96782211024575,
                                40.78565779964913
                            ],
                            [
                                -73.96749761693025,
                                40.785840835972465
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a570fa87-feb0-437d-a382-7708823cd6dd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96661909767073,
                                40.78491047525265
                            ],
                            [
                                -73.96717998190425,
                                40.784522790965084
                            ],
                            [
                                -73.96738860978448,
                                40.78538850448355
                            ],
                            [
                                -73.96661909767073,
                                40.78491047525265
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "08d84f93-2f54-4461-8ccd-d53f05f4038c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96661909767073,
                                40.78491047525265
                            ],
                            [
                                -73.96653101648512,
                                40.784971357141735
                            ],
                            [
                                -73.96430175727605,
                                40.78392955952468
                            ],
                            [
                                -73.96500113930611,
                                40.7839053820529
                            ],
                            [
                                -73.96661909767073,
                                40.78491047525265
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6c17caee-9ea7-4b8f-add7-3ac463997b38"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96436340000824,
                                40.78350921151755
                            ],
                            [
                                -73.9645294577427,
                                40.783598659659404
                            ],
                            [
                                -73.9655175201136,
                                40.78388753089021
                            ],
                            [
                                -73.96500113930611,
                                40.7839053820529
                            ],
                            [
                                -73.96436340000824,
                                40.78350921151755
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "eb5ddd56-5bc0-4b42-bfce-d74918abb7f8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96436340000824,
                                40.78350921151755
                            ],
                            [
                                -73.96376492675721,
                                40.78318683978078
                            ],
                            [
                                -73.96373884608118,
                                40.78312123187685
                            ],
                            [
                                -73.96436340000824,
                                40.78350921151755
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "edb7c6a4-f632-4b46-8d0b-e110434dbb97"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97699781247653,
                                40.7717740163652
                            ],
                            [
                                -73.97088719523283,
                                40.772311146969344
                            ],
                            [
                                -73.97396222007855,
                                40.777337269321784
                            ],
                            [
                                -73.96357982709628,
                                40.78272120764909
                            ],
                            [
                                -73.96373884608118,
                                40.78312123187685
                            ],
                            [
                                -73.96127081815123,
                                40.781588066287775
                            ],
                            [
                                -73.94862568664759,
                                40.795017980977896
                            ],
                            [
                                -73.95178445453764,
                                40.79781695261813
                            ],
                            [
                                -73.94954949252622,
                                40.79787788699402
                            ],
                            [
                                -73.9537852330416,
                                40.79959347987811
                            ],
                            [
                                -73.94199658607641,
                                40.801236580986455
                            ],
                            [
                                -73.94691252124734,
                                40.80837646670656
                            ],
                            [
                                -73.93566902748825,
                                40.809913110192895
                            ],
                            [
                                -73.933449620155,
                                40.807765424161296
                            ],
                            [
                                -73.9353157781312,
                                40.806985414709274
                            ],
                            [
                                -73.93524599195632,
                                40.80723519243004
                            ],
                            [
                                -73.94211427615842,
                                40.8062373307076
                            ],
                            [
                                -73.9398558558386,
                                40.80122705883991
                            ],
                            [
                                -73.93475621159881,
                                40.801622966277456
                            ],
                            [
                                -73.9365972135522,
                                40.80239891861133
                            ],
                            [
                                -73.93555232476781,
                                40.80613877022915
                            ],
                            [
                                -73.9345172929709,
                                40.80580530341131
                            ],
                            [
                                -73.93463427607877,
                                40.805892063626644
                            ],
                            [
                                -73.93475899008799,
                                40.80616929900255
                            ],
                            [
                                -73.93463227283569,
                                40.80647993936083
                            ],
                            [
                                -73.93415964850347,
                                40.80674395743189
                            ],
                            [
                                -73.93293658040331,
                                40.80726896355585
                            ],
                            [
                                -73.93276653125369,
                                40.8071044096329
                            ],
                            [
                                -73.93475881042492,
                                40.80616930104242
                            ],
                            [
                                -73.93086461085294,
                                40.80266351682492
                            ],
                            [
                                -73.93940431406777,
                                40.79857521944706
                            ],
                            [
                                -73.9371307723071,
                                40.79720877464568
                            ],
                            [
                                -73.93865192467145,
                                40.791390227306906
                            ],
                            [
                                -73.9473275132642,
                                40.779438918561205
                            ],
                            [
                                -73.943695825475,
                                40.774643652437405
                            ],
                            [
                                -73.94528422005584,
                                40.77432532202686
                            ],
                            [
                                -73.95446507555754,
                                40.77391793439476
                            ],
                            [
                                -73.9494982463342,
                                40.76836204150158
                            ],
                            [
                                -73.95776937561658,
                                40.76513643482107
                            ],
                            [
                                -73.95711224457678,
                                40.76111479506759
                            ],
                            [
                                -73.95839710923,
                                40.75989938298704
                            ],
                            [
                                -73.96235621388148,
                                40.76312091859324
                            ],
                            [
                                -73.9542778882119,
                                40.766964707223636
                            ],
                            [
                                -73.9566195140866,
                                40.774971519049956
                            ],
                            [
                                -73.94895377384724,
                                40.77683720713611
                            ],
                            [
                                -73.95357311405742,
                                40.78207611840286
                            ],
                            [
                                -73.94567500211194,
                                40.78353053605274
                            ],
                            [
                                -73.94939061379011,
                                40.78782701855545
                            ],
                            [
                                -73.94264642016643,
                                40.797232028953594
                            ],
                            [
                                -73.96793819509467,
                                40.76798573701813
                            ],
                            [
                                -73.97288405956482,
                                40.767414157680825
                            ],
                            [
                                -73.97699781247653,
                                40.7717740163652
                            ]
                        ],
                        [
                            [
                                -73.94264597011046,
                                40.7972326464377
                            ],
                            [
                                -73.93971633040698,
                                40.79781159374967
                            ],
                            [
                                -73.93982529335601,
                                40.79846576571168
                            ],
                            [
                                -73.94264597011046,
                                40.7972326464377
                            ]
                        ],
                        [
                            [
                                -73.94576565649714,
                                40.80772279086665
                            ],
                            [
                                -73.94518407819906,
                                40.807649173602414
                            ],
                            [
                                -73.94523572503968,
                                40.8079894883898
                            ],
                            [
                                -73.94576565649714,
                                40.80772279086665
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4a96a274-b050-411a-b593-9caaf0b042a1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96793819509467,
                                40.76798573701813
                            ],
                            [
                                -73.97056132507532,
                                40.76495246531552
                            ],
                            [
                                -73.97288405956482,
                                40.767414157680825
                            ],
                            [
                                -73.96793819509467,
                                40.76798573701813
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "987c22fc-0be5-42f6-aa9d-5864b3d5452d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94264642016643,
                                40.797232028953594
                            ],
                            [
                                -73.94939061379011,
                                40.78782701855545
                            ],
                            [
                                -73.94567500211194,
                                40.78353053605274
                            ],
                            [
                                -73.95357311405742,
                                40.78207611840286
                            ],
                            [
                                -73.94895377384724,
                                40.77683720713611
                            ],
                            [
                                -73.9566195140866,
                                40.774971519049956
                            ],
                            [
                                -73.9542778882119,
                                40.766964707223636
                            ],
                            [
                                -73.96235621388148,
                                40.76312091859324
                            ],
                            [
                                -73.95839710923,
                                40.75989938298704
                            ],
                            [
                                -73.95925424406883,
                                40.7590885800475
                            ],
                            [
                                -73.95864212165968,
                                40.758820750419176
                            ],
                            [
                                -73.96172796157929,
                                40.75722415772246
                            ],
                            [
                                -73.96336691020426,
                                40.75769860110211
                            ],
                            [
                                -73.96284196045185,
                                40.75984258680772
                            ],
                            [
                                -73.96234562184596,
                                40.759878435278985
                            ],
                            [
                                -73.96266286313325,
                                40.760574051275015
                            ],
                            [
                                -73.96210001615907,
                                40.762872815797806
                            ],
                            [
                                -73.9644411009102,
                                40.76447319899613
                            ],
                            [
                                -73.96509024204936,
                                40.765896572870126
                            ],
                            [
                                -73.96273050751397,
                                40.7676945814845
                            ],
                            [
                                -73.96179358913757,
                                40.7681291300032
                            ],
                            [
                                -73.9619087557067,
                                40.76832071836818
                            ],
                            [
                                -73.96134899224221,
                                40.768747232247634
                            ],
                            [
                                -73.96211212528206,
                                40.76865903915625
                            ],
                            [
                                -73.96273919353487,
                                40.769702214985756
                            ],
                            [
                                -73.96597999527974,
                                40.76832420686695
                            ],
                            [
                                -73.96591516831143,
                                40.76821953240721
                            ],
                            [
                                -73.96793819509467,
                                40.76798573701813
                            ],
                            [
                                -73.94264642016643,
                                40.797232028953594
                            ]
                        ],
                        [
                            [
                                -73.95838419195911,
                                40.768810936851125
                            ],
                            [
                                -73.95872209556924,
                                40.768350359989846
                            ],
                            [
                                -73.95800283248757,
                                40.76853362818096
                            ],
                            [
                                -73.95803295341683,
                                40.76866285685534
                            ],
                            [
                                -73.95619203818224,
                                40.76788673838497
                            ],
                            [
                                -73.95732590071701,
                                40.7702206649627
                            ],
                            [
                                -73.96047865049604,
                                40.7696939476087
                            ],
                            [
                                -73.95838419195911,
                                40.768810936851125
                            ]
                        ],
                        [
                            [
                                -73.95931421829215,
                                40.77439122835418
                            ],
                            [
                                -73.95986839739113,
                                40.77363393928083
                            ],
                            [
                                -73.96139992941376,
                                40.77430828213568
                            ],
                            [
                                -73.96120377867821,
                                40.77180619294697
                            ],
                            [
                                -73.95730859377474,
                                40.77250684219566
                            ],
                            [
                                -73.95858258658853,
                                40.77306778897211
                            ],
                            [
                                -73.95931421829215,
                                40.77439122835418
                            ]
                        ],
                        [
                            [
                                -73.9542338842377,
                                40.78154817747029
                            ],
                            [
                                -73.95565623696915,
                                40.77950325589768
                            ],
                            [
                                -73.95758647952304,
                                40.77676233863719
                            ],
                            [
                                -73.95126097807687,
                                40.777680533293264
                            ],
                            [
                                -73.9537487769163,
                                40.78221182232731
                            ],
                            [
                                -73.95422593592873,
                                40.781534263245135
                            ],
                            [
                                -73.9542338842377,
                                40.78154817747029
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b9995dbb-719c-4275-b836-23b6d0411f2e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95839710923,
                                40.75989938298704
                            ],
                            [
                                -73.95768201086194,
                                40.75931750525016
                            ],
                            [
                                -73.95864212165968,
                                40.758820750419176
                            ],
                            [
                                -73.95925424406883,
                                40.7590885800475
                            ],
                            [
                                -73.95839710923,
                                40.75989938298704
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d4dd9ad9-505d-42d7-ba3d-71b92689b173"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95864212165968,
                                40.758820750419176
                            ],
                            [
                                -73.95768201086194,
                                40.75931750525016
                            ],
                            [
                                -73.95839710923,
                                40.75989938298704
                            ],
                            [
                                -73.95711224457678,
                                40.76111479506759
                            ],
                            [
                                -73.95659073907602,
                                40.75792318315695
                            ],
                            [
                                -73.95864212165968,
                                40.758820750419176
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "78135e33-233b-4352-b501-c956cd3c02ff"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96172796157929,
                                40.75722415772246
                            ],
                            [
                                -73.95864212165968,
                                40.758820750419176
                            ],
                            [
                                -73.95659073907602,
                                40.75792318315695
                            ],
                            [
                                -73.95659073026826,
                                40.75792312925352
                            ],
                            [
                                -73.96098895818332,
                                40.756716281595
                            ],
                            [
                                -73.96112291323465,
                                40.75674951832739
                            ],
                            [
                                -73.96094262854365,
                                40.756996819260536
                            ],
                            [
                                -73.96172796157929,
                                40.75722415772246
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8ae99524-6ddc-4f97-a29d-5c1aecd71b78"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96172796157929,
                                40.75722415772246
                            ],
                            [
                                -73.96456441271856,
                                40.75575659709341
                            ],
                            [
                                -73.96539970336913,
                                40.75941398227793
                            ],
                            [
                                -73.96670103357589,
                                40.75956386202114
                            ],
                            [
                                -73.96284196045185,
                                40.75984258680772
                            ],
                            [
                                -73.96336691020426,
                                40.75769860110211
                            ],
                            [
                                -73.96172796157929,
                                40.75722415772246
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "fd99f731-001d-47ab-9c25-8966d8946381"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96456441271856,
                                40.75575659709341
                            ],
                            [
                                -73.96172796157929,
                                40.75722415772246
                            ],
                            [
                                -73.96094262854365,
                                40.756996819260536
                            ],
                            [
                                -73.96112291323465,
                                40.75674951832739
                            ],
                            [
                                -73.96098895818332,
                                40.756716281595
                            ],
                            [
                                -73.96455981371804,
                                40.75573646001134
                            ],
                            [
                                -73.96456441271856,
                                40.75575659709341
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9b72d205-43a5-45c7-ad45-6922a94fa066"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96465247483432,
                                40.75571103435051
                            ],
                            [
                                -73.96456441271856,
                                40.75575659709341
                            ],
                            [
                                -73.96455981371804,
                                40.75573646001134
                            ],
                            [
                                -73.96465247483432,
                                40.75571103435051
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c39a6d59-4e77-4b89-8709-cd7ae866ef43"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96465247483432,
                                40.75571103435051
                            ],
                            [
                                -73.96454820424634,
                                40.75568562703512
                            ],
                            [
                                -73.96435460785916,
                                40.75483795017225
                            ],
                            [
                                -73.96507194540527,
                                40.75489033205098
                            ],
                            [
                                -73.96465247483432,
                                40.75571103435051
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "5519e2fd-b5b3-4810-99b5-38476a9749f5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96454820424634,
                                40.75568562703512
                            ],
                            [
                                -73.96465247483432,
                                40.75571103435051
                            ],
                            [
                                -73.96455981371804,
                                40.75573646001134
                            ],
                            [
                                -73.96454820424634,
                                40.75568562703512
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "68fc8526-543f-4c04-a9d2-1c6b9abc7540"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96454820424634,
                                40.75568562703512
                            ],
                            [
                                -73.96242496406468,
                                40.75516826318406
                            ],
                            [
                                -73.9645571209349,
                                40.75397852647326
                            ],
                            [
                                -73.96577809948906,
                                40.75350872790392
                            ],
                            [
                                -73.96507194540527,
                                40.75489033205098
                            ],
                            [
                                -73.96435460785916,
                                40.75483795017225
                            ],
                            [
                                -73.96454820424634,
                                40.75568562703512
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "357005bd-e9e6-4e94-97e0-d68418e756ea"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96242496406468,
                                40.75516826318406
                            ],
                            [
                                -73.96454820424634,
                                40.75568562703512
                            ],
                            [
                                -73.96455981371804,
                                40.75573646001134
                            ],
                            [
                                -73.96098895818332,
                                40.756716281595
                            ],
                            [
                                -73.96027093754446,
                                40.75653812735901
                            ],
                            [
                                -73.96041926287259,
                                40.75628743803111
                            ],
                            [
                                -73.96242496406468,
                                40.75516826318406
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b1c994a1-256d-46c6-9a36-8ca64957f89c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96242496406468,
                                40.75516826318406
                            ],
                            [
                                -73.96183725085363,
                                40.755025056789975
                            ],
                            [
                                -73.9645571209349,
                                40.75397852647326
                            ],
                            [
                                -73.96242496406468,
                                40.75516826318406
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "68bca031-e3d5-4bf7-8dc8-cf8dfd43bea7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96577809948906,
                                40.75350872790392
                            ],
                            [
                                -73.9645571209349,
                                40.75397852647326
                            ],
                            [
                                -73.96592933305547,
                                40.753212836500985
                            ],
                            [
                                -73.96577809948906,
                                40.75350872790392
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "2b4fd7a0-a321-4c28-94c3-4e461dc6b513"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96624858705856,
                                40.75303469378886
                            ],
                            [
                                -73.9664358714218,
                                40.75325563556916
                            ],
                            [
                                -73.96577809948906,
                                40.75350872790392
                            ],
                            [
                                -73.96592933305547,
                                40.753212836500985
                            ],
                            [
                                -73.96624858705856,
                                40.75303469378886
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "669c77ad-ca9a-476e-88f6-7deb6ced8aef"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96610622390091,
                                40.75286674613452
                            ],
                            [
                                -73.96624858705856,
                                40.75303469378886
                            ],
                            [
                                -73.96592933305547,
                                40.753212836500985
                            ],
                            [
                                -73.96610622390091,
                                40.75286674613452
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "41103fa6-5ca4-497e-a029-49c24e743156"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96610622390091,
                                40.75286674613452
                            ],
                            [
                                -73.96499595286973,
                                40.7515569450705
                            ],
                            [
                                -73.967482005424,
                                40.75017500292803
                            ],
                            [
                                -73.96610622390091,
                                40.75286674613452
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "43ed6303-27b5-40fe-a9b5-fdb506003fb4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96837327945222,
                                40.74843120802288
                            ],
                            [
                                -73.96937246857716,
                                40.74912413588355
                            ],
                            [
                                -73.967482005424,
                                40.75017500292803
                            ],
                            [
                                -73.96837327945222,
                                40.74843120802288
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6eaf3ee5-dfc5-47b2-afa5-ad448fb9c8fd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96837327945222,
                                40.74843120802288
                            ],
                            [
                                -73.96812603096762,
                                40.74825974362319
                            ],
                            [
                                -73.96864752544793,
                                40.74771536195252
                            ],
                            [
                                -73.96891556272048,
                                40.74737022030147
                            ],
                            [
                                -73.96837327945222,
                                40.74843120802288
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a309338b-68a1-4c67-b9f6-3a36a5a894d6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9738094065805,
                                40.74137388002877
                            ],
                            [
                                -73.97344560858161,
                                40.74472785088287
                            ],
                            [
                                -73.97146524315187,
                                40.74345214345516
                            ],
                            [
                                -73.9738094065805,
                                40.74137388002877
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "0ef2f42d-58ec-42b5-9fdf-e6695cee5dd7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97499518047223,
                                40.73044184883396
                            ],
                            [
                                -73.97539909034853,
                                40.72671807367057
                            ],
                            [
                                -73.97544934614154,
                                40.726703785726684
                            ],
                            [
                                -73.97505384213386,
                                40.73049476783595
                            ],
                            [
                                -73.97499518047223,
                                40.73044184883396
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "961e5e94-fc46-4603-bc95-8b0aab3993bc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97539909034853,
                                40.72671807367057
                            ],
                            [
                                -73.97499518047223,
                                40.73044184883396
                            ],
                            [
                                -73.97377733881365,
                                40.72934322729195
                            ],
                            [
                                -73.97383127176126,
                                40.72932754608496
                            ],
                            [
                                -73.97303988653866,
                                40.72856708087817
                            ],
                            [
                                -73.97301988300357,
                                40.72865992231203
                            ],
                            [
                                -73.9719532789811,
                                40.727697733058925
                            ],
                            [
                                -73.97539909034853,
                                40.72671807367057
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f17501b3-d463-4978-84bf-68a909042478"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97539909034853,
                                40.72671807367057
                            ],
                            [
                                -73.97610934035781,
                                40.72017005021375
                            ],
                            [
                                -73.97613596429932,
                                40.7201224184044
                            ],
                            [
                                -73.97544934614154,
                                40.726703785726684
                            ],
                            [
                                -73.97539909034853,
                                40.72671807367057
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "23678480-371e-45d3-88df-181a246bcd5c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97667665488098,
                                40.714939795012675
                            ],
                            [
                                -73.97023346262068,
                                40.7129599676353
                            ],
                            [
                                -73.9702451180953,
                                40.71295137802507
                            ],
                            [
                                -73.97021946701042,
                                40.71287798013258
                            ],
                            [
                                -73.9706108024119,
                                40.712984631853246
                            ],
                            [
                                -73.97044370775684,
                                40.71301133359369
                            ],
                            [
                                -73.97667665488098,
                                40.714939795012675
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "4254b64b-1cbf-41eb-84e7-71dac2890a3c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97023346262068,
                                40.7129599676353
                            ],
                            [
                                -73.96798627646561,
                                40.71226946508747
                            ],
                            [
                                -73.96640710379567,
                                40.714105244574725
                            ],
                            [
                                -73.96677787881386,
                                40.71098977874175
                            ],
                            [
                                -73.96789144549676,
                                40.71050664644401
                            ],
                            [
                                -73.96798632048306,
                                40.71226937452535
                            ],
                            [
                                -73.97021946701042,
                                40.71287798013258
                            ],
                            [
                                -73.9702451180953,
                                40.71295137802507
                            ],
                            [
                                -73.97023346262068,
                                40.7129599676353
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "0ae2c18a-4c8e-4047-bf72-365b1c008c0f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96798627646561,
                                40.71226946508747
                            ],
                            [
                                -73.97023346262068,
                                40.7129599676353
                            ],
                            [
                                -73.97014473196509,
                                40.71302535851573
                            ],
                            [
                                -73.96798627646561,
                                40.71226946508747
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "190235e4-f25a-4be8-8e9f-a7021aa4e0e0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96640710379567,
                                40.714105244574725
                            ],
                            [
                                -73.96798627646561,
                                40.71226946508747
                            ],
                            [
                                -73.97014473196509,
                                40.71302535851573
                            ],
                            [
                                -73.9661886486536,
                                40.71594083084083
                            ],
                            [
                                -73.96640710379567,
                                40.714105244574725
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "6b382187-4371-4c68-bc6b-d70b6994b5fe"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9624718387501,
                                40.71867996840646
                            ],
                            [
                                -73.9575861893672,
                                40.72228049307808
                            ],
                            [
                                -73.95832710358043,
                                40.72349820009795
                            ],
                            [
                                -73.95788028251988,
                                40.72401762710745
                            ],
                            [
                                -73.95865035382296,
                                40.7259771770162
                            ],
                            [
                                -73.95734046420439,
                                40.72696805193771
                            ],
                            [
                                -73.95976987803935,
                                40.72882595682051
                            ],
                            [
                                -73.96136712278138,
                                40.73289036069584
                            ],
                            [
                                -73.95537433920612,
                                40.73794815146971
                            ],
                            [
                                -73.9503679203784,
                                40.733461722457655
                            ],
                            [
                                -73.94927546232545,
                                40.73150688215088
                            ],
                            [
                                -73.94915714434272,
                                40.73078945373239
                            ],
                            [
                                -73.94904671663366,
                                40.731097565475444
                            ],
                            [
                                -73.9438752454065,
                                40.72184375442714
                            ],
                            [
                                -73.93315492507361,
                                40.716879670283134
                            ],
                            [
                                -73.93333654556483,
                                40.714465954544075
                            ],
                            [
                                -73.92975461425496,
                                40.71530514373025
                            ],
                            [
                                -73.9288838652977,
                                40.714901940191744
                            ],
                            [
                                -73.93468441913961,
                                40.713304270229315
                            ],
                            [
                                -73.93261664401489,
                                40.70903069947666
                            ],
                            [
                                -73.93445246387313,
                                40.70837557151161
                            ],
                            [
                                -73.93803658862352,
                                40.70857533233678
                            ],
                            [
                                -73.94010760310805,
                                40.71147200938461
                            ],
                            [
                                -73.9394209894944,
                                40.71217532876022
                            ],
                            [
                                -73.94050571354892,
                                40.71202883667151
                            ],
                            [
                                -73.94267065341653,
                                40.7150568848437
                            ],
                            [
                                -73.94517602031546,
                                40.719112713638204
                            ],
                            [
                                -73.94518740286392,
                                40.71968806685508
                            ],
                            [
                                -73.9454386756714,
                                40.71953791489558
                            ],
                            [
                                -73.94752977613695,
                                40.72292310589656
                            ],
                            [
                                -73.94665946860518,
                                40.71743908201192
                            ],
                            [
                                -73.94691428019965,
                                40.71730189875147
                            ],
                            [
                                -73.95043965658567,
                                40.71782169065115
                            ],
                            [
                                -73.94848965656074,
                                40.71838969876068
                            ],
                            [
                                -73.950398070788,
                                40.72026747409338
                            ],
                            [
                                -73.95132775345628,
                                40.71795263427501
                            ],
                            [
                                -73.95366993409345,
                                40.718297972301215
                            ],
                            [
                                -73.95385877562283,
                                40.719547965015224
                            ],
                            [
                                -73.95542645247876,
                                40.7185569585564
                            ],
                            [
                                -73.95995734466193,
                                40.719225006673035
                            ],
                            [
                                -73.95760827452213,
                                40.71717772039974
                            ],
                            [
                                -73.95986499874904,
                                40.71575113285001
                            ],
                            [
                                -73.95662725690327,
                                40.715393729903724
                            ],
                            [
                                -73.96677787881386,
                                40.71098977874175
                            ],
                            [
                                -73.96640710379567,
                                40.714105244574725
                            ],
                            [
                                -73.9624718387501,
                                40.71867996840646
                            ]
                        ],
                        [
                            [
                                -73.95053154696448,
                                40.72169874499559
                            ],
                            [
                                -73.95043655910635,
                                40.72175570592425
                            ],
                            [
                                -73.95058403732317,
                                40.72167121528099
                            ],
                            [
                                -73.95053154696448,
                                40.72169874499559
                            ]
                        ],
                        [
                            [
                                -73.95124128321666,
                                40.72456201399583
                            ],
                            [
                                -73.95122570842219,
                                40.72453450876276
                            ],
                            [
                                -73.95123985955357,
                                40.72456218216459
                            ],
                            [
                                -73.94722177077549,
                                40.7250368148272
                            ],
                            [
                                -73.95330612128026,
                                40.72818818031754
                            ],
                            [
                                -73.95556188528178,
                                40.72405164725793
                            ],
                            [
                                -73.95124128321666,
                                40.72456201399583
                            ]
                        ],
                        [
                            [
                                -73.95495263887295,
                                40.73440958250411
                            ],
                            [
                                -73.95366502698235,
                                40.72882456510747
                            ],
                            [
                                -73.9502034930025,
                                40.731967479843966
                            ],
                            [
                                -73.95495263887295,
                                40.73440958250411
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "d7cae188-1db1-4754-8938-d261959ac086"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9624718387501,
                                40.71867996840646
                            ],
                            [
                                -73.96640710379567,
                                40.714105244574725
                            ],
                            [
                                -73.9661886486536,
                                40.71594083084083
                            ],
                            [
                                -73.9624718387501,
                                40.71867996840646
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "d5aec439-6ff6-487a-80b8-44b325af6e7a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9624718387501,
                                40.71867996840646
                            ],
                            [
                                -73.95832710358043,
                                40.72349820009795
                            ],
                            [
                                -73.9575861893672,
                                40.72228049307808
                            ],
                            [
                                -73.9624718387501,
                                40.71867996840646
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "e7343c33-e1a7-4578-8855-617a466a0650"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95832710358043,
                                40.72349820009795
                            ],
                            [
                                -73.9624718387501,
                                40.71867996840646
                            ],
                            [
                                -73.9661886486536,
                                40.71594083084083
                            ],
                            [
                                -73.96562183158606,
                                40.720703555190774
                            ],
                            [
                                -73.95946190714444,
                                40.72536327197997
                            ],
                            [
                                -73.95832710358043,
                                40.72349820009795
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b8d2f6e8-2a9f-4b9c-81f8-9c91799a8072"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95788028251988,
                                40.72401762710745
                            ],
                            [
                                -73.95832710358043,
                                40.72349820009795
                            ],
                            [
                                -73.95946190714444,
                                40.72536327197997
                            ],
                            [
                                -73.95913772527979,
                                40.72560850155897
                            ],
                            [
                                -73.95788028251988,
                                40.72401762710745
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "2d25e032-161f-4bc8-be4f-fe9e61cba995"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95788028251988,
                                40.72401762710745
                            ],
                            [
                                -73.95913772527979,
                                40.72560850155897
                            ],
                            [
                                -73.95865035382296,
                                40.7259771770162
                            ],
                            [
                                -73.95788028251988,
                                40.72401762710745
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "58281391-9998-4332-a6eb-ec957ef9d1a5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95913772527979,
                                40.72560850155897
                            ],
                            [
                                -73.961194081757,
                                40.72821013487947
                            ],
                            [
                                -73.96313429056315,
                                40.73139890605039
                            ],
                            [
                                -73.95976987803935,
                                40.72882595682051
                            ],
                            [
                                -73.95865035382296,
                                40.7259771770162
                            ],
                            [
                                -73.95913772527979,
                                40.72560850155897
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "bc7c7d32-28b0-4baa-a72c-a413fc27e6f4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.961194081757,
                                40.72821013487947
                            ],
                            [
                                -73.95913772527979,
                                40.72560850155897
                            ],
                            [
                                -73.95946190714444,
                                40.72536327197997
                            ],
                            [
                                -73.961194081757,
                                40.72821013487947
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "9d6ce803-9460-4561-9ffc-f1cb67af0e37"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.961194081757,
                                40.72821013487947
                            ],
                            [
                                -73.96368701048273,
                                40.731364104782244
                            ],
                            [
                                -73.96146942606231,
                                40.73315068514114
                            ],
                            [
                                -73.96136846908104,
                                40.732893786536216
                            ],
                            [
                                -73.96313429056315,
                                40.73139890605039
                            ],
                            [
                                -73.961194081757,
                                40.72821013487947
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ebbf9311-81db-4eb4-a354-9a3a98b4073e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96146942606231,
                                40.73315068514114
                            ],
                            [
                                -73.95499444728242,
                                40.73836720353201
                            ],
                            [
                                -73.95465614431586,
                                40.73855429374804
                            ],
                            [
                                -73.95537433920612,
                                40.73794815146971
                            ],
                            [
                                -73.95538584333387,
                                40.737958460725544
                            ],
                            [
                                -73.96136846908104,
                                40.732893786536216
                            ],
                            [
                                -73.96146942606231,
                                40.73315068514114
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "4c5303f1-79af-496e-9d6a-316a09405531"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95499444728242,
                                40.73836720353201
                            ],
                            [
                                -73.96146942606231,
                                40.73315068514114
                            ],
                            [
                                -73.96199746628287,
                                40.73449435443757
                            ],
                            [
                                -73.95499444728242,
                                40.73836720353201
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "2db625d9-87fb-4712-bbac-7dff498168b7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95499444728242,
                                40.73836720353201
                            ],
                            [
                                -73.95339646771134,
                                40.73965460386243
                            ],
                            [
                                -73.95335050722967,
                                40.73965622561753
                            ],
                            [
                                -73.95465614431586,
                                40.73855429374804
                            ],
                            [
                                -73.95499444728242,
                                40.73836720353201
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a9a0a40a-c935-4a48-9721-e832b617280c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95339646771134,
                                40.73965460386243
                            ],
                            [
                                -73.95310073608641,
                                40.73989285784173
                            ],
                            [
                                -73.95308054723193,
                                40.73988406651463
                            ],
                            [
                                -73.95335050722967,
                                40.73965622561753
                            ],
                            [
                                -73.95339646771134,
                                40.73965460386243
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9fcbefe4-42a7-4e8f-8d53-869f7caa0781"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95310073608641,
                                40.73989285784173
                            ],
                            [
                                -73.95339646771134,
                                40.73965460386243
                            ],
                            [
                                -73.96057571348021,
                                40.7394012780042
                            ],
                            [
                                -73.95391383740309,
                                40.74024692645188
                            ],
                            [
                                -73.95310073608641,
                                40.73989285784173
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "d274a5ba-de4f-4f3e-a1ab-8306307dd42b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95310073608641,
                                40.73989285784173
                            ],
                            [
                                -73.95305877625168,
                                40.73992666246982
                            ],
                            [
                                -73.95304829549991,
                                40.73991128633837
                            ],
                            [
                                -73.95308054723193,
                                40.73988406651463
                            ],
                            [
                                -73.95310073608641,
                                40.73989285784173
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9f9363c7-dce1-485d-8644-3182721d5b51"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95305877625168,
                                40.73992666246982
                            ],
                            [
                                -73.95310073608641,
                                40.73989285784173
                            ],
                            [
                                -73.95391383740309,
                                40.74024692645188
                            ],
                            [
                                -73.95332778403221,
                                40.740321319178136
                            ],
                            [
                                -73.95305877625168,
                                40.73992666246982
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "5b84bf8e-352e-4ccd-926b-12f56de3b306"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95305877625168,
                                40.73992666246982
                            ],
                            [
                                -73.95242697784093,
                                40.74043566615015
                            ],
                            [
                                -73.95304829549991,
                                40.73991128633837
                            ],
                            [
                                -73.95305877625168,
                                40.73992666246982
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "0dc50a53-20fc-4925-86f6-77a677ec2e3b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95242697784093,
                                40.74043566615015
                            ],
                            [
                                -73.95305877625168,
                                40.73992666246982
                            ],
                            [
                                -73.95332778403221,
                                40.740321319178136
                            ],
                            [
                                -73.95242697784093,
                                40.74043566615015
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "c846a377-38e1-4cf8-826d-d2502204425a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9533605704634,
                                40.7403694195899
                            ],
                            [
                                -73.95388711588188,
                                40.74114190535676
                            ],
                            [
                                -73.95092151191753,
                                40.74210965476561
                            ],
                            [
                                -73.95138797470523,
                                40.74328163271585
                            ],
                            [
                                -73.95529895252736,
                                40.743213186723
                            ],
                            [
                                -73.95683970194251,
                                40.7454735938061
                            ],
                            [
                                -73.93859274998896,
                                40.75165289303168
                            ],
                            [
                                -73.93860280064868,
                                40.749772572295555
                            ],
                            [
                                -73.93892622169041,
                                40.74972606637795
                            ],
                            [
                                -73.93860425005643,
                                40.749501410848296
                            ],
                            [
                                -73.93862645657505,
                                40.74534691964601
                            ],
                            [
                                -73.95006156306381,
                                40.74052791038213
                            ],
                            [
                                -73.95220943911679,
                                40.74089439154543
                            ],
                            [
                                -73.9508676229643,
                                40.74018821873767
                            ],
                            [
                                -73.95124697215087,
                                40.74002835251814
                            ],
                            [
                                -73.95203785460932,
                                40.74035824103042
                            ],
                            [
                                -73.95244391443423,
                                40.73952393430765
                            ],
                            [
                                -73.95270831671318,
                                40.73941250928218
                            ],
                            [
                                -73.95281671835524,
                                40.73957154347496
                            ],
                            [
                                -73.95261677748742,
                                40.7396821159022
                            ],
                            [
                                -73.9530082966156,
                                40.739852604660136
                            ],
                            [
                                -73.95304829549991,
                                40.73991128633837
                            ],
                            [
                                -73.95242697784093,
                                40.74043566615015
                            ],
                            [
                                -73.9533605704634,
                                40.7403694195899
                            ]
                        ],
                        [
                            [
                                -73.95042641173688,
                                40.7427241188273
                            ],
                            [
                                -73.94886719961004,
                                40.742374886381015
                            ],
                            [
                                -73.9469882609039,
                                40.74201093859007
                            ],
                            [
                                -73.95042641173688,
                                40.7427241188273
                            ]
                        ],
                        [
                            [
                                -73.95116078448166,
                                40.74305548700942
                            ],
                            [
                                -73.94818027947184,
                                40.744829908368
                            ],
                            [
                                -73.9516288130329,
                                40.744466155047455
                            ],
                            [
                                -73.95116078448166,
                                40.74305548700942
                            ]
                        ],
                        [
                            [
                                -73.94506964810323,
                                40.7468737815905
                            ],
                            [
                                -73.9444608265975,
                                40.74634666347437
                            ],
                            [
                                -73.9431149859102,
                                40.74644288301428
                            ],
                            [
                                -73.94292036320896,
                                40.747048858541646
                            ],
                            [
                                -73.94506964810323,
                                40.7468737815905
                            ]
                        ],
                        [
                            [
                                -73.9453653878678,
                                40.74693241693425
                            ],
                            [
                                -73.94531866738818,
                                40.746873562448826
                            ],
                            [
                                -73.94521125313472,
                                40.74719246862386
                            ],
                            [
                                -73.9453653878678,
                                40.74693241693425
                            ]
                        ],
                        [
                            [
                                -73.93975608534987,
                                40.75063720191655
                            ],
                            [
                                -73.93989962355586,
                                40.750447065491024
                            ],
                            [
                                -73.9402024492314,
                                40.75058151515712
                            ],
                            [
                                -73.94014363473313,
                                40.750246334036554
                            ],
                            [
                                -73.94016247869284,
                                40.7505636336469
                            ],
                            [
                                -73.93989957684347,
                                40.75044693414919
                            ],
                            [
                                -73.93975608534987,
                                40.75063720191655
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "56f8c8ef-f227-4f84-b8d4-a6fe02b7ac79"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9533605704634,
                                40.7403694195899
                            ],
                            [
                                -73.95242697784093,
                                40.74043566615015
                            ],
                            [
                                -73.95332778403221,
                                40.740321319178136
                            ],
                            [
                                -73.9533605704634,
                                40.7403694195899
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "931d8ff0-3c2e-416f-89e9-f7038f7d6f08"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95407819727139,
                                40.740318497692684
                            ],
                            [
                                -73.96044627402996,
                                40.74309150523921
                            ],
                            [
                                -73.95727514609888,
                                40.74852586565075
                            ],
                            [
                                -73.95654891355657,
                                40.74869685492393
                            ],
                            [
                                -73.95271304544484,
                                40.74688355857504
                            ],
                            [
                                -73.95532257737871,
                                40.74898559209764
                            ],
                            [
                                -73.92601392583137,
                                40.75588622617003
                            ],
                            [
                                -73.9228835432046,
                                40.752877445197385
                            ],
                            [
                                -73.92390901685472,
                                40.751740329938215
                            ],
                            [
                                -73.93190482413972,
                                40.75186356389862
                            ],
                            [
                                -73.92819929226381,
                                40.75022266665144
                            ],
                            [
                                -73.93549933775836,
                                40.749134157441716
                            ],
                            [
                                -73.93536925788129,
                                40.7502375354677
                            ],
                            [
                                -73.93860280064868,
                                40.749772572295555
                            ],
                            [
                                -73.93859274998896,
                                40.75165289303168
                            ],
                            [
                                -73.95683970194251,
                                40.7454735938061
                            ],
                            [
                                -73.95529895252736,
                                40.743213186723
                            ],
                            [
                                -73.95642172841724,
                                40.74319353703076
                            ],
                            [
                                -73.9540144788144,
                                40.74110034370384
                            ],
                            [
                                -73.95388711588188,
                                40.74114190535676
                            ],
                            [
                                -73.9533605704634,
                                40.7403694195899
                            ],
                            [
                                -73.95407819727139,
                                40.740318497692684
                            ]
                        ],
                        [
                            [
                                -73.92784691840569,
                                40.7520496846601
                            ],
                            [
                                -73.92791147403695,
                                40.75219707370126
                            ],
                            [
                                -73.92957085909777,
                                40.75202025526509
                            ],
                            [
                                -73.92784691840569,
                                40.7520496846601
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "258dd3f9-6040-4164-b30a-77494dd789ed"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95407819727139,
                                40.740318497692684
                            ],
                            [
                                -73.9533605704634,
                                40.7403694195899
                            ],
                            [
                                -73.95332778403221,
                                40.740321319178136
                            ],
                            [
                                -73.95391383740309,
                                40.74024692645188
                            ],
                            [
                                -73.95407819727139,
                                40.740318497692684
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "8f46db65-a0aa-4288-b86d-b38788c1175b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95407819727139,
                                40.740318497692684
                            ],
                            [
                                -73.96221083487423,
                                40.73974141593999
                            ],
                            [
                                -73.96073447710442,
                                40.742597611769014
                            ],
                            [
                                -73.96044627402996,
                                40.74309150523921
                            ],
                            [
                                -73.95407819727139,
                                40.740318497692684
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "2a868148-180b-4b69-b904-77147e51f554"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96221083487423,
                                40.73974141593999
                            ],
                            [
                                -73.95407819727139,
                                40.740318497692684
                            ],
                            [
                                -73.95391383740309,
                                40.74024692645188
                            ],
                            [
                                -73.96057571348021,
                                40.7394012780042
                            ],
                            [
                                -73.96242029457078,
                                40.73933619037482
                            ],
                            [
                                -73.96221083487423,
                                40.73974141593999
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "39aa931f-d56a-4851-be1a-21dcf3ba0034"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96221083487423,
                                40.73974141593999
                            ],
                            [
                                -73.96227010554946,
                                40.73973721016729
                            ],
                            [
                                -73.9611064652052,
                                40.74196013593947
                            ],
                            [
                                -73.96073447710442,
                                40.742597611769014
                            ],
                            [
                                -73.96221083487423,
                                40.73974141593999
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "6043a482-3dcc-40a6-b638-19276d865f67"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96227010554946,
                                40.73973721016729
                            ],
                            [
                                -73.96221083487423,
                                40.73974141593999
                            ],
                            [
                                -73.96242029457078,
                                40.73933619037482
                            ],
                            [
                                -73.96248115246951,
                                40.739334042951555
                            ],
                            [
                                -73.96227010554946,
                                40.73973721016729
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c92fccb5-a546-4d0d-9ab6-1648dbe7db85"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96227010554946,
                                40.73973721016729
                            ],
                            [
                                -73.96240938246821,
                                40.73972732725202
                            ],
                            [
                                -73.9611064652052,
                                40.74196013593947
                            ],
                            [
                                -73.96227010554946,
                                40.73973721016729
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "2a8890c6-c076-4e69-8515-d51df7b9b954"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96073447710442,
                                40.742597611769014
                            ],
                            [
                                -73.9611064652052,
                                40.74196013593947
                            ],
                            [
                                -73.96050161067427,
                                40.74311560182881
                            ],
                            [
                                -73.9604731384891,
                                40.74310320348826
                            ],
                            [
                                -73.96073447710442,
                                40.742597611769014
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9fa62967-4041-49c5-a4c4-ef22ae8fee6f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96044627402996,
                                40.74309150523921
                            ],
                            [
                                -73.96073447710442,
                                40.742597611769014
                            ],
                            [
                                -73.9604731384891,
                                40.74310320348826
                            ],
                            [
                                -73.96044627402996,
                                40.74309150523921
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "83da385b-a601-4a50-b0b0-e6ea0e358e83"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95727514609888,
                                40.74852586565075
                            ],
                            [
                                -73.96044627402996,
                                40.74309150523921
                            ],
                            [
                                -73.9604731384891,
                                40.74310320348826
                            ],
                            [
                                -73.95772492158643,
                                40.74841996736166
                            ],
                            [
                                -73.95727514609888,
                                40.74852586565075
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "fb2943d1-094d-4b21-9533-e263377f78f5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95727514609888,
                                40.74852586565075
                            ],
                            [
                                -73.9572609589366,
                                40.74855017818392
                            ],
                            [
                                -73.95661947423673,
                                40.748730210456486
                            ],
                            [
                                -73.95654891355657,
                                40.74869685492393
                            ],
                            [
                                -73.95727514609888,
                                40.74852586565075
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c37bd93f-8756-408e-9178-5040cfb7655a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9572609589366,
                                40.74855017818392
                            ],
                            [
                                -73.95727514609888,
                                40.74852586565075
                            ],
                            [
                                -73.95772492158643,
                                40.74841996736166
                            ],
                            [
                                -73.9572609589366,
                                40.74855017818392
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "911450c2-42cc-4d9f-86c0-0cdf55db9862"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9572609589366,
                                40.74855017818392
                            ],
                            [
                                -73.95714599990826,
                                40.74874718341553
                            ],
                            [
                                -73.95692255016391,
                                40.74887348088607
                            ],
                            [
                                -73.95661947423673,
                                40.748730210456486
                            ],
                            [
                                -73.9572609589366,
                                40.74855017818392
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "21ead35a-2c27-46ad-a43f-c2a68bf49ae9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95714599990826,
                                40.74874718341553
                            ],
                            [
                                -73.9572609589366,
                                40.74855017818392
                            ],
                            [
                                -73.95772492158643,
                                40.74841996736166
                            ],
                            [
                                -73.95714599990826,
                                40.74874718341553
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "07a1eb33-dd00-4617-bd32-db73facc5d8b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95714599990826,
                                40.74874718341553
                            ],
                            [
                                -73.95703992384293,
                                40.74892896591773
                            ],
                            [
                                -73.95692255016391,
                                40.74887348088607
                            ],
                            [
                                -73.95714599990826,
                                40.74874718341553
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3784191d-41fe-40e4-a26d-7e7a6c8cc483"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95661947423673,
                                40.748730210456486
                            ],
                            [
                                -73.95692255016391,
                                40.74887348088607
                            ],
                            [
                                -73.95590052872657,
                                40.74945114421136
                            ],
                            [
                                -73.95542255396731,
                                40.74906612536799
                            ],
                            [
                                -73.95661947423673,
                                40.748730210456486
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "e0a834c2-bc1f-4e6e-8742-13816becb540"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95654891355657,
                                40.74869685492393
                            ],
                            [
                                -73.95661947423673,
                                40.748730210456486
                            ],
                            [
                                -73.95542255396731,
                                40.74906612536799
                            ],
                            [
                                -73.95532257737871,
                                40.74898559209764
                            ],
                            [
                                -73.95654891355657,
                                40.74869685492393
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "27c5aa56-9aec-4d63-b455-66b5e199666f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95532257737871,
                                40.74898559209764
                            ],
                            [
                                -73.95271304544484,
                                40.74688355857504
                            ],
                            [
                                -73.95654891355657,
                                40.74869685492393
                            ],
                            [
                                -73.95532257737871,
                                40.74898559209764
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "e3001a92-5089-4bf6-9d1e-ae7ae00fe95f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95542255396731,
                                40.74906612536799
                            ],
                            [
                                -73.94437231918582,
                                40.7521673667234
                            ],
                            [
                                -73.94770312899114,
                                40.75408444936229
                            ],
                            [
                                -73.94181806890535,
                                40.7574107821128
                            ],
                            [
                                -73.93582841287416,
                                40.75590976709328
                            ],
                            [
                                -73.91604982129275,
                                40.771975419466976
                            ],
                            [
                                -73.91591218451019,
                                40.77205321404015
                            ],
                            [
                                -73.92365391234112,
                                40.761267714763555
                            ],
                            [
                                -73.92268166406873,
                                40.76129809846373
                            ],
                            [
                                -73.92292271244594,
                                40.76092191648312
                            ],
                            [
                                -73.91058462690303,
                                40.75967096743181
                            ],
                            [
                                -73.90253597797718,
                                40.751410921770564
                            ],
                            [
                                -73.90610455888364,
                                40.75146592189031
                            ],
                            [
                                -73.90394456778373,
                                40.75200845995772
                            ],
                            [
                                -73.90773782444516,
                                40.75367633498012
                            ],
                            [
                                -73.90688222665455,
                                40.751477907556776
                            ],
                            [
                                -73.92390901685472,
                                40.751740329938215
                            ],
                            [
                                -73.9228835432046,
                                40.752877445197385
                            ],
                            [
                                -73.92601392583137,
                                40.75588622617003
                            ],
                            [
                                -73.95532257737871,
                                40.74898559209764
                            ],
                            [
                                -73.95542255396731,
                                40.74906612536799
                            ]
                        ],
                        [
                            [
                                -73.94197007319912,
                                40.754293039858105
                            ],
                            [
                                -73.94122800099406,
                                40.75508291260112
                            ],
                            [
                                -73.94221560432585,
                                40.75404271701485
                            ],
                            [
                                -73.94197007319912,
                                40.754293039858105
                            ]
                        ],
                        [
                            [
                                -73.94112694771111,
                                40.755180069448976
                            ],
                            [
                                -73.94063471495286,
                                40.75569438179477
                            ],
                            [
                                -73.94093054185402,
                                40.75538881223615
                            ],
                            [
                                -73.94112694771111,
                                40.755180069448976
                            ]
                        ],
                        [
                            [
                                -73.92306849733845,
                                40.75398811168642
                            ],
                            [
                                -73.91762789573595,
                                40.755266954335745
                            ],
                            [
                                -73.92389950904486,
                                40.75820216329582
                            ],
                            [
                                -73.92306849733845,
                                40.75398811168642
                            ]
                        ],
                        [
                            [
                                -73.91527677017712,
                                40.753898835239475
                            ],
                            [
                                -73.91160625931609,
                                40.75845793853912
                            ],
                            [
                                -73.91590538067022,
                                40.75751978451053
                            ],
                            [
                                -73.91527677017712,
                                40.753898835239475
                            ]
                        ],
                        [
                            [
                                -73.92889741728209,
                                40.758230335208445
                            ],
                            [
                                -73.92855088766966,
                                40.75806738473227
                            ],
                            [
                                -73.93082116754786,
                                40.75526424535158
                            ],
                            [
                                -73.92883687622184,
                                40.75625123577057
                            ],
                            [
                                -73.92834119303457,
                                40.75832547265307
                            ],
                            [
                                -73.92889741728209,
                                40.758230335208445
                            ]
                        ],
                        [
                            [
                                -73.92505343095992,
                                40.76237384906452
                            ],
                            [
                                -73.92616308120283,
                                40.76224724316158
                            ],
                            [
                                -73.92607175936936,
                                40.76111856313504
                            ],
                            [
                                -73.92473377714417,
                                40.76157483449863
                            ],
                            [
                                -73.92505343095992,
                                40.76237384906452
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "8270b3b9-9c5e-4f59-a8bd-9b7a9ad3d995"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95542255396731,
                                40.74906612536799
                            ],
                            [
                                -73.95590052872657,
                                40.74945114421136
                            ],
                            [
                                -73.9514466666596,
                                40.7519685403031
                            ],
                            [
                                -73.95162131030365,
                                40.75176726373513
                            ],
                            [
                                -73.95144575037065,
                                40.751969058204686
                            ],
                            [
                                -73.950963493034,
                                40.75224163798292
                            ],
                            [
                                -73.9507227821188,
                                40.75143485897861
                            ],
                            [
                                -73.95028071128601,
                                40.7519509090627
                            ],
                            [
                                -73.95079722360289,
                                40.75233561619997
                            ],
                            [
                                -73.94770312899114,
                                40.75408444936229
                            ],
                            [
                                -73.94437231918582,
                                40.7521673667234
                            ],
                            [
                                -73.95542255396731,
                                40.74906612536799
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "fd935699-e1af-47ef-8c9d-a320fcda5dad"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95590052872657,
                                40.74945114421136
                            ],
                            [
                                -73.95619528379535,
                                40.7496885756938
                            ],
                            [
                                -73.94912008344735,
                                40.75465425633863
                            ],
                            [
                                -73.9537279714189,
                                40.756100518718654
                            ],
                            [
                                -73.9531623222465,
                                40.75642307665181
                            ],
                            [
                                -73.94912001697202,
                                40.75465433867726
                            ],
                            [
                                -73.94892891470305,
                                40.75478996322968
                            ],
                            [
                                -73.94770312899114,
                                40.75408444936229
                            ],
                            [
                                -73.95079722360289,
                                40.75233561619997
                            ],
                            [
                                -73.95099824780573,
                                40.75248534243702
                            ],
                            [
                                -73.9514466666596,
                                40.7519685403031
                            ],
                            [
                                -73.95590052872657,
                                40.74945114421136
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3e0eabd9-a734-4f60-b35e-d6e31e1aaaf3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9537279714189,
                                40.756100518718654
                            ],
                            [
                                -73.95589063364147,
                                40.75677930627437
                            ],
                            [
                                -73.95535776671073,
                                40.75738370522673
                            ],
                            [
                                -73.95525631076147,
                                40.757339313927254
                            ],
                            [
                                -73.95466008819494,
                                40.75787342149195
                            ],
                            [
                                -73.95514195702243,
                                40.757289279218455
                            ],
                            [
                                -73.9531623222465,
                                40.75642307665181
                            ],
                            [
                                -73.9537279714189,
                                40.756100518718654
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a85dfcb0-7d2c-4d2c-a046-299fc5552c7b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95535776671073,
                                40.75738370522673
                            ],
                            [
                                -73.95589063364147,
                                40.75677930627437
                            ],
                            [
                                -73.9537279714189,
                                40.756100518718654
                            ],
                            [
                                -73.95933301014958,
                                40.75290428013759
                            ],
                            [
                                -73.95543857025328,
                                40.757419060218545
                            ],
                            [
                                -73.95535776671073,
                                40.75738370522673
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5425c836-9891-48f4-a0f0-fa91f2eccdd8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95535776671073,
                                40.75738370522673
                            ],
                            [
                                -73.95079335197087,
                                40.76256084670005
                            ],
                            [
                                -73.95334369247216,
                                40.75946921343049
                            ],
                            [
                                -73.95339398280325,
                                40.75945675242033
                            ],
                            [
                                -73.95348061250333,
                                40.75930323303555
                            ],
                            [
                                -73.95466008819494,
                                40.75787342149195
                            ],
                            [
                                -73.95525631076147,
                                40.757339313927254
                            ],
                            [
                                -73.95535776671073,
                                40.75738370522673
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a9c56164-62fb-4a83-b4a5-c881f48dfdf8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95334369247216,
                                40.75946921343049
                            ],
                            [
                                -73.95079335197087,
                                40.76256084670005
                            ],
                            [
                                -73.95535776671073,
                                40.75738370522673
                            ],
                            [
                                -73.95543857025328,
                                40.757419060218545
                            ],
                            [
                                -73.95339038954211,
                                40.75979349292856
                            ],
                            [
                                -73.94766008126048,
                                40.76595457188496
                            ],
                            [
                                -73.94946976088104,
                                40.762523015850306
                            ],
                            [
                                -73.95466008819494,
                                40.75787342149195
                            ],
                            [
                                -73.95348061250333,
                                40.75930323303555
                            ],
                            [
                                -73.95366759346912,
                                40.758971877821274
                            ],
                            [
                                -73.95347617056692,
                                40.75895858609767
                            ],
                            [
                                -73.95338613691756,
                                40.75921227697095
                            ],
                            [
                                -73.95303940787915,
                                40.75954460950215
                            ],
                            [
                                -73.95334369247216,
                                40.75946921343049
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "cc0bdd12-8b52-41b2-a8eb-bcb6b1a8c0f6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95334369247216,
                                40.75946921343049
                            ],
                            [
                                -73.95348061250333,
                                40.75930323303555
                            ],
                            [
                                -73.95339398280325,
                                40.75945675242033
                            ],
                            [
                                -73.95334369247216,
                                40.75946921343049
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "04346e59-6ad4-46a2-8c9a-c8d7b607de22"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95348061250333,
                                40.75930323303555
                            ],
                            [
                                -73.95334369247216,
                                40.75946921343049
                            ],
                            [
                                -73.95303940787915,
                                40.75954460950215
                            ],
                            [
                                -73.95338613691756,
                                40.75921227697095
                            ],
                            [
                                -73.95347617056692,
                                40.75895858609767
                            ],
                            [
                                -73.95366759346912,
                                40.758971877821274
                            ],
                            [
                                -73.95348061250333,
                                40.75930323303555
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5bf63a44-5f7c-4d5a-ae7f-cf6a6903b0d5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9531623222465,
                                40.75642307665181
                            ],
                            [
                                -73.95514195702243,
                                40.757289279218455
                            ],
                            [
                                -73.95466008819494,
                                40.75787342149195
                            ],
                            [
                                -73.94946976088104,
                                40.762523015850306
                            ],
                            [
                                -73.95248208499204,
                                40.756810977728215
                            ],
                            [
                                -73.9531623222465,
                                40.75642307665181
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e10bd50c-9a39-4d7a-87e5-f502011bb1f0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94912001697202,
                                40.75465433867726
                            ],
                            [
                                -73.9531623222465,
                                40.75642307665181
                            ],
                            [
                                -73.95248208499204,
                                40.756810977728215
                            ],
                            [
                                -73.94946976088104,
                                40.762523015850306
                            ],
                            [
                                -73.94709133118823,
                                40.76465365860415
                            ],
                            [
                                -73.9470978050629,
                                40.764639720818046
                            ],
                            [
                                -73.94620239133715,
                                40.76512963036765
                            ],
                            [
                                -73.94643143560444,
                                40.765244805674065
                            ],
                            [
                                -73.94339188890159,
                                40.767967689674784
                            ],
                            [
                                -73.94541072314523,
                                40.76853393909748
                            ],
                            [
                                -73.94326921766132,
                                40.769097167082116
                            ],
                            [
                                -73.94448118960837,
                                40.76980009542695
                            ],
                            [
                                -73.9428665352588,
                                40.77090984716105
                            ],
                            [
                                -73.94303765533728,
                                40.77035615363248
                            ],
                            [
                                -73.94235198745059,
                                40.77166648454732
                            ],
                            [
                                -73.94331157053203,
                                40.77085673946326
                            ],
                            [
                                -73.94218295775178,
                                40.77196725182165
                            ],
                            [
                                -73.94191664050771,
                                40.7720898607781
                            ],
                            [
                                -73.94125643357111,
                                40.772289941215796
                            ],
                            [
                                -73.94030111479232,
                                40.77069397845646
                            ],
                            [
                                -73.94660828881007,
                                40.76394696686299
                            ],
                            [
                                -73.9424917293762,
                                40.76218066395751
                            ],
                            [
                                -73.93412550353952,
                                40.77504556031087
                            ],
                            [
                                -73.92398980949385,
                                40.77475186758566
                            ],
                            [
                                -73.91623299266108,
                                40.78344835353541
                            ],
                            [
                                -73.89706166619533,
                                40.78022783606587
                            ],
                            [
                                -73.89017895235483,
                                40.77330882474927
                            ],
                            [
                                -73.90684192113757,
                                40.76881836791123
                            ],
                            [
                                -73.90196865684096,
                                40.759555159638424
                            ],
                            [
                                -73.89381430315562,
                                40.76603111130029
                            ],
                            [
                                -73.88673147161929,
                                40.7630618409779
                            ],
                            [
                                -73.88778492415635,
                                40.76767398743423
                            ],
                            [
                                -73.88326144676337,
                                40.75961305437448
                            ],
                            [
                                -73.88096272442664,
                                40.763889446033936
                            ],
                            [
                                -73.87741548757542,
                                40.7639747954659
                            ],
                            [
                                -73.87622573725358,
                                40.76308091594732
                            ],
                            [
                                -73.87482524665765,
                                40.75491775441214
                            ],
                            [
                                -73.83844110160689,
                                40.7571068680195
                            ],
                            [
                                -73.84565411102251,
                                40.75461885323378
                            ],
                            [
                                -73.8476006314676,
                                40.754036358508245
                            ],
                            [
                                -73.84460539588375,
                                40.75315272092131
                            ],
                            [
                                -73.84446893888611,
                                40.75338892506174
                            ],
                            [
                                -73.84140497430921,
                                40.75025314341622
                            ],
                            [
                                -73.85233424264267,
                                40.75247217070275
                            ],
                            [
                                -73.85096723867473,
                                40.75301754627638
                            ],
                            [
                                -73.8814558456187,
                                40.750135154448216
                            ],
                            [
                                -73.89361084552355,
                                40.759607861983866
                            ],
                            [
                                -73.89338397240529,
                                40.75484324024446
                            ],
                            [
                                -73.90483293871765,
                                40.75735534957699
                            ],
                            [
                                -73.90597766098588,
                                40.76553038774095
                            ],
                            [
                                -73.91277931265176,
                                40.76514511575409
                            ],
                            [
                                -73.90074694176636,
                                40.777085170724526
                            ],
                            [
                                -73.90607130316774,
                                40.772975967823164
                            ],
                            [
                                -73.90671855010972,
                                40.779259127868556
                            ],
                            [
                                -73.91478835615715,
                                40.77901938759495
                            ],
                            [
                                -73.94434407939444,
                                40.75804380338545
                            ],
                            [
                                -73.94699333458861,
                                40.758707709929716
                            ],
                            [
                                -73.9503916532236,
                                40.75563185777993
                            ],
                            [
                                -73.94892891470305,
                                40.75478996322968
                            ],
                            [
                                -73.94912001697202,
                                40.75465433867726
                            ]
                        ],
                        [
                            [
                                -73.88613389163103,
                                40.75503619421786
                            ],
                            [
                                -73.88590741197487,
                                40.75375956758084
                            ],
                            [
                                -73.88594201687623,
                                40.753954992842864
                            ],
                            [
                                -73.88613389163103,
                                40.75503619421786
                            ]
                        ],
                        [
                            [
                                -73.8784700459386,
                                40.75453125455615
                            ],
                            [
                                -73.87776076583467,
                                40.754606114079415
                            ],
                            [
                                -73.87859553788886,
                                40.755223965785866
                            ],
                            [
                                -73.8784700459386,
                                40.75453125455615
                            ]
                        ],
                        [
                            [
                                -73.88670642299591,
                                40.755045333752975
                            ],
                            [
                                -73.88613270226159,
                                40.75465697351346
                            ],
                            [
                                -73.88628338746399,
                                40.75547484217204
                            ],
                            [
                                -73.88670642299591,
                                40.755045333752975
                            ]
                        ],
                        [
                            [
                                -73.8844184876291,
                                40.755546484025544
                            ],
                            [
                                -73.8843386130273,
                                40.75556141637684
                            ],
                            [
                                -73.88435597836005,
                                40.75556893426736
                            ],
                            [
                                -73.8844184876291,
                                40.755546484025544
                            ]
                        ],
                        [
                            [
                                -73.8786416547006,
                                40.75639349498552
                            ],
                            [
                                -73.87886102329298,
                                40.75665696507132
                            ],
                            [
                                -73.8788180550763,
                                40.75643592887955
                            ],
                            [
                                -73.8786416547006,
                                40.75639349498552
                            ]
                        ],
                        [
                            [
                                -73.94244669233942,
                                40.76202523964502
                            ],
                            [
                                -73.94253492037505,
                                40.7620003366666
                            ],
                            [
                                -73.9425725975147,
                                40.76182331024161
                            ],
                            [
                                -73.94244669233942,
                                40.76202523964502
                            ]
                        ],
                        [
                            [
                                -73.94006833884431,
                                40.765667020153764
                            ],
                            [
                                -73.94001408060116,
                                40.76566302977282
                            ],
                            [
                                -73.94003219243392,
                                40.765723707350325
                            ],
                            [
                                -73.94006833884431,
                                40.765667020153764
                            ]
                        ],
                        [
                            [
                                -73.89897777449299,
                                40.77418049622514
                            ],
                            [
                                -73.89847975209273,
                                40.77410633903191
                            ],
                            [
                                -73.89848785938817,
                                40.77416449932915
                            ],
                            [
                                -73.89897777449299,
                                40.77418049622514
                            ]
                        ],
                        [
                            [
                                -73.89990675197268,
                                40.77569739331701
                            ],
                            [
                                -73.90062830575836,
                                40.775728668683364
                            ],
                            [
                                -73.9004737012064,
                                40.77523542501942
                            ],
                            [
                                -73.89990675197268,
                                40.77569739331701
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6c01883e-d962-4837-84fb-ff62fa3851ac"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94892891470305,
                                40.75478996322968
                            ],
                            [
                                -73.94434407939444,
                                40.75804380338545
                            ],
                            [
                                -73.94181806890535,
                                40.7574107821128
                            ],
                            [
                                -73.94770312899114,
                                40.75408444936229
                            ],
                            [
                                -73.94892891470305,
                                40.75478996322968
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "27c67613-66a9-4033-8c12-7aab503291f0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94892891470305,
                                40.75478996322968
                            ],
                            [
                                -73.9503916532236,
                                40.75563185777993
                            ],
                            [
                                -73.94699333458861,
                                40.758707709929716
                            ],
                            [
                                -73.94434407939444,
                                40.75804380338545
                            ],
                            [
                                -73.94892891470305,
                                40.75478996322968
                            ]
                        ],
                        [
                            [
                                -73.94900717779255,
                                40.75657864088887
                            ],
                            [
                                -73.94865114669938,
                                40.75505005492697
                            ],
                            [
                                -73.94589641437159,
                                40.757595057787285
                            ],
                            [
                                -73.94900717779255,
                                40.75657864088887
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6758082b-9c7f-453c-99ad-3ed39fb73445"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91478835615715,
                                40.77901938759495
                            ],
                            [
                                -73.90671855010972,
                                40.779259127868556
                            ],
                            [
                                -73.90607130316774,
                                40.772975967823164
                            ],
                            [
                                -73.90074694176636,
                                40.777085170724526
                            ],
                            [
                                -73.91277931265176,
                                40.76514511575409
                            ],
                            [
                                -73.90597766098588,
                                40.76553038774095
                            ],
                            [
                                -73.90483293871765,
                                40.75735534957699
                            ],
                            [
                                -73.89338397240529,
                                40.75484324024446
                            ],
                            [
                                -73.89361084552355,
                                40.759607861983866
                            ],
                            [
                                -73.8814558456187,
                                40.750135154448216
                            ],
                            [
                                -73.85096723867473,
                                40.75301754627638
                            ],
                            [
                                -73.85233424264267,
                                40.75247217070275
                            ],
                            [
                                -73.85261991781195,
                                40.752530172846754
                            ],
                            [
                                -73.85261717296713,
                                40.75235929371657
                            ],
                            [
                                -73.87823301467111,
                                40.742139678637905
                            ],
                            [
                                -73.86455802488133,
                                40.731219465305216
                            ],
                            [
                                -73.85907429223079,
                                40.73246335537347
                            ],
                            [
                                -73.85527962750963,
                                40.73149817249841
                            ],
                            [
                                -73.86403797040042,
                                40.72729033551499
                            ],
                            [
                                -73.86215579147105,
                                40.71701398718805
                            ],
                            [
                                -73.86570512499014,
                                40.72021243457479
                            ],
                            [
                                -73.86848171602372,
                                40.719037575339094
                            ],
                            [
                                -73.86084546388187,
                                40.71421126704788
                            ],
                            [
                                -73.86590892577864,
                                40.712763538183694
                            ],
                            [
                                -73.85990387854714,
                                40.713364232289216
                            ],
                            [
                                -73.85761425614425,
                                40.71691927542453
                            ],
                            [
                                -73.8581846414339,
                                40.7119224519247
                            ],
                            [
                                -73.84846756842761,
                                40.71009952077115
                            ],
                            [
                                -73.84855777202976,
                                40.710022905763516
                            ],
                            [
                                -73.84854957823259,
                                40.71001034695671
                            ],
                            [
                                -73.85809503178936,
                                40.71152995589427
                            ],
                            [
                                -73.85498853536599,
                                40.702714247697934
                            ],
                            [
                                -73.84687294668011,
                                40.69893135500235
                            ],
                            [
                                -73.82977350356879,
                                40.70801216710272
                            ],
                            [
                                -73.81506836264212,
                                40.702430651638586
                            ],
                            [
                                -73.81710603451735,
                                40.70117272986468
                            ],
                            [
                                -73.81954252072937,
                                40.70218746754978
                            ],
                            [
                                -73.8354444842077,
                                40.695673667923906
                            ],
                            [
                                -73.83742202400248,
                                40.699622129424405
                            ],
                            [
                                -73.8386729972059,
                                40.6959326089294
                            ],
                            [
                                -73.83635454691826,
                                40.69530088589912
                            ],
                            [
                                -73.8488219180458,
                                40.69019397205815
                            ],
                            [
                                -73.84247766336976,
                                40.68527256238189
                            ],
                            [
                                -73.82147141025689,
                                40.68719189957158
                            ],
                            [
                                -73.83625073675466,
                                40.67484065114887
                            ],
                            [
                                -73.84010935733681,
                                40.67923279283112
                            ],
                            [
                                -73.83068902608218,
                                40.684875700177216
                            ],
                            [
                                -73.86621246111076,
                                40.682820613447696
                            ],
                            [
                                -73.86630642220703,
                                40.6832594661636
                            ],
                            [
                                -73.86286853637704,
                                40.68530756349369
                            ],
                            [
                                -73.86381592662514,
                                40.687678960786464
                            ],
                            [
                                -73.83975765901255,
                                40.69553096834447
                            ],
                            [
                                -73.85183751214895,
                                40.69771215397611
                            ],
                            [
                                -73.85561597176101,
                                40.692802941231285
                            ],
                            [
                                -73.86677193996705,
                                40.695508100765586
                            ],
                            [
                                -73.86698538199924,
                                40.6938999436633
                            ],
                            [
                                -73.86816764703408,
                                40.69403380338769
                            ],
                            [
                                -73.86870006682487,
                                40.693867236992524
                            ],
                            [
                                -73.86867068293193,
                                40.69381137466886
                            ],
                            [
                                -73.86809698106225,
                                40.69275962897199
                            ],
                            [
                                -73.86975436377831,
                                40.69236703771386
                            ],
                            [
                                -73.86840064391112,
                                40.69182242868406
                            ],
                            [
                                -73.86862680013049,
                                40.69157820946264
                            ],
                            [
                                -73.8744988581722,
                                40.69243563685003
                            ],
                            [
                                -73.88226327924384,
                                40.685432066540514
                            ],
                            [
                                -73.88394837588473,
                                40.68781462102672
                            ],
                            [
                                -73.89633449945205,
                                40.68076470395783
                            ],
                            [
                                -73.89754219083909,
                                40.68044044551458
                            ],
                            [
                                -73.89743508430549,
                                40.68063256932226
                            ],
                            [
                                -73.89735471101405,
                                40.6806604897987
                            ],
                            [
                                -73.89733621034316,
                                40.68080992584278
                            ],
                            [
                                -73.89460089227292,
                                40.685716439994906
                            ],
                            [
                                -73.89570138149656,
                                40.68470012790433
                            ],
                            [
                                -73.89650272708667,
                                40.683053221682705
                            ],
                            [
                                -73.89703074670462,
                                40.683277256786624
                            ],
                            [
                                -73.89586726524365,
                                40.6926750817614
                            ],
                            [
                                -73.89380885132638,
                                40.692243525582505
                            ],
                            [
                                -73.8904898565797,
                                40.69076183454668
                            ],
                            [
                                -73.89037728689483,
                                40.6914220325565
                            ],
                            [
                                -73.89203001903265,
                                40.69184922859923
                            ],
                            [
                                -73.89040332186839,
                                40.691899486897334
                            ],
                            [
                                -73.89102669877666,
                                40.69323756685381
                            ],
                            [
                                -73.89117931625522,
                                40.693280976201486
                            ],
                            [
                                -73.89137993700763,
                                40.69270016192292
                            ],
                            [
                                -73.89222091642041,
                                40.69294416256766
                            ],
                            [
                                -73.89172453973376,
                                40.692160460675964
                            ],
                            [
                                -73.8934129313951,
                                40.69263792198569
                            ],
                            [
                                -73.89200503868123,
                                40.69190686145327
                            ],
                            [
                                -73.89262679804119,
                                40.69209194198953
                            ],
                            [
                                -73.89663735459713,
                                40.694091602922306
                            ],
                            [
                                -73.89665585039339,
                                40.69417687451781
                            ],
                            [
                                -73.89040044276791,
                                40.6937599592491
                            ],
                            [
                                -73.89012518189686,
                                40.69460354976418
                            ],
                            [
                                -73.8923679967374,
                                40.69489213029136
                            ],
                            [
                                -73.89015848450613,
                                40.69524035367502
                            ],
                            [
                                -73.88985748753547,
                                40.69521372298045
                            ],
                            [
                                -73.88987927213766,
                                40.69528435808211
                            ],
                            [
                                -73.88907592089893,
                                40.69541096778477
                            ],
                            [
                                -73.8854397803167,
                                40.701435942689116
                            ],
                            [
                                -73.88416727645485,
                                40.70166955496943
                            ],
                            [
                                -73.88497189267568,
                                40.70221121835061
                            ],
                            [
                                -73.88282258471278,
                                40.70577255660068
                            ],
                            [
                                -73.89043990411272,
                                40.70589225470888
                            ],
                            [
                                -73.89360501566057,
                                40.70802299105055
                            ],
                            [
                                -73.89270957568047,
                                40.708639037100156
                            ],
                            [
                                -73.90044132772016,
                                40.712625160145755
                            ],
                            [
                                -73.88900081731026,
                                40.70842712261048
                            ],
                            [
                                -73.88923787636487,
                                40.70814916488732
                            ],
                            [
                                -73.88556779849182,
                                40.706656969995315
                            ],
                            [
                                -73.88529174697884,
                                40.70706609797833
                            ],
                            [
                                -73.88346268465837,
                                40.706394932794204
                            ],
                            [
                                -73.8800622504909,
                                40.71252105707912
                            ],
                            [
                                -73.88825732301143,
                                40.712191665370845
                            ],
                            [
                                -73.8717008948894,
                                40.71323768069757
                            ],
                            [
                                -73.87172310975835,
                                40.7132199990061
                            ],
                            [
                                -73.87149138933081,
                                40.71322169787273
                            ],
                            [
                                -73.87152664100866,
                                40.71324868984934
                            ],
                            [
                                -73.87078264102952,
                                40.71329569487817
                            ],
                            [
                                -73.87528558113847,
                                40.71436558090371
                            ],
                            [
                                -73.87588249978278,
                                40.71407596119522
                            ],
                            [
                                -73.87778751414403,
                                40.71463094550562
                            ],
                            [
                                -73.87629977550098,
                                40.719963241650525
                            ],
                            [
                                -73.88554286024088,
                                40.716890296139894
                            ],
                            [
                                -73.88731827743968,
                                40.71740752514865
                            ],
                            [
                                -73.89336018501656,
                                40.72499900999908
                            ],
                            [
                                -73.89477057774187,
                                40.72494453400119
                            ],
                            [
                                -73.8957074379382,
                                40.72417894473225
                            ],
                            [
                                -73.89430038874238,
                                40.72322338975161
                            ],
                            [
                                -73.89570053258862,
                                40.723435344799206
                            ],
                            [
                                -73.89575901021867,
                                40.72367860992164
                            ],
                            [
                                -73.8957006673359,
                                40.72343525493308
                            ],
                            [
                                -73.89449368912355,
                                40.72314659068642
                            ],
                            [
                                -73.89473708764976,
                                40.72233825414759
                            ],
                            [
                                -73.89426808981634,
                                40.72314199319688
                            ],
                            [
                                -73.89213158794857,
                                40.71898881564036
                            ],
                            [
                                -73.8947903855939,
                                40.71768686073953
                            ],
                            [
                                -73.89244527739649,
                                40.71663048700361
                            ],
                            [
                                -73.89456827733335,
                                40.714914691851476
                            ],
                            [
                                -73.89612412052293,
                                40.715054308841566
                            ],
                            [
                                -73.89681733701445,
                                40.716653195408924
                            ],
                            [
                                -73.89971555151811,
                                40.71537659375372
                            ],
                            [
                                -73.90111428946814,
                                40.71550211256166
                            ],
                            [
                                -73.9013728150928,
                                40.722616777115576
                            ],
                            [
                                -73.8957046926867,
                                40.72292459959203
                            ],
                            [
                                -73.89759702593436,
                                40.724820610937634
                            ],
                            [
                                -73.88850115452563,
                                40.73012964388689
                            ],
                            [
                                -73.91836862226471,
                                40.72594763367213
                            ],
                            [
                                -73.91753285936878,
                                40.731011239562136
                            ],
                            [
                                -73.91584476954138,
                                40.728624756843956
                            ],
                            [
                                -73.9121168033331,
                                40.7296840376866
                            ],
                            [
                                -73.91209622382826,
                                40.7297982365025
                            ],
                            [
                                -73.91415998012432,
                                40.73028566624367
                            ],
                            [
                                -73.91565455577154,
                                40.730460658880496
                            ],
                            [
                                -73.91588651065938,
                                40.73067250374568
                            ],
                            [
                                -73.91515065210113,
                                40.73328965751134
                            ],
                            [
                                -73.91931592447791,
                                40.731147936791736
                            ],
                            [
                                -73.91407919472236,
                                40.74187131720441
                            ],
                            [
                                -73.91385430709151,
                                40.74190590210158
                            ],
                            [
                                -73.91397116233644,
                                40.7382938705383
                            ],
                            [
                                -73.8981859544858,
                                40.74606304570057
                            ],
                            [
                                -73.89031707192302,
                                40.74073798909299
                            ],
                            [
                                -73.88058695285508,
                                40.74798489952921
                            ],
                            [
                                -73.90295844667214,
                                40.753838854579556
                            ],
                            [
                                -73.90892553918458,
                                40.76172799068981
                            ],
                            [
                                -73.92268166406873,
                                40.76129809846373
                            ],
                            [
                                -73.91561202772935,
                                40.77233102816499
                            ],
                            [
                                -73.91604982129275,
                                40.771975419466976
                            ],
                            [
                                -73.94181806890535,
                                40.7574107821128
                            ],
                            [
                                -73.94434407939444,
                                40.75804380338545
                            ],
                            [
                                -73.91478835615715,
                                40.77901938759495
                            ]
                        ],
                        [
                            [
                                -73.88612894730372,
                                40.69333497136131
                            ],
                            [
                                -73.88170818238203,
                                40.69176587290744
                            ],
                            [
                                -73.88397988339501,
                                40.69556601514997
                            ],
                            [
                                -73.88731281276215,
                                40.69707267321447
                            ],
                            [
                                -73.88612894730372,
                                40.69333497136131
                            ]
                        ],
                        [
                            [
                                -73.88107066060128,
                                40.70187037919324
                            ],
                            [
                                -73.88115602031621,
                                40.70186177512865
                            ],
                            [
                                -73.88111001220064,
                                40.70186306771338
                            ],
                            [
                                -73.88107066060128,
                                40.70187037919324
                            ]
                        ],
                        [
                            [
                                -73.88320210944559,
                                40.70174806775483
                            ],
                            [
                                -73.88298999025756,
                                40.702182715383
                            ],
                            [
                                -73.88357565678851,
                                40.701718521444405
                            ],
                            [
                                -73.88320210944559,
                                40.70174806775483
                            ]
                        ],
                        [
                            [
                                -73.89434217567445,
                                40.726618159480985
                            ],
                            [
                                -73.89449143075892,
                                40.72632194359291
                            ],
                            [
                                -73.89408234785999,
                                40.72657255118239
                            ],
                            [
                                -73.89434217567445,
                                40.726618159480985
                            ]
                        ],
                        [
                            [
                                -73.91131277791615,
                                40.72947513116167
                            ],
                            [
                                -73.91182091085493,
                                40.72934501056303
                            ],
                            [
                                -73.91121945733117,
                                40.72903673036711
                            ],
                            [
                                -73.91131277791615,
                                40.72947513116167
                            ]
                        ],
                        [
                            [
                                -73.88659303764074,
                                40.73047216189115
                            ],
                            [
                                -73.88624280607104,
                                40.73062444351174
                            ],
                            [
                                -73.88750950697843,
                                40.73021136477479
                            ],
                            [
                                -73.88659303764074,
                                40.73047216189115
                            ]
                        ],
                        [
                            [
                                -73.88534377752457,
                                40.73101677931419
                            ],
                            [
                                -73.88516065146233,
                                40.73110193269247
                            ],
                            [
                                -73.88513116156818,
                                40.731238971459284
                            ],
                            [
                                -73.88518837437032,
                                40.731157338285506
                            ],
                            [
                                -73.88534377752457,
                                40.73101677931419
                            ]
                        ],
                        [
                            [
                                -73.87557602174118,
                                40.73705797540888
                            ],
                            [
                                -73.87734722998688,
                                40.73867149927686
                            ],
                            [
                                -73.87948695590822,
                                40.737445503205514
                            ],
                            [
                                -73.87545507796122,
                                40.73618315972266
                            ],
                            [
                                -73.87557602174118,
                                40.73705797540888
                            ]
                        ],
                        [
                            [
                                -73.87924011952938,
                                40.740671664682665
                            ],
                            [
                                -73.87957015158163,
                                40.74350883394242
                            ],
                            [
                                -73.88097701123293,
                                40.74195896756837
                            ],
                            [
                                -73.8847046953702,
                                40.74380488143794
                            ],
                            [
                                -73.87924011952938,
                                40.740671664682665
                            ]
                        ],
                        [
                            [
                                -73.87858547136777,
                                40.748196393914384
                            ],
                            [
                                -73.8768854231673,
                                40.747369352301085
                            ],
                            [
                                -73.87450154323659,
                                40.748628171963766
                            ],
                            [
                                -73.8767949170041,
                                40.750097348908774
                            ],
                            [
                                -73.87858547136777,
                                40.748196393914384
                            ]
                        ],
                        [
                            [
                                -73.91347435356013,
                                40.76560775974031
                            ],
                            [
                                -73.91348302948916,
                                40.76559725274784
                            ],
                            [
                                -73.91349009563717,
                                40.76558853037114
                            ],
                            [
                                -73.91347435356013,
                                40.76560775974031
                            ]
                        ],
                        [
                            [
                                -73.91373420023922,
                                40.775094887091875
                            ],
                            [
                                -73.91369923870667,
                                40.7736733257393
                            ],
                            [
                                -73.91180472411182,
                                40.773729134655554
                            ],
                            [
                                -73.91039330551045,
                                40.77641017889951
                            ],
                            [
                                -73.91373420023922,
                                40.775094887091875
                            ]
                        ],
                        [
                            [
                                -73.82672208465213,
                                40.68584563566583
                            ],
                            [
                                -73.82568300515963,
                                40.68539869490758
                            ],
                            [
                                -73.8247993297197,
                                40.68608545508763
                            ],
                            [
                                -73.82510124180858,
                                40.68611653418722
                            ],
                            [
                                -73.82672208465213,
                                40.68584563566583
                            ]
                        ],
                        [
                            [
                                -73.82650351645866,
                                40.70079234199514
                            ],
                            [
                                -73.82947652502745,
                                40.701220306090796
                            ],
                            [
                                -73.83025079554724,
                                40.700158807014134
                            ],
                            [
                                -73.82650351645866,
                                40.70079234199514
                            ]
                        ],
                        [
                            [
                                -73.85337358097912,
                                40.701056673164906
                            ],
                            [
                                -73.85502861100746,
                                40.70124500844936
                            ],
                            [
                                -73.85520108664369,
                                40.70024771971703
                            ],
                            [
                                -73.85337358097912,
                                40.701056673164906
                            ]
                        ],
                        [
                            [
                                -73.85638706044809,
                                40.704604949850896
                            ],
                            [
                                -73.85647993367205,
                                40.704630553955354
                            ],
                            [
                                -73.85629731605624,
                                40.70450296168222
                            ],
                            [
                                -73.85638706044809,
                                40.704604949850896
                            ]
                        ],
                        [
                            [
                                -73.83185952910077,
                                40.70572913360096
                            ],
                            [
                                -73.83186691055745,
                                40.70604699829291
                            ],
                            [
                                -73.8320405207659,
                                40.706160348984604
                            ],
                            [
                                -73.83185952910077,
                                40.70572913360096
                            ]
                        ],
                        [
                            [
                                -73.8738491237952,
                                40.71973533908304
                            ],
                            [
                                -73.8722064267374,
                                40.72049541432736
                            ],
                            [
                                -73.87226005346493,
                                40.72051110823173
                            ],
                            [
                                -73.8738491237952,
                                40.71973533908304
                            ]
                        ],
                        [
                            [
                                -73.87110365040304,
                                40.72101539659679
                            ],
                            [
                                -73.87126306453504,
                                40.72094154433744
                            ],
                            [
                                -73.87138218742993,
                                40.720886316105464
                            ],
                            [
                                -73.87110365040304,
                                40.72101539659679
                            ]
                        ],
                        [
                            [
                                -73.86160403903702,
                                40.72984593858402
                            ],
                            [
                                -73.86169560341561,
                                40.72982108141578
                            ],
                            [
                                -73.86154785839915,
                                40.729767690378964
                            ],
                            [
                                -73.86160403903702,
                                40.72984593858402
                            ]
                        ],
                        [
                            [
                                -73.86854083235593,
                                40.733003920159966
                            ],
                            [
                                -73.8689686433319,
                                40.733426944993475
                            ],
                            [
                                -73.8696528667276,
                                40.733464455055035
                            ],
                            [
                                -73.86854083235593,
                                40.733003920159966
                            ]
                        ],
                        [
                            [
                                -73.8699927227558,
                                40.748613745062386
                            ],
                            [
                                -73.8681903869872,
                                40.7492957504506
                            ],
                            [
                                -73.87088116106338,
                                40.74900565219957
                            ],
                            [
                                -73.8699927227558,
                                40.748613745062386
                            ]
                        ],
                        [
                            [
                                -73.86274980514762,
                                40.749865486077745
                            ],
                            [
                                -73.86270109040807,
                                40.74987079764214
                            ],
                            [
                                -73.86255901196442,
                                40.749887082126904
                            ],
                            [
                                -73.86274980514762,
                                40.749865486077745
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "7fc10cb6-1d22-4fc5-996f-2bd4775f553f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.85233424264267,
                                40.75247217070275
                            ],
                            [
                                -73.85261717296713,
                                40.75235929371657
                            ],
                            [
                                -73.85261991781195,
                                40.752530172846754
                            ],
                            [
                                -73.85233424264267,
                                40.75247217070275
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5e07db9a-968f-4691-9b9b-f554b0c11bad"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86455802488133,
                                40.731219465305216
                            ],
                            [
                                -73.87823301467111,
                                40.742139678637905
                            ],
                            [
                                -73.85261717296713,
                                40.75235929371657
                            ],
                            [
                                -73.85251153247965,
                                40.745782690756755
                            ],
                            [
                                -73.86737872229706,
                                40.74199036566871
                            ],
                            [
                                -73.86423526289471,
                                40.73377606173082
                            ],
                            [
                                -73.85907429223079,
                                40.73246335537347
                            ],
                            [
                                -73.86455802488133,
                                40.731219465305216
                            ]
                        ],
                        [
                            [
                                -73.86568827169708,
                                40.73668464682304
                            ],
                            [
                                -73.86561403222706,
                                40.73667506369111
                            ],
                            [
                                -73.86556051778896,
                                40.736722087661796
                            ],
                            [
                                -73.86568827169708,
                                40.73668464682304
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c98e17b3-0432-452d-abff-0093a9156eb0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.85907429223079,
                                40.73246335537347
                            ],
                            [
                                -73.8518617874278,
                                40.73409938755508
                            ],
                            [
                                -73.85256022026974,
                                40.733495032155176
                            ],
                            [
                                -73.84856941599249,
                                40.73481165636823
                            ],
                            [
                                -73.84867763440053,
                                40.734821657690915
                            ],
                            [
                                -73.84807969761872,
                                40.73495728932574
                            ],
                            [
                                -73.85527962750963,
                                40.73149817249841
                            ],
                            [
                                -73.85907429223079,
                                40.73246335537347
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e1fcbe5d-2299-4483-a299-67bf64665600"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8518617874278,
                                40.73409938755508
                            ],
                            [
                                -73.84867763440053,
                                40.734821657690915
                            ],
                            [
                                -73.84856941599249,
                                40.73481165636823
                            ],
                            [
                                -73.85256022026974,
                                40.733495032155176
                            ],
                            [
                                -73.8518617874278,
                                40.73409938755508
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4b90f4f8-7322-411f-a345-d0fe4ec29fb1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84867763440053,
                                40.734821657690915
                            ],
                            [
                                -73.8518617874278,
                                40.73409938755508
                            ],
                            [
                                -73.85080036762756,
                                40.73501783632971
                            ],
                            [
                                -73.84867763440053,
                                40.734821657690915
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b5c07acc-2ad3-4363-be89-1f19811c3875"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8581846414339,
                                40.7119224519247
                            ],
                            [
                                -73.85761425614425,
                                40.71691927542453
                            ],
                            [
                                -73.85990387854714,
                                40.713364232289216
                            ],
                            [
                                -73.86590892577864,
                                40.712763538183694
                            ],
                            [
                                -73.86084546388187,
                                40.71421126704788
                            ],
                            [
                                -73.86848171602372,
                                40.719037575339094
                            ],
                            [
                                -73.86570512499014,
                                40.72021243457479
                            ],
                            [
                                -73.86215579147105,
                                40.71701398718805
                            ],
                            [
                                -73.86403797040042,
                                40.72729033551499
                            ],
                            [
                                -73.85527962750963,
                                40.73149817249841
                            ],
                            [
                                -73.83584832294842,
                                40.72655576947189
                            ],
                            [
                                -73.83499379799913,
                                40.72063627062563
                            ],
                            [
                                -73.83874679829808,
                                40.72406624832457
                            ],
                            [
                                -73.85610035863358,
                                40.727771308921675
                            ],
                            [
                                -73.84572007143933,
                                40.72244669873533
                            ],
                            [
                                -73.84667130723818,
                                40.722571639920595
                            ],
                            [
                                -73.84240856226317,
                                40.720679806406636
                            ],
                            [
                                -73.84241414872591,
                                40.72075091234468
                            ],
                            [
                                -73.8307314741089,
                                40.7147582372765
                            ],
                            [
                                -73.83095561102421,
                                40.714578890539386
                            ],
                            [
                                -73.83057689239534,
                                40.71467894396301
                            ],
                            [
                                -73.83041501858935,
                                40.71459591014129
                            ],
                            [
                                -73.83077411091263,
                                40.71447420563814
                            ],
                            [
                                -73.8303565088966,
                                40.71456589735838
                            ],
                            [
                                -73.82578710732463,
                                40.71222200439085
                            ],
                            [
                                -73.82874007906625,
                                40.7149208123878
                            ],
                            [
                                -73.82359972218423,
                                40.716049466338454
                            ],
                            [
                                -73.82180941216232,
                                40.71629556775275
                            ],
                            [
                                -73.82110279825815,
                                40.716343220957185
                            ],
                            [
                                -73.82444941166202,
                                40.71222901036288
                            ],
                            [
                                -73.82076790091567,
                                40.70913782953658
                            ],
                            [
                                -73.82009096287157,
                                40.708463037834285
                            ],
                            [
                                -73.82025668124987,
                                40.70870858389863
                            ],
                            [
                                -73.81509986476252,
                                40.704378662475385
                            ],
                            [
                                -73.8109782327924,
                                40.70548619640649
                            ],
                            [
                                -73.81279397613265,
                                40.69937686165016
                            ],
                            [
                                -73.81710603451735,
                                40.70117272986468
                            ],
                            [
                                -73.81506836264212,
                                40.702430651638586
                            ],
                            [
                                -73.82977350356879,
                                40.70801216710272
                            ],
                            [
                                -73.84687294668011,
                                40.69893135500235
                            ],
                            [
                                -73.85498853536599,
                                40.702714247697934
                            ],
                            [
                                -73.85809503178936,
                                40.71152995589427
                            ],
                            [
                                -73.84854957823259,
                                40.71001034695671
                            ],
                            [
                                -73.84820804261831,
                                40.70948686807305
                            ],
                            [
                                -73.84689857625106,
                                40.7097475121577
                            ],
                            [
                                -73.84634231025247,
                                40.70965895619982
                            ],
                            [
                                -73.84594678182556,
                                40.70929176703621
                            ],
                            [
                                -73.84555580177624,
                                40.70952955388163
                            ],
                            [
                                -73.84596395694327,
                                40.70959872343384
                            ],
                            [
                                -73.84486840642926,
                                40.70942431492169
                            ],
                            [
                                -73.84716236989924,
                                40.70985466441807
                            ],
                            [
                                -73.84827636039397,
                                40.71026192454004
                            ],
                            [
                                -73.84846756842761,
                                40.71009952077115
                            ],
                            [
                                -73.8581846414339,
                                40.7119224519247
                            ]
                        ],
                        [
                            [
                                -73.8366037674748,
                                40.708533344577816
                            ],
                            [
                                -73.83638951209302,
                                40.7077497627464
                            ],
                            [
                                -73.8364923844644,
                                40.70859538580642
                            ],
                            [
                                -73.8366037674748,
                                40.708533344577816
                            ]
                        ],
                        [
                            [
                                -73.84432461217033,
                                40.70941598557381
                            ],
                            [
                                -73.84433856570163,
                                40.70933932379227
                            ],
                            [
                                -73.84412299967785,
                                40.70897282967042
                            ],
                            [
                                -73.84406994338055,
                                40.70898814700105
                            ],
                            [
                                -73.84384197072437,
                                40.70926063541837
                            ],
                            [
                                -73.84365740106946,
                                40.70920273974968
                            ],
                            [
                                -73.84382345015815,
                                40.70933584346609
                            ],
                            [
                                -73.84366661239439,
                                40.70931066063489
                            ],
                            [
                                -73.84408894095216,
                                40.709495851120764
                            ],
                            [
                                -73.84432461217033,
                                40.70941598557381
                            ]
                        ],
                        [
                            [
                                -73.83486192425744,
                                40.70955403729855
                            ],
                            [
                                -73.83483541407507,
                                40.70953891757247
                            ],
                            [
                                -73.8348707277472,
                                40.70958022579453
                            ],
                            [
                                -73.83486192425744,
                                40.70955403729855
                            ]
                        ],
                        [
                            [
                                -73.83056442705733,
                                40.714264148315955
                            ],
                            [
                                -73.83146074808153,
                                40.71466965304488
                            ],
                            [
                                -73.8312152519891,
                                40.714497644786086
                            ],
                            [
                                -73.83056413061328,
                                40.71423361276602
                            ],
                            [
                                -73.83056442705733,
                                40.714264148315955
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "186d498f-b0b6-4bfd-a2da-4cef3c5129f0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84846756842761,
                                40.71009952077115
                            ],
                            [
                                -73.84716236989924,
                                40.70985466441807
                            ],
                            [
                                -73.84687960319637,
                                40.709751288668954
                            ],
                            [
                                -73.84689857625106,
                                40.7097475121577
                            ],
                            [
                                -73.84854957823259,
                                40.71001034695671
                            ],
                            [
                                -73.84855777202976,
                                40.710022905763516
                            ],
                            [
                                -73.84846756842761,
                                40.71009952077115
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1d268155-b1c2-412f-a9a1-82492638f580"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84716236989924,
                                40.70985466441807
                            ],
                            [
                                -73.84846756842761,
                                40.71009952077115
                            ],
                            [
                                -73.84827636039397,
                                40.71026192454004
                            ],
                            [
                                -73.84716236989924,
                                40.70985466441807
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ab0b3585-dde1-4aaa-9835-10980947fd04"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84716236989924,
                                40.70985466441807
                            ],
                            [
                                -73.84486840642926,
                                40.70942431492169
                            ],
                            [
                                -73.84596395694327,
                                40.70959872343384
                            ],
                            [
                                -73.84634743134059,
                                40.709663710366485
                            ],
                            [
                                -73.84634231025247,
                                40.70965895619982
                            ],
                            [
                                -73.84689857625106,
                                40.7097475121577
                            ],
                            [
                                -73.84687960319637,
                                40.709751288668954
                            ],
                            [
                                -73.84716236989924,
                                40.70985466441807
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "fd426df5-d1ab-4542-96ec-e6c2cbf9b8a9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84596395694327,
                                40.70959872343384
                            ],
                            [
                                -73.84634231025247,
                                40.70965895619982
                            ],
                            [
                                -73.84634743134059,
                                40.709663710366485
                            ],
                            [
                                -73.84596395694327,
                                40.70959872343384
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9e32c4a0-0025-4a25-8df6-480400aed91a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84634231025247,
                                40.70965895619982
                            ],
                            [
                                -73.84596395694327,
                                40.70959872343384
                            ],
                            [
                                -73.84555580177624,
                                40.70952955388163
                            ],
                            [
                                -73.84594678182556,
                                40.70929176703621
                            ],
                            [
                                -73.84634231025247,
                                40.70965895619982
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e60a5a2f-eeab-4c68-a24e-ef25cafa11ce"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84854957823259,
                                40.71001034695671
                            ],
                            [
                                -73.84689857625106,
                                40.7097475121577
                            ],
                            [
                                -73.84820804261831,
                                40.70948686807305
                            ],
                            [
                                -73.84854957823259,
                                40.71001034695671
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d3fceb44-840e-4f99-b3cd-5f2ad3337f30"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.81710603451735,
                                40.70117272986468
                            ],
                            [
                                -73.82956261776765,
                                40.69348287190008
                            ],
                            [
                                -73.81777313157211,
                                40.688726931319025
                            ],
                            [
                                -73.82227461910524,
                                40.67844001271883
                            ],
                            [
                                -73.82794241811783,
                                40.680545171207065
                            ],
                            [
                                -73.82257789843001,
                                40.67657159196902
                            ],
                            [
                                -73.83543032157536,
                                40.673936767306195
                            ],
                            [
                                -73.83499550403894,
                                40.672906999112925
                            ],
                            [
                                -73.83628253621741,
                                40.673982235198906
                            ],
                            [
                                -73.83748283725227,
                                40.67288964070649
                            ],
                            [
                                -73.83485298347456,
                                40.66991044519535
                            ],
                            [
                                -73.8347889319144,
                                40.66956273441884
                            ],
                            [
                                -73.83482707474474,
                                40.66949783956828
                            ],
                            [
                                -73.83913239204894,
                                40.66985192947225
                            ],
                            [
                                -73.83881125704181,
                                40.66923826746643
                            ],
                            [
                                -73.84213268310992,
                                40.67031997258805
                            ],
                            [
                                -73.84196462473122,
                                40.67065007177728
                            ],
                            [
                                -73.84436872214572,
                                40.670292857337046
                            ],
                            [
                                -73.84266947883575,
                                40.66926560186732
                            ],
                            [
                                -73.84242089383021,
                                40.66975387094034
                            ],
                            [
                                -73.84181840774906,
                                40.66604025680825
                            ],
                            [
                                -73.84481094299716,
                                40.667061247611066
                            ],
                            [
                                -73.84361735417409,
                                40.665679068993754
                            ],
                            [
                                -73.845716323531,
                                40.666268834393136
                            ],
                            [
                                -73.84681236519566,
                                40.66426600890723
                            ],
                            [
                                -73.85006079723054,
                                40.66268324835125
                            ],
                            [
                                -73.85089829656994,
                                40.66279028514816
                            ],
                            [
                                -73.84688335276437,
                                40.6704543664296
                            ],
                            [
                                -73.85668094102584,
                                40.67369580813454
                            ],
                            [
                                -73.85648662342597,
                                40.67375228395666
                            ],
                            [
                                -73.85637783540588,
                                40.67382400696841
                            ],
                            [
                                -73.84034901008262,
                                40.67294766468251
                            ],
                            [
                                -73.8407440018699,
                                40.678852632070196
                            ],
                            [
                                -73.84010935733681,
                                40.67923279283112
                            ],
                            [
                                -73.83625073675466,
                                40.67484065114887
                            ],
                            [
                                -73.82147141025689,
                                40.68719189957158
                            ],
                            [
                                -73.84247766336976,
                                40.68527256238189
                            ],
                            [
                                -73.8488219180458,
                                40.69019397205815
                            ],
                            [
                                -73.83635454691826,
                                40.69530088589912
                            ],
                            [
                                -73.83508445581792,
                                40.69495481602525
                            ],
                            [
                                -73.8354444842077,
                                40.695673667923906
                            ],
                            [
                                -73.81954252072937,
                                40.70218746754978
                            ],
                            [
                                -73.81710603451735,
                                40.70117272986468
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1ce465c3-2253-4ab1-b8ff-d5b8027af71c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83499550403894,
                                40.672906999112925
                            ],
                            [
                                -73.83279795228539,
                                40.667702588735416
                            ],
                            [
                                -73.83501179019628,
                                40.67077254454766
                            ],
                            [
                                -73.83485298347456,
                                40.66991044519535
                            ],
                            [
                                -73.83748283725227,
                                40.67288964070649
                            ],
                            [
                                -73.83628253621741,
                                40.673982235198906
                            ],
                            [
                                -73.83499550403894,
                                40.672906999112925
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d34ab7a7-1934-40e3-bdbc-9d3910a76e9b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83279795228539,
                                40.667702588735416
                            ],
                            [
                                -73.83499550403894,
                                40.672906999112925
                            ],
                            [
                                -73.82982695152005,
                                40.668588991938314
                            ],
                            [
                                -73.83219795300096,
                                40.6668705624694
                            ],
                            [
                                -73.83279795228539,
                                40.667702588735416
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "92e6b9c2-722b-4f97-b441-9375ed868bea"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83279795228539,
                                40.667702588735416
                            ],
                            [
                                -73.832388360122,
                                40.66673256120263
                            ],
                            [
                                -73.83219795300096,
                                40.6668705624694
                            ],
                            [
                                -73.8319782299335,
                                40.666565869833725
                            ],
                            [
                                -73.83295718144474,
                                40.66676482743721
                            ],
                            [
                                -73.83291083813192,
                                40.666735313569546
                            ],
                            [
                                -73.83294564827946,
                                40.66674296574227
                            ],
                            [
                                -73.83295730810718,
                                40.666764825393074
                            ],
                            [
                                -73.83325728030974,
                                40.66681147054016
                            ],
                            [
                                -73.83432537491127,
                                40.66704626541905
                            ],
                            [
                                -73.8346883466326,
                                40.669016696408896
                            ],
                            [
                                -73.83312057241851,
                                40.666803910211506
                            ],
                            [
                                -73.83435648985775,
                                40.669672995347845
                            ],
                            [
                                -73.83460954886655,
                                40.669867930336004
                            ],
                            [
                                -73.83469184960528,
                                40.66972790679565
                            ],
                            [
                                -73.83485298347456,
                                40.66991044519535
                            ],
                            [
                                -73.83501179019628,
                                40.67077254454766
                            ],
                            [
                                -73.83279795228539,
                                40.667702588735416
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1774df14-2a72-4fcf-8797-cb9bbcc1b70f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83219795300096,
                                40.6668705624694
                            ],
                            [
                                -73.832388360122,
                                40.66673256120263
                            ],
                            [
                                -73.83279795228539,
                                40.667702588735416
                            ],
                            [
                                -73.83219795300096,
                                40.6668705624694
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c4a67a7a-ad67-4364-b30c-61a1be3b4102"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83219795300096,
                                40.6668705624694
                            ],
                            [
                                -73.82982695152005,
                                40.668588991938314
                            ],
                            [
                                -73.82696528015911,
                                40.666198241835126
                            ],
                            [
                                -73.829157836194,
                                40.66599266597954
                            ],
                            [
                                -73.8319782299335,
                                40.666565869833725
                            ],
                            [
                                -73.83219795300096,
                                40.6668705624694
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ffa2f33e-52bc-46c4-ba9c-8334f7a86dcc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.82982695152005,
                                40.668588991938314
                            ],
                            [
                                -73.82666174391619,
                                40.670883037752056
                            ],
                            [
                                -73.8227432486294,
                                40.66619874674447
                            ],
                            [
                                -73.82627146634941,
                                40.66540605344514
                            ],
                            [
                                -73.829157836194,
                                40.66599266597954
                            ],
                            [
                                -73.82696528015911,
                                40.666198241835126
                            ],
                            [
                                -73.82982695152005,
                                40.668588991938314
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3b2e2040-0620-449e-a2d2-a75f6d5fac2a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8319782299335,
                                40.666565869833725
                            ],
                            [
                                -73.829157836194,
                                40.66599266597954
                            ],
                            [
                                -73.83141273827299,
                                40.66578124450762
                            ],
                            [
                                -73.83291083813192,
                                40.666735313569546
                            ],
                            [
                                -73.83194774905155,
                                40.666523601626
                            ],
                            [
                                -73.8319782299335,
                                40.666565869833725
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6368c8ed-1935-49ed-9559-d0b631ea6a2a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83291083813192,
                                40.666735313569546
                            ],
                            [
                                -73.83295718144474,
                                40.66676482743721
                            ],
                            [
                                -73.8319782299335,
                                40.666565869833725
                            ],
                            [
                                -73.83194774905155,
                                40.666523601626
                            ],
                            [
                                -73.83291083813192,
                                40.666735313569546
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "15a917e3-bb48-4a7a-8170-9d5d23431f7a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83291083813192,
                                40.666735313569546
                            ],
                            [
                                -73.83141273827299,
                                40.66578124450762
                            ],
                            [
                                -73.83238409210198,
                                40.66569016957439
                            ],
                            [
                                -73.83294564827946,
                                40.66674296574227
                            ],
                            [
                                -73.83291083813192,
                                40.666735313569546
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "7baa487e-c1e0-4fd2-9f8e-8aa4ba4f9bc6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83141273827299,
                                40.66578124450762
                            ],
                            [
                                -73.82814287268191,
                                40.66369882151124
                            ],
                            [
                                -73.83024211961822,
                                40.661674435240016
                            ],
                            [
                                -73.83238409210198,
                                40.66569016957439
                            ],
                            [
                                -73.83141273827299,
                                40.66578124450762
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "eb7441e7-1461-4ceb-b672-cb1b0fcdeaf4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83294564827946,
                                40.66674296574227
                            ],
                            [
                                -73.83238409210198,
                                40.66569016957439
                            ],
                            [
                                -73.83508812453755,
                                40.66543663727116
                            ],
                            [
                                -73.83459821302598,
                                40.66701998320651
                            ],
                            [
                                -73.83325728030974,
                                40.66681147054016
                            ],
                            [
                                -73.83294564827946,
                                40.66674296574227
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "2c404cbb-923b-402f-8bbe-f8368464f9a7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83325728030974,
                                40.66681147054016
                            ],
                            [
                                -73.83295730810718,
                                40.666764825393074
                            ],
                            [
                                -73.83294564827946,
                                40.66674296574227
                            ],
                            [
                                -73.83325728030974,
                                40.66681147054016
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f7b0f4fc-32fc-4434-91b0-ccae503b42f0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83325728030974,
                                40.66681147054016
                            ],
                            [
                                -73.83459821302598,
                                40.66701998320651
                            ],
                            [
                                -73.83508812453755,
                                40.66543663727116
                            ],
                            [
                                -73.83282414005285,
                                40.66500295057536
                            ],
                            [
                                -73.832460964431,
                                40.66429544004414
                            ],
                            [
                                -73.83194844794085,
                                40.662115055332464
                            ],
                            [
                                -73.83230434182641,
                                40.663990320053976
                            ],
                            [
                                -73.83230097932105,
                                40.66398376948226
                            ],
                            [
                                -73.83185774774155,
                                40.66206405566552
                            ],
                            [
                                -73.83113420459463,
                                40.66132592125565
                            ],
                            [
                                -73.83194524544685,
                                40.660313481165886
                            ],
                            [
                                -73.82996763259305,
                                40.65943812466559
                            ],
                            [
                                -73.82918631439344,
                                40.65791602125009
                            ],
                            [
                                -73.82866321013215,
                                40.65650810412943
                            ],
                            [
                                -73.82847016127927,
                                40.65557901467711
                            ],
                            [
                                -73.83654181785617,
                                40.65965335528365
                            ],
                            [
                                -73.83233207348293,
                                40.66371903908134
                            ],
                            [
                                -73.83579436204761,
                                40.66347323471581
                            ],
                            [
                                -73.83652434798756,
                                40.664868176488525
                            ],
                            [
                                -73.8352681478188,
                                40.66546885779777
                            ],
                            [
                                -73.83683678776455,
                                40.668595232370706
                            ],
                            [
                                -73.83881125704181,
                                40.66923826746643
                            ],
                            [
                                -73.83913239204894,
                                40.66985192947225
                            ],
                            [
                                -73.83482707474474,
                                40.66949783956828
                            ],
                            [
                                -73.83491874180082,
                                40.66934188050976
                            ],
                            [
                                -73.8346883466326,
                                40.669016696408896
                            ],
                            [
                                -73.83432537491127,
                                40.66704626541905
                            ],
                            [
                                -73.83325728030974,
                                40.66681147054016
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4368fc44-f893-4fa1-b727-03d1c77e4fb1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83282414005285,
                                40.66500295057536
                            ],
                            [
                                -73.83508812453755,
                                40.66543663727116
                            ],
                            [
                                -73.8343370790611,
                                40.66530336612292
                            ],
                            [
                                -73.83367567736518,
                                40.66518266584906
                            ],
                            [
                                -73.83282414005285,
                                40.66500295057536
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "31a46b42-37c7-4925-b56d-5d18a659c9e3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.832460964431,
                                40.66429544004414
                            ],
                            [
                                -73.83282414005285,
                                40.66500295057536
                            ],
                            [
                                -73.83246561343981,
                                40.6643152181936
                            ],
                            [
                                -73.832460964431,
                                40.66429544004414
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "2a0606af-5930-4760-8584-c7569af5544e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.832460964431,
                                40.66429544004414
                            ],
                            [
                                -73.83230434182641,
                                40.663990320053976
                            ],
                            [
                                -73.83194844794085,
                                40.662115055332464
                            ],
                            [
                                -73.832460964431,
                                40.66429544004414
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "35e1de3b-79b4-43ba-97a3-0dd358e52922"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83230097932105,
                                40.66398376948226
                            ],
                            [
                                -73.83230434182641,
                                40.663990320053976
                            ],
                            [
                                -73.83231288457014,
                                40.66403533320705
                            ],
                            [
                                -73.83230097932105,
                                40.66398376948226
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f6fcab68-7403-4f73-9e30-b6ae56a9de23"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83230097932105,
                                40.66398376948226
                            ],
                            [
                                -73.83101381195772,
                                40.66147621002567
                            ],
                            [
                                -73.83113420459463,
                                40.66132592125565
                            ],
                            [
                                -73.83185774774155,
                                40.66206405566552
                            ],
                            [
                                -73.83230097932105,
                                40.66398376948226
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "2d22564e-709b-4790-b739-9939c406266e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83101381195772,
                                40.66147621002567
                            ],
                            [
                                -73.83071949916594,
                                40.66090285271001
                            ],
                            [
                                -73.83113420459463,
                                40.66132592125565
                            ],
                            [
                                -73.83101381195772,
                                40.66147621002567
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4b0c1866-f0aa-4881-8dee-5cb4a3c0243f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83071949916594,
                                40.66090285271001
                            ],
                            [
                                -73.83101381195772,
                                40.66147621002567
                            ],
                            [
                                -73.83101124728302,
                                40.661479411565374
                            ],
                            [
                                -73.83068289925498,
                                40.66086551471049
                            ],
                            [
                                -73.83071949916594,
                                40.66090285271001
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "2a3627f5-fb2f-4c81-8869-b87e90f19416"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83071949916594,
                                40.66090285271001
                            ],
                            [
                                -73.83048858502075,
                                40.6604530037122
                            ],
                            [
                                -73.83056467474965,
                                40.66044366918985
                            ],
                            [
                                -73.83062462496115,
                                40.66059852464583
                            ],
                            [
                                -73.83079124358173,
                                40.66048271137695
                            ],
                            [
                                -73.83047626729366,
                                40.6602153067443
                            ],
                            [
                                -73.83055190343751,
                                40.660410680025755
                            ],
                            [
                                -73.83029465504113,
                                40.66007520439241
                            ],
                            [
                                -73.82996763259305,
                                40.65943812466559
                            ],
                            [
                                -73.83194524544685,
                                40.660313481165886
                            ],
                            [
                                -73.83113420459463,
                                40.66132592125565
                            ],
                            [
                                -73.83071949916594,
                                40.66090285271001
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "7254d5bc-b3c4-4723-9d39-c527950c4f49"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83048858502075,
                                40.6604530037122
                            ],
                            [
                                -73.83071949916594,
                                40.66090285271001
                            ],
                            [
                                -73.83068289925498,
                                40.66086551471049
                            ],
                            [
                                -73.83046388489535,
                                40.66045603386982
                            ],
                            [
                                -73.83048858502075,
                                40.6604530037122
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3279cada-a412-43b3-bf04-453e085808ee"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83048858502075,
                                40.6604530037122
                            ],
                            [
                                -73.83029465504113,
                                40.66007520439241
                            ],
                            [
                                -73.83055190343751,
                                40.660410680025755
                            ],
                            [
                                -73.83056467474965,
                                40.66044366918985
                            ],
                            [
                                -73.83048858502075,
                                40.6604530037122
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e4145c43-39b2-4327-878d-f28b399fb275"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83029465504113,
                                40.66007520439241
                            ],
                            [
                                -73.83048858502075,
                                40.6604530037122
                            ],
                            [
                                -73.83046388489535,
                                40.66045603386982
                            ],
                            [
                                -73.83018073555903,
                                40.659926642881636
                            ],
                            [
                                -73.83029465504113,
                                40.66007520439241
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "7d1ea133-dc16-46d9-ad2e-2cc408e353be"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.82996763259305,
                                40.65943812466559
                            ],
                            [
                                -73.83029465504113,
                                40.66007520439241
                            ],
                            [
                                -73.83018073555903,
                                40.659926642881636
                            ],
                            [
                                -73.82990450129343,
                                40.65941018067575
                            ],
                            [
                                -73.82996763259305,
                                40.65943812466559
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "80f727cb-a9cf-43a6-a886-6e2ad89bca74"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.82918631439344,
                                40.65791602125009
                            ],
                            [
                                -73.82996763259305,
                                40.65943812466559
                            ],
                            [
                                -73.82990450129343,
                                40.65941018067575
                            ],
                            [
                                -73.83018073555903,
                                40.659926642881636
                            ],
                            [
                                -73.83010544094775,
                                40.65982845176457
                            ],
                            [
                                -73.82918631439344,
                                40.65791602125009
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3d1e7950-74de-4cf9-9f3e-0272576af52c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.82866321013215,
                                40.65650810412943
                            ],
                            [
                                -73.82809530149773,
                                40.6541523904803
                            ],
                            [
                                -73.82800508818532,
                                40.65416598664706
                            ],
                            [
                                -73.82756283499452,
                                40.652118626252594
                            ],
                            [
                                -73.84171991196973,
                                40.662383796807084
                            ],
                            [
                                -73.83652434798756,
                                40.664868176488525
                            ],
                            [
                                -73.83579436204761,
                                40.66347323471581
                            ],
                            [
                                -73.83233207348293,
                                40.66371903908134
                            ],
                            [
                                -73.83654181785617,
                                40.65965335528365
                            ],
                            [
                                -73.82847016127927,
                                40.65557901467711
                            ],
                            [
                                -73.82866321013215,
                                40.65650810412943
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "eec52df6-4ad9-4a8f-b0b3-df73c3c6679d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83881125704181,
                                40.66923826746643
                            ],
                            [
                                -73.83683678776455,
                                40.668595232370706
                            ],
                            [
                                -73.8352681478188,
                                40.66546885779777
                            ],
                            [
                                -73.83652434798756,
                                40.664868176488525
                            ],
                            [
                                -73.83881125704181,
                                40.66923826746643
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "38749807-938a-4a6b-854b-a0c4598f3680"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84213268310992,
                                40.67031997258805
                            ],
                            [
                                -73.84253393743242,
                                40.670450651051695
                            ],
                            [
                                -73.84242089383021,
                                40.66975387094034
                            ],
                            [
                                -73.84266947883575,
                                40.66926560186732
                            ],
                            [
                                -73.84436872214572,
                                40.670292857337046
                            ],
                            [
                                -73.84196462473122,
                                40.67065007177728
                            ],
                            [
                                -73.84213268310992,
                                40.67031997258805
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ec076796-b514-43f6-bd9a-6059ff3c5d04"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84242089383021,
                                40.66975387094034
                            ],
                            [
                                -73.84253393743242,
                                40.670450651051695
                            ],
                            [
                                -73.84213268310992,
                                40.67031997258805
                            ],
                            [
                                -73.84242089383021,
                                40.66975387094034
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "7e2039e8-4085-482b-8510-f6f76412b4af"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.85668094102584,
                                40.67369580813454
                            ],
                            [
                                -73.85720515294761,
                                40.67386923881361
                            ],
                            [
                                -73.85637783540588,
                                40.67382400696841
                            ],
                            [
                                -73.85648662342597,
                                40.67375228395666
                            ],
                            [
                                -73.85668094102584,
                                40.67369580813454
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "2d3da455-0a56-44c3-a34d-71893291b4ed"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.85720515294761,
                                40.67386923881361
                            ],
                            [
                                -73.85668094102584,
                                40.67369580813454
                            ],
                            [
                                -73.85949793305848,
                                40.67287708692214
                            ],
                            [
                                -73.86198960145494,
                                40.67409398537193
                            ],
                            [
                                -73.86179284614848,
                                40.67412006128285
                            ],
                            [
                                -73.85720515294761,
                                40.67386923881361
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "59fc5189-d1f3-4546-94bb-2fd761168ae8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.85720515294761,
                                40.67386923881361
                            ],
                            [
                                -73.85905858981232,
                                40.67448243130449
                            ],
                            [
                                -73.85445342905739,
                                40.675092751624625
                            ],
                            [
                                -73.85637783540588,
                                40.67382400696841
                            ],
                            [
                                -73.85720515294761,
                                40.67386923881361
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "fa2784a9-7cae-4064-b9c1-69b7c314f3c7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.85905858981232,
                                40.67448243130449
                            ],
                            [
                                -73.85720515294761,
                                40.67386923881361
                            ],
                            [
                                -73.86179284614848,
                                40.67412006128285
                            ],
                            [
                                -73.85905858981232,
                                40.67448243130449
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b031f0e8-6697-45c0-a2b1-b367af219355"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8591813096652,
                                40.67452303203587
                            ],
                            [
                                -73.8620745262772,
                                40.67413546155099
                            ],
                            [
                                -73.86201123204485,
                                40.67409111867753
                            ],
                            [
                                -73.86665062608118,
                                40.67347626143276
                            ],
                            [
                                -73.86762097558233,
                                40.67051623281025
                            ],
                            [
                                -73.87486758555013,
                                40.66841010214682
                            ],
                            [
                                -73.87702705065668,
                                40.67045994392481
                            ],
                            [
                                -73.8876632987011,
                                40.66889055361166
                            ],
                            [
                                -73.88696792527006,
                                40.67059496195513
                            ],
                            [
                                -73.86124894937825,
                                40.67663960170464
                            ],
                            [
                                -73.8795839497044,
                                40.6769000561543
                            ],
                            [
                                -73.87702662126198,
                                40.681202066977605
                            ],
                            [
                                -73.86921279374803,
                                40.68449681457991
                            ],
                            [
                                -73.87403509033037,
                                40.686709238622754
                            ],
                            [
                                -73.87661595553153,
                                40.68239105947644
                            ],
                            [
                                -73.88040141382571,
                                40.6850426491322
                            ],
                            [
                                -73.87495107727275,
                                40.68650603428573
                            ],
                            [
                                -73.87525186646754,
                                40.69068015037183
                            ],
                            [
                                -73.86893723216208,
                                40.69124298335532
                            ],
                            [
                                -73.86921923017704,
                                40.69093846227759
                            ],
                            [
                                -73.86890259883579,
                                40.69080907423248
                            ],
                            [
                                -73.87031947745626,
                                40.69059632764505
                            ],
                            [
                                -73.86975108672415,
                                40.690804169990905
                            ],
                            [
                                -73.87094802985648,
                                40.690502064315
                            ],
                            [
                                -73.8717290368426,
                                40.690277062027114
                            ],
                            [
                                -73.87464064915497,
                                40.689138556416815
                            ],
                            [
                                -73.87461398985228,
                                40.68928855877292
                            ],
                            [
                                -73.87493209766743,
                                40.68890963968366
                            ],
                            [
                                -73.87090526680586,
                                40.6903821231802
                            ],
                            [
                                -73.86817347710027,
                                40.68270716558059
                            ],
                            [
                                -73.86621246111076,
                                40.682820613447696
                            ],
                            [
                                -73.86575592831271,
                                40.680688340982954
                            ],
                            [
                                -73.83533934738989,
                                40.68284131409792
                            ],
                            [
                                -73.8406201045048,
                                40.67981415963768
                            ],
                            [
                                -73.84082383169402,
                                40.68004605563797
                            ],
                            [
                                -73.84080136923654,
                                40.67971025098798
                            ],
                            [
                                -73.8470082292001,
                                40.67615221500679
                            ],
                            [
                                -73.84704925003777,
                                40.67630335663315
                            ],
                            [
                                -73.84735942304188,
                                40.67595089576762
                            ],
                            [
                                -73.84943629203829,
                                40.67476034617236
                            ],
                            [
                                -73.85634303451805,
                                40.67864125944039
                            ],
                            [
                                -73.85496858917342,
                                40.67963636227378
                            ],
                            [
                                -73.86269676501999,
                                40.67914548992068
                            ],
                            [
                                -73.85889884161064,
                                40.67679084691624
                            ],
                            [
                                -73.85668528667766,
                                40.67839346778021
                            ],
                            [
                                -73.85373682823898,
                                40.67556520041888
                            ],
                            [
                                -73.85445342905739,
                                40.675092751624625
                            ],
                            [
                                -73.85905858981232,
                                40.67448243130449
                            ],
                            [
                                -73.8591813096652,
                                40.67452303203587
                            ]
                        ],
                        [
                            [
                                -73.86506289603732,
                                40.6770788421232
                            ],
                            [
                                -73.86502770184113,
                                40.67694396939058
                            ],
                            [
                                -73.865050087858,
                                40.6770439865715
                            ],
                            [
                                -73.86506289603732,
                                40.6770788421232
                            ]
                        ],
                        [
                            [
                                -73.85090985339606,
                                40.67843408299635
                            ],
                            [
                                -73.84937544555083,
                                40.67999168319881
                            ],
                            [
                                -73.85108031590775,
                                40.68075175700225
                            ],
                            [
                                -73.85364847533755,
                                40.679725136820025
                            ],
                            [
                                -73.85090985339606,
                                40.67843408299635
                            ]
                        ],
                        [
                            [
                                -73.84369836604304,
                                40.67991965206436
                            ],
                            [
                                -73.84313815147905,
                                40.68046944628839
                            ],
                            [
                                -73.8437905215131,
                                40.680544965471825
                            ],
                            [
                                -73.8444922943962,
                                40.68031047797437
                            ],
                            [
                                -73.84369836604304,
                                40.67991965206436
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "4d8d1ee1-e8c3-4845-ba76-b09b1049a82a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8620745262772,
                                40.67413546155099
                            ],
                            [
                                -73.8591813096652,
                                40.67452303203587
                            ],
                            [
                                -73.85905858981232,
                                40.67448243130449
                            ],
                            [
                                -73.86179284614848,
                                40.67412006128285
                            ],
                            [
                                -73.8620745262772,
                                40.67413546155099
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "aa718412-9cd1-44b8-8b9b-8736a7209ea4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8620745262772,
                                40.67413546155099
                            ],
                            [
                                -73.86198960145494,
                                40.67409398537193
                            ],
                            [
                                -73.86201123204485,
                                40.67409111867753
                            ],
                            [
                                -73.8620745262772,
                                40.67413546155099
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "d652a697-7bde-4a23-ae6e-7e9a8f002ed2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86198960145494,
                                40.67409398537193
                            ],
                            [
                                -73.8620745262772,
                                40.67413546155099
                            ],
                            [
                                -73.86179284614848,
                                40.67412006128285
                            ],
                            [
                                -73.86198960145494,
                                40.67409398537193
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "5c06e242-ba05-49da-9d1a-3cd3446db521"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86198960145494,
                                40.67409398537193
                            ],
                            [
                                -73.85949793305848,
                                40.67287708692214
                            ],
                            [
                                -73.86004951926869,
                                40.67271677573719
                            ],
                            [
                                -73.86201123204485,
                                40.67409111867753
                            ],
                            [
                                -73.86198960145494,
                                40.67409398537193
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "45097c98-46e0-4e39-beba-7ccecbd6314b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.85949793305848,
                                40.67287708692214
                            ],
                            [
                                -73.85474308688428,
                                40.67055488187005
                            ],
                            [
                                -73.8599249681019,
                                40.66853244731406
                            ],
                            [
                                -73.85686682279355,
                                40.67048703217585
                            ],
                            [
                                -73.86004951926869,
                                40.67271677573719
                            ],
                            [
                                -73.85949793305848,
                                40.67287708692214
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5049f700-53ae-4d56-b294-1dca7753b14f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86146532568243,
                                40.66793126170816
                            ],
                            [
                                -73.86132072983236,
                                40.66764035930843
                            ],
                            [
                                -73.86224240263397,
                                40.667051280790595
                            ],
                            [
                                -73.86252502240903,
                                40.66819378610622
                            ],
                            [
                                -73.86331416364003,
                                40.66756581259426
                            ],
                            [
                                -73.86260030661973,
                                40.66711405728999
                            ],
                            [
                                -73.86225441985948,
                                40.66704360009373
                            ],
                            [
                                -73.86500807248399,
                                40.66528362887202
                            ],
                            [
                                -73.86569502612971,
                                40.665736061481375
                            ],
                            [
                                -73.865171968417,
                                40.66517887632521
                            ],
                            [
                                -73.86714792623594,
                                40.66391596143692
                            ],
                            [
                                -73.86517574239173,
                                40.66022603371
                            ],
                            [
                                -73.86718651516561,
                                40.660463477517844
                            ],
                            [
                                -73.86432531440981,
                                40.65681738267477
                            ],
                            [
                                -73.87312102398604,
                                40.66399393405499
                            ],
                            [
                                -73.87716637189416,
                                40.66222135146119
                            ],
                            [
                                -73.87720302343432,
                                40.66224093457119
                            ],
                            [
                                -73.87721649073531,
                                40.66219939048591
                            ],
                            [
                                -73.8856124133204,
                                40.658520481632756
                            ],
                            [
                                -73.88133482188869,
                                40.65671715846207
                            ],
                            [
                                -73.88400369855428,
                                40.65235687950855
                            ],
                            [
                                -73.88627331375639,
                                40.65261008058715
                            ],
                            [
                                -73.88480984849751,
                                40.651039831524976
                            ],
                            [
                                -73.88500967879905,
                                40.65071335864247
                            ],
                            [
                                -73.89233611008424,
                                40.648712097846435
                            ],
                            [
                                -73.88691801307624,
                                40.64384524650093
                            ],
                            [
                                -73.89356991019437,
                                40.63978799430397
                            ],
                            [
                                -73.89336848096933,
                                40.63949470289426
                            ],
                            [
                                -73.89519091024309,
                                40.63874199417427
                            ],
                            [
                                -73.89093420643837,
                                40.6358920848637
                            ],
                            [
                                -73.89040914205313,
                                40.63444359717579
                            ],
                            [
                                -73.90007340656283,
                                40.63787663239878
                            ],
                            [
                                -73.90597200698949,
                                40.64023793196338
                            ],
                            [
                                -73.90816891067864,
                                40.63881299479411
                            ],
                            [
                                -73.90586994555963,
                                40.63768861586329
                            ],
                            [
                                -73.91039979928745,
                                40.635331198994095
                            ],
                            [
                                -73.91541702101516,
                                40.633910970677846
                            ],
                            [
                                -73.9153990419336,
                                40.63413702128471
                            ],
                            [
                                -73.91036752085147,
                                40.63739118725401
                            ],
                            [
                                -73.91036708907721,
                                40.6373910054832
                            ],
                            [
                                -73.9103672796946,
                                40.63739134322364
                            ],
                            [
                                -73.90962408981076,
                                40.637872005669294
                            ],
                            [
                                -73.91342333653552,
                                40.642806137846556
                            ],
                            [
                                -73.91404647460747,
                                40.643910228790205
                            ],
                            [
                                -73.90944908990858,
                                40.65152200554188
                            ],
                            [
                                -73.92575460646336,
                                40.66017773224812
                            ],
                            [
                                -73.9300710958452,
                                40.64740576572159
                            ],
                            [
                                -73.9345034580172,
                                40.66180194802627
                            ],
                            [
                                -73.94643879600157,
                                40.65752136781694
                            ],
                            [
                                -73.94728421435902,
                                40.6595659376407
                            ],
                            [
                                -73.94538999721502,
                                40.65916344522923
                            ],
                            [
                                -73.93426406867209,
                                40.66584125566285
                            ],
                            [
                                -73.93302442265806,
                                40.66225254426217
                            ],
                            [
                                -73.9309083966569,
                                40.65534774328999
                            ],
                            [
                                -73.93024715960537,
                                40.65842777721989
                            ],
                            [
                                -73.92740661455908,
                                40.664981232948215
                            ],
                            [
                                -73.92562231835014,
                                40.66393909858385
                            ],
                            [
                                -73.92599640419176,
                                40.662832181805065
                            ],
                            [
                                -73.92323257728154,
                                40.662543348664634
                            ],
                            [
                                -73.91451057730836,
                                40.65744918556972
                            ],
                            [
                                -73.91573390045501,
                                40.65928796262132
                            ],
                            [
                                -73.91355894072392,
                                40.661860822538976
                            ],
                            [
                                -73.91720211510255,
                                40.66149483609391
                            ],
                            [
                                -73.91897972549125,
                                40.664166762312576
                            ],
                            [
                                -73.91503265503616,
                                40.66893301666463
                            ],
                            [
                                -73.91243133974223,
                                40.66933484197136
                            ],
                            [
                                -73.90972442792206,
                                40.66247064421728
                            ],
                            [
                                -73.91325778052492,
                                40.661906423368045
                            ],
                            [
                                -73.90942414281703,
                                40.661709180054245
                            ],
                            [
                                -73.90662472556987,
                                40.65461040667068
                            ],
                            [
                                -73.90486859824117,
                                40.65353052406718
                            ],
                            [
                                -73.89916720923351,
                                40.656711714527525
                            ],
                            [
                                -73.89915612224196,
                                40.656658846043136
                            ],
                            [
                                -73.90587370883952,
                                40.650858777508304
                            ],
                            [
                                -73.90550549685963,
                                40.64902913427932
                            ],
                            [
                                -73.90658676725754,
                                40.64774063521611
                            ],
                            [
                                -73.90659203048679,
                                40.644948338452394
                            ],
                            [
                                -73.904590983198,
                                40.644484921107995
                            ],
                            [
                                -73.90444442364547,
                                40.643756667526944
                            ],
                            [
                                -73.90289101613345,
                                40.64409123015075
                            ],
                            [
                                -73.90054592665354,
                                40.64354813696887
                            ],
                            [
                                -73.89777204295314,
                                40.64519372069822
                            ],
                            [
                                -73.89318105286408,
                                40.646182497743624
                            ],
                            [
                                -73.89221978865832,
                                40.65772228178339
                            ],
                            [
                                -73.88960705541315,
                                40.66412627155858
                            ],
                            [
                                -73.88481592072544,
                                40.66551875103839
                            ],
                            [
                                -73.88483952261288,
                                40.665376533948034
                            ],
                            [
                                -73.88333311345842,
                                40.66469447265994
                            ],
                            [
                                -73.883577088616,
                                40.6653535400149
                            ],
                            [
                                -73.88309837516702,
                                40.66601793277751
                            ],
                            [
                                -73.87486758555013,
                                40.66841010214682
                            ],
                            [
                                -73.8725366504957,
                                40.66619749494057
                            ],
                            [
                                -73.87282481799585,
                                40.666043907699766
                            ],
                            [
                                -73.87210877377483,
                                40.66579133900148
                            ],
                            [
                                -73.86882250899475,
                                40.66267189853479
                            ],
                            [
                                -73.86823926522011,
                                40.663761728320786
                            ],
                            [
                                -73.87092417432258,
                                40.665933942713224
                            ],
                            [
                                -73.87101265074718,
                                40.666386578332904
                            ],
                            [
                                -73.86855141450998,
                                40.66571575662285
                            ],
                            [
                                -73.86172665458173,
                                40.66944474489848
                            ],
                            [
                                -73.86827584911006,
                                40.66851855631954
                            ],
                            [
                                -73.86762097558233,
                                40.67051623281025
                            ],
                            [
                                -73.86004951926869,
                                40.67271677573719
                            ],
                            [
                                -73.85686682279355,
                                40.67048703217585
                            ],
                            [
                                -73.8599249681019,
                                40.66853244731406
                            ],
                            [
                                -73.86146532568243,
                                40.66793126170816
                            ]
                        ],
                        [
                            [
                                -73.93119323895877,
                                40.651626386574215
                            ],
                            [
                                -73.93097720835092,
                                40.65164739335891
                            ],
                            [
                                -73.93099022689108,
                                40.651634846504734
                            ],
                            [
                                -73.93006087481389,
                                40.651706696236346
                            ],
                            [
                                -73.93009743359448,
                                40.65173294252749
                            ],
                            [
                                -73.92981350932736,
                                40.651760551281676
                            ],
                            [
                                -73.93057372031465,
                                40.6522515945639
                            ],
                            [
                                -73.93119323895877,
                                40.651626386574215
                            ]
                        ],
                        [
                            [
                                -73.93085308918148,
                                40.655000994518716
                            ],
                            [
                                -73.93091015286328,
                                40.65531008222759
                            ],
                            [
                                -73.93101611633757,
                                40.65526317249664
                            ],
                            [
                                -73.93083966924945,
                                40.65459734421496
                            ],
                            [
                                -73.93085308918148,
                                40.655000994518716
                            ]
                        ],
                        [
                            [
                                -73.91355698598986,
                                40.65658782865158
                            ],
                            [
                                -73.91356956869204,
                                40.65659824791026
                            ],
                            [
                                -73.91362393383473,
                                40.65654104589427
                            ],
                            [
                                -73.91355698598986,
                                40.65658782865158
                            ]
                        ],
                        [
                            [
                                -73.91561779401518,
                                40.66166119358701
                            ],
                            [
                                -73.91585908778869,
                                40.662787987386565
                            ],
                            [
                                -73.91596878286467,
                                40.66305353300997
                            ],
                            [
                                -73.91561779401518,
                                40.66166119358701
                            ]
                        ],
                        [
                            [
                                -73.91722091219985,
                                40.66451001348251
                            ],
                            [
                                -73.91599028314268,
                                40.66314993292445
                            ],
                            [
                                -73.9167463825409,
                                40.66609253308573
                            ],
                            [
                                -73.91722091219985,
                                40.66451001348251
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "13d8d3fb-92f4-43c7-a217-c6e2b864e09f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86132072983236,
                                40.66764035930843
                            ],
                            [
                                -73.86146532568243,
                                40.66793126170816
                            ],
                            [
                                -73.8599249681019,
                                40.66853244731406
                            ],
                            [
                                -73.86132072983236,
                                40.66764035930843
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d59063fa-307a-4c3a-aab3-76074bb75b1d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.85961438836199,
                                40.6642074888062
                            ],
                            [
                                -73.86002074128078,
                                40.661998936689336
                            ],
                            [
                                -73.86500807248399,
                                40.66528362887202
                            ],
                            [
                                -73.86225441985948,
                                40.66704360009373
                            ],
                            [
                                -73.86223976418718,
                                40.6670406147306
                            ],
                            [
                                -73.86224240263397,
                                40.667051280790595
                            ],
                            [
                                -73.86132072983236,
                                40.66764035930843
                            ],
                            [
                                -73.85961438836199,
                                40.6642074888062
                            ]
                        ],
                        [
                            [
                                -73.86178688841815,
                                40.66629166567067
                            ],
                            [
                                -73.86208988387529,
                                40.6664317855067
                            ],
                            [
                                -73.86270881681783,
                                40.66616199328629
                            ],
                            [
                                -73.86178688841815,
                                40.66629166567067
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f3878ebe-f7cc-40b1-aa7f-75379882c441"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.865171968417,
                                40.66517887632521
                            ],
                            [
                                -73.86569502612971,
                                40.665736061481375
                            ],
                            [
                                -73.86500807248399,
                                40.66528362887202
                            ],
                            [
                                -73.865171968417,
                                40.66517887632521
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e3020379-f031-4354-bb01-951a23c89f25"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.865171968417,
                                40.66517887632521
                            ],
                            [
                                -73.85994234800087,
                                40.65960804389621
                            ],
                            [
                                -73.86517574239173,
                                40.66022603371
                            ],
                            [
                                -73.86714792623594,
                                40.66391596143692
                            ],
                            [
                                -73.865171968417,
                                40.66517887632521
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b2d85691-644b-4771-aa6f-f4cbf892d230"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86432531440981,
                                40.65681738267477
                            ],
                            [
                                -73.86718651516561,
                                40.660463477517844
                            ],
                            [
                                -73.86517574239173,
                                40.66022603371
                            ],
                            [
                                -73.86274037692613,
                                40.65566949980316
                            ],
                            [
                                -73.86302627192364,
                                40.65575747442447
                            ],
                            [
                                -73.86432531440981,
                                40.65681738267477
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "fe0ccb8c-8ae3-4211-b779-3f16bcd50890"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86432531440981,
                                40.65681738267477
                            ],
                            [
                                -73.86364233617962,
                                40.65594704758976
                            ],
                            [
                                -73.86302627192364,
                                40.65575747442447
                            ],
                            [
                                -73.86260266918892,
                                40.6554118505991
                            ],
                            [
                                -73.86274037692613,
                                40.65566949980316
                            ],
                            [
                                -73.86072086934858,
                                40.65504806390571
                            ],
                            [
                                -73.86044664064181,
                                40.6549217512869
                            ],
                            [
                                -73.86011642443495,
                                40.65461133692842
                            ],
                            [
                                -73.85990791288108,
                                40.65429721507059
                            ],
                            [
                                -73.85991881124211,
                                40.65361488851237
                            ],
                            [
                                -73.86013358585012,
                                40.653120644609544
                            ],
                            [
                                -73.87716637189416,
                                40.66222135146119
                            ],
                            [
                                -73.87312102398604,
                                40.66399393405499
                            ],
                            [
                                -73.86432531440981,
                                40.65681738267477
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "66557dc1-5eee-4acd-b881-d6182832ed91"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86302627192364,
                                40.65575747442447
                            ],
                            [
                                -73.86364233617962,
                                40.65594704758976
                            ],
                            [
                                -73.86432531440981,
                                40.65681738267477
                            ],
                            [
                                -73.86302627192364,
                                40.65575747442447
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f694441b-a997-4cc5-a70d-6828d130f2e6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86302627192364,
                                40.65575747442447
                            ],
                            [
                                -73.86274037692613,
                                40.65566949980316
                            ],
                            [
                                -73.86260266918892,
                                40.6554118505991
                            ],
                            [
                                -73.86302627192364,
                                40.65575747442447
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b5442f10-a515-4794-8f70-4bb7035b3df1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87721649073531,
                                40.66219939048591
                            ],
                            [
                                -73.87720302343432,
                                40.66224093457119
                            ],
                            [
                                -73.87716637189416,
                                40.66222135146119
                            ],
                            [
                                -73.87721649073531,
                                40.66219939048591
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f0abee44-1101-4482-ac46-04d7bfa0705c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87721649073531,
                                40.66219939048591
                            ],
                            [
                                -73.87864105063187,
                                40.657804891699044
                            ],
                            [
                                -73.87914015500456,
                                40.65762059286922
                            ],
                            [
                                -73.87875735411593,
                                40.65773162305956
                            ],
                            [
                                -73.87864962474814,
                                40.65777844216639
                            ],
                            [
                                -73.88053265598437,
                                40.6519696457071
                            ],
                            [
                                -73.88400369855428,
                                40.65235687950855
                            ],
                            [
                                -73.88133482188869,
                                40.65671715846207
                            ],
                            [
                                -73.8856124133204,
                                40.658520481632756
                            ],
                            [
                                -73.87721649073531,
                                40.66219939048591
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c5137598-4077-487e-9856-0684634b268f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87864105063187,
                                40.657804891699044
                            ],
                            [
                                -73.87864962474814,
                                40.65777844216639
                            ],
                            [
                                -73.87875735411593,
                                40.65773162305956
                            ],
                            [
                                -73.87914015500456,
                                40.65762059286922
                            ],
                            [
                                -73.87864105063187,
                                40.657804891699044
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "61ef2d9e-99f3-45dc-ae5e-9503aa8626e8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87864962474814,
                                40.65777844216639
                            ],
                            [
                                -73.87864105063187,
                                40.657804891699044
                            ],
                            [
                                -73.87829328264353,
                                40.657933308192284
                            ],
                            [
                                -73.87864962474814,
                                40.65777844216639
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "87febee3-eee8-43c3-99c0-1165e71b8988"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88480984849751,
                                40.651039831524976
                            ],
                            [
                                -73.88627331375639,
                                40.65261008058715
                            ],
                            [
                                -73.88400369855428,
                                40.65235687950855
                            ],
                            [
                                -73.88480984849751,
                                40.651039831524976
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "aa44929d-c3b5-4e48-b623-f2cac8a02202"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88303966709277,
                                40.649140486341466
                            ],
                            [
                                -73.89233611008424,
                                40.648712097846435
                            ],
                            [
                                -73.88500967879905,
                                40.65071335864247
                            ],
                            [
                                -73.88480984849751,
                                40.651039831524976
                            ],
                            [
                                -73.88303966709277,
                                40.649140486341466
                            ]
                        ],
                        [
                            [
                                -73.8848216272741,
                                40.650484592804965
                            ],
                            [
                                -73.88473692512596,
                                40.65038103738909
                            ],
                            [
                                -73.88456173298431,
                                40.650183005721345
                            ],
                            [
                                -73.8848216272741,
                                40.650484592804965
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d33433e8-3655-402c-94a4-c919987837a4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89233611008424,
                                40.648712097846435
                            ],
                            [
                                -73.89077179372995,
                                40.64731166793228
                            ],
                            [
                                -73.89067824317625,
                                40.64812724744618
                            ],
                            [
                                -73.88951065968236,
                                40.64708795119709
                            ],
                            [
                                -73.88561795682402,
                                40.64269586257312
                            ],
                            [
                                -73.89336848096933,
                                40.63949470289426
                            ],
                            [
                                -73.89356991019437,
                                40.63978799430397
                            ],
                            [
                                -73.88691801307624,
                                40.64384524650093
                            ],
                            [
                                -73.89233611008424,
                                40.648712097846435
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "254da58f-b4c6-4512-b96b-4a692c1deab1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90007340656283,
                                40.63787663239878
                            ],
                            [
                                -73.89040914205313,
                                40.63444359717579
                            ],
                            [
                                -73.89093420643837,
                                40.6358920848637
                            ],
                            [
                                -73.89519091024309,
                                40.63874199417427
                            ],
                            [
                                -73.89336848096933,
                                40.63949470289426
                            ],
                            [
                                -73.8892934551315,
                                40.63356125370213
                            ],
                            [
                                -73.88987299825861,
                                40.63379325364021
                            ],
                            [
                                -73.8898566323393,
                                40.634234207875906
                            ],
                            [
                                -73.89019494236688,
                                40.63424994800553
                            ],
                            [
                                -73.88988661985343,
                                40.63379870657202
                            ],
                            [
                                -73.90007340656283,
                                40.63787663239878
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "7822d533-2685-445c-a539-c113f36f5a94"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90007340656283,
                                40.63787663239878
                            ],
                            [
                                -73.90330370786339,
                                40.63902413184217
                            ],
                            [
                                -73.90586994555963,
                                40.63768861586329
                            ],
                            [
                                -73.90816891067864,
                                40.63881299479411
                            ],
                            [
                                -73.90597200698949,
                                40.64023793196338
                            ],
                            [
                                -73.90007340656283,
                                40.63787663239878
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6e060fb9-54ca-4323-b032-7ce9e2208bf2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91039979928745,
                                40.635331198994095
                            ],
                            [
                                -73.90586994555963,
                                40.63768861586329
                            ],
                            [
                                -73.90566464212681,
                                40.637588205964775
                            ],
                            [
                                -73.90632691069699,
                                40.637162994277894
                            ],
                            [
                                -73.90572514635615,
                                40.63745600600566
                            ],
                            [
                                -73.90626291022457,
                                40.6371057940067
                            ],
                            [
                                -73.90571968190427,
                                40.63669518719393
                            ],
                            [
                                -73.90576563791757,
                                40.6366615505325
                            ],
                            [
                                -73.90581004972691,
                                40.63663042247755
                            ],
                            [
                                -73.91039979928745,
                                40.635331198994095
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f8a73f71-74f1-46ef-89fd-3c53ac486c67"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91039979928745,
                                40.635331198994095
                            ],
                            [
                                -73.91551582355233,
                                40.63266872849727
                            ],
                            [
                                -73.91541702101516,
                                40.633910970677846
                            ],
                            [
                                -73.91039979928745,
                                40.635331198994095
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4adceacb-4ea4-48f5-9468-bb457a00df6a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9153990419336,
                                40.63413702128471
                            ],
                            [
                                -73.91541702101516,
                                40.633910970677846
                            ],
                            [
                                -73.91600660373476,
                                40.633744077102364
                            ],
                            [
                                -73.9153990419336,
                                40.63413702128471
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "bb1fa4e3-8c97-4fc2-8291-2f2ab7b95f90"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9153990419336,
                                40.63413702128471
                            ],
                            [
                                -73.91498559115924,
                                40.63933532900287
                            ],
                            [
                                -73.91036752085147,
                                40.63739118725401
                            ],
                            [
                                -73.9153990419336,
                                40.63413702128471
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "12bac60a-6dd4-485a-8891-4a1fdbcc23a2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9103672796946,
                                40.63739134322364
                            ],
                            [
                                -73.91036708907721,
                                40.6373910054832
                            ],
                            [
                                -73.91036752085147,
                                40.63739118725401
                            ],
                            [
                                -73.9103672796946,
                                40.63739134322364
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9d471fc1-4498-40a3-9400-28824ae800ee"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9103672796946,
                                40.63739134322364
                            ],
                            [
                                -73.91342333653552,
                                40.642806137846556
                            ],
                            [
                                -73.90962408981076,
                                40.637872005669294
                            ],
                            [
                                -73.9103672796946,
                                40.63739134322364
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "81fcae63-f467-4ab1-82d9-f568a49b67d3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91404647460747,
                                40.643910228790205
                            ],
                            [
                                -73.91342333653552,
                                40.642806137846556
                            ],
                            [
                                -73.91414626284994,
                                40.643745011851905
                            ],
                            [
                                -73.91404647460747,
                                40.643910228790205
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c3c986ec-5475-45f2-9f8e-1c634b0a1310"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91813700694009,
                                40.65115793183778
                            ],
                            [
                                -73.9200798625753,
                                40.64602051819375
                            ],
                            [
                                -73.92341985270058,
                                40.65046107252237
                            ],
                            [
                                -73.92349901404005,
                                40.64242249326088
                            ],
                            [
                                -73.92809813137657,
                                40.63985915604595
                            ],
                            [
                                -73.92682937063222,
                                40.63812224390787
                            ],
                            [
                                -73.92885692931392,
                                40.637456958316385
                            ],
                            [
                                -73.92752058202254,
                                40.637026372054436
                            ],
                            [
                                -73.92876883063569,
                                40.63664305693982
                            ],
                            [
                                -73.92758765473322,
                                40.635960266966684
                            ],
                            [
                                -73.92868863174208,
                                40.63589895570427
                            ],
                            [
                                -73.92770257980077,
                                40.635216496928784
                            ],
                            [
                                -73.92848510673636,
                                40.63398216146343
                            ],
                            [
                                -73.92896802755835,
                                40.63384914039062
                            ],
                            [
                                -73.93753769575575,
                                40.64787312151643
                            ],
                            [
                                -73.93304216854453,
                                40.64816644405817
                            ],
                            [
                                -73.93321805777884,
                                40.649852948000806
                            ],
                            [
                                -73.9399992422293,
                                40.65103811446597
                            ],
                            [
                                -73.93976235918383,
                                40.65689914904733
                            ],
                            [
                                -73.94630114639945,
                                40.65718847437991
                            ],
                            [
                                -73.94643879600157,
                                40.65752136781694
                            ],
                            [
                                -73.9345034580172,
                                40.66180194802627
                            ],
                            [
                                -73.9300710958452,
                                40.64740576572159
                            ],
                            [
                                -73.92575460646336,
                                40.66017773224812
                            ],
                            [
                                -73.90944908990858,
                                40.65152200554188
                            ],
                            [
                                -73.91404647460747,
                                40.643910228790205
                            ],
                            [
                                -73.91813700694009,
                                40.65115793183778
                            ]
                        ],
                        [
                            [
                                -73.92913108974874,
                                40.64174299473056
                            ],
                            [
                                -73.92935356381706,
                                40.642070749779336
                            ],
                            [
                                -73.92943291020947,
                                40.64171900580748
                            ],
                            [
                                -73.92913108974874,
                                40.64174299473056
                            ]
                        ],
                        [
                            [
                                -73.92975770598193,
                                40.645897036877216
                            ],
                            [
                                -73.9297852348538,
                                40.64615230314078
                            ],
                            [
                                -73.92989291051701,
                                40.64602300533475
                            ],
                            [
                                -73.92975770598193,
                                40.645897036877216
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1e5d0bca-745d-405d-9296-e8a796604bc7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94630114639945,
                                40.65718847437991
                            ],
                            [
                                -73.94724993206131,
                                40.65723045582358
                            ],
                            [
                                -73.94643879600157,
                                40.65752136781694
                            ],
                            [
                                -73.94630114639945,
                                40.65718847437991
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "2e05494d-1c7f-42e0-9e49-c37042c481cd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94724993206131,
                                40.65723045582358
                            ],
                            [
                                -73.94630114639945,
                                40.65718847437991
                            ],
                            [
                                -73.94527986329767,
                                40.65471859116405
                            ],
                            [
                                -73.94724993206131,
                                40.65723045582358
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b8ad6b54-172f-4443-9407-6d67b7a7239c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94627030311064,
                                40.64701068601902
                            ],
                            [
                                -73.94724993206131,
                                40.65723045582358
                            ],
                            [
                                -73.94527986329767,
                                40.65471859116405
                            ],
                            [
                                -73.94379032945933,
                                40.651116284790504
                            ],
                            [
                                -73.94627030311064,
                                40.64701068601902
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f6fbf962-01db-4b3e-adf4-7a25f76b7666"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94627030311064,
                                40.64701068601902
                            ],
                            [
                                -73.9458644587156,
                                40.6427768010456
                            ],
                            [
                                -73.94710084819063,
                                40.63973165537371
                            ],
                            [
                                -73.94736413442682,
                                40.63965594907824
                            ],
                            [
                                -73.94702659561032,
                                40.64001502859139
                            ],
                            [
                                -73.94892149241322,
                                40.63993633166934
                            ],
                            [
                                -73.9502700697098,
                                40.64457209487142
                            ],
                            [
                                -73.94872827425304,
                                40.642941512422254
                            ],
                            [
                                -73.94627030311064,
                                40.64701068601902
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "bc4ee09c-5db5-4b89-8ce0-1c7e025e7ad3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9458644587156,
                                40.6427768010456
                            ],
                            [
                                -73.94627030311064,
                                40.64701068601902
                            ],
                            [
                                -73.94379032945933,
                                40.651116284790504
                            ],
                            [
                                -73.94527986329767,
                                40.65471859116405
                            ],
                            [
                                -73.94247030814992,
                                40.651136369865554
                            ],
                            [
                                -73.9458644587156,
                                40.6427768010456
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6694001e-acd4-462f-bd97-23c24ea73a66"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9458644587156,
                                40.6427768010456
                            ],
                            [
                                -73.9456135564482,
                                40.64015931672711
                            ],
                            [
                                -73.94710084819063,
                                40.63973165537371
                            ],
                            [
                                -73.9458644587156,
                                40.6427768010456
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "400caeb0-460b-4136-ac46-46bfef58cab4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94736413442682,
                                40.63965594907824
                            ],
                            [
                                -73.94710084819063,
                                40.63973165537371
                            ],
                            [
                                -73.94812684139053,
                                40.637204701840744
                            ],
                            [
                                -73.94863613060839,
                                40.63895539438272
                            ],
                            [
                                -73.94827380756765,
                                40.63868822337511
                            ],
                            [
                                -73.94736413442682,
                                40.63965594907824
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d8a39a72-6788-4e63-9dab-f205f69e6720"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94736413442682,
                                40.63965594907824
                            ],
                            [
                                -73.94872600829414,
                                40.63926435084325
                            ],
                            [
                                -73.94892149241322,
                                40.63993633166934
                            ],
                            [
                                -73.94702659561032,
                                40.64001502859139
                            ],
                            [
                                -73.94736413442682,
                                40.63965594907824
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "05671ae9-9c2b-41ee-856f-b27e47b4a588"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94872600829414,
                                40.63926435084325
                            ],
                            [
                                -73.94736413442682,
                                40.63965594907824
                            ],
                            [
                                -73.94827380756765,
                                40.63868822337511
                            ],
                            [
                                -73.94863613060839,
                                40.63895539438272
                            ],
                            [
                                -73.94872600829414,
                                40.63926435084325
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "2711b4e4-8a11-4fdc-bfbe-49ba7faabd01"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94872600829414,
                                40.63926435084325
                            ],
                            [
                                -73.94896278819687,
                                40.63919626627668
                            ],
                            [
                                -73.94991071049185,
                                40.63989524847267
                            ],
                            [
                                -73.94892149241322,
                                40.63993633166934
                            ],
                            [
                                -73.94872600829414,
                                40.63926435084325
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a83d3aaa-fea0-4bbc-a47f-74ea1cb6d2d0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94896278819687,
                                40.63919626627668
                            ],
                            [
                                -73.94872600829414,
                                40.63926435084325
                            ],
                            [
                                -73.94863613060839,
                                40.63895539438272
                            ],
                            [
                                -73.94896278819687,
                                40.63919626627668
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "79573736-83aa-4671-9061-ab37dca769c4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94896278819687,
                                40.63919626627668
                            ],
                            [
                                -73.95013887987908,
                                40.63885808853644
                            ],
                            [
                                -73.9508629623568,
                                40.64519913028016
                            ],
                            [
                                -73.9502700697098,
                                40.64457209487142
                            ],
                            [
                                -73.94892149241322,
                                40.63993633166934
                            ],
                            [
                                -73.94991071049185,
                                40.63989524847267
                            ],
                            [
                                -73.94896278819687,
                                40.63919626627668
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5d0ae128-01f5-4d75-ba7c-99ed17a7d8fe"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9508629623568,
                                40.64519913028016
                            ],
                            [
                                -73.95112820518659,
                                40.64752195386415
                            ],
                            [
                                -73.9502700697098,
                                40.64457209487142
                            ],
                            [
                                -73.9508629623568,
                                40.64519913028016
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "846716d8-2d34-4d3b-b4be-7f46e05905bd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95112820518659,
                                40.64752195386415
                            ],
                            [
                                -73.9508629623568,
                                40.64519913028016
                            ],
                            [
                                -73.95367946618998,
                                40.648177827420746
                            ],
                            [
                                -73.9512713590083,
                                40.648014048177636
                            ],
                            [
                                -73.95112820518659,
                                40.64752195386415
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4bd5df81-5d7c-4135-9abb-3c346e29cc80"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95452564756268,
                                40.64907273763019
                            ],
                            [
                                -73.95118545940588,
                                40.64802334894785
                            ],
                            [
                                -73.95112820518659,
                                40.64752195386415
                            ],
                            [
                                -73.9512713590083,
                                40.648014048177636
                            ],
                            [
                                -73.95367946618998,
                                40.648177827420746
                            ],
                            [
                                -73.95452564756268,
                                40.64907273763019
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d4bfc208-73a0-4961-9a5e-cf396658bd27"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95452564756268,
                                40.64907273763019
                            ],
                            [
                                -73.95856682985723,
                                40.65034235801207
                            ],
                            [
                                -73.95878500128343,
                                40.64474346266485
                            ],
                            [
                                -73.96062058531024,
                                40.63444837665347
                            ],
                            [
                                -73.95931812955689,
                                40.65351935738498
                            ],
                            [
                                -73.95308416913312,
                                40.65531595607896
                            ],
                            [
                                -73.95598380869939,
                                40.65061486953047
                            ],
                            [
                                -73.95452564756268,
                                40.64907273763019
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8e690cc3-f260-43f7-8c8d-670f2ef16e2b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95878500128343,
                                40.64474346266485
                            ],
                            [
                                -73.95856682985723,
                                40.65034235801207
                            ],
                            [
                                -73.95452564756268,
                                40.64907273763019
                            ],
                            [
                                -73.95367946618998,
                                40.648177827420746
                            ],
                            [
                                -73.95811883104189,
                                40.648479755765486
                            ],
                            [
                                -73.95878500128343,
                                40.64474346266485
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f55ba68b-8a3e-499f-93ac-7f0b0eae1144"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95878500128343,
                                40.64474346266485
                            ],
                            [
                                -73.95937933357384,
                                40.629491216514694
                            ],
                            [
                                -73.96083645323074,
                                40.630027499172314
                            ],
                            [
                                -73.9595166886757,
                                40.630185790000546
                            ],
                            [
                                -73.96092629362158,
                                40.63006056429729
                            ],
                            [
                                -73.96137356889885,
                                40.63022518082225
                            ],
                            [
                                -73.9610597527347,
                                40.63198525519339
                            ],
                            [
                                -73.96079297191227,
                                40.63192423596219
                            ],
                            [
                                -73.96062058531024,
                                40.63444837665347
                            ],
                            [
                                -73.95878500128343,
                                40.64474346266485
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a957f254-3683-46b4-bb1f-2938d419ff7b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96083645323074,
                                40.630027499172314
                            ],
                            [
                                -73.96092629362158,
                                40.63006056429729
                            ],
                            [
                                -73.9595166886757,
                                40.630185790000546
                            ],
                            [
                                -73.96083645323074,
                                40.630027499172314
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3134c919-5a11-42f2-98bb-b6677870866d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96092629362158,
                                40.63006056429729
                            ],
                            [
                                -73.96083645323074,
                                40.630027499172314
                            ],
                            [
                                -73.96141622485301,
                                40.629957962125026
                            ],
                            [
                                -73.96142126554227,
                                40.629957668662975
                            ],
                            [
                                -73.96141059055014,
                                40.63001754059505
                            ],
                            [
                                -73.96092629362158,
                                40.63006056429729
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "68d85592-a4fe-49db-886d-8650b3904b3a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96137356889885,
                                40.63022518082225
                            ],
                            [
                                -73.96092629362158,
                                40.63006056429729
                            ],
                            [
                                -73.96141059055014,
                                40.63001754059505
                            ],
                            [
                                -73.96137356889885,
                                40.63022518082225
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b8f440d0-022c-4b72-8604-069f7f8b2e78"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96137356889885,
                                40.63022518082225
                            ],
                            [
                                -73.96392326093016,
                                40.63116357707007
                            ],
                            [
                                -73.96498608790212,
                                40.63288330307843
                            ],
                            [
                                -73.9610597527347,
                                40.63198525519339
                            ],
                            [
                                -73.96137356889885,
                                40.63022518082225
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e6afed1c-01d1-4615-9bca-1c792eb3010c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96392326093016,
                                40.63116357707007
                            ],
                            [
                                -73.96137356889885,
                                40.63022518082225
                            ],
                            [
                                -73.96141059055014,
                                40.63001754059505
                            ],
                            [
                                -73.96312107477084,
                                40.62986558554546
                            ],
                            [
                                -73.96392326093016,
                                40.63116357707007
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ace9de48-0888-4ee2-84c8-6f6fe0665a76"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96392326093016,
                                40.63116357707007
                            ],
                            [
                                -73.96880260341206,
                                40.63295938480708
                            ],
                            [
                                -73.96776873929824,
                                40.63396275814108
                            ],
                            [
                                -73.96765185075333,
                                40.63349302756411
                            ],
                            [
                                -73.96498608790212,
                                40.63288330307843
                            ],
                            [
                                -73.96392326093016,
                                40.63116357707007
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8696184f-89b6-49eb-bddf-1e6b995417ca"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96776873929824,
                                40.63396275814108
                            ],
                            [
                                -73.96644636600337,
                                40.63524613192678
                            ],
                            [
                                -73.96498608790212,
                                40.63288330307843
                            ],
                            [
                                -73.96765185075333,
                                40.63349302756411
                            ],
                            [
                                -73.96776873929824,
                                40.63396275814108
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d1006172-5806-45c1-9b17-92e7adc927ff"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96776873929824,
                                40.63396275814108
                            ],
                            [
                                -73.96872007489081,
                                40.63778581383181
                            ],
                            [
                                -73.9684623781763,
                                40.63753935052598
                            ],
                            [
                                -73.96885866746653,
                                40.639149399039525
                            ],
                            [
                                -73.96644636600337,
                                40.63524613192678
                            ],
                            [
                                -73.96776873929824,
                                40.63396275814108
                            ]
                        ],
                        [
                            [
                                -73.96782476836067,
                                40.63590034159751
                            ],
                            [
                                -73.96783651203638,
                                40.63594341402719
                            ],
                            [
                                -73.96803762955714,
                                40.636642436601
                            ],
                            [
                                -73.96807391251147,
                                40.636296014646184
                            ],
                            [
                                -73.96782476836067,
                                40.63590034159751
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "61edb1fb-f4d3-49ce-8416-a3f040aebfb2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96994911167631,
                                40.642724844930505
                            ],
                            [
                                -73.96770925669324,
                                40.643930747176945
                            ],
                            [
                                -73.96538208111821,
                                40.63627902886098
                            ],
                            [
                                -73.96644636600337,
                                40.63524613192678
                            ],
                            [
                                -73.96885866746653,
                                40.639149399039525
                            ],
                            [
                                -73.96948398184328,
                                40.64168993320884
                            ],
                            [
                                -73.96954752312695,
                                40.64111101338565
                            ],
                            [
                                -73.96994911167631,
                                40.642724844930505
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "db710761-dec4-4000-911f-60b8a959712d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96994911167631,
                                40.642724844930505
                            ],
                            [
                                -73.97078893259602,
                                40.64227269872402
                            ],
                            [
                                -73.97155642844142,
                                40.643514558971695
                            ],
                            [
                                -73.97159555348036,
                                40.641838426887766
                            ],
                            [
                                -73.97343302703625,
                                40.640849160392584
                            ],
                            [
                                -73.97258130264021,
                                40.609386097608535
                            ],
                            [
                                -73.9735325456134,
                                40.6095071345224
                            ],
                            [
                                -73.97632506387468,
                                40.618503047821925
                            ],
                            [
                                -73.97376879572988,
                                40.61285598113665
                            ],
                            [
                                -73.97455543784777,
                                40.647655050815594
                            ],
                            [
                                -73.9656310267413,
                                40.651854535115184
                            ],
                            [
                                -73.97018142754166,
                                40.64365843397939
                            ],
                            [
                                -73.96994911167631,
                                40.642724844930505
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "0cc894f2-3801-42ff-8037-9e53e4fcad77"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97078893259602,
                                40.64227269872402
                            ],
                            [
                                -73.96994911167631,
                                40.642724844930505
                            ],
                            [
                                -73.96954752312695,
                                40.64111101338565
                            ],
                            [
                                -73.96962646803102,
                                40.64039175234333
                            ],
                            [
                                -73.97078893259602,
                                40.64227269872402
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "eae844ce-0d93-4244-a4f8-1f761382c03d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97078893259602,
                                40.64227269872402
                            ],
                            [
                                -73.97159555348036,
                                40.641838426887766
                            ],
                            [
                                -73.97155642844142,
                                40.643514558971695
                            ],
                            [
                                -73.97078893259602,
                                40.64227269872402
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ae02ca7c-e2c3-42df-b1b0-4ce6b3ea49ea"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97258130264021,
                                40.609386097608535
                            ],
                            [
                                -73.97343302703625,
                                40.640849160392584
                            ],
                            [
                                -73.97159555348036,
                                40.641838426887766
                            ],
                            [
                                -73.97235374640691,
                                40.60935714317209
                            ],
                            [
                                -73.97258130264021,
                                40.609386097608535
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "402d0bc9-7e18-449a-adee-b24a329cce9d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97258130264021,
                                40.609386097608535
                            ],
                            [
                                -73.97254080064705,
                                40.607889936691464
                            ],
                            [
                                -73.97277493832708,
                                40.607652110568196
                            ],
                            [
                                -73.97306158370448,
                                40.60798996181181
                            ],
                            [
                                -73.9735325456134,
                                40.6095071345224
                            ],
                            [
                                -73.97258130264021,
                                40.609386097608535
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "07ad9105-a830-4a24-bedc-82f8dbf5beb1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97254080064705,
                                40.607889936691464
                            ],
                            [
                                -73.97258130264021,
                                40.609386097608535
                            ],
                            [
                                -73.97235374640691,
                                40.60935714317209
                            ],
                            [
                                -73.97238428356147,
                                40.60804891943908
                            ],
                            [
                                -73.97254080064705,
                                40.607889936691464
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "56b07327-9820-4bb5-af34-0787a1bf7634"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97254080064705,
                                40.607889936691464
                            ],
                            [
                                -73.97249401267986,
                                40.60616156921019
                            ],
                            [
                                -73.97306158370448,
                                40.60798996181181
                            ],
                            [
                                -73.97277493832708,
                                40.607652110568196
                            ],
                            [
                                -73.97254080064705,
                                40.607889936691464
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "040b1dd3-c9f9-4366-a51d-b4d7b0797663"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97306158370448,
                                40.60798996181181
                            ],
                            [
                                -73.97249401267986,
                                40.60616156921019
                            ],
                            [
                                -73.97254080064705,
                                40.607889936691464
                            ],
                            [
                                -73.97238428356147,
                                40.60804891943908
                            ],
                            [
                                -73.97243557816894,
                                40.60585143824363
                            ],
                            [
                                -73.97321330349935,
                                40.608168784585224
                            ],
                            [
                                -73.97306158370448,
                                40.60798996181181
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ee565eaf-0781-425d-9013-729577db60be"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9735325456134,
                                40.6095071345224
                            ],
                            [
                                -73.97306158370448,
                                40.60798996181181
                            ],
                            [
                                -73.97321330349935,
                                40.608168784585224
                            ],
                            [
                                -73.97366826312931,
                                40.60952440332727
                            ],
                            [
                                -73.9735325456134,
                                40.6095071345224
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "aa8d47cd-9891-4fee-9db7-7c4b21196ef7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97632506387468,
                                40.618503047821925
                            ],
                            [
                                -73.9735325456134,
                                40.6095071345224
                            ],
                            [
                                -73.97366826312931,
                                40.60952440332727
                            ],
                            [
                                -73.97770373021287,
                                40.62154866757206
                            ],
                            [
                                -73.97632506387468,
                                40.618503047821925
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ef3066a5-4eed-4b7a-8bb9-7c48afe1681f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98534661872746,
                                40.63843261662014
                            ],
                            [
                                -73.9814320889584,
                                40.634954992114764
                            ],
                            [
                                -73.97632506387468,
                                40.618503047821925
                            ],
                            [
                                -73.97770373021287,
                                40.62154866757206
                            ],
                            [
                                -73.9820150892896,
                                40.634394992351694
                            ],
                            [
                                -73.98424311466982,
                                40.63599485944915
                            ],
                            [
                                -73.98534661872746,
                                40.63843261662014
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "2d43b208-a539-4a9d-aec4-57b1f74d8d6d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98534661872746,
                                40.63843261662014
                            ],
                            [
                                -73.9897906394808,
                                40.64238063499934
                            ],
                            [
                                -73.98950351287166,
                                40.64347873569745
                            ],
                            [
                                -73.98988325465966,
                                40.643769069530954
                            ],
                            [
                                -73.98913275794186,
                                40.644475420186794
                            ],
                            [
                                -73.98885577455121,
                                40.64438249930921
                            ],
                            [
                                -73.98909080445647,
                                40.64451490586504
                            ],
                            [
                                -73.98481003567946,
                                40.64854386929874
                            ],
                            [
                                -73.98130173173259,
                                40.65024256386174
                            ],
                            [
                                -73.981993225363,
                                40.651194987907544
                            ],
                            [
                                -73.9815541448527,
                                40.65160824062279
                            ],
                            [
                                -73.98255842039788,
                                40.65197345544236
                            ],
                            [
                                -73.9855224029171,
                                40.65605587659351
                            ],
                            [
                                -73.98409593036524,
                                40.65652603766183
                            ],
                            [
                                -73.98390647684896,
                                40.65420069945425
                            ],
                            [
                                -73.97902078795364,
                                40.65249741233733
                            ],
                            [
                                -73.98741097365557,
                                40.64299299498451
                            ],
                            [
                                -73.98534661872746,
                                40.63843261662014
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5296bac9-ea1f-4419-bcd5-7d69ce8fe457"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9897906394808,
                                40.64238063499934
                            ],
                            [
                                -73.98534661872746,
                                40.63843261662014
                            ],
                            [
                                -73.98424311466982,
                                40.63599485944915
                            ],
                            [
                                -73.99022682645696,
                                40.6402915535954
                            ],
                            [
                                -73.99027379354554,
                                40.64053283755073
                            ],
                            [
                                -73.9897906394808,
                                40.64238063499934
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4b433ba0-9046-4c5a-8ded-61557dde8603"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9897906394808,
                                40.64238063499934
                            ],
                            [
                                -73.99059717040758,
                                40.64309714806242
                            ],
                            [
                                -73.98988325465966,
                                40.643769069530954
                            ],
                            [
                                -73.98950351287166,
                                40.64347873569745
                            ],
                            [
                                -73.9897906394808,
                                40.64238063499934
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a62afb43-5f33-492f-b1bb-74a36b2437c5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98988325465966,
                                40.643769069530954
                            ],
                            [
                                -73.99059717040758,
                                40.64309714806242
                            ],
                            [
                                -73.9897906394808,
                                40.64238063499934
                            ],
                            [
                                -73.99027379354554,
                                40.64053283755073
                            ],
                            [
                                -73.99108216968692,
                                40.64468570711712
                            ],
                            [
                                -73.98988325465966,
                                40.643769069530954
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "56778c0e-860f-4b69-8859-b313d2b55d4d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98913275794186,
                                40.644475420186794
                            ],
                            [
                                -73.98988325465966,
                                40.643769069530954
                            ],
                            [
                                -73.99108216968692,
                                40.64468570711712
                            ],
                            [
                                -73.9911745702192,
                                40.64516039623616
                            ],
                            [
                                -73.98913275794186,
                                40.644475420186794
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8f760905-22e0-44d9-9f21-ffe1dbd2495d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98913275794186,
                                40.644475420186794
                            ],
                            [
                                -73.98909080445647,
                                40.64451490586504
                            ],
                            [
                                -73.98885577455121,
                                40.64438249930921
                            ],
                            [
                                -73.98913275794186,
                                40.644475420186794
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "fb72b7ca-c6eb-4dd9-8714-26789d8221f4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98909080445647,
                                40.64451490586504
                            ],
                            [
                                -73.98913275794186,
                                40.644475420186794
                            ],
                            [
                                -73.9911745702192,
                                40.64516039623616
                            ],
                            [
                                -73.99122825935255,
                                40.64543621334228
                            ],
                            [
                                -73.99095824905113,
                                40.64556695031317
                            ],
                            [
                                -73.98909080445647,
                                40.64451490586504
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1319ff47-913f-4076-908e-e6ff1b549546"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98481003567946,
                                40.64854386929874
                            ],
                            [
                                -73.98909080445647,
                                40.64451490586504
                            ],
                            [
                                -73.99095824905113,
                                40.64556695031317
                            ],
                            [
                                -73.98481003567946,
                                40.64854386929874
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e2a310f4-9b0e-403e-8d3a-35cc9e43e63f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98481003567946,
                                40.64854386929874
                            ],
                            [
                                -73.981993225363,
                                40.651194987907544
                            ],
                            [
                                -73.98130173173259,
                                40.65024256386174
                            ],
                            [
                                -73.98481003567946,
                                40.64854386929874
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f579b62a-d53f-4e56-b8c8-ab7b6728ed1f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.981993225363,
                                40.651194987907544
                            ],
                            [
                                -73.98481003567946,
                                40.64854386929874
                            ],
                            [
                                -73.99095824905113,
                                40.64556695031317
                            ],
                            [
                                -73.99815912918048,
                                40.64962364146397
                            ],
                            [
                                -73.9978226112919,
                                40.65027770876925
                            ],
                            [
                                -74.0020827107284,
                                40.65290080886914
                            ],
                            [
                                -73.99784527541571,
                                40.650364314319674
                            ],
                            [
                                -73.99673530973467,
                                40.64922413217036
                            ],
                            [
                                -73.9965931096204,
                                40.648942904914755
                            ],
                            [
                                -73.99661489715469,
                                40.64931934743627
                            ],
                            [
                                -73.99156721540695,
                                40.655635014695946
                            ],
                            [
                                -73.9860374927053,
                                40.65676533200778
                            ],
                            [
                                -73.9855224029171,
                                40.65605587659351
                            ],
                            [
                                -73.98985635045402,
                                40.65462742050349
                            ],
                            [
                                -73.98255842039788,
                                40.65197345544236
                            ],
                            [
                                -73.981993225363,
                                40.651194987907544
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f78cd0f7-a1f6-414c-986a-42e6c348111d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98255842039788,
                                40.65197345544236
                            ],
                            [
                                -73.9815541448527,
                                40.65160824062279
                            ],
                            [
                                -73.981993225363,
                                40.651194987907544
                            ],
                            [
                                -73.98255842039788,
                                40.65197345544236
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6ad8839b-3b8f-479f-a389-1efbe602488b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98255842039788,
                                40.65197345544236
                            ],
                            [
                                -73.98985635045402,
                                40.65462742050349
                            ],
                            [
                                -73.9855224029171,
                                40.65605587659351
                            ],
                            [
                                -73.98255842039788,
                                40.65197345544236
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "895ae896-ad9e-4443-bad9-8a443afaf5a5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98409593036524,
                                40.65652603766183
                            ],
                            [
                                -73.9855224029171,
                                40.65605587659351
                            ],
                            [
                                -73.9860374927053,
                                40.65676533200778
                            ],
                            [
                                -73.99156721540695,
                                40.655635014695946
                            ],
                            [
                                -73.99050333205778,
                                40.65696614719839
                            ],
                            [
                                -73.9902255346796,
                                40.657268275949825
                            ],
                            [
                                -73.99024552848287,
                                40.6572885411282
                            ],
                            [
                                -73.99049746549588,
                                40.657538763134035
                            ],
                            [
                                -73.99086272498197,
                                40.65750356548835
                            ],
                            [
                                -73.99117002336932,
                                40.65740286614067
                            ],
                            [
                                -73.99145484590825,
                                40.65738925495952
                            ],
                            [
                                -73.992143861816,
                                40.65832046174255
                            ],
                            [
                                -73.99435084819591,
                                40.65798085526754
                            ],
                            [
                                -73.99513998942693,
                                40.65957893206996
                            ],
                            [
                                -73.98833729302036,
                                40.659197931917134
                            ],
                            [
                                -73.98409649316119,
                                40.65653294537701
                            ],
                            [
                                -73.98409593036524,
                                40.65652603766183
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6c397957-bb76-4091-96fe-386e1befa7c5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98409649316119,
                                40.65653294537701
                            ],
                            [
                                -73.98408908907486,
                                40.65652829253059
                            ],
                            [
                                -73.98409593036524,
                                40.65652603766183
                            ],
                            [
                                -73.98409649316119,
                                40.65653294537701
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "22837540-4237-4ea9-9af4-d71fac9cf5dc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98409649316119,
                                40.65653294537701
                            ],
                            [
                                -73.98833729302036,
                                40.659197931917134
                            ],
                            [
                                -73.99513998942693,
                                40.65957893206996
                            ],
                            [
                                -73.99049329102475,
                                40.66059083247911
                            ],
                            [
                                -73.98410558932999,
                                40.6566445910685
                            ],
                            [
                                -73.98409649316119,
                                40.65653294537701
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "411aa321-22ce-4c28-ae5e-480bc06ef0ff"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99050333205778,
                                40.65696614719839
                            ],
                            [
                                -73.990245654247,
                                40.657288554076324
                            ],
                            [
                                -73.99049746549588,
                                40.657538763134035
                            ],
                            [
                                -73.99024552848287,
                                40.6572885411282
                            ],
                            [
                                -73.9902255346796,
                                40.657268275949825
                            ],
                            [
                                -73.99050333205778,
                                40.65696614719839
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e030fb3d-2bbe-4009-9615-4a4174f9e929"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99050333205778,
                                40.65696614719839
                            ],
                            [
                                -73.99156721540695,
                                40.655635014695946
                            ],
                            [
                                -73.99176430684666,
                                40.655594727708994
                            ],
                            [
                                -73.99050333205778,
                                40.65696614719839
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d93d3fe4-0859-4b7a-911d-098e36c968ee"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99156721540695,
                                40.655635014695946
                            ],
                            [
                                -73.99661489715469,
                                40.64931934743627
                            ],
                            [
                                -73.99666934402366,
                                40.65026007420116
                            ],
                            [
                                -73.99176430684666,
                                40.655594727708994
                            ],
                            [
                                -73.99156721540695,
                                40.655635014695946
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "0445b4e1-16aa-44a5-92d0-7b12e500c408"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99661489715469,
                                40.64931934743627
                            ],
                            [
                                -73.99671097487389,
                                40.64919913484446
                            ],
                            [
                                -73.99673530973467,
                                40.64922413217036
                            ],
                            [
                                -73.99708029378704,
                                40.649906402496036
                            ],
                            [
                                -73.99702497828652,
                                40.64987329108425
                            ],
                            [
                                -73.99666934402366,
                                40.65026007420116
                            ],
                            [
                                -73.99661489715469,
                                40.64931934743627
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "42a5d67a-f90d-4d11-b1da-c4dd6d1bc724"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99673530973467,
                                40.64922413217036
                            ],
                            [
                                -73.99671097487389,
                                40.64919913484446
                            ],
                            [
                                -73.99661489715469,
                                40.64931934743627
                            ],
                            [
                                -73.9965931096204,
                                40.648942904914755
                            ],
                            [
                                -73.99673530973467,
                                40.64922413217036
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ac7a74a3-04d1-4923-8815-b8e5ca6c5275"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99673530973467,
                                40.64922413217036
                            ],
                            [
                                -73.99784527541571,
                                40.650364314319674
                            ],
                            [
                                -73.99708029378704,
                                40.649906402496036
                            ],
                            [
                                -73.99673530973467,
                                40.64922413217036
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "45c3e3e0-67cc-4302-af52-48ddcf58f89a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99784527541571,
                                40.650364314319674
                            ],
                            [
                                -74.00107226862883,
                                40.65367915559847
                            ],
                            [
                                -73.99738957391762,
                                40.65051806165527
                            ],
                            [
                                -73.99708029378704,
                                40.649906402496036
                            ],
                            [
                                -73.99784527541571,
                                40.650364314319674
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "cd4af114-cc02-4959-a4c9-0b27a969d9e1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00107226862883,
                                40.65367915559847
                            ],
                            [
                                -73.99784527541571,
                                40.650364314319674
                            ],
                            [
                                -74.0020827107284,
                                40.65290080886914
                            ],
                            [
                                -74.00107226862883,
                                40.65367915559847
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e5aa6543-e8cc-4836-9efc-9d35bbf73ec8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9917890703979,
                                40.64577454228578
                            ],
                            [
                                -73.99815912918048,
                                40.64962364146397
                            ],
                            [
                                -73.99095824905113,
                                40.64556695031317
                            ],
                            [
                                -73.99122825935255,
                                40.64543621334228
                            ],
                            [
                                -73.9911745702192,
                                40.64516039623616
                            ],
                            [
                                -73.9918062601583,
                                40.645372312132494
                            ],
                            [
                                -73.9917890703979,
                                40.64577454228578
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "74da3175-67a7-4473-94f2-78e7681cf6a9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9918158753679,
                                40.645375537790585
                            ],
                            [
                                -73.9917890703979,
                                40.64577454228578
                            ],
                            [
                                -73.9918062601583,
                                40.645372312132494
                            ],
                            [
                                -73.9918158753679,
                                40.645375537790585
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "294e51a1-be8b-41c8-89fe-50730072218f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9918158753679,
                                40.645375537790585
                            ],
                            [
                                -73.99182410996602,
                                40.645252961952565
                            ],
                            [
                                -73.99204055735495,
                                40.64541844808596
                            ],
                            [
                                -73.99230806935836,
                                40.64517704352105
                            ],
                            [
                                -73.99289900989834,
                                40.644618067399065
                            ],
                            [
                                -73.99458701258916,
                                40.646305183795334
                            ],
                            [
                                -73.9918158753679,
                                40.645375537790585
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1f6e2566-1f19-48dc-9e91-f9b41f4f5497"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99182410996602,
                                40.645252961952565
                            ],
                            [
                                -73.9918158753679,
                                40.645375537790585
                            ],
                            [
                                -73.9918062601583,
                                40.645372312132494
                            ],
                            [
                                -73.99181176411356,
                                40.645243522857946
                            ],
                            [
                                -73.99182410996602,
                                40.645252961952565
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c9700857-baf9-4c28-b49a-18ebeb7b5e61"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99781322411721,
                                40.64758385805235
                            ],
                            [
                                -73.99713533362421,
                                40.63709494224194
                            ],
                            [
                                -73.99255460480157,
                                40.63437920521798
                            ],
                            [
                                -73.99182410996602,
                                40.645252961952565
                            ],
                            [
                                -73.99181176411356,
                                40.645243522857946
                            ],
                            [
                                -73.99228307014232,
                                40.63421524254522
                            ],
                            [
                                -73.9973167106666,
                                40.63692072069716
                            ],
                            [
                                -73.99812306736162,
                                40.64777063081786
                            ],
                            [
                                -73.99781322411721,
                                40.64758385805235
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "0345bf5b-ec6d-4621-8cf2-6e97fb536160"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99781322411721,
                                40.64758385805235
                            ],
                            [
                                -73.99788537140982,
                                40.64870018419343
                            ],
                            [
                                -73.99508808926139,
                                40.64670139224434
                            ],
                            [
                                -73.99446978963111,
                                40.64668859269663
                            ],
                            [
                                -73.99518930783434,
                                40.64600216760249
                            ],
                            [
                                -73.99781322411721,
                                40.64758385805235
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a162b00e-fb39-4684-aab6-4cede6c63b5a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99788537140982,
                                40.64870018419343
                            ],
                            [
                                -73.99781322411721,
                                40.64758385805235
                            ],
                            [
                                -73.99812306736162,
                                40.64777063081786
                            ],
                            [
                                -73.99820935611699,
                                40.64893168676984
                            ],
                            [
                                -73.99788537140982,
                                40.64870018419343
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d190681b-64b8-43d6-9995-45746f7e8fda"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99407978874547,
                                40.647073191486534
                            ],
                            [
                                -73.99789060118249,
                                40.648781103819616
                            ],
                            [
                                -73.99788537140982,
                                40.64870018419343
                            ],
                            [
                                -73.99820935611699,
                                40.64893168676984
                            ],
                            [
                                -73.99821167074313,
                                40.64896283115773
                            ],
                            [
                                -73.99407978874547,
                                40.647073191486534
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "7814feeb-85d5-4080-a2e8-6cbe8bc1609c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00192188534308,
                                40.6508260326309
                            ],
                            [
                                -74.0024509202819,
                                40.65095883381337
                            ],
                            [
                                -74.00264935779656,
                                40.65108669395583
                            ],
                            [
                                -74.0022156928315,
                                40.65289223187681
                            ],
                            [
                                -74.00228797397419,
                                40.65098899693508
                            ],
                            [
                                -74.00192188534308,
                                40.6508260326309
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "14654c5a-eec8-4812-823a-92ca558d8565"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00264935779656,
                                40.65108669395583
                            ],
                            [
                                -74.0024509202819,
                                40.65095883381337
                            ],
                            [
                                -74.00264936170854,
                                40.651086677668566
                            ],
                            [
                                -74.00264935779656,
                                40.65108669395583
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "21572b9c-77f5-4c41-885d-dba25677d815"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00264935779656,
                                40.65108669395583
                            ],
                            [
                                -74.00306542991999,
                                40.651354783591344
                            ],
                            [
                                -74.0022156928315,
                                40.65289223187681
                            ],
                            [
                                -74.00264935779656,
                                40.65108669395583
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4e65aab8-25cf-4319-865f-f6a1c088a09a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0022156928315,
                                40.65289223187681
                            ],
                            [
                                -74.00306542991999,
                                40.651354783591344
                            ],
                            [
                                -74.00264935779656,
                                40.65108669395583
                            ],
                            [
                                -74.00264936170854,
                                40.651086677668566
                            ],
                            [
                                -74.00335295009971,
                                40.65153995727818
                            ],
                            [
                                -74.0022156928315,
                                40.65289223187681
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "371dbb72-4353-475f-9472-0f0259432bb6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00513591010595,
                                40.64972430279937
                            ],
                            [
                                -74.0022156928315,
                                40.65289223187681
                            ],
                            [
                                -74.00335295009971,
                                40.65153995727818
                            ],
                            [
                                -74.00264936170854,
                                40.651086677668566
                            ],
                            [
                                -74.00368114685412,
                                40.64679090245792
                            ],
                            [
                                -74.00513591010595,
                                40.64972430279937
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "72e5fc5d-5379-404a-a46d-226e3dc1f1c1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00373910091345,
                                40.64654961422801
                            ],
                            [
                                -74.00513591010595,
                                40.64972430279937
                            ],
                            [
                                -74.00368114685412,
                                40.64679090245792
                            ],
                            [
                                -74.00373910091345,
                                40.64654961422801
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "812b67a2-7713-4b69-8cb5-e348583a9120"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00373910091345,
                                40.64654961422801
                            ],
                            [
                                -74.0032253560917,
                                40.64538196740626
                            ],
                            [
                                -74.00397487462672,
                                40.64556798459601
                            ],
                            [
                                -74.00373910091345,
                                40.64654961422801
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a255d003-fe45-4997-bf03-c8d7d8f4d10e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00397487462672,
                                40.64556798459601
                            ],
                            [
                                -74.0032253560917,
                                40.64538196740626
                            ],
                            [
                                -74.00373910091345,
                                40.64654961422801
                            ],
                            [
                                -74.00368114685412,
                                40.64679090245792
                            ],
                            [
                                -74.00287843301719,
                                40.64517230160284
                            ],
                            [
                                -74.00399214229577,
                                40.64549609169758
                            ],
                            [
                                -74.00397487462672,
                                40.64556798459601
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d7ebbf01-9d91-41ab-823f-65dff788b176"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00578251115927,
                                40.64601660788172
                            ],
                            [
                                -74.00397487462672,
                                40.64556798459601
                            ],
                            [
                                -74.00399214229577,
                                40.64549609169758
                            ],
                            [
                                -74.00578251115927,
                                40.64601660788172
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c762d784-830c-4401-9956-6356270ef574"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00407566198744,
                                40.645148362499654
                            ],
                            [
                                -74.00578251115927,
                                40.64601660788172
                            ],
                            [
                                -74.00403280079,
                                40.64532681250962
                            ],
                            [
                                -74.00407566198744,
                                40.645148362499654
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "739a9642-086f-4945-bb83-85b7fe812622"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00407566198744,
                                40.645148362499654
                            ],
                            [
                                -74.00343910482367,
                                40.64482455660808
                            ],
                            [
                                -74.00333979517069,
                                40.64454288991122
                            ],
                            [
                                -74.00705258987504,
                                40.64321772947564
                            ],
                            [
                                -74.01039861136772,
                                40.642292091114136
                            ],
                            [
                                -74.00410945463813,
                                40.64500766883712
                            ],
                            [
                                -74.00407566198744,
                                40.645148362499654
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ef341020-c0b0-41ee-8ff3-df33feeee28e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00705258987504,
                                40.64321772947564
                            ],
                            [
                                -74.00333979517069,
                                40.64454288991122
                            ],
                            [
                                -74.00343910482367,
                                40.64482455660808
                            ],
                            [
                                -74.00407566198744,
                                40.645148362499654
                            ],
                            [
                                -74.00403280079,
                                40.64532681250962
                            ],
                            [
                                -74.00324037322831,
                                40.645014410597646
                            ],
                            [
                                -74.00300201065755,
                                40.6443382756282
                            ],
                            [
                                -74.00705258987504,
                                40.64321772947564
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ca123161-b0c4-4781-a8f3-ae2ac96e1a59"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00705258987504,
                                40.64321772947564
                            ],
                            [
                                -74.01398635457413,
                                40.640742949322
                            ],
                            [
                                -74.01039861136772,
                                40.642292091114136
                            ],
                            [
                                -74.00705258987504,
                                40.64321772947564
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d1bbc4b7-863b-4a36-8d67-777140079aab"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01398635457413,
                                40.640742949322
                            ],
                            [
                                -74.0140232452801,
                                40.640729782393066
                            ],
                            [
                                -74.01439691096623,
                                40.641186007516865
                            ],
                            [
                                -74.01039861136772,
                                40.642292091114136
                            ],
                            [
                                -74.01398635457413,
                                40.640742949322
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e744372d-1934-4f94-beed-52318cae2d02"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0140232452801,
                                40.640729782393066
                            ],
                            [
                                -74.01398635457413,
                                40.640742949322
                            ],
                            [
                                -74.01402157410298,
                                40.640727741978296
                            ],
                            [
                                -74.0140232452801,
                                40.640729782393066
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "bd5a3270-f166-457a-b3db-213bd5042055"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02035236214878,
                                40.637363753449684
                            ],
                            [
                                -74.02083589029402,
                                40.63829823194307
                            ],
                            [
                                -74.0140232452801,
                                40.640729782393066
                            ],
                            [
                                -74.01402157410298,
                                40.640727741978296
                            ],
                            [
                                -74.01555991035723,
                                40.64006350798921
                            ],
                            [
                                -74.01249124162756,
                                40.63754595402828
                            ],
                            [
                                -74.02035236214878,
                                40.637363753449684
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "37b266c6-c75e-4880-b11e-40c721f5340a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02035236214878,
                                40.637363753449684
                            ],
                            [
                                -74.01830147055043,
                                40.63340014944895
                            ],
                            [
                                -74.02189901050018,
                                40.637327906114216
                            ],
                            [
                                -74.02035236214878,
                                40.637363753449684
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "0f2499ab-05b4-4398-972f-b637dbff1f90"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02035236214878,
                                40.637363753449684
                            ],
                            [
                                -74.01249124162756,
                                40.63754595402828
                            ],
                            [
                                -74.00975655307647,
                                40.63530239937958
                            ],
                            [
                                -74.01074101861437,
                                40.634356345551176
                            ],
                            [
                                -74.01074198946863,
                                40.63435693225056
                            ],
                            [
                                -74.01074304252904,
                                40.63435440060526
                            ],
                            [
                                -74.01226452517906,
                                40.632892282907406
                            ],
                            [
                                -74.01136706293224,
                                40.63285420337969
                            ],
                            [
                                -74.01567969441216,
                                40.622486276709374
                            ],
                            [
                                -74.02308563288821,
                                40.62135889509295
                            ],
                            [
                                -74.02664535894827,
                                40.62662185038945
                            ],
                            [
                                -74.02456243926332,
                                40.62816025132545
                            ],
                            [
                                -74.02163357809664,
                                40.62699006653739
                            ],
                            [
                                -74.0227499948427,
                                40.629498884885486
                            ],
                            [
                                -74.0182555879198,
                                40.632818359883466
                            ],
                            [
                                -74.01776631912041,
                                40.63281587668881
                            ],
                            [
                                -74.01830147055043,
                                40.63340014944895
                            ],
                            [
                                -74.02035236214878,
                                40.637363753449684
                            ]
                        ],
                        [
                            [
                                -74.01647334069432,
                                40.63325263245424
                            ],
                            [
                                -74.0164064251888,
                                40.63314296589027
                            ],
                            [
                                -74.01617808961325,
                                40.633451002044495
                            ],
                            [
                                -74.01647334069432,
                                40.63325263245424
                            ]
                        ],
                        [
                            [
                                -74.01637796386566,
                                40.63345505557289
                            ],
                            [
                                -74.01638039740176,
                                40.6333869295398
                            ],
                            [
                                -74.01617808961325,
                                40.633451002044495
                            ],
                            [
                                -74.01637796386566,
                                40.63345505557289
                            ]
                        ],
                        [
                            [
                                -74.01949605035281,
                                40.6369159434263
                            ],
                            [
                                -74.01962672019236,
                                40.63653114431555
                            ],
                            [
                                -74.0192358964499,
                                40.63656057909427
                            ],
                            [
                                -74.01949605035281,
                                40.6369159434263
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "fbe5df1a-a8c9-4ad0-9ac6-8ab95e509921"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0182555879198,
                                40.632818359883466
                            ],
                            [
                                -74.01807098364733,
                                40.63295470473996
                            ],
                            [
                                -74.01830147055043,
                                40.63340014944895
                            ],
                            [
                                -74.01776631912041,
                                40.63281587668881
                            ],
                            [
                                -74.0182555879198,
                                40.632818359883466
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c75334b7-4550-4412-ad99-1f52da211478"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0182555879198,
                                40.632818359883466
                            ],
                            [
                                -74.0227499948427,
                                40.629498884885486
                            ],
                            [
                                -74.02424066871421,
                                40.63284873607063
                            ],
                            [
                                -74.0182555879198,
                                40.632818359883466
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1eeab54f-bcb7-4c0f-81d8-0bb4a19a23d5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02456243926332,
                                40.62816025132545
                            ],
                            [
                                -74.0227499948427,
                                40.629498884885486
                            ],
                            [
                                -74.02163357809664,
                                40.62699006653739
                            ],
                            [
                                -74.02456243926332,
                                40.62816025132545
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "fd293b7d-8a0f-4d96-8b1e-73f0bfd65c78"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02456243926332,
                                40.62816025132545
                            ],
                            [
                                -74.02664535894827,
                                40.62662185038945
                            ],
                            [
                                -74.02884252480243,
                                40.62987029857741
                            ],
                            [
                                -74.02456243926332,
                                40.62816025132545
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3c6704b2-4e22-4c26-a9ac-bca196e3a58f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02664535894827,
                                40.62662185038945
                            ],
                            [
                                -74.02685178378427,
                                40.626469389320874
                            ],
                            [
                                -74.02899133026942,
                                40.62992975168356
                            ],
                            [
                                -74.02884252480243,
                                40.62987029857741
                            ],
                            [
                                -74.02664535894827,
                                40.62662185038945
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "32406a21-5740-4529-b587-7c545cda547e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02899133026942,
                                40.62992975168356
                            ],
                            [
                                -74.02973804127643,
                                40.63113743316296
                            ],
                            [
                                -74.02969615792135,
                                40.631172804045924
                            ],
                            [
                                -74.02388408425881,
                                40.6337239758856
                            ],
                            [
                                -74.02905435224962,
                                40.63018347952625
                            ],
                            [
                                -74.02884252480243,
                                40.62987029857741
                            ],
                            [
                                -74.02899133026942,
                                40.62992975168356
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "71ee8afa-8a19-4987-bbd2-9ac34ee68a05"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02973804127643,
                                40.63113743316296
                            ],
                            [
                                -74.02899133026942,
                                40.62992975168356
                            ],
                            [
                                -74.03046899849778,
                                40.63052013299023
                            ],
                            [
                                -74.02973804127643,
                                40.63113743316296
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "965eb604-cc47-4f08-93ed-65a36aaef643"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02973804127643,
                                40.63113743316296
                            ],
                            [
                                -74.02974630205509,
                                40.63115079360536
                            ],
                            [
                                -74.02969615792135,
                                40.631172804045924
                            ],
                            [
                                -74.02973804127643,
                                40.63113743316296
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "664dccb7-bf36-4059-a4eb-3c74114c771d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02388408425881,
                                40.6337239758856
                            ],
                            [
                                -74.02969615792135,
                                40.631172804045924
                            ],
                            [
                                -74.02574643277,
                                40.63450838367624
                            ],
                            [
                                -74.02388408425881,
                                40.6337239758856
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "172f62fc-0d45-4423-a92a-d9cdc105dfde"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0255163665987,
                                40.63470267669797
                            ],
                            [
                                -74.02388408425881,
                                40.6337239758856
                            ],
                            [
                                -74.02574643277,
                                40.63450838367624
                            ],
                            [
                                -74.0255163665987,
                                40.63470267669797
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "93fb38d7-641a-4bf3-8656-9f21d4933a3a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0255163665987,
                                40.63470267669797
                            ],
                            [
                                -74.02851964983968,
                                40.636503416481716
                            ],
                            [
                                -74.02164211526949,
                                40.6418650095329
                            ],
                            [
                                -74.02150028876974,
                                40.638094291781734
                            ],
                            [
                                -74.0255163665987,
                                40.63470267669797
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "83a11bee-9a3d-4ce0-b305-ffb6ce747ad8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02164211526949,
                                40.6418650095329
                            ],
                            [
                                -74.02851964983968,
                                40.636503416481716
                            ],
                            [
                                -74.0255163665987,
                                40.63470267669797
                            ],
                            [
                                -74.02574643277,
                                40.63450838367624
                            ],
                            [
                                -74.0359530570005,
                                40.63880734035079
                            ],
                            [
                                -74.0346896196725,
                                40.63687733804799
                            ],
                            [
                                -74.03733452307533,
                                40.635887965358506
                            ],
                            [
                                -74.03589416345046,
                                40.63338464644524
                            ],
                            [
                                -74.0379961277905,
                                40.63217316983456
                            ],
                            [
                                -74.03827354371992,
                                40.63952892729485
                            ],
                            [
                                -74.02166413469942,
                                40.642450436483834
                            ],
                            [
                                -74.02164211526949,
                                40.6418650095329
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "19fac259-0f33-439c-ae3c-760735375c58"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02066594519816,
                                40.64262601285724
                            ],
                            [
                                -74.02164211526949,
                                40.6418650095329
                            ],
                            [
                                -74.02166413469942,
                                40.642450436483834
                            ],
                            [
                                -74.02066594519816,
                                40.64262601285724
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3596285b-a097-4beb-b20e-a28efd6c8cc8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02066594519816,
                                40.64262601285724
                            ],
                            [
                                -74.02004543498593,
                                40.643109750628106
                            ],
                            [
                                -74.0199942101953,
                                40.6427441675719
                            ],
                            [
                                -74.02066594519816,
                                40.64262601285724
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c3a646ee-01af-4e91-bd09-82b56ba3a163"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02004543498593,
                                40.643109750628106
                            ],
                            [
                                -74.02066594519816,
                                40.64262601285724
                            ],
                            [
                                -74.02166413469942,
                                40.642450436483834
                            ],
                            [
                                -74.02192493857422,
                                40.649384386077195
                            ],
                            [
                                -74.00487598796839,
                                40.66087678722672
                            ],
                            [
                                -74.02156446171868,
                                40.649166391878914
                            ],
                            [
                                -74.02071806028914,
                                40.64791016876552
                            ],
                            [
                                -74.02004543498593,
                                40.643109750628106
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "61d21adb-56d7-438c-aca8-32be3719e896"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02071806028914,
                                40.64791016876552
                            ],
                            [
                                -74.01836588887701,
                                40.6444190923195
                            ],
                            [
                                -74.02004543498593,
                                40.643109750628106
                            ],
                            [
                                -74.02071806028914,
                                40.64791016876552
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5c319648-f1c2-4cd3-82e4-4790c876e6bb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02071806028914,
                                40.64791016876552
                            ],
                            [
                                -74.02156446171868,
                                40.649166391878914
                            ],
                            [
                                -74.00487598796839,
                                40.66087678722672
                            ],
                            [
                                -74.02083176433193,
                                40.64872165615194
                            ],
                            [
                                -74.02071806028914,
                                40.64791016876552
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "58bc28ae-9424-42fa-a252-9eed87728906"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00534385911928,
                                40.663495721407884
                            ],
                            [
                                -74.00951041807873,
                                40.6626915494706
                            ],
                            [
                                -74.00894010106104,
                                40.662363631983666
                            ],
                            [
                                -74.00973692827762,
                                40.66282175875008
                            ],
                            [
                                -74.00547674986646,
                                40.66358657898961
                            ],
                            [
                                -74.00534385911928,
                                40.663495721407884
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "58203d9e-d1d4-434c-a9c6-af70e1fd4dc2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00534385911928,
                                40.663495721407884
                            ],
                            [
                                -73.99843706486855,
                                40.664828775894115
                            ],
                            [
                                -74.00127241237583,
                                40.66071206781977
                            ],
                            [
                                -74.00534385911928,
                                40.663495721407884
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a56337ec-77ee-490a-8db0-4f712d12401b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99843706486855,
                                40.664828775894115
                            ],
                            [
                                -74.00534385911928,
                                40.663495721407884
                            ],
                            [
                                -74.00547674986646,
                                40.66358657898961
                            ],
                            [
                                -73.99842007181209,
                                40.66485344851453
                            ],
                            [
                                -73.99843706486855,
                                40.664828775894115
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a9f7bf6a-26a0-4c32-b708-4a686a6e1c69"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99683288883436,
                                40.66513839190141
                            ],
                            [
                                -73.99843706486855,
                                40.664828775894115
                            ],
                            [
                                -73.99842007181209,
                                40.66485344851453
                            ],
                            [
                                -73.99683288883436,
                                40.66513839190141
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "161a47a3-520e-449a-a196-39d50304903d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9978029394089,
                                40.66574947763992
                            ],
                            [
                                -73.99683288883436,
                                40.66513839190141
                            ],
                            [
                                -73.99842007181209,
                                40.66485344851453
                            ],
                            [
                                -73.9978029394089,
                                40.66574947763992
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "73268ad0-22dd-4d77-8eb9-fcb8097beb6e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9978029394089,
                                40.66574947763992
                            ],
                            [
                                -74.00022832926717,
                                40.66727735792948
                            ],
                            [
                                -73.99651845153547,
                                40.66761445594908
                            ],
                            [
                                -73.9978029394089,
                                40.66574947763992
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "17accdc7-afd1-4436-ac57-41ff5836ad76"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00471275530344,
                                40.67029164793699
                            ],
                            [
                                -74.01014856868015,
                                40.6684777636405
                            ],
                            [
                                -74.01714240005212,
                                40.67358482507989
                            ],
                            [
                                -74.01604175116867,
                                40.67455812219344
                            ],
                            [
                                -74.00828840296681,
                                40.678749731664404
                            ],
                            [
                                -74.00730158655922,
                                40.67797731701375
                            ],
                            [
                                -74.01180870563087,
                                40.673783667902754
                            ],
                            [
                                -74.01313644257026,
                                40.67469284277122
                            ],
                            [
                                -74.01272392631031,
                                40.67290900792762
                            ],
                            [
                                -74.01156790507875,
                                40.67253131985715
                            ],
                            [
                                -74.01200293127675,
                                40.6724024141866
                            ],
                            [
                                -74.01055818428003,
                                40.672109053755015
                            ],
                            [
                                -74.01060953460158,
                                40.67221820701248
                            ],
                            [
                                -74.00471275530344,
                                40.67029164793699
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "32ceb403-5184-41ed-8cd4-1e344a094d72"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01714240005212,
                                40.67358482507989
                            ],
                            [
                                -74.01014856868015,
                                40.6684777636405
                            ],
                            [
                                -74.01738576312167,
                                40.67336962059762
                            ],
                            [
                                -74.01714240005212,
                                40.67358482507989
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3c8380c3-505f-4457-941f-eb0436f7e1ea"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01714240005212,
                                40.67358482507989
                            ],
                            [
                                -74.01715768715923,
                                40.67359598808785
                            ],
                            [
                                -74.01601859291193,
                                40.67460074912856
                            ],
                            [
                                -74.01605771626349,
                                40.67454949115548
                            ],
                            [
                                -74.01604175116867,
                                40.67455812219344
                            ],
                            [
                                -74.01714240005212,
                                40.67358482507989
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "24bb4917-7c19-4aee-b792-6e7685fbad48"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01601859291193,
                                40.67460074912856
                            ],
                            [
                                -74.00605878982152,
                                40.68338599419687
                            ],
                            [
                                -74.01604175116867,
                                40.67455812219344
                            ],
                            [
                                -74.01605771626349,
                                40.67454949115548
                            ],
                            [
                                -74.01601859291193,
                                40.67460074912856
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "683245ac-b047-4e0e-b98b-721eef2186b7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00605878982152,
                                40.68338599419687
                            ],
                            [
                                -74.01601859291193,
                                40.67460074912856
                            ],
                            [
                                -74.00893958108152,
                                40.68387540948737
                            ],
                            [
                                -74.00843671032865,
                                40.68415413223726
                            ],
                            [
                                -74.00791134410946,
                                40.68302105350479
                            ],
                            [
                                -74.00718117907347,
                                40.683510468135104
                            ],
                            [
                                -74.00755368257403,
                                40.683360366940285
                            ],
                            [
                                -74.00843661061565,
                                40.68415419286456
                            ],
                            [
                                -74.00713169896933,
                                40.68492633158167
                            ],
                            [
                                -74.00803194383467,
                                40.68398859429196
                            ],
                            [
                                -74.00764790237261,
                                40.68363613191641
                            ],
                            [
                                -74.00684436653749,
                                40.683760245708804
                            ],
                            [
                                -74.00709379725085,
                                40.684074531947914
                            ],
                            [
                                -74.00605878982152,
                                40.68338599419687
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f0c5479f-ff8d-43d6-9b8b-57b74ff8eec0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00893958108152,
                                40.68387540948737
                            ],
                            [
                                -74.009464201554,
                                40.683584631668424
                            ],
                            [
                                -74.0105122848606,
                                40.68436477703819
                            ],
                            [
                                -74.01048657777211,
                                40.684504033959804
                            ],
                            [
                                -74.00814454130077,
                                40.684917041301944
                            ],
                            [
                                -74.00893958108152,
                                40.68387540948737
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "2f4d237c-954c-4b68-bac1-6b1709d1f58d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.009464201554,
                                40.683584631668424
                            ],
                            [
                                -74.00979686297507,
                                40.68378395111986
                            ],
                            [
                                -74.0105122848606,
                                40.68436477703819
                            ],
                            [
                                -74.009464201554,
                                40.683584631668424
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4aa868e0-5de4-47e1-b918-6d0f10624fa6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00253383365968,
                                40.68590646374635
                            ],
                            [
                                -74.00814454130077,
                                40.684917041301944
                            ],
                            [
                                -74.0040264711784,
                                40.69031238497897
                            ],
                            [
                                -74.00198247042078,
                                40.6892265642701
                            ],
                            [
                                -74.00093738389397,
                                40.689380724862524
                            ],
                            [
                                -74.00149484691826,
                                40.69005224493217
                            ],
                            [
                                -74.00075998279503,
                                40.69006723285292
                            ],
                            [
                                -74.00051103717193,
                                40.69013885182687
                            ],
                            [
                                -73.99997381049604,
                                40.69118190062693
                            ],
                            [
                                -74.00068125354522,
                                40.6918930413972
                            ],
                            [
                                -73.99986117164089,
                                40.691612858724746
                            ],
                            [
                                -73.99981098905417,
                                40.69126850809678
                            ],
                            [
                                -74.00052678374054,
                                40.6897834249257
                            ],
                            [
                                -74.00079191880171,
                                40.689763770394244
                            ],
                            [
                                -74.00086882357317,
                                40.68910116578158
                            ],
                            [
                                -74.0015378834069,
                                40.68919222457374
                            ],
                            [
                                -74.00238850599843,
                                40.68607321981626
                            ],
                            [
                                -74.00253383365968,
                                40.68590646374635
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "dd4e1b8f-b652-4293-b9c7-8a38b9c17055"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0024289394052,
                                40.68592496136488
                            ],
                            [
                                -74.00253383365968,
                                40.68590646374635
                            ],
                            [
                                -74.00238850599843,
                                40.68607321981626
                            ],
                            [
                                -74.0024289394052,
                                40.68592496136488
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "4fdac184-3275-4357-8923-03372747249e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0040264711784,
                                40.69031238497897
                            ],
                            [
                                -74.00534733938785,
                                40.69101406087128
                            ],
                            [
                                -74.0037435370955,
                                40.69068307478507
                            ],
                            [
                                -74.0040264711784,
                                40.69031238497897
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f4771c82-a8cf-46d4-a6b2-344fd5ecf949"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0007600519653,
                                40.69006735545906
                            ],
                            [
                                -74.0037435370955,
                                40.69068307478507
                            ],
                            [
                                -74.00271412415165,
                                40.692031773678636
                            ],
                            [
                                -74.0007600519653,
                                40.69006735545906
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9b0ffd3a-c618-4ded-94f4-e62c50f900b7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99509552550067,
                                40.702363738673675
                            ],
                            [
                                -73.99472625845159,
                                40.70274604439877
                            ],
                            [
                                -73.99462489914542,
                                40.70262997810267
                            ],
                            [
                                -73.995042290808,
                                40.70208312693195
                            ],
                            [
                                -73.99509552550067,
                                40.702363738673675
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "8b057df1-6fef-473e-82b5-d3e7397a0317"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99472625845159,
                                40.70274604439877
                            ],
                            [
                                -73.9938208350875,
                                40.70368343791005
                            ],
                            [
                                -73.99382083285427,
                                40.70368343611843
                            ],
                            [
                                -73.99462489914542,
                                40.70262997810267
                            ],
                            [
                                -73.99472625845159,
                                40.70274604439877
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "67cee943-be85-4a98-83db-e1f9ef9544b5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9938208350875,
                                40.70368343791005
                            ],
                            [
                                -73.99382081792376,
                                40.70368345567984
                            ],
                            [
                                -73.99382083285427,
                                40.70368343611843
                            ],
                            [
                                -73.9938208350875,
                                40.70368343791005
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 750
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "52f9edc1-e909-4183-83e3-ff9ddd977491"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00989466057423,
                                40.745570890816715
                            ],
                            [
                                -74.00993348629892,
                                40.74539539987428
                            ],
                            [
                                -74.01174497195808,
                                40.745587958803995
                            ],
                            [
                                -74.01173768841777,
                                40.745629115345736
                            ],
                            [
                                -74.00989466057423,
                                40.745570890816715
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f217eaf7-8666-46ed-9c6c-e2d070115d52"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97423876321602,
                                40.78919894343847
                            ],
                            [
                                -73.97417950123578,
                                40.789089216712114
                            ],
                            [
                                -73.9743056864948,
                                40.788979904272075
                            ],
                            [
                                -73.97796677307122,
                                40.789745574241316
                            ],
                            [
                                -73.97526653088727,
                                40.79024506861554
                            ],
                            [
                                -73.97423876321602,
                                40.78919894343847
                            ]
                        ],
                        [
                            [
                                -73.97568581402092,
                                40.789679718358784
                            ],
                            [
                                -73.97558732273318,
                                40.789636365756614
                            ],
                            [
                                -73.97554428714285,
                                40.78969268291829
                            ],
                            [
                                -73.97568581402092,
                                40.789679718358784
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1b198e06-bdfe-40a2-8f85-d964d06db647"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97423876321602,
                                40.78919894343847
                            ],
                            [
                                -73.97526653088727,
                                40.79024506861554
                            ],
                            [
                                -73.97484579580546,
                                40.790322896748016
                            ],
                            [
                                -73.97423876321602,
                                40.78919894343847
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "03d48bcc-7c85-4254-9871-1a28d4e7ac0e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97423876321602,
                                40.78919894343847
                            ],
                            [
                                -73.97415327916306,
                                40.7891119325093
                            ],
                            [
                                -73.97417950123578,
                                40.789089216712114
                            ],
                            [
                                -73.97423876321602,
                                40.78919894343847
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ef10be1b-8f94-40cc-a4f4-3dfd99e15cc5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9743056864948,
                                40.788979904272075
                            ],
                            [
                                -73.97417950123578,
                                40.789089216712114
                            ],
                            [
                                -73.97409687753962,
                                40.788936234519134
                            ],
                            [
                                -73.9743056864948,
                                40.788979904272075
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "df99afc8-ecb5-4f00-8c82-4f888aed4d50"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95178445453764,
                                40.79781695261813
                            ],
                            [
                                -73.95378878787905,
                                40.799592984405024
                            ],
                            [
                                -73.9537852330416,
                                40.79959347987811
                            ],
                            [
                                -73.94954949252622,
                                40.79787788699402
                            ],
                            [
                                -73.95178445453764,
                                40.79781695261813
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5c94f617-27ee-4169-b61e-81697fa099ae"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9537852330416,
                                40.79959347987811
                            ],
                            [
                                -73.95378878787905,
                                40.799592984405024
                            ],
                            [
                                -73.95178445453764,
                                40.79781695261813
                            ],
                            [
                                -73.95299478047414,
                                40.79778395409055
                            ],
                            [
                                -73.95391656334675,
                                40.79964667230956
                            ],
                            [
                                -73.9537852330416,
                                40.79959347987811
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8a04ed9b-9df1-4f4b-ba8e-b7f2b24433a0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.933449620155,
                                40.807765424161296
                            ],
                            [
                                -73.93293658040331,
                                40.80726896355585
                            ],
                            [
                                -73.93415964850347,
                                40.80674395743189
                            ],
                            [
                                -73.93463227283569,
                                40.80647993936083
                            ],
                            [
                                -73.93475899008799,
                                40.80616929900255
                            ],
                            [
                                -73.93463427607877,
                                40.805892063626644
                            ],
                            [
                                -73.9345172929709,
                                40.80580530341131
                            ],
                            [
                                -73.93555232476781,
                                40.80613877022915
                            ],
                            [
                                -73.9353157781312,
                                40.806985414709274
                            ],
                            [
                                -73.933449620155,
                                40.807765424161296
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "0e04b908-aa5d-4374-babf-5ccbb3e94e90"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93276653125369,
                                40.8071044096329
                            ],
                            [
                                -73.93293658040331,
                                40.80726896355585
                            ],
                            [
                                -73.93158503647498,
                                40.80784911836414
                            ],
                            [
                                -73.93162617392511,
                                40.807904478360456
                            ],
                            [
                                -73.9307446391705,
                                40.808053417524626
                            ],
                            [
                                -73.93276653125369,
                                40.8071044096329
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5452a58a-c2fe-4b3e-a657-dff0412543fc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93276653125369,
                                40.8071044096329
                            ],
                            [
                                -73.92906677486597,
                                40.80352421279601
                            ],
                            [
                                -73.93086461085294,
                                40.80266351682492
                            ],
                            [
                                -73.93475881042492,
                                40.80616930104242
                            ],
                            [
                                -73.93276653125369,
                                40.8071044096329
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a271e37a-6761-4ff7-b0f3-5927579b6869"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9371307723071,
                                40.79720877464568
                            ],
                            [
                                -73.93940431406777,
                                40.79857521944706
                            ],
                            [
                                -73.93086461085294,
                                40.80266351682492
                            ],
                            [
                                -73.92839811388423,
                                40.800443033260706
                            ],
                            [
                                -73.93685787410493,
                                40.7982526353037
                            ],
                            [
                                -73.9371307723071,
                                40.79720877464568
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "bc0d2792-255a-4075-99d9-a467a3ce706e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9371307723071,
                                40.79720877464568
                            ],
                            [
                                -73.93524911809352,
                                40.7960778622241
                            ],
                            [
                                -73.93865192467145,
                                40.791390227306906
                            ],
                            [
                                -73.9371307723071,
                                40.79720877464568
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5ad96715-ce28-412b-95ac-b09ff0d9ffbe"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.943695825475,
                                40.774643652437405
                            ],
                            [
                                -73.9473275132642,
                                40.779438918561205
                            ],
                            [
                                -73.93865192467145,
                                40.791390227306906
                            ],
                            [
                                -73.94299319591724,
                                40.77478446653881
                            ],
                            [
                                -73.943695825475,
                                40.774643652437405
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a6db8872-22a2-4839-9e33-1c0ccc30138f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.943695825475,
                                40.774643652437405
                            ],
                            [
                                -73.94351422147696,
                                40.774403863240124
                            ],
                            [
                                -73.94528422005584,
                                40.77432532202686
                            ],
                            [
                                -73.943695825475,
                                40.774643652437405
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "62338fa1-0707-49c7-b58d-8bd4df281b89"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95711224457678,
                                40.76111479506759
                            ],
                            [
                                -73.95776937561658,
                                40.76513643482107
                            ],
                            [
                                -73.9494982463342,
                                40.76836204150158
                            ],
                            [
                                -73.95446507555754,
                                40.77391793439476
                            ],
                            [
                                -73.94528422005584,
                                40.77432532202686
                            ],
                            [
                                -73.95205277472786,
                                40.772968834908674
                            ],
                            [
                                -73.94824221022584,
                                40.76950536513878
                            ],
                            [
                                -73.95711224457678,
                                40.76111479506759
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e93689fc-c9b6-48dd-a8c2-f8c50ec4e7a0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97577458742752,
                                40.712159458557586
                            ],
                            [
                                -73.9706108024119,
                                40.712984631853246
                            ],
                            [
                                -73.97021946701042,
                                40.71287798013258
                            ],
                            [
                                -73.96999685521855,
                                40.71224099980826
                            ],
                            [
                                -73.97577458742752,
                                40.712159458557586
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "59a63201-b139-4aa2-9228-fc73a63ec46c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98972727366169,
                                40.709929818081015
                            ],
                            [
                                -73.98994198114677,
                                40.70989550780618
                            ],
                            [
                                -73.99215126206643,
                                40.71192833421959
                            ],
                            [
                                -73.9849810447632,
                                40.712029527640354
                            ],
                            [
                                -73.98642762384665,
                                40.71059035341893
                            ],
                            [
                                -73.98953652955161,
                                40.711171058021705
                            ],
                            [
                                -73.98861335339201,
                                40.7106296910439
                            ],
                            [
                                -73.9894442294528,
                                40.71054865769008
                            ],
                            [
                                -73.98908535968332,
                                40.71020586078772
                            ],
                            [
                                -73.9897287268016,
                                40.71012876070917
                            ],
                            [
                                -73.98928734946821,
                                40.710120474348635
                            ],
                            [
                                -73.98983042597322,
                                40.710054361152835
                            ],
                            [
                                -73.98972727366169,
                                40.709929818081015
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "662e59cd-fc4c-42eb-ba7a-7762c67aeb3e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98994198114677,
                                40.70989550780618
                            ],
                            [
                                -73.98972727366169,
                                40.709929818081015
                            ],
                            [
                                -73.98970798829495,
                                40.709906533495555
                            ],
                            [
                                -73.98992065276038,
                                40.709875882910666
                            ],
                            [
                                -73.98994198114677,
                                40.70989550780618
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "bfb29724-374a-4de5-ab9a-0b1b9358bde3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97076417068978,
                                40.70438757943334
                            ],
                            [
                                -73.96882765922197,
                                40.699470651342715
                            ],
                            [
                                -73.97276108989867,
                                40.70513369733053
                            ],
                            [
                                -73.97076417068978,
                                40.70438757943334
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "4f756ccd-5e57-4930-ac7e-cd51cd8d2bdc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96769987288619,
                                40.70324265207554
                            ],
                            [
                                -73.96773860914516,
                                40.70291716765663
                            ],
                            [
                                -73.96813147452083,
                                40.703403913333695
                            ],
                            [
                                -73.96769987288619,
                                40.70324265207554
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "fa5fe269-fa09-4dfd-b663-dc69ddd09577"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96769987288619,
                                40.70324265207554
                            ],
                            [
                                -73.96756257148554,
                                40.7043963377381
                            ],
                            [
                                -73.967496384717,
                                40.703166621876505
                            ],
                            [
                                -73.96769987288619,
                                40.70324265207554
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f1979d35-7e77-476b-99e5-96f52dec2edd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96748451606298,
                                40.70505220447704
                            ],
                            [
                                -73.96756257148554,
                                40.7043963377381
                            ],
                            [
                                -73.96761850794776,
                                40.70543560821996
                            ],
                            [
                                -73.96748451606298,
                                40.70505220447704
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "5959cc1b-6681-4f20-a242-5b28f128c1d3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96724985298434,
                                40.70702397918653
                            ],
                            [
                                -73.96748451606298,
                                40.70505220447704
                            ],
                            [
                                -73.96761850794776,
                                40.70543560821996
                            ],
                            [
                                -73.96776215683404,
                                40.708104530088136
                            ],
                            [
                                -73.96724985298434,
                                40.70702397918653
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "4564c2f9-55ce-4528-9398-5f2f4c842097"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95976987803935,
                                40.72882595682051
                            ],
                            [
                                -73.95734046420439,
                                40.72696805193771
                            ],
                            [
                                -73.95865035382296,
                                40.7259771770162
                            ],
                            [
                                -73.95976987803935,
                                40.72882595682051
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "38f0d926-3b4c-40ff-b9c5-b20030266f93"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95976987803935,
                                40.72882595682051
                            ],
                            [
                                -73.96313429056315,
                                40.73139890605039
                            ],
                            [
                                -73.96136712278138,
                                40.73289036069584
                            ],
                            [
                                -73.95976987803935,
                                40.72882595682051
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "ffbcc585-b425-4add-a810-f19c9a1a7333"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96136712278138,
                                40.73289036069584
                            ],
                            [
                                -73.96313429056315,
                                40.73139890605039
                            ],
                            [
                                -73.96136846908104,
                                40.732893786536216
                            ],
                            [
                                -73.96136712278138,
                                40.73289036069584
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "9893c25d-bc90-4d1a-b7ff-6aabbe206610"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95537433920612,
                                40.73794815146971
                            ],
                            [
                                -73.96136712278138,
                                40.73289036069584
                            ],
                            [
                                -73.96136846908104,
                                40.732893786536216
                            ],
                            [
                                -73.95538584333387,
                                40.737958460725544
                            ],
                            [
                                -73.95537433920612,
                                40.73794815146971
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "019215a8-06e0-4348-8406-2d5c14d0f58f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95537433920612,
                                40.73794815146971
                            ],
                            [
                                -73.95465614431586,
                                40.73855429374804
                            ],
                            [
                                -73.95281671835524,
                                40.73957154347496
                            ],
                            [
                                -73.95270831671318,
                                40.73941250928218
                            ],
                            [
                                -73.95244391443423,
                                40.73952393430765
                            ],
                            [
                                -73.95305458401755,
                                40.73826922820893
                            ],
                            [
                                -73.9503679203784,
                                40.733461722457655
                            ],
                            [
                                -73.95537433920612,
                                40.73794815146971
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ca6b3b5b-0bf2-41a9-a1ca-df3aaf2ceb1d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95465614431586,
                                40.73855429374804
                            ],
                            [
                                -73.95335050722967,
                                40.73965622561753
                            ],
                            [
                                -73.9528856211082,
                                40.739672629523945
                            ],
                            [
                                -73.95281671835524,
                                40.73957154347496
                            ],
                            [
                                -73.95465614431586,
                                40.73855429374804
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4ef9a198-216d-4f50-9a73-f66abef7219a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95335050722967,
                                40.73965622561753
                            ],
                            [
                                -73.95308054723193,
                                40.73988406651463
                            ],
                            [
                                -73.9530082966156,
                                40.739852604660136
                            ],
                            [
                                -73.9528856211082,
                                40.739672629523945
                            ],
                            [
                                -73.95335050722967,
                                40.73965622561753
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "2cb5c449-2c27-46aa-aeae-00107bcc2f7a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95308054723193,
                                40.73988406651463
                            ],
                            [
                                -73.95304829549991,
                                40.73991128633837
                            ],
                            [
                                -73.9530082966156,
                                40.739852604660136
                            ],
                            [
                                -73.95308054723193,
                                40.73988406651463
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "aec5aa71-fc1c-4f64-b808-cdd009aa3dcc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96057571348021,
                                40.7394012780042
                            ],
                            [
                                -73.96251381790819,
                                40.73915525796691
                            ],
                            [
                                -73.96242029457078,
                                40.73933619037482
                            ],
                            [
                                -73.96057571348021,
                                40.7394012780042
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b996f590-43e2-4c9b-a4cf-ac192131d4c8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95772492158643,
                                40.74841996736166
                            ],
                            [
                                -73.9604731384891,
                                40.74310320348826
                            ],
                            [
                                -73.96050161067427,
                                40.74311560182881
                            ],
                            [
                                -73.95772492158643,
                                40.74841996736166
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "fd77f50b-9787-4592-a19a-5f2c4a4a1a77"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91604982129275,
                                40.771975419466976
                            ],
                            [
                                -73.93582841287416,
                                40.75590976709328
                            ],
                            [
                                -73.94181806890535,
                                40.7574107821128
                            ],
                            [
                                -73.91604982129275,
                                40.771975419466976
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "74721122-c9e5-4c16-b012-ebe978e80739"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91604982129275,
                                40.771975419466976
                            ],
                            [
                                -73.91561202772935,
                                40.77233102816499
                            ],
                            [
                                -73.92268166406873,
                                40.76129809846373
                            ],
                            [
                                -73.92365391234112,
                                40.761267714763555
                            ],
                            [
                                -73.91591218451019,
                                40.77205321404015
                            ],
                            [
                                -73.91604982129275,
                                40.771975419466976
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "cc95704c-6ce7-426f-9bc8-6a5088a00ad3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90253597797718,
                                40.751410921770564
                            ],
                            [
                                -73.91058462690303,
                                40.75967096743181
                            ],
                            [
                                -73.92292271244594,
                                40.76092191648312
                            ],
                            [
                                -73.92268166406873,
                                40.76129809846373
                            ],
                            [
                                -73.90892553918458,
                                40.76172799068981
                            ],
                            [
                                -73.90295844667214,
                                40.753838854579556
                            ],
                            [
                                -73.88058695285508,
                                40.74798489952921
                            ],
                            [
                                -73.89031707192302,
                                40.74073798909299
                            ],
                            [
                                -73.8981859544858,
                                40.74606304570057
                            ],
                            [
                                -73.91397116233644,
                                40.7382938705383
                            ],
                            [
                                -73.91385430709151,
                                40.74190590210158
                            ],
                            [
                                -73.91056160038735,
                                40.742412279096875
                            ],
                            [
                                -73.91333204766255,
                                40.74363909874371
                            ],
                            [
                                -73.90901164899277,
                                40.74504476057437
                            ],
                            [
                                -73.91985329375332,
                                40.74652686091034
                            ],
                            [
                                -73.92819929226381,
                                40.75022266665144
                            ],
                            [
                                -73.9248237685727,
                                40.75072599072362
                            ],
                            [
                                -73.92390901685472,
                                40.751740329938215
                            ],
                            [
                                -73.90688222665455,
                                40.751477907556776
                            ],
                            [
                                -73.9068087265912,
                                40.751289051815604
                            ],
                            [
                                -73.90610455888364,
                                40.75146592189031
                            ],
                            [
                                -73.90253597797718,
                                40.751410921770564
                            ]
                        ],
                        [
                            [
                                -73.90469947287657,
                                40.745487212833865
                            ],
                            [
                                -73.90568073185186,
                                40.74539184266323
                            ],
                            [
                                -73.9034031136361,
                                40.74421937920291
                            ],
                            [
                                -73.90021822842442,
                                40.74592275233854
                            ],
                            [
                                -73.90113938272388,
                                40.745833223840016
                            ],
                            [
                                -73.902973501538,
                                40.74652667661017
                            ],
                            [
                                -73.90469947287657,
                                40.745487212833865
                            ]
                        ],
                        [
                            [
                                -73.89698988721086,
                                40.74626266956396
                            ],
                            [
                                -73.89640224823373,
                                40.74632459400667
                            ],
                            [
                                -73.89083859425111,
                                40.74377352026575
                            ],
                            [
                                -73.88824943490492,
                                40.745327482963994
                            ],
                            [
                                -73.89182108077904,
                                40.74933950367607
                            ],
                            [
                                -73.89640298729302,
                                40.74632493288374
                            ],
                            [
                                -73.89640232995225,
                                40.74632463147665
                            ],
                            [
                                -73.89640298369977,
                                40.74632456265376
                            ],
                            [
                                -73.89698988721086,
                                40.74626266956396
                            ]
                        ],
                        [
                            [
                                -73.89936128864396,
                                40.74970487995872
                            ],
                            [
                                -73.89908359779055,
                                40.74963532734168
                            ],
                            [
                                -73.89922170572018,
                                40.74779682236454
                            ],
                            [
                                -73.89653561635821,
                                40.74885817033564
                            ],
                            [
                                -73.89892360697966,
                                40.75176513917002
                            ],
                            [
                                -73.8990243862611,
                                40.75042355645996
                            ],
                            [
                                -73.89953258299562,
                                40.750125337109864
                            ],
                            [
                                -73.89936128864396,
                                40.74970487995872
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "46c3b222-9c3d-4d64-866a-8546de20756c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90610455888364,
                                40.75146592189031
                            ],
                            [
                                -73.90688222665455,
                                40.751477907556776
                            ],
                            [
                                -73.90773782444516,
                                40.75367633498012
                            ],
                            [
                                -73.90394456778373,
                                40.75200845995772
                            ],
                            [
                                -73.90610455888364,
                                40.75146592189031
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "fd47085a-0d87-4c73-b1bc-0beda2035335"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90688222665455,
                                40.751477907556776
                            ],
                            [
                                -73.90610455888364,
                                40.75146592189031
                            ],
                            [
                                -73.9068087265912,
                                40.751289051815604
                            ],
                            [
                                -73.90688222665455,
                                40.751477907556776
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c2577864-40ea-42ad-a1ee-ac93014d69ec"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92819929226381,
                                40.75022266665144
                            ],
                            [
                                -73.93190482413972,
                                40.75186356389862
                            ],
                            [
                                -73.92390901685472,
                                40.751740329938215
                            ],
                            [
                                -73.9248237685727,
                                40.75072599072362
                            ],
                            [
                                -73.92819929226381,
                                40.75022266665144
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f1bcc275-1dae-4dc3-a69b-1b8455d20ea2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92819929226381,
                                40.75022266665144
                            ],
                            [
                                -73.91985329375332,
                                40.74652686091034
                            ],
                            [
                                -73.9355536843081,
                                40.74867317308252
                            ],
                            [
                                -73.93549933775836,
                                40.749134157441716
                            ],
                            [
                                -73.92819929226381,
                                40.75022266665144
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "2dec8ce7-13b9-4af1-a5ef-248728e592ac"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91333204766255,
                                40.74363909874371
                            ],
                            [
                                -73.92296605519971,
                                40.740504629518426
                            ],
                            [
                                -73.92553476252576,
                                40.74010959454738
                            ],
                            [
                                -73.93635044634364,
                                40.74507484505476
                            ],
                            [
                                -73.93730284714084,
                                40.744604684996425
                            ],
                            [
                                -73.93783169278868,
                                40.74514353213836
                            ],
                            [
                                -73.93981314403706,
                                40.74336545741636
                            ],
                            [
                                -73.94485695219677,
                                40.740875542303336
                            ],
                            [
                                -73.93954453310828,
                                40.738733443067666
                            ],
                            [
                                -73.95006156306381,
                                40.74052791038213
                            ],
                            [
                                -73.93862645657505,
                                40.74534691964601
                            ],
                            [
                                -73.93860425005643,
                                40.749501410848296
                            ],
                            [
                                -73.93569532746481,
                                40.747471711717715
                            ],
                            [
                                -73.9355536843081,
                                40.74867317308252
                            ],
                            [
                                -73.91985329375332,
                                40.74652686091034
                            ],
                            [
                                -73.91333204766255,
                                40.74363909874371
                            ]
                        ],
                        [
                            [
                                -73.93070144278174,
                                40.744710195380485
                            ],
                            [
                                -73.9307937976778,
                                40.74481320204311
                            ],
                            [
                                -73.93098458457277,
                                40.74474277980465
                            ],
                            [
                                -73.9308400537284,
                                40.74458925439752
                            ],
                            [
                                -73.93159858935768,
                                40.74466800922289
                            ],
                            [
                                -73.93099378332128,
                                40.74460512773967
                            ],
                            [
                                -73.93087926159728,
                                40.744352534727724
                            ],
                            [
                                -73.93070144278174,
                                40.744710195380485
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "006a18ed-04a7-4e3e-b7fa-51ea4512a6a7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91333204766255,
                                40.74363909874371
                            ],
                            [
                                -73.91985329375332,
                                40.74652686091034
                            ],
                            [
                                -73.90901164899277,
                                40.74504476057437
                            ],
                            [
                                -73.91333204766255,
                                40.74363909874371
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "bdd4239f-08e6-4aee-ab97-d58207d544bc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91333204766255,
                                40.74363909874371
                            ],
                            [
                                -73.91056160038735,
                                40.742412279096875
                            ],
                            [
                                -73.91385430709151,
                                40.74190590210158
                            ],
                            [
                                -73.91383955016417,
                                40.74236204326462
                            ],
                            [
                                -73.91407919472236,
                                40.74187131720441
                            ],
                            [
                                -73.92296605519971,
                                40.740504629518426
                            ],
                            [
                                -73.91333204766255,
                                40.74363909874371
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "83e398f5-4ad2-41d4-a40a-2542eb3bb9e8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91385430709151,
                                40.74190590210158
                            ],
                            [
                                -73.91407919472236,
                                40.74187131720441
                            ],
                            [
                                -73.91383955016417,
                                40.74236204326462
                            ],
                            [
                                -73.91385430709151,
                                40.74190590210158
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "53f1aa38-984c-47f6-a1e7-666797447996"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91407919472236,
                                40.74187131720441
                            ],
                            [
                                -73.91931592447791,
                                40.731147936791736
                            ],
                            [
                                -73.91515065210113,
                                40.73328965751134
                            ],
                            [
                                -73.91588651065938,
                                40.73067250374568
                            ],
                            [
                                -73.91565455577154,
                                40.730460658880496
                            ],
                            [
                                -73.91415998012432,
                                40.73028566624367
                            ],
                            [
                                -73.91209622382826,
                                40.7297982365025
                            ],
                            [
                                -73.9121168033331,
                                40.7296840376866
                            ],
                            [
                                -73.91584476954138,
                                40.728624756843956
                            ],
                            [
                                -73.91753285936878,
                                40.731011239562136
                            ],
                            [
                                -73.91836862226471,
                                40.72594763367213
                            ],
                            [
                                -73.88850115452563,
                                40.73012964388689
                            ],
                            [
                                -73.89759702593436,
                                40.724820610937634
                            ],
                            [
                                -73.90029522302741,
                                40.72752405247334
                            ],
                            [
                                -73.93042621612618,
                                40.71561613147096
                            ],
                            [
                                -73.93176326450141,
                                40.71623525668673
                            ],
                            [
                                -73.92975995446507,
                                40.71848816674973
                            ],
                            [
                                -73.93289959600158,
                                40.72027296470528
                            ],
                            [
                                -73.9323112480254,
                                40.72809204261121
                            ],
                            [
                                -73.94287964437375,
                                40.72775585363357
                            ],
                            [
                                -73.94362082365343,
                                40.73234266397572
                            ],
                            [
                                -73.9410414828933,
                                40.733342974248394
                            ],
                            [
                                -73.94735398571731,
                                40.73582056731624
                            ],
                            [
                                -73.94872656395255,
                                40.73199084490198
                            ],
                            [
                                -73.94946430874352,
                                40.732651964106665
                            ],
                            [
                                -73.95011024607939,
                                40.73656864516102
                            ],
                            [
                                -73.9474450919551,
                                40.73698758784124
                            ],
                            [
                                -73.95305458401755,
                                40.73826922820893
                            ],
                            [
                                -73.95244391443423,
                                40.73952393430765
                            ],
                            [
                                -73.95125862508024,
                                40.74002344171345
                            ],
                            [
                                -73.94742373450923,
                                40.7383757644939
                            ],
                            [
                                -73.94795375154976,
                                40.73865470257419
                            ],
                            [
                                -73.94729348957283,
                                40.738379297753966
                            ],
                            [
                                -73.9508465370787,
                                40.740197104800785
                            ],
                            [
                                -73.95047182652013,
                                40.74035501619888
                            ],
                            [
                                -73.93990690529957,
                                40.73707554063826
                            ],
                            [
                                -73.93939038209602,
                                40.73867128555335
                            ],
                            [
                                -73.93954453310828,
                                40.738733443067666
                            ],
                            [
                                -73.93033028495749,
                                40.73716126278446
                            ],
                            [
                                -73.93809250695332,
                                40.741542544538916
                            ],
                            [
                                -73.94215661465608,
                                40.74126252105957
                            ],
                            [
                                -73.93981314403706,
                                40.74336545741636
                            ],
                            [
                                -73.93730284714084,
                                40.744604684996425
                            ],
                            [
                                -73.9319264699448,
                                40.73912663010856
                            ],
                            [
                                -73.92553476252576,
                                40.74010959454738
                            ],
                            [
                                -73.92497294819866,
                                40.73985167753356
                            ],
                            [
                                -73.92296605519971,
                                40.740504629518426
                            ],
                            [
                                -73.91407919472236,
                                40.74187131720441
                            ]
                        ],
                        [
                            [
                                -73.93625914696806,
                                40.73501486922149
                            ],
                            [
                                -73.93886877712293,
                                40.734121428050734
                            ],
                            [
                                -73.9375336254892,
                                40.733878194974466
                            ],
                            [
                                -73.93625914696806,
                                40.73501486922149
                            ]
                        ],
                        [
                            [
                                -73.93486942986733,
                                40.73552563599502
                            ],
                            [
                                -73.9344901386965,
                                40.73558200991506
                            ],
                            [
                                -73.9350337371227,
                                40.735779245426535
                            ],
                            [
                                -73.93527377145817,
                                40.73537828760206
                            ],
                            [
                                -73.93486942986733,
                                40.73552563599502
                            ]
                        ],
                        [
                            [
                                -73.93508057258666,
                                40.737448535548744
                            ],
                            [
                                -73.93445807153579,
                                40.73732370839553
                            ],
                            [
                                -73.93502064148247,
                                40.73759849581011
                            ],
                            [
                                -73.93536335864484,
                                40.73749721978348
                            ],
                            [
                                -73.93531895042877,
                                40.73720779429206
                            ],
                            [
                                -73.93529972917663,
                                40.7372256582929
                            ],
                            [
                                -73.93508057258666,
                                40.737448535548744
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "126432be-2c43-4557-b774-243052c5958b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92553476252576,
                                40.74010959454738
                            ],
                            [
                                -73.92296605519971,
                                40.740504629518426
                            ],
                            [
                                -73.92497294819866,
                                40.73985167753356
                            ],
                            [
                                -73.92553476252576,
                                40.74010959454738
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "1c81704e-aa59-401d-a2dd-1ba58828a2c8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92553476252576,
                                40.74010959454738
                            ],
                            [
                                -73.9319264699448,
                                40.73912663010856
                            ],
                            [
                                -73.93730284714084,
                                40.744604684996425
                            ],
                            [
                                -73.93635044634364,
                                40.74507484505476
                            ],
                            [
                                -73.92553476252576,
                                40.74010959454738
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "a8343384-eb23-4a07-b4eb-c725a9d556c1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93981314403706,
                                40.74336545741636
                            ],
                            [
                                -73.93783169278868,
                                40.74514353213836
                            ],
                            [
                                -73.93730284714084,
                                40.744604684996425
                            ],
                            [
                                -73.93981314403706,
                                40.74336545741636
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c8fff481-ed88-4e30-872e-c0a41cdf7a86"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93981314403706,
                                40.74336545741636
                            ],
                            [
                                -73.94215661465608,
                                40.74126252105957
                            ],
                            [
                                -73.93809250695332,
                                40.741542544538916
                            ],
                            [
                                -73.93033028495749,
                                40.73716126278446
                            ],
                            [
                                -73.93954453310828,
                                40.738733443067666
                            ],
                            [
                                -73.94485695219677,
                                40.740875542303336
                            ],
                            [
                                -73.93981314403706,
                                40.74336545741636
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "5b034da7-13bc-43b7-afd8-d16fff2015b9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95006156306381,
                                40.74052791038213
                            ],
                            [
                                -73.93954453310828,
                                40.738733443067666
                            ],
                            [
                                -73.93939038209602,
                                40.73867128555335
                            ],
                            [
                                -73.93990690529957,
                                40.73707554063826
                            ],
                            [
                                -73.95047182652013,
                                40.74035501619888
                            ],
                            [
                                -73.95006156306381,
                                40.74052791038213
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f22442b3-1e03-491f-a4bb-ec3fda163b3d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95220943911679,
                                40.74089439154543
                            ],
                            [
                                -73.95006156306381,
                                40.74052791038213
                            ],
                            [
                                -73.95047182652013,
                                40.74035501619888
                            ],
                            [
                                -73.95220943911679,
                                40.74089439154543
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "6c03873a-37c4-49c9-8ff0-50f8ad43deba"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95220943911679,
                                40.74089439154543
                            ],
                            [
                                -73.9508465370787,
                                40.740197104800785
                            ],
                            [
                                -73.9508676229643,
                                40.74018821873767
                            ],
                            [
                                -73.95220943911679,
                                40.74089439154543
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "cde2b82e-8514-4e98-baf6-a5e06bfbddbe"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9508465370787,
                                40.740197104800785
                            ],
                            [
                                -73.95220943911679,
                                40.74089439154543
                            ],
                            [
                                -73.95047182652013,
                                40.74035501619888
                            ],
                            [
                                -73.9508465370787,
                                40.740197104800785
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "4e4c805b-7e7f-4284-9186-383767aa7b39"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9508465370787,
                                40.740197104800785
                            ],
                            [
                                -73.94729348957283,
                                40.738379297753966
                            ],
                            [
                                -73.94795375154976,
                                40.73865470257419
                            ],
                            [
                                -73.9508676229643,
                                40.74018821873767
                            ],
                            [
                                -73.9508465370787,
                                40.740197104800785
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "dbb8803c-099c-4fdc-b4d0-b170e4d50f46"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94795375154976,
                                40.73865470257419
                            ],
                            [
                                -73.95124697215087,
                                40.74002835251814
                            ],
                            [
                                -73.9508676229643,
                                40.74018821873767
                            ],
                            [
                                -73.94795375154976,
                                40.73865470257419
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "32209be2-ea74-4b92-a72c-bfdeda3256f7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95124697215087,
                                40.74002835251814
                            ],
                            [
                                -73.94795375154976,
                                40.73865470257419
                            ],
                            [
                                -73.94742373450923,
                                40.7383757644939
                            ],
                            [
                                -73.95125862508024,
                                40.74002344171345
                            ],
                            [
                                -73.95124697215087,
                                40.74002835251814
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ceb01b27-16ca-4741-a798-55e8b0dcfc10"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95203785460932,
                                40.74035824103042
                            ],
                            [
                                -73.95124697215087,
                                40.74002835251814
                            ],
                            [
                                -73.95125862508024,
                                40.74002344171345
                            ],
                            [
                                -73.95203785460932,
                                40.74035824103042
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "1ed14c4e-c7a8-4252-bcb8-fd3baa7865a3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95244391443423,
                                40.73952393430765
                            ],
                            [
                                -73.95203785460932,
                                40.74035824103042
                            ],
                            [
                                -73.95125862508024,
                                40.74002344171345
                            ],
                            [
                                -73.95244391443423,
                                40.73952393430765
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "e64399ee-37f6-45e6-831c-1f3b46099802"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9503679203784,
                                40.733461722457655
                            ],
                            [
                                -73.95305458401755,
                                40.73826922820893
                            ],
                            [
                                -73.9474450919551,
                                40.73698758784124
                            ],
                            [
                                -73.95011024607939,
                                40.73656864516102
                            ],
                            [
                                -73.94946430874352,
                                40.732651964106665
                            ],
                            [
                                -73.9503679203784,
                                40.733461722457655
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9f38964f-876f-4f54-a2c6-aa0f62dbee9c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94927546232545,
                                40.73150688215088
                            ],
                            [
                                -73.9503679203784,
                                40.733461722457655
                            ],
                            [
                                -73.94946430874352,
                                40.732651964106665
                            ],
                            [
                                -73.94927546232545,
                                40.73150688215088
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "5e3f6e5f-a4c9-4537-9503-3fb8b6f78c00"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94927546232545,
                                40.73150688215088
                            ],
                            [
                                -73.94904671663366,
                                40.731097565475444
                            ],
                            [
                                -73.94915714434272,
                                40.73078945373239
                            ],
                            [
                                -73.94927546232545,
                                40.73150688215088
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "838ac268-ddc5-47ef-b488-89c3af7f809d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94904671663366,
                                40.731097565475444
                            ],
                            [
                                -73.94927546232545,
                                40.73150688215088
                            ],
                            [
                                -73.94946430874352,
                                40.732651964106665
                            ],
                            [
                                -73.94872656395255,
                                40.73199084490198
                            ],
                            [
                                -73.94904671663366,
                                40.731097565475444
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "75a19897-a5f1-4022-b137-ac4358b45590"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9438752454065,
                                40.72184375442714
                            ],
                            [
                                -73.94904671663366,
                                40.731097565475444
                            ],
                            [
                                -73.94872656395255,
                                40.73199084490198
                            ],
                            [
                                -73.94388634588849,
                                40.72765335427355
                            ],
                            [
                                -73.9323112480254,
                                40.72809204261121
                            ],
                            [
                                -73.93695151988003,
                                40.722576369527204
                            ],
                            [
                                -73.93289959600158,
                                40.72027296470528
                            ],
                            [
                                -73.93315492507361,
                                40.716879670283134
                            ],
                            [
                                -73.9438752454065,
                                40.72184375442714
                            ]
                        ],
                        [
                            [
                                -73.93961369519083,
                                40.72434078685879
                            ],
                            [
                                -73.94028942681216,
                                40.72246643927623
                            ],
                            [
                                -73.93611644329698,
                                40.72404254567288
                            ],
                            [
                                -73.93961369519083,
                                40.72434078685879
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "f099e10e-9101-4b68-885d-cb2ccf096918"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93315492507361,
                                40.716879670283134
                            ],
                            [
                                -73.93176326450141,
                                40.71623525668673
                            ],
                            [
                                -73.93333654556483,
                                40.714465954544075
                            ],
                            [
                                -73.93315492507361,
                                40.716879670283134
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "57c7d369-6743-4c9f-9e4e-ab550e54a83a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93176326450141,
                                40.71623525668673
                            ],
                            [
                                -73.93315492507361,
                                40.716879670283134
                            ],
                            [
                                -73.93289959600158,
                                40.72027296470528
                            ],
                            [
                                -73.92975995446507,
                                40.71848816674973
                            ],
                            [
                                -73.93176326450141,
                                40.71623525668673
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "13e7e08a-f2c6-4859-8e9c-bc1a7ffba2bb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93176326450141,
                                40.71623525668673
                            ],
                            [
                                -73.93042621612618,
                                40.71561613147096
                            ],
                            [
                                -73.93333654556483,
                                40.714465954544075
                            ],
                            [
                                -73.93176326450141,
                                40.71623525668673
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "272111cc-ad1e-4ad8-8c52-79ae0b2546f3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93042621612618,
                                40.71561613147096
                            ],
                            [
                                -73.92975461425496,
                                40.71530514373025
                            ],
                            [
                                -73.93333654556483,
                                40.714465954544075
                            ],
                            [
                                -73.93042621612618,
                                40.71561613147096
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "773b2f1d-6096-4ed6-8d7c-8bc93abe0ed5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93042621612618,
                                40.71561613147096
                            ],
                            [
                                -73.90029522302741,
                                40.72752405247334
                            ],
                            [
                                -73.89759702593436,
                                40.724820610937634
                            ],
                            [
                                -73.9013728150928,
                                40.722616777115576
                            ],
                            [
                                -73.90129426550604,
                                40.7155182631049
                            ],
                            [
                                -73.90111428946814,
                                40.71550211256166
                            ],
                            [
                                -73.90108776528008,
                                40.71477216286786
                            ],
                            [
                                -73.89971555151811,
                                40.71537659375372
                            ],
                            [
                                -73.89612412052293,
                                40.715054308841566
                            ],
                            [
                                -73.89504474356659,
                                40.71256475278263
                            ],
                            [
                                -73.88888336162788,
                                40.712358549556434
                            ],
                            [
                                -73.89500335728314,
                                40.71259574735367
                            ],
                            [
                                -73.89557970199638,
                                40.714097264821234
                            ],
                            [
                                -73.89456827733335,
                                40.714914691851476
                            ],
                            [
                                -73.8871605671861,
                                40.714249944786246
                            ],
                            [
                                -73.89244527739649,
                                40.71663048700361
                            ],
                            [
                                -73.89038012736775,
                                40.71829952822507
                            ],
                            [
                                -73.88731827743968,
                                40.71740752514865
                            ],
                            [
                                -73.88662127887665,
                                40.716531766298544
                            ],
                            [
                                -73.88554286024088,
                                40.716890296139894
                            ],
                            [
                                -73.87778751414403,
                                40.71463094550562
                            ],
                            [
                                -73.87826485994793,
                                40.71292006086752
                            ],
                            [
                                -73.87588249978278,
                                40.71407596119522
                            ],
                            [
                                -73.87287663551638,
                                40.71320026831807
                            ],
                            [
                                -73.88825732301143,
                                40.712191665370845
                            ],
                            [
                                -73.88172199847108,
                                40.71235671925073
                            ],
                            [
                                -73.88529174697884,
                                40.70706609797833
                            ],
                            [
                                -73.88900081731026,
                                40.70842712261048
                            ],
                            [
                                -73.8871903894569,
                                40.71054989495042
                            ],
                            [
                                -73.88833703950974,
                                40.712195338928744
                            ],
                            [
                                -73.90044132772016,
                                40.712625160145755
                            ],
                            [
                                -73.89360501566057,
                                40.70802299105055
                            ],
                            [
                                -73.8955501560525,
                                40.70668477064382
                            ],
                            [
                                -73.90423174277427,
                                40.70601970656662
                            ],
                            [
                                -73.91686045788386,
                                40.70756008422719
                            ],
                            [
                                -73.91765416017178,
                                40.708712802313194
                            ],
                            [
                                -73.91041218899603,
                                40.71309570789837
                            ],
                            [
                                -73.91454451585898,
                                40.714929628160405
                            ],
                            [
                                -73.91373769378735,
                                40.715767813790656
                            ],
                            [
                                -73.91523178183371,
                                40.71523463573562
                            ],
                            [
                                -73.9197082187625,
                                40.71722127151195
                            ],
                            [
                                -73.90937956300053,
                                40.72007869218811
                            ],
                            [
                                -73.92975461425496,
                                40.71530514373025
                            ],
                            [
                                -73.93042621612618,
                                40.71561613147096
                            ]
                        ],
                        [
                            [
                                -73.90839731183121,
                                40.71296514672962
                            ],
                            [
                                -73.91028951056927,
                                40.713085292021056
                            ],
                            [
                                -73.90973547192283,
                                40.71239647119148
                            ],
                            [
                                -73.90839731183121,
                                40.71296514672962
                            ]
                        ],
                        [
                            [
                                -73.9078550878274,
                                40.72053548480469
                            ],
                            [
                                -73.90748691690146,
                                40.7206592200806
                            ],
                            [
                                -73.90762367731864,
                                40.72063406808455
                            ],
                            [
                                -73.9078550878274,
                                40.72053548480469
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "70e86126-0c55-4556-878b-dfb3283c57be"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93468441913961,
                                40.713304270229315
                            ],
                            [
                                -73.9288838652977,
                                40.714901940191744
                            ],
                            [
                                -73.92975461425496,
                                40.71530514373025
                            ],
                            [
                                -73.90937956300053,
                                40.72007869218811
                            ],
                            [
                                -73.9197082187625,
                                40.71722127151195
                            ],
                            [
                                -73.91523178183371,
                                40.71523463573562
                            ],
                            [
                                -73.93261664401489,
                                40.70903069947666
                            ],
                            [
                                -73.93468441913961,
                                40.713304270229315
                            ]
                        ],
                        [
                            [
                                -73.92749776661094,
                                40.71518527206899
                            ],
                            [
                                -73.92681163878682,
                                40.71536663869904
                            ],
                            [
                                -73.92603345700228,
                                40.71557539001096
                            ],
                            [
                                -73.92749776661094,
                                40.71518527206899
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "43677396-6fec-4a33-a9ed-96492a8b1861"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93261664401489,
                                40.70903069947666
                            ],
                            [
                                -73.93223999451557,
                                40.70825225975901
                            ],
                            [
                                -73.93445246387313,
                                40.70837557151161
                            ],
                            [
                                -73.93261664401489,
                                40.70903069947666
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "a215a977-9f45-4ee5-b0c8-1828fa93b958"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93223999451557,
                                40.70825225975901
                            ],
                            [
                                -73.93261664401489,
                                40.70903069947666
                            ],
                            [
                                -73.91523178183371,
                                40.71523463573562
                            ],
                            [
                                -73.91454451585898,
                                40.714929628160405
                            ],
                            [
                                -73.91885289823293,
                                40.71045376622939
                            ],
                            [
                                -73.91968871454104,
                                40.71166764779382
                            ],
                            [
                                -73.93105271991605,
                                40.70818608713103
                            ],
                            [
                                -73.93223999451557,
                                40.70825225975901
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "2060b2c1-2888-4860-b081-a9fe2c419f0d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93105271991605,
                                40.70818608713103
                            ],
                            [
                                -73.93205883400697,
                                40.70787784658331
                            ],
                            [
                                -73.93223999451557,
                                40.70825225975901
                            ],
                            [
                                -73.93105271991605,
                                40.70818608713103
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "5b45fd9e-92ea-4783-9aa0-dee4e553119b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91968871454104,
                                40.71166764779382
                            ],
                            [
                                -73.91885289823293,
                                40.71045376622939
                            ],
                            [
                                -73.91943801091492,
                                40.70984590848805
                            ],
                            [
                                -73.92129351077867,
                                40.705690107696356
                            ],
                            [
                                -73.920029909059,
                                40.704809467791364
                            ],
                            [
                                -73.91522022232735,
                                40.705177920007564
                            ],
                            [
                                -73.91365601087689,
                                40.70290616792025
                            ],
                            [
                                -73.90617055911682,
                                40.70176671371434
                            ],
                            [
                                -73.90352687102309,
                                40.699239200818596
                            ],
                            [
                                -73.90490839899815,
                                40.699262931732456
                            ],
                            [
                                -73.90685881126156,
                                40.69725170815055
                            ],
                            [
                                -73.90235230830869,
                                40.698116253518364
                            ],
                            [
                                -73.90105818021493,
                                40.69687899506359
                            ],
                            [
                                -73.90071088249253,
                                40.69347542736423
                            ],
                            [
                                -73.90651558744938,
                                40.696893732466734
                            ],
                            [
                                -73.9076364756165,
                                40.69516919721833
                            ],
                            [
                                -73.91261258713166,
                                40.69545703264896
                            ],
                            [
                                -73.91613781076684,
                                40.6930502094881
                            ],
                            [
                                -73.91376552856192,
                                40.69127791899819
                            ],
                            [
                                -73.92574468726599,
                                40.69520083262076
                            ],
                            [
                                -73.91955858908057,
                                40.69204745464828
                            ],
                            [
                                -73.92078687712811,
                                40.68688341600033
                            ],
                            [
                                -73.9253863223497,
                                40.69129103383992
                            ],
                            [
                                -73.9302342837997,
                                40.6882398089659
                            ],
                            [
                                -73.93115195328663,
                                40.69287864532601
                            ],
                            [
                                -73.93743872519612,
                                40.69421366880238
                            ],
                            [
                                -73.93738662787105,
                                40.695169159758215
                            ],
                            [
                                -73.92439240745472,
                                40.69818119366863
                            ],
                            [
                                -73.94013225670723,
                                40.70295085970945
                            ],
                            [
                                -73.93948522891867,
                                40.706169657989776
                            ],
                            [
                                -73.92887465666773,
                                40.70241031913789
                            ],
                            [
                                -73.92968693760214,
                                40.70810996539392
                            ],
                            [
                                -73.93105271991605,
                                40.70818608713103
                            ],
                            [
                                -73.91968871454104,
                                40.71166764779382
                            ]
                        ],
                        [
                            [
                                -73.92169169980814,
                                40.68999174693641
                            ],
                            [
                                -73.92281821412388,
                                40.689931955915874
                            ],
                            [
                                -73.9227894330005,
                                40.689415798743454
                            ],
                            [
                                -73.92150413876334,
                                40.68928775364988
                            ],
                            [
                                -73.92169169980814,
                                40.68999174693641
                            ]
                        ],
                        [
                            [
                                -73.93104105536649,
                                40.69296238786161
                            ],
                            [
                                -73.92729414099392,
                                40.69247804206734
                            ],
                            [
                                -73.9276221832778,
                                40.6942415297674
                            ],
                            [
                                -73.93104105536649,
                                40.69296238786161
                            ]
                        ],
                        [
                            [
                                -73.92282308838261,
                                40.69827298921953
                            ],
                            [
                                -73.92288185796514,
                                40.698336547874604
                            ],
                            [
                                -73.9243671090997,
                                40.69811867416732
                            ],
                            [
                                -73.92420450145687,
                                40.69796599399342
                            ],
                            [
                                -73.92282308838261,
                                40.69827298921953
                            ]
                        ],
                        [
                            [
                                -73.91992158930371,
                                40.69865106094883
                            ],
                            [
                                -73.91971256840509,
                                40.69867243729568
                            ],
                            [
                                -73.91951768878567,
                                40.69869246241852
                            ],
                            [
                                -73.91992158930371,
                                40.69865106094883
                            ]
                        ],
                        [
                            [
                                -73.92785339346224,
                                40.701831722867155
                            ],
                            [
                                -73.92804205404497,
                                40.70195394561886
                            ],
                            [
                                -73.92824455856451,
                                40.70205561216196
                            ],
                            [
                                -73.92785339346224,
                                40.701831722867155
                            ]
                        ],
                        [
                            [
                                -73.93343308032189,
                                40.70279536958278
                            ],
                            [
                                -73.93352304120769,
                                40.70276106071517
                            ],
                            [
                                -73.93375729668097,
                                40.70266790999723
                            ],
                            [
                                -73.93341197260764,
                                40.702697272693186
                            ],
                            [
                                -73.93343308032189,
                                40.70279536958278
                            ]
                        ],
                        [
                            [
                                -73.92319809908113,
                                40.70623604471043
                            ],
                            [
                                -73.9224865749856,
                                40.7063419485774
                            ],
                            [
                                -73.9234839951068,
                                40.70696237186857
                            ],
                            [
                                -73.92319809908113,
                                40.70623604471043
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "f27ce0d0-f830-46b6-9a80-3edbd125c455"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91885289823293,
                                40.71045376622939
                            ],
                            [
                                -73.91765416017178,
                                40.708712802313194
                            ],
                            [
                                -73.9191062109782,
                                40.707834008206596
                            ],
                            [
                                -73.91686045788386,
                                40.70756008422719
                            ],
                            [
                                -73.91522022232735,
                                40.705177920007564
                            ],
                            [
                                -73.920029909059,
                                40.704809467791364
                            ],
                            [
                                -73.92129351077867,
                                40.705690107696356
                            ],
                            [
                                -73.91943801091492,
                                40.70984590848805
                            ],
                            [
                                -73.91885289823293,
                                40.71045376622939
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "bdd63656-ec89-46df-8890-91d46d3a6231"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91765416017178,
                                40.708712802313194
                            ],
                            [
                                -73.91885289823293,
                                40.71045376622939
                            ],
                            [
                                -73.91454451585898,
                                40.714929628160405
                            ],
                            [
                                -73.91041218899603,
                                40.71309570789837
                            ],
                            [
                                -73.91765416017178,
                                40.708712802313194
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "c0b0cc29-3b2a-4052-aec4-c1b05b396e26"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91765416017178,
                                40.708712802313194
                            ],
                            [
                                -73.91686045788386,
                                40.70756008422719
                            ],
                            [
                                -73.9191062109782,
                                40.707834008206596
                            ],
                            [
                                -73.91765416017178,
                                40.708712802313194
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "eeef223c-a60f-4629-8d6b-178efc3276b8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91522022232735,
                                40.705177920007564
                            ],
                            [
                                -73.91686045788386,
                                40.70756008422719
                            ],
                            [
                                -73.90423174277427,
                                40.70601970656662
                            ],
                            [
                                -73.91522022232735,
                                40.705177920007564
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "bcb3bc9f-b4d2-45b6-aaa0-f4615ffc0f06"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90617055911682,
                                40.70176671371434
                            ],
                            [
                                -73.91365601087689,
                                40.70290616792025
                            ],
                            [
                                -73.91522022232735,
                                40.705177920007564
                            ],
                            [
                                -73.90423174277427,
                                40.70601970656662
                            ],
                            [
                                -73.90062232118193,
                                40.7055794501874
                            ],
                            [
                                -73.90298449124761,
                                40.70436824544534
                            ],
                            [
                                -73.90307692906823,
                                40.704422958033604
                            ],
                            [
                                -73.90302125962035,
                                40.704349392430444
                            ],
                            [
                                -73.90682951091193,
                                40.7023967082904
                            ],
                            [
                                -73.90617055911682,
                                40.70176671371434
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "ef84e6a4-7ae9-40c0-9100-9642ebc4bda2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90617055911682,
                                40.70176671371434
                            ],
                            [
                                -73.90091821846637,
                                40.700967189271886
                            ],
                            [
                                -73.90175009044412,
                                40.69920868053408
                            ],
                            [
                                -73.90352687102309,
                                40.699239200818596
                            ],
                            [
                                -73.90617055911682,
                                40.70176671371434
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "5c0834a3-59ff-4105-ad6d-8ef22ecc8ee5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90617055911682,
                                40.70176671371434
                            ],
                            [
                                -73.90682951091193,
                                40.7023967082904
                            ],
                            [
                                -73.90302125962035,
                                40.704349392430444
                            ],
                            [
                                -73.9028679135595,
                                40.704146749941835
                            ],
                            [
                                -73.90277036819774,
                                40.70424150915725
                            ],
                            [
                                -73.90298449124761,
                                40.70436824544534
                            ],
                            [
                                -73.90062232118193,
                                40.7055794501874
                            ],
                            [
                                -73.90423174277427,
                                40.70601970656662
                            ],
                            [
                                -73.8955501560525,
                                40.70668477064382
                            ],
                            [
                                -73.89656226128785,
                                40.70598846106877
                            ],
                            [
                                -73.89247074997449,
                                40.705924167301085
                            ],
                            [
                                -73.89360142938274,
                                40.70235076536017
                            ],
                            [
                                -73.89486374112168,
                                40.70411003919659
                            ],
                            [
                                -73.89974121761865,
                                40.70345527197031
                            ],
                            [
                                -73.90091821846637,
                                40.700967189271886
                            ],
                            [
                                -73.90617055911682,
                                40.70176671371434
                            ]
                        ],
                        [
                            [
                                -73.89502460064298,
                                40.70467420512396
                            ],
                            [
                                -73.89432102214614,
                                40.705854146682356
                            ],
                            [
                                -73.89762948859148,
                                40.705523573850286
                            ],
                            [
                                -73.89502460064298,
                                40.70467420512396
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "e6ea18d1-670d-44cf-8d02-c4d2dedf985c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90091821846637,
                                40.700967189271886
                            ],
                            [
                                -73.89803345199158,
                                40.700528062897405
                            ],
                            [
                                -73.89666413109796,
                                40.69421505124084
                            ],
                            [
                                -73.89683191128162,
                                40.69418860876071
                            ],
                            [
                                -73.89662666457473,
                                40.69404231846574
                            ],
                            [
                                -73.89651778210487,
                                40.69354033510698
                            ],
                            [
                                -73.89872896621185,
                                40.69327505085635
                            ],
                            [
                                -73.89981892278905,
                                40.69350356538964
                            ],
                            [
                                -73.89974186119265,
                                40.6931535299549
                            ],
                            [
                                -73.9020778299437,
                                40.69287327481121
                            ],
                            [
                                -73.89808729880635,
                                40.69403866715586
                            ],
                            [
                                -73.90105818021493,
                                40.69687899506359
                            ],
                            [
                                -73.90120685224237,
                                40.69833600234501
                            ],
                            [
                                -73.90235230830869,
                                40.698116253518364
                            ],
                            [
                                -73.90352687102309,
                                40.699239200818596
                            ],
                            [
                                -73.90175009044412,
                                40.69920868053408
                            ],
                            [
                                -73.90091821846637,
                                40.700967189271886
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "0b9d5360-c3a4-48cb-9087-30b6dc705141"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89666413109796,
                                40.69421505124084
                            ],
                            [
                                -73.89803345199158,
                                40.700528062897405
                            ],
                            [
                                -73.90091821846637,
                                40.700967189271886
                            ],
                            [
                                -73.89974121761865,
                                40.70345527197031
                            ],
                            [
                                -73.89486374112168,
                                40.70411003919659
                            ],
                            [
                                -73.89360142938274,
                                40.70235076536017
                            ],
                            [
                                -73.89247074997449,
                                40.705924167301085
                            ],
                            [
                                -73.89043990411272,
                                40.70589225470888
                            ],
                            [
                                -73.88497189267568,
                                40.70221121835061
                            ],
                            [
                                -73.8854397803167,
                                40.701435942689116
                            ],
                            [
                                -73.8914369297225,
                                40.70033495762331
                            ],
                            [
                                -73.88987927213766,
                                40.69528435808211
                            ],
                            [
                                -73.89015848450613,
                                40.69524035367502
                            ],
                            [
                                -73.8930019862886,
                                40.695491932377976
                            ],
                            [
                                -73.89638251109444,
                                40.69443228996723
                            ],
                            [
                                -73.8928537750465,
                                40.69495463485393
                            ],
                            [
                                -73.8923679967374,
                                40.69489213029136
                            ],
                            [
                                -73.89666413109796,
                                40.69421505124084
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "dd6f95e3-31e0-4cc0-8071-5a042d42b380"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89666413109796,
                                40.69421505124084
                            ],
                            [
                                -73.89665585039339,
                                40.69417687451781
                            ],
                            [
                                -73.89683191128162,
                                40.69418860876071
                            ],
                            [
                                -73.89666413109796,
                                40.69421505124084
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "7654524b-cc98-4d3b-8f41-4d232290bb99"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89665585039339,
                                40.69417687451781
                            ],
                            [
                                -73.89666413109796,
                                40.69421505124084
                            ],
                            [
                                -73.8923679967374,
                                40.69489213029136
                            ],
                            [
                                -73.89012518189686,
                                40.69460354976418
                            ],
                            [
                                -73.89040044276791,
                                40.6937599592491
                            ],
                            [
                                -73.89665585039339,
                                40.69417687451781
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b4042ab1-54b8-4627-bcc7-9cea27e2e3df"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89665585039339,
                                40.69417687451781
                            ],
                            [
                                -73.89663735459713,
                                40.694091602922306
                            ],
                            [
                                -73.89683191128162,
                                40.69418860876071
                            ],
                            [
                                -73.89665585039339,
                                40.69417687451781
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "29bd1e8c-4117-428d-a928-6df2e3902e8d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89663735459713,
                                40.694091602922306
                            ],
                            [
                                -73.89662666457473,
                                40.69404231846574
                            ],
                            [
                                -73.89683191128162,
                                40.69418860876071
                            ],
                            [
                                -73.89663735459713,
                                40.694091602922306
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "8e535a6c-b6ba-43ed-8c12-4592e9882500"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89662666457473,
                                40.69404231846574
                            ],
                            [
                                -73.89663735459713,
                                40.694091602922306
                            ],
                            [
                                -73.89262679804119,
                                40.69209194198953
                            ],
                            [
                                -73.89479635489896,
                                40.69273775896077
                            ],
                            [
                                -73.89662666457473,
                                40.69404231846574
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "2184716a-2755-49cf-b8a4-f0ba20267a4f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89872896621185,
                                40.69327505085635
                            ],
                            [
                                -73.89651778210487,
                                40.69354033510698
                            ],
                            [
                                -73.89662666457473,
                                40.69404231846574
                            ],
                            [
                                -73.89479635489896,
                                40.69273775896077
                            ],
                            [
                                -73.89262679804119,
                                40.69209194198953
                            ],
                            [
                                -73.8922937257725,
                                40.69192587236947
                            ],
                            [
                                -73.89380885132638,
                                40.692243525582505
                            ],
                            [
                                -73.8958100866291,
                                40.69313693236224
                            ],
                            [
                                -73.89586726524365,
                                40.6926750817614
                            ],
                            [
                                -73.89872896621185,
                                40.69327505085635
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "60cefc79-8964-49e7-a0f4-8a7404826ebd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89872896621185,
                                40.69327505085635
                            ],
                            [
                                -73.89974186119265,
                                40.6931535299549
                            ],
                            [
                                -73.89981892278905,
                                40.69350356538964
                            ],
                            [
                                -73.89872896621185,
                                40.69327505085635
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "efeac6ae-1725-4a0e-95a0-740fcccc2f79"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89974186119265,
                                40.6931535299549
                            ],
                            [
                                -73.89872896621185,
                                40.69327505085635
                            ],
                            [
                                -73.89586726524365,
                                40.6926750817614
                            ],
                            [
                                -73.89703074670462,
                                40.683277256786624
                            ],
                            [
                                -73.89762287349298,
                                40.68352849212346
                            ],
                            [
                                -73.89974186119265,
                                40.6931535299549
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b935c159-a6e0-4243-90a9-cd117f818933"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9020778299437,
                                40.69287327481121
                            ],
                            [
                                -73.89974186119265,
                                40.6931535299549
                            ],
                            [
                                -73.89762287349298,
                                40.68352849212346
                            ],
                            [
                                -73.90444756730524,
                                40.68642416291956
                            ],
                            [
                                -73.9030850935438,
                                40.687303601018634
                            ],
                            [
                                -73.90308479831012,
                                40.68729701630853
                            ],
                            [
                                -73.90307747540427,
                                40.687308518311234
                            ],
                            [
                                -73.90277135158756,
                                40.68750611253658
                            ],
                            [
                                -73.90074883272003,
                                40.68811356329457
                            ],
                            [
                                -73.90095558424841,
                                40.68867813876
                            ],
                            [
                                -73.90078108313705,
                                40.68879077426903
                            ],
                            [
                                -73.90117965831011,
                                40.68929001678899
                            ],
                            [
                                -73.90158298314003,
                                40.69039137408176
                            ],
                            [
                                -73.90227486467354,
                                40.69066183726782
                            ],
                            [
                                -73.90318186319355,
                                40.689692406599406
                            ],
                            [
                                -73.90319225837767,
                                40.68969373945671
                            ],
                            [
                                -73.90319172596857,
                                40.689681864932176
                            ],
                            [
                                -73.90514181278786,
                                40.687597546240404
                            ],
                            [
                                -73.90637531102618,
                                40.68748341032628
                            ],
                            [
                                -73.90220821993043,
                                40.69283519579689
                            ],
                            [
                                -73.9020778299437,
                                40.69287327481121
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "1021c2dd-9f88-43e7-abd5-02efbda749be"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9020778299437,
                                40.69287327481121
                            ],
                            [
                                -73.90218895069809,
                                40.69285994322749
                            ],
                            [
                                -73.90220821993043,
                                40.69283519579689
                            ],
                            [
                                -73.91083112821026,
                                40.69031696678126
                            ],
                            [
                                -73.91376552856192,
                                40.69127791899819
                            ],
                            [
                                -73.91613781076684,
                                40.6930502094881
                            ],
                            [
                                -73.91261258713166,
                                40.69545703264896
                            ],
                            [
                                -73.9076364756165,
                                40.69516919721833
                            ],
                            [
                                -73.90651558744938,
                                40.696893732466734
                            ],
                            [
                                -73.90071088249253,
                                40.69347542736423
                            ],
                            [
                                -73.90105818021493,
                                40.69687899506359
                            ],
                            [
                                -73.89808729880635,
                                40.69403866715586
                            ],
                            [
                                -73.9020778299437,
                                40.69287327481121
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "262a828c-b972-4312-8fd9-12bbbd71d2b1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90220821993043,
                                40.69283519579689
                            ],
                            [
                                -73.90218895069809,
                                40.69285994322749
                            ],
                            [
                                -73.9020778299437,
                                40.69287327481121
                            ],
                            [
                                -73.90220821993043,
                                40.69283519579689
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "fabef8bf-bae1-4026-9c05-feb8612534a6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90637531102618,
                                40.68748341032628
                            ],
                            [
                                -73.90514181278786,
                                40.687597546240404
                            ],
                            [
                                -73.9061515460788,
                                40.6865183091771
                            ],
                            [
                                -73.90456841990077,
                                40.6863461560031
                            ],
                            [
                                -73.90444756730524,
                                40.68642416291956
                            ],
                            [
                                -73.89762287349298,
                                40.68352849212346
                            ],
                            [
                                -73.89740936182099,
                                40.68255866208588
                            ],
                            [
                                -73.8971091893471,
                                40.68264364959176
                            ],
                            [
                                -73.89713371691536,
                                40.68244553230216
                            ],
                            [
                                -73.89908307893165,
                                40.680765573144285
                            ],
                            [
                                -73.9001205340742,
                                40.68108398257891
                            ],
                            [
                                -73.91376552856192,
                                40.69127791899819
                            ],
                            [
                                -73.91083112821026,
                                40.69031696678126
                            ],
                            [
                                -73.90220821993043,
                                40.69283519579689
                            ],
                            [
                                -73.90637531102618,
                                40.68748341032628
                            ]
                        ],
                        [
                            [
                                -73.90597957709238,
                                40.68937579059566
                            ],
                            [
                                -73.90595805435649,
                                40.689396945819546
                            ],
                            [
                                -73.90599203313211,
                                40.68938298833132
                            ],
                            [
                                -73.90597957709238,
                                40.68937579059566
                            ]
                        ],
                        [
                            [
                                -73.90719736383426,
                                40.690195552311984
                            ],
                            [
                                -73.90738373820456,
                                40.69018133615631
                            ],
                            [
                                -73.90673858703212,
                                40.689813248307644
                            ],
                            [
                                -73.90719736383426,
                                40.690195552311984
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "6b232aa4-4814-4e38-b76a-87772783b757"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90514181278786,
                                40.687597546240404
                            ],
                            [
                                -73.90340538637376,
                                40.68775821822998
                            ],
                            [
                                -73.90401308588369,
                                40.688660620466734
                            ],
                            [
                                -73.90349440133359,
                                40.68789759816406
                            ],
                            [
                                -73.90315435273058,
                                40.688848315255115
                            ],
                            [
                                -73.90308988395297,
                                40.687410443354004
                            ],
                            [
                                -73.90524591042342,
                                40.686762894431354
                            ],
                            [
                                -73.90444756730524,
                                40.68642416291956
                            ],
                            [
                                -73.90456841990077,
                                40.6863461560031
                            ],
                            [
                                -73.9061515460788,
                                40.6865183091771
                            ],
                            [
                                -73.90514181278786,
                                40.687597546240404
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "21db5787-092e-4448-9731-850784f87c07"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90401308588369,
                                40.688660620466734
                            ],
                            [
                                -73.90340538637376,
                                40.68775821822998
                            ],
                            [
                                -73.90514181278786,
                                40.687597546240404
                            ],
                            [
                                -73.90319172596857,
                                40.689681864932176
                            ],
                            [
                                -73.90319161766149,
                                40.68967944931766
                            ],
                            [
                                -73.90404596152815,
                                40.688763938672
                            ],
                            [
                                -73.90401308588369,
                                40.688660620466734
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "fb961ec8-597a-4e35-aec6-e7c29db23951"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90401308588369,
                                40.688660620466734
                            ],
                            [
                                -73.90404596152815,
                                40.688763938672
                            ],
                            [
                                -73.90319161766149,
                                40.68967944931766
                            ],
                            [
                                -73.90315971765726,
                                40.68896797127477
                            ],
                            [
                                -73.90401308588369,
                                40.688660620466734
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "99bcba35-99c6-4c3a-96c9-9af7038f1a19"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90319161766149,
                                40.68967944931766
                            ],
                            [
                                -73.90317977583432,
                                40.68969213896086
                            ],
                            [
                                -73.90168216433392,
                                40.689500117116864
                            ],
                            [
                                -73.90299430752347,
                                40.68743914913049
                            ],
                            [
                                -73.90308988395297,
                                40.687410443354004
                            ],
                            [
                                -73.90315435273058,
                                40.688848315255115
                            ],
                            [
                                -73.90310443278729,
                                40.68898788278417
                            ],
                            [
                                -73.90315971765726,
                                40.68896797127477
                            ],
                            [
                                -73.90319161766149,
                                40.68967944931766
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "15046615-9d06-4061-a103-bfd0e98a7e46"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90317977583432,
                                40.68969213896086
                            ],
                            [
                                -73.90319161766149,
                                40.68967944931766
                            ],
                            [
                                -73.90319172596857,
                                40.689681864932176
                            ],
                            [
                                -73.90318186319355,
                                40.689692406599406
                            ],
                            [
                                -73.90317977583432,
                                40.68969213896086
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "5ce4a233-b9d2-4de7-b259-f3680e787ceb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90317977583432,
                                40.68969213896086
                            ],
                            [
                                -73.90227486467354,
                                40.69066183726782
                            ],
                            [
                                -73.90117965831011,
                                40.68929001678899
                            ],
                            [
                                -73.90095558424841,
                                40.68867813876
                            ],
                            [
                                -73.90277135158756,
                                40.68750611253658
                            ],
                            [
                                -73.90299430752347,
                                40.68743914913049
                            ],
                            [
                                -73.90168216433392,
                                40.689500117116864
                            ],
                            [
                                -73.90317977583432,
                                40.68969213896086
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "6d44c48b-98e3-4241-945e-5ac8576de59f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90227486467354,
                                40.69066183726782
                            ],
                            [
                                -73.90317977583432,
                                40.68969213896086
                            ],
                            [
                                -73.90318186319355,
                                40.689692406599406
                            ],
                            [
                                -73.90227486467354,
                                40.69066183726782
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "57a4912f-9788-4b41-a895-e993d693f371"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90227486467354,
                                40.69066183726782
                            ],
                            [
                                -73.90158298314003,
                                40.69039137408176
                            ],
                            [
                                -73.90117965831011,
                                40.68929001678899
                            ],
                            [
                                -73.90227486467354,
                                40.69066183726782
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f889f673-1758-4b87-829a-8f388ddbeeb5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90095558424841,
                                40.68867813876
                            ],
                            [
                                -73.90117965831011,
                                40.68929001678899
                            ],
                            [
                                -73.90078108313705,
                                40.68879077426903
                            ],
                            [
                                -73.90095558424841,
                                40.68867813876
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "49960b2e-bb02-4f9c-bb8a-222e1b68bc53"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90095558424841,
                                40.68867813876
                            ],
                            [
                                -73.90074883272003,
                                40.68811356329457
                            ],
                            [
                                -73.90277135158756,
                                40.68750611253658
                            ],
                            [
                                -73.90095558424841,
                                40.68867813876
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "7d7d52f8-3baa-4d23-9bf3-b2e30c224344"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90299430752347,
                                40.68743914913049
                            ],
                            [
                                -73.90277135158756,
                                40.68750611253658
                            ],
                            [
                                -73.90307747540427,
                                40.687308518311234
                            ],
                            [
                                -73.90299430752347,
                                40.68743914913049
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "f8b692af-fbc5-461b-8818-c6a84e883c1a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90308988395297,
                                40.687410443354004
                            ],
                            [
                                -73.90299430752347,
                                40.68743914913049
                            ],
                            [
                                -73.90307747540427,
                                40.687308518311234
                            ],
                            [
                                -73.9030850935438,
                                40.687303601018634
                            ],
                            [
                                -73.90308988395297,
                                40.687410443354004
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "94aa8109-0084-4bea-a04c-9d2bcb9c9b78"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90444756730524,
                                40.68642416291956
                            ],
                            [
                                -73.90524591042342,
                                40.686762894431354
                            ],
                            [
                                -73.90308988395297,
                                40.687410443354004
                            ],
                            [
                                -73.9030850935438,
                                40.687303601018634
                            ],
                            [
                                -73.90444756730524,
                                40.68642416291956
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "34f0eb65-9bc9-4d18-90ef-1e769e2ccf4d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89762287349298,
                                40.68352849212346
                            ],
                            [
                                -73.89703074670462,
                                40.683277256786624
                            ],
                            [
                                -73.8971091893471,
                                40.68264364959176
                            ],
                            [
                                -73.89740936182099,
                                40.68255866208588
                            ],
                            [
                                -73.89762287349298,
                                40.68352849212346
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "4248f0e8-8e1f-4692-ac0f-114857ed55c7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89703074670462,
                                40.683277256786624
                            ],
                            [
                                -73.89650272708667,
                                40.683053221682705
                            ],
                            [
                                -73.8965562748795,
                                40.682943171544224
                            ],
                            [
                                -73.89680335135199,
                                40.682730241170496
                            ],
                            [
                                -73.8971091893471,
                                40.68264364959176
                            ],
                            [
                                -73.89703074670462,
                                40.683277256786624
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "56a59d42-1dfe-4cd5-91a1-bb29f6e0e596"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89650272708667,
                                40.683053221682705
                            ],
                            [
                                -73.89645303962403,
                                40.68303213963296
                            ],
                            [
                                -73.8965562748795,
                                40.682943171544224
                            ],
                            [
                                -73.89650272708667,
                                40.683053221682705
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "75487866-90e4-4aef-9255-f4c3de6f6296"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8965562748795,
                                40.682943171544224
                            ],
                            [
                                -73.89645303962403,
                                40.68303213963296
                            ],
                            [
                                -73.89650272708667,
                                40.683053221682705
                            ],
                            [
                                -73.89570138149656,
                                40.68470012790433
                            ],
                            [
                                -73.89460089227292,
                                40.685716439994906
                            ],
                            [
                                -73.89733621034316,
                                40.68080992584278
                            ],
                            [
                                -73.89713371691536,
                                40.68244553230216
                            ],
                            [
                                -73.89680335135199,
                                40.682730241170496
                            ],
                            [
                                -73.89663695889867,
                                40.682777351684734
                            ],
                            [
                                -73.8965562748795,
                                40.682943171544224
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "113acf52-98b7-48df-b351-d5a2595b8286"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89680335135199,
                                40.682730241170496
                            ],
                            [
                                -73.8965562748795,
                                40.682943171544224
                            ],
                            [
                                -73.89663695889867,
                                40.682777351684734
                            ],
                            [
                                -73.89680335135199,
                                40.682730241170496
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "629a14be-16ca-44d7-9efa-7926570822c8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89680335135199,
                                40.682730241170496
                            ],
                            [
                                -73.89713371691536,
                                40.68244553230216
                            ],
                            [
                                -73.8971091893471,
                                40.68264364959176
                            ],
                            [
                                -73.89680335135199,
                                40.682730241170496
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "535b6b1e-d1e9-4c07-9d14-ce60c79ac163"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89908307893165,
                                40.680765573144285
                            ],
                            [
                                -73.89713371691536,
                                40.68244553230216
                            ],
                            [
                                -73.89733621034316,
                                40.68080992584278
                            ],
                            [
                                -73.89743508430549,
                                40.68063256932226
                            ],
                            [
                                -73.89800483715649,
                                40.68043464572397
                            ],
                            [
                                -73.89908307893165,
                                40.680765573144285
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "46033413-fc80-4835-a0f1-d632ee72370d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89908307893165,
                                40.680765573144285
                            ],
                            [
                                -73.89936691276469,
                                40.68052096529662
                            ],
                            [
                                -73.9001205340742,
                                40.68108398257891
                            ],
                            [
                                -73.89908307893165,
                                40.680765573144285
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "268960fa-d30f-4dc6-8098-9abcb260ecc0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89936691276469,
                                40.68052096529662
                            ],
                            [
                                -73.89908307893165,
                                40.680765573144285
                            ],
                            [
                                -73.89800483715649,
                                40.68043464572397
                            ],
                            [
                                -73.89833038917166,
                                40.68032155383481
                            ],
                            [
                                -73.89921193684339,
                                40.68040518549988
                            ],
                            [
                                -73.89936691276469,
                                40.68052096529662
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "7d7fd53d-00c7-4776-934d-fe2062f53790"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89936691276469,
                                40.68052096529662
                            ],
                            [
                                -73.8994725683327,
                                40.680429911383825
                            ],
                            [
                                -73.89978098072982,
                                40.68045917020116
                            ],
                            [
                                -73.90098515881002,
                                40.681349347954246
                            ],
                            [
                                -73.9001205340742,
                                40.68108398257891
                            ],
                            [
                                -73.89936691276469,
                                40.68052096529662
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "9cc366e8-2a40-4d4a-92d4-131b7e0eff22"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8994725683327,
                                40.680429911383825
                            ],
                            [
                                -73.89936691276469,
                                40.68052096529662
                            ],
                            [
                                -73.89921193684339,
                                40.68040518549988
                            ],
                            [
                                -73.8994725683327,
                                40.680429911383825
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "7fb24160-e6d4-4f93-bc07-7fa01206acdd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8994725683327,
                                40.680429911383825
                            ],
                            [
                                -73.89959669527794,
                                40.68032293884786
                            ],
                            [
                                -73.89978098072982,
                                40.68045917020116
                            ],
                            [
                                -73.8994725683327,
                                40.680429911383825
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "fdb45a86-0c09-41d9-ab71-7ca7d15c9d01"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89959669527794,
                                40.68032293884786
                            ],
                            [
                                -73.8994725683327,
                                40.680429911383825
                            ],
                            [
                                -73.89921193684339,
                                40.68040518549988
                            ],
                            [
                                -73.89885571967712,
                                40.680139061893335
                            ],
                            [
                                -73.89919059346863,
                                40.68002273175883
                            ],
                            [
                                -73.89959669527794,
                                40.68032293884786
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "4eaa79bc-ac50-41c3-9b09-4b7c8ccca423"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89959669527794,
                                40.68032293884786
                            ],
                            [
                                -73.89985279517347,
                                40.680102232092906
                            ],
                            [
                                -73.90233126571519,
                                40.68070111353172
                            ],
                            [
                                -73.89978098072982,
                                40.68045917020116
                            ],
                            [
                                -73.89959669527794,
                                40.68032293884786
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "fcfc97dc-3526-4c76-84ab-1ff060962980"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89985279517347,
                                40.680102232092906
                            ],
                            [
                                -73.89959669527794,
                                40.68032293884786
                            ],
                            [
                                -73.89919059346863,
                                40.68002273175883
                            ],
                            [
                                -73.89885571967712,
                                40.680139061893335
                            ],
                            [
                                -73.89880521479786,
                                40.680101330582524
                            ],
                            [
                                -73.89929965738344,
                                40.67996857548897
                            ],
                            [
                                -73.89985279517347,
                                40.680102232092906
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "ad3094d3-b099-4684-bb6b-188bb895348a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89929965738344,
                                40.67996857548897
                            ],
                            [
                                -73.90032838703107,
                                40.67969236728078
                            ],
                            [
                                -73.89985279517347,
                                40.680102232092906
                            ],
                            [
                                -73.89929965738344,
                                40.67996857548897
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "32b7f972-8d76-464e-bdc2-c6e18a5a9569"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89929965738344,
                                40.67996857548897
                            ],
                            [
                                -73.89880521479786,
                                40.680101330582524
                            ],
                            [
                                -73.89830619518703,
                                40.67972852177052
                            ],
                            [
                                -73.89929965738344,
                                40.67996857548897
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "f2c9c138-a251-4d51-b382-8263764aaa55"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89880521479786,
                                40.680101330582524
                            ],
                            [
                                -73.89807517592078,
                                40.68029734197445
                            ],
                            [
                                -73.8972869888214,
                                40.68022256734612
                            ],
                            [
                                -73.89633449945205,
                                40.68076470395783
                            ],
                            [
                                -73.88579222104543,
                                40.68359524727468
                            ],
                            [
                                -73.89564696335327,
                                40.679085962353355
                            ],
                            [
                                -73.89830619518703,
                                40.67972852177052
                            ],
                            [
                                -73.89880521479786,
                                40.680101330582524
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "3445d6ce-4813-4c03-b427-021da3f6f006"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89807517592078,
                                40.68029734197445
                            ],
                            [
                                -73.89880521479786,
                                40.680101330582524
                            ],
                            [
                                -73.89885571967712,
                                40.680139061893335
                            ],
                            [
                                -73.89833038917166,
                                40.68032155383481
                            ],
                            [
                                -73.89807517592078,
                                40.68029734197445
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "cf68e091-fc04-4308-8116-12e25d564b4c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89807517592078,
                                40.68029734197445
                            ],
                            [
                                -73.89779903865941,
                                40.680371483298515
                            ],
                            [
                                -73.89761254546688,
                                40.68031424593995
                            ],
                            [
                                -73.89754219083909,
                                40.68044044551458
                            ],
                            [
                                -73.89633449945205,
                                40.68076470395783
                            ],
                            [
                                -73.8972869888214,
                                40.68022256734612
                            ],
                            [
                                -73.89807517592078,
                                40.68029734197445
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "21212ace-f3d0-4722-bde4-843a7b1fe546"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89779903865941,
                                40.680371483298515
                            ],
                            [
                                -73.89807517592078,
                                40.68029734197445
                            ],
                            [
                                -73.89833038917166,
                                40.68032155383481
                            ],
                            [
                                -73.89800483715649,
                                40.68043464572397
                            ],
                            [
                                -73.89779903865941,
                                40.680371483298515
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1b46b7cb-d52d-4218-962c-8e06a3b358bd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89779903865941,
                                40.680371483298515
                            ],
                            [
                                -73.89754219083909,
                                40.68044044551458
                            ],
                            [
                                -73.89761254546688,
                                40.68031424593995
                            ],
                            [
                                -73.89779903865941,
                                40.680371483298515
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f9695b83-2d84-4047-83ee-e3082c616987"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89754219083909,
                                40.68044044551458
                            ],
                            [
                                -73.89779903865941,
                                40.680371483298515
                            ],
                            [
                                -73.89800483715649,
                                40.68043464572397
                            ],
                            [
                                -73.89743508430549,
                                40.68063256932226
                            ],
                            [
                                -73.89754219083909,
                                40.68044044551458
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9e703d73-7cf3-43db-86f8-3c1a05b77114"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88579222104543,
                                40.68359524727468
                            ],
                            [
                                -73.89633449945205,
                                40.68076470395783
                            ],
                            [
                                -73.88394837588473,
                                40.68781462102672
                            ],
                            [
                                -73.88226327924384,
                                40.685432066540514
                            ],
                            [
                                -73.8744988581722,
                                40.69243563685003
                            ],
                            [
                                -73.86862680013049,
                                40.69157820946264
                            ],
                            [
                                -73.86893723216208,
                                40.69124298335532
                            ],
                            [
                                -73.87525186646754,
                                40.69068015037183
                            ],
                            [
                                -73.87495107727275,
                                40.68650603428573
                            ],
                            [
                                -73.88040141382571,
                                40.6850426491322
                            ],
                            [
                                -73.88128160389462,
                                40.68565919347121
                            ],
                            [
                                -73.88579222104543,
                                40.68359524727468
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "61b50510-1b81-4679-92c3-9e09cd30f105"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88040141382571,
                                40.6850426491322
                            ],
                            [
                                -73.88579222104543,
                                40.68359524727468
                            ],
                            [
                                -73.88128160389462,
                                40.68565919347121
                            ],
                            [
                                -73.88040141382571,
                                40.6850426491322
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "407a48b2-f1a4-46dc-86fb-6a0a7d2a2e33"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86893723216208,
                                40.69124298335532
                            ],
                            [
                                -73.8673188810244,
                                40.69138722945971
                            ],
                            [
                                -73.86719903921433,
                                40.6922901652875
                            ],
                            [
                                -73.86512871829314,
                                40.69165396895838
                            ],
                            [
                                -73.86604108355111,
                                40.69123873532193
                            ],
                            [
                                -73.86890259883579,
                                40.69080907423248
                            ],
                            [
                                -73.86921923017704,
                                40.69093846227759
                            ],
                            [
                                -73.86893723216208,
                                40.69124298335532
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "92eb58fd-094f-4c2d-8a25-6a1357c1723a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8673188810244,
                                40.69138722945971
                            ],
                            [
                                -73.86893723216208,
                                40.69124298335532
                            ],
                            [
                                -73.86862680013049,
                                40.69157820946264
                            ],
                            [
                                -73.8673188810244,
                                40.69138722945971
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "1a5404cb-26ac-41d0-bea8-9b1e5bc74742"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8673188810244,
                                40.69138722945971
                            ],
                            [
                                -73.86840064391112,
                                40.69182242868406
                            ],
                            [
                                -73.86779726715088,
                                40.69247399689221
                            ],
                            [
                                -73.86719903921433,
                                40.6922901652875
                            ],
                            [
                                -73.8673188810244,
                                40.69138722945971
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "e55a59e0-b8cc-4506-988a-1cfffcc2636b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86840064391112,
                                40.69182242868406
                            ],
                            [
                                -73.8673188810244,
                                40.69138722945971
                            ],
                            [
                                -73.86862680013049,
                                40.69157820946264
                            ],
                            [
                                -73.86840064391112,
                                40.69182242868406
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "de6f7598-4fd6-45c0-8bd2-66f96e2d31b1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86840064391112,
                                40.69182242868406
                            ],
                            [
                                -73.86975436377831,
                                40.69236703771386
                            ],
                            [
                                -73.86809698106225,
                                40.69275962897199
                            ],
                            [
                                -73.86867068293193,
                                40.69381137466886
                            ],
                            [
                                -73.86870006682487,
                                40.693867236992524
                            ],
                            [
                                -73.86816764703408,
                                40.69403380338769
                            ],
                            [
                                -73.86698538199924,
                                40.6938999436633
                            ],
                            [
                                -73.86719903921433,
                                40.6922901652875
                            ],
                            [
                                -73.86779726715088,
                                40.69247399689221
                            ],
                            [
                                -73.86840064391112,
                                40.69182242868406
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3d9e7059-d474-42cc-baa9-d8ef419edf55"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.85634276452141,
                                40.69269495341116
                            ],
                            [
                                -73.86604108355111,
                                40.69123873532193
                            ],
                            [
                                -73.86512871829314,
                                40.69165396895838
                            ],
                            [
                                -73.86719903921433,
                                40.6922901652875
                            ],
                            [
                                -73.86698538199924,
                                40.6938999436633
                            ],
                            [
                                -73.85634276452141,
                                40.69269495341116
                            ]
                        ],
                        [
                            [
                                -73.85917926240468,
                                40.69251022364379
                            ],
                            [
                                -73.8600065550858,
                                40.692417640268715
                            ],
                            [
                                -73.8608336752904,
                                40.692324978435934
                            ],
                            [
                                -73.85917926240468,
                                40.69251022364379
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ef5330d0-99ad-4ab0-aacc-1ae6eb1cc0a2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.85634276452141,
                                40.69269495341116
                            ],
                            [
                                -73.86698538199924,
                                40.6938999436633
                            ],
                            [
                                -73.86677193996705,
                                40.695508100765586
                            ],
                            [
                                -73.85561597176101,
                                40.692802941231285
                            ],
                            [
                                -73.85183751214895,
                                40.69771215397611
                            ],
                            [
                                -73.83975765901255,
                                40.69553096834447
                            ],
                            [
                                -73.86381592662514,
                                40.687678960786464
                            ],
                            [
                                -73.86286853637704,
                                40.68530756349369
                            ],
                            [
                                -73.86630642220703,
                                40.6832594661636
                            ],
                            [
                                -73.86621246111076,
                                40.682820613447696
                            ],
                            [
                                -73.86817347710027,
                                40.68270716558059
                            ],
                            [
                                -73.87090526680586,
                                40.6903821231802
                            ],
                            [
                                -73.87031947745626,
                                40.69059632764505
                            ],
                            [
                                -73.86890259883579,
                                40.69080907423248
                            ],
                            [
                                -73.86789228555173,
                                40.69039622040613
                            ],
                            [
                                -73.86604108355111,
                                40.69123873532193
                            ],
                            [
                                -73.85634276452141,
                                40.69269495341116
                            ]
                        ],
                        [
                            [
                                -73.84998440270216,
                                40.694368114596145
                            ],
                            [
                                -73.85222313734084,
                                40.694680433518144
                            ],
                            [
                                -73.85276047361118,
                                40.69354648212175
                            ],
                            [
                                -73.84998440270216,
                                40.694368114596145
                            ]
                        ],
                        [
                            [
                                -73.84433019879307,
                                40.69519142468628
                            ],
                            [
                                -73.84371636558639,
                                40.695198356848664
                            ],
                            [
                                -73.84483221420477,
                                40.695185842449284
                            ],
                            [
                                -73.84433019879307,
                                40.69519142468628
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "55d8a69d-1b9c-4135-8928-4d46c249cb00"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86890259883579,
                                40.69080907423248
                            ],
                            [
                                -73.86604108355111,
                                40.69123873532193
                            ],
                            [
                                -73.86789228555173,
                                40.69039622040613
                            ],
                            [
                                -73.86890259883579,
                                40.69080907423248
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "df3eeb8f-f550-4605-9b13-b38147450cdd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87031947745626,
                                40.69059632764505
                            ],
                            [
                                -73.87094792295696,
                                40.69050196554958
                            ],
                            [
                                -73.87090526680586,
                                40.6903821231802
                            ],
                            [
                                -73.87493209766743,
                                40.68890963968366
                            ],
                            [
                                -73.87461398985228,
                                40.68928855877292
                            ],
                            [
                                -73.87464064915497,
                                40.689138556416815
                            ],
                            [
                                -73.8717290368426,
                                40.690277062027114
                            ],
                            [
                                -73.87094802985648,
                                40.690502064315
                            ],
                            [
                                -73.86975108672415,
                                40.690804169990905
                            ],
                            [
                                -73.87031947745626,
                                40.69059632764505
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "2d19ed48-6d7a-41cf-bf4c-f14a43e42c50"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87090526680586,
                                40.6903821231802
                            ],
                            [
                                -73.87094792295696,
                                40.69050196554958
                            ],
                            [
                                -73.87031947745626,
                                40.69059632764505
                            ],
                            [
                                -73.87090526680586,
                                40.6903821231802
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9c26e944-f28f-4ac2-bd77-c95f93b1343a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83068902608218,
                                40.684875700177216
                            ],
                            [
                                -73.84010935733681,
                                40.67923279283112
                            ],
                            [
                                -73.8406201045048,
                                40.67981415963768
                            ],
                            [
                                -73.83533934738989,
                                40.68284131409792
                            ],
                            [
                                -73.86575592831271,
                                40.680688340982954
                            ],
                            [
                                -73.86621246111076,
                                40.682820613447696
                            ],
                            [
                                -73.83068902608218,
                                40.684875700177216
                            ]
                        ],
                        [
                            [
                                -73.83205451112812,
                                40.68429440868049
                            ],
                            [
                                -73.83204557917925,
                                40.684338906968215
                            ],
                            [
                                -73.83226175156585,
                                40.68425602758067
                            ],
                            [
                                -73.83205451112812,
                                40.68429440868049
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "02356274-0449-493a-9c5a-c42e191e961b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84010935733681,
                                40.67923279283112
                            ],
                            [
                                -73.8407440018699,
                                40.678852632070196
                            ],
                            [
                                -73.84080136923654,
                                40.67971025098798
                            ],
                            [
                                -73.8406201045048,
                                40.67981415963768
                            ],
                            [
                                -73.84010935733681,
                                40.67923279283112
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "0e461f2a-9ed7-43c6-bea2-4d38b9210c48"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8407440018699,
                                40.678852632070196
                            ],
                            [
                                -73.84927059419087,
                                40.67374508657687
                            ],
                            [
                                -73.85645556863508,
                                40.67855978437908
                            ],
                            [
                                -73.85634303451805,
                                40.67864125944039
                            ],
                            [
                                -73.84943629203829,
                                40.67476034617236
                            ],
                            [
                                -73.84735942304188,
                                40.67595089576762
                            ],
                            [
                                -73.84767146093279,
                                40.675596315763414
                            ],
                            [
                                -73.84698214678436,
                                40.676056114123504
                            ],
                            [
                                -73.8470082292001,
                                40.67615221500679
                            ],
                            [
                                -73.84080136923654,
                                40.67971025098798
                            ],
                            [
                                -73.8407440018699,
                                40.678852632070196
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c135bc69-4b3e-4be1-932f-227ed272141b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.85645556863508,
                                40.67855978437908
                            ],
                            [
                                -73.84927059419087,
                                40.67374508657687
                            ],
                            [
                                -73.8407440018699,
                                40.678852632070196
                            ],
                            [
                                -73.84034901008262,
                                40.67294766468251
                            ],
                            [
                                -73.85637783540588,
                                40.67382400696841
                            ],
                            [
                                -73.85445342905739,
                                40.675092751624625
                            ],
                            [
                                -73.85266098968505,
                                40.675330302978374
                            ],
                            [
                                -73.85657269085341,
                                40.67847498751774
                            ],
                            [
                                -73.85645556863508,
                                40.67855978437908
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f097d090-0fba-4783-b2a8-924942a1e788"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.85645556863508,
                                40.67855978437908
                            ],
                            [
                                -73.85779292925761,
                                40.679455958373865
                            ],
                            [
                                -73.85634303451805,
                                40.67864125944039
                            ],
                            [
                                -73.85645556863508,
                                40.67855978437908
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "3cce01d4-9184-4e71-a41c-efc1f4bc353c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.85779292925761,
                                40.679455958373865
                            ],
                            [
                                -73.85645556863508,
                                40.67855978437908
                            ],
                            [
                                -73.85657269085341,
                                40.67847498751774
                            ],
                            [
                                -73.85779292925761,
                                40.679455958373865
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "922ca270-2534-4a69-b921-2e3120b0525b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.85779292925761,
                                40.679455958373865
                            ],
                            [
                                -73.85657269085341,
                                40.67847498751774
                            ],
                            [
                                -73.85668528667766,
                                40.67839346778021
                            ],
                            [
                                -73.85779292925761,
                                40.679455958373865
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "35162003-b268-492a-a7ff-bbc633d82ea2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.85657269085341,
                                40.67847498751774
                            ],
                            [
                                -73.85266098968505,
                                40.675330302978374
                            ],
                            [
                                -73.85445342905739,
                                40.675092751624625
                            ],
                            [
                                -73.85373682823898,
                                40.67556520041888
                            ],
                            [
                                -73.85668528667766,
                                40.67839346778021
                            ],
                            [
                                -73.85657269085341,
                                40.67847498751774
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "2a1305e4-4c0b-45ac-9b59-40b5d8ddeff9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86762097558233,
                                40.67051623281025
                            ],
                            [
                                -73.86665062608118,
                                40.67347626143276
                            ],
                            [
                                -73.86201123204485,
                                40.67409111867753
                            ],
                            [
                                -73.86004951926869,
                                40.67271677573719
                            ],
                            [
                                -73.86762097558233,
                                40.67051623281025
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b540fb22-ac09-48c8-9f84-9f90cf6eb9a5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86762097558233,
                                40.67051623281025
                            ],
                            [
                                -73.86827584911006,
                                40.66851855631954
                            ],
                            [
                                -73.86172665458173,
                                40.66944474489848
                            ],
                            [
                                -73.86855141450998,
                                40.66571575662285
                            ],
                            [
                                -73.87101265074718,
                                40.666386578332904
                            ],
                            [
                                -73.87112296957154,
                                40.66695095725815
                            ],
                            [
                                -73.8725366504957,
                                40.66619749494057
                            ],
                            [
                                -73.87486758555013,
                                40.66841010214682
                            ],
                            [
                                -73.86762097558233,
                                40.67051623281025
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "7468d22d-de12-45b2-8c00-6b97cb7b29ad"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87101265074718,
                                40.666386578332904
                            ],
                            [
                                -73.87172292201046,
                                40.66658016616338
                            ],
                            [
                                -73.87092417432258,
                                40.665933942713224
                            ],
                            [
                                -73.87080651196156,
                                40.665331995065245
                            ],
                            [
                                -73.87210877377483,
                                40.66579133900148
                            ],
                            [
                                -73.8725366504957,
                                40.66619749494057
                            ],
                            [
                                -73.87112296957154,
                                40.66695095725815
                            ],
                            [
                                -73.87101265074718,
                                40.666386578332904
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "02b9aff2-39b6-4767-9d6f-6e7cebd193d0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87092417432258,
                                40.665933942713224
                            ],
                            [
                                -73.87172292201046,
                                40.66658016616338
                            ],
                            [
                                -73.87101265074718,
                                40.666386578332904
                            ],
                            [
                                -73.87092417432258,
                                40.665933942713224
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "72fa3b0d-d418-413d-9624-0da24c158bfe"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86823926522011,
                                40.663761728320786
                            ],
                            [
                                -73.86882250899475,
                                40.66267189853479
                            ],
                            [
                                -73.87210877377483,
                                40.66579133900148
                            ],
                            [
                                -73.87080651196156,
                                40.665331995065245
                            ],
                            [
                                -73.87092417432258,
                                40.665933942713224
                            ],
                            [
                                -73.86823926522011,
                                40.663761728320786
                            ]
                        ],
                        [
                            [
                                -73.86943712822729,
                                40.66350125934571
                            ],
                            [
                                -73.86945607818822,
                                40.6637269666567
                            ],
                            [
                                -73.86976720789025,
                                40.66394270278169
                            ],
                            [
                                -73.86943712822729,
                                40.66350125934571
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "57cc21f8-d7e2-460a-8bba-7b336dcc5742"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8725366504957,
                                40.66619749494057
                            ],
                            [
                                -73.87210877377483,
                                40.66579133900148
                            ],
                            [
                                -73.87282481799585,
                                40.666043907699766
                            ],
                            [
                                -73.8725366504957,
                                40.66619749494057
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "cf26c24e-131f-419b-a0dd-c0d41e29012f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8876632987011,
                                40.66889055361166
                            ],
                            [
                                -73.87702705065668,
                                40.67045994392481
                            ],
                            [
                                -73.87486758555013,
                                40.66841010214682
                            ],
                            [
                                -73.88309837516702,
                                40.66601793277751
                            ],
                            [
                                -73.88280192076631,
                                40.666429373397584
                            ],
                            [
                                -73.88435976583183,
                                40.6674678413543
                            ],
                            [
                                -73.88445138303649,
                                40.667715333391406
                            ],
                            [
                                -73.88481592072544,
                                40.66551875103839
                            ],
                            [
                                -73.88960705541315,
                                40.66412627155858
                            ],
                            [
                                -73.8876632987011,
                                40.66889055361166
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "3540b2d0-5000-4007-a996-462e40f69224"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89134077869397,
                                40.66362238797993
                            ],
                            [
                                -73.89029908854934,
                                40.665311987173325
                            ],
                            [
                                -73.89483092566435,
                                40.66783296222441
                            ],
                            [
                                -73.8876632987011,
                                40.66889055361166
                            ],
                            [
                                -73.88960705541315,
                                40.66412627155858
                            ],
                            [
                                -73.89134077869397,
                                40.66362238797993
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "19a509a3-3129-4910-a42b-c7892e5e4dcc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89134077869397,
                                40.66362238797993
                            ],
                            [
                                -73.89178884885253,
                                40.66289562774095
                            ],
                            [
                                -73.8917379246315,
                                40.66350696280311
                            ],
                            [
                                -73.89134077869397,
                                40.66362238797993
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b099c97a-bc8b-47c5-b7bf-e69608e494ce"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89178884885253,
                                40.66289562774095
                            ],
                            [
                                -73.89134077869397,
                                40.66362238797993
                            ],
                            [
                                -73.88960705541315,
                                40.66412627155858
                            ],
                            [
                                -73.89221978865832,
                                40.65772228178339
                            ],
                            [
                                -73.89178884885253,
                                40.66289562774095
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9d208f53-f1cf-44ad-b0c6-a8818b3e79cf"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89532244319811,
                                40.650117461051885
                            ],
                            [
                                -73.89760991160648,
                                40.653453988128234
                            ],
                            [
                                -73.89178884885253,
                                40.66289562774095
                            ],
                            [
                                -73.89221978865832,
                                40.65772228178339
                            ],
                            [
                                -73.89532244319811,
                                40.650117461051885
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ac4837fc-7c37-4d81-a485-768aed396199"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89532244319811,
                                40.650117461051885
                            ],
                            [
                                -73.89363102797304,
                                40.64765034383396
                            ],
                            [
                                -73.89777204295314,
                                40.64519372069822
                            ],
                            [
                                -73.90289101613345,
                                40.64409123015075
                            ],
                            [
                                -73.904590983198,
                                40.644484921107995
                            ],
                            [
                                -73.90491320339679,
                                40.6460860314389
                            ],
                            [
                                -73.9005858675477,
                                40.649134354997024
                            ],
                            [
                                -73.90461051985334,
                                40.6500956362722
                            ],
                            [
                                -73.89914547476538,
                                40.65660807339238
                            ],
                            [
                                -73.89694851619876,
                                40.64613184373363
                            ],
                            [
                                -73.89532244319811,
                                40.650117461051885
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "fe2d2a88-8d45-43c4-999b-d80aeb2f7094"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89777204295314,
                                40.64519372069822
                            ],
                            [
                                -73.89363102797304,
                                40.64765034383396
                            ],
                            [
                                -73.89532244319811,
                                40.650117461051885
                            ],
                            [
                                -73.89221978865832,
                                40.65772228178339
                            ],
                            [
                                -73.89318105286408,
                                40.646182497743624
                            ],
                            [
                                -73.89777204295314,
                                40.64519372069822
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "32a41620-2c13-4165-851d-f440be25d3dd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89777204295314,
                                40.64519372069822
                            ],
                            [
                                -73.90054592665354,
                                40.64354813696887
                            ],
                            [
                                -73.90289101613345,
                                40.64409123015075
                            ],
                            [
                                -73.89777204295314,
                                40.64519372069822
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "01697819-f7b0-4471-82c1-65d224dbd4e6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.904590983198,
                                40.644484921107995
                            ],
                            [
                                -73.90289101613345,
                                40.64409123015075
                            ],
                            [
                                -73.90444442364547,
                                40.643756667526944
                            ],
                            [
                                -73.904590983198,
                                40.644484921107995
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "4657fe24-65bb-4f85-ab8d-1c7cf9f2000d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.904590983198,
                                40.644484921107995
                            ],
                            [
                                -73.90659203048679,
                                40.644948338452394
                            ],
                            [
                                -73.90658676725754,
                                40.64774063521611
                            ],
                            [
                                -73.90550549685963,
                                40.64902913427932
                            ],
                            [
                                -73.90542237919065,
                                40.64861612305599
                            ],
                            [
                                -73.90590744417169,
                                40.64538565419011
                            ],
                            [
                                -73.90491320339679,
                                40.6460860314389
                            ],
                            [
                                -73.904590983198,
                                40.644484921107995
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5797aba4-b729-41e1-81fb-76d7cfe76f1a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90550549685963,
                                40.64902913427932
                            ],
                            [
                                -73.90532873645508,
                                40.649239771337484
                            ],
                            [
                                -73.90542237919065,
                                40.64861612305599
                            ],
                            [
                                -73.90550549685963,
                                40.64902913427932
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ee75dd02-c6ce-4160-a940-c97cfcb2a7c3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90532873645508,
                                40.649239771337484
                            ],
                            [
                                -73.90550549685963,
                                40.64902913427932
                            ],
                            [
                                -73.90587370883952,
                                40.650858777508304
                            ],
                            [
                                -73.89915612224196,
                                40.656658846043136
                            ],
                            [
                                -73.89914547476538,
                                40.65660807339238
                            ],
                            [
                                -73.90461051985334,
                                40.6500956362722
                            ],
                            [
                                -73.90517980879156,
                                40.65023160995512
                            ],
                            [
                                -73.90532873645508,
                                40.649239771337484
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "e31db75a-6ed8-4df9-85f1-fc1b4beea5bc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90532873645508,
                                40.649239771337484
                            ],
                            [
                                -73.90461051985334,
                                40.6500956362722
                            ],
                            [
                                -73.9005858675477,
                                40.649134354997024
                            ],
                            [
                                -73.90491320339679,
                                40.6460860314389
                            ],
                            [
                                -73.90542237919065,
                                40.64861612305599
                            ],
                            [
                                -73.90532873645508,
                                40.649239771337484
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "45b9c8e9-89d5-468b-9989-ad74fc04fbb0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90461051985334,
                                40.6500956362722
                            ],
                            [
                                -73.90532873645508,
                                40.649239771337484
                            ],
                            [
                                -73.90517980879156,
                                40.65023160995512
                            ],
                            [
                                -73.90461051985334,
                                40.6500956362722
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "6b20f959-e9b6-4606-aac2-ad2a58f2d0e8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89896278430582,
                                40.65682577701808
                            ],
                            [
                                -73.89914547476538,
                                40.65660807339238
                            ],
                            [
                                -73.89915612224196,
                                40.656658846043136
                            ],
                            [
                                -73.89896278430582,
                                40.65682577701808
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b76f04df-442b-480b-8de9-2de6a18b1149"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8991682996376,
                                40.65671691413562
                            ],
                            [
                                -73.89896278430582,
                                40.65682577701808
                            ],
                            [
                                -73.89916720923351,
                                40.656711714527525
                            ],
                            [
                                -73.8991682996376,
                                40.65671691413562
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ac8947d6-945a-43b8-95a6-204af33a96c8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8991682996376,
                                40.65671691413562
                            ],
                            [
                                -73.90414028822624,
                                40.6540832176389
                            ],
                            [
                                -73.90627409784311,
                                40.65499809335817
                            ],
                            [
                                -73.907423501796,
                                40.66160624568882
                            ],
                            [
                                -73.9049006501305,
                                40.66147644322426
                            ],
                            [
                                -73.90385549353509,
                                40.65469942648519
                            ],
                            [
                                -73.9030719541982,
                                40.65496524147148
                            ],
                            [
                                -73.90243231059677,
                                40.655329894121685
                            ],
                            [
                                -73.90011366901692,
                                40.65718625701219
                            ],
                            [
                                -73.90079561159014,
                                40.65712188699313
                            ],
                            [
                                -73.89932424481658,
                                40.65746054104519
                            ],
                            [
                                -73.8991682996376,
                                40.65671691413562
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "28245bfc-492e-4236-8ee2-ed4a657529fb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.907423501796,
                                40.66160624568882
                            ],
                            [
                                -73.90627409784311,
                                40.65499809335817
                            ],
                            [
                                -73.90414028822624,
                                40.6540832176389
                            ],
                            [
                                -73.8991682996376,
                                40.65671691413562
                            ],
                            [
                                -73.89916720923351,
                                40.656711714527525
                            ],
                            [
                                -73.90486859824117,
                                40.65353052406718
                            ],
                            [
                                -73.90662472556987,
                                40.65461040667068
                            ],
                            [
                                -73.90942414281703,
                                40.661709180054245
                            ],
                            [
                                -73.907423501796,
                                40.66160624568882
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "18feb033-523f-479c-95c5-61e932f3197c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90763197100111,
                                40.662804776683984
                            ],
                            [
                                -73.907423501796,
                                40.66160624568882
                            ],
                            [
                                -73.90942414281703,
                                40.661709180054245
                            ],
                            [
                                -73.90972442792206,
                                40.66247064421728
                            ],
                            [
                                -73.90763197100111,
                                40.662804776683984
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "37466065-76f1-4add-a318-9c7de8863c17"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90763197100111,
                                40.662804776683984
                            ],
                            [
                                -73.90878758098624,
                                40.66944860873047
                            ],
                            [
                                -73.90535647417028,
                                40.66316813774602
                            ],
                            [
                                -73.90763197100111,
                                40.662804776683984
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f7729356-f986-4cec-ad0b-c28c421bc9be"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90878758098624,
                                40.66944860873047
                            ],
                            [
                                -73.90763197100111,
                                40.662804776683984
                            ],
                            [
                                -73.90972442792206,
                                40.66247064421728
                            ],
                            [
                                -73.91243133974223,
                                40.66933484197136
                            ],
                            [
                                -73.90901382926388,
                                40.669862744990056
                            ],
                            [
                                -73.90878758098624,
                                40.66944860873047
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "846ac50c-d400-4588-8346-c004a4b00382"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90901382926388,
                                40.669862744990056
                            ],
                            [
                                -73.90886364975307,
                                40.66988594322679
                            ],
                            [
                                -73.90878758098624,
                                40.66944860873047
                            ],
                            [
                                -73.90901382926388,
                                40.669862744990056
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "41d5cef7-106b-47da-bd88-1406ef8a9549"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91243133974223,
                                40.66933484197136
                            ],
                            [
                                -73.91316313761985,
                                40.671190537938465
                            ],
                            [
                                -73.91503265503616,
                                40.66893301666463
                            ],
                            [
                                -73.92843634079284,
                                40.66686254863903
                            ],
                            [
                                -73.93024715960537,
                                40.65842777721989
                            ],
                            [
                                -73.93105746668842,
                                40.656558307977726
                            ],
                            [
                                -73.93302442265806,
                                40.66225254426217
                            ],
                            [
                                -73.93423946740972,
                                40.66621735479752
                            ],
                            [
                                -73.93960444164509,
                                40.6665119485649
                            ],
                            [
                                -73.94756643189211,
                                40.660248455883696
                            ],
                            [
                                -73.94728421435902,
                                40.6595659376407
                            ],
                            [
                                -73.96057626551685,
                                40.662390296896895
                            ],
                            [
                                -73.96046903116037,
                                40.66254038764782
                            ],
                            [
                                -73.95108006366685,
                                40.66475784801767
                            ],
                            [
                                -73.95311969756244,
                                40.66953206713695
                            ],
                            [
                                -73.9479165682404,
                                40.66875795567562
                            ],
                            [
                                -73.9439197955564,
                                40.664120339203684
                            ],
                            [
                                -73.9407533935879,
                                40.67179073335819
                            ],
                            [
                                -73.93911688641452,
                                40.67160064771993
                            ],
                            [
                                -73.93138920876841,
                                40.672302798622624
                            ],
                            [
                                -73.93099468433151,
                                40.671286222951714
                            ],
                            [
                                -73.92976746418401,
                                40.672450153297085
                            ],
                            [
                                -73.92407524287063,
                                40.672967358920374
                            ],
                            [
                                -73.92696882125176,
                                40.675104465517556
                            ],
                            [
                                -73.92476286728801,
                                40.6771966547341
                            ],
                            [
                                -73.91127196745279,
                                40.673996155033805
                            ],
                            [
                                -73.90901382926388,
                                40.669862744990056
                            ],
                            [
                                -73.91243133974223,
                                40.66933484197136
                            ]
                        ],
                        [
                            [
                                -73.94959760180126,
                                40.66328618822052
                            ],
                            [
                                -73.9525319118459,
                                40.66309995004716
                            ],
                            [
                                -73.95092190717625,
                                40.662267267729945
                            ],
                            [
                                -73.94959760180126,
                                40.66328618822052
                            ]
                        ],
                        [
                            [
                                -73.94834643007016,
                                40.66440724710025
                            ],
                            [
                                -73.94935956621727,
                                40.664117766879535
                            ],
                            [
                                -73.94810258957281,
                                40.66419219593172
                            ],
                            [
                                -73.94832989298409,
                                40.664412346071856
                            ],
                            [
                                -73.94834643007016,
                                40.66440724710025
                            ]
                        ],
                        [
                            [
                                -73.9507519935352,
                                40.66718106823158
                            ],
                            [
                                -73.95066800644604,
                                40.66807193205314
                            ],
                            [
                                -73.95146525856575,
                                40.668053825324634
                            ],
                            [
                                -73.9507519935352,
                                40.66718106823158
                            ]
                        ],
                        [
                            [
                                -73.93103629519379,
                                40.67085163268177
                            ],
                            [
                                -73.9316943219192,
                                40.670529314408384
                            ],
                            [
                                -73.93116457281974,
                                40.66951546211476
                            ],
                            [
                                -73.93056036595964,
                                40.67047517540369
                            ],
                            [
                                -73.93103629519379,
                                40.67085163268177
                            ]
                        ],
                        [
                            [
                                -73.92522825209736,
                                40.67199731302322
                            ],
                            [
                                -73.92558938023147,
                                40.672013177787555
                            ],
                            [
                                -73.92543550600982,
                                40.671222827906945
                            ],
                            [
                                -73.92522825209736,
                                40.67199731302322
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "5127e141-9363-4327-8941-a37f22253ffe"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91243133974223,
                                40.66933484197136
                            ],
                            [
                                -73.91503265503616,
                                40.66893301666463
                            ],
                            [
                                -73.91316313761985,
                                40.671190537938465
                            ],
                            [
                                -73.91243133974223,
                                40.66933484197136
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "44c9307a-17ec-48e2-a983-82eda514b20b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93024715960537,
                                40.65842777721989
                            ],
                            [
                                -73.92843634079284,
                                40.66686254863903
                            ],
                            [
                                -73.91503265503616,
                                40.66893301666463
                            ],
                            [
                                -73.91897972549125,
                                40.664166762312576
                            ],
                            [
                                -73.91720211510255,
                                40.66149483609391
                            ],
                            [
                                -73.91884032582378,
                                40.66133026452641
                            ],
                            [
                                -73.91853060687451,
                                40.66104653046846
                            ],
                            [
                                -73.91811356540273,
                                40.660663052131426
                            ],
                            [
                                -73.91811275091065,
                                40.660663731906894
                            ],
                            [
                                -73.91807271182024,
                                40.66062705202785
                            ],
                            [
                                -73.91735405060979,
                                40.66129694352051
                            ],
                            [
                                -73.91705433310514,
                                40.65988436574025
                            ],
                            [
                                -73.91700191461169,
                                40.65991997849774
                            ],
                            [
                                -73.91700196955657,
                                40.659920193428974
                            ],
                            [
                                -73.91669613491054,
                                40.660734297254926
                            ],
                            [
                                -73.91604579133401,
                                40.65975676580916
                            ],
                            [
                                -73.91589340433086,
                                40.65909927814914
                            ],
                            [
                                -73.91573390045501,
                                40.65928796262132
                            ],
                            [
                                -73.91451057730836,
                                40.65744918556972
                            ],
                            [
                                -73.92323257728154,
                                40.662543348664634
                            ],
                            [
                                -73.9198074475671,
                                40.66218540621601
                            ],
                            [
                                -73.92457507807113,
                                40.66703787353669
                            ],
                            [
                                -73.92562231835014,
                                40.66393909858385
                            ],
                            [
                                -73.92740661455908,
                                40.664981232948215
                            ],
                            [
                                -73.93024715960537,
                                40.65842777721989
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "92dcd0bc-8777-4902-84f8-af30448bc96e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93024715960537,
                                40.65842777721989
                            ],
                            [
                                -73.9309083966569,
                                40.65534774328999
                            ],
                            [
                                -73.93302442265806,
                                40.66225254426217
                            ],
                            [
                                -73.93105746668842,
                                40.656558307977726
                            ],
                            [
                                -73.93024715960537,
                                40.65842777721989
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "bdc6b20e-b652-4138-8662-1d2644a59cd6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94728421435902,
                                40.6595659376407
                            ],
                            [
                                -73.94756643189211,
                                40.660248455883696
                            ],
                            [
                                -73.93960444164509,
                                40.6665119485649
                            ],
                            [
                                -73.93423946740972,
                                40.66621735479752
                            ],
                            [
                                -73.93302442265806,
                                40.66225254426217
                            ],
                            [
                                -73.93426406867209,
                                40.66584125566285
                            ],
                            [
                                -73.94538999721502,
                                40.65916344522923
                            ],
                            [
                                -73.94728421435902,
                                40.6595659376407
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "5534cc7d-c7d6-4aba-9990-00e84dac6592"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96062058531024,
                                40.63444837665347
                            ],
                            [
                                -73.96079297191227,
                                40.63192423596219
                            ],
                            [
                                -73.9610597527347,
                                40.63198525519339
                            ],
                            [
                                -73.96062058531024,
                                40.63444837665347
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e133cca1-1bdc-4118-b836-dedddac0b9d1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96872007489081,
                                40.63778581383181
                            ],
                            [
                                -73.96919468788529,
                                40.639693102830186
                            ],
                            [
                                -73.96885866746653,
                                40.639149399039525
                            ],
                            [
                                -73.9684623781763,
                                40.63753935052598
                            ],
                            [
                                -73.96872007489081,
                                40.63778581383181
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "dc2472ac-86b8-489f-8a6c-e0bb1434138f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96919468788529,
                                40.639693102830186
                            ],
                            [
                                -73.96872007489081,
                                40.63778581383181
                            ],
                            [
                                -73.96979921055795,
                                40.63881790826298
                            ],
                            [
                                -73.96962646803102,
                                40.64039175234333
                            ],
                            [
                                -73.96919468788529,
                                40.639693102830186
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1495448a-0c5f-4aba-8464-90ed51f8e1c6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96919468788529,
                                40.639693102830186
                            ],
                            [
                                -73.96954752312695,
                                40.64111101338565
                            ],
                            [
                                -73.96948398184328,
                                40.64168993320884
                            ],
                            [
                                -73.96885866746653,
                                40.639149399039525
                            ],
                            [
                                -73.96919468788529,
                                40.639693102830186
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "566e3e00-ef62-44e1-8906-cf0266d1cd54"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96954752312695,
                                40.64111101338565
                            ],
                            [
                                -73.96919468788529,
                                40.639693102830186
                            ],
                            [
                                -73.96962646803102,
                                40.64039175234333
                            ],
                            [
                                -73.96954752312695,
                                40.64111101338565
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6961a047-5a7a-4efc-be64-0dd058fb1494"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97770373021287,
                                40.62154866757206
                            ],
                            [
                                -73.98424311466982,
                                40.63599485944915
                            ],
                            [
                                -73.9820150892896,
                                40.634394992351694
                            ],
                            [
                                -73.97770373021287,
                                40.62154866757206
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "edbb6588-a44b-4943-9468-d595ac2aa9c9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99176430684666,
                                40.655594727708994
                            ],
                            [
                                -73.99666934402366,
                                40.65026007420116
                            ],
                            [
                                -73.9969171391299,
                                40.65454144966071
                            ],
                            [
                                -73.99176430684666,
                                40.655594727708994
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "09c8cd89-f4ae-4dc1-a034-ddc2fd45a875"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9918062601583,
                                40.645372312132494
                            ],
                            [
                                -73.9911745702192,
                                40.64516039623616
                            ],
                            [
                                -73.99108216968692,
                                40.64468570711712
                            ],
                            [
                                -73.99181176411356,
                                40.645243522857946
                            ],
                            [
                                -73.9918062601583,
                                40.645372312132494
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9379e468-f88b-48c4-9f90-be4908c4adbb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99181176411356,
                                40.645243522857946
                            ],
                            [
                                -73.99108216968692,
                                40.64468570711712
                            ],
                            [
                                -73.99027379354554,
                                40.64053283755073
                            ],
                            [
                                -73.99300454936783,
                                40.63008920474412
                            ],
                            [
                                -73.99444756437366,
                                40.63008500481464
                            ],
                            [
                                -73.99354019141553,
                                40.630412984430485
                            ],
                            [
                                -73.99445094387475,
                                40.63278505361952
                            ],
                            [
                                -73.99723031788909,
                                40.63264336807754
                            ],
                            [
                                -73.99625397203484,
                                40.6300797472222
                            ],
                            [
                                -73.99726512131815,
                                40.63007680424817
                            ],
                            [
                                -73.9989966249266,
                                40.648297209225476
                            ],
                            [
                                -73.99812306736162,
                                40.64777063081786
                            ],
                            [
                                -73.9973167106666,
                                40.63692072069716
                            ],
                            [
                                -73.99228307014232,
                                40.63421524254522
                            ],
                            [
                                -73.99181176411356,
                                40.645243522857946
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "0a77d998-87a4-4f83-8982-8ad814a61f76"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99444756437366,
                                40.63008500481464
                            ],
                            [
                                -73.99510912445785,
                                40.63008307932829
                            ],
                            [
                                -73.99381399162593,
                                40.63057870445473
                            ],
                            [
                                -73.99466629430289,
                                40.632580053566095
                            ],
                            [
                                -73.9969166028357,
                                40.632453511625044
                            ],
                            [
                                -73.99608356232115,
                                40.63008024320372
                            ],
                            [
                                -73.99625397203484,
                                40.6300797472222
                            ],
                            [
                                -73.99723031788909,
                                40.63264336807754
                            ],
                            [
                                -73.99445094387475,
                                40.63278505361952
                            ],
                            [
                                -73.99354019141553,
                                40.630412984430485
                            ],
                            [
                                -73.99444756437366,
                                40.63008500481464
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8d21e4a2-7e2a-4082-8049-e2dcff212a1d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99510912445785,
                                40.63008307932829
                            ],
                            [
                                -73.99444756437366,
                                40.63008500481464
                            ],
                            [
                                -73.99603715037591,
                                40.62951043203097
                            ],
                            [
                                -73.99625397203484,
                                40.6300797472222
                            ],
                            [
                                -73.99608356232115,
                                40.63008024320372
                            ],
                            [
                                -73.9959690481959,
                                40.62975400125931
                            ],
                            [
                                -73.99510912445785,
                                40.63008307932829
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c90bb0e8-4209-44a8-bd43-79443f2707f1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99510912445785,
                                40.63008307932829
                            ],
                            [
                                -73.99608356232115,
                                40.63008024320372
                            ],
                            [
                                -73.9969166028357,
                                40.632453511625044
                            ],
                            [
                                -73.99466629430289,
                                40.632580053566095
                            ],
                            [
                                -73.99381399162593,
                                40.63057870445473
                            ],
                            [
                                -73.99510912445785,
                                40.63008307932829
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "55f59887-7fd6-4e17-b7f9-295f6e3c4e62"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99608356232115,
                                40.63008024320372
                            ],
                            [
                                -73.99510912445785,
                                40.63008307932829
                            ],
                            [
                                -73.9959690481959,
                                40.62975400125931
                            ],
                            [
                                -73.99608356232115,
                                40.63008024320372
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9f4e9209-aa8a-42f0-81f0-519d6d81a7d6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99820935611699,
                                40.64893168676984
                            ],
                            [
                                -73.99879403955867,
                                40.64934947106689
                            ],
                            [
                                -73.99407978874547,
                                40.647073191486534
                            ],
                            [
                                -73.99821167074313,
                                40.64896283115773
                            ],
                            [
                                -73.99820935611699,
                                40.64893168676984
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b32dc4b9-65d9-46b6-bdc0-6bc114f28be0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00399214229577,
                                40.64549609169758
                            ],
                            [
                                -74.00403280079,
                                40.64532681250962
                            ],
                            [
                                -74.00578251115927,
                                40.64601660788172
                            ],
                            [
                                -74.00399214229577,
                                40.64549609169758
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b1c69431-858a-40e1-8cd2-161c63e3c8c8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01249124162756,
                                40.63754595402828
                            ],
                            [
                                -74.01555991035723,
                                40.64006350798921
                            ],
                            [
                                -74.01402157410298,
                                40.640727741978296
                            ],
                            [
                                -74.0114356083439,
                                40.63757042089589
                            ],
                            [
                                -74.01249124162756,
                                40.63754595402828
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "fdb04d5c-71c8-49a7-92e6-60d3bbe7d128"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00975655307647,
                                40.63530239937958
                            ],
                            [
                                -74.00975628532768,
                                40.6353021797169
                            ],
                            [
                                -74.01074069809714,
                                40.6343561518586
                            ],
                            [
                                -74.01074101861437,
                                40.634356345551176
                            ],
                            [
                                -74.00975655307647,
                                40.63530239937958
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e265f295-4225-4263-b77c-5560920b5e75"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00975628532768,
                                40.6353021797169
                            ],
                            [
                                -74.00975655307647,
                                40.63530239937958
                            ],
                            [
                                -74.00891688679746,
                                40.63610930370203
                            ],
                            [
                                -74.0084098539951,
                                40.63419755949456
                            ],
                            [
                                -74.00880624354393,
                                40.634522759822985
                            ],
                            [
                                -74.00917628342059,
                                40.635859565784024
                            ],
                            [
                                -74.00975628532768,
                                40.6353021797169
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "2182481a-73a1-4028-bc21-507775930a23"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00975628532768,
                                40.6353021797169
                            ],
                            [
                                -74.00880624354393,
                                40.634522759822985
                            ],
                            [
                                -74.00836333234298,
                                40.63292269893498
                            ],
                            [
                                -74.00836910828716,
                                40.63292297043131
                            ],
                            [
                                -74.01074069809714,
                                40.6343561518586
                            ],
                            [
                                -74.00975628532768,
                                40.6353021797169
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "750515ef-1781-4608-8335-9e7b5d34fe87"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00880624354393,
                                40.634522759822985
                            ],
                            [
                                -74.00975628532768,
                                40.6353021797169
                            ],
                            [
                                -74.00917628342059,
                                40.635859565784024
                            ],
                            [
                                -74.00880624354393,
                                40.634522759822985
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9c613a24-04e3-4d5b-a026-260d6a2049a7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00880624354393,
                                40.634522759822985
                            ],
                            [
                                -74.0084098539951,
                                40.63419755949456
                            ],
                            [
                                -74.00801585680456,
                                40.63271201093975
                            ],
                            [
                                -74.0080203324821,
                                40.63271220084377
                            ],
                            [
                                -74.00836910828716,
                                40.63292297043131
                            ],
                            [
                                -74.00836333234298,
                                40.63292269893498
                            ],
                            [
                                -74.00880624354393,
                                40.634522759822985
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ecc8dda2-27c1-4447-b12e-0283dd160410"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0084098539951,
                                40.63419755949456
                            ],
                            [
                                -74.00569984668911,
                                40.63197425348521
                            ],
                            [
                                -74.00789125328814,
                                40.63263645872495
                            ],
                            [
                                -74.00789065210773,
                                40.63263383336923
                            ],
                            [
                                -74.0080203324821,
                                40.63271220084377
                            ],
                            [
                                -74.00801585680456,
                                40.63271201093975
                            ],
                            [
                                -74.0084098539951,
                                40.63419755949456
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a8fd9fd6-b043-4130-9404-f128ab927f87"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00569984668911,
                                40.63197425348521
                            ],
                            [
                                -74.00529076523398,
                                40.631638640641846
                            ],
                            [
                                -74.00757954776601,
                                40.63244745919226
                            ],
                            [
                                -74.0075790809826,
                                40.632445547035765
                            ],
                            [
                                -74.00789065210773,
                                40.63263383336923
                            ],
                            [
                                -74.00789125328814,
                                40.63263645872495
                            ],
                            [
                                -74.00569984668911,
                                40.63197425348521
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "749419e1-6abf-4806-8232-3ccb1edcd1b3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00529076523398,
                                40.631638640641846
                            ],
                            [
                                -74.00569984668911,
                                40.63197425348521
                            ],
                            [
                                -74.00323532739135,
                                40.631229518303186
                            ],
                            [
                                -74.00318449261262,
                                40.6299106421357
                            ],
                            [
                                -74.00343095048544,
                                40.63011283763107
                            ],
                            [
                                -74.00347652684178,
                                40.63099751827834
                            ],
                            [
                                -74.00529076523398,
                                40.631638640641846
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1d38ae3a-d509-4930-8245-cfdda8f55993"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00529076523398,
                                40.631638640641846
                            ],
                            [
                                -74.00343095048544,
                                40.63011283763107
                            ],
                            [
                                -74.00342169536172,
                                40.629933186832154
                            ],
                            [
                                -74.0075790809826,
                                40.632445547035765
                            ],
                            [
                                -74.00757954776601,
                                40.63244745919226
                            ],
                            [
                                -74.00529076523398,
                                40.631638640641846
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "dc107e31-b290-47f8-9cb0-2a803874518c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00343095048544,
                                40.63011283763107
                            ],
                            [
                                -74.00529076523398,
                                40.631638640641846
                            ],
                            [
                                -74.00347652684178,
                                40.63099751827834
                            ],
                            [
                                -74.00343095048544,
                                40.63011283763107
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c72af758-ff54-4ad7-b1b4-535b2aa80314"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00343095048544,
                                40.63011283763107
                            ],
                            [
                                -74.00318449261262,
                                40.6299106421357
                            ],
                            [
                                -74.00317972546048,
                                40.62978696139006
                            ],
                            [
                                -74.00342169536172,
                                40.629933186832154
                            ],
                            [
                                -74.00343095048544,
                                40.63011283763107
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "53eebfa7-adb7-4544-ac13-57cc803904a3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00318449261262,
                                40.6299106421357
                            ],
                            [
                                -74.00262547551802,
                                40.629452021207626
                            ],
                            [
                                -74.00317972546048,
                                40.62978696139006
                            ],
                            [
                                -74.00318449261262,
                                40.6299106421357
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "fdd656f1-f9a5-4164-9b14-eb824d37bc93"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00342169536172,
                                40.629933186832154
                            ],
                            [
                                -74.00317972546048,
                                40.62978696139006
                            ],
                            [
                                -74.00317970460728,
                                40.62978642036678
                            ],
                            [
                                -74.0074344734409,
                                40.63064170044029
                            ],
                            [
                                -74.00789065210773,
                                40.63263383336923
                            ],
                            [
                                -74.0075790809826,
                                40.632445547035765
                            ],
                            [
                                -74.00719482627927,
                                40.63087146556164
                            ],
                            [
                                -74.00342165504928,
                                40.629932404329004
                            ],
                            [
                                -74.00342169536172,
                                40.629933186832154
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f8b451c0-33da-40fa-948c-f94d7d3f3e80"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0075790809826,
                                40.632445547035765
                            ],
                            [
                                -74.00342169536172,
                                40.629933186832154
                            ],
                            [
                                -74.00342165504928,
                                40.629932404329004
                            ],
                            [
                                -74.00719482627927,
                                40.63087146556164
                            ],
                            [
                                -74.0075790809826,
                                40.632445547035765
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "808b16ae-e864-40de-83e2-7a8829148ff4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00836910828716,
                                40.63292297043131
                            ],
                            [
                                -74.0080203324821,
                                40.63271220084377
                            ],
                            [
                                -74.01136706293224,
                                40.63285420337969
                            ],
                            [
                                -74.01128151525486,
                                40.633059867149
                            ],
                            [
                                -74.00836910828716,
                                40.63292297043131
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1ab9dd11-4f2d-4b52-a1ad-1c015d670139"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01074069809714,
                                40.6343561518586
                            ],
                            [
                                -74.00836910828716,
                                40.63292297043131
                            ],
                            [
                                -74.01128151525486,
                                40.633059867149
                            ],
                            [
                                -74.01074339023266,
                                40.634353564696696
                            ],
                            [
                                -74.01074069809714,
                                40.6343561518586
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "59950a79-97ab-4c6d-b2d5-a28f16bca298"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01074101861437,
                                40.634356345551176
                            ],
                            [
                                -74.01074069809714,
                                40.6343561518586
                            ],
                            [
                                -74.01074339023266,
                                40.634353564696696
                            ],
                            [
                                -74.01074304252904,
                                40.63435440060526
                            ],
                            [
                                -74.01074101861437,
                                40.634356345551176
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e38c64fe-d344-42c9-aad4-93495b77dcac"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01074304252904,
                                40.63435440060526
                            ],
                            [
                                -74.01074198946863,
                                40.63435693225056
                            ],
                            [
                                -74.01074101861437,
                                40.634356345551176
                            ],
                            [
                                -74.01074304252904,
                                40.63435440060526
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e8d68306-45e6-4f7d-aa2b-7010c0566645"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01074304252904,
                                40.63435440060526
                            ],
                            [
                                -74.01074339023266,
                                40.634353564696696
                            ],
                            [
                                -74.01205189844289,
                                40.63309607875369
                            ],
                            [
                                -74.01128151525486,
                                40.633059867149
                            ],
                            [
                                -74.01136706293224,
                                40.63285420337969
                            ],
                            [
                                -74.01226452517906,
                                40.632892282907406
                            ],
                            [
                                -74.01074304252904,
                                40.63435440060526
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "38f1b6b1-89e0-4cfa-9740-5ef33dde56bf"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01074339023266,
                                40.634353564696696
                            ],
                            [
                                -74.01128151525486,
                                40.633059867149
                            ],
                            [
                                -74.01205189844289,
                                40.63309607875369
                            ],
                            [
                                -74.01074339023266,
                                40.634353564696696
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "200d0354-dc36-4d68-8d28-49e35406caf8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00973692827762,
                                40.66282175875008
                            ],
                            [
                                -74.00563672986002,
                                40.663695957525334
                            ],
                            [
                                -74.00547674986646,
                                40.66358657898961
                            ],
                            [
                                -74.00973692827762,
                                40.66282175875008
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a137bd4d-dad8-44a3-ade3-36202ad88b7d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01060953460158,
                                40.67221820701248
                            ],
                            [
                                -74.01156790507875,
                                40.67253131985715
                            ],
                            [
                                -74.01085606652656,
                                40.6727422497421
                            ],
                            [
                                -74.01060953460158,
                                40.67221820701248
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8fbab4d5-a3e0-499c-ab21-501d3913e632"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01156790507875,
                                40.67253131985715
                            ],
                            [
                                -74.01060953460158,
                                40.67221820701248
                            ],
                            [
                                -74.01055818428003,
                                40.672109053755015
                            ],
                            [
                                -74.01200293127675,
                                40.6724024141866
                            ],
                            [
                                -74.01156790507875,
                                40.67253131985715
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "cdb885dc-e095-4159-abb7-7b065a89d6aa"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95110297081402,
                                40.81347823319368
                            ],
                            [
                                -73.95107810229581,
                                40.813467722060906
                            ],
                            [
                                -73.95569307524588,
                                40.812601962677824
                            ],
                            [
                                -73.95110297081402,
                                40.81347823319368
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "60c71e81-6d52-45bc-8dfb-51724bd5ddb8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95107810229581,
                                40.813467722060906
                            ],
                            [
                                -73.95110297081402,
                                40.81347823319368
                            ],
                            [
                                -73.94993664665073,
                                40.81370088945159
                            ],
                            [
                                -73.94920037512051,
                                40.81267406642513
                            ],
                            [
                                -73.94924997653688,
                                40.812695031368364
                            ],
                            [
                                -73.949953588877,
                                40.81367867845313
                            ],
                            [
                                -73.95107810229581,
                                40.813467722060906
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ca59133c-6923-46d7-9661-81175f53f55d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95107810229581,
                                40.813467722060906
                            ],
                            [
                                -73.94924997653688,
                                40.812695031368364
                            ],
                            [
                                -73.9475735779902,
                                40.810351433268515
                            ],
                            [
                                -73.95318787873435,
                                40.80761358135632
                            ],
                            [
                                -73.95569307524588,
                                40.812601962677824
                            ],
                            [
                                -73.95107810229581,
                                40.813467722060906
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "45943f43-7c83-43a6-9f17-dfc46ce0368b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94924997653688,
                                40.812695031368364
                            ],
                            [
                                -73.95107810229581,
                                40.813467722060906
                            ],
                            [
                                -73.949953588877,
                                40.81367867845313
                            ],
                            [
                                -73.94924997653688,
                                40.812695031368364
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b905871b-fb8e-497d-b407-1df9d03ce673"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94924997653688,
                                40.812695031368364
                            ],
                            [
                                -73.94920037512051,
                                40.81267406642513
                            ],
                            [
                                -73.94751792825666,
                                40.81032768318239
                            ],
                            [
                                -73.95321319864895,
                                40.80757949380406
                            ],
                            [
                                -73.95569307524588,
                                40.812601962677824
                            ],
                            [
                                -73.95318787873435,
                                40.80761358135632
                            ],
                            [
                                -73.9475735779902,
                                40.810351433268515
                            ],
                            [
                                -73.94924997653688,
                                40.812695031368364
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c9449a37-a999-4bdc-b02d-cb2d72560313"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93124185398531,
                                40.814219654783805
                            ],
                            [
                                -73.93266182884742,
                                40.81715988322071
                            ],
                            [
                                -73.92555637343298,
                                40.82202474128368
                            ],
                            [
                                -73.92672181444493,
                                40.813343819674024
                            ],
                            [
                                -73.92436250291807,
                                40.811937700161586
                            ],
                            [
                                -73.92464501371262,
                                40.81072901844908
                            ],
                            [
                                -73.91219998973897,
                                40.80468899777245
                            ],
                            [
                                -73.91156649000015,
                                40.80431144002574
                            ],
                            [
                                -73.9282560065566,
                                40.81214617139373
                            ],
                            [
                                -73.92878884869347,
                                40.814855661441904
                            ],
                            [
                                -73.93187167091584,
                                40.813835065643474
                            ],
                            [
                                -73.93143932493884,
                                40.81359262467117
                            ],
                            [
                                -73.93144091346575,
                                40.81358788040694
                            ],
                            [
                                -73.93215571192047,
                                40.81198598527283
                            ],
                            [
                                -73.93217050986816,
                                40.81187840297701
                            ],
                            [
                                -73.9306500232149,
                                40.81019977483999
                            ],
                            [
                                -73.93222987413539,
                                40.810091315274946
                            ],
                            [
                                -73.93613561693839,
                                40.81478150139749
                            ],
                            [
                                -73.93595006984967,
                                40.81490853903237
                            ],
                            [
                                -73.93124185398531,
                                40.814219654783805
                            ]
                        ],
                        [
                            [
                                -73.92627178453874,
                                40.81830422270401
                            ],
                            [
                                -73.92704658596287,
                                40.81894078097275
                            ],
                            [
                                -73.9276264808181,
                                40.81766442705093
                            ],
                            [
                                -73.92627178453874,
                                40.81830422270401
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "fc5ae3c6-a7bf-4f97-a1f6-4a3489eb6424"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93266182884742,
                                40.81715988322071
                            ],
                            [
                                -73.93124185398531,
                                40.814219654783805
                            ],
                            [
                                -73.93595006984967,
                                40.81490853903237
                            ],
                            [
                                -73.93266182884742,
                                40.81715988322071
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "62229bc1-2308-44d7-9474-66e36dfa0890"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93266182884742,
                                40.81715988322071
                            ],
                            [
                                -73.93380680283627,
                                40.819530689332424
                            ],
                            [
                                -73.92749973212479,
                                40.82316688824847
                            ],
                            [
                                -73.92229799165801,
                                40.82077041400278
                            ],
                            [
                                -73.92436250291807,
                                40.811937700161586
                            ],
                            [
                                -73.92672181444493,
                                40.813343819674024
                            ],
                            [
                                -73.92555637343298,
                                40.82202474128368
                            ],
                            [
                                -73.93266182884742,
                                40.81715988322071
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "10818f6e-888f-4cb1-b2d2-610a455a73c2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91219998973897,
                                40.80468899777245
                            ],
                            [
                                -73.92464501371262,
                                40.81072901844908
                            ],
                            [
                                -73.92436250291807,
                                40.811937700161586
                            ],
                            [
                                -73.91219998973897,
                                40.80468899777245
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b47b8faf-bcbd-4db1-9435-04767a58de59"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93561287829141,
                                40.806861233939955
                            ],
                            [
                                -73.93593743151872,
                                40.80626284402392
                            ],
                            [
                                -73.93555232476781,
                                40.80613877022915
                            ],
                            [
                                -73.9365972135522,
                                40.80239891861133
                            ],
                            [
                                -73.93475621159881,
                                40.801622966277456
                            ],
                            [
                                -73.9398558558386,
                                40.80122705883991
                            ],
                            [
                                -73.94211427615842,
                                40.8062373307076
                            ],
                            [
                                -73.93524599195632,
                                40.80723519243004
                            ],
                            [
                                -73.9353157781312,
                                40.806985414709274
                            ],
                            [
                                -73.93561287829141,
                                40.806861233939955
                            ]
                        ],
                        [
                            [
                                -73.9380660515682,
                                40.80350656244579
                            ],
                            [
                                -73.9366502527815,
                                40.80393183414649
                            ],
                            [
                                -73.93748587823521,
                                40.80429613376293
                            ],
                            [
                                -73.9380660515682,
                                40.80350656244579
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6c01d465-1187-4a39-b456-8085f1f91f70"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93555232476781,
                                40.80613877022915
                            ],
                            [
                                -73.93593743151872,
                                40.80626284402392
                            ],
                            [
                                -73.93561287829141,
                                40.806861233939955
                            ],
                            [
                                -73.9353157781312,
                                40.806985414709274
                            ],
                            [
                                -73.93555232476781,
                                40.80613877022915
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8f0913da-4629-4df9-b2f0-d517bffa32c1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94709133118823,
                                40.76465365860415
                            ],
                            [
                                -73.94643143560444,
                                40.765244805674065
                            ],
                            [
                                -73.94620239133715,
                                40.76512963036765
                            ],
                            [
                                -73.9470978050629,
                                40.764639720818046
                            ],
                            [
                                -73.94709133118823,
                                40.76465365860415
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8b377f40-7329-478b-9007-d299beaa1c36"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94643143560444,
                                40.765244805674065
                            ],
                            [
                                -73.94709133118823,
                                40.76465365860415
                            ],
                            [
                                -73.94674379607741,
                                40.765401876678766
                            ],
                            [
                                -73.94643143560444,
                                40.765244805674065
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "06be963d-999a-4c6d-8efe-623ed2d72d69"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84566651733837,
                                40.75461457386916
                            ],
                            [
                                -73.84446893888611,
                                40.75338892506174
                            ],
                            [
                                -73.84460539588375,
                                40.75315272092131
                            ],
                            [
                                -73.8476006314676,
                                40.754036358508245
                            ],
                            [
                                -73.84565411102251,
                                40.75461885323378
                            ],
                            [
                                -73.84566651733837,
                                40.75461457386916
                            ]
                        ],
                        [
                            [
                                -73.84550883425992,
                                40.75432993728262
                            ],
                            [
                                -73.84558541384126,
                                40.75430658089274
                            ],
                            [
                                -73.84535984328016,
                                40.75406435934162
                            ],
                            [
                                -73.84550883425992,
                                40.75432993728262
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a836b9c1-ac41-4bd9-9316-4704d0c661f1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84446893888611,
                                40.75338892506174
                            ],
                            [
                                -73.84566651733837,
                                40.75461457386916
                            ],
                            [
                                -73.84565411102251,
                                40.75461885323378
                            ],
                            [
                                -73.84336217244628,
                                40.75530471406575
                            ],
                            [
                                -73.84446893888611,
                                40.75338892506174
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3467a676-7905-41f1-a374-1ede4d8c0553"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8347923281791,
                                40.7192406409624
                            ],
                            [
                                -73.83318354803444,
                                40.71898182977464
                            ],
                            [
                                -73.8295748467219,
                                40.715683731176654
                            ],
                            [
                                -73.8307314741089,
                                40.7147582372765
                            ],
                            [
                                -73.84241414872591,
                                40.72075091234468
                            ],
                            [
                                -73.84251429756536,
                                40.72202563259381
                            ],
                            [
                                -73.84572007143933,
                                40.72244669873533
                            ],
                            [
                                -73.85610035863358,
                                40.727771308921675
                            ],
                            [
                                -73.83874679829808,
                                40.72406624832457
                            ],
                            [
                                -73.83499379799913,
                                40.72063627062563
                            ],
                            [
                                -73.8347923281791,
                                40.7192406409624
                            ]
                        ],
                        [
                            [
                                -73.8377256249241,
                                40.71839803787969
                            ],
                            [
                                -73.8369797070313,
                                40.7181348036342
                            ],
                            [
                                -73.83777990023526,
                                40.71848629848468
                            ],
                            [
                                -73.8377256249241,
                                40.71839803787969
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ee81b45d-246d-4932-a914-8f277f9cb0eb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83318354803444,
                                40.71898182977464
                            ],
                            [
                                -73.8347923281791,
                                40.7192406409624
                            ],
                            [
                                -73.83499379799913,
                                40.72063627062563
                            ],
                            [
                                -73.83318354803444,
                                40.71898182977464
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9e1247ec-b6ef-489a-8c3e-939c92919b45"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83318354803444,
                                40.71898182977464
                            ],
                            [
                                -73.82379553964479,
                                40.7174715416162
                            ],
                            [
                                -73.82717597731406,
                                40.717221534326825
                            ],
                            [
                                -73.82800320980806,
                                40.71694130158641
                            ],
                            [
                                -73.8295748467219,
                                40.715683731176654
                            ],
                            [
                                -73.83318354803444,
                                40.71898182977464
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d9279bc2-aec3-4c81-b0b6-03c74cd62dc6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8307314741089,
                                40.7147582372765
                            ],
                            [
                                -73.8295748467219,
                                40.715683731176654
                            ],
                            [
                                -73.82908996488527,
                                40.71524058336057
                            ],
                            [
                                -73.82936635114942,
                                40.71499875605269
                            ],
                            [
                                -73.83057689239534,
                                40.71467894396301
                            ],
                            [
                                -73.8307314741089,
                                40.7147582372765
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "57a4183d-76d0-4ec3-8a63-cf9612942968"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83057689239534,
                                40.71467894396301
                            ],
                            [
                                -73.83095561102421,
                                40.714578890539386
                            ],
                            [
                                -73.8307314741089,
                                40.7147582372765
                            ],
                            [
                                -73.83057689239534,
                                40.71467894396301
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "78b46335-438d-40d3-8432-57543f25974f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83057689239534,
                                40.71467894396301
                            ],
                            [
                                -73.82936635114942,
                                40.71499875605269
                            ],
                            [
                                -73.82908996488527,
                                40.71524058336057
                            ],
                            [
                                -73.82893385501794,
                                40.71509790994707
                            ],
                            [
                                -73.83041501858935,
                                40.71459591014129
                            ],
                            [
                                -73.83057689239534,
                                40.71467894396301
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "891e90de-d3bc-475b-9431-7b8f9c92c459"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.82908996488527,
                                40.71524058336057
                            ],
                            [
                                -73.82750536257929,
                                40.71662704918546
                            ],
                            [
                                -73.82708694708288,
                                40.716858758251924
                            ],
                            [
                                -73.82650282924747,
                                40.71708526467334
                            ],
                            [
                                -73.82590601372554,
                                40.71717076746057
                            ],
                            [
                                -73.82317140366011,
                                40.7174235678509
                            ],
                            [
                                -73.82286719457905,
                                40.71741636769793
                            ],
                            [
                                -73.82223346907871,
                                40.717368822157624
                            ],
                            [
                                -73.82893385501794,
                                40.71509790994707
                            ],
                            [
                                -73.82908996488527,
                                40.71524058336057
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4be171d2-4383-4971-9331-2c6ecdb2e836"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83041501858935,
                                40.71459591014129
                            ],
                            [
                                -73.82893385501794,
                                40.71509790994707
                            ],
                            [
                                -73.82874007906625,
                                40.7149208123878
                            ],
                            [
                                -73.8303565088966,
                                40.71456589735838
                            ],
                            [
                                -73.83041501858935,
                                40.71459591014129
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ea4982db-a6c3-4390-aeec-a0bd85efa3ff"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8303565088966,
                                40.71456589735838
                            ],
                            [
                                -73.83077411091263,
                                40.71447420563814
                            ],
                            [
                                -73.83041501858935,
                                40.71459591014129
                            ],
                            [
                                -73.8303565088966,
                                40.71456589735838
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "73f6b840-83a1-4aed-ab9e-c1cfd3a05c1c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8303565088966,
                                40.71456589735838
                            ],
                            [
                                -73.82874007906625,
                                40.7149208123878
                            ],
                            [
                                -73.82578710732463,
                                40.71222200439085
                            ],
                            [
                                -73.8303565088966,
                                40.71456589735838
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d451f84e-66dc-4862-8bb6-c360e0efe058"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.82076790091567,
                                40.70913782953658
                            ],
                            [
                                -73.82025668124987,
                                40.70870858389863
                            ],
                            [
                                -73.82009096287157,
                                40.708463037834285
                            ],
                            [
                                -73.82076790091567,
                                40.70913782953658
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9dba03c5-e642-402d-9c70-3b650d9c07ff"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.82025668124987,
                                40.70870858389863
                            ],
                            [
                                -73.82076790091567,
                                40.70913782953658
                            ],
                            [
                                -73.82200288771496,
                                40.71036890061002
                            ],
                            [
                                -73.82077661369028,
                                40.709293374755966
                            ],
                            [
                                -73.82116193165835,
                                40.70999155590527
                            ],
                            [
                                -73.82093067206657,
                                40.709913159745405
                            ],
                            [
                                -73.8209714106647,
                                40.70976760340317
                            ],
                            [
                                -73.82025668124987,
                                40.70870858389863
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d8e4557b-ad01-48aa-8440-c251be83ac60"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8109782327924,
                                40.70548619640649
                            ],
                            [
                                -73.81089406819488,
                                40.70550881248386
                            ],
                            [
                                -73.79977132728271,
                                40.70429385465113
                            ],
                            [
                                -73.80032954028219,
                                40.70326761236848
                            ],
                            [
                                -73.80794651906963,
                                40.70514237043353
                            ],
                            [
                                -73.79985395240786,
                                40.7025167337226
                            ],
                            [
                                -73.80520238776052,
                                40.69621514837104
                            ],
                            [
                                -73.81279397613265,
                                40.69937686165016
                            ],
                            [
                                -73.8109782327924,
                                40.70548619640649
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b49f0433-2bdb-49ce-8ef3-8edffb745e5f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.81089406819488,
                                40.70550881248386
                            ],
                            [
                                -73.8109782327924,
                                40.70548619640649
                            ],
                            [
                                -73.81096907601452,
                                40.70551700572856
                            ],
                            [
                                -73.81089406819488,
                                40.70550881248386
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "79ab51eb-c0c2-44fc-86ae-09c2a75f9467"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.81089406819488,
                                40.70550881248386
                            ],
                            [
                                -73.80288589135175,
                                40.70766070951017
                            ],
                            [
                                -73.8010442551936,
                                40.70800376812011
                            ],
                            [
                                -73.80107598548607,
                                40.70808238171157
                            ],
                            [
                                -73.80109558672557,
                                40.70808393225979
                            ],
                            [
                                -73.80109248933447,
                                40.70808210932716
                            ],
                            [
                                -73.80175223274038,
                                40.70789106339505
                            ],
                            [
                                -73.80180526388486,
                                40.70787456229813
                            ],
                            [
                                -73.80183839232966,
                                40.70794218556582
                            ],
                            [
                                -73.79709330108066,
                                40.70921725078332
                            ],
                            [
                                -73.79977132728271,
                                40.70429385465113
                            ],
                            [
                                -73.81089406819488,
                                40.70550881248386
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "65bc5cc3-50cf-45f9-b7d8-c5290faebf9a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.80288589135175,
                                40.70766070951017
                            ],
                            [
                                -73.80183839232966,
                                40.70794218556582
                            ],
                            [
                                -73.80180526388486,
                                40.70787456229813
                            ],
                            [
                                -73.80175223274038,
                                40.70789106339505
                            ],
                            [
                                -73.80109248933447,
                                40.70808210932716
                            ],
                            [
                                -73.80109558672557,
                                40.70808393225979
                            ],
                            [
                                -73.80107598548607,
                                40.70808238171157
                            ],
                            [
                                -73.8010442551936,
                                40.70800376812011
                            ],
                            [
                                -73.80288589135175,
                                40.70766070951017
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "550b0283-9d5f-43e2-9fd2-4af6c40a8107"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.80183839232966,
                                40.70794218556582
                            ],
                            [
                                -73.80288589135175,
                                40.70766070951017
                            ],
                            [
                                -73.80449361610303,
                                40.707361223742645
                            ],
                            [
                                -73.8035439135914,
                                40.70859826080164
                            ],
                            [
                                -73.80267546100228,
                                40.70775656149485
                            ],
                            [
                                -73.801861131909,
                                40.70798846350134
                            ],
                            [
                                -73.80116208451447,
                                40.708187276183644
                            ],
                            [
                                -73.80186101872128,
                                40.70798837157147
                            ],
                            [
                                -73.80183839232966,
                                40.70794218556582
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "781d1e4f-426b-4307-bad1-33c2dc49aca1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.81279397613265,
                                40.69937686165016
                            ],
                            [
                                -73.80520238776052,
                                40.69621514837104
                            ],
                            [
                                -73.79985395240786,
                                40.7025167337226
                            ],
                            [
                                -73.80794651906963,
                                40.70514237043353
                            ],
                            [
                                -73.80032954028219,
                                40.70326761236848
                            ],
                            [
                                -73.79977132728271,
                                40.70429385465113
                            ],
                            [
                                -73.79459763120273,
                                40.70372872213332
                            ],
                            [
                                -73.80310390888377,
                                40.695228649122974
                            ],
                            [
                                -73.81339957401693,
                                40.69733923875448
                            ],
                            [
                                -73.81279397613265,
                                40.69937686165016
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "13f36529-9403-410e-8962-415a3d9171db"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8354444842077,
                                40.695673667923906
                            ],
                            [
                                -73.83635454691826,
                                40.69530088589912
                            ],
                            [
                                -73.8386729972059,
                                40.6959326089294
                            ],
                            [
                                -73.83742202400248,
                                40.699622129424405
                            ],
                            [
                                -73.8354444842077,
                                40.695673667923906
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "52d6a497-cf42-4a27-804e-b6a630dd227f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83635454691826,
                                40.69530088589912
                            ],
                            [
                                -73.8354444842077,
                                40.695673667923906
                            ],
                            [
                                -73.83508445581792,
                                40.69495481602525
                            ],
                            [
                                -73.83635454691826,
                                40.69530088589912
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "cf9d138d-7634-46a8-853a-80f3b6c9339f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84080136923654,
                                40.67971025098798
                            ],
                            [
                                -73.84082383169402,
                                40.68004605563797
                            ],
                            [
                                -73.8406201045048,
                                40.67981415963768
                            ],
                            [
                                -73.84080136923654,
                                40.67971025098798
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "be4db49d-3bf0-4416-a935-8d95c70702e0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86224240263397,
                                40.667051280790595
                            ],
                            [
                                -73.86225441985948,
                                40.66704360009373
                            ],
                            [
                                -73.86260030661973,
                                40.66711405728999
                            ],
                            [
                                -73.86331416364003,
                                40.66756581259426
                            ],
                            [
                                -73.86252502240903,
                                40.66819378610622
                            ],
                            [
                                -73.86224240263397,
                                40.667051280790595
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "84d53966-9bb2-48e4-9698-90373461985b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86225441985948,
                                40.66704360009373
                            ],
                            [
                                -73.86224240263397,
                                40.667051280790595
                            ],
                            [
                                -73.86223976418718,
                                40.6670406147306
                            ],
                            [
                                -73.86225441985948,
                                40.66704360009373
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "398e1d32-2362-4b03-91a6-a540b81ea376"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88987299825861,
                                40.63379325364021
                            ],
                            [
                                -73.88988661985343,
                                40.63379870657202
                            ],
                            [
                                -73.89019494236688,
                                40.63424994800553
                            ],
                            [
                                -73.8898566323393,
                                40.634234207875906
                            ],
                            [
                                -73.88987299825861,
                                40.63379325364021
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "bf7220f1-0a0b-4c07-bfbf-716eabba78fb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88988661985343,
                                40.63379870657202
                            ],
                            [
                                -73.88987299825861,
                                40.63379325364021
                            ],
                            [
                                -73.88987350809023,
                                40.633779517020294
                            ],
                            [
                                -73.88988661985343,
                                40.63379870657202
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4cb2d16d-70fd-475f-ad1d-035879a8101a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96141059055014,
                                40.63001754059505
                            ],
                            [
                                -73.96142126554227,
                                40.629957668662975
                            ],
                            [
                                -73.96311697197707,
                                40.629858946947344
                            ],
                            [
                                -73.96312107477084,
                                40.62986558554546
                            ],
                            [
                                -73.96141059055014,
                                40.63001754059505
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "093c459d-012d-49f0-ac25-bf69483ea367"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96142126554227,
                                40.629957668662975
                            ],
                            [
                                -73.96181459803712,
                                40.62775161772421
                            ],
                            [
                                -73.96311697197707,
                                40.629858946947344
                            ],
                            [
                                -73.96142126554227,
                                40.629957668662975
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c754a4a1-4218-441e-8705-ce1e0729a0a5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96312107477084,
                                40.62986558554546
                            ],
                            [
                                -73.96311697197707,
                                40.629858946947344
                            ],
                            [
                                -73.96334569020063,
                                40.62984563128435
                            ],
                            [
                                -73.96312107477084,
                                40.62986558554546
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f6b9ae32-f32e-431d-b6fe-02719a38103d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97238428356147,
                                40.60804891943908
                            ],
                            [
                                -73.97235374640691,
                                40.60935714317209
                            ],
                            [
                                -73.9719840058554,
                                40.609310097089704
                            ],
                            [
                                -73.97146108934199,
                                40.60898665702242
                            ],
                            [
                                -73.97238428356147,
                                40.60804891943908
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "84305a4c-b597-43ce-8132-5fb5556b8a9a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97366826312931,
                                40.60952440332727
                            ],
                            [
                                -73.97321330349935,
                                40.608168784585224
                            ],
                            [
                                -73.97444759330128,
                                40.60962356591933
                            ],
                            [
                                -73.97366826312931,
                                40.60952440332727
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a4238e92-ab38-4e78-b603-f24bfdaa1073"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01183887824462,
                                40.72475376587922
                            ],
                            [
                                -74.01180174548404,
                                40.72496424623117
                            ],
                            [
                                -74.01173577680284,
                                40.72495756497383
                            ],
                            [
                                -74.01183887824462,
                                40.72475376587922
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d086d0ce-09ac-4e80-82ed-75115008af20"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00264466083753,
                                40.654141249799636
                            ],
                            [
                                -74.00260464598759,
                                40.654252942814956
                            ],
                            [
                                -74.00218181107391,
                                40.65432695210223
                            ],
                            [
                                -74.002303296108,
                                40.65509409745541
                            ],
                            [
                                -74.00187074504048,
                                40.65630147254437
                            ],
                            [
                                -74.00152822561928,
                                40.653944766787724
                            ],
                            [
                                -74.00264466083753,
                                40.654141249799636
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "a81fe42b-5ca2-4e9d-b837-9555250b4069"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00260464598759,
                                40.654252942814956
                            ],
                            [
                                -74.00264466083753,
                                40.654141249799636
                            ],
                            [
                                -74.00261233973009,
                                40.65425159617025
                            ],
                            [
                                -74.00260464598759,
                                40.654252942814956
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "e31b59fc-b09f-41dc-a8cf-e1ebd3acae11"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00260464598759,
                                40.654252942814956
                            ],
                            [
                                -74.002303296108,
                                40.65509409745541
                            ],
                            [
                                -74.00218181107391,
                                40.65432695210223
                            ],
                            [
                                -74.00260464598759,
                                40.654252942814956
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "a520ce55-eba2-4467-be38-db5aa1b2b21f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.002303296108,
                                40.65509409745541
                            ],
                            [
                                -74.00260464598759,
                                40.654252942814956
                            ],
                            [
                                -74.00261233973009,
                                40.65425159617025
                            ],
                            [
                                -74.0023251481234,
                                40.655232087062174
                            ],
                            [
                                -74.002303296108,
                                40.65509409745541
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "8b20e3d7-15f4-42b8-ba4c-eed10896b481"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00187074504048,
                                40.65630147254437
                            ],
                            [
                                -74.002303296108,
                                40.65509409745541
                            ],
                            [
                                -74.0023251481234,
                                40.655232087062174
                            ],
                            [
                                -74.00191756354825,
                                40.656623607407276
                            ],
                            [
                                -74.00187074504048,
                                40.65630147254437
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "613a5caf-8511-44ba-aad8-0865dec6e76c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00187074504048,
                                40.65630147254437
                            ],
                            [
                                -74.0016701028403,
                                40.6568615229349
                            ],
                            [
                                -74.0012011642957,
                                40.65375766903923
                            ],
                            [
                                -74.00264466083753,
                                40.654141249799636
                            ],
                            [
                                -74.00152822561928,
                                40.653944766787724
                            ],
                            [
                                -74.00187074504048,
                                40.65630147254437
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "01e147d2-9c81-4553-a2a6-13eb92249afe"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0023251481234,
                                40.655232087062174
                            ],
                            [
                                -74.00261233973009,
                                40.65425159617025
                            ],
                            [
                                -74.00274456876849,
                                40.65422845196769
                            ],
                            [
                                -74.00246254178671,
                                40.656099691135
                            ],
                            [
                                -74.0023251481234,
                                40.655232087062174
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f348934a-0782-4c12-9326-4d448c7d8bbb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99899319156559,
                                40.71062023217553
                            ],
                            [
                                -73.99871220932451,
                                40.71017996806556
                            ],
                            [
                                -73.99917021088054,
                                40.71046300612526
                            ],
                            [
                                -73.99899319156559,
                                40.71062023217553
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "dab07289-4dd0-437a-aadb-f96e027343a4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98811394646127,
                                40.71861110119638
                            ],
                            [
                                -73.98811391345023,
                                40.718611164382
                            ],
                            [
                                -73.98811394638817,
                                40.718611101174254
                            ],
                            [
                                -73.98811394646127,
                                40.71861110119638
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "d1b838d2-8f66-492a-bdbb-567f927e35a0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98811394646127,
                                40.71861110119638
                            ],
                            [
                                -73.9881140293329,
                                40.71861107450935
                            ],
                            [
                                -73.98815430619699,
                                40.71862331488969
                            ],
                            [
                                -73.98811394646127,
                                40.71861110119638
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "11c034c3-1891-454d-9b42-e3f03ba9c225"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97982099463232,
                                40.68651216832579
                            ],
                            [
                                -73.97948292894651,
                                40.686622835711916
                            ],
                            [
                                -73.97946538305237,
                                40.68660132539344
                            ],
                            [
                                -73.97982099463232,
                                40.68651216832579
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "1f0af9ec-8913-4f0d-9971-cb5d02a50d8f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97568581402092,
                                40.789679718358784
                            ],
                            [
                                -73.97554428714285,
                                40.78969268291829
                            ],
                            [
                                -73.97558732273318,
                                40.789636365756614
                            ],
                            [
                                -73.97568581402092,
                                40.789679718358784
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "168a459c-ddaf-4ebf-a262-65c84c71037e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96288945383412,
                                40.662326994299214
                            ],
                            [
                                -73.9622460894108,
                                40.66161399257867
                            ],
                            [
                                -73.96292710222768,
                                40.66208563318629
                            ],
                            [
                                -73.96282695924006,
                                40.661408863368735
                            ],
                            [
                                -73.96295038835648,
                                40.66224241327332
                            ],
                            [
                                -73.96288945383412,
                                40.662326994299214
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "17719b85-3258-453f-9a78-4dd381a93bdc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9622460894108,
                                40.66161399257867
                            ],
                            [
                                -73.96270893108958,
                                40.660611787634615
                            ],
                            [
                                -73.96274815437953,
                                40.66087667302427
                            ],
                            [
                                -73.9622460894108,
                                40.66161399257867
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "d2cf785a-3851-4de8-a2eb-9bc27ec0ae4a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96274815437953,
                                40.66087667302427
                            ],
                            [
                                -73.96282695924006,
                                40.661408863368735
                            ],
                            [
                                -73.96292710222768,
                                40.66208563318629
                            ],
                            [
                                -73.9622460894108,
                                40.66161399257867
                            ],
                            [
                                -73.96274815437953,
                                40.66087667302427
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "a4c5e308-f76b-4415-bd91-dc9ad3e39e04"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96282695924006,
                                40.661408863368735
                            ],
                            [
                                -73.96274815437953,
                                40.66087667302427
                            ],
                            [
                                -73.9627482000439,
                                40.660876605962756
                            ],
                            [
                                -73.96282695924006,
                                40.661408863368735
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c00ac99d-b884-4c7e-b20c-27c998256acf"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95132846580923,
                                40.72471597927168
                            ],
                            [
                                -73.95127708217497,
                                40.72463497327441
                            ],
                            [
                                -73.95123985955357,
                                40.72456218216459
                            ],
                            [
                                -73.95124128321666,
                                40.72456201399583
                            ],
                            [
                                -73.95132846580923,
                                40.72471597927168
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "5d687e99-d84b-44d2-95e0-62ef53f5dc83"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95127708217497,
                                40.72463497327441
                            ],
                            [
                                -73.95132846580923,
                                40.72471597927168
                            ],
                            [
                                -73.95124128321666,
                                40.72456201399583
                            ],
                            [
                                -73.95556188528178,
                                40.72405164725793
                            ],
                            [
                                -73.95330612128026,
                                40.72818818031754
                            ],
                            [
                                -73.94722177077549,
                                40.7250368148272
                            ],
                            [
                                -73.95123985955357,
                                40.72456218216459
                            ],
                            [
                                -73.95127708217497,
                                40.72463497327441
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "f9cf6289-fede-41c7-a934-603c67427509"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95123985955357,
                                40.72456218216459
                            ],
                            [
                                -73.95122570842219,
                                40.72453450876276
                            ],
                            [
                                -73.95124128321666,
                                40.72456201399583
                            ],
                            [
                                -73.95123985955357,
                                40.72456218216459
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "4a681093-2c17-42d5-ac56-7d92e7daf492"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84550883425992,
                                40.75432993728262
                            ],
                            [
                                -73.84535984328016,
                                40.75406435934162
                            ],
                            [
                                -73.84558541384126,
                                40.75430658089274
                            ],
                            [
                                -73.84550883425992,
                                40.75432993728262
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "47f11933-a0c3-47ac-bcd0-4ca4ba4069c7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.07586826293915,
                                40.60675156189064
                            ],
                            [
                                -74.07586833749932,
                                40.606751437767336
                            ],
                            [
                                -74.07592254094524,
                                40.606782306951004
                            ],
                            [
                                -74.07586826293915,
                                40.60675156189064
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6b5604b1-a3e2-4d8a-98d9-934a9891934b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.07511151226232,
                                40.76103978424597
                            ],
                            [
                                -74.0772636295447,
                                40.76346732867459
                            ],
                            [
                                -74.07288617869105,
                                40.7628987077512
                            ],
                            [
                                -74.07511151226232,
                                40.76103978424597
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "cc50bdab-d3cd-4205-9562-d50d53e599c2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.06906763265773,
                                40.604507163608325
                            ],
                            [
                                -74.07055229180253,
                                40.60509857788618
                            ],
                            [
                                -74.0682080967461,
                                40.604289792195495
                            ],
                            [
                                -74.06906763265773,
                                40.604507163608325
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "be493299-31bb-4c0c-945d-561c0d12d99f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.06429334384082,
                                40.7315265652291
                            ],
                            [
                                -74.06284479574975,
                                40.73231772255466
                            ],
                            [
                                -74.06281396525546,
                                40.73200506139096
                            ],
                            [
                                -74.06429334384082,
                                40.7315265652291
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ccec8c55-5025-4457-9e16-1f26a1178202"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.06284479574975,
                                40.73231772255466
                            ],
                            [
                                -74.06102022340697,
                                40.733314254036024
                            ],
                            [
                                -74.0605645116566,
                                40.73273263371354
                            ],
                            [
                                -74.06281396525546,
                                40.73200506139096
                            ],
                            [
                                -74.06284479574975,
                                40.73231772255466
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "998def91-78b6-43c9-ae4a-bd32025e9309"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.06281396525546,
                                40.73200506139096
                            ],
                            [
                                -74.0605645116566,
                                40.73273263371354
                            ],
                            [
                                -74.06102022340697,
                                40.733314254036024
                            ],
                            [
                                -74.06284479574975,
                                40.73231772255466
                            ],
                            [
                                -74.06311220476927,
                                40.735029596532286
                            ],
                            [
                                -74.05978901134317,
                                40.732637941593445
                            ],
                            [
                                -74.0627152976357,
                                40.731004443869104
                            ],
                            [
                                -74.06281396525546,
                                40.73200506139096
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4b76995f-2d7d-43ea-8b81-ee5fc4662cd8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.05843892611614,
                                40.79282570921729
                            ],
                            [
                                -74.05817208964584,
                                40.792738997891256
                            ],
                            [
                                -74.05820898255624,
                                40.7923389011074
                            ],
                            [
                                -74.05843892611614,
                                40.79282570921729
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ddefe89f-844e-4822-88c2-ef4da6de1fb0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.058299510279,
                                40.79459660204416
                            ],
                            [
                                -74.0582947545979,
                                40.79441541334016
                            ],
                            [
                                -74.05830428662135,
                                40.79469406564446
                            ],
                            [
                                -74.058299510279,
                                40.79459660204416
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "caef71e6-26d9-4bac-ace4-c513c0985589"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.05811921211327,
                                40.79129485636981
                            ],
                            [
                                -74.05723593552533,
                                40.79080807988308
                            ],
                            [
                                -74.0573955715412,
                                40.79023585569569
                            ],
                            [
                                -74.05811921211327,
                                40.79129485636981
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "fb659ca4-761b-4b91-b015-f40b9a314068"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0562047782753,
                                40.78912371919167
                            ],
                            [
                                -74.05548306009794,
                                40.788748949277085
                            ],
                            [
                                -74.05504824316108,
                                40.78833131009276
                            ],
                            [
                                -74.0562047782753,
                                40.78912371919167
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9d6dd185-3003-41c6-bd10-d373da5c7baa"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.05362206344296,
                                40.7877440517055
                            ],
                            [
                                -74.05423989145771,
                                40.787903112445534
                            ],
                            [
                                -74.05275871328466,
                                40.787575417509125
                            ],
                            [
                                -74.05362206344296,
                                40.7877440517055
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1d66b715-2f9d-40b3-9e90-d85238798233"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.05088576544313,
                                40.78522460318652
                            ],
                            [
                                -74.04860354964974,
                                40.78398566182665
                            ],
                            [
                                -74.05093362229158,
                                40.78431538985267
                            ],
                            [
                                -74.05088576544313,
                                40.78522460318652
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5088e2ec-8c76-4505-8fdb-07379c6a879e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0425704704898,
                                40.71974369028862
                            ],
                            [
                                -74.04245006749565,
                                40.71965995486927
                            ],
                            [
                                -74.04232477586972,
                                40.719532119055565
                            ],
                            [
                                -74.0425704704898,
                                40.71974369028862
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3288f59f-27e5-4e3d-93c5-cf6aefe2dbf9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0421118535878,
                                40.72506182968286
                            ],
                            [
                                -74.03904922451196,
                                40.72165115843904
                            ],
                            [
                                -74.04218369006445,
                                40.72463588417374
                            ],
                            [
                                -74.04457051034336,
                                40.723584625059964
                            ],
                            [
                                -74.0421118535878,
                                40.72506182968286
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "07810699-4732-4caa-98fe-39f3abc5d632"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03710035981553,
                                40.71948082157695
                            ],
                            [
                                -74.04243781467108,
                                40.717126447553554
                            ],
                            [
                                -74.04646060873853,
                                40.71989222909303
                            ],
                            [
                                -74.04731367706698,
                                40.72193648179786
                            ],
                            [
                                -74.04457051034336,
                                40.723584625059964
                            ],
                            [
                                -74.04218369006445,
                                40.72463588417374
                            ],
                            [
                                -74.03904922451196,
                                40.72165115843904
                            ],
                            [
                                -74.03710035981553,
                                40.71948082157695
                            ]
                        ],
                        [
                            [
                                -74.04232477586972,
                                40.719532119055565
                            ],
                            [
                                -74.04245006749565,
                                40.71965995486927
                            ],
                            [
                                -74.0425704704898,
                                40.71974369028862
                            ],
                            [
                                -74.04232477586972,
                                40.719532119055565
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "079c6b46-94a6-4698-96c6-8f15204cbfec"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03710035981553,
                                40.71948082157695
                            ],
                            [
                                -74.03904922451196,
                                40.72165115843904
                            ],
                            [
                                -74.03687458924313,
                                40.719580409936064
                            ],
                            [
                                -74.03710035981553,
                                40.71948082157695
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c6d00354-fb5d-47a2-a44f-ce8324472701"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03710035981553,
                                40.71948082157695
                            ],
                            [
                                -74.03189031024537,
                                40.71367869346816
                            ],
                            [
                                -74.0338437615094,
                                40.71188719923442
                            ],
                            [
                                -74.03541938405985,
                                40.712301083516735
                            ],
                            [
                                -74.04243781467108,
                                40.717126447553554
                            ],
                            [
                                -74.03710035981553,
                                40.71948082157695
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "89b88050-d8f8-432f-a1d5-dd053922795a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.04646060873853,
                                40.71989222909303
                            ],
                            [
                                -74.04243781467108,
                                40.717126447553554
                            ],
                            [
                                -74.04486049533796,
                                40.71605779281389
                            ],
                            [
                                -74.04646060873853,
                                40.71989222909303
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a40aebf2-ff9c-4b84-9e62-a46d62919946"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.04646060873853,
                                40.71989222909303
                            ],
                            [
                                -74.04844516319042,
                                40.72125666484964
                            ],
                            [
                                -74.04731367706698,
                                40.72193648179786
                            ],
                            [
                                -74.04646060873853,
                                40.71989222909303
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "515817b8-de39-4816-8d2a-d706065913bc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.04457051034336,
                                40.723584625059964
                            ],
                            [
                                -74.04731367706698,
                                40.72193648179786
                            ],
                            [
                                -74.04746875825697,
                                40.7223081110467
                            ],
                            [
                                -74.04457051034336,
                                40.723584625059964
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8ff5e049-9115-4c42-832c-956b6fd13336"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.04088636648731,
                                40.62437053604411
                            ],
                            [
                                -74.04087608976047,
                                40.62429500575521
                            ],
                            [
                                -74.0409059901847,
                                40.62419826879716
                            ],
                            [
                                -74.04088636648731,
                                40.62437053604411
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ae0daa60-c0b9-48f5-af91-0b67cfb0a155"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.04080363703955,
                                40.627797633206356
                            ],
                            [
                                -74.04019008141228,
                                40.627754029186335
                            ],
                            [
                                -74.04049764659925,
                                40.62729353515332
                            ],
                            [
                                -74.04080363703955,
                                40.627797633206356
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ad696c21-c331-4615-ace6-1d5c18d865e0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03995025818087,
                                40.630761472618545
                            ],
                            [
                                -74.03820377786,
                                40.631934408873526
                            ],
                            [
                                -74.03768520290437,
                                40.630937227809575
                            ],
                            [
                                -74.03995025818087,
                                40.630761472618545
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "913858a3-15a7-4d3c-a63c-500ca3a541c1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03796519430371,
                                40.61230461490156
                            ],
                            [
                                -74.0349431314322,
                                40.60869994391245
                            ],
                            [
                                -74.0332904585832,
                                40.60686883306793
                            ],
                            [
                                -74.03593477179288,
                                40.609283959219695
                            ],
                            [
                                -74.03796519430371,
                                40.61230461490156
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8ba76b6c-37a5-4349-949a-59ded5d2cca8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03455992630168,
                                40.72920281805986
                            ],
                            [
                                -74.03453902718101,
                                40.72807902811369
                            ],
                            [
                                -74.03509959191608,
                                40.72657673244828
                            ],
                            [
                                -74.03455992630168,
                                40.72920281805986
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e5a88021-d621-4ba1-ae51-1e703dfd1419"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03453902718101,
                                40.72807902811369
                            ],
                            [
                                -74.03455992630168,
                                40.72920281805986
                            ],
                            [
                                -74.03509959191608,
                                40.72657673244828
                            ],
                            [
                                -74.03630096462855,
                                40.72335709177955
                            ],
                            [
                                -74.03723990981466,
                                40.729451821863265
                            ],
                            [
                                -74.03410940142375,
                                40.73142564278414
                            ],
                            [
                                -74.0334119099113,
                                40.73109966655699
                            ],
                            [
                                -74.03453902718101,
                                40.72807902811369
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ea93adfc-5e87-4a71-8455-3f55c7a15041"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03453902718101,
                                40.72807902811369
                            ],
                            [
                                -74.03451512462351,
                                40.72679373701276
                            ],
                            [
                                -74.03399908166567,
                                40.72675223536605
                            ],
                            [
                                -74.03449744308377,
                                40.726621847219796
                            ],
                            [
                                -74.03355814050165,
                                40.72607703069103
                            ],
                            [
                                -74.03542350463206,
                                40.72500052951789
                            ],
                            [
                                -74.03509959191608,
                                40.72657673244828
                            ],
                            [
                                -74.03453902718101,
                                40.72807902811369
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "807bb756-4fc4-4b4f-aa2b-8f8f6011e17f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03399908166567,
                                40.72675223536605
                            ],
                            [
                                -74.03451512462351,
                                40.72679373701276
                            ],
                            [
                                -74.03453902718101,
                                40.72807902811369
                            ],
                            [
                                -74.0334119099113,
                                40.73109966655699
                            ],
                            [
                                -74.03410940142375,
                                40.73142564278414
                            ],
                            [
                                -74.03723990981466,
                                40.729451821863265
                            ],
                            [
                                -74.03630096462855,
                                40.72335709177955
                            ],
                            [
                                -74.03509959191608,
                                40.72657673244828
                            ],
                            [
                                -74.03542350463206,
                                40.72500052951789
                            ],
                            [
                                -74.03355814050165,
                                40.72607703069103
                            ],
                            [
                                -74.0302760484596,
                                40.724173343820055
                            ],
                            [
                                -74.03580581234563,
                                40.72113897575191
                            ],
                            [
                                -74.0383007132657,
                                40.72213504002431
                            ],
                            [
                                -74.04030269870788,
                                40.72956900340521
                            ],
                            [
                                -74.02802377620958,
                                40.735561264835354
                            ],
                            [
                                -74.02987074849806,
                                40.736174872202334
                            ],
                            [
                                -74.02850809211523,
                                40.73785943345518
                            ],
                            [
                                -74.02879785075774,
                                40.73765414716963
                            ],
                            [
                                -74.02841428823663,
                                40.738173384135756
                            ],
                            [
                                -74.03059801878445,
                                40.73641648839133
                            ],
                            [
                                -74.0369439122336,
                                40.73852474252961
                            ],
                            [
                                -74.02885226544667,
                                40.7422681318992
                            ],
                            [
                                -74.02490728782531,
                                40.740368815310795
                            ],
                            [
                                -74.02518785145149,
                                40.73631734281425
                            ],
                            [
                                -74.02765715218257,
                                40.736711542751124
                            ],
                            [
                                -74.0265592887345,
                                40.735036809755
                            ],
                            [
                                -74.03166364373962,
                                40.7311641468967
                            ],
                            [
                                -74.02793101437672,
                                40.72833984631752
                            ],
                            [
                                -74.03399908166567,
                                40.72675223536605
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "be514e25-6245-4454-adaf-63770d6a50c2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03399908166567,
                                40.72675223536605
                            ],
                            [
                                -74.03295235426982,
                                40.72666805456303
                            ],
                            [
                                -74.03288175477333,
                                40.7269659538111
                            ],
                            [
                                -74.03221871005874,
                                40.72700129858966
                            ],
                            [
                                -74.03209643138226,
                                40.72692058265826
                            ],
                            [
                                -74.03355814050165,
                                40.72607703069103
                            ],
                            [
                                -74.03449744308377,
                                40.726621847219796
                            ],
                            [
                                -74.03399908166567,
                                40.72675223536605
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "32353b50-0f52-4ccf-bc61-0db2d158cc9a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03371162202606,
                                40.716478257702946
                            ],
                            [
                                -74.03384829440833,
                                40.716955224704265
                            ],
                            [
                                -74.02998392800731,
                                40.71702854009502
                            ],
                            [
                                -74.03357926874367,
                                40.71692356899472
                            ],
                            [
                                -74.03349797839698,
                                40.716450270323584
                            ],
                            [
                                -74.03153398809805,
                                40.715937867363856
                            ],
                            [
                                -74.03371162202606,
                                40.716478257702946
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f97e95f4-56ae-4914-991b-49002c1c8dcd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03153398809805,
                                40.715937867363856
                            ],
                            [
                                -74.03349797839698,
                                40.716450270323584
                            ],
                            [
                                -74.03357926874367,
                                40.71692356899472
                            ],
                            [
                                -74.02998392800731,
                                40.71702854009502
                            ],
                            [
                                -74.03153398809805,
                                40.715937867363856
                            ]
                        ],
                        [
                            [
                                -74.03264822693542,
                                40.71675291400556
                            ],
                            [
                                -74.03264824130848,
                                40.71676454807041
                            ],
                            [
                                -74.03253670378786,
                                40.71675113216259
                            ],
                            [
                                -74.03238008970459,
                                40.71675999507764
                            ],
                            [
                                -74.03265212472544,
                                40.71676525277317
                            ],
                            [
                                -74.03264822693542,
                                40.71675291400556
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6b28bce0-c00d-4574-a6bf-3752bf476679"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03358701222142,
                                40.76095001171647
                            ],
                            [
                                -74.03369021966441,
                                40.76307217010684
                            ],
                            [
                                -74.03209484405916,
                                40.76331574072151
                            ],
                            [
                                -74.03164782710243,
                                40.76203811502553
                            ],
                            [
                                -74.03358701222142,
                                40.76095001171647
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "0852a384-1f64-4b3d-8daf-974018286067"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03342372814717,
                                40.77477071742886
                            ],
                            [
                                -74.03319137639869,
                                40.77423806979482
                            ],
                            [
                                -74.03476219707413,
                                40.77451763218808
                            ],
                            [
                                -74.03342372814717,
                                40.77477071742886
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8725d8fb-1783-42a9-b71a-fa61b04481c4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03319137639869,
                                40.77423806979482
                            ],
                            [
                                -74.03275174789044,
                                40.77323025691493
                            ],
                            [
                                -74.03546518196968,
                                40.77438470784596
                            ],
                            [
                                -74.03476219707413,
                                40.77451763218808
                            ],
                            [
                                -74.03319137639869,
                                40.77423806979482
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3bba5682-d462-4510-8ec7-cbb45be96d25"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03546518196968,
                                40.77438470784596
                            ],
                            [
                                -74.03275174789044,
                                40.77323025691493
                            ],
                            [
                                -74.03319137639869,
                                40.77423806979482
                            ],
                            [
                                -74.02265695796387,
                                40.77236323629201
                            ],
                            [
                                -74.0277957442646,
                                40.77072480104403
                            ],
                            [
                                -74.02400115753063,
                                40.768201723195965
                            ],
                            [
                                -74.03194393427795,
                                40.76353510000029
                            ],
                            [
                                -74.0297554891255,
                                40.77152148983068
                            ],
                            [
                                -74.03741039747918,
                                40.774988938209596
                            ],
                            [
                                -74.03476219707413,
                                40.77451763218808
                            ],
                            [
                                -74.03546518196968,
                                40.77438470784596
                            ]
                        ],
                        [
                            [
                                -74.02955615296396,
                                40.76731565768984
                            ],
                            [
                                -74.0295535110187,
                                40.76735549246075
                            ],
                            [
                                -74.02972065425541,
                                40.76806437594004
                            ],
                            [
                                -74.02955615296396,
                                40.76731565768984
                            ]
                        ],
                        [
                            [
                                -74.02941785193607,
                                40.76945350472965
                            ],
                            [
                                -74.02892379110621,
                                40.7683024371958
                            ],
                            [
                                -74.02779046845895,
                                40.768779264702374
                            ],
                            [
                                -74.02804707671142,
                                40.76969075277609
                            ],
                            [
                                -74.02941785193607,
                                40.76945350472965
                            ]
                        ],
                        [
                            [
                                -74.02824824004625,
                                40.77153280545752
                            ],
                            [
                                -74.02934087350275,
                                40.77221389610751
                            ],
                            [
                                -74.02884112543882,
                                40.77112453821772
                            ],
                            [
                                -74.02834842376001,
                                40.771167476345816
                            ],
                            [
                                -74.02824824004625,
                                40.77153280545752
                            ]
                        ],
                        [
                            [
                                -74.0300190745928,
                                40.77168796466484
                            ],
                            [
                                -74.03037676397601,
                                40.77191384631386
                            ],
                            [
                                -74.03083994701458,
                                40.77220620185967
                            ],
                            [
                                -74.0300190745928,
                                40.77168796466484
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "643cac21-f33b-4db1-b8a9-4303bb6e6397"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03265212472544,
                                40.71676525277317
                            ],
                            [
                                -74.03238008970459,
                                40.71675999507764
                            ],
                            [
                                -74.03253670378786,
                                40.71675113216259
                            ],
                            [
                                -74.03264824130848,
                                40.71676454807041
                            ],
                            [
                                -74.03264822693542,
                                40.71675291400556
                            ],
                            [
                                -74.03265212472544,
                                40.71676525277317
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "7cb55e29-abe2-4ac7-bb9d-cddf490cdb38"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03078239644591,
                                40.61613354410113
                            ],
                            [
                                -74.03125942521798,
                                40.615679208699184
                            ],
                            [
                                -74.031623510606,
                                40.61627193700358
                            ],
                            [
                                -74.03078239644591,
                                40.61613354410113
                            ]
                        ],
                        [
                            [
                                -74.03115173697834,
                                40.615927833533206
                            ],
                            [
                                -74.03100983190954,
                                40.61602861659787
                            ],
                            [
                                -74.03125858708981,
                                40.61609512514081
                            ],
                            [
                                -74.03115173697834,
                                40.615927833533206
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e52b25ee-75dd-4ee1-a50c-a9a6b6d9e3c8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03125858708981,
                                40.61609512514081
                            ],
                            [
                                -74.03100983190954,
                                40.61602861659787
                            ],
                            [
                                -74.03115173697834,
                                40.615927833533206
                            ],
                            [
                                -74.03125858708981,
                                40.61609512514081
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f56058d4-b212-4ce3-b285-8d0c23702f48"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03090005418856,
                                40.77519416503581
                            ],
                            [
                                -74.03022558919177,
                                40.775440305506166
                            ],
                            [
                                -74.03034680336464,
                                40.77550263197673
                            ],
                            [
                                -74.03072976235678,
                                40.77533613404741
                            ],
                            [
                                -74.03054629403641,
                                40.77564050510555
                            ],
                            [
                                -74.02994977765346,
                                40.775631107858935
                            ],
                            [
                                -74.02981336578262,
                                40.775295620386125
                            ],
                            [
                                -74.03090005418856,
                                40.77519416503581
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9b784350-6fbd-405b-bb03-988ffe889c22"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03075336918413,
                                40.61692456431531
                            ],
                            [
                                -74.03084508447971,
                                40.61669897691244
                            ],
                            [
                                -74.0307534105957,
                                40.61692455830756
                            ],
                            [
                                -74.03075336918413,
                                40.61692456431531
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c2c6ce27-eb42-44eb-b092-3dc2acc4434d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03084508447971,
                                40.61669897691244
                            ],
                            [
                                -74.0311750850909,
                                40.61686338422775
                            ],
                            [
                                -74.0307534105957,
                                40.61692455830756
                            ],
                            [
                                -74.03084508447971,
                                40.61669897691244
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "45e74cb6-35b8-488c-a85c-8a88a3a17010"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0307534105957,
                                40.61692455830756
                            ],
                            [
                                -74.0311750850909,
                                40.61686338422775
                            ],
                            [
                                -74.03084508447971,
                                40.61669897691244
                            ],
                            [
                                -74.03153940044763,
                                40.617044813869526
                            ],
                            [
                                -74.03065222966292,
                                40.61717353364248
                            ],
                            [
                                -74.0307534105957,
                                40.61692455830756
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "79dc72f7-c7d9-406f-9c9e-d41c9e516bc4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03037676397601,
                                40.77191384631386
                            ],
                            [
                                -74.0300190745928,
                                40.77168796466484
                            ],
                            [
                                -74.03083994701458,
                                40.77220620185967
                            ],
                            [
                                -74.03037676397601,
                                40.77191384631386
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d06b7c51-010b-4fb7-b803-37e62eee8d9c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03084508447971,
                                40.61669897691244
                            ],
                            [
                                -74.03186094252212,
                                40.617204944887106
                            ],
                            [
                                -74.03056116295099,
                                40.61739340113446
                            ],
                            [
                                -74.03009967054177,
                                40.616329649497715
                            ],
                            [
                                -74.03084508447971,
                                40.61669897691244
                            ]
                        ],
                        [
                            [
                                -74.03075336918413,
                                40.61692456431531
                            ],
                            [
                                -74.0307534105957,
                                40.61692455830756
                            ],
                            [
                                -74.03065222966292,
                                40.61717353364248
                            ],
                            [
                                -74.03153940044763,
                                40.617044813869526
                            ],
                            [
                                -74.03084508447971,
                                40.61669897691244
                            ],
                            [
                                -74.03075336918413,
                                40.61692456431531
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8ca33250-07b9-48f4-9df8-fec40d56de33"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0295535110187,
                                40.76735549246075
                            ],
                            [
                                -74.02955615296396,
                                40.76731565768984
                            ],
                            [
                                -74.02972065425541,
                                40.76806437594004
                            ],
                            [
                                -74.0295535110187,
                                40.76735549246075
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "929a6687-4aeb-4d6a-8a6a-4d2b8717abb1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02934087350275,
                                40.77221389610751
                            ],
                            [
                                -74.02824824004625,
                                40.77153280545752
                            ],
                            [
                                -74.02834842376001,
                                40.771167476345816
                            ],
                            [
                                -74.02884112543882,
                                40.77112453821772
                            ],
                            [
                                -74.02934087350275,
                                40.77221389610751
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e5b8b6fd-4d7b-4c4d-bf35-c97cb06d2545"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02930298076743,
                                40.755397445447926
                            ],
                            [
                                -74.02931208698946,
                                40.75531801604662
                            ],
                            [
                                -74.02935080707316,
                                40.75524498440979
                            ],
                            [
                                -74.02930298076743,
                                40.755397445447926
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5689b5ae-8541-48cb-82eb-146f17c509b3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02892379110621,
                                40.7683024371958
                            ],
                            [
                                -74.02941785193607,
                                40.76945350472965
                            ],
                            [
                                -74.02804707671142,
                                40.76969075277609
                            ],
                            [
                                -74.02779046845895,
                                40.768779264702374
                            ],
                            [
                                -74.02892379110621,
                                40.7683024371958
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "390aa135-b22b-4382-bfba-f35a4f119efc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02878895847365,
                                40.759916459798994
                            ],
                            [
                                -74.02834129511976,
                                40.75988705976712
                            ],
                            [
                                -74.02859808686907,
                                40.75961054936392
                            ],
                            [
                                -74.02878895847365,
                                40.759916459798994
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "459e0cd5-419d-4876-b6e8-23b775af42dc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02834129511976,
                                40.75988705976712
                            ],
                            [
                                -74.02646584597863,
                                40.75976389072936
                            ],
                            [
                                -74.02665831073146,
                                40.75967665798398
                            ],
                            [
                                -74.02681275378181,
                                40.75976974176617
                            ],
                            [
                                -74.02687211080661,
                                40.75957975521481
                            ],
                            [
                                -74.026872252342,
                                40.759579691065284
                            ],
                            [
                                -74.026812876851,
                                40.75976976422043
                            ],
                            [
                                -74.02693385027537,
                                40.759789843091404
                            ],
                            [
                                -74.02701075299582,
                                40.75951691701398
                            ],
                            [
                                -74.02820261101972,
                                40.75897671915437
                            ],
                            [
                                -74.02859808686907,
                                40.75961054936392
                            ],
                            [
                                -74.02834129511976,
                                40.75988705976712
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "078eaaa7-8db5-403a-ba0c-874cf9cba805"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02646584597863,
                                40.75976389072936
                            ],
                            [
                                -74.02834129511976,
                                40.75988705976712
                            ],
                            [
                                -74.02749408011071,
                                40.760799331148
                            ],
                            [
                                -74.02289372669237,
                                40.75908786490591
                            ],
                            [
                                -74.02279328875541,
                                40.76087750883001
                            ],
                            [
                                -74.02250052061778,
                                40.76130786751197
                            ],
                            [
                                -74.0221408226016,
                                40.76175348300697
                            ],
                            [
                                -74.02187332586956,
                                40.7604321650759
                            ],
                            [
                                -74.02200935595641,
                                40.76169112458784
                            ],
                            [
                                -74.02035366207309,
                                40.76202765305612
                            ],
                            [
                                -74.01973651048975,
                                40.76141449685111
                            ],
                            [
                                -74.02335467292592,
                                40.75768553503449
                            ],
                            [
                                -74.02665831073146,
                                40.75967665798398
                            ],
                            [
                                -74.02646584597863,
                                40.75976389072936
                            ]
                        ],
                        [
                            [
                                -74.02340522651684,
                                40.75829292958729
                            ],
                            [
                                -74.02261881167271,
                                40.75855661056793
                            ],
                            [
                                -74.02122324204791,
                                40.76091034048962
                            ],
                            [
                                -74.02340522651684,
                                40.75829292958729
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "df8ca9f3-23d0-443d-80fd-54f24b5de690"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02665831073146,
                                40.75967665798398
                            ],
                            [
                                -74.02687211080661,
                                40.75957975521481
                            ],
                            [
                                -74.02681275378181,
                                40.75976974176617
                            ],
                            [
                                -74.02665831073146,
                                40.75967665798398
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4be4d0d4-3770-4dd0-a033-d21f19c66da8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.026872252342,
                                40.759579691065284
                            ],
                            [
                                -74.02687211080661,
                                40.75957975521481
                            ],
                            [
                                -74.02771191887219,
                                40.756891745855285
                            ],
                            [
                                -74.026872252342,
                                40.759579691065284
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "0c8d4185-a379-4836-ad3f-b8651435b3a3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.026872252342,
                                40.759579691065284
                            ],
                            [
                                -74.02701075299582,
                                40.75951691701398
                            ],
                            [
                                -74.02693385027537,
                                40.759789843091404
                            ],
                            [
                                -74.026812876851,
                                40.75976976422043
                            ],
                            [
                                -74.026872252342,
                                40.759579691065284
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "08088367-c9e3-482a-bc87-dfc7bcdbfb7a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02820261101972,
                                40.75897671915437
                            ],
                            [
                                -74.02701075299582,
                                40.75951691701398
                            ],
                            [
                                -74.0282845230006,
                                40.75499633505128
                            ],
                            [
                                -74.02521715898763,
                                40.75317627233947
                            ],
                            [
                                -74.02650145160324,
                                40.752347084413785
                            ],
                            [
                                -74.03233369800837,
                                40.755588086239285
                            ],
                            [
                                -74.02859808686907,
                                40.75961054936392
                            ],
                            [
                                -74.02820261101972,
                                40.75897671915437
                            ]
                        ],
                        [
                            [
                                -74.02935080707316,
                                40.75524498440979
                            ],
                            [
                                -74.02931208698946,
                                40.75531801604662
                            ],
                            [
                                -74.02930298076743,
                                40.755397445447926
                            ],
                            [
                                -74.02935080707316,
                                40.75524498440979
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "055b4d02-2b8a-49b7-b2f2-067ff69440f5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02850809211523,
                                40.73785943345518
                            ],
                            [
                                -74.02987074849806,
                                40.736174872202334
                            ],
                            [
                                -74.02988668605742,
                                40.736180167032096
                            ],
                            [
                                -74.02879785075774,
                                40.73765414716963
                            ],
                            [
                                -74.02850809211523,
                                40.73785943345518
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "413d0620-efce-47d9-b88f-4ed4669796b7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02987074849806,
                                40.736174872202334
                            ],
                            [
                                -74.03009945045453,
                                40.73589214321858
                            ],
                            [
                                -74.02988668605742,
                                40.736180167032096
                            ],
                            [
                                -74.02987074849806,
                                40.736174872202334
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3939d712-69b4-48b2-8288-4013c205f915"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03009945045453,
                                40.73589214321858
                            ],
                            [
                                -74.02943218725137,
                                40.73571811577416
                            ],
                            [
                                -74.03026299745437,
                                40.73545735689488
                            ],
                            [
                                -74.03011407592567,
                                40.73584126330634
                            ],
                            [
                                -74.0303668375921,
                                40.73546095091296
                            ],
                            [
                                -74.03067404884285,
                                40.73532834391573
                            ],
                            [
                                -74.03166110512772,
                                40.735561194282646
                            ],
                            [
                                -74.03099058867306,
                                40.73610065072198
                            ],
                            [
                                -74.03056178118473,
                                40.73640444941365
                            ],
                            [
                                -74.02988668605742,
                                40.736180167032096
                            ],
                            [
                                -74.03009945045453,
                                40.73589214321858
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "afdbd83a-a5a7-4dac-9c79-b8d9c7035e40"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03026299745437,
                                40.73545735689488
                            ],
                            [
                                -74.02943218725137,
                                40.73571811577416
                            ],
                            [
                                -74.03029856652883,
                                40.73536566299505
                            ],
                            [
                                -74.03026299745437,
                                40.73545735689488
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "037d33d9-b46c-4224-a403-b8f8dfaba82d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03026299745437,
                                40.73545735689488
                            ],
                            [
                                -74.03067404884285,
                                40.73532834391573
                            ],
                            [
                                -74.0303668375921,
                                40.73546095091296
                            ],
                            [
                                -74.03011407592567,
                                40.73584126330634
                            ],
                            [
                                -74.03026299745437,
                                40.73545735689488
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9888a7cf-ef8c-40fc-8d20-7aa5d6527dd0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03166110512772,
                                40.735561194282646
                            ],
                            [
                                -74.03067404884285,
                                40.73532834391573
                            ],
                            [
                                -74.03144370112378,
                                40.73535663170626
                            ],
                            [
                                -74.03183036297153,
                                40.73542501979638
                            ],
                            [
                                -74.03166110512772,
                                40.735561194282646
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c94ee392-85d3-437b-bf53-e498f00b3c30"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03166110512772,
                                40.735561194282646
                            ],
                            [
                                -74.03172931238353,
                                40.735577284636136
                            ],
                            [
                                -74.03099058867306,
                                40.73610065072198
                            ],
                            [
                                -74.03166110512772,
                                40.735561194282646
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4fe4745c-083f-4346-9bf8-b06f1a6d0242"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03056178118473,
                                40.73640444941365
                            ],
                            [
                                -74.03099058867306,
                                40.73610065072198
                            ],
                            [
                                -74.03059801878445,
                                40.73641648839133
                            ],
                            [
                                -74.03056178118473,
                                40.73640444941365
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "91ac48fa-b230-4ab5-9c9e-f2c8745f648f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03056178118473,
                                40.73640444941365
                            ],
                            [
                                -74.02879785075774,
                                40.73765414716963
                            ],
                            [
                                -74.02988668605742,
                                40.736180167032096
                            ],
                            [
                                -74.03056178118473,
                                40.73640444941365
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "73bb6b32-a2b8-42b1-985f-2ce50b9b8fa3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02879785075774,
                                40.73765414716963
                            ],
                            [
                                -74.03056178118473,
                                40.73640444941365
                            ],
                            [
                                -74.03059801878445,
                                40.73641648839133
                            ],
                            [
                                -74.02841428823663,
                                40.738173384135756
                            ],
                            [
                                -74.02879785075774,
                                40.73765414716963
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5984ebde-f7af-4987-a0ac-3416bb69c028"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02692003418629,
                                40.62191982511776
                            ],
                            [
                                -74.02907936224543,
                                40.622993237915786
                            ],
                            [
                                -74.0292749614154,
                                40.62263423826591
                            ],
                            [
                                -74.02987794027851,
                                40.62277626005137
                            ],
                            [
                                -74.03019223923685,
                                40.623258779631044
                            ],
                            [
                                -74.02784906107335,
                                40.62402340473599
                            ],
                            [
                                -74.02692003418629,
                                40.62191982511776
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4fc699b7-e907-4690-8972-9c48b5084bed"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02690017962188,
                                40.750594033436364
                            ],
                            [
                                -74.02682341139433,
                                40.751038103831924
                            ],
                            [
                                -74.02672712097902,
                                40.751160833743995
                            ],
                            [
                                -74.02690017962188,
                                40.750594033436364
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c411b377-12c9-4ac4-a40a-415e17a3781e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03067157757752,
                                40.62296306555186
                            ],
                            [
                                -74.03105891406004,
                                40.623465479454254
                            ],
                            [
                                -74.02759845536359,
                                40.624632909350446
                            ],
                            [
                                -74.02622259747106,
                                40.62149936975502
                            ],
                            [
                                -74.03067157757752,
                                40.62296306555186
                            ]
                        ],
                        [
                            [
                                -74.02784906107335,
                                40.62402340473599
                            ],
                            [
                                -74.03019223923685,
                                40.623258779631044
                            ],
                            [
                                -74.02987794027851,
                                40.62277626005137
                            ],
                            [
                                -74.0292749614154,
                                40.62263423826591
                            ],
                            [
                                -74.02907936224543,
                                40.622993237915786
                            ],
                            [
                                -74.02692003418629,
                                40.62191982511776
                            ],
                            [
                                -74.02784906107335,
                                40.62402340473599
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c4f8ee6b-9383-4a7f-891f-f54bc00789ac"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0261620914451,
                                40.774223102440516
                            ],
                            [
                                -74.02603729119762,
                                40.774166267389425
                            ],
                            [
                                -74.02614813072726,
                                40.77412678228628
                            ],
                            [
                                -74.0261620914451,
                                40.774223102440516
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9da7108d-227f-43cb-8457-aa63f1a76bc7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.03067157757752,
                                40.62296306555186
                            ],
                            [
                                -74.03132110624033,
                                40.62352910942881
                            ],
                            [
                                -74.02749150284417,
                                40.624895593213694
                            ],
                            [
                                -74.0260073853835,
                                40.621369369626414
                            ],
                            [
                                -74.03067157757752,
                                40.62296306555186
                            ]
                        ],
                        [
                            [
                                -74.02622259747106,
                                40.62149936975502
                            ],
                            [
                                -74.02759845536359,
                                40.624632909350446
                            ],
                            [
                                -74.03105891406004,
                                40.623465479454254
                            ],
                            [
                                -74.03067157757752,
                                40.62296306555186
                            ],
                            [
                                -74.02622259747106,
                                40.62149936975502
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "0d8d8fc1-cfd5-456d-a280-385e1031b66a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.024129442909,
                                40.61797105487672
                            ],
                            [
                                -74.02156022795876,
                                40.62085905774719
                            ],
                            [
                                -74.01881537440416,
                                40.617020837893016
                            ],
                            [
                                -74.024129442909,
                                40.61797105487672
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6400d3ee-f918-4666-ae2b-40fce06b1c7d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0239350645495,
                                40.769094501522794
                            ],
                            [
                                -74.024021955892,
                                40.76911805953915
                            ],
                            [
                                -74.02407540744804,
                                40.769154686125184
                            ],
                            [
                                -74.0239350645495,
                                40.769094501522794
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1453c834-1dcd-466b-b756-f1f3e43a38cc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02400115753063,
                                40.768201723195965
                            ],
                            [
                                -74.0277957442646,
                                40.77072480104403
                            ],
                            [
                                -74.02265695796387,
                                40.77236323629201
                            ],
                            [
                                -74.02200759525846,
                                40.77224766779023
                            ],
                            [
                                -74.02214025001082,
                                40.772527981909676
                            ],
                            [
                                -74.02150351102665,
                                40.772730997862155
                            ],
                            [
                                -74.02151108319195,
                                40.77258990739374
                            ],
                            [
                                -74.021767310293,
                                40.77217340435295
                            ],
                            [
                                -74.0215356693819,
                                40.772131798395584
                            ],
                            [
                                -74.02175372237602,
                                40.76806886555168
                            ],
                            [
                                -74.02127708976097,
                                40.76900346423965
                            ],
                            [
                                -74.02126555269778,
                                40.767954350764995
                            ],
                            [
                                -74.0213566122232,
                                40.767544685185
                            ],
                            [
                                -74.02152201362657,
                                40.767112580268936
                            ],
                            [
                                -74.0217018060408,
                                40.76667284977294
                            ],
                            [
                                -74.0234415298467,
                                40.76782961832356
                            ],
                            [
                                -74.02369817521583,
                                40.76837973453169
                            ],
                            [
                                -74.02400115753063,
                                40.768201723195965
                            ]
                        ],
                        [
                            [
                                -74.02407540744804,
                                40.769154686125184
                            ],
                            [
                                -74.024021955892,
                                40.76911805953915
                            ],
                            [
                                -74.0239350645495,
                                40.769094501522794
                            ],
                            [
                                -74.02407540744804,
                                40.769154686125184
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "aefe345c-ed47-4fc6-ba44-323c6f8985ab"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02400115753063,
                                40.768201723195965
                            ],
                            [
                                -74.02369817521583,
                                40.76837973453169
                            ],
                            [
                                -74.0234415298467,
                                40.76782961832356
                            ],
                            [
                                -74.02400115753063,
                                40.768201723195965
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4116f0d4-f07f-43b6-ab5f-e9b1038071f6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02214025001082,
                                40.772527981909676
                            ],
                            [
                                -74.02200759525846,
                                40.77224766779023
                            ],
                            [
                                -74.02265695796387,
                                40.77236323629201
                            ],
                            [
                                -74.02214025001082,
                                40.772527981909676
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "98acfac7-d38a-4b9c-9415-b9d2e3f99629"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02294205425802,
                                40.77422228269801
                            ],
                            [
                                -74.01904057306274,
                                40.77660577838038
                            ],
                            [
                                -74.02151108319195,
                                40.77258990739374
                            ],
                            [
                                -74.02150351102665,
                                40.772730997862155
                            ],
                            [
                                -74.02214025001082,
                                40.772527981909676
                            ],
                            [
                                -74.02294205425802,
                                40.77422228269801
                            ]
                        ],
                        [
                            [
                                -74.02125317750644,
                                40.773077601396906
                            ],
                            [
                                -74.02108546563615,
                                40.77358509820778
                            ],
                            [
                                -74.02130085020025,
                                40.77353504171387
                            ],
                            [
                                -74.02132981368163,
                                40.7730767408225
                            ],
                            [
                                -74.02125317750644,
                                40.773077601396906
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4846a41b-7b06-4221-9d7d-c244e6085687"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0215356693819,
                                40.772131798395584
                            ],
                            [
                                -74.021767310293,
                                40.77217340435295
                            ],
                            [
                                -74.02151108319195,
                                40.77258990739374
                            ],
                            [
                                -74.0215356693819,
                                40.772131798395584
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d4b8ac07-b5ee-4ce5-bd3a-3f5338a6105a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01659043070171,
                                40.77124356415094
                            ],
                            [
                                -74.01884707325559,
                                40.773757384342765
                            ],
                            [
                                -74.01146325579342,
                                40.78099211838445
                            ],
                            [
                                -74.01334115425045,
                                40.77207239119601
                            ],
                            [
                                -74.01585676425462,
                                40.769804048545105
                            ],
                            [
                                -74.01506984972534,
                                40.77118726604111
                            ],
                            [
                                -74.01645801112377,
                                40.76926190015376
                            ],
                            [
                                -74.02184399497723,
                                40.764405321879856
                            ],
                            [
                                -74.0234415298467,
                                40.76782961832356
                            ],
                            [
                                -74.0217018060408,
                                40.76667284977294
                            ],
                            [
                                -74.02152201362657,
                                40.767112580268936
                            ],
                            [
                                -74.0213566122232,
                                40.767544685185
                            ],
                            [
                                -74.02126555269778,
                                40.767954350764995
                            ],
                            [
                                -74.02127708976097,
                                40.76900346423965
                            ],
                            [
                                -74.02175372237602,
                                40.76806886555168
                            ],
                            [
                                -74.0215356693819,
                                40.772131798395584
                            ],
                            [
                                -74.01659043070171,
                                40.77124356415094
                            ]
                        ],
                        [
                            [
                                -74.01696401397723,
                                40.77021271944402
                            ],
                            [
                                -74.0169117266378,
                                40.77019216477261
                            ],
                            [
                                -74.01693907494831,
                                40.77024593495775
                            ],
                            [
                                -74.01696401397723,
                                40.77021271944402
                            ]
                        ],
                        [
                            [
                                -74.01977018922807,
                                40.77116435641875
                            ],
                            [
                                -74.02009279311122,
                                40.77112415996204
                            ],
                            [
                                -74.02050055907993,
                                40.770923651495416
                            ],
                            [
                                -74.02068627498514,
                                40.77076343708905
                            ],
                            [
                                -74.02086629197818,
                                40.76986769225362
                            ],
                            [
                                -74.01977018922807,
                                40.77116435641875
                            ]
                        ],
                        [
                            [
                                -74.01760358032354,
                                40.77078659584655
                            ],
                            [
                                -74.01890964091513,
                                40.77120315140194
                            ],
                            [
                                -74.01809289894702,
                                40.770269757892535
                            ],
                            [
                                -74.0171421274402,
                                40.77067279667834
                            ],
                            [
                                -74.01760507152692,
                                40.77068816443832
                            ],
                            [
                                -74.01760358032354,
                                40.77078659584655
                            ]
                        ],
                        [
                            [
                                -74.01598201703113,
                                40.77226868035085
                            ],
                            [
                                -74.01574208510358,
                                40.7727080214224
                            ],
                            [
                                -74.01607516963115,
                                40.77254981208494
                            ],
                            [
                                -74.01598201703113,
                                40.77226868035085
                            ]
                        ],
                        [
                            [
                                -74.01438818113829,
                                40.775403883745476
                            ],
                            [
                                -74.01434208947937,
                                40.77544000482604
                            ],
                            [
                                -74.01437484385126,
                                40.77553953055308
                            ],
                            [
                                -74.01438818113829,
                                40.775403883745476
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "28373764-33f7-41d9-9456-20097abf3e21"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01585676425462,
                                40.769804048545105
                            ],
                            [
                                -74.01645801112377,
                                40.76926190015376
                            ],
                            [
                                -74.01506984972534,
                                40.77118726604111
                            ],
                            [
                                -74.01585676425462,
                                40.769804048545105
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "02c87d4f-1ab1-4985-a098-3c63cd2db76b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01645801112377,
                                40.76926190015376
                            ],
                            [
                                -74.01585676425462,
                                40.769804048545105
                            ],
                            [
                                -74.01607437552045,
                                40.76942153723161
                            ],
                            [
                                -74.01699193181963,
                                40.768521357522694
                            ],
                            [
                                -74.01645801112377,
                                40.76926190015376
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "80f472b1-b894-4b63-8e22-4458a4c33544"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02132981368163,
                                40.7730767408225
                            ],
                            [
                                -74.02130085020025,
                                40.77353504171387
                            ],
                            [
                                -74.02108546563615,
                                40.77358509820778
                            ],
                            [
                                -74.02125317750644,
                                40.773077601396906
                            ],
                            [
                                -74.02132981368163,
                                40.7730767408225
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "536d0818-226e-49bd-a9dc-8e89b8e33e9b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02122324204791,
                                40.76091034048962
                            ],
                            [
                                -74.02261881167271,
                                40.75855661056793
                            ],
                            [
                                -74.02340522651684,
                                40.75829292958729
                            ],
                            [
                                -74.02122324204791,
                                40.76091034048962
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b5153fd2-edf3-4f82-99f8-cdf9a8437fee"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.02009279311122,
                                40.77112415996204
                            ],
                            [
                                -74.01977018922807,
                                40.77116435641875
                            ],
                            [
                                -74.02086629197818,
                                40.76986769225362
                            ],
                            [
                                -74.02068627498514,
                                40.77076343708905
                            ],
                            [
                                -74.02050055907993,
                                40.770923651495416
                            ],
                            [
                                -74.02009279311122,
                                40.77112415996204
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "2d5b8949-e448-46ff-8506-96035aa90a43"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01962672019236,
                                40.63653114431555
                            ],
                            [
                                -74.01949605035281,
                                40.6369159434263
                            ],
                            [
                                -74.0192358964499,
                                40.63656057909427
                            ],
                            [
                                -74.01962672019236,
                                40.63653114431555
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "dcd30670-c8fa-4c35-a730-64777bdb4f34"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01890964091513,
                                40.77120315140194
                            ],
                            [
                                -74.01760358032354,
                                40.77078659584655
                            ],
                            [
                                -74.01760507152692,
                                40.77068816443832
                            ],
                            [
                                -74.0171421274402,
                                40.77067279667834
                            ],
                            [
                                -74.01809289894702,
                                40.770269757892535
                            ],
                            [
                                -74.01890964091513,
                                40.77120315140194
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ef54441b-6fc3-4420-af4d-8ebfc823aea9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0185179431119,
                                40.7772065852344
                            ],
                            [
                                -74.01832986014593,
                                40.77736324951438
                            ],
                            [
                                -74.01868034054895,
                                40.77705208311651
                            ],
                            [
                                -74.0185179431119,
                                40.7772065852344
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "bdf4b062-393e-4b13-a220-b0624e4e1837"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01403102586212,
                                40.706903196250366
                            ],
                            [
                                -74.01388218798651,
                                40.70688881318136
                            ],
                            [
                                -74.01403179254888,
                                40.70682571327412
                            ],
                            [
                                -74.01403102586212,
                                40.706903196250366
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "bf56e12f-ee27-4aa8-b705-75b8a74d74b2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01631194847205,
                                40.70712361505955
                            ],
                            [
                                -74.01650534048137,
                                40.70797728210467
                            ],
                            [
                                -74.01396444366574,
                                40.713632133072586
                            ],
                            [
                                -74.01403102586212,
                                40.706903196250366
                            ],
                            [
                                -74.01631194847205,
                                40.70712361505955
                            ]
                        ],
                        [
                            [
                                -74.0140296635084,
                                40.713028448279836
                            ],
                            [
                                -74.01408838727684,
                                40.71311053266388
                            ],
                            [
                                -74.01410301813786,
                                40.71305842875982
                            ],
                            [
                                -74.0140296635084,
                                40.713028448279836
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "3adcb37f-b3a5-4588-968f-b2034f75f109"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01318211976249,
                                40.716860013612596
                            ],
                            [
                                -74.01130368176129,
                                40.71560907131078
                            ],
                            [
                                -74.0131996219825,
                                40.71533426934556
                            ],
                            [
                                -74.01396444366574,
                                40.713632133072586
                            ],
                            [
                                -74.01393355814604,
                                40.71675348866182
                            ],
                            [
                                -74.01318211976249,
                                40.716860013612596
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "65aadf7d-59ae-44ee-8c8d-09b6045ae5e8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00970602545925,
                                40.72656611221722
                            ],
                            [
                                -74.00978133431668,
                                40.72520801338893
                            ],
                            [
                                -74.0104732359935,
                                40.72621912205984
                            ],
                            [
                                -74.00970602545925,
                                40.72656611221722
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "304db966-ca2c-483b-8f54-372052a8bc41"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00966341638328,
                                40.72733451235148
                            ],
                            [
                                -74.00965792838086,
                                40.72743348144876
                            ],
                            [
                                -74.00962440620346,
                                40.72753956499929
                            ],
                            [
                                -74.00966341638328,
                                40.72733451235148
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ff26844a-b405-4bba-9ac2-90f1bc518582"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00965792838086,
                                40.72743348144876
                            ],
                            [
                                -74.00966341638328,
                                40.72733451235148
                            ],
                            [
                                -74.00976972397201,
                                40.72677571839926
                            ],
                            [
                                -74.00971591129323,
                                40.727249989990575
                            ],
                            [
                                -74.00965792838086,
                                40.72743348144876
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "fbbd52cc-9946-4a38-8b5c-db15c3eb29df"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00897997403689,
                                40.7396595200784
                            ],
                            [
                                -74.00900663558298,
                                40.739178713228796
                            ],
                            [
                                -74.00942530270555,
                                40.73935842147837
                            ],
                            [
                                -74.00897997403689,
                                40.7396595200784
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "e2671e86-9c9a-4509-b993-0865b9095840"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00897997403689,
                                40.7396595200784
                            ],
                            [
                                -74.00893788363801,
                                40.740418566535254
                            ],
                            [
                                -74.00806557855555,
                                40.74027776727128
                            ],
                            [
                                -74.00897997403689,
                                40.7396595200784
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "7223c7fa-863d-420b-b4eb-592eec2e8ef4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00893788363801,
                                40.740418566535254
                            ],
                            [
                                -74.00909823958534,
                                40.74044444968074
                            ],
                            [
                                -74.00921140305663,
                                40.74149295599931
                            ],
                            [
                                -74.00885659656403,
                                40.74188447484507
                            ],
                            [
                                -74.00893788363801,
                                40.740418566535254
                            ]
                        ],
                        [
                            [
                                -74.00898626907674,
                                40.741497543372965
                            ],
                            [
                                -74.00900878984092,
                                40.741540432578894
                            ],
                            [
                                -74.00901632670616,
                                40.741502138913745
                            ],
                            [
                                -74.00898626907674,
                                40.741497543372965
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "7f5380e9-d384-4d5f-aa64-29134ea354f1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00885659656403,
                                40.74188447484507
                            ],
                            [
                                -74.00884237188632,
                                40.74214099869622
                            ],
                            [
                                -74.00858927211166,
                                40.742179459754375
                            ],
                            [
                                -74.00885659656403,
                                40.74188447484507
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "6f0ed67b-bb6d-4100-a861-bc24c132e207"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00883175357524,
                                40.742332486342406
                            ],
                            [
                                -74.00884237188632,
                                40.74214099869622
                            ],
                            [
                                -74.0092128816836,
                                40.74208469600343
                            ],
                            [
                                -74.0090952814331,
                                40.74225067081328
                            ],
                            [
                                -74.00925710125344,
                                40.74221165610841
                            ],
                            [
                                -74.0092171199351,
                                40.74233417362545
                            ],
                            [
                                -74.00883175357524,
                                40.742332486342406
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "0f1b815f-6230-449e-b918-0658bb6a566b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96591516831143,
                                40.76821953240721
                            ],
                            [
                                -73.96211212528206,
                                40.76865903915625
                            ],
                            [
                                -73.9619087557067,
                                40.76832071836818
                            ],
                            [
                                -73.96273050751397,
                                40.7676945814845
                            ],
                            [
                                -73.96495195698039,
                                40.766664259527595
                            ],
                            [
                                -73.96591516831143,
                                40.76821953240721
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "4cb981bf-e6db-475e-8a54-998cf1c9ae69"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96211212528206,
                                40.76865903915625
                            ],
                            [
                                -73.96591516831143,
                                40.76821953240721
                            ],
                            [
                                -73.96597999527974,
                                40.76832420686695
                            ],
                            [
                                -73.96273919353487,
                                40.769702214985756
                            ],
                            [
                                -73.96211212528206,
                                40.76865903915625
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "0738b9c9-cffa-4167-946a-e1dc62949624"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96211212528206,
                                40.76865903915625
                            ],
                            [
                                -73.96134899224221,
                                40.768747232247634
                            ],
                            [
                                -73.9619087557067,
                                40.76832071836818
                            ],
                            [
                                -73.96211212528206,
                                40.76865903915625
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "6e04363b-1326-45e4-aa7a-00966beb43bf"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96273050751397,
                                40.7676945814845
                            ],
                            [
                                -73.9619087557067,
                                40.76832071836818
                            ],
                            [
                                -73.96179358913757,
                                40.7681291300032
                            ],
                            [
                                -73.96273050751397,
                                40.7676945814845
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d5e045fd-9567-4dfa-89f2-25f53aa37f7b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96509024204936,
                                40.765896572870126
                            ],
                            [
                                -73.96576791382094,
                                40.76538021831213
                            ],
                            [
                                -73.9644411009102,
                                40.76447319899613
                            ],
                            [
                                -73.96266286313325,
                                40.760574051275015
                            ],
                            [
                                -73.96284196045185,
                                40.75984258680772
                            ],
                            [
                                -73.96670103357589,
                                40.75956386202114
                            ],
                            [
                                -73.96976202543783,
                                40.75991640949285
                            ],
                            [
                                -73.96795005039218,
                                40.76254042463455
                            ],
                            [
                                -73.96756446084585,
                                40.762048019058014
                            ],
                            [
                                -73.96752408247215,
                                40.763138669491745
                            ],
                            [
                                -73.96753809887716,
                                40.763136993012054
                            ],
                            [
                                -73.96530593003325,
                                40.76636951260955
                            ],
                            [
                                -73.96509024204936,
                                40.765896572870126
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "30163cf2-f9b9-4227-a25a-66c340d00b1c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9644411009102,
                                40.76447319899613
                            ],
                            [
                                -73.96576791382094,
                                40.76538021831213
                            ],
                            [
                                -73.96509024204936,
                                40.765896572870126
                            ],
                            [
                                -73.9644411009102,
                                40.76447319899613
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4e5c3c46-fc77-48a5-b815-02bf239ae664"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9644411009102,
                                40.76447319899613
                            ],
                            [
                                -73.96210001615907,
                                40.762872815797806
                            ],
                            [
                                -73.96266286313325,
                                40.760574051275015
                            ],
                            [
                                -73.9644411009102,
                                40.76447319899613
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "88fe8b35-0998-4ad7-a354-b94ea10b5775"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96284196045185,
                                40.75984258680772
                            ],
                            [
                                -73.96266286313325,
                                40.760574051275015
                            ],
                            [
                                -73.96234562184596,
                                40.759878435278985
                            ],
                            [
                                -73.96284196045185,
                                40.75984258680772
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "bc3d694b-36b1-4551-8c5a-70ed1f074bd3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97666434424549,
                                40.738842779824196
                            ],
                            [
                                -73.97742171398437,
                                40.738171319033825
                            ],
                            [
                                -73.97625718827103,
                                40.73817222186798
                            ],
                            [
                                -73.97573318757574,
                                40.7373092286729
                            ],
                            [
                                -73.9779574216091,
                                40.7374363673773
                            ],
                            [
                                -73.97832575602843,
                                40.73693605941154
                            ],
                            [
                                -73.97893003385543,
                                40.73792844585644
                            ],
                            [
                                -73.97678886883301,
                                40.73904786327658
                            ],
                            [
                                -73.97666434424549,
                                40.738842779824196
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "1863236f-fa2e-407b-ae71-bc99494849a7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97625718827103,
                                40.73817222186798
                            ],
                            [
                                -73.97742171398437,
                                40.738171319033825
                            ],
                            [
                                -73.97666434424549,
                                40.738842779824196
                            ],
                            [
                                -73.97625718827103,
                                40.73817222186798
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "bf67b6c0-d743-406d-becb-359bd464d4cb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.976867144097,
                                40.71537955444599
                            ],
                            [
                                -73.97847271771396,
                                40.71594182804669
                            ],
                            [
                                -73.97770536836366,
                                40.71731466151501
                            ],
                            [
                                -73.976867144097,
                                40.71537955444599
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "2ecd3112-7b8d-4264-8b35-800d40ee1880"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95261677748742,
                                40.7396821159022
                            ],
                            [
                                -73.95281671835524,
                                40.73957154347496
                            ],
                            [
                                -73.9528856211082,
                                40.739672629523945
                            ],
                            [
                                -73.95261677748742,
                                40.7396821159022
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "36a15539-ffec-4a52-bf8e-ffa9f76c2d10"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95261677748742,
                                40.7396821159022
                            ],
                            [
                                -73.9528856211082,
                                40.739672629523945
                            ],
                            [
                                -73.9530082966156,
                                40.739852604660136
                            ],
                            [
                                -73.95261677748742,
                                40.7396821159022
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "154458e0-e348-4129-ab02-4e507bc5941d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95772492158643,
                                40.74841996736166
                            ],
                            [
                                -73.96050161067427,
                                40.74311560182881
                            ],
                            [
                                -73.96075753157767,
                                40.743227043732446
                            ],
                            [
                                -73.95772492158643,
                                40.74841996736166
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "d4103cb9-aca6-4270-9734-21ac613d11eb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9514466666596,
                                40.7519685403031
                            ],
                            [
                                -73.95144575037065,
                                40.751969058204686
                            ],
                            [
                                -73.95162131030365,
                                40.75176726373513
                            ],
                            [
                                -73.9514466666596,
                                40.7519685403031
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "fcc1159e-7d28-4678-a669-d37fb8df6cd2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95144575037065,
                                40.751969058204686
                            ],
                            [
                                -73.9514466666596,
                                40.7519685403031
                            ],
                            [
                                -73.95099824780573,
                                40.75248534243702
                            ],
                            [
                                -73.95079722360289,
                                40.75233561619997
                            ],
                            [
                                -73.950963493034,
                                40.75224163798292
                            ],
                            [
                                -73.9510260874947,
                                40.75245143277614
                            ],
                            [
                                -73.95144575037065,
                                40.751969058204686
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "187f62dc-e718-49f7-aa19-42c0f3d9e006"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.950963493034,
                                40.75224163798292
                            ],
                            [
                                -73.95144575037065,
                                40.751969058204686
                            ],
                            [
                                -73.9510260874947,
                                40.75245143277614
                            ],
                            [
                                -73.950963493034,
                                40.75224163798292
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "da4bc396-38a5-4b12-94c0-3f8308e06356"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.950963493034,
                                40.75224163798292
                            ],
                            [
                                -73.95079722360289,
                                40.75233561619997
                            ],
                            [
                                -73.95028071128601,
                                40.7519509090627
                            ],
                            [
                                -73.9507227821188,
                                40.75143485897861
                            ],
                            [
                                -73.950963493034,
                                40.75224163798292
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "88a2114b-fb1a-4f57-a0a6-ee23763d0ae6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9013728150928,
                                40.722616777115576
                            ],
                            [
                                -73.89759702593436,
                                40.724820610937634
                            ],
                            [
                                -73.8957046926867,
                                40.72292459959203
                            ],
                            [
                                -73.9013728150928,
                                40.722616777115576
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "2809f2d7-c734-4a0f-b6a6-630f7c1a459f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9013728150928,
                                40.722616777115576
                            ],
                            [
                                -73.90111428946814,
                                40.71550211256166
                            ],
                            [
                                -73.90129426550604,
                                40.7155182631049
                            ],
                            [
                                -73.9013728150928,
                                40.722616777115576
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "74182fe8-f6b8-4bd0-8a7d-8cbe6ed9c28a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89971555151811,
                                40.71537659375372
                            ],
                            [
                                -73.90108776528008,
                                40.71477216286786
                            ],
                            [
                                -73.90111428946814,
                                40.71550211256166
                            ],
                            [
                                -73.89971555151811,
                                40.71537659375372
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "565c839d-e4fc-4b88-a82a-ea952d5b4677"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89971555151811,
                                40.71537659375372
                            ],
                            [
                                -73.89681733701445,
                                40.716653195408924
                            ],
                            [
                                -73.89612412052293,
                                40.715054308841566
                            ],
                            [
                                -73.89971555151811,
                                40.71537659375372
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b5f5bd0c-63fe-4cee-8313-41729314c266"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89456827733335,
                                40.714914691851476
                            ],
                            [
                                -73.89557970199638,
                                40.714097264821234
                            ],
                            [
                                -73.89500335728314,
                                40.71259574735367
                            ],
                            [
                                -73.88888336162788,
                                40.712358549556434
                            ],
                            [
                                -73.89504474356659,
                                40.71256475278263
                            ],
                            [
                                -73.89612412052293,
                                40.715054308841566
                            ],
                            [
                                -73.89456827733335,
                                40.714914691851476
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "65b767ba-64f2-47ed-9319-903d5a29a4d5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89456827733335,
                                40.714914691851476
                            ],
                            [
                                -73.89244527739649,
                                40.71663048700361
                            ],
                            [
                                -73.8871605671861,
                                40.714249944786246
                            ],
                            [
                                -73.89456827733335,
                                40.714914691851476
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "5ec5c8f0-7239-46fd-9964-4c17102e7f8a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88731827743968,
                                40.71740752514865
                            ],
                            [
                                -73.89038012736775,
                                40.71829952822507
                            ],
                            [
                                -73.89244527739649,
                                40.71663048700361
                            ],
                            [
                                -73.8947903855939,
                                40.71768686073953
                            ],
                            [
                                -73.89213158794857,
                                40.71898881564036
                            ],
                            [
                                -73.89426808981634,
                                40.72314199319688
                            ],
                            [
                                -73.89473708764976,
                                40.72233825414759
                            ],
                            [
                                -73.89449368912355,
                                40.72314659068642
                            ],
                            [
                                -73.8957006673359,
                                40.72343525493308
                            ],
                            [
                                -73.89575901021867,
                                40.72367860992164
                            ],
                            [
                                -73.89570053258862,
                                40.723435344799206
                            ],
                            [
                                -73.89430038874238,
                                40.72322338975161
                            ],
                            [
                                -73.8957074379382,
                                40.72417894473225
                            ],
                            [
                                -73.89477057774187,
                                40.72494453400119
                            ],
                            [
                                -73.89336018501656,
                                40.72499900999908
                            ],
                            [
                                -73.88731827743968,
                                40.71740752514865
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "95b6b7ea-157a-431b-9794-4a903e203ff5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88731827743968,
                                40.71740752514865
                            ],
                            [
                                -73.88554286024088,
                                40.716890296139894
                            ],
                            [
                                -73.88662127887665,
                                40.716531766298544
                            ],
                            [
                                -73.88731827743968,
                                40.71740752514865
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "2ee46d96-0f8f-47f5-991c-f526f43f9a04"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87778751414403,
                                40.71463094550562
                            ],
                            [
                                -73.88554286024088,
                                40.716890296139894
                            ],
                            [
                                -73.87629977550098,
                                40.719963241650525
                            ],
                            [
                                -73.87778751414403,
                                40.71463094550562
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ffc56a9a-f8df-409d-8fe4-1cd96980b770"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87778751414403,
                                40.71463094550562
                            ],
                            [
                                -73.87588249978278,
                                40.71407596119522
                            ],
                            [
                                -73.87826485994793,
                                40.71292006086752
                            ],
                            [
                                -73.87778751414403,
                                40.71463094550562
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "1a0c98de-b1a3-46a3-9490-19943c0e9a1f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88825732301143,
                                40.712191665370845
                            ],
                            [
                                -73.87287663551638,
                                40.71320026831807
                            ],
                            [
                                -73.87588249978278,
                                40.71407596119522
                            ],
                            [
                                -73.87528558113847,
                                40.71436558090371
                            ],
                            [
                                -73.87078264102952,
                                40.71329569487817
                            ],
                            [
                                -73.87152664100866,
                                40.71324868984934
                            ],
                            [
                                -73.87160840567634,
                                40.713311296514675
                            ],
                            [
                                -73.8717008948894,
                                40.71323768069757
                            ],
                            [
                                -73.88825732301143,
                                40.712191665370845
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "0469bd00-3585-41df-8c39-4173501da7ca"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88529174697884,
                                40.70706609797833
                            ],
                            [
                                -73.88172199847108,
                                40.71235671925073
                            ],
                            [
                                -73.88825732301143,
                                40.712191665370845
                            ],
                            [
                                -73.8800622504909,
                                40.71252105707912
                            ],
                            [
                                -73.88346268465837,
                                40.706394932794204
                            ],
                            [
                                -73.88529174697884,
                                40.70706609797833
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "72eb94dd-848c-423e-8737-1dd5607178f5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88529174697884,
                                40.70706609797833
                            ],
                            [
                                -73.88556779849182,
                                40.706656969995315
                            ],
                            [
                                -73.88923787636487,
                                40.70814916488732
                            ],
                            [
                                -73.88900081731026,
                                40.70842712261048
                            ],
                            [
                                -73.88529174697884,
                                40.70706609797833
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ef9e82a8-1d72-4e43-88f7-aa17ce0e8aaa"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88833703950974,
                                40.712195338928744
                            ],
                            [
                                -73.8871903894569,
                                40.71054989495042
                            ],
                            [
                                -73.88900081731026,
                                40.70842712261048
                            ],
                            [
                                -73.90044132772016,
                                40.712625160145755
                            ],
                            [
                                -73.88833703950974,
                                40.712195338928744
                            ]
                        ],
                        [
                            [
                                -73.89268373923457,
                                40.71229772114416
                            ],
                            [
                                -73.8915422940205,
                                40.709446370120084
                            ],
                            [
                                -73.89079617400677,
                                40.70938933464977
                            ],
                            [
                                -73.89268373923457,
                                40.71229772114416
                            ]
                        ],
                        [
                            [
                                -73.88966190184023,
                                40.71185855215405
                            ],
                            [
                                -73.89007085807668,
                                40.71103220206536
                            ],
                            [
                                -73.88985420419309,
                                40.71146821632644
                            ],
                            [
                                -73.88966190184023,
                                40.71185855215405
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "21eca4fc-df01-4848-a15c-b36474792003"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89360501566057,
                                40.70802299105055
                            ],
                            [
                                -73.90044132772016,
                                40.712625160145755
                            ],
                            [
                                -73.89270957568047,
                                40.708639037100156
                            ],
                            [
                                -73.89360501566057,
                                40.70802299105055
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f9283069-422d-4804-9c9b-0aa024bc8489"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89360501566057,
                                40.70802299105055
                            ],
                            [
                                -73.89043990411272,
                                40.70589225470888
                            ],
                            [
                                -73.89247074997449,
                                40.705924167301085
                            ],
                            [
                                -73.89214760760503,
                                40.70694542722836
                            ],
                            [
                                -73.8955501560525,
                                40.70668477064382
                            ],
                            [
                                -73.89360501566057,
                                40.70802299105055
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b204fe93-9c2a-42e5-9f65-7871700d4244"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88497189267568,
                                40.70221121835061
                            ],
                            [
                                -73.89043990411272,
                                40.70589225470888
                            ],
                            [
                                -73.88282258471278,
                                40.70577255660068
                            ],
                            [
                                -73.88497189267568,
                                40.70221121835061
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "7609cd1f-dc51-46a2-bf05-19d9fda806db"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88497189267568,
                                40.70221121835061
                            ],
                            [
                                -73.88416727645485,
                                40.70166955496943
                            ],
                            [
                                -73.8854397803167,
                                40.701435942689116
                            ],
                            [
                                -73.88497189267568,
                                40.70221121835061
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b0fa5684-2550-436e-a2ab-0f2681b71a3a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88987927213766,
                                40.69528435808211
                            ],
                            [
                                -73.8914369297225,
                                40.70033495762331
                            ],
                            [
                                -73.8854397803167,
                                40.701435942689116
                            ],
                            [
                                -73.88907592089893,
                                40.69541096778477
                            ],
                            [
                                -73.88987927213766,
                                40.69528435808211
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "205d8429-6496-448e-ab01-fa257bcf5be1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88987927213766,
                                40.69528435808211
                            ],
                            [
                                -73.88985748753547,
                                40.69521372298045
                            ],
                            [
                                -73.89015848450613,
                                40.69524035367502
                            ],
                            [
                                -73.88987927213766,
                                40.69528435808211
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f60a0fbe-e641-4c08-be23-ab857cc842df"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8923679967374,
                                40.69489213029136
                            ],
                            [
                                -73.8928537750465,
                                40.69495463485393
                            ],
                            [
                                -73.89638251109444,
                                40.69443228996723
                            ],
                            [
                                -73.8930019862886,
                                40.695491932377976
                            ],
                            [
                                -73.89015848450613,
                                40.69524035367502
                            ],
                            [
                                -73.8923679967374,
                                40.69489213029136
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "a5f3c2c4-f270-4b27-a157-7e13fb0f41d0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89262679804119,
                                40.69209194198953
                            ],
                            [
                                -73.89200503868123,
                                40.69190686145327
                            ],
                            [
                                -73.8934129313951,
                                40.69263792198569
                            ],
                            [
                                -73.89172453973376,
                                40.692160460675964
                            ],
                            [
                                -73.89222091642041,
                                40.69294416256766
                            ],
                            [
                                -73.89137993700763,
                                40.69270016192292
                            ],
                            [
                                -73.89117931625522,
                                40.693280976201486
                            ],
                            [
                                -73.89102669877666,
                                40.69323756685381
                            ],
                            [
                                -73.89040332186839,
                                40.691899486897334
                            ],
                            [
                                -73.89203001903265,
                                40.69184922859923
                            ],
                            [
                                -73.89037728689483,
                                40.6914220325565
                            ],
                            [
                                -73.8904898565797,
                                40.69076183454668
                            ],
                            [
                                -73.89380885132638,
                                40.692243525582505
                            ],
                            [
                                -73.8922937257725,
                                40.69192587236947
                            ],
                            [
                                -73.89262679804119,
                                40.69209194198953
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "67dfa739-0b68-47f5-80cf-fadea7fd53d6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89586726524365,
                                40.6926750817614
                            ],
                            [
                                -73.8958100866291,
                                40.69313693236224
                            ],
                            [
                                -73.89380885132638,
                                40.692243525582505
                            ],
                            [
                                -73.89586726524365,
                                40.6926750817614
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "29a7c277-c80f-4bc3-ae2d-1f04f1700a7a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89733621034316,
                                40.68080992584278
                            ],
                            [
                                -73.89735471101405,
                                40.6806604897987
                            ],
                            [
                                -73.89743508430549,
                                40.68063256932226
                            ],
                            [
                                -73.89733621034316,
                                40.68080992584278
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "cbc810b8-d7e8-4b62-afa1-1dfe6486ea5e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89833038917166,
                                40.68032155383481
                            ],
                            [
                                -73.89885571967712,
                                40.680139061893335
                            ],
                            [
                                -73.89921193684339,
                                40.68040518549988
                            ],
                            [
                                -73.89833038917166,
                                40.68032155383481
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ececacb4-c7ea-4f97-b453-5ef4a7e46f0b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90098515881002,
                                40.681349347954246
                            ],
                            [
                                -73.89978098072982,
                                40.68045917020116
                            ],
                            [
                                -73.90233126571519,
                                40.68070111353172
                            ],
                            [
                                -73.90252702564332,
                                40.680748415682565
                            ],
                            [
                                -73.90248634785436,
                                40.68138364468672
                            ],
                            [
                                -73.90098515881002,
                                40.681349347954246
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "1000154f-809d-4af8-87b2-8b0d5be82b58"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90252702564332,
                                40.680748415682565
                            ],
                            [
                                -73.90257831108495,
                                40.68076080796182
                            ],
                            [
                                -73.90248634785436,
                                40.68138364468672
                            ],
                            [
                                -73.90252702564332,
                                40.680748415682565
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "342c40d6-0d2d-482c-a05a-6975045cbbb6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90252702564332,
                                40.680748415682565
                            ],
                            [
                                -73.90233126571519,
                                40.68070111353172
                            ],
                            [
                                -73.90252885433698,
                                40.680719858593356
                            ],
                            [
                                -73.90252702564332,
                                40.680748415682565
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "a96e0db0-3c3c-4768-9e36-513e29c1dbae"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89915612224196,
                                40.656658846043136
                            ],
                            [
                                -73.89916720923351,
                                40.656711714527525
                            ],
                            [
                                -73.89896278430582,
                                40.65682577701808
                            ],
                            [
                                -73.89915612224196,
                                40.656658846043136
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "deafe28b-03a3-4553-884c-5dddfe9374ea"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90942414281703,
                                40.661709180054245
                            ],
                            [
                                -73.91325778052492,
                                40.661906423368045
                            ],
                            [
                                -73.90972442792206,
                                40.66247064421728
                            ],
                            [
                                -73.90942414281703,
                                40.661709180054245
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c24bba3e-e4ad-4b6b-9154-21533e5ec19d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92976746418401,
                                40.672450153297085
                            ],
                            [
                                -73.92696882125176,
                                40.675104465517556
                            ],
                            [
                                -73.92407524287063,
                                40.672967358920374
                            ],
                            [
                                -73.92976746418401,
                                40.672450153297085
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "6523c300-9798-408f-9f7d-98d4bead1313"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92976746418401,
                                40.672450153297085
                            ],
                            [
                                -73.93099468433151,
                                40.671286222951714
                            ],
                            [
                                -73.93138920876841,
                                40.672302798622624
                            ],
                            [
                                -73.92976746418401,
                                40.672450153297085
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "953036f1-3e0e-4a6c-97bc-88a655c4fb17"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95311969756244,
                                40.66953206713695
                            ],
                            [
                                -73.9552475485942,
                                40.66984864467514
                            ],
                            [
                                -73.96046903116037,
                                40.66254038764782
                            ],
                            [
                                -73.96085434660911,
                                40.66244938491104
                            ],
                            [
                                -73.96057626551685,
                                40.662390296896895
                            ],
                            [
                                -73.96081224975826,
                                40.662060001148745
                            ],
                            [
                                -73.96267536037168,
                                40.671571806734754
                            ],
                            [
                                -73.96219578285657,
                                40.671421770069514
                            ],
                            [
                                -73.96228502099075,
                                40.671543548780434
                            ],
                            [
                                -73.96101670413829,
                                40.67119218981487
                            ],
                            [
                                -73.96102309064365,
                                40.67116959700799
                            ],
                            [
                                -73.96069249078239,
                                40.67078183871586
                            ],
                            [
                                -73.96159801332381,
                                40.67039858191296
                            ],
                            [
                                -73.95986828093905,
                                40.669815128687624
                            ],
                            [
                                -73.95882750747795,
                                40.668594412689046
                            ],
                            [
                                -73.95735621815169,
                                40.66997805999832
                            ],
                            [
                                -73.95638181806578,
                                40.670548289860555
                            ],
                            [
                                -73.95653388174387,
                                40.67075141132881
                            ],
                            [
                                -73.95446987795104,
                                40.67269246618681
                            ],
                            [
                                -73.95311969756244,
                                40.66953206713695
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "77c9a8fe-bef4-4073-aed7-ab8c1c31bef2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96046903116037,
                                40.66254038764782
                            ],
                            [
                                -73.9552475485942,
                                40.66984864467514
                            ],
                            [
                                -73.95311969756244,
                                40.66953206713695
                            ],
                            [
                                -73.95108006366685,
                                40.66475784801767
                            ],
                            [
                                -73.96046903116037,
                                40.66254038764782
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9e8eba6c-180d-4d8d-b483-acebfc864235"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96046903116037,
                                40.66254038764782
                            ],
                            [
                                -73.96057626551685,
                                40.662390296896895
                            ],
                            [
                                -73.96085434660911,
                                40.66244938491104
                            ],
                            [
                                -73.96046903116037,
                                40.66254038764782
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "5732150f-c91d-4ce8-bab8-277f3d536fa7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96267536037168,
                                40.671571806734754
                            ],
                            [
                                -73.96269190382404,
                                40.67165626661678
                            ],
                            [
                                -73.96228502099075,
                                40.671543548780434
                            ],
                            [
                                -73.96219578285657,
                                40.671421770069514
                            ],
                            [
                                -73.96267536037168,
                                40.671571806734754
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "1f40a59c-5c7e-4d01-a9dc-bb37546405db"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96269190382404,
                                40.67165626661678
                            ],
                            [
                                -73.96267536037168,
                                40.671571806734754
                            ],
                            [
                                -73.96490506357968,
                                40.672269373287136
                            ],
                            [
                                -73.96269190382404,
                                40.67165626661678
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "745e6049-0bd2-46aa-8f2d-c5a9e41d1f27"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96269190382404,
                                40.67165626661678
                            ],
                            [
                                -73.96281020677371,
                                40.672260242918256
                            ],
                            [
                                -73.96228502099075,
                                40.671543548780434
                            ],
                            [
                                -73.96269190382404,
                                40.67165626661678
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "797fa6e6-888c-4bd8-8476-5f22c4b26b9e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96281020677371,
                                40.672260242918256
                            ],
                            [
                                -73.96269190382404,
                                40.67165626661678
                            ],
                            [
                                -73.96490506357968,
                                40.672269373287136
                            ],
                            [
                                -73.96582032692451,
                                40.67255571502701
                            ],
                            [
                                -73.96284011566378,
                                40.672301058048596
                            ],
                            [
                                -73.96281020677371,
                                40.672260242918256
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ee46a20e-de3c-461b-a7b7-05f2628a126f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96281020677371,
                                40.672260242918256
                            ],
                            [
                                -73.96303279199353,
                                40.67339661524203
                            ],
                            [
                                -73.96096025002953,
                                40.671391901034866
                            ],
                            [
                                -73.96101670413829,
                                40.67119218981487
                            ],
                            [
                                -73.96228502099075,
                                40.671543548780434
                            ],
                            [
                                -73.96281020677371,
                                40.672260242918256
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f35b7e52-aa5c-4fec-92e2-22b2f9dc880f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96303279199353,
                                40.67339661524203
                            ],
                            [
                                -73.96281020677371,
                                40.672260242918256
                            ],
                            [
                                -73.96284011566378,
                                40.672301058048596
                            ],
                            [
                                -73.96582032692451,
                                40.67255571502701
                            ],
                            [
                                -73.96490506357968,
                                40.672269373287136
                            ],
                            [
                                -73.96744282176986,
                                40.672972402731894
                            ],
                            [
                                -73.96304463779946,
                                40.67340807337212
                            ],
                            [
                                -73.96303279199353,
                                40.67339661524203
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6c2334fc-2c71-461b-b1ff-06abb37b2b56"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9707484047749,
                                40.67279386287366
                            ],
                            [
                                -73.97192475477588,
                                40.67837984072643
                            ],
                            [
                                -73.97061272814858,
                                40.678082348625686
                            ],
                            [
                                -73.96827082950995,
                                40.67572493151378
                            ],
                            [
                                -73.9707484047749,
                                40.67279386287366
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "fb2efa16-43b0-4062-a803-d9da45fb1c0c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97402301253807,
                                40.65749808700339
                            ],
                            [
                                -73.97514123200254,
                                40.66370899997483
                            ],
                            [
                                -73.97380740442247,
                                40.657497550763644
                            ],
                            [
                                -73.97402301253807,
                                40.65749808700339
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "3c0ad224-d09c-407b-8e2d-abddb8e1d233"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97402301253807,
                                40.65749808700339
                            ],
                            [
                                -73.97391355744128,
                                40.65689014187097
                            ],
                            [
                                -73.98319931856571,
                                40.657251983796876
                            ],
                            [
                                -73.98362007609195,
                                40.663451977150295
                            ],
                            [
                                -73.98136698804977,
                                40.66334368563962
                            ],
                            [
                                -73.98291641392993,
                                40.65752020581501
                            ],
                            [
                                -73.97402301253807,
                                40.65749808700339
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "a1ee7ded-7a2a-4c98-a554-290e36b20635"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98368947505779,
                                40.66447459255248
                            ],
                            [
                                -73.98362007609195,
                                40.663451977150295
                            ],
                            [
                                -73.98441888936175,
                                40.6634903709876
                            ],
                            [
                                -73.98368947505779,
                                40.66447459255248
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f4b60f4b-4a3e-4152-870e-6fd3af938025"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98368947505779,
                                40.66447459255248
                            ],
                            [
                                -73.98369398755501,
                                40.664541085606174
                            ],
                            [
                                -73.98775248078725,
                                40.66727773336881
                            ],
                            [
                                -73.99064607686546,
                                40.66516390126117
                            ],
                            [
                                -73.99065152753568,
                                40.66518798885998
                            ],
                            [
                                -73.98771048095445,
                                40.66732773355656
                            ],
                            [
                                -73.98323258767226,
                                40.66509108501105
                            ],
                            [
                                -73.98368947505779,
                                40.66447459255248
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c84b8fdd-1c51-4594-ac56-078540fca9d3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99064607686546,
                                40.66516390126117
                            ],
                            [
                                -73.99304119676304,
                                40.66341421636932
                            ],
                            [
                                -73.99311388762175,
                                40.66339651028601
                            ],
                            [
                                -73.99065152753568,
                                40.66518798885998
                            ],
                            [
                                -73.99064607686546,
                                40.66516390126117
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "fbcbef81-a8f0-4e87-8c02-3280eddbc569"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99304119676304,
                                40.66341421636932
                            ],
                            [
                                -73.99064607686546,
                                40.66516390126117
                            ],
                            [
                                -73.99039595137718,
                                40.66405854683935
                            ],
                            [
                                -73.99304119676304,
                                40.66341421636932
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "bfc486c3-19fe-4a69-8018-984281f6d0d7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99304119676304,
                                40.66341421636932
                            ],
                            [
                                -73.99619000987302,
                                40.661113942912706
                            ],
                            [
                                -73.99619702130518,
                                40.66115339078219
                            ],
                            [
                                -73.99311388762175,
                                40.66339651028601
                            ],
                            [
                                -73.99304119676304,
                                40.66341421636932
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "faa07bee-d7bc-4972-af2a-e5f7e1d47f67"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99619000987302,
                                40.661113942912706
                            ],
                            [
                                -73.99956579964721,
                                40.658647858282905
                            ],
                            [
                                -73.999654734564,
                                40.658637747658716
                            ],
                            [
                                -73.99619702130518,
                                40.66115339078219
                            ],
                            [
                                -73.99619000987302,
                                40.661113942912706
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c71dfd4c-b919-4817-a184-914cfc06ce0d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99956579964721,
                                40.658647858282905
                            ],
                            [
                                -73.99619000987302,
                                40.661113942912706
                            ],
                            [
                                -73.99582723295863,
                                40.6590728796823
                            ],
                            [
                                -73.99956579964721,
                                40.658647858282905
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "873fdcb5-1166-4c5a-995b-4e2762bd3c79"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99956579964721,
                                40.658647858282905
                            ],
                            [
                                -74.0005910096901,
                                40.65789892102727
                            ],
                            [
                                -73.99570276424889,
                                40.665529673135175
                            ],
                            [
                                -73.99567821222557,
                                40.66525165137717
                            ],
                            [
                                -74.00034439699178,
                                40.658135986982195
                            ],
                            [
                                -73.999654734564,
                                40.658637747658716
                            ],
                            [
                                -73.99956579964721,
                                40.658647858282905
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "96bc25ff-7644-46af-905f-697898c9ebdd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99570276424889,
                                40.665529673135175
                            ],
                            [
                                -73.99485358495477,
                                40.66685527692627
                            ],
                            [
                                -73.99563381123491,
                                40.66804281846359
                            ],
                            [
                                -73.99553984091618,
                                40.668025431677094
                            ],
                            [
                                -73.99471028659897,
                                40.666727682495356
                            ],
                            [
                                -73.99567821222557,
                                40.66525165137717
                            ],
                            [
                                -73.99570276424889,
                                40.665529673135175
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "eb2b6386-4243-4b3f-9e5f-01df1464e14d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99563381123491,
                                40.66804281846359
                            ],
                            [
                                -73.99485358495477,
                                40.66685527692627
                            ],
                            [
                                -73.99570276424889,
                                40.665529673135175
                            ],
                            [
                                -73.99592953130656,
                                40.668097533844126
                            ],
                            [
                                -73.99563381123491,
                                40.66804281846359
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a7ce7527-ce43-4bc2-85b2-5fda3e882f6e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99561537844494,
                                40.705103997568564
                            ],
                            [
                                -73.99529066092693,
                                40.70339234030687
                            ],
                            [
                                -73.99941952447591,
                                40.70812029196093
                            ],
                            [
                                -73.99846016541198,
                                40.70735615325179
                            ],
                            [
                                -73.99561537844494,
                                40.705103997568564
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "aca818de-b6db-4b22-84b7-9f8a286b9c86"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97021946701042,
                                40.71287798013258
                            ],
                            [
                                -73.96798632048306,
                                40.71226937452535
                            ],
                            [
                                -73.96999685521855,
                                40.71224099980826
                            ],
                            [
                                -73.97021946701042,
                                40.71287798013258
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "03c2f243-aecf-441b-9798-8b0f2a41934d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99285400090955,
                                40.7125749455533
                            ],
                            [
                                -73.99215126206643,
                                40.71192833421959
                            ],
                            [
                                -73.99445394282495,
                                40.71189583643981
                            ],
                            [
                                -73.99285400090955,
                                40.7125749455533
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c5963ec2-65e7-46fe-94b9-83790e676f8c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99285400090955,
                                40.7125749455533
                            ],
                            [
                                -73.99481881292837,
                                40.71438282871279
                            ],
                            [
                                -73.99076985046452,
                                40.71412204107879
                            ],
                            [
                                -73.9915424298518,
                                40.71326242163378
                            ],
                            [
                                -73.99140969078474,
                                40.71318799541407
                            ],
                            [
                                -73.99285400090955,
                                40.7125749455533
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b5047dc3-999c-4586-9481-4fe3b1e0cef9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99481881292837,
                                40.71438282871279
                            ],
                            [
                                -73.99285400090955,
                                40.7125749455533
                            ],
                            [
                                -73.99445394282495,
                                40.71189583643981
                            ],
                            [
                                -73.99766431128619,
                                40.71185052844526
                            ],
                            [
                                -73.99663496679888,
                                40.714499804475125
                            ],
                            [
                                -73.99481881292837,
                                40.71438282871279
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "8a6eb0f7-080c-4af9-9842-e88d38d7477b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99663496679888,
                                40.714499804475125
                            ],
                            [
                                -73.99619015413003,
                                40.71564464136492
                            ],
                            [
                                -73.99481881292837,
                                40.71438282871279
                            ],
                            [
                                -73.99663496679888,
                                40.714499804475125
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "5a11ea61-b9bb-4d7c-b954-d787551281e8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99766431128619,
                                40.71185052844526
                            ],
                            [
                                -73.99816634983874,
                                40.71184344316509
                            ],
                            [
                                -73.9982712308431,
                                40.71045094060719
                            ],
                            [
                                -73.9987452754118,
                                40.711107834374474
                            ],
                            [
                                -73.99903271294652,
                                40.71083508182377
                            ],
                            [
                                -73.99845935692822,
                                40.71041326776807
                            ],
                            [
                                -73.99865070130124,
                                40.710114486191436
                            ],
                            [
                                -74.00005811019894,
                                40.709517099374054
                            ],
                            [
                                -73.99896523419731,
                                40.70887570951362
                            ],
                            [
                                -73.99975290331969,
                                40.70839591670913
                            ],
                            [
                                -74.00343453239887,
                                40.71131828433606
                            ],
                            [
                                -73.99976027768554,
                                40.70839170516536
                            ],
                            [
                                -74.00288025975249,
                                40.7079148009347
                            ],
                            [
                                -74.01333390125285,
                                40.70721692025466
                            ],
                            [
                                -74.01089297650496,
                                40.715418141617064
                            ],
                            [
                                -73.99663496679888,
                                40.714499804475125
                            ],
                            [
                                -73.99766431128619,
                                40.71185052844526
                            ]
                        ],
                        [
                            [
                                -73.99871220932451,
                                40.71017996806556
                            ],
                            [
                                -73.99899319156559,
                                40.71062023217553
                            ],
                            [
                                -73.99917021088054,
                                40.71046300612526
                            ],
                            [
                                -73.99871220932451,
                                40.71017996806556
                            ]
                        ],
                        [
                            [
                                -74.01023762226752,
                                40.714432065673535
                            ],
                            [
                                -74.01005205907592,
                                40.71467299827533
                            ],
                            [
                                -74.01052735230286,
                                40.71456083457432
                            ],
                            [
                                -74.01023762226752,
                                40.714432065673535
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "05d0ec4e-e74e-4b13-b86d-87f2d92ffb32"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99766431128619,
                                40.71185052844526
                            ],
                            [
                                -73.99827723894072,
                                40.71027300550401
                            ],
                            [
                                -73.99865070130124,
                                40.710114486191436
                            ],
                            [
                                -73.99845935692822,
                                40.71041326776807
                            ],
                            [
                                -73.99903271294652,
                                40.71083508182377
                            ],
                            [
                                -73.9987452754118,
                                40.711107834374474
                            ],
                            [
                                -73.9982712308431,
                                40.71045094060719
                            ],
                            [
                                -73.99816634983874,
                                40.71184344316509
                            ],
                            [
                                -73.99766431128619,
                                40.71185052844526
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "8947711c-28aa-4cfc-876a-cab8a4b5f293"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99827723894072,
                                40.71027300550401
                            ],
                            [
                                -73.99766431128619,
                                40.71185052844526
                            ],
                            [
                                -73.99445394282495,
                                40.71189583643981
                            ],
                            [
                                -73.99827723894072,
                                40.71027300550401
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "cf392aa6-71ba-4c4d-9c12-6744495a796c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99827723894072,
                                40.71027300550401
                            ],
                            [
                                -73.99880453679437,
                                40.70891587231729
                            ],
                            [
                                -74.00005811019894,
                                40.709517099374054
                            ],
                            [
                                -73.99872301426075,
                                40.710001570511125
                            ],
                            [
                                -73.99865070130124,
                                40.710114486191436
                            ],
                            [
                                -73.99827723894072,
                                40.71027300550401
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "3e94f0d1-cce9-4852-94fe-e7b2259886f0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99880453679437,
                                40.70891587231729
                            ],
                            [
                                -73.99896070938323,
                                40.70851392301722
                            ],
                            [
                                -73.99974964507443,
                                40.708393330410985
                            ],
                            [
                                -73.99975290331969,
                                40.70839591670913
                            ],
                            [
                                -73.99896523419731,
                                40.70887570951362
                            ],
                            [
                                -74.00005811019894,
                                40.709517099374054
                            ],
                            [
                                -73.99880453679437,
                                40.70891587231729
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "df6731f9-3dc3-46b6-b69a-4dfb098359a3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99974964507443,
                                40.708393330410985
                            ],
                            [
                                -73.9997596631377,
                                40.708391799101946
                            ],
                            [
                                -73.99975290331969,
                                40.70839591670913
                            ],
                            [
                                -73.99974964507443,
                                40.708393330410985
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 750
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "01e4d23c-11d1-4f83-90d5-bc6dafd36c6b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9997596631377,
                                40.708391799101946
                            ],
                            [
                                -73.99976027768554,
                                40.70839170516536
                            ],
                            [
                                -74.00343453239887,
                                40.71131828433606
                            ],
                            [
                                -73.99975290331969,
                                40.70839591670913
                            ],
                            [
                                -73.9997596631377,
                                40.708391799101946
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 750
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "fda574d8-879d-4fc9-9877-a4be02fa4ca1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99976027768554,
                                40.70839170516536
                            ],
                            [
                                -73.9997596631377,
                                40.708391799101946
                            ],
                            [
                                -73.99976007820133,
                                40.70839154627428
                            ],
                            [
                                -73.99976027768554,
                                40.70839170516536
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 750
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c26debab-c88b-413c-b73b-5492a1a59e43"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00288025975249,
                                40.7079148009347
                            ],
                            [
                                -73.99976027768554,
                                40.70839170516536
                            ],
                            [
                                -73.99976007820133,
                                40.70839154627428
                            ],
                            [
                                -74.00025502295523,
                                40.70809006063165
                            ],
                            [
                                -74.00288025975249,
                                40.7079148009347
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "764eba4e-811d-4d21-a5dc-d8c6ac82e47f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00776758788518,
                                40.70716774937701
                            ],
                            [
                                -74.00288025975249,
                                40.7079148009347
                            ],
                            [
                                -74.00025502295523,
                                40.70809006063165
                            ],
                            [
                                -74.00320875073368,
                                40.706290856760056
                            ],
                            [
                                -74.00776758788518,
                                40.70716774937701
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "26d2adad-cddb-4be9-bfbc-68f265430341"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00776758788518,
                                40.70716774937701
                            ],
                            [
                                -74.00837395773917,
                                40.70707506283507
                            ],
                            [
                                -74.00863518949741,
                                40.707334632602475
                            ],
                            [
                                -74.00776758788518,
                                40.70716774937701
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "8a036803-bed0-446e-8e06-f329f8c75084"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00783938397318,
                                40.70654389009777
                            ],
                            [
                                -74.00896711070669,
                                40.706984396557644
                            ],
                            [
                                -74.00837395773917,
                                40.70707506283507
                            ],
                            [
                                -74.00783938397318,
                                40.70654389009777
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "4d5e1e92-83a3-468e-b6af-53a28c300d5c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00783938397318,
                                40.70654389009777
                            ],
                            [
                                -74.00722002492131,
                                40.706301959446286
                            ],
                            [
                                -74.00742312349296,
                                40.70613027792153
                            ],
                            [
                                -74.00783938397318,
                                40.70654389009777
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "1cf23e88-0426-487d-aad7-607b8e9a7612"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00749642978847,
                                40.70606831127901
                            ],
                            [
                                -74.00742312349296,
                                40.70613027792153
                            ],
                            [
                                -74.00712694212227,
                                40.70583598089572
                            ],
                            [
                                -74.00749642978847,
                                40.70606831127901
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "73d3c484-d106-4102-bb4f-6caed35217c8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00749642978847,
                                40.70606831127901
                            ],
                            [
                                -74.00816746429967,
                                40.70550107820047
                            ],
                            [
                                -74.0084821462174,
                                40.706688120535716
                            ],
                            [
                                -74.00749642978847,
                                40.70606831127901
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "66b90a0b-2fc2-4e7f-bbe8-a43d1d15c62c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00816746429967,
                                40.70550107820047
                            ],
                            [
                                -74.00749642978847,
                                40.70606831127901
                            ],
                            [
                                -74.00712694212227,
                                40.70583598089572
                            ],
                            [
                                -74.00707707151857,
                                40.70578642757536
                            ],
                            [
                                -74.00798402030442,
                                40.70480909122489
                            ],
                            [
                                -74.00816746429967,
                                40.70550107820047
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "026042a4-7d01-4ab3-beed-523b16f9c792"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00772458705684,
                                40.70303076747769
                            ],
                            [
                                -74.00753723531949,
                                40.70355526775394
                            ],
                            [
                                -74.00840621072811,
                                40.7036464954309
                            ],
                            [
                                -74.00795543342359,
                                40.704169333301905
                            ],
                            [
                                -74.0092125115777,
                                40.70461768789069
                            ],
                            [
                                -74.00816746429967,
                                40.70550107820047
                            ],
                            [
                                -74.00798402030442,
                                40.70480909122489
                            ],
                            [
                                -74.00808331040092,
                                40.704702095303986
                            ],
                            [
                                -74.00757791013724,
                                40.70427549514929
                            ],
                            [
                                -74.0082291105648,
                                40.7038191970626
                            ],
                            [
                                -74.0071077355108,
                                40.70343081627555
                            ],
                            [
                                -74.00776433121995,
                                40.70285105616267
                            ],
                            [
                                -74.00794254309368,
                                40.70292874205865
                            ],
                            [
                                -74.00772458705684,
                                40.70303076747769
                            ]
                        ],
                        [
                            [
                                -74.00803170757774,
                                40.7046552318953
                            ],
                            [
                                -74.00810771044067,
                                40.70460479254069
                            ],
                            [
                                -74.0080457221945,
                                40.70458076629602
                            ],
                            [
                                -74.00803170757774,
                                40.7046552318953
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f1742852-e844-421a-bbbd-adbc82e06e54"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01790168984384,
                                40.704950394684474
                            ],
                            [
                                -74.01809011596626,
                                40.705048776315245
                            ],
                            [
                                -74.0180572286437,
                                40.70505013557534
                            ],
                            [
                                -74.01790168984384,
                                40.704950394684474
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "dfd2ed21-303e-4960-81df-426058891a84"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0169117266378,
                                40.77019216477261
                            ],
                            [
                                -74.01696401397723,
                                40.77021271944402
                            ],
                            [
                                -74.01693907494831,
                                40.77024593495775
                            ],
                            [
                                -74.0169117266378,
                                40.77019216477261
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "24aa4276-0b29-463d-b6f9-001cc082b237"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01647334069432,
                                40.63325263245424
                            ],
                            [
                                -74.01617808961325,
                                40.633451002044495
                            ],
                            [
                                -74.0164064251888,
                                40.63314296589027
                            ],
                            [
                                -74.01647334069432,
                                40.63325263245424
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4bf5cab2-07ea-4cd4-97a7-19ed63ad26ed"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01638105586686,
                                40.615663946981265
                            ],
                            [
                                -74.01484261189107,
                                40.614618337457784
                            ],
                            [
                                -74.01760965023993,
                                40.616291662292085
                            ],
                            [
                                -74.01638105586686,
                                40.615663946981265
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8237477a-ec33-418c-ac3b-80b7505ae4b3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01638039740176,
                                40.6333869295398
                            ],
                            [
                                -74.01637796386566,
                                40.63345505557289
                            ],
                            [
                                -74.01617808961325,
                                40.633451002044495
                            ],
                            [
                                -74.01638039740176,
                                40.6333869295398
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e2807182-f0b3-4039-bfa2-922fe237baaa"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01607516963115,
                                40.77254981208494
                            ],
                            [
                                -74.01574208510358,
                                40.7727080214224
                            ],
                            [
                                -74.01598201703113,
                                40.77226868035085
                            ],
                            [
                                -74.01607516963115,
                                40.77254981208494
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e15dcefc-653a-425b-a957-6d2f4cfb42ca"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01437724864128,
                                40.60626245634786
                            ],
                            [
                                -74.01451194832318,
                                40.60625305632578
                            ],
                            [
                                -74.0146066639918,
                                40.60638338458487
                            ],
                            [
                                -74.01437724864128,
                                40.60626245634786
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5e4a5cd9-87f4-4efd-9313-d2b6254f6a94"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01437484385126,
                                40.77553953055308
                            ],
                            [
                                -74.01434208947937,
                                40.77544000482604
                            ],
                            [
                                -74.01438818113829,
                                40.775403883745476
                            ],
                            [
                                -74.01437484385126,
                                40.77553953055308
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5c1c1a89-492f-4327-b101-fafddfe84c5c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01434234370261,
                                40.61098434115391
                            ],
                            [
                                -74.01280335175447,
                                40.61168865570263
                            ],
                            [
                                -74.01286803943809,
                                40.61066688176255
                            ],
                            [
                                -74.01434234370261,
                                40.61098434115391
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "089637de-8c5a-4dc1-8094-56c26eef2a3c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01410301813786,
                                40.71305842875982
                            ],
                            [
                                -74.01408838727684,
                                40.71311053266388
                            ],
                            [
                                -74.0140296635084,
                                40.713028448279836
                            ],
                            [
                                -74.01410301813786,
                                40.71305842875982
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c80fed79-0387-4c02-acdd-b28fda5bcd94"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01405325793893,
                                40.704656375803445
                            ],
                            [
                                -74.01404740584302,
                                40.70524780092608
                            ],
                            [
                                -74.01375325842608,
                                40.70541103286315
                            ],
                            [
                                -74.01382647986249,
                                40.705304973881
                            ],
                            [
                                -74.01388982725969,
                                40.705213037521766
                            ],
                            [
                                -74.01375323961629,
                                40.7054110433013
                            ],
                            [
                                -74.0135785933531,
                                40.705507960174266
                            ],
                            [
                                -74.01405325793893,
                                40.704656375803445
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "78c02d17-2606-4b75-892f-e906f0c63aba"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01404740584302,
                                40.70524780092608
                            ],
                            [
                                -74.01405325793893,
                                40.704656375803445
                            ],
                            [
                                -74.01430487227715,
                                40.704204960542405
                            ],
                            [
                                -74.0147784856545,
                                40.70484210103921
                            ],
                            [
                                -74.01404740584302,
                                40.70524780092608
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "a7f70f7b-2840-46e1-bd8a-9ef77571d2be"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97377733881365,
                                40.72934322729195
                            ],
                            [
                                -73.97301988300357,
                                40.72865992231203
                            ],
                            [
                                -73.97303988653866,
                                40.72856708087817
                            ],
                            [
                                -73.97383127176126,
                                40.72932754608496
                            ],
                            [
                                -73.97377733881365,
                                40.72934322729195
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "e072b9ec-9efb-44a1-9d58-2eff98e3cd49"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97301988300357,
                                40.72865992231203
                            ],
                            [
                                -73.97377733881365,
                                40.72934322729195
                            ],
                            [
                                -73.9728121968518,
                                40.729623845940615
                            ],
                            [
                                -73.97301988300357,
                                40.72865992231203
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f0c89264-6353-4eb8-b22a-0604fc09d350"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93549933775836,
                                40.749134157441716
                            ],
                            [
                                -73.93713821225396,
                                40.74888978499453
                            ],
                            [
                                -73.9355536843081,
                                40.74867317308252
                            ],
                            [
                                -73.93569532746481,
                                40.747471711717715
                            ],
                            [
                                -73.93860425005643,
                                40.749501410848296
                            ],
                            [
                                -73.93860280064868,
                                40.749772572295555
                            ],
                            [
                                -73.93536925788129,
                                40.7502375354677
                            ],
                            [
                                -73.93549933775836,
                                40.749134157441716
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "66bf3162-6ce7-45be-b416-903fd526aecf"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9355536843081,
                                40.74867317308252
                            ],
                            [
                                -73.93713821225396,
                                40.74888978499453
                            ],
                            [
                                -73.93549933775836,
                                40.749134157441716
                            ],
                            [
                                -73.9355536843081,
                                40.74867317308252
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "f4fc5113-48a3-490c-9d9d-152cbaf84d6e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94872656395255,
                                40.73199084490198
                            ],
                            [
                                -73.94735398571731,
                                40.73582056731624
                            ],
                            [
                                -73.9410414828933,
                                40.733342974248394
                            ],
                            [
                                -73.94362082365343,
                                40.73234266397572
                            ],
                            [
                                -73.94287964437375,
                                40.72775585363357
                            ],
                            [
                                -73.9323112480254,
                                40.72809204261121
                            ],
                            [
                                -73.94388634588849,
                                40.72765335427355
                            ],
                            [
                                -73.94872656395255,
                                40.73199084490198
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "4f069abc-5746-48b9-afb9-3a9c88adea2e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93289959600158,
                                40.72027296470528
                            ],
                            [
                                -73.93695151988003,
                                40.722576369527204
                            ],
                            [
                                -73.9323112480254,
                                40.72809204261121
                            ],
                            [
                                -73.93289959600158,
                                40.72027296470528
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "4b1012d5-a901-4f52-92b1-2024f285bee5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87152664100866,
                                40.71324868984934
                            ],
                            [
                                -73.8717008948894,
                                40.71323768069757
                            ],
                            [
                                -73.87160840567634,
                                40.713311296514675
                            ],
                            [
                                -73.87152664100866,
                                40.71324868984934
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ecccfde3-490a-4c08-bc3b-ec20854c0fa4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8717008948894,
                                40.71323768069757
                            ],
                            [
                                -73.87152664100866,
                                40.71324868984934
                            ],
                            [
                                -73.87149138933081,
                                40.71322169787273
                            ],
                            [
                                -73.87172310975835,
                                40.7132199990061
                            ],
                            [
                                -73.8717008948894,
                                40.71323768069757
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b545c70f-04d0-4c59-9cac-510906068c5a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89247074997449,
                                40.705924167301085
                            ],
                            [
                                -73.89656226128785,
                                40.70598846106877
                            ],
                            [
                                -73.8955501560525,
                                40.70668477064382
                            ],
                            [
                                -73.89214760760503,
                                40.70694542722836
                            ],
                            [
                                -73.89247074997449,
                                40.705924167301085
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "8b1666a4-2638-4e9a-a708-a414e6fb7efe"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8470082292001,
                                40.67615221500679
                            ],
                            [
                                -73.84735942304188,
                                40.67595089576762
                            ],
                            [
                                -73.84704925003777,
                                40.67630335663315
                            ],
                            [
                                -73.8470082292001,
                                40.67615221500679
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "8e5ea2f6-3fc8-46dd-ac54-68f398b424e8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84735942304188,
                                40.67595089576762
                            ],
                            [
                                -73.8470082292001,
                                40.67615221500679
                            ],
                            [
                                -73.84698214678436,
                                40.676056114123504
                            ],
                            [
                                -73.84767146093279,
                                40.675596315763414
                            ],
                            [
                                -73.84735942304188,
                                40.67595089576762
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a97e9aa3-4659-4211-8ed7-6b7569c3dfd8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.85634303451805,
                                40.67864125944039
                            ],
                            [
                                -73.85779292925761,
                                40.679455958373865
                            ],
                            [
                                -73.85668528667766,
                                40.67839346778021
                            ],
                            [
                                -73.85889884161064,
                                40.67679084691624
                            ],
                            [
                                -73.86269676501999,
                                40.67914548992068
                            ],
                            [
                                -73.85496858917342,
                                40.67963636227378
                            ],
                            [
                                -73.85634303451805,
                                40.67864125944039
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "2cf1cd14-e41c-4931-9a0d-60e92b2345e8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88309837516702,
                                40.66601793277751
                            ],
                            [
                                -73.88375264335203,
                                40.66582777844429
                            ],
                            [
                                -73.88435976583183,
                                40.6674678413543
                            ],
                            [
                                -73.88280192076631,
                                40.666429373397584
                            ],
                            [
                                -73.88309837516702,
                                40.66601793277751
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c98a5d86-c388-46d1-b3fd-d5d99eb44029"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88375264335203,
                                40.66582777844429
                            ],
                            [
                                -73.88309837516702,
                                40.66601793277751
                            ],
                            [
                                -73.883577088616,
                                40.6653535400149
                            ],
                            [
                                -73.88375264335203,
                                40.66582777844429
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "4f6468c3-200f-4254-a1b1-ab987674a3db"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88375264335203,
                                40.66582777844429
                            ],
                            [
                                -73.88388004566033,
                                40.665790750660356
                            ],
                            [
                                -73.88439463825662,
                                40.66749108750152
                            ],
                            [
                                -73.88435976583183,
                                40.6674678413543
                            ],
                            [
                                -73.88375264335203,
                                40.66582777844429
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9241fa76-0bd4-471e-8ea9-a61ce1355315"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88388004566033,
                                40.665790750660356
                            ],
                            [
                                -73.88375264335203,
                                40.66582777844429
                            ],
                            [
                                -73.883577088616,
                                40.6653535400149
                            ],
                            [
                                -73.883697254542,
                                40.66518676514838
                            ],
                            [
                                -73.88388004566033,
                                40.665790750660356
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f11d9100-36db-49f3-b075-40790bccb045"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88388004566033,
                                40.665790750660356
                            ],
                            [
                                -73.88481592072544,
                                40.66551875103839
                            ],
                            [
                                -73.88445138303649,
                                40.667715333391406
                            ],
                            [
                                -73.88435976583183,
                                40.6674678413543
                            ],
                            [
                                -73.88439463825662,
                                40.66749108750152
                            ],
                            [
                                -73.88388004566033,
                                40.665790750660356
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "deb69f30-a92f-4929-999e-b0b532796afc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88481592072544,
                                40.66551875103839
                            ],
                            [
                                -73.88388004566033,
                                40.665790750660356
                            ],
                            [
                                -73.883697254542,
                                40.66518676514838
                            ],
                            [
                                -73.883577088616,
                                40.6653535400149
                            ],
                            [
                                -73.88333311345842,
                                40.66469447265994
                            ],
                            [
                                -73.88483952261288,
                                40.665376533948034
                            ],
                            [
                                -73.88481592072544,
                                40.66551875103839
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b910b20a-a825-4a05-8948-b26a09df62ab"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90542237919065,
                                40.64861612305599
                            ],
                            [
                                -73.90491320339679,
                                40.6460860314389
                            ],
                            [
                                -73.90590744417169,
                                40.64538565419011
                            ],
                            [
                                -73.90542237919065,
                                40.64861612305599
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "e685f08f-eeed-4c18-818b-3325abebd377"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91720211510255,
                                40.66149483609391
                            ],
                            [
                                -73.91718595775916,
                                40.66147054999065
                            ],
                            [
                                -73.91880365120399,
                                40.66129760042789
                            ],
                            [
                                -73.91853060687451,
                                40.66104653046846
                            ],
                            [
                                -73.91884032582378,
                                40.66133026452641
                            ],
                            [
                                -73.91720211510255,
                                40.66149483609391
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b9dc667b-6eea-46fc-be20-983f5177b24a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91718595775916,
                                40.66147054999065
                            ],
                            [
                                -73.91720211510255,
                                40.66149483609391
                            ],
                            [
                                -73.91355894072392,
                                40.661860822538976
                            ],
                            [
                                -73.91573390045501,
                                40.65928796262132
                            ],
                            [
                                -73.91575551591049,
                                40.65932045281299
                            ],
                            [
                                -73.91361778217166,
                                40.66185202795837
                            ],
                            [
                                -73.91718595775916,
                                40.66147054999065
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "614a418c-884f-4de6-926d-8c3469dcf5a6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91718595775916,
                                40.66147054999065
                            ],
                            [
                                -73.91709568186644,
                                40.661334856294104
                            ],
                            [
                                -73.91735392574397,
                                40.66129696191936
                            ],
                            [
                                -73.91700196955657,
                                40.659920193428974
                            ],
                            [
                                -73.91700201701963,
                                40.65992006708665
                            ],
                            [
                                -73.91735405060979,
                                40.66129694352051
                            ],
                            [
                                -73.91811275091065,
                                40.660663731906894
                            ],
                            [
                                -73.91853060687451,
                                40.66104653046846
                            ],
                            [
                                -73.91880365120399,
                                40.66129760042789
                            ],
                            [
                                -73.91718595775916,
                                40.66147054999065
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "bb568e2b-e1d7-4ad2-9aa7-7e3ec7eda218"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91709568186644,
                                40.661334856294104
                            ],
                            [
                                -73.91718595775916,
                                40.66147054999065
                            ],
                            [
                                -73.91361778217166,
                                40.66185202795837
                            ],
                            [
                                -73.91575551591049,
                                40.65932045281299
                            ],
                            [
                                -73.91604306961337,
                                40.65975267479079
                            ],
                            [
                                -73.9164340499947,
                                40.66143194330477
                            ],
                            [
                                -73.91709568186644,
                                40.661334856294104
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "a387cade-e172-4421-b8ce-c51c90ef1789"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91709568186644,
                                40.661334856294104
                            ],
                            [
                                -73.91669613491054,
                                40.660734297254926
                            ],
                            [
                                -73.91700196955657,
                                40.659920193428974
                            ],
                            [
                                -73.91735392574397,
                                40.66129696191936
                            ],
                            [
                                -73.91709568186644,
                                40.661334856294104
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "4f321f11-e053-4611-bbce-68d64d8d0061"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91669613491054,
                                40.660734297254926
                            ],
                            [
                                -73.91709568186644,
                                40.661334856294104
                            ],
                            [
                                -73.9164340499947,
                                40.66143194330477
                            ],
                            [
                                -73.91669613491054,
                                40.660734297254926
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "bc64acd8-fcb2-4c72-b311-34534c8d70b5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91604579133401,
                                40.65975676580916
                            ],
                            [
                                -73.91669613491054,
                                40.660734297254926
                            ],
                            [
                                -73.9164340499947,
                                40.66143194330477
                            ],
                            [
                                -73.91604579133401,
                                40.65975676580916
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "dc52e498-ec43-4692-9041-e41287e9d098"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91604579133401,
                                40.65975676580916
                            ],
                            [
                                -73.91604306961337,
                                40.65975267479079
                            ],
                            [
                                -73.91590203714074,
                                40.65914693751592
                            ],
                            [
                                -73.91575551591049,
                                40.65932045281299
                            ],
                            [
                                -73.91573390045501,
                                40.65928796262132
                            ],
                            [
                                -73.91589340433086,
                                40.65909927814914
                            ],
                            [
                                -73.91604579133401,
                                40.65975676580916
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9230d5cb-6d2b-4d19-8d62-8ce78b34135a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91604306961337,
                                40.65975267479079
                            ],
                            [
                                -73.91604579133401,
                                40.65975676580916
                            ],
                            [
                                -73.9164340499947,
                                40.66143194330477
                            ],
                            [
                                -73.91604306961337,
                                40.65975267479079
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a05fe3a9-78a5-416e-a9c9-9daba0a4dc99"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91604306961337,
                                40.65975267479079
                            ],
                            [
                                -73.91575551591049,
                                40.65932045281299
                            ],
                            [
                                -73.91590203714074,
                                40.65914693751592
                            ],
                            [
                                -73.91604306961337,
                                40.65975267479079
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "cb311ca6-ef4f-4c20-83ff-ce3d86856e7c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92562231835014,
                                40.66393909858385
                            ],
                            [
                                -73.92457507807113,
                                40.66703787353669
                            ],
                            [
                                -73.9198074475671,
                                40.66218540621601
                            ],
                            [
                                -73.92323257728154,
                                40.662543348664634
                            ],
                            [
                                -73.92562231835014,
                                40.66393909858385
                            ]
                        ],
                        [
                            [
                                -73.92376488482144,
                                40.6642746130565
                            ],
                            [
                                -73.92166923114625,
                                40.66385650340441
                            ],
                            [
                                -73.92341510240936,
                                40.66545505065738
                            ],
                            [
                                -73.92376488482144,
                                40.6642746130565
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "832c9518-2f0c-4ba4-b984-b6a789d03d10"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92562231835014,
                                40.66393909858385
                            ],
                            [
                                -73.92323257728154,
                                40.662543348664634
                            ],
                            [
                                -73.92599640419176,
                                40.662832181805065
                            ],
                            [
                                -73.92562231835014,
                                40.66393909858385
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8d791234-68f3-4446-a7f3-40fb8b7cadfa"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95735621815169,
                                40.66997805999832
                            ],
                            [
                                -73.95845133974936,
                                40.669337182553
                            ],
                            [
                                -73.95986828093905,
                                40.669815128687624
                            ],
                            [
                                -73.96069249078239,
                                40.67078183871586
                            ],
                            [
                                -73.95755180275644,
                                40.67211111545574
                            ],
                            [
                                -73.95653388174387,
                                40.67075141132881
                            ],
                            [
                                -73.95735621815169,
                                40.66997805999832
                            ]
                        ],
                        [
                            [
                                -73.95820365985247,
                                40.66984594924724
                            ],
                            [
                                -73.95824153102824,
                                40.66985395581247
                            ],
                            [
                                -73.9581981073657,
                                40.669783668207224
                            ],
                            [
                                -73.95820365985247,
                                40.66984594924724
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ed738b59-8048-49ab-b0bd-c299e49fda31"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95735621815169,
                                40.66997805999832
                            ],
                            [
                                -73.95653388174387,
                                40.67075141132881
                            ],
                            [
                                -73.95638181806578,
                                40.670548289860555
                            ],
                            [
                                -73.95735621815169,
                                40.66997805999832
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "70a94f4d-bf2f-4f14-baee-f3deff2e2970"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95735621815169,
                                40.66997805999832
                            ],
                            [
                                -73.95882750747795,
                                40.668594412689046
                            ],
                            [
                                -73.95986828093905,
                                40.669815128687624
                            ],
                            [
                                -73.95845133974936,
                                40.669337182553
                            ],
                            [
                                -73.95735621815169,
                                40.66997805999832
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "cbf039c3-ddbd-4bcd-80a7-f830858132e9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96069249078239,
                                40.67078183871586
                            ],
                            [
                                -73.95986828093905,
                                40.669815128687624
                            ],
                            [
                                -73.96159801332381,
                                40.67039858191296
                            ],
                            [
                                -73.96069249078239,
                                40.67078183871586
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "74db77d1-d6f4-467c-b293-525fedc871f2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96101670413829,
                                40.67119218981487
                            ],
                            [
                                -73.96096025002953,
                                40.671391901034866
                            ],
                            [
                                -73.96064825870988,
                                40.67109012020636
                            ],
                            [
                                -73.96101670413829,
                                40.67119218981487
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9837d541-41c9-49c1-9e73-db8b2ab9e6ad"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99065152753568,
                                40.66518798885998
                            ],
                            [
                                -73.99311388762175,
                                40.66339651028601
                            ],
                            [
                                -73.99546383657842,
                                40.6628241082987
                            ],
                            [
                                -73.99567821222557,
                                40.66525165137717
                            ],
                            [
                                -73.99471028659897,
                                40.666727682495356
                            ],
                            [
                                -73.99553984091618,
                                40.668025431677094
                            ],
                            [
                                -73.99110804769954,
                                40.667205442519915
                            ],
                            [
                                -73.99065152753568,
                                40.66518798885998
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "3225bf7a-dcc2-4099-98b8-4461139d3754"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99311388762175,
                                40.66339651028601
                            ],
                            [
                                -73.99619702130518,
                                40.66115339078219
                            ],
                            [
                                -73.99637185546425,
                                40.66213704646513
                            ],
                            [
                                -73.99984500466071,
                                40.65861611667832
                            ],
                            [
                                -73.999654734564,
                                40.658637747658716
                            ],
                            [
                                -74.00034439699178,
                                40.658135986982195
                            ],
                            [
                                -73.99567821222557,
                                40.66525165137717
                            ],
                            [
                                -73.99546383657842,
                                40.6628241082987
                            ],
                            [
                                -73.99311388762175,
                                40.66339651028601
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "a96133fb-c910-41d0-be35-c5e2f00d681a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99619702130518,
                                40.66115339078219
                            ],
                            [
                                -73.999654734564,
                                40.658637747658716
                            ],
                            [
                                -73.99984500466071,
                                40.65861611667832
                            ],
                            [
                                -73.99637185546425,
                                40.66213704646513
                            ],
                            [
                                -73.99619702130518,
                                40.66115339078219
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b93e53f0-8da4-4e74-9995-03f6c5f6225e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99865070130124,
                                40.710114486191436
                            ],
                            [
                                -73.99872301426075,
                                40.710001570511125
                            ],
                            [
                                -74.00005811019894,
                                40.709517099374054
                            ],
                            [
                                -73.99865070130124,
                                40.710114486191436
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ab0e5875-65a0-412e-b9ff-be2b50b68cc2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00025502295523,
                                40.70809006063165
                            ],
                            [
                                -73.99976007820133,
                                40.70839154627428
                            ],
                            [
                                -73.99944911686256,
                                40.70814386258114
                            ],
                            [
                                -74.00025502295523,
                                40.70809006063165
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "950980c0-0938-4284-8c0d-ce00f24347af"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00798402030442,
                                40.70480909122489
                            ],
                            [
                                -74.00707707151857,
                                40.70578642757536
                            ],
                            [
                                -74.00712694212227,
                                40.70583598089572
                            ],
                            [
                                -74.00631119485679,
                                40.70532304665679
                            ],
                            [
                                -74.00797553412642,
                                40.70477707968567
                            ],
                            [
                                -74.00798402030442,
                                40.70480909122489
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d2400957-72f4-4100-9953-273d33688955"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01382647986249,
                                40.705304973881
                            ],
                            [
                                -74.01375325842608,
                                40.70541103286315
                            ],
                            [
                                -74.01375323961629,
                                40.7054110433013
                            ],
                            [
                                -74.01388982725969,
                                40.705213037521766
                            ],
                            [
                                -74.01382647986249,
                                40.705304973881
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c1c702af-60d7-4b7d-8156-7c2dcfc0e112"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01375325842608,
                                40.70541103286315
                            ],
                            [
                                -74.01373913936236,
                                40.705431483889505
                            ],
                            [
                                -74.01375323961629,
                                40.7054110433013
                            ],
                            [
                                -74.01375325842608,
                                40.70541103286315
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "5b66387c-503a-4903-99e9-f662a56fcf54"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99076985046452,
                                40.71412204107879
                            ],
                            [
                                -73.98941475842328,
                                40.714034761621285
                            ],
                            [
                                -73.99140969078474,
                                40.71318799541407
                            ],
                            [
                                -73.9915424298518,
                                40.71326242163378
                            ],
                            [
                                -73.99076985046452,
                                40.71412204107879
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "0dd2c9b5-9098-40b3-b72e-24486b3eec59"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99140969078474,
                                40.71318799541407
                            ],
                            [
                                -73.98941475842328,
                                40.714034761621285
                            ],
                            [
                                -73.99076985046452,
                                40.71412204107879
                            ],
                            [
                                -73.99045575941219,
                                40.71447151814293
                            ],
                            [
                                -73.98894867202968,
                                40.71407667441684
                            ],
                            [
                                -73.98997414024902,
                                40.71238308844929
                            ],
                            [
                                -73.99140969078474,
                                40.71318799541407
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "e28a3587-e353-438b-bff9-60b40143b12a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98222948888511,
                                40.68909086834752
                            ],
                            [
                                -73.98192191380554,
                                40.68835145469463
                            ],
                            [
                                -73.98440991491073,
                                40.688054626599964
                            ],
                            [
                                -73.98577304405494,
                                40.68849562367353
                            ],
                            [
                                -73.98222948888511,
                                40.68909086834752
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "b6027e90-100a-4f49-b250-b3227fb0e070"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98192191380554,
                                40.68835145469463
                            ],
                            [
                                -73.98222948888511,
                                40.68909086834752
                            ],
                            [
                                -73.97336647715143,
                                40.69057967274794
                            ],
                            [
                                -73.97115877390952,
                                40.68963553865316
                            ],
                            [
                                -73.98192191380554,
                                40.68835145469463
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "6816bcd3-d71f-4ebe-bbfd-927912218d7b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98192191380554,
                                40.68835145469463
                            ],
                            [
                                -73.98139235557508,
                                40.687078391265246
                            ],
                            [
                                -73.98440991491073,
                                40.688054626599964
                            ],
                            [
                                -73.98192191380554,
                                40.68835145469463
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "8624e16a-d7a5-4662-bb89-970e275f1ccd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9879494165255,
                                40.69316259699168
                            ],
                            [
                                -73.98734120779567,
                                40.6939011383718
                            ],
                            [
                                -73.98557701269739,
                                40.69217240145634
                            ],
                            [
                                -73.9863133392779,
                                40.691417353865056
                            ],
                            [
                                -73.98627606638013,
                                40.69213725410272
                            ],
                            [
                                -73.98639085849703,
                                40.69147860117264
                            ],
                            [
                                -73.98634626702463,
                                40.69213935468923
                            ],
                            [
                                -73.98745559567068,
                                40.691597268351984
                            ],
                            [
                                -73.98742246580301,
                                40.69217855404629
                            ],
                            [
                                -73.98773967350624,
                                40.69171174365601
                            ],
                            [
                                -73.98811013423784,
                                40.69181006310479
                            ],
                            [
                                -73.98821340995279,
                                40.69180246785285
                            ],
                            [
                                -73.98841925979846,
                                40.69197044934013
                            ],
                            [
                                -73.98829613850225,
                                40.69191556148019
                            ],
                            [
                                -73.98826386652567,
                                40.692262153744906
                            ],
                            [
                                -73.98846447435074,
                                40.69226634435197
                            ],
                            [
                                -73.9879494165255,
                                40.69316259699168
                            ]
                        ],
                        [
                            [
                                -73.98819831017119,
                                40.69188800174173
                            ],
                            [
                                -73.98817954705983,
                                40.69188045826431
                            ],
                            [
                                -73.98817984350389,
                                40.691896540344146
                            ],
                            [
                                -73.98819831017119,
                                40.69188800174173
                            ]
                        ],
                        [
                            [
                                -73.98581979857259,
                                40.692183490830125
                            ],
                            [
                                -73.98736178370724,
                                40.693586231668355
                            ],
                            [
                                -73.98746266002208,
                                40.69353344772533
                            ],
                            [
                                -73.98581979857259,
                                40.692183490830125
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "e1a12acf-d8ce-4dd7-9daf-40230f14b21a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9879494165255,
                                40.69316259699168
                            ],
                            [
                                -73.98846447435074,
                                40.69226634435197
                            ],
                            [
                                -73.98868373280126,
                                40.692270924562195
                            ],
                            [
                                -73.9879494165255,
                                40.69316259699168
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "e55aebfb-1a54-47d2-b5d6-7f39d6ff0681"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01329393520587,
                                40.703082352805765
                            ],
                            [
                                -74.0130856877567,
                                40.70307341588276
                            ],
                            [
                                -74.01313173629647,
                                40.703038562348304
                            ],
                            [
                                -74.01329393520587,
                                40.703082352805765
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "dd773a26-97b5-4d59-98bf-84568bd99330"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01287659409454,
                                40.61343409213239
                            ],
                            [
                                -74.01116317986096,
                                40.61338560772086
                            ],
                            [
                                -74.0110170050996,
                                40.61340515042282
                            ],
                            [
                                -74.01091419381365,
                                40.612253624904945
                            ],
                            [
                                -74.01247768551453,
                                40.612000893379374
                            ],
                            [
                                -74.01287659409454,
                                40.61343409213239
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6fa4a80d-0015-4f6c-8fd5-ee7b020a7a5a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01125863394472,
                                40.781259062071854
                            ],
                            [
                                -74.01096318792925,
                                40.78230551334397
                            ],
                            [
                                -74.01087797553804,
                                40.7825028102489
                            ],
                            [
                                -74.01049704134854,
                                40.78236728309099
                            ],
                            [
                                -74.01096305138533,
                                40.78230544260301
                            ],
                            [
                                -74.01104721364598,
                                40.78156478137544
                            ],
                            [
                                -74.01110192014846,
                                40.781483468454844
                            ],
                            [
                                -74.01125863394472,
                                40.781259062071854
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "82607b9d-1d84-498e-9fc0-ac76ac6210c2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01108805914365,
                                40.776669886716874
                            ],
                            [
                                -74.01127397357652,
                                40.77662757798358
                            ],
                            [
                                -74.01118757900238,
                                40.77669379170049
                            ],
                            [
                                -74.01108805914365,
                                40.776669886716874
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "7cd02dd3-a727-49b2-a04b-98311dfd44e4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01103740853466,
                                40.70319255750524
                            ],
                            [
                                -74.011064217856,
                                40.703208529935786
                            ],
                            [
                                -74.00986289094507,
                                40.703622787551616
                            ],
                            [
                                -74.00999109041759,
                                40.70318409321873
                            ],
                            [
                                -74.01047641423298,
                                40.70311477987225
                            ],
                            [
                                -74.01034965386155,
                                40.70298745437651
                            ],
                            [
                                -74.01088901493455,
                                40.703123767865584
                            ],
                            [
                                -74.01103740853466,
                                40.70319255750524
                            ]
                        ],
                        [
                            [
                                -74.01008821446948,
                                40.70328963283135
                            ],
                            [
                                -74.01032882282843,
                                40.70318143455132
                            ],
                            [
                                -74.01040581922807,
                                40.703251669916554
                            ],
                            [
                                -74.01036195988264,
                                40.70314466062096
                            ],
                            [
                                -74.01008821446948,
                                40.70328963283135
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "d796b24c-e1fd-4b88-ba58-63f3c9d1562d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01063709588786,
                                40.648422357312526
                            ],
                            [
                                -74.01005314334246,
                                40.6495058148992
                            ],
                            [
                                -74.00947261966651,
                                40.649541980489
                            ],
                            [
                                -74.00977489019853,
                                40.64925135274838
                            ],
                            [
                                -74.01006382520951,
                                40.64897361760761
                            ],
                            [
                                -74.01031138764466,
                                40.648735519995604
                            ],
                            [
                                -74.01063709588786,
                                40.648422357312526
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "7e2d176e-76a7-46ef-a612-cb11844edf2c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00977489019853,
                                40.64925135274838
                            ],
                            [
                                -74.01031138764466,
                                40.648735519995604
                            ],
                            [
                                -74.01006382520951,
                                40.64897361760761
                            ],
                            [
                                -74.00977489019853,
                                40.64925135274838
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "5a3a93e5-df44-4bd4-b0c0-bcbb4d682a59"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01031138764466,
                                40.648735519995604
                            ],
                            [
                                -74.00977489019853,
                                40.64925135274838
                            ],
                            [
                                -74.00971965547134,
                                40.64930444643432
                            ],
                            [
                                -74.01038984718231,
                                40.6486600601281
                            ],
                            [
                                -74.01031138764466,
                                40.648735519995604
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "95423e02-9e00-4a54-a72a-4f871159963d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01036195988264,
                                40.70314466062096
                            ],
                            [
                                -74.01040581922807,
                                40.703251669916554
                            ],
                            [
                                -74.01032882282843,
                                40.70318143455132
                            ],
                            [
                                -74.01008821446948,
                                40.70328963283135
                            ],
                            [
                                -74.01036195988264,
                                40.70314466062096
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "0e3fc22e-03a7-427a-8046-736970585bf7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.01023762226752,
                                40.714432065673535
                            ],
                            [
                                -74.01052735230286,
                                40.71456083457432
                            ],
                            [
                                -74.01005205907592,
                                40.71467299827533
                            ],
                            [
                                -74.01023762226752,
                                40.714432065673535
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "20367a5e-63ec-4b20-bf2d-5a0ef83e41c0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00946009625315,
                                40.67578013179546
                            ],
                            [
                                -74.00938839003223,
                                40.675729897449436
                            ],
                            [
                                -74.0096182033364,
                                40.6755438677559
                            ],
                            [
                                -74.00946009625315,
                                40.67578013179546
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "84ba2e7d-4b4e-4877-9db0-f2ad57b940f2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00901632670616,
                                40.741502138913745
                            ],
                            [
                                -74.00900878984092,
                                40.741540432578894
                            ],
                            [
                                -74.00898626907674,
                                40.741497543372965
                            ],
                            [
                                -74.00901632670616,
                                40.741502138913745
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f6e054fc-4bd6-47e3-a193-9a27fd72fe8e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0080457221945,
                                40.70458076629602
                            ],
                            [
                                -74.00810771044067,
                                40.70460479254069
                            ],
                            [
                                -74.00803170757774,
                                40.7046552318953
                            ],
                            [
                                -74.0080457221945,
                                40.70458076629602
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8e9ba834-dab4-4f3f-8d8e-e14f76a38cc1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00675762162038,
                                40.749726003768735
                            ],
                            [
                                -74.00748895626286,
                                40.74999705962956
                            ],
                            [
                                -74.00817329015136,
                                40.75032570416613
                            ],
                            [
                                -74.00675762162038,
                                40.749726003768735
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ec26f802-1763-48dc-8999-ab19c7a4235e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0054861668285,
                                40.741007891176615
                            ],
                            [
                                -74.00017987863232,
                                40.73876042249244
                            ],
                            [
                                -74.0037923116136,
                                40.738620306349965
                            ],
                            [
                                -74.0054861668285,
                                40.741007891176615
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "d050dffe-0631-4e8e-af3c-7ead92f9588f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00430462710834,
                                40.72028882580046
                            ],
                            [
                                -74.00573688572598,
                                40.72107249087171
                            ],
                            [
                                -74.00536094886442,
                                40.722195479552944
                            ],
                            [
                                -74.00430462710834,
                                40.72028882580046
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "620a6268-a980-48b0-91ec-04e665cf64bb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00389958752652,
                                40.7258350784576
                            ],
                            [
                                -74.00408516958275,
                                40.726284074140395
                            ],
                            [
                                -74.00362066242742,
                                40.72652166754718
                            ],
                            [
                                -74.0035408390296,
                                40.72634872661691
                            ],
                            [
                                -74.00389958752652,
                                40.7258350784576
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "845b4449-7b62-4fcc-9952-785fd35c4a90"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00339500293312,
                                40.756259944402316
                            ],
                            [
                                -74.00362682487027,
                                40.75657361225329
                            ],
                            [
                                -74.00331387413225,
                                40.75644249350558
                            ],
                            [
                                -74.00331525948535,
                                40.756442173518785
                            ],
                            [
                                -74.00067669279852,
                                40.75533312469294
                            ],
                            [
                                -74.00067914259354,
                                40.75533860507223
                            ],
                            [
                                -74.00045458524284,
                                40.755244521003924
                            ],
                            [
                                -74.00264581247774,
                                40.75489252213955
                            ],
                            [
                                -74.00339500293312,
                                40.756259944402316
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "28877976-2262-4ab5-a71d-e78040992bee"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00331387413225,
                                40.75644249350558
                            ],
                            [
                                -74.00067914259354,
                                40.75533860507223
                            ],
                            [
                                -74.00067669279852,
                                40.75533312469294
                            ],
                            [
                                -74.00331525948535,
                                40.756442173518785
                            ],
                            [
                                -74.00331387413225,
                                40.75644249350558
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "3755867d-f2b0-4bcb-ab47-eb0748222a3a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00067914259354,
                                40.75533860507223
                            ],
                            [
                                -74.00331387413225,
                                40.75644249350558
                            ],
                            [
                                -74.00137299044998,
                                40.75689079593079
                            ],
                            [
                                -74.00067914259354,
                                40.75533860507223
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "95b5771e-a8c6-4461-99f4-d4ebad2ecb62"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00297388799927,
                                40.76015411204586
                            ],
                            [
                                -74.0030135270595,
                                40.760149339521774
                            ],
                            [
                                -74.00300167468764,
                                40.76016603450899
                            ],
                            [
                                -74.00297388799927,
                                40.76015411204586
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "89cf36f4-7af1-45c5-b7aa-a7eb434e24dd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00289258866943,
                                40.76011911216714
                            ],
                            [
                                -74.00292943935901,
                                40.76011494998508
                            ],
                            [
                                -74.0029279571388,
                                40.760134040089206
                            ],
                            [
                                -74.00289258866943,
                                40.76011911216714
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5bfc3cc6-5b9b-438d-88f1-fe647f581eb2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.002724286606,
                                40.760046718603924
                            ],
                            [
                                -74.00279072241116,
                                40.760048634012584
                            ],
                            [
                                -74.00277667455674,
                                40.76006923468847
                            ],
                            [
                                -74.002724286606,
                                40.760046718603924
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9c39a405-9faa-4c8f-a63d-ff232eaa4573"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99853200548188,
                                40.72066115909123
                            ],
                            [
                                -73.9980123112254,
                                40.71703511048568
                            ],
                            [
                                -74.00188966367215,
                                40.717110149067146
                            ],
                            [
                                -73.99853200548188,
                                40.72066115909123
                            ]
                        ],
                        [
                            [
                                -73.99973770359497,
                                40.71828811366431
                            ],
                            [
                                -74.00001636638599,
                                40.717940051345515
                            ],
                            [
                                -73.99971864763286,
                                40.7179960119035
                            ],
                            [
                                -73.99973770359497,
                                40.71828811366431
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "d8ba6a9a-45d5-49b3-8efe-01e79147f105"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00186664074975,
                                40.65915244031462
                            ],
                            [
                                -74.00296625681094,
                                40.65974745543131
                            ],
                            [
                                -74.00217330762143,
                                40.65984618047486
                            ],
                            [
                                -74.00186664074975,
                                40.65915244031462
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6378a1fb-256e-4ca1-8800-265afaf7268a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00020148670818,
                                40.69282991823629
                            ],
                            [
                                -74.00038197082516,
                                40.69256506401663
                            ],
                            [
                                -74.00119251711277,
                                40.69326387346813
                            ],
                            [
                                -74.00020148670818,
                                40.69282991823629
                            ]
                        ],
                        [
                            [
                                -74.00028567861324,
                                40.69277512848415
                            ],
                            [
                                -74.00020918616848,
                                40.692831561090394
                            ],
                            [
                                -74.0010140802563,
                                40.69317335491405
                            ],
                            [
                                -74.00028567861324,
                                40.69277512848415
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "d91bfda2-4fe4-49c0-9298-4d5ac97feee2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.0010140802563,
                                40.69317335491405
                            ],
                            [
                                -74.00020918616848,
                                40.692831561090394
                            ],
                            [
                                -74.00028567861324,
                                40.69277512848415
                            ],
                            [
                                -74.0010140802563,
                                40.69317335491405
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "33549947-f2de-48ac-b169-fdd53d55f9b3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00073175054229,
                                40.73201157733927
                            ],
                            [
                                -74.00047113291446,
                                40.73237058389438
                            ],
                            [
                                -74.00031554759643,
                                40.73229899827209
                            ],
                            [
                                -74.0003629355243,
                                40.73251931009577
                            ],
                            [
                                -74.00036325646543,
                                40.732519186092404
                            ],
                            [
                                -74.00022380175815,
                                40.732711288040655
                            ],
                            [
                                -74.00010224981874,
                                40.73219536911694
                            ],
                            [
                                -74.00073175054229,
                                40.73201157733927
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "800e926c-703d-4698-bd66-6db75f574c6e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00047113291446,
                                40.73237058389438
                            ],
                            [
                                -74.00036325646543,
                                40.732519186092404
                            ],
                            [
                                -74.0003629355243,
                                40.73251931009577
                            ],
                            [
                                -74.00031554759643,
                                40.73229899827209
                            ],
                            [
                                -74.00047113291446,
                                40.73237058389438
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "c6e1f891-8837-4967-876e-1b7a01bf783c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -74.00036325646543,
                                40.732519186092404
                            ],
                            [
                                -74.00047113291446,
                                40.73237058389438
                            ],
                            [
                                -74.00059744611912,
                                40.7324287012635
                            ],
                            [
                                -74.00036325646543,
                                40.732519186092404
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "d8520b89-0573-493b-998f-4ee20b88ba3d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99971864763286,
                                40.7179960119035
                            ],
                            [
                                -74.00001636638599,
                                40.717940051345515
                            ],
                            [
                                -73.99973770359497,
                                40.71828811366431
                            ],
                            [
                                -73.99971864763286,
                                40.7179960119035
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "c95b2f13-a3a4-42ef-851d-e1d61d2656aa"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99885063881146,
                                40.619586719072025
                            ],
                            [
                                -73.99891668205284,
                                40.619358667525276
                            ],
                            [
                                -73.99898046872622,
                                40.61975613847747
                            ],
                            [
                                -73.99885063881146,
                                40.619586719072025
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e2118606-a50f-4b55-a451-c5e7286bb3ba"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99848749395956,
                                40.741245848550314
                            ],
                            [
                                -73.99759835047448,
                                40.74141470447628
                            ],
                            [
                                -73.99735462136822,
                                40.74077148750291
                            ],
                            [
                                -73.99827170335695,
                                40.74073621742092
                            ],
                            [
                                -73.99848749395956,
                                40.741245848550314
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "d7b8512d-3e8b-4ddc-8871-b20b16310cea"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99743792483946,
                                40.62466563435086
                            ],
                            [
                                -73.99742376789524,
                                40.625182355486224
                            ],
                            [
                                -73.99734750747422,
                                40.62496089767078
                            ],
                            [
                                -73.99741585035744,
                                40.62472688681025
                            ],
                            [
                                -73.99743792483946,
                                40.62466563435086
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "86114224-6e8e-479c-b3c4-f6f67722874a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99742376789524,
                                40.625182355486224
                            ],
                            [
                                -73.99743792483946,
                                40.62466563435086
                            ],
                            [
                                -73.99741585035744,
                                40.62472688681025
                            ],
                            [
                                -73.99758018653944,
                                40.624164188112765
                            ],
                            [
                                -73.99838544175002,
                                40.625978450889896
                            ],
                            [
                                -73.99737310069673,
                                40.62703168200384
                            ],
                            [
                                -73.9974150185999,
                                40.6255017002377
                            ],
                            [
                                -73.99751784884862,
                                40.62545556355572
                            ],
                            [
                                -73.99742376789524,
                                40.625182355486224
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4df82d02-e8c3-4df0-9305-d2fdf69342ce"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99742376789524,
                                40.625182355486224
                            ],
                            [
                                -73.9974150185999,
                                40.6255017002377
                            ],
                            [
                                -73.99715556950257,
                                40.62561810684608
                            ],
                            [
                                -73.99734750747422,
                                40.62496089767078
                            ],
                            [
                                -73.99742376789524,
                                40.625182355486224
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5a4b1bc6-17fd-4a37-b303-de7bd3ad5ee9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9974150185999,
                                40.6255017002377
                            ],
                            [
                                -73.99742376789524,
                                40.625182355486224
                            ],
                            [
                                -73.99751784884862,
                                40.62545556355572
                            ],
                            [
                                -73.9974150185999,
                                40.6255017002377
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6bf92b75-52a4-4d30-ac57-aec2cd629021"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9974150185999,
                                40.6255017002377
                            ],
                            [
                                -73.99737310069673,
                                40.62703168200384
                            ],
                            [
                                -73.99718113497755,
                                40.62723140152286
                            ],
                            [
                                -73.99664424567575,
                                40.627368915754
                            ],
                            [
                                -73.99670466505356,
                                40.62716203553577
                            ],
                            [
                                -73.99709160183956,
                                40.62701507048301
                            ],
                            [
                                -73.99708629503962,
                                40.62585530750143
                            ],
                            [
                                -73.99715556950257,
                                40.62561810684608
                            ],
                            [
                                -73.9974150185999,
                                40.6255017002377
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c0fa9d14-62f9-4cc9-b477-2b4d02798e03"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99737310069673,
                                40.62703168200384
                            ],
                            [
                                -73.99736894680206,
                                40.6271832970094
                            ],
                            [
                                -73.99718113497755,
                                40.62723140152286
                            ],
                            [
                                -73.99737310069673,
                                40.62703168200384
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "de161a99-7ed5-4066-aefd-23a8c8abd0b4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99664424567575,
                                40.627368915754
                            ],
                            [
                                -73.99718113497755,
                                40.62723140152286
                            ],
                            [
                                -73.9964676023988,
                                40.6279737548141
                            ],
                            [
                                -73.99664424567575,
                                40.627368915754
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "78389b9e-34e4-4367-b462-655215321b7b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99664424567575,
                                40.627368915754
                            ],
                            [
                                -73.99644534214606,
                                40.6274198612019
                            ],
                            [
                                -73.99660187456573,
                                40.62698551372016
                            ],
                            [
                                -73.99669568105618,
                                40.627165447808
                            ],
                            [
                                -73.99670466505356,
                                40.62716203553577
                            ],
                            [
                                -73.99664424567575,
                                40.627368915754
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "323cf5ea-4589-4f12-85d8-25d01ce38453"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99660187456573,
                                40.62698551372016
                            ],
                            [
                                -73.996976661985,
                                40.625945550419885
                            ],
                            [
                                -73.99708614816747,
                                40.625823209647876
                            ],
                            [
                                -73.99708629503962,
                                40.62585530750143
                            ],
                            [
                                -73.99670466505356,
                                40.62716203553577
                            ],
                            [
                                -73.99669568105618,
                                40.627165447808
                            ],
                            [
                                -73.99660187456573,
                                40.62698551372016
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1e563446-83be-4cfb-bfd8-0153c5c28794"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.996976661985,
                                40.625945550419885
                            ],
                            [
                                -73.99660187456573,
                                40.62698551372016
                            ],
                            [
                                -73.99639724185418,
                                40.62659299929909
                            ],
                            [
                                -73.996976661985,
                                40.625945550419885
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1d06ec97-5e23-49e0-b321-c7126ffd1c7c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.996976661985,
                                40.625945550419885
                            ],
                            [
                                -73.99741585035744,
                                40.62472688681025
                            ],
                            [
                                -73.99734750747422,
                                40.62496089767078
                            ],
                            [
                                -73.99734601730444,
                                40.62495657026555
                            ],
                            [
                                -73.99714411106285,
                                40.62562324788576
                            ],
                            [
                                -73.99715556950257,
                                40.62561810684608
                            ],
                            [
                                -73.99708629503962,
                                40.62585530750143
                            ],
                            [
                                -73.99708614816747,
                                40.625823209647876
                            ],
                            [
                                -73.996976661985,
                                40.625945550419885
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "03c1f9ac-348a-4dad-8647-1f493f6d7e04"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99714411106285,
                                40.62562324788576
                            ],
                            [
                                -73.99734601730444,
                                40.62495657026555
                            ],
                            [
                                -73.99734750747422,
                                40.62496089767078
                            ],
                            [
                                -73.99715556950257,
                                40.62561810684608
                            ],
                            [
                                -73.99714411106285,
                                40.62562324788576
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "057f33ee-258e-4a96-9284-3f52431a4cbb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99708629503962,
                                40.62585530750143
                            ],
                            [
                                -73.99709160183956,
                                40.62701507048301
                            ],
                            [
                                -73.99670466505356,
                                40.62716203553577
                            ],
                            [
                                -73.99708629503962,
                                40.62585530750143
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3523e65b-35f0-4432-9b5b-490f5aedfbc5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99672486911639,
                                40.66508027527355
                            ],
                            [
                                -73.99677416776088,
                                40.6651130451809
                            ],
                            [
                                -73.99669431112537,
                                40.66506208460191
                            ],
                            [
                                -73.99672486911639,
                                40.66508027527355
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5f8174f5-90bd-4884-8b7e-51f268648400"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9964427639812,
                                40.715522362032345
                            ],
                            [
                                -73.99646508262443,
                                40.71539102736916
                            ],
                            [
                                -73.99656144580328,
                                40.71531826441711
                            ],
                            [
                                -73.9964427639812,
                                40.715522362032345
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "3ab3921d-63d8-4e2b-963d-42d2b931d8c2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99631465883179,
                                40.72526557949837
                            ],
                            [
                                -73.99620402681298,
                                40.72529693509946
                            ],
                            [
                                -73.99608217214131,
                                40.72520991983208
                            ],
                            [
                                -73.99631465883179,
                                40.72526557949837
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "45ad13b2-d821-4dd9-9d0f-55315ed0b09f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99607865254202,
                                40.67326525791574
                            ],
                            [
                                -73.99623764895742,
                                40.673979763407644
                            ],
                            [
                                -73.99544189343098,
                                40.67359567468362
                            ],
                            [
                                -73.99607865254202,
                                40.67326525791574
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "88de7e40-4b7e-418a-aa0a-cb0548ba6cf2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99607120910159,
                                40.678276492797885
                            ],
                            [
                                -73.99587063990079,
                                40.67860060067131
                            ],
                            [
                                -73.99267765513088,
                                40.67938845972711
                            ],
                            [
                                -73.99391093578625,
                                40.68176736571098
                            ],
                            [
                                -73.99372463530631,
                                40.68206841618201
                            ],
                            [
                                -73.9924401028405,
                                40.67946457078206
                            ],
                            [
                                -73.99607120910159,
                                40.678276492797885
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "735a5a8c-ef2b-409f-81be-a7b0e1983821"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99587063990079,
                                40.67860060067131
                            ],
                            [
                                -73.99391093578625,
                                40.68176736571098
                            ],
                            [
                                -73.99267765513088,
                                40.67938845972711
                            ],
                            [
                                -73.99587063990079,
                                40.67860060067131
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "bb81d9b9-4fc9-44e9-98d1-4bd010f7464d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99391093578625,
                                40.68176736571098
                            ],
                            [
                                -73.99587063990079,
                                40.67860060067131
                            ],
                            [
                                -73.99591646620907,
                                40.67858929317235
                            ],
                            [
                                -73.99391860403402,
                                40.68178215718641
                            ],
                            [
                                -73.99391093578625,
                                40.68176736571098
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "3f3e2c28-b59a-4431-9229-3930b323ab94"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99548935142744,
                                40.72507015707339
                            ],
                            [
                                -73.9954951956659,
                                40.72510167967316
                            ],
                            [
                                -73.99543893194867,
                                40.72508111706688
                            ],
                            [
                                -73.99548935142744,
                                40.72507015707339
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c05651b1-9e1d-4ccf-9d7c-5b00fbbf3948"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9954951956659,
                                40.72510167967316
                            ],
                            [
                                -73.99572816679526,
                                40.72635827721349
                            ],
                            [
                                -73.99529318523143,
                                40.72636628508677
                            ],
                            [
                                -73.99415927608455,
                                40.7258901188248
                            ],
                            [
                                -73.99400285348429,
                                40.725393286311885
                            ],
                            [
                                -73.99543893194867,
                                40.72508111706688
                            ],
                            [
                                -73.9954951956659,
                                40.72510167967316
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "8af47120-1e69-47bd-b1fa-8ad202a4633f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99572816679526,
                                40.72635827721349
                            ],
                            [
                                -73.9954951956659,
                                40.72510167967316
                            ],
                            [
                                -73.99877979766816,
                                40.726302097652436
                            ],
                            [
                                -73.99572816679526,
                                40.72635827721349
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "e5205dac-87f9-4973-9f76-f8ae8908b438"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99572816679526,
                                40.72635827721349
                            ],
                            [
                                -73.99576650145553,
                                40.72656504635241
                            ],
                            [
                                -73.99529318523143,
                                40.72636628508677
                            ],
                            [
                                -73.99572816679526,
                                40.72635827721349
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "40af2f61-e536-4bf9-9b05-327e8bc0b4cc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99415927608455,
                                40.7258901188248
                            ],
                            [
                                -73.99529318523143,
                                40.72636628508677
                            ],
                            [
                                -73.99431486258395,
                                40.72638429569788
                            ],
                            [
                                -73.99415927608455,
                                40.7258901188248
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "8d16600c-aa90-4e52-bd76-92220a9ad01e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99415927608455,
                                40.7258901188248
                            ],
                            [
                                -73.9933263447672,
                                40.72554034318983
                            ],
                            [
                                -73.99400285348429,
                                40.725393286311885
                            ],
                            [
                                -73.99415927608455,
                                40.7258901188248
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "8fdf0167-7664-44ef-b991-d58af32a1f22"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99543893194867,
                                40.72508111706688
                            ],
                            [
                                -73.99400285348429,
                                40.725393286311885
                            ],
                            [
                                -73.99370506460951,
                                40.72444744348142
                            ],
                            [
                                -73.99543893194867,
                                40.72508111706688
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "ac94baf7-f3fa-4530-97a3-b87760a12d44"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99545242617768,
                                40.72162095221274
                            ],
                            [
                                -73.99851723448366,
                                40.721940020303
                            ],
                            [
                                -73.99658920913544,
                                40.72355198956997
                            ],
                            [
                                -73.99545242617768,
                                40.72162095221274
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "02b6badb-2b12-4565-aa7c-8b431833d284"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99502067429258,
                                40.72056796328588
                            ],
                            [
                                -73.9945695223149,
                                40.7204261693894
                            ],
                            [
                                -73.99417360430216,
                                40.71960156714236
                            ],
                            [
                                -73.99412228413026,
                                40.71972399288943
                            ],
                            [
                                -73.99355792236261,
                                40.719993593469255
                            ],
                            [
                                -73.99357136944057,
                                40.72011245695469
                            ],
                            [
                                -73.9929412837173,
                                40.71991442543926
                            ],
                            [
                                -73.9943213430304,
                                40.719264968979665
                            ],
                            [
                                -73.99502067429258,
                                40.72056796328588
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "9f16f986-e99c-436a-b7cd-d78f868d0634"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9945695223149,
                                40.7204261693894
                            ],
                            [
                                -73.99442084672356,
                                40.72037944169573
                            ],
                            [
                                -73.99412524439714,
                                40.719722578743905
                            ],
                            [
                                -73.99412228413026,
                                40.71972399288943
                            ],
                            [
                                -73.99417360430216,
                                40.71960156714236
                            ],
                            [
                                -73.9945695223149,
                                40.7204261693894
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "5ec044e3-7a33-4a4a-851d-3bf036195251"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99442084672356,
                                40.72037944169573
                            ],
                            [
                                -73.9945695223149,
                                40.7204261693894
                            ],
                            [
                                -73.99461031758258,
                                40.72051113614592
                            ],
                            [
                                -73.99361240069302,
                                40.72094033450339
                            ],
                            [
                                -73.9936558821638,
                                40.72083660820348
                            ],
                            [
                                -73.9944567182454,
                                40.72045915241387
                            ],
                            [
                                -73.99442084672356,
                                40.72037944169573
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "0d79ac0a-83a3-415f-b098-1628007aabea"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99442084672356,
                                40.72037944169573
                            ],
                            [
                                -73.99391426570074,
                                40.720220226839835
                            ],
                            [
                                -73.99412228413026,
                                40.71972399288943
                            ],
                            [
                                -73.99412524439714,
                                40.719722578743905
                            ],
                            [
                                -73.99442084672356,
                                40.72037944169573
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "91d34f00-9dc6-4f07-8999-e73d313ee849"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99391426570074,
                                40.720220226839835
                            ],
                            [
                                -73.99442084672356,
                                40.72037944169573
                            ],
                            [
                                -73.9944567182454,
                                40.72045915241387
                            ],
                            [
                                -73.9936558821638,
                                40.72083660820348
                            ],
                            [
                                -73.99391426570074,
                                40.720220226839835
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "381da581-e6bb-4905-844e-db08313dba44"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99391426570074,
                                40.720220226839835
                            ],
                            [
                                -73.99357136944057,
                                40.72011245695469
                            ],
                            [
                                -73.99355792236261,
                                40.719993593469255
                            ],
                            [
                                -73.99412228413026,
                                40.71972399288943
                            ],
                            [
                                -73.99391426570074,
                                40.720220226839835
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "11624dfd-3213-4762-b9aa-05347581e969"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99357136944057,
                                40.72011245695469
                            ],
                            [
                                -73.99391426570074,
                                40.720220226839835
                            ],
                            [
                                -73.9936558821638,
                                40.72083660820348
                            ],
                            [
                                -73.9936534240571,
                                40.7208377667759
                            ],
                            [
                                -73.99357136944057,
                                40.72011245695469
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "7f893078-eec4-4d64-94b1-2722f4fd024f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99274202750581,
                                40.7429869563501
                            ],
                            [
                                -73.9927511481009,
                                40.74275186375211
                            ],
                            [
                                -73.99296710668845,
                                40.74267823503271
                            ],
                            [
                                -73.99274202750581,
                                40.7429869563501
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "0b162781-2414-471a-a226-f67eae745c36"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99041405602344,
                                40.62067094674864
                            ],
                            [
                                -73.98990530324711,
                                40.61994286650961
                            ],
                            [
                                -73.99146304213689,
                                40.620883401909914
                            ],
                            [
                                -73.99041405602344,
                                40.62067094674864
                            ]
                        ],
                        [
                            [
                                -73.99086016298678,
                                40.62051939564646
                            ],
                            [
                                -73.99068422165018,
                                40.62041322723388
                            ],
                            [
                                -73.99041405602344,
                                40.62067094674864
                            ],
                            [
                                -73.99086016298678,
                                40.62051939564646
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "2a1484c3-2150-4f8e-9011-29ac7f9175c5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99102342100988,
                                40.7301331871152
                            ],
                            [
                                -73.9910236460507,
                                40.73013299502906
                            ],
                            [
                                -73.99102353541356,
                                40.73013313555337
                            ],
                            [
                                -73.99102342100988,
                                40.7301331871152
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "685367eb-40ad-4174-b666-e21f03670898"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9910236460507,
                                40.73013299502906
                            ],
                            [
                                -73.99102342100988,
                                40.7301331871152
                            ],
                            [
                                -73.99102353541356,
                                40.73013313555337
                            ],
                            [
                                -73.99074262112681,
                                40.73048993512106
                            ],
                            [
                                -73.99079123525503,
                                40.729999962962644
                            ],
                            [
                                -73.99058452751827,
                                40.730519529931094
                            ],
                            [
                                -73.99010388123055,
                                40.729642229340605
                            ],
                            [
                                -73.99274529737345,
                                40.73019323612687
                            ],
                            [
                                -73.99253254487313,
                                40.7305809434596
                            ],
                            [
                                -73.9921592490585,
                                40.73073063874041
                            ],
                            [
                                -73.99107079171519,
                                40.730111837098924
                            ],
                            [
                                -73.99116984011299,
                                40.73006719590365
                            ],
                            [
                                -73.99116065663584,
                                40.73001604809172
                            ],
                            [
                                -73.99105737202831,
                                40.73010420783874
                            ],
                            [
                                -73.99104973086793,
                                40.7300998637433
                            ],
                            [
                                -73.9910236460507,
                                40.73013299502906
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "e07e230c-c0c6-4f6c-899d-5d529a3dac54"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9910236460507,
                                40.73013299502906
                            ],
                            [
                                -73.99105737202831,
                                40.73010420783874
                            ],
                            [
                                -73.99107079171519,
                                40.730111837098924
                            ],
                            [
                                -73.99102353541356,
                                40.73013313555337
                            ],
                            [
                                -73.9910236460507,
                                40.73013299502906
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "01d6bc1f-bd49-4074-a25d-0b859065cf29"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99105737202831,
                                40.73010420783874
                            ],
                            [
                                -73.9910236460507,
                                40.73013299502906
                            ],
                            [
                                -73.99104973086793,
                                40.7300998637433
                            ],
                            [
                                -73.99105737202831,
                                40.73010420783874
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "5c1318a0-adf6-4144-8a2d-3b5a8b00e852"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99105737202831,
                                40.73010420783874
                            ],
                            [
                                -73.99116065663584,
                                40.73001604809172
                            ],
                            [
                                -73.99116984011299,
                                40.73006719590365
                            ],
                            [
                                -73.99107079171519,
                                40.730111837098924
                            ],
                            [
                                -73.99105737202831,
                                40.73010420783874
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "48c15d3c-7140-49c3-9cd8-7028fa077482"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99096507543219,
                                40.69945750913745
                            ],
                            [
                                -73.99077541232961,
                                40.69957123540446
                            ],
                            [
                                -73.99053105530098,
                                40.69933705321722
                            ],
                            [
                                -73.99096507543219,
                                40.69945750913745
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "85a69e35-3887-49ea-bf15-e6bce1ed3c95"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9908600471041,
                                40.68614416851833
                            ],
                            [
                                -73.9908585415277,
                                40.68614624000281
                            ],
                            [
                                -73.99088143868597,
                                40.68611315482545
                            ],
                            [
                                -73.9908600471041,
                                40.68614416851833
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "e5079594-9b1b-4009-8838-3ea35c433f67"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.99041405602344,
                                40.62067094674864
                            ],
                            [
                                -73.99068422165018,
                                40.62041322723388
                            ],
                            [
                                -73.99086016298678,
                                40.62051939564646
                            ],
                            [
                                -73.99041405602344,
                                40.62067094674864
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "7fd4683e-0bff-451f-8e6a-131aa9d803a5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98985220742392,
                                40.73409506814385
                            ],
                            [
                                -73.9897891241314,
                                40.73448933706499
                            ],
                            [
                                -73.98947634152927,
                                40.73440093976056
                            ],
                            [
                                -73.98934694460247,
                                40.73431741882122
                            ],
                            [
                                -73.98985220742392,
                                40.73409506814385
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "2e5e05e0-2d0d-49b4-9553-54c6ee45f4db"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98954856787473,
                                40.72367340723626
                            ],
                            [
                                -73.99073738933859,
                                40.722311627458836
                            ],
                            [
                                -73.99118058306549,
                                40.723538608389404
                            ],
                            [
                                -73.98954856787473,
                                40.72367340723626
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "9b9e3b9e-aee7-4ca6-8d43-02300b9f9bee"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98898415907667,
                                40.74214967139198
                            ],
                            [
                                -73.99011805664574,
                                40.740838202493
                            ],
                            [
                                -73.99059092981129,
                                40.74184587248453
                            ],
                            [
                                -73.98898415907667,
                                40.74214967139198
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "9e1d87ea-58e5-4fba-beb7-d713207811a2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98869875442944,
                                40.74561739104584
                            ],
                            [
                                -73.98872627880974,
                                40.745446237946595
                            ],
                            [
                                -73.98870508934881,
                                40.745585009181674
                            ],
                            [
                                -73.98869875442944,
                                40.74561739104584
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "595435a7-f5b5-4e5b-81f1-98572934f774"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98850556633771,
                                40.74591940799816
                            ],
                            [
                                -73.98863568730661,
                                40.745241467121716
                            ],
                            [
                                -73.98881842170339,
                                40.74540236714166
                            ],
                            [
                                -73.98862991293596,
                                40.745974432380436
                            ],
                            [
                                -73.98850556633771,
                                40.74591940799816
                            ]
                        ],
                        [
                            [
                                -73.98870508934881,
                                40.745585009181674
                            ],
                            [
                                -73.98872627880974,
                                40.745446237946595
                            ],
                            [
                                -73.98869875442944,
                                40.74561739104584
                            ],
                            [
                                -73.98870508934881,
                                40.745585009181674
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f94affe3-1f4d-44d5-8b12-06119dd539c2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98825798256055,
                                40.71668314089393
                            ],
                            [
                                -73.98745451183504,
                                40.71823715835798
                            ],
                            [
                                -73.98522943066953,
                                40.71755628394011
                            ],
                            [
                                -73.98508515633324,
                                40.71724366456141
                            ],
                            [
                                -73.98825798256055,
                                40.71668314089393
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "becf607b-1065-4404-a58f-659707f6e107"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98745451183504,
                                40.71823715835798
                            ],
                            [
                                -73.98644486790685,
                                40.72018994176008
                            ],
                            [
                                -73.98522943066953,
                                40.71755628394011
                            ],
                            [
                                -73.98745451183504,
                                40.71823715835798
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "af00fe42-6eca-4204-aab7-ee5c50c7fb8a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98644486790685,
                                40.72018994176008
                            ],
                            [
                                -73.98745451183504,
                                40.71823715835798
                            ],
                            [
                                -73.99140515649661,
                                40.71944605488089
                            ],
                            [
                                -73.9854932951028,
                                40.72186469100917
                            ],
                            [
                                -73.98272646067151,
                                40.71679037559179
                            ],
                            [
                                -73.98522943066953,
                                40.71755628394011
                            ],
                            [
                                -73.98644486790685,
                                40.72018994176008
                            ]
                        ],
                        [
                            [
                                -73.98811391075529,
                                40.71861103910498
                            ],
                            [
                                -73.98806886563368,
                                40.718597458802726
                            ],
                            [
                                -73.98811394638817,
                                40.718611101174254
                            ],
                            [
                                -73.98811391075529,
                                40.71861103910498
                            ]
                        ],
                        [
                            [
                                -73.98811391345023,
                                40.718611164382
                            ],
                            [
                                -73.98811394646127,
                                40.71861110119638
                            ],
                            [
                                -73.98811394638817,
                                40.718611101174254
                            ],
                            [
                                -73.98811391345023,
                                40.718611164382
                            ]
                        ],
                        [
                            [
                                -73.98815430619699,
                                40.71862331488969
                            ],
                            [
                                -73.9881140293329,
                                40.71861107450935
                            ],
                            [
                                -73.98811394646127,
                                40.71861110119638
                            ],
                            [
                                -73.98815430619699,
                                40.71862331488969
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "3f5c3b45-dbf3-4657-abf4-a3ba938ac1fb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98819831017119,
                                40.69188800174173
                            ],
                            [
                                -73.98817984350389,
                                40.691896540344146
                            ],
                            [
                                -73.98817954705983,
                                40.69188045826431
                            ],
                            [
                                -73.98819831017119,
                                40.69188800174173
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "60472425-8623-4cef-bc8b-12096567022f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98811394638817,
                                40.718611101174254
                            ],
                            [
                                -73.98806886563368,
                                40.718597458802726
                            ],
                            [
                                -73.98811391075529,
                                40.71861103910498
                            ],
                            [
                                -73.98811394638817,
                                40.718611101174254
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "0e0caf0f-f759-4a7d-9bdc-30cfc4569648"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98797793726231,
                                40.763158253376446
                            ],
                            [
                                -73.98518315893749,
                                40.7635706553224
                            ],
                            [
                                -73.98433080505656,
                                40.761549040689545
                            ],
                            [
                                -73.98477486897046,
                                40.7617449736154
                            ],
                            [
                                -73.98539522153165,
                                40.76327240220962
                            ],
                            [
                                -73.98759190872684,
                                40.762995967483384
                            ],
                            [
                                -73.9862559749341,
                                40.76239847734733
                            ],
                            [
                                -73.98797793726231,
                                40.763158253376446
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "8a159550-e079-434e-9e06-f136b47e3fa4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98477486897046,
                                40.7617449736154
                            ],
                            [
                                -73.9862559749341,
                                40.76239847734733
                            ],
                            [
                                -73.98759190872684,
                                40.762995967483384
                            ],
                            [
                                -73.98539522153165,
                                40.76327240220962
                            ],
                            [
                                -73.98477486897046,
                                40.7617449736154
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "97ff6e63-156c-458a-b554-78465ae5ecd2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9862559749341,
                                40.76239847734733
                            ],
                            [
                                -73.98477486897046,
                                40.7617449736154
                            ],
                            [
                                -73.98477044504818,
                                40.76173408105879
                            ],
                            [
                                -73.9862559749341,
                                40.76239847734733
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f6701afc-c9e5-449b-9fc6-4f6c9b4016f1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98789760901128,
                                40.756547160535064
                            ],
                            [
                                -73.99215607081902,
                                40.75836226305081
                            ],
                            [
                                -73.98869876520921,
                                40.758730073368184
                            ],
                            [
                                -73.98789760901128,
                                40.756547160535064
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "3b94ebcc-33a8-46dd-954d-bb1306677fbc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9878017434991,
                                40.74971534046483
                            ],
                            [
                                -73.98802050932194,
                                40.74959604527387
                            ],
                            [
                                -73.98794196782002,
                                40.74975661762903
                            ],
                            [
                                -73.9878017434991,
                                40.74971534046483
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "fc7cdd3d-85ea-4f63-ac6b-516affd2bc51"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98588875954202,
                                40.739864517050854
                            ],
                            [
                                -73.98718953264984,
                                40.74041457818506
                            ],
                            [
                                -73.98614437785105,
                                40.74057956752837
                            ],
                            [
                                -73.98588875954202,
                                40.739864517050854
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "c185af19-a1a3-4118-9506-597c8b5f8100"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98581979857259,
                                40.692183490830125
                            ],
                            [
                                -73.98746266002208,
                                40.69353344772533
                            ],
                            [
                                -73.98736178370724,
                                40.693586231668355
                            ],
                            [
                                -73.98581979857259,
                                40.692183490830125
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "c2609e12-0909-4a10-969b-4bd752246d77"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98559338359513,
                                40.73262372224362
                            ],
                            [
                                -73.98631894386695,
                                40.73293464057805
                            ],
                            [
                                -73.98665145886264,
                                40.73307496678096
                            ],
                            [
                                -73.98631885583205,
                                40.73293475970175
                            ],
                            [
                                -73.98559338359513,
                                40.73262372224362
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "7ef92878-7ebe-4f0b-ba93-06e3344df808"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98468458676827,
                                40.77976978133107
                            ],
                            [
                                -73.97997259342135,
                                40.78112896320108
                            ],
                            [
                                -73.97912491347486,
                                40.776741417972126
                            ],
                            [
                                -73.9750776033696,
                                40.77743450452126
                            ],
                            [
                                -73.97466947537983,
                                40.77531391021031
                            ],
                            [
                                -73.98188405563236,
                                40.776936440980755
                            ],
                            [
                                -73.98036214784432,
                                40.77562550311566
                            ],
                            [
                                -73.98191755560595,
                                40.776266340778754
                            ],
                            [
                                -73.98082488621684,
                                40.77321206584013
                            ],
                            [
                                -73.98283259752066,
                                40.77189553038225
                            ],
                            [
                                -73.98468458676827,
                                40.77976978133107
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "d133f099-2571-4d59-8212-d5857e0864ef"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98287311073454,
                                40.76333344784437
                            ],
                            [
                                -73.98193724812002,
                                40.76295028296003
                            ],
                            [
                                -73.98287299745392,
                                40.76332386056578
                            ],
                            [
                                -73.98287311073454,
                                40.76333344784437
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f0a361cd-0927-4d93-b771-b88a8e06e736"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98193724812002,
                                40.76295028296003
                            ],
                            [
                                -73.98287311073454,
                                40.76333344784437
                            ],
                            [
                                -73.9829020130376,
                                40.7657795365511
                            ],
                            [
                                -73.97948446237069,
                                40.76628261229804
                            ],
                            [
                                -73.98050457842756,
                                40.76237832068964
                            ],
                            [
                                -73.98193724812002,
                                40.76295028296003
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "61559c9d-6b75-4b3b-967e-c306be59ca04"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9841684435521,
                                40.74329154022325
                            ],
                            [
                                -73.98431443595545,
                                40.74309103558941
                            ],
                            [
                                -73.98446816465002,
                                40.74288011028838
                            ],
                            [
                                -73.9841684435521,
                                40.74329154022325
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "6a4a72d2-c188-4522-bd8a-b0ca26be2de9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98331761231559,
                                40.75312951654991
                            ],
                            [
                                -73.98312253057523,
                                40.75335313601675
                            ],
                            [
                                -73.98314208600065,
                                40.753211220753165
                            ],
                            [
                                -73.98331761231559,
                                40.75312951654991
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "fbb82cde-00d6-4fc3-9e04-abca87fca2c7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98270073741335,
                                40.693720815311025
                            ],
                            [
                                -73.98264685736092,
                                40.69371855198958
                            ],
                            [
                                -73.98270199505475,
                                40.693703067681255
                            ],
                            [
                                -73.98270073741335,
                                40.693720815311025
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "747b4ef9-9f15-441e-829c-70ab4c259344"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98217563170559,
                                40.69116626383623
                            ],
                            [
                                -73.98182408410399,
                                40.69063497585984
                            ],
                            [
                                -73.98159966787804,
                                40.69001833614539
                            ],
                            [
                                -73.98217563170559,
                                40.69116626383623
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "54bfa4da-9fa3-402a-b4ce-84f78d8142d3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98198411178534,
                                40.76871900016011
                            ],
                            [
                                -73.98088110188901,
                                40.767783168078566
                            ],
                            [
                                -73.98238078176367,
                                40.768087784645246
                            ],
                            [
                                -73.9823756278901,
                                40.768095985933826
                            ],
                            [
                                -73.98095052459247,
                                40.767812445581086
                            ],
                            [
                                -73.98198279126187,
                                40.76870252503031
                            ],
                            [
                                -73.98219829146814,
                                40.768378178958265
                            ],
                            [
                                -73.98198411178534,
                                40.76871900016011
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9268b9a7-95c4-4612-8bba-1c650f6f92d0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9823756278901,
                                40.768095985933826
                            ],
                            [
                                -73.98219829146814,
                                40.768378178958265
                            ],
                            [
                                -73.98198279126187,
                                40.76870252503031
                            ],
                            [
                                -73.98095052459247,
                                40.767812445581086
                            ],
                            [
                                -73.9823756278901,
                                40.768095985933826
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "054167bb-c0ed-44fe-96f9-890c697d5680"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98219829146814,
                                40.768378178958265
                            ],
                            [
                                -73.9823756278901,
                                40.768095985933826
                            ],
                            [
                                -73.9823845987053,
                                40.76809777077855
                            ],
                            [
                                -73.98219829146814,
                                40.768378178958265
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "28387d9d-fb85-43ee-a499-a7dae2002762"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9815582241024,
                                40.66104679148942
                            ],
                            [
                                -73.97776169936996,
                                40.65970089363695
                            ],
                            [
                                -73.97769487549259,
                                40.659176354825156
                            ],
                            [
                                -73.98094749008345,
                                40.65955247382472
                            ],
                            [
                                -73.9815582241024,
                                40.66104679148942
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ec94d502-5906-43a3-8bee-66584de85ab4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98055437833196,
                                40.73049383299144
                            ],
                            [
                                -73.98252270120553,
                                40.731343887309215
                            ],
                            [
                                -73.98197162789783,
                                40.731265983269765
                            ],
                            [
                                -73.98095987515626,
                                40.73083719798922
                            ],
                            [
                                -73.98055437833196,
                                40.73049383299144
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "bac0493c-b2ec-4d99-8110-e49130646b75"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9802406309392,
                                40.64702598765421
                            ],
                            [
                                -73.98040446924871,
                                40.64641286537322
                            ],
                            [
                                -73.98176191978801,
                                40.64467633272257
                            ],
                            [
                                -73.98144373223766,
                                40.644737961383676
                            ],
                            [
                                -73.98216709681209,
                                40.644518161083724
                            ],
                            [
                                -73.9802406309392,
                                40.64702598765421
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "3c42b76e-82cb-49ea-b78c-f649d6b5d21b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98040446924871,
                                40.64641286537322
                            ],
                            [
                                -73.98079974686361,
                                40.644933641658035
                            ],
                            [
                                -73.98144373223766,
                                40.644737961383676
                            ],
                            [
                                -73.98176191978801,
                                40.64467633272257
                            ],
                            [
                                -73.98040446924871,
                                40.64641286537322
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "147c3dae-1ef5-4922-a59c-e71813a963d9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98144373223766,
                                40.644737961383676
                            ],
                            [
                                -73.98079974686361,
                                40.644933641658035
                            ],
                            [
                                -73.98040446924871,
                                40.64641286537322
                            ],
                            [
                                -73.9801793002598,
                                40.64670091513255
                            ],
                            [
                                -73.98001315415307,
                                40.64501504517238
                            ],
                            [
                                -73.98144373223766,
                                40.644737961383676
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b6af628c-5dc2-4fc2-a4d0-f1add142af9a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.98022991673281,
                                40.78395464745753
                            ],
                            [
                                -73.97949049545615,
                                40.784379056793725
                            ],
                            [
                                -73.97927504531503,
                                40.78368043387832
                            ],
                            [
                                -73.97989687622268,
                                40.78362504007438
                            ],
                            [
                                -73.98022991673281,
                                40.78395464745753
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "b50d5406-3d46-422a-a508-57d111af3091"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96976202543783,
                                40.75991640949285
                            ],
                            [
                                -73.96670103357589,
                                40.75956386202114
                            ],
                            [
                                -73.97017893022517,
                                40.75931266802451
                            ],
                            [
                                -73.96976202543783,
                                40.75991640949285
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "890eef93-8996-4f22-904e-6c8678e3bfd2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97918857618072,
                                40.75276957181321
                            ],
                            [
                                -73.97899992547949,
                                40.75299223978845
                            ],
                            [
                                -73.97831762358017,
                                40.75250836601852
                            ],
                            [
                                -73.9777945390818,
                                40.75320896081484
                            ],
                            [
                                -73.97685851970857,
                                40.75280826661237
                            ],
                            [
                                -73.97634892341419,
                                40.7534555290938
                            ],
                            [
                                -73.97556728401412,
                                40.7522107669164
                            ],
                            [
                                -73.97488284681937,
                                40.75304328487285
                            ],
                            [
                                -73.97386940434676,
                                40.75052343902135
                            ],
                            [
                                -73.97918857618072,
                                40.75276957181321
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "e17f769a-1349-42db-b527-53cce7590b0b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97878301647435,
                                40.71351517490267
                            ],
                            [
                                -73.97769478566107,
                                40.71322380936986
                            ],
                            [
                                -73.97794516499538,
                                40.71272873942712
                            ],
                            [
                                -73.97878301647435,
                                40.71351517490267
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "969271b5-3bd1-4e71-b70f-3a234effa593"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9778519737678,
                                40.665084049551105
                            ],
                            [
                                -73.9788532198138,
                                40.66635706663857
                            ],
                            [
                                -73.9797840298567,
                                40.66687712953821
                            ],
                            [
                                -73.9783128714923,
                                40.66698932447328
                            ],
                            [
                                -73.9778519737678,
                                40.665084049551105
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "27da038c-7129-4bea-8dc5-570276f7f5e4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97679711411584,
                                40.65326162514227
                            ],
                            [
                                -73.97679699024184,
                                40.65326165917856
                            ],
                            [
                                -73.9763085494652,
                                40.653183329835876
                            ],
                            [
                                -73.97605579595046,
                                40.651479670366975
                            ],
                            [
                                -73.97234203232787,
                                40.65048805350183
                            ],
                            [
                                -73.97727508018392,
                                40.64899743080581
                            ],
                            [
                                -73.97679711411584,
                                40.65326162514227
                            ]
                        ],
                        [
                            [
                                -73.97560709824583,
                                40.65045275897628
                            ],
                            [
                                -73.97572840225021,
                                40.651053685699864
                            ],
                            [
                                -73.97567596040057,
                                40.650793575350065
                            ],
                            [
                                -73.97560709824583,
                                40.65045275897628
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "e7f34261-6795-4473-ba18-10705f3ecedf"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97679711411584,
                                40.65326162514227
                            ],
                            [
                                -73.9767971081808,
                                40.65326167809197
                            ],
                            [
                                -73.97679699024184,
                                40.65326165917856
                            ],
                            [
                                -73.97679711411584,
                                40.65326162514227
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "cdfd95cb-ae2f-4a4e-8074-9c98f55386e5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97679699024184,
                                40.65326165917856
                            ],
                            [
                                -73.9767971081808,
                                40.65326167809197
                            ],
                            [
                                -73.97679711411584,
                                40.65326162514227
                            ],
                            [
                                -73.97709223259942,
                                40.65318053679985
                            ],
                            [
                                -73.97709384327872,
                                40.65318304003054
                            ],
                            [
                                -73.97627923840265,
                                40.6537769021057
                            ],
                            [
                                -73.9741057331317,
                                40.65357491271795
                            ],
                            [
                                -73.97536369045984,
                                40.653031806481636
                            ],
                            [
                                -73.9763085494652,
                                40.653183329835876
                            ],
                            [
                                -73.97633884611334,
                                40.65338754132433
                            ],
                            [
                                -73.97679699024184,
                                40.65326165917856
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9903bc17-ce63-49e7-a624-26568e4316d9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9763085494652,
                                40.653183329835876
                            ],
                            [
                                -73.97679699024184,
                                40.65326165917856
                            ],
                            [
                                -73.97633884611334,
                                40.65338754132433
                            ],
                            [
                                -73.9763085494652,
                                40.653183329835876
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "65576ec0-1872-44e0-aea2-4053e8a3ed6a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9763085494652,
                                40.653183329835876
                            ],
                            [
                                -73.97536369045984,
                                40.653031806481636
                            ],
                            [
                                -73.97596485859594,
                                40.65277226018664
                            ],
                            [
                                -73.97413808372933,
                                40.65283526070948
                            ],
                            [
                                -73.97279685446732,
                                40.652620173002816
                            ],
                            [
                                -73.97270357447323,
                                40.652331670067554
                            ],
                            [
                                -73.97230432172952,
                                40.65049944854105
                            ],
                            [
                                -73.97234203232787,
                                40.65048805350183
                            ],
                            [
                                -73.97605579595046,
                                40.651479670366975
                            ],
                            [
                                -73.9763085494652,
                                40.653183329835876
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "6f50ea8b-4758-4c7e-9003-5ce833e9455f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97536369045984,
                                40.653031806481636
                            ],
                            [
                                -73.97413808372933,
                                40.65283526070948
                            ],
                            [
                                -73.97596485859594,
                                40.65277226018664
                            ],
                            [
                                -73.97536369045984,
                                40.653031806481636
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "1bf74dfb-dfa6-4f83-b53b-862276dd62f5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97279685446732,
                                40.652620173002816
                            ],
                            [
                                -73.97413808372933,
                                40.65283526070948
                            ],
                            [
                                -73.97288042120269,
                                40.652878634087436
                            ],
                            [
                                -73.97279685446732,
                                40.652620173002816
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f275a3af-1eb3-4236-b2e0-20a2f3dc0f93"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97279685446732,
                                40.652620173002816
                            ],
                            [
                                -73.97276533982827,
                                40.65261511912304
                            ],
                            [
                                -73.97270357447323,
                                40.652331670067554
                            ],
                            [
                                -73.97279685446732,
                                40.652620173002816
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "1eef3c05-89fe-480d-8094-6aecd668585c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97234203232787,
                                40.65048805350183
                            ],
                            [
                                -73.97230432172952,
                                40.65049944854105
                            ],
                            [
                                -73.97270357447323,
                                40.652331670067554
                            ],
                            [
                                -73.9720853268363,
                                40.65041951023395
                            ],
                            [
                                -73.97234203232787,
                                40.65048805350183
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "23e7d48c-1cb0-488b-8184-df8466cccbcf"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97709825131182,
                                40.76421678699716
                            ],
                            [
                                -73.9773025222538,
                                40.7640176410599
                            ],
                            [
                                -73.97737677970328,
                                40.76407921080297
                            ],
                            [
                                -73.97716648284923,
                                40.764364860066614
                            ],
                            [
                                -73.97709825131182,
                                40.76421678699716
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "7adfdd9c-f3d6-43b2-b096-ef6b59ccf267"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9773025222538,
                                40.7640176410599
                            ],
                            [
                                -73.97709825131182,
                                40.76421678699716
                            ],
                            [
                                -73.97716648284923,
                                40.764364860066614
                            ],
                            [
                                -73.97737677970328,
                                40.76407921080297
                            ],
                            [
                                -73.97816511479775,
                                40.76473285012868
                            ],
                            [
                                -73.976396331105,
                                40.76542788263016
                            ],
                            [
                                -73.97622501878705,
                                40.763124240976104
                            ],
                            [
                                -73.9773025222538,
                                40.7640176410599
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "3deb4657-f240-43a6-873a-4f76d8eda72c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9773025222538,
                                40.7640176410599
                            ],
                            [
                                -73.97772618717013,
                                40.76360460557853
                            ],
                            [
                                -73.97737677970328,
                                40.76407921080297
                            ],
                            [
                                -73.9773025222538,
                                40.7640176410599
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "3cf15d5f-50c4-452a-abc5-6b4aaf64d6ff"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97580955425636,
                                40.765497353571014
                            ],
                            [
                                -73.9727463395617,
                                40.764883873536235
                            ],
                            [
                                -73.97295451424732,
                                40.76437586733695
                            ],
                            [
                                -73.9726422670411,
                                40.76484775488146
                            ],
                            [
                                -73.97249127820814,
                                40.76478333304868
                            ],
                            [
                                -73.97303282937379,
                                40.763540724814455
                            ],
                            [
                                -73.97348031974269,
                                40.76342692956702
                            ],
                            [
                                -73.97580955425636,
                                40.765497353571014
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "d71087bf-70d3-4433-9dd5-e7746f3057bb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97567596040057,
                                40.650793575350065
                            ],
                            [
                                -73.97572840225021,
                                40.651053685699864
                            ],
                            [
                                -73.97560709824583,
                                40.65045275897628
                            ],
                            [
                                -73.97567596040057,
                                40.650793575350065
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "0a44de5c-8206-4e92-a31c-6fa58889339b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97563776044142,
                                40.740862762195626
                            ],
                            [
                                -73.97606529293965,
                                40.74052839512751
                            ],
                            [
                                -73.97621071670942,
                                40.740743811856596
                            ],
                            [
                                -73.97563776044142,
                                40.740862762195626
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "8fe5f87f-bb31-4c8b-af82-76fd9d73f242"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97529952587233,
                                40.69794162209738
                            ],
                            [
                                -73.97659452191455,
                                40.69761922503633
                            ],
                            [
                                -73.97746472981176,
                                40.69803262317697
                            ],
                            [
                                -73.97724076633669,
                                40.698206907850235
                            ],
                            [
                                -73.97529952587233,
                                40.69794162209738
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "a510ed16-0ffd-4afe-b76a-ece68c88f2bb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97483636080004,
                                40.697923407700394
                            ],
                            [
                                -73.97411593978995,
                                40.697894632021416
                            ],
                            [
                                -73.9746664112264,
                                40.697791479851624
                            ],
                            [
                                -73.97483636080004,
                                40.697923407700394
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "f2171e85-d931-4ddd-b552-0f3bb79eecf9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91454451585898,
                                40.714929628160405
                            ],
                            [
                                -73.91523178183371,
                                40.71523463573562
                            ],
                            [
                                -73.91373769378735,
                                40.715767813790656
                            ],
                            [
                                -73.91454451585898,
                                40.714929628160405
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "9d179a03-4487-4146-ac12-57d8d8d0a65c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90298449124761,
                                40.70436824544534
                            ],
                            [
                                -73.90302125962035,
                                40.704349392430444
                            ],
                            [
                                -73.90307692906823,
                                40.704422958033604
                            ],
                            [
                                -73.90298449124761,
                                40.70436824544534
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "38e199c3-3fc2-4b20-bd62-d6be462a5fec"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90302125962035,
                                40.704349392430444
                            ],
                            [
                                -73.90298449124761,
                                40.70436824544534
                            ],
                            [
                                -73.90277036819774,
                                40.70424150915725
                            ],
                            [
                                -73.9028679135595,
                                40.704146749941835
                            ],
                            [
                                -73.90302125962035,
                                40.704349392430444
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "119e9e74-988b-46b1-a6e4-7a9c8b256c02"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90352687102309,
                                40.699239200818596
                            ],
                            [
                                -73.90235230830869,
                                40.698116253518364
                            ],
                            [
                                -73.90685881126156,
                                40.69725170815055
                            ],
                            [
                                -73.90490839899815,
                                40.699262931732456
                            ],
                            [
                                -73.90352687102309,
                                40.699239200818596
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "cbee6f2e-3470-4e41-a5ab-05da4c37eb71"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90105818021493,
                                40.69687899506359
                            ],
                            [
                                -73.90235230830869,
                                40.698116253518364
                            ],
                            [
                                -73.90120685224237,
                                40.69833600234501
                            ],
                            [
                                -73.90105818021493,
                                40.69687899506359
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "a4305867-5efe-4b86-ac7a-d4589e277feb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9415074165936,
                                40.68978622609004
                            ],
                            [
                                -73.9403131270643,
                                40.69482406059482
                            ],
                            [
                                -73.93743872519612,
                                40.69421366880238
                            ],
                            [
                                -73.9380732335989,
                                40.68257646786283
                            ],
                            [
                                -73.9415074165936,
                                40.68978622609004
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "3366aae7-4169-498d-8dd5-2bef8e97ef83"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95662725690327,
                                40.715393729903724
                            ],
                            [
                                -73.95591558973838,
                                40.71570249398393
                            ],
                            [
                                -73.95760827452213,
                                40.71717772039974
                            ],
                            [
                                -73.95542645247876,
                                40.7185569585564
                            ],
                            [
                                -73.95366993409345,
                                40.718297972301215
                            ],
                            [
                                -73.95317358294491,
                                40.71501249097023
                            ],
                            [
                                -73.95662725690327,
                                40.715393729903724
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "0c0ead07-1475-4d74-9007-befda025fe7e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95760827452213,
                                40.71717772039974
                            ],
                            [
                                -73.95591558973838,
                                40.71570249398393
                            ],
                            [
                                -73.95662725690327,
                                40.715393729903724
                            ],
                            [
                                -73.95986499874904,
                                40.71575113285001
                            ],
                            [
                                -73.95760827452213,
                                40.71717772039974
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "886f7534-a811-4211-913c-ff7d49a98dcc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95760827452213,
                                40.71717772039974
                            ],
                            [
                                -73.95995734466193,
                                40.719225006673035
                            ],
                            [
                                -73.95542645247876,
                                40.7185569585564
                            ],
                            [
                                -73.95760827452213,
                                40.71717772039974
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "e4997ed4-1579-45e5-a566-ff84fd023525"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95366993409345,
                                40.718297972301215
                            ],
                            [
                                -73.95542645247876,
                                40.7185569585564
                            ],
                            [
                                -73.95385877562283,
                                40.719547965015224
                            ],
                            [
                                -73.95366993409345,
                                40.718297972301215
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "9754ef46-5afd-4fd2-8060-edc9cd494676"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95132775345628,
                                40.71795263427501
                            ],
                            [
                                -73.95043965658567,
                                40.71782169065115
                            ],
                            [
                                -73.9515049694912,
                                40.71751137969638
                            ],
                            [
                                -73.95132775345628,
                                40.71795263427501
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "a39584ba-8c97-40a0-b731-c3edf5d5ffa2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95132775345628,
                                40.71795263427501
                            ],
                            [
                                -73.950398070788,
                                40.72026747409338
                            ],
                            [
                                -73.94848965656074,
                                40.71838969876068
                            ],
                            [
                                -73.95043965658567,
                                40.71782169065115
                            ],
                            [
                                -73.95132775345628,
                                40.71795263427501
                            ]
                        ],
                        [
                            [
                                -73.9501956111842,
                                40.7190779673737
                            ],
                            [
                                -73.95021908955246,
                                40.71941199152565
                            ],
                            [
                                -73.95026978867047,
                                40.719528232117476
                            ],
                            [
                                -73.9501956111842,
                                40.7190779673737
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "5c7544b3-fd2b-45a8-8e34-6cd6ce754dab"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94665946860518,
                                40.71743908201192
                            ],
                            [
                                -73.946631070984,
                                40.717260141548806
                            ],
                            [
                                -73.94691428019965,
                                40.71730189875147
                            ],
                            [
                                -73.94665946860518,
                                40.71743908201192
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "a9ec99c1-eddd-473b-bbb9-f6314131b1ae"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94665946860518,
                                40.71743908201192
                            ],
                            [
                                -73.94752977613695,
                                40.72292310589656
                            ],
                            [
                                -73.9454386756714,
                                40.71953791489558
                            ],
                            [
                                -73.94586385673074,
                                40.719283841365524
                            ],
                            [
                                -73.94517342956968,
                                40.71898175931593
                            ],
                            [
                                -73.94517602031546,
                                40.719112713638204
                            ],
                            [
                                -73.94267065341653,
                                40.7150568848437
                            ],
                            [
                                -73.94500908997499,
                                40.7183275985723
                            ],
                            [
                                -73.94665946860518,
                                40.71743908201192
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "b7fa9166-ac9c-4fc8-861c-ed1c608dfbe5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9454386756714,
                                40.71953791489558
                            ],
                            [
                                -73.94517602031546,
                                40.719112713638204
                            ],
                            [
                                -73.94517342956968,
                                40.71898175931593
                            ],
                            [
                                -73.94586385673074,
                                40.719283841365524
                            ],
                            [
                                -73.9454386756714,
                                40.71953791489558
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "69210213-0805-4cfc-a89a-4e83a587b20a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94517602031546,
                                40.719112713638204
                            ],
                            [
                                -73.9454386756714,
                                40.71953791489558
                            ],
                            [
                                -73.94518740286392,
                                40.71968806685508
                            ],
                            [
                                -73.94517602031546,
                                40.719112713638204
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "8852822a-8537-4928-a195-9edb6bc8385e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94077749442965,
                                40.711992132637214
                            ],
                            [
                                -73.94267065341653,
                                40.7150568848437
                            ],
                            [
                                -73.94050571354892,
                                40.71202883667151
                            ],
                            [
                                -73.94077749442965,
                                40.711992132637214
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "560cc4d1-86ea-49e7-8807-6b9fc22e7a25"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94077749442965,
                                40.711992132637214
                            ],
                            [
                                -73.94032110843592,
                                40.71125330933131
                            ],
                            [
                                -73.94041229208852,
                                40.711159907120326
                            ],
                            [
                                -73.94207093244596,
                                40.71181745370773
                            ],
                            [
                                -73.94077749442965,
                                40.711992132637214
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "62562439-2560-4ab1-a51c-3ebbc7669369"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94032110843592,
                                40.71125330933131
                            ],
                            [
                                -73.94077749442965,
                                40.711992132637214
                            ],
                            [
                                -73.94050571354892,
                                40.71202883667151
                            ],
                            [
                                -73.94010760310805,
                                40.71147200938461
                            ],
                            [
                                -73.94032110843592,
                                40.71125330933131
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "a3899566-d792-452f-97eb-b28300d8e732"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93445246387313,
                                40.70837557151161
                            ],
                            [
                                -73.93780454390416,
                                40.70717935317921
                            ],
                            [
                                -73.94032110843592,
                                40.71125330933131
                            ],
                            [
                                -73.94010760310805,
                                40.71147200938461
                            ],
                            [
                                -73.93803658862352,
                                40.70857533233678
                            ],
                            [
                                -73.93445246387313,
                                40.70837557151161
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "ad54114e-32b6-4e4b-b6e8-2a3b6e5e9bac"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97234539539926,
                                40.605363874958854
                            ],
                            [
                                -73.97077106103289,
                                40.60434780762581
                            ],
                            [
                                -73.97062448382631,
                                40.60262289170001
                            ],
                            [
                                -73.97268349971263,
                                40.60094491534341
                            ],
                            [
                                -73.97234539539926,
                                40.605363874958854
                            ]
                        ],
                        [
                            [
                                -73.9726354093022,
                                40.601289500427626
                            ],
                            [
                                -73.97085610633748,
                                40.60278710789568
                            ],
                            [
                                -73.97228403687208,
                                40.60502780536016
                            ],
                            [
                                -73.9726354093022,
                                40.601289500427626
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ed595509-0638-4043-b9e3-dde221b9be2e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97232839208755,
                                40.68505686461042
                            ],
                            [
                                -73.97467148580942,
                                40.687965704649834
                            ],
                            [
                                -73.97204479574899,
                                40.68606780079024
                            ],
                            [
                                -73.97232839208755,
                                40.68505686461042
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "56098fc7-075e-4acc-9dfb-caf522a18dff"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97231401095816,
                                40.60359004632587
                            ],
                            [
                                -73.97236994455933,
                                40.603217663926486
                            ],
                            [
                                -73.97242644948902,
                                40.602809162226954
                            ],
                            [
                                -73.97235208964476,
                                40.603348007653
                            ],
                            [
                                -73.97231401095816,
                                40.60359004632587
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6178f82d-c94c-4146-8e86-2c21bf4d6e4d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97085610633748,
                                40.60278710789568
                            ],
                            [
                                -73.9726354093022,
                                40.601289500427626
                            ],
                            [
                                -73.97228403687208,
                                40.60502780536016
                            ],
                            [
                                -73.97085610633748,
                                40.60278710789568
                            ]
                        ],
                        [
                            [
                                -73.97235208964476,
                                40.603348007653
                            ],
                            [
                                -73.97242644948902,
                                40.602809162226954
                            ],
                            [
                                -73.97236994455933,
                                40.603217663926486
                            ],
                            [
                                -73.97231401095816,
                                40.60359004632587
                            ],
                            [
                                -73.97235208964476,
                                40.603348007653
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "724d5d70-5879-435e-9e53-fa96df2d6498"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97191321501775,
                                40.781782876447146
                            ],
                            [
                                -73.97236449627714,
                                40.78088820118948
                            ],
                            [
                                -73.97302687354346,
                                40.78026399193574
                            ],
                            [
                                -73.97294767896632,
                                40.78077353300057
                            ],
                            [
                                -73.97191321501775,
                                40.781782876447146
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9734811c-42aa-4b70-8a21-192e744436f8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.97023603343284,
                                40.79388426189567
                            ],
                            [
                                -73.97370773993497,
                                40.792193952191646
                            ],
                            [
                                -73.97521558100398,
                                40.79515150467569
                            ],
                            [
                                -73.97107972216453,
                                40.795317299389026
                            ],
                            [
                                -73.97023603343284,
                                40.79388426189567
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "85214a68-bc89-433f-a2b7-0a6efc5e1087"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96795005039218,
                                40.76254042463455
                            ],
                            [
                                -73.96753809887716,
                                40.763136993012054
                            ],
                            [
                                -73.96752408247215,
                                40.763138669491745
                            ],
                            [
                                -73.96756446084585,
                                40.762048019058014
                            ],
                            [
                                -73.96795005039218,
                                40.76254042463455
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "dbbbbbee-e22e-4321-9426-ddf7c70108b2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96753809887716,
                                40.763136993012054
                            ],
                            [
                                -73.96795005039218,
                                40.76254042463455
                            ],
                            [
                                -73.9683419194866,
                                40.76304084931984
                            ],
                            [
                                -73.96753809887716,
                                40.763136993012054
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "dd641f5a-03c1-4079-8255-37622866b538"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9695113076959,
                                40.67251029385424
                            ],
                            [
                                -73.96905729757135,
                                40.672234623598776
                            ],
                            [
                                -73.96886968241095,
                                40.67197560516842
                            ],
                            [
                                -73.96886112775451,
                                40.67209886652696
                            ],
                            [
                                -73.96890489195937,
                                40.672142084486275
                            ],
                            [
                                -73.96884507845365,
                                40.672105766343776
                            ],
                            [
                                -73.96865980451956,
                                40.671686722623996
                            ],
                            [
                                -73.9695113076959,
                                40.67251029385424
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "39f59710-7ee0-4195-bae3-102f51a83271"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96905729757135,
                                40.672234623598776
                            ],
                            [
                                -73.96890489195937,
                                40.672142084486275
                            ],
                            [
                                -73.96886112775451,
                                40.67209886652696
                            ],
                            [
                                -73.96886968241095,
                                40.67197560516842
                            ],
                            [
                                -73.96905729757135,
                                40.672234623598776
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "cf647c1a-978b-40f6-8eec-533a44c307b8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96890489195937,
                                40.672142084486275
                            ],
                            [
                                -73.96905729757135,
                                40.672234623598776
                            ],
                            [
                                -73.96920476479173,
                                40.67243821443795
                            ],
                            [
                                -73.96890489195937,
                                40.672142084486275
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "8c3a42bc-080a-4011-a695-a443efd8a826"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96924700806795,
                                40.797949080549515
                            ],
                            [
                                -73.96897052296069,
                                40.79850907020504
                            ],
                            [
                                -73.96845236536906,
                                40.798245376103694
                            ],
                            [
                                -73.96924700806795,
                                40.797949080549515
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8da5624d-4777-4b63-9185-e3eaab062a43"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96897052296069,
                                40.79850907020504
                            ],
                            [
                                -73.96796887755863,
                                40.80053779091706
                            ],
                            [
                                -73.96784045384386,
                                40.800714176722906
                            ],
                            [
                                -73.96703106997623,
                                40.79887207710546
                            ],
                            [
                                -73.96701710562849,
                                40.79878053622136
                            ],
                            [
                                -73.96845236536906,
                                40.798245376103694
                            ],
                            [
                                -73.96897052296069,
                                40.79850907020504
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "732feed5-2a21-4abe-ae45-f852ae397bb9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96796887755863,
                                40.80053779091706
                            ],
                            [
                                -73.96897052296069,
                                40.79850907020504
                            ],
                            [
                                -73.96931742161318,
                                40.79868560940837
                            ],
                            [
                                -73.96796887755863,
                                40.80053779091706
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "fcfe7c84-e5cb-4c71-b4a3-7d131c792abd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96796887755863,
                                40.80053779091706
                            ],
                            [
                                -73.96782597569643,
                                40.80082722265313
                            ],
                            [
                                -73.96648159656807,
                                40.798980209551324
                            ],
                            [
                                -73.96701710562849,
                                40.79878053622136
                            ],
                            [
                                -73.96703106997623,
                                40.79887207710546
                            ],
                            [
                                -73.96784045384386,
                                40.800714176722906
                            ],
                            [
                                -73.96796887755863,
                                40.80053779091706
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "7afbffcb-757b-4701-a2d0-f85aba2ced4d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96845236536906,
                                40.798245376103694
                            ],
                            [
                                -73.96701710562849,
                                40.79878053622136
                            ],
                            [
                                -73.96697757979264,
                                40.798521431390775
                            ],
                            [
                                -73.96841617871955,
                                40.798226960459154
                            ],
                            [
                                -73.96845236536906,
                                40.798245376103694
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3a20e219-d080-4e18-be60-34246756c254"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96915804880369,
                                40.74839796863181
                            ],
                            [
                                -73.96965495639468,
                                40.74858205949539
                            ],
                            [
                                -73.96969117826357,
                                40.74862483320381
                            ],
                            [
                                -73.96915804880369,
                                40.74839796863181
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ee55d466-cbf5-407b-9907-731829f1aefb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96884228559142,
                                40.785990397146236
                            ],
                            [
                                -73.96892764620466,
                                40.78587280353445
                            ],
                            [
                                -73.96907944082643,
                                40.78566430612765
                            ],
                            [
                                -73.96884228559142,
                                40.785990397146236
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "625070e9-382e-4e30-8f51-73ba9fbbea1f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96813138582503,
                                40.757174918694375
                            ],
                            [
                                -73.96978615983349,
                                40.757866721193494
                            ],
                            [
                                -73.96924072345422,
                                40.75792410093769
                            ],
                            [
                                -73.96813138582503,
                                40.757174918694375
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "4b313e48-f462-4e43-99dc-a52f7f6d74dd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96783651203638,
                                40.63594341402719
                            ],
                            [
                                -73.96782476836067,
                                40.63590034159751
                            ],
                            [
                                -73.96807391251147,
                                40.636296014646184
                            ],
                            [
                                -73.96803762955714,
                                40.636642436601
                            ],
                            [
                                -73.96783651203638,
                                40.63594341402719
                            ]
                        ],
                        [
                            [
                                -73.9679446215859,
                                40.63631729646488
                            ],
                            [
                                -73.96796274599505,
                                40.63638081194052
                            ],
                            [
                                -73.96807391251147,
                                40.636296014646184
                            ],
                            [
                                -73.9679446215859,
                                40.63631729646488
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "93818c54-e6e1-42d0-b6c9-c782eb4b78a9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96796274599505,
                                40.63638081194052
                            ],
                            [
                                -73.9679446215859,
                                40.63631729646488
                            ],
                            [
                                -73.96807391251147,
                                40.636296014646184
                            ],
                            [
                                -73.96796274599505,
                                40.63638081194052
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "356e909c-b90a-4e75-be0b-a605b1770720"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96700451308149,
                                40.68350408177022
                            ],
                            [
                                -73.96599087926771,
                                40.68429788759031
                            ],
                            [
                                -73.96461781693236,
                                40.683006284713734
                            ],
                            [
                                -73.96700451308149,
                                40.68350408177022
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "01de8694-162e-46a5-9057-c4821bcef13c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9655056659451,
                                40.80366748801596
                            ],
                            [
                                -73.96579940516155,
                                40.803084721282765
                            ],
                            [
                                -73.968161564727,
                                40.804080160046404
                            ],
                            [
                                -73.96625628202683,
                                40.804617704615104
                            ],
                            [
                                -73.9655056659451,
                                40.80366748801596
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d2fef9ac-ddb2-4d11-854a-7937ab6e452d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9655965961131,
                                40.802998422510214
                            ],
                            [
                                -73.96836937379831,
                                40.80416655260925
                            ],
                            [
                                -73.96619732469642,
                                40.80470087273073
                            ],
                            [
                                -73.96529314880338,
                                40.803577688765834
                            ],
                            [
                                -73.9655965961131,
                                40.802998422510214
                            ]
                        ],
                        [
                            [
                                -73.96625628202683,
                                40.804617704615104
                            ],
                            [
                                -73.968161564727,
                                40.804080160046404
                            ],
                            [
                                -73.96579940516155,
                                40.803084721282765
                            ],
                            [
                                -73.9655056659451,
                                40.80366748801596
                            ],
                            [
                                -73.96625628202683,
                                40.804617704615104
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c8cdd1af-d876-4c90-9dbe-f72bde815c2d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96350889971471,
                                40.80839308430139
                            ],
                            [
                                -73.96315274914559,
                                40.80758976841549
                            ],
                            [
                                -73.96453075850323,
                                40.80698444007338
                            ],
                            [
                                -73.96350889971471,
                                40.80839308430139
                            ]
                        ],
                        [
                            [
                                -73.9633505725442,
                                40.80767218011117
                            ],
                            [
                                -73.96361269196096,
                                40.80824865523341
                            ],
                            [
                                -73.96443098172631,
                                40.80712048474376
                            ],
                            [
                                -73.9633505725442,
                                40.80767218011117
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4273fdc9-4dbc-4d2e-9122-5657cd80870a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96443098172631,
                                40.80712048474376
                            ],
                            [
                                -73.96361269196096,
                                40.80824865523341
                            ],
                            [
                                -73.9633505725442,
                                40.80767218011117
                            ],
                            [
                                -73.96443098172631,
                                40.80712048474376
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e79c020a-e89c-4e55-8be4-0889b6bb7b9d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96170684152553,
                                40.69856814828185
                            ],
                            [
                                -73.96168688904476,
                                40.69861981879297
                            ],
                            [
                                -73.96171833187631,
                                40.698538121095666
                            ],
                            [
                                -73.96170684152553,
                                40.69856814828185
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "9e6eb71b-44f6-4267-97c0-8cdded12c83a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96169893904597,
                                40.697282750713036
                            ],
                            [
                                -73.96104652858773,
                                40.696357211101784
                            ],
                            [
                                -73.96193527411911,
                                40.69625664057635
                            ],
                            [
                                -73.96169893904597,
                                40.697282750713036
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "d8ff893b-7f32-4d95-bdf9-0b7328acb502"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96151551653968,
                                40.65517882989917
                            ],
                            [
                                -73.96152909367689,
                                40.655180192902556
                            ],
                            [
                                -73.96149501249332,
                                40.65529193250747
                            ],
                            [
                                -73.96151551653968,
                                40.65517882989917
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "0c807f2e-a122-482c-94c9-d1ed01ae1f3c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96120377867821,
                                40.77180619294697
                            ],
                            [
                                -73.96139992941376,
                                40.77430828213568
                            ],
                            [
                                -73.95986839739113,
                                40.77363393928083
                            ],
                            [
                                -73.96061371938553,
                                40.77261545229072
                            ],
                            [
                                -73.958559093566,
                                40.77302529273926
                            ],
                            [
                                -73.95858258658853,
                                40.77306778897211
                            ],
                            [
                                -73.95730859377474,
                                40.77250684219566
                            ],
                            [
                                -73.96120377867821,
                                40.77180619294697
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "cf3bb5c0-8092-4390-adfd-56025c5e1c67"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95986839739113,
                                40.77363393928083
                            ],
                            [
                                -73.95858258658853,
                                40.77306778897211
                            ],
                            [
                                -73.958559093566,
                                40.77302529273926
                            ],
                            [
                                -73.96061371938553,
                                40.77261545229072
                            ],
                            [
                                -73.95986839739113,
                                40.77363393928083
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "cfed3f33-fea5-48e3-944d-6173a039809e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95858258658853,
                                40.77306778897211
                            ],
                            [
                                -73.95986839739113,
                                40.77363393928083
                            ],
                            [
                                -73.95931421829215,
                                40.77439122835418
                            ],
                            [
                                -73.95858258658853,
                                40.77306778897211
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a8dc2a4f-94cc-46bc-92e7-7cfa4920e6fd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96073877744949,
                                40.801641420212626
                            ],
                            [
                                -73.95729299258215,
                                40.80031906726311
                            ],
                            [
                                -73.96067840437418,
                                40.80161452947223
                            ],
                            [
                                -73.95887626264168,
                                40.79938508838049
                            ],
                            [
                                -73.95545083731552,
                                40.79961212253381
                            ],
                            [
                                -73.95539145331281,
                                40.799589333353936
                            ],
                            [
                                -73.95890012459057,
                                40.79935269530892
                            ],
                            [
                                -73.96073877744949,
                                40.801641420212626
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "89e52ad6-6aa9-4a88-b362-0322aaa2809e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95729299258215,
                                40.80031906726311
                            ],
                            [
                                -73.95545083731552,
                                40.79961212253381
                            ],
                            [
                                -73.95887626264168,
                                40.79938508838049
                            ],
                            [
                                -73.96067840437418,
                                40.80161452947223
                            ],
                            [
                                -73.95729299258215,
                                40.80031906726311
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "842fadf5-bc0c-43e3-a3ca-4f3d341a431e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95545083731552,
                                40.79961212253381
                            ],
                            [
                                -73.95729299258215,
                                40.80031906726311
                            ],
                            [
                                -73.95544632600364,
                                40.79961242153956
                            ],
                            [
                                -73.95545083731552,
                                40.79961212253381
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d00fd299-f455-498c-8d45-15d0106e6a02"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95895044012795,
                                40.61728235020437
                            ],
                            [
                                -73.9590688913388,
                                40.617910840296716
                            ],
                            [
                                -73.95901826716786,
                                40.617904644619315
                            ],
                            [
                                -73.9588018030844,
                                40.61746821369998
                            ],
                            [
                                -73.95895044012795,
                                40.61728235020437
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ca6720d4-d4ac-4930-8331-ea5f7d4842dd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9590688913388,
                                40.617910840296716
                            ],
                            [
                                -73.95895044012795,
                                40.61728235020437
                            ],
                            [
                                -73.9588018030844,
                                40.61746821369998
                            ],
                            [
                                -73.95901826716786,
                                40.617904644619315
                            ],
                            [
                                -73.95778523896618,
                                40.61775373953148
                            ],
                            [
                                -73.95879088945202,
                                40.61643740367437
                            ],
                            [
                                -73.95981925075162,
                                40.61783863240441
                            ],
                            [
                                -73.95949508305343,
                                40.617963000091635
                            ],
                            [
                                -73.9590688913388,
                                40.617910840296716
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a1dba001-d9f2-4173-ad22-272f5e1f1c73"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9590688913388,
                                40.617910840296716
                            ],
                            [
                                -73.95909803422745,
                                40.61806546950348
                            ],
                            [
                                -73.95901826716786,
                                40.617904644619315
                            ],
                            [
                                -73.9590688913388,
                                40.617910840296716
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "0f2ca6fc-0be2-46c0-b445-4acad9c69300"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95885560947498,
                                40.625380718345255
                            ],
                            [
                                -73.9610002887068,
                                40.62355006872266
                            ],
                            [
                                -73.96287521374025,
                                40.62494142043855
                            ],
                            [
                                -73.96158964551694,
                                40.626636030007326
                            ],
                            [
                                -73.95885560947498,
                                40.625380718345255
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ebf33495-f1f9-4af1-9e22-b1067f556a14"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95981925075162,
                                40.61783863240441
                            ],
                            [
                                -73.95995152088395,
                                40.618018861580296
                            ],
                            [
                                -73.95949508305343,
                                40.617963000091635
                            ],
                            [
                                -73.95981925075162,
                                40.61783863240441
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "7d67400b-f683-4b37-b1c3-c785e53415ae"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95949508305343,
                                40.617963000091635
                            ],
                            [
                                -73.95995152088395,
                                40.618018861580296
                            ],
                            [
                                -73.95981925075162,
                                40.61783863240441
                            ],
                            [
                                -73.95999261431662,
                                40.61777212107169
                            ],
                            [
                                -73.96007656906643,
                                40.61822150952786
                            ],
                            [
                                -73.958170453628,
                                40.61835694011978
                            ],
                            [
                                -73.95745427176774,
                                40.61784723869664
                            ],
                            [
                                -73.95910685388691,
                                40.618111945109376
                            ],
                            [
                                -73.95949508305343,
                                40.617963000091635
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5c6569d6-790c-4b2b-a7a2-6666a6588236"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95872209556924,
                                40.768350359989846
                            ],
                            [
                                -73.95838419195911,
                                40.768810936851125
                            ],
                            [
                                -73.95803295341683,
                                40.76866285685534
                            ],
                            [
                                -73.95800283248757,
                                40.76853362818096
                            ],
                            [
                                -73.95872209556924,
                                40.768350359989846
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "72bab52f-a0be-4051-80f6-e3c6b361f122"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95838419195911,
                                40.768810936851125
                            ],
                            [
                                -73.95814383115624,
                                40.769138558755245
                            ],
                            [
                                -73.95803295341683,
                                40.76866285685534
                            ],
                            [
                                -73.95838419195911,
                                40.768810936851125
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "d4d7cc35-6fe5-4442-955d-35fc880198be"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95803295341683,
                                40.76866285685534
                            ],
                            [
                                -73.95814383115624,
                                40.769138558755245
                            ],
                            [
                                -73.95838419195911,
                                40.768810936851125
                            ],
                            [
                                -73.96047865049604,
                                40.7696939476087
                            ],
                            [
                                -73.95732590071701,
                                40.7702206649627
                            ],
                            [
                                -73.95619203818224,
                                40.76788673838497
                            ],
                            [
                                -73.95803295341683,
                                40.76866285685534
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "47538b71-e032-4cc8-8f1c-cb3903288818"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.958654602447,
                                40.69437486907678
                            ],
                            [
                                -73.95814393446248,
                                40.694669955504985
                            ],
                            [
                                -73.95790684749944,
                                40.69446445221008
                            ],
                            [
                                -73.95806875984626,
                                40.6942931675359
                            ],
                            [
                                -73.958654602447,
                                40.69437486907678
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "5d2909c3-7aa7-44e3-af48-46cee95e8dfa"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9581981073657,
                                40.669783668207224
                            ],
                            [
                                -73.95824153102824,
                                40.66985395581247
                            ],
                            [
                                -73.95820365985247,
                                40.66984594924724
                            ],
                            [
                                -73.9581981073657,
                                40.669783668207224
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "5a7e3cd0-7785-474d-a080-24a060f08ec8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95801292685641,
                                40.612330041691344
                            ],
                            [
                                -73.95822784426228,
                                40.6116112175628
                            ],
                            [
                                -73.95850447499946,
                                40.61170759565426
                            ],
                            [
                                -73.95801292685641,
                                40.612330041691344
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3b954bee-888c-4af0-9e3f-fa20558e08b6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95822784426228,
                                40.6116112175628
                            ],
                            [
                                -73.95852387757613,
                                40.61062108887605
                            ],
                            [
                                -73.95863940744397,
                                40.61064780676374
                            ],
                            [
                                -73.96048610819997,
                                40.607804584207514
                            ],
                            [
                                -73.96178429516763,
                                40.60727589346083
                            ],
                            [
                                -73.96083950702771,
                                40.60875075113555
                            ],
                            [
                                -73.95850447499946,
                                40.61170759565426
                            ],
                            [
                                -73.95822784426228,
                                40.6116112175628
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b2d218cf-bfb8-48e4-acdc-a14068ff65a9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95852387757613,
                                40.61062108887605
                            ],
                            [
                                -73.95822784426228,
                                40.6116112175628
                            ],
                            [
                                -73.95440737586853,
                                40.61028016716689
                            ],
                            [
                                -73.9557297019149,
                                40.609741645704084
                            ],
                            [
                                -73.95560899203697,
                                40.60994698108113
                            ],
                            [
                                -73.95852387757613,
                                40.61062108887605
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "db0af8cd-272a-4fd3-9e74-16d9c14df1ec"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95852387757613,
                                40.61062108887605
                            ],
                            [
                                -73.95921066868772,
                                40.60832401098107
                            ],
                            [
                                -73.96048610819997,
                                40.607804584207514
                            ],
                            [
                                -73.95863940744397,
                                40.61064780676374
                            ],
                            [
                                -73.95852387757613,
                                40.61062108887605
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b52bc8f6-7cba-4aad-9612-e10922bd1dd7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95921066868772,
                                40.60832401098107
                            ],
                            [
                                -73.95852387757613,
                                40.61062108887605
                            ],
                            [
                                -73.95560899203697,
                                40.60994698108113
                            ],
                            [
                                -73.9557297019149,
                                40.609741645704084
                            ],
                            [
                                -73.95921066868772,
                                40.60832401098107
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8739007e-27b1-4658-ad16-acbca96163ac"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95921066868772,
                                40.60832401098107
                            ],
                            [
                                -73.9592282405363,
                                40.60826523924644
                            ],
                            [
                                -73.95938290779144,
                                40.607158828421525
                            ],
                            [
                                -73.96052315393352,
                                40.60774754775216
                            ],
                            [
                                -73.96048610819997,
                                40.607804584207514
                            ],
                            [
                                -73.95921066868772,
                                40.60832401098107
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e04d476a-150e-4258-8e78-996d8ab41b5c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95938290779144,
                                40.607158828421525
                            ],
                            [
                                -73.9592282405363,
                                40.60826523924644
                            ],
                            [
                                -73.95921066868772,
                                40.60832401098107
                            ],
                            [
                                -73.9557297019149,
                                40.609741645704084
                            ],
                            [
                                -73.95774514895167,
                                40.60631323881388
                            ],
                            [
                                -73.95938290779144,
                                40.607158828421525
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5aacb973-2285-4df3-a65b-677bb0c9dea4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95938290779144,
                                40.607158828421525
                            ],
                            [
                                -73.95955848369262,
                                40.60590284778437
                            ],
                            [
                                -73.96204647836478,
                                40.60722236671125
                            ],
                            [
                                -73.96083950702771,
                                40.60875075113555
                            ],
                            [
                                -73.96178429516763,
                                40.60727589346083
                            ],
                            [
                                -73.96048610819997,
                                40.607804584207514
                            ],
                            [
                                -73.96052315393352,
                                40.60774754775216
                            ],
                            [
                                -73.95938290779144,
                                40.607158828421525
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e72c4cbf-04bf-4f54-b78e-8adaa6f30f07"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95850447499946,
                                40.61170759565426
                            ],
                            [
                                -73.96083950702771,
                                40.60875075113555
                            ],
                            [
                                -73.95886491871627,
                                40.61183317417043
                            ],
                            [
                                -73.95850447499946,
                                40.61170759565426
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "2eb59ac7-31d5-4c4f-952f-43b53f47e139"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96085371329683,
                                40.622768067150645
                            ],
                            [
                                -73.96374951885346,
                                40.62484368010846
                            ],
                            [
                                -73.96175697200995,
                                40.627515840804044
                            ],
                            [
                                -73.95792081630212,
                                40.6254852486668
                            ],
                            [
                                -73.96085371329683,
                                40.622768067150645
                            ]
                        ],
                        [
                            [
                                -73.96158964551694,
                                40.626636030007326
                            ],
                            [
                                -73.96287521374025,
                                40.62494142043855
                            ],
                            [
                                -73.9610002887068,
                                40.62355006872266
                            ],
                            [
                                -73.95885560947498,
                                40.625380718345255
                            ],
                            [
                                -73.96158964551694,
                                40.626636030007326
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "79c306c4-17b4-4327-97aa-bf8861068f56"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95758647952304,
                                40.77676233863719
                            ],
                            [
                                -73.95565623696915,
                                40.77950325589768
                            ],
                            [
                                -73.95701800315337,
                                40.77754543981916
                            ],
                            [
                                -73.95235946585407,
                                40.778266840576286
                            ],
                            [
                                -73.95422593592873,
                                40.781534263245135
                            ],
                            [
                                -73.9537487769163,
                                40.78221182232731
                            ],
                            [
                                -73.95126097807687,
                                40.777680533293264
                            ],
                            [
                                -73.95758647952304,
                                40.77676233863719
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "2183cbc1-5ff6-4994-bc65-83d1c67fa752"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95565623696915,
                                40.77950325589768
                            ],
                            [
                                -73.95422593592873,
                                40.781534263245135
                            ],
                            [
                                -73.95235946585407,
                                40.778266840576286
                            ],
                            [
                                -73.95701800315337,
                                40.77754543981916
                            ],
                            [
                                -73.95565623696915,
                                40.77950325589768
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "97118912-6da2-4b64-82e6-e38e1496e710"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95422593592873,
                                40.781534263245135
                            ],
                            [
                                -73.95565623696915,
                                40.77950325589768
                            ],
                            [
                                -73.9542338842377,
                                40.78154817747029
                            ],
                            [
                                -73.95422593592873,
                                40.781534263245135
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e78fec76-3b0e-4829-abce-a68889b37b1d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.96079476494957,
                                40.622448395279946
                            ],
                            [
                                -73.96411346140935,
                                40.62480416230392
                            ],
                            [
                                -73.96186958301735,
                                40.62771513557094
                            ],
                            [
                                -73.9575771029081,
                                40.625522198392495
                            ],
                            [
                                -73.96079476494957,
                                40.622448395279946
                            ]
                        ],
                        [
                            [
                                -73.96175697200995,
                                40.627515840804044
                            ],
                            [
                                -73.96374951885346,
                                40.62484368010846
                            ],
                            [
                                -73.96085371329683,
                                40.622768067150645
                            ],
                            [
                                -73.95792081630212,
                                40.6254852486668
                            ],
                            [
                                -73.96175697200995,
                                40.627515840804044
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d8e6ed5c-61fa-4155-aafc-b5928eba1be0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95728854517422,
                                40.70926825796074
                            ],
                            [
                                -73.95818900769947,
                                40.70774024274163
                            ],
                            [
                                -73.9583597080912,
                                40.707808932808135
                            ],
                            [
                                -73.95904862688681,
                                40.70881228348666
                            ],
                            [
                                -73.95728854517422,
                                40.70926825796074
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "f394dc56-302b-451c-8e39-f8f8c932a057"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95818900769947,
                                40.70774024274163
                            ],
                            [
                                -73.95824606482546,
                                40.707643421214456
                            ],
                            [
                                -73.9583597080912,
                                40.707808932808135
                            ],
                            [
                                -73.95818900769947,
                                40.70774024274163
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "c7d58acc-e44e-435a-8619-5901f2071398"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95698083446018,
                                40.6993255529985
                            ],
                            [
                                -73.95705444870276,
                                40.69972175570604
                            ],
                            [
                                -73.95644006752376,
                                40.699797270099864
                            ],
                            [
                                -73.95698083446018,
                                40.6993255529985
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "5308401b-5ce4-42fa-85b9-9465feda4158"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95684919533845,
                                40.681376915457335
                            ],
                            [
                                -73.95674434218185,
                                40.68135477943517
                            ],
                            [
                                -73.95698200137173,
                                40.68140483855303
                            ],
                            [
                                -73.95684919533845,
                                40.681376915457335
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "472ba3c8-e9a7-417a-bacb-3bff9262f889"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95642172841724,
                                40.74319353703076
                            ],
                            [
                                -73.95529895252736,
                                40.743213186723
                            ],
                            [
                                -73.95388711588188,
                                40.74114190535676
                            ],
                            [
                                -73.9540144788144,
                                40.74110034370384
                            ],
                            [
                                -73.95642172841724,
                                40.74319353703076
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9d3caddc-ecb2-445e-bf0f-4dd2cb8eea69"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95529895252736,
                                40.743213186723
                            ],
                            [
                                -73.95138797470523,
                                40.74328163271585
                            ],
                            [
                                -73.95092151191753,
                                40.74210965476561
                            ],
                            [
                                -73.95388711588188,
                                40.74114190535676
                            ],
                            [
                                -73.95529895252736,
                                40.743213186723
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "4adc3139-e850-4127-ac9a-91a6c582a0aa"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95587957807527,
                                40.82294744650862
                            ],
                            [
                                -73.95205267591318,
                                40.82134261221905
                            ],
                            [
                                -73.9546163644278,
                                40.82060808531497
                            ],
                            [
                                -73.95587957807527,
                                40.82294744650862
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d3961ac8-45ab-4a4e-b48f-a635003a5d92"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95515548565062,
                                40.680936157628096
                            ],
                            [
                                -73.95537004376466,
                                40.67963908183098
                            ],
                            [
                                -73.95619578864856,
                                40.68115531851593
                            ],
                            [
                                -73.95515548565062,
                                40.680936157628096
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "56e6835c-0096-426c-b664-49be5071a21f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95366502698235,
                                40.72882456510747
                            ],
                            [
                                -73.95495263887295,
                                40.73440958250411
                            ],
                            [
                                -73.9502034930025,
                                40.731967479843966
                            ],
                            [
                                -73.95366502698235,
                                40.72882456510747
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "ff1ba35a-7c96-44fa-97f8-cf4ebad490ec"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.953640474229,
                                40.68961892336848
                            ],
                            [
                                -73.95352302130225,
                                40.68963221736754
                            ],
                            [
                                -73.95338731640553,
                                40.689647428799795
                            ],
                            [
                                -73.95352201339249,
                                40.68962706719483
                            ],
                            [
                                -73.953640474229,
                                40.68961892336848
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "09530eb7-ef38-4204-876e-f772c6bceffb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95285627912196,
                                40.8042495329367
                            ],
                            [
                                -73.9564358382554,
                                40.805404918194405
                            ],
                            [
                                -73.95651799464476,
                                40.80586451683926
                            ],
                            [
                                -73.95491864406615,
                                40.80515982342537
                            ],
                            [
                                -73.95285627912196,
                                40.8042495329367
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f6473bfe-060c-4bbe-b71f-1593304bdcbe"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95651799464476,
                                40.80586451683926
                            ],
                            [
                                -73.9564358382554,
                                40.805404918194405
                            ],
                            [
                                -73.95285627912196,
                                40.8042495329367
                            ],
                            [
                                -73.95491864406615,
                                40.80515982342537
                            ],
                            [
                                -73.95279502839263,
                                40.804224132402524
                            ],
                            [
                                -73.95646146808878,
                                40.80536902759984
                            ],
                            [
                                -73.95658648932181,
                                40.80589469643118
                            ],
                            [
                                -73.95651799464476,
                                40.80586451683926
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a541d7f6-ea66-40bb-bab5-2f6c242b639a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95651799464476,
                                40.80586451683926
                            ],
                            [
                                -73.95651823352989,
                                40.80586585320865
                            ],
                            [
                                -73.95491864406615,
                                40.80515982342537
                            ],
                            [
                                -73.95651799464476,
                                40.80586451683926
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "29aa70bd-9677-4d7d-8d28-16b1e3084fe0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9525319118459,
                                40.66309995004716
                            ],
                            [
                                -73.94959760180126,
                                40.66328618822052
                            ],
                            [
                                -73.95092190717625,
                                40.662267267729945
                            ],
                            [
                                -73.9525319118459,
                                40.66309995004716
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "72e541e9-1378-46bd-bd76-63a0c0640491"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95221294524133,
                                40.71762855366646
                            ],
                            [
                                -73.9522439290338,
                                40.717820458823105
                            ],
                            [
                                -73.95214665496326,
                                40.71783104621754
                            ],
                            [
                                -73.95221294524133,
                                40.71762855366646
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "6ee51a26-1394-4a34-9ab4-80b8815ced52"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9511193101633,
                                40.71096076761372
                            ],
                            [
                                -73.94991197716057,
                                40.71568802461129
                            ],
                            [
                                -73.94947800725701,
                                40.715921661571294
                            ],
                            [
                                -73.94689695164685,
                                40.714293766682964
                            ],
                            [
                                -73.9511193101633,
                                40.71096076761372
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "2d8a2a15-2db3-47df-b68f-a9a25bbc1616"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94991197716057,
                                40.71568802461129
                            ],
                            [
                                -73.94980037851033,
                                40.7161249840078
                            ],
                            [
                                -73.94947800725701,
                                40.715921661571294
                            ],
                            [
                                -73.94991197716057,
                                40.71568802461129
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "60707b9e-5f45-44e2-8033-60ca5ac07368"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95083897830249,
                                40.80264345411715
                            ],
                            [
                                -73.94795060450821,
                                40.801516073025255
                            ],
                            [
                                -73.94786741602131,
                                40.801395541403345
                            ],
                            [
                                -73.9500641562171,
                                40.801379059417535
                            ],
                            [
                                -73.95014687646523,
                                40.801514046591045
                            ],
                            [
                                -73.9479846524541,
                                40.801444133249404
                            ],
                            [
                                -73.94799293851428,
                                40.80145610338397
                            ],
                            [
                                -73.9507352130057,
                                40.80259971694357
                            ],
                            [
                                -73.95022494366681,
                                40.80164144068288
                            ],
                            [
                                -73.95083897830249,
                                40.80264345411715
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9b80ce5f-d016-4098-a96d-87a4f6a9d10d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95014687646523,
                                40.801514046591045
                            ],
                            [
                                -73.95022494366681,
                                40.80164144068288
                            ],
                            [
                                -73.9507352130057,
                                40.80259971694357
                            ],
                            [
                                -73.94799293851428,
                                40.80145610338397
                            ],
                            [
                                -73.9479846524541,
                                40.801444133249404
                            ],
                            [
                                -73.95014687646523,
                                40.801514046591045
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ac44a5c7-cbf8-4307-ada1-4f7300ceca92"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95022494366681,
                                40.80164144068288
                            ],
                            [
                                -73.95014687646523,
                                40.801514046591045
                            ],
                            [
                                -73.95015728725755,
                                40.80151438321356
                            ],
                            [
                                -73.95022494366681,
                                40.80164144068288
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "82079fb2-3a5e-4851-9804-9ab7a7498a0a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95080639011893,
                                40.825796170877055
                            ],
                            [
                                -73.950049297184,
                                40.82714237160002
                            ],
                            [
                                -73.9497540676625,
                                40.82722396575072
                            ],
                            [
                                -73.95080639011893,
                                40.825796170877055
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "83ef8cfb-0b7d-43f4-b414-e3a05d994d68"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95076069102379,
                                40.680454880623756
                            ],
                            [
                                -73.94997600633491,
                                40.6804469318747
                            ],
                            [
                                -73.94988967733778,
                                40.68040757438052
                            ],
                            [
                                -73.95076069102379,
                                40.680454880623756
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 1500
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "5ad3ffcd-1de3-4c7e-85a7-5472af9de6dc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95066800644604,
                                40.66807193205314
                            ],
                            [
                                -73.9507519935352,
                                40.66718106823158
                            ],
                            [
                                -73.95146525856575,
                                40.668053825324634
                            ],
                            [
                                -73.95066800644604,
                                40.66807193205314
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "636bc15e-5c2a-4bbf-ab50-afbc0df28f01"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95054893655175,
                                40.66934613694687
                            ],
                            [
                                -73.95046343939458,
                                40.67026298025147
                            ],
                            [
                                -73.9498875914497,
                                40.66969796150283
                            ],
                            [
                                -73.95054893655175,
                                40.66934613694687
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "6338cff8-acd2-45e9-b806-6ed37de3dfd4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95053154696448,
                                40.72169874499559
                            ],
                            [
                                -73.95058403732317,
                                40.72167121528099
                            ],
                            [
                                -73.95043655910635,
                                40.72175570592425
                            ],
                            [
                                -73.95053154696448,
                                40.72169874499559
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "b3527ba3-c6aa-4eae-8e26-4dbe2e466eb4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.95026978867047,
                                40.719528232117476
                            ],
                            [
                                -73.95021908955246,
                                40.71941199152565
                            ],
                            [
                                -73.9501956111842,
                                40.7190779673737
                            ],
                            [
                                -73.95026978867047,
                                40.719528232117476
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "644fc217-fa48-4747-b84e-fc773b441ddf"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94669876721981,
                                40.70324908896534
                            ],
                            [
                                -73.94634792548034,
                                40.70330598322752
                            ],
                            [
                                -73.94630379945463,
                                40.7032819980275
                            ],
                            [
                                -73.94629109458157,
                                40.703242440181754
                            ],
                            [
                                -73.94669876721981,
                                40.70324908896534
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "e0284764-26e8-43e7-bcda-3cb3f19f0d03"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94935956621727,
                                40.664117766879535
                            ],
                            [
                                -73.94834643007016,
                                40.66440724710025
                            ],
                            [
                                -73.94832989298409,
                                40.664412346071856
                            ],
                            [
                                -73.94810258957281,
                                40.66419219593172
                            ],
                            [
                                -73.94935956621727,
                                40.664117766879535
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "13bd9327-74cf-4ae9-ab4b-31c5b5630444"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94886719961004,
                                40.742374886381015
                            ],
                            [
                                -73.95042641173688,
                                40.7427241188273
                            ],
                            [
                                -73.9469882609039,
                                40.74201093859007
                            ],
                            [
                                -73.94886719961004,
                                40.742374886381015
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "952edfe0-7d9d-42b2-afc1-1269fb8e72c0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94856013478469,
                                40.63367843896516
                            ],
                            [
                                -73.94696292574136,
                                40.632167602423095
                            ],
                            [
                                -73.94696927993851,
                                40.632167233563734
                            ],
                            [
                                -73.94844803132543,
                                40.63220510850693
                            ],
                            [
                                -73.94856013478469,
                                40.63367843896516
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "160c9cba-a91c-4012-99ff-beef463fb232"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94696292574136,
                                40.632167602423095
                            ],
                            [
                                -73.94856013478469,
                                40.63367843896516
                            ],
                            [
                                -73.94844803132543,
                                40.63220510850693
                            ],
                            [
                                -73.94696927993851,
                                40.632167233563734
                            ],
                            [
                                -73.94870543907851,
                                40.6320664500134
                            ],
                            [
                                -73.94872255378131,
                                40.63382916464193
                            ],
                            [
                                -73.94781992927877,
                                40.633162364421885
                            ],
                            [
                                -73.94817344508915,
                                40.63388809860533
                            ],
                            [
                                -73.94789339080776,
                                40.63407123197368
                            ],
                            [
                                -73.94763973980919,
                                40.63402364468312
                            ],
                            [
                                -73.94679209130372,
                                40.632177519314205
                            ],
                            [
                                -73.94696292574136,
                                40.632167602423095
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ce7bf14f-e921-467e-81e0-7289ed77ee6a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94696292574136,
                                40.632167602423095
                            ],
                            [
                                -73.94696234810121,
                                40.632167056020066
                            ],
                            [
                                -73.94696927993851,
                                40.632167233563734
                            ],
                            [
                                -73.94696292574136,
                                40.632167602423095
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "0323c97c-d449-4b05-8bcb-086321fb081e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9483824318518,
                                40.81631787213867
                            ],
                            [
                                -73.94643848925506,
                                40.81929674514709
                            ],
                            [
                                -73.94493404291951,
                                40.81678214758659
                            ],
                            [
                                -73.9483824318518,
                                40.81631787213867
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c761aab3-6a80-4b87-9d2d-a026eaf53786"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94830223834808,
                                40.82640638424846
                            ],
                            [
                                -73.94518435375271,
                                40.82471177210407
                            ],
                            [
                                -73.9452485035746,
                                40.82305548398431
                            ],
                            [
                                -73.94442480924558,
                                40.82429894946531
                            ],
                            [
                                -73.94112963547045,
                                40.822507978271084
                            ],
                            [
                                -73.94608615293265,
                                40.820934249422606
                            ],
                            [
                                -73.94830223834808,
                                40.82640638424846
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a3c25c79-66f3-4bfe-81d8-51e268cc6d44"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94518435375271,
                                40.82471177210407
                            ],
                            [
                                -73.94500398225252,
                                40.824613737771685
                            ],
                            [
                                -73.94512625184758,
                                40.82331932053694
                            ],
                            [
                                -73.94445321857813,
                                40.82431439031903
                            ],
                            [
                                -73.94442480924558,
                                40.82429894946531
                            ],
                            [
                                -73.9452485035746,
                                40.82305548398431
                            ],
                            [
                                -73.94518435375271,
                                40.82471177210407
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6cb5f1e9-e22c-4101-9902-df24bd4ec60a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94500398225252,
                                40.824613737771685
                            ],
                            [
                                -73.94518435375271,
                                40.82471177210407
                            ],
                            [
                                -73.9451387303452,
                                40.82588972561362
                            ],
                            [
                                -73.94299876008212,
                                40.826451741886565
                            ],
                            [
                                -73.94442480924558,
                                40.82429894946531
                            ],
                            [
                                -73.94445321857813,
                                40.82431439031903
                            ],
                            [
                                -73.94313656074839,
                                40.82626104953961
                            ],
                            [
                                -73.94489320999827,
                                40.82578643745181
                            ],
                            [
                                -73.94500398225252,
                                40.824613737771685
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "377e879c-f5f6-4fbe-a588-ec557479c48a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94500398225252,
                                40.824613737771685
                            ],
                            [
                                -73.94445321857813,
                                40.82431439031903
                            ],
                            [
                                -73.94512625184758,
                                40.82331932053694
                            ],
                            [
                                -73.94500398225252,
                                40.824613737771685
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b8f8a7a6-c4f3-46c3-9fb4-f880f4637d0b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94445321857813,
                                40.82431439031903
                            ],
                            [
                                -73.94500398225252,
                                40.824613737771685
                            ],
                            [
                                -73.94489320999827,
                                40.82578643745181
                            ],
                            [
                                -73.94313656074839,
                                40.82626104953961
                            ],
                            [
                                -73.94445321857813,
                                40.82431439031903
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4d7de109-5dd3-4c59-8588-9d2dee98846b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94818027947184,
                                40.744829908368
                            ],
                            [
                                -73.95116078448166,
                                40.74305548700942
                            ],
                            [
                                -73.9516288130329,
                                40.744466155047455
                            ],
                            [
                                -73.94818027947184,
                                40.744829908368
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "c7a6c863-d742-4c28-8cc6-238374d9497c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94772285553253,
                                40.694727083269164
                            ],
                            [
                                -73.94885151322855,
                                40.693591441472655
                            ],
                            [
                                -73.9504763643549,
                                40.694409908317226
                            ],
                            [
                                -73.9492187867375,
                                40.69543369917892
                            ],
                            [
                                -73.94772285553253,
                                40.694727083269164
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "429f34bc-db56-4cd5-a457-54eea4808bf4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94713284385055,
                                40.69901566746373
                            ],
                            [
                                -73.9470714414479,
                                40.698932535825506
                            ],
                            [
                                -73.9472028493918,
                                40.69890750378936
                            ],
                            [
                                -73.94713284385055,
                                40.69901566746373
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "90d575b4-4e73-4159-b7b2-97635bd8bc41"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9470714414479,
                                40.698932535825506
                            ],
                            [
                                -73.94691688885626,
                                40.69872328977678
                            ],
                            [
                                -73.9470463136308,
                                40.698590667470754
                            ],
                            [
                                -73.94735478162465,
                                40.69867275739257
                            ],
                            [
                                -73.9472028493918,
                                40.69890750378936
                            ],
                            [
                                -73.9470714414479,
                                40.698932535825506
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "970d6b5b-c69b-428f-8396-b92acdbafc14"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94626811298164,
                                40.76573656840695
                            ],
                            [
                                -73.94610492502714,
                                40.76605496296084
                            ],
                            [
                                -73.94586833034087,
                                40.76618612550928
                            ],
                            [
                                -73.94581010873067,
                                40.766008162479984
                            ],
                            [
                                -73.94626811298164,
                                40.76573656840695
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "7395d06b-f79f-45a2-80d5-98a893a5d775"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94589641437159,
                                40.757595057787285
                            ],
                            [
                                -73.94865114669938,
                                40.75505005492697
                            ],
                            [
                                -73.94900717779255,
                                40.75657864088887
                            ],
                            [
                                -73.94589641437159,
                                40.757595057787285
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "fbccd06d-5b1c-40ef-ab10-78a9e62cfa6b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94576565649714,
                                40.80772279086665
                            ],
                            [
                                -73.94523572503968,
                                40.8079894883898
                            ],
                            [
                                -73.94518407819906,
                                40.807649173602414
                            ],
                            [
                                -73.94576565649714,
                                40.80772279086665
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c2f55713-902c-4451-bcf7-7834ed43b925"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94521125313472,
                                40.74719246862386
                            ],
                            [
                                -73.94531866738818,
                                40.746873562448826
                            ],
                            [
                                -73.9453653878678,
                                40.74693241693425
                            ],
                            [
                                -73.94521125313472,
                                40.74719246862386
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "0c8b67c1-2bb0-4b8b-ad7c-12fa5959670d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94497836220236,
                                40.670586561661864
                            ],
                            [
                                -73.9448990894718,
                                40.67099200457144
                            ],
                            [
                                -73.94496457396107,
                                40.67058284765401
                            ],
                            [
                                -73.94497836220236,
                                40.670586561661864
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "1a643b1c-2200-47bd-b364-f2cb6f5f3aaf"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94455174508741,
                                40.71618072957227
                            ],
                            [
                                -73.94401624677013,
                                40.71290525648695
                            ],
                            [
                                -73.94674956056659,
                                40.71430770873874
                            ],
                            [
                                -73.94455174508741,
                                40.71618072957227
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "bcbde73f-4ed9-4ceb-9957-cb51cb5f1f79"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9444608265975,
                                40.74634666347437
                            ],
                            [
                                -73.94506964810323,
                                40.7468737815905
                            ],
                            [
                                -73.94292036320896,
                                40.747048858541646
                            ],
                            [
                                -73.9431149859102,
                                40.74644288301428
                            ],
                            [
                                -73.9444608265975,
                                40.74634666347437
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "5d369421-bc61-4d27-beac-3536cd1fd913"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94359937996929,
                                40.68016025853013
                            ],
                            [
                                -73.9433092879129,
                                40.68030673248967
                            ],
                            [
                                -73.94306869572361,
                                40.680129851768626
                            ],
                            [
                                -73.94359937996929,
                                40.68016025853013
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "0a498eb3-9b66-4786-9f2a-a99f702d88cf"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94277227144279,
                                40.70530549347592
                            ],
                            [
                                -73.9421726028715,
                                40.70459641968722
                            ],
                            [
                                -73.94262351043174,
                                40.70444836744254
                            ],
                            [
                                -73.94299171549365,
                                40.70451348173264
                            ],
                            [
                                -73.94277227144279,
                                40.70530549347592
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "94d9fdc8-ff2f-4583-8945-09146d4fbc0a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94264597011046,
                                40.7972326464377
                            ],
                            [
                                -73.93982529335601,
                                40.79846576571168
                            ],
                            [
                                -73.93971633040698,
                                40.79781159374967
                            ],
                            [
                                -73.94264597011046,
                                40.7972326464377
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "160c2e63-2891-45f0-b884-1896da0a8809"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9425725975147,
                                40.76182331024161
                            ],
                            [
                                -73.94253492037505,
                                40.7620003366666
                            ],
                            [
                                -73.94244669233942,
                                40.76202523964502
                            ],
                            [
                                -73.9425725975147,
                                40.76182331024161
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1ab8de57-b4e9-486e-b9be-81812ca41ed7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94050571354892,
                                40.71202883667151
                            ],
                            [
                                -73.9394209894944,
                                40.71217532876022
                            ],
                            [
                                -73.94010760310805,
                                40.71147200938461
                            ],
                            [
                                -73.94050571354892,
                                40.71202883667151
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "a0673718-c488-4a9c-bab0-b5ae0d64b648"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94122800099406,
                                40.75508291260112
                            ],
                            [
                                -73.94197007319912,
                                40.754293039858105
                            ],
                            [
                                -73.94221560432585,
                                40.75404271701485
                            ],
                            [
                                -73.94122800099406,
                                40.75508291260112
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "d27b85be-a19e-489f-b637-4c8231b8fdf7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94111312263888,
                                40.70026184042229
                            ],
                            [
                                -73.94112594788619,
                                40.70026005541385
                            ],
                            [
                                -73.941127616956,
                                40.70026958045263
                            ],
                            [
                                -73.94111312263888,
                                40.70026184042229
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "de7c1659-7cb9-4225-a08a-3b8fe94acfde"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94093054185402,
                                40.75538881223615
                            ],
                            [
                                -73.94063471495286,
                                40.75569438179477
                            ],
                            [
                                -73.94112694771111,
                                40.755180069448976
                            ],
                            [
                                -73.94093054185402,
                                40.75538881223615
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "28cb1739-ca76-4ccb-b3f0-b14b8f63014b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9402024492314,
                                40.75058151515712
                            ],
                            [
                                -73.93989962355586,
                                40.750447065491024
                            ],
                            [
                                -73.93975608534987,
                                40.75063720191655
                            ],
                            [
                                -73.93989957684347,
                                40.75044693414919
                            ],
                            [
                                -73.94016247869284,
                                40.7505636336469
                            ],
                            [
                                -73.94014363473313,
                                40.750246334036554
                            ],
                            [
                                -73.9402024492314,
                                40.75058151515712
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "435ad398-ffd9-4937-b160-6339b49881c1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.94003219243392,
                                40.765723707350325
                            ],
                            [
                                -73.94001408060116,
                                40.76566302977282
                            ],
                            [
                                -73.94006833884431,
                                40.765667020153764
                            ],
                            [
                                -73.94003219243392,
                                40.765723707350325
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9a383f62-b420-4ade-9a18-198e7d649308"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93970645612538,
                                40.84739162278185
                            ],
                            [
                                -73.93978308960564,
                                40.84720353688344
                            ],
                            [
                                -73.93960173681776,
                                40.84764893345065
                            ],
                            [
                                -73.93970645612538,
                                40.84739162278185
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "0365f7fd-31f6-4f52-be64-d45a5c0e5f51"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.939270603431,
                                40.707797377804674
                            ],
                            [
                                -73.9397828874847,
                                40.707323711907065
                            ],
                            [
                                -73.94032559568045,
                                40.707696266840884
                            ],
                            [
                                -73.94002201811502,
                                40.70808584644115
                            ],
                            [
                                -73.939270603431,
                                40.707797377804674
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "f65fd3ac-8182-45b4-843a-74f096c554b1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93860280064868,
                                40.749772572295555
                            ],
                            [
                                -73.93860425005643,
                                40.749501410848296
                            ],
                            [
                                -73.93892622169041,
                                40.74972606637795
                            ],
                            [
                                -73.93860280064868,
                                40.749772572295555
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "25b5056b-9f86-4be3-bc97-95b33a01fa98"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93831985438204,
                                40.681710878702894
                            ],
                            [
                                -73.9378417862592,
                                40.68199343280711
                            ],
                            [
                                -73.93751755193381,
                                40.681804295110325
                            ],
                            [
                                -73.93786428905707,
                                40.68154574138513
                            ],
                            [
                                -73.93831985438204,
                                40.681710878702894
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "ffc48927-c237-411e-90a2-a5934a9d1340"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93821112140175,
                                40.83026948668249
                            ],
                            [
                                -73.93821122299775,
                                40.830269351868594
                            ],
                            [
                                -73.93836020703242,
                                40.83007184606671
                            ],
                            [
                                -73.93836056603074,
                                40.830071370623784
                            ],
                            [
                                -73.93821112140175,
                                40.83026948668249
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "cb81d0e4-1f38-4520-b797-1cd6e08a6ec7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93821122299775,
                                40.830269351868594
                            ],
                            [
                                -73.9382870955186,
                                40.830168672013905
                            ],
                            [
                                -73.93836020703242,
                                40.83007184606671
                            ],
                            [
                                -73.93821122299775,
                                40.830269351868594
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1430fd2b-8bc1-4547-9bc2-06a81f7f1164"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9382870955186,
                                40.830168672013905
                            ],
                            [
                                -73.93821122299775,
                                40.830269351868594
                            ],
                            [
                                -73.93818830868511,
                                40.830299729014044
                            ],
                            [
                                -73.9382870955186,
                                40.830168672013905
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9c257e9f-83e8-4702-aa21-c182e801f6cc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93836020703242,
                                40.83007184606671
                            ],
                            [
                                -73.9382870955186,
                                40.830168672013905
                            ],
                            [
                                -73.93838261338276,
                                40.83004214231892
                            ],
                            [
                                -73.93836020703242,
                                40.83007184606671
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "11b4989a-2ded-4830-9e95-90ce355365d2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93782485121947,
                                40.84017226974429
                            ],
                            [
                                -73.9404477153551,
                                40.84146897863858
                            ],
                            [
                                -73.93905241971646,
                                40.841983911368295
                            ],
                            [
                                -73.93782485121947,
                                40.84017226974429
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e5750120-1848-4838-93bf-2110a7ca7dbe"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93748587823521,
                                40.80429613376293
                            ],
                            [
                                -73.9366502527815,
                                40.80393183414649
                            ],
                            [
                                -73.9380660515682,
                                40.80350656244579
                            ],
                            [
                                -73.93748587823521,
                                40.80429613376293
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "40bfa0e6-b860-4f31-a20c-8b695892a745"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93744015129231,
                                40.829240638329516
                            ],
                            [
                                -73.93768481913804,
                                40.830181766646284
                            ],
                            [
                                -73.9378735776371,
                                40.83095323610434
                            ],
                            [
                                -73.93696879088968,
                                40.83036758915884
                            ],
                            [
                                -73.93744015129231,
                                40.829240638329516
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c5775ff5-4f6b-47e4-83df-0c6555943ece"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93625914696806,
                                40.73501486922149
                            ],
                            [
                                -73.9375336254892,
                                40.733878194974466
                            ],
                            [
                                -73.93886877712293,
                                40.734121428050734
                            ],
                            [
                                -73.93625914696806,
                                40.73501486922149
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "843d2d43-c096-458d-82ba-2631c8859be1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93611644329698,
                                40.72404254567288
                            ],
                            [
                                -73.94028942681216,
                                40.72246643927623
                            ],
                            [
                                -73.93961369519083,
                                40.72434078685879
                            ],
                            [
                                -73.93611644329698,
                                40.72404254567288
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "9429404d-690e-4ca6-9d88-40f442731455"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93516131675764,
                                40.77539212456013
                            ],
                            [
                                -73.9351912540128,
                                40.77540119735072
                            ],
                            [
                                -73.9350515830541,
                                40.77558657466059
                            ],
                            [
                                -73.93513509134125,
                                40.775608722216234
                            ],
                            [
                                -73.93512695529972,
                                40.775624298357705
                            ],
                            [
                                -73.93491575688485,
                                40.77554280061453
                            ],
                            [
                                -73.93516131675764,
                                40.77539212456013
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "48f83867-3945-4ff5-b7b5-da2b5e8f0074"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93514656821732,
                                40.819984739625234
                            ],
                            [
                                -73.93705827207513,
                                40.81918996738044
                            ],
                            [
                                -73.93752182162636,
                                40.82098962433387
                            ],
                            [
                                -73.93514656821732,
                                40.819984739625234
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "bdc85a33-0c79-48f0-8c2a-2f094d1afa98"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9344901386965,
                                40.73558200991506
                            ],
                            [
                                -73.93486942986733,
                                40.73552563599502
                            ],
                            [
                                -73.93527377145817,
                                40.73537828760206
                            ],
                            [
                                -73.9350337371227,
                                40.735779245426535
                            ],
                            [
                                -73.9344901386965,
                                40.73558200991506
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "df18c4f2-18d0-45fb-a19b-803e2fd482df"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93445807153579,
                                40.73732370839553
                            ],
                            [
                                -73.93508057258666,
                                40.737448535548744
                            ],
                            [
                                -73.93529972917663,
                                40.7372256582929
                            ],
                            [
                                -73.93531895042877,
                                40.73720779429206
                            ],
                            [
                                -73.93536335864484,
                                40.73749721978348
                            ],
                            [
                                -73.93502064148247,
                                40.73759849581011
                            ],
                            [
                                -73.93445807153579,
                                40.73732370839553
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "1f43e54b-ffb7-4e30-81fd-a1d0804cbe00"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93375729668097,
                                40.70266790999723
                            ],
                            [
                                -73.93352304120769,
                                40.70276106071517
                            ],
                            [
                                -73.93343308032189,
                                40.70279536958278
                            ],
                            [
                                -73.93341197260764,
                                40.702697272693186
                            ],
                            [
                                -73.93375729668097,
                                40.70266790999723
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "9164317a-69c4-49fa-b07e-73bd572f45e5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93187167091584,
                                40.813835065643474
                            ],
                            [
                                -73.92878884869347,
                                40.814855661441904
                            ],
                            [
                                -73.9282560065566,
                                40.81214617139373
                            ],
                            [
                                -73.93142545795128,
                                40.813634039552
                            ],
                            [
                                -73.93143932493884,
                                40.81359262467117
                            ],
                            [
                                -73.93187167091584,
                                40.813835065643474
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "564bcf64-d937-4b3e-833f-c14ea109102c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9282560065566,
                                40.81214617139373
                            ],
                            [
                                -73.92818116175998,
                                40.81176558731914
                            ],
                            [
                                -73.93143932493884,
                                40.81359262467117
                            ],
                            [
                                -73.93142545795128,
                                40.813634039552
                            ],
                            [
                                -73.9282560065566,
                                40.81214617139373
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3033fb26-3737-4ac9-b7e6-89fb47142d5d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93116457281974,
                                40.66951546211476
                            ],
                            [
                                -73.9316943219192,
                                40.670529314408384
                            ],
                            [
                                -73.93103629519379,
                                40.67085163268177
                            ],
                            [
                                -73.93056036595964,
                                40.67047517540369
                            ],
                            [
                                -73.93116457281974,
                                40.66951546211476
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "de07c057-edd7-43dc-9d28-00cf594a5d1d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93091015286328,
                                40.65531008222759
                            ],
                            [
                                -73.93085308918148,
                                40.655000994518716
                            ],
                            [
                                -73.93083966924945,
                                40.65459734421496
                            ],
                            [
                                -73.93101611633757,
                                40.65526317249664
                            ],
                            [
                                -73.93091015286328,
                                40.65531008222759
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ff71d9a7-60c9-412c-b659-46531b28f672"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93087926159728,
                                40.744352534727724
                            ],
                            [
                                -73.93099378332128,
                                40.74460512773967
                            ],
                            [
                                -73.93159858935768,
                                40.74466800922289
                            ],
                            [
                                -73.9308400537284,
                                40.74458925439752
                            ],
                            [
                                -73.93098458457277,
                                40.74474277980465
                            ],
                            [
                                -73.9307937976778,
                                40.74481320204311
                            ],
                            [
                                -73.93070144278174,
                                40.744710195380485
                            ],
                            [
                                -73.93087926159728,
                                40.744352534727724
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "e78d8e52-adb4-467b-919b-9ed5e392a7df"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93082116754786,
                                40.75526424535158
                            ],
                            [
                                -73.92855088766966,
                                40.75806738473227
                            ],
                            [
                                -73.92889741728209,
                                40.758230335208445
                            ],
                            [
                                -73.92834119303457,
                                40.75832547265307
                            ],
                            [
                                -73.92883687622184,
                                40.75625123577057
                            ],
                            [
                                -73.93082116754786,
                                40.75526424535158
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "2fd9df26-9637-407d-8150-62d33c06ae6f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93006087481389,
                                40.651706696236346
                            ],
                            [
                                -73.93099022689108,
                                40.651634846504734
                            ],
                            [
                                -73.93097720835092,
                                40.65164739335891
                            ],
                            [
                                -73.93009743359448,
                                40.65173294252749
                            ],
                            [
                                -73.93006087481389,
                                40.651706696236346
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "29f77807-934f-4919-93a6-6e8d99d1b4a6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93097720835092,
                                40.65164739335891
                            ],
                            [
                                -73.93055075757935,
                                40.65205839302383
                            ],
                            [
                                -73.93009743359448,
                                40.65173294252749
                            ],
                            [
                                -73.93097720835092,
                                40.65164739335891
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "12a8ddcc-8556-47d7-9bf6-975c885f4fe4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.93009743359448,
                                40.65173294252749
                            ],
                            [
                                -73.93055075757935,
                                40.65205839302383
                            ],
                            [
                                -73.93097720835092,
                                40.65164739335891
                            ],
                            [
                                -73.93119323895877,
                                40.651626386574215
                            ],
                            [
                                -73.93057372031465,
                                40.6522515945639
                            ],
                            [
                                -73.92981350932736,
                                40.651760551281676
                            ],
                            [
                                -73.93009743359448,
                                40.65173294252749
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5684a859-8a98-4c61-bbad-3cc75f91820b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92994394111335,
                                40.686780721564034
                            ],
                            [
                                -73.92990356723122,
                                40.686587585978714
                            ],
                            [
                                -73.92998448926865,
                                40.6869740752474
                            ],
                            [
                                -73.92994394111335,
                                40.686780721564034
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "d1d4874f-c5fd-4ad5-a73e-d765dff743fb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92975770598193,
                                40.645897036877216
                            ],
                            [
                                -73.92989291051701,
                                40.64602300533475
                            ],
                            [
                                -73.9297852348538,
                                40.64615230314078
                            ],
                            [
                                -73.92975770598193,
                                40.645897036877216
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "0860d69b-1dd9-4003-a7cb-73570ff72ddf"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92245054266125,
                                40.82678907810797
                            ],
                            [
                                -73.92789407366989,
                                40.82448228965906
                            ],
                            [
                                -73.92974153540848,
                                40.82979504126539
                            ],
                            [
                                -73.92245054266125,
                                40.82678907810797
                            ]
                        ],
                        [
                            [
                                -73.92767147204081,
                                40.82485996979565
                            ],
                            [
                                -73.92253431415475,
                                40.826813332110156
                            ],
                            [
                                -73.92955277241784,
                                40.829679130742385
                            ],
                            [
                                -73.92767147204081,
                                40.82485996979565
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b27ca3c0-9e26-43d4-b7eb-34127fc9313b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92957085909777,
                                40.75202025526509
                            ],
                            [
                                -73.92791147403695,
                                40.75219707370126
                            ],
                            [
                                -73.92784691840569,
                                40.7520496846601
                            ],
                            [
                                -73.92957085909777,
                                40.75202025526509
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "0aba23d7-0669-4674-8c94-fa99a826e8fe"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92955277241784,
                                40.829679130742385
                            ],
                            [
                                -73.92253431415475,
                                40.826813332110156
                            ],
                            [
                                -73.92767147204081,
                                40.82485996979565
                            ],
                            [
                                -73.92955277241784,
                                40.829679130742385
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "7a08befe-a03f-4057-afc4-87f2b14f2eae"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92934228906192,
                                40.69779260974051
                            ],
                            [
                                -73.92942491520344,
                                40.69778562200062
                            ],
                            [
                                -73.92940276993507,
                                40.697795635717696
                            ],
                            [
                                -73.92934228906192,
                                40.69779260974051
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "f1a99db9-32b6-4bf6-afdd-01a8c504e5f7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92913108974874,
                                40.64174299473056
                            ],
                            [
                                -73.92943291020947,
                                40.64171900580748
                            ],
                            [
                                -73.92935356381706,
                                40.642070749779336
                            ],
                            [
                                -73.92913108974874,
                                40.64174299473056
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9ec5af0d-8cc8-4bad-86dc-3bee9c844cb6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92824455856451,
                                40.70205561216196
                            ],
                            [
                                -73.92804205404497,
                                40.70195394561886
                            ],
                            [
                                -73.92785339346224,
                                40.701831722867155
                            ],
                            [
                                -73.92824455856451,
                                40.70205561216196
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "8b2cc2f5-4dca-4c0c-a270-8e1331cebb63"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92776369308787,
                                40.62722483217806
                            ],
                            [
                                -73.92678433898534,
                                40.6267732714232
                            ],
                            [
                                -73.9272108572856,
                                40.62650315247634
                            ],
                            [
                                -73.92776369308787,
                                40.62722483217806
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9d289a74-1331-48b2-af0d-0ea548725ec0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9276264808181,
                                40.81766442705093
                            ],
                            [
                                -73.92704658596287,
                                40.81894078097275
                            ],
                            [
                                -73.92627178453874,
                                40.81830422270401
                            ],
                            [
                                -73.9276264808181,
                                40.81766442705093
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "14f4e732-55a7-462a-b523-de50ea10e30c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9276221832778,
                                40.6942415297674
                            ],
                            [
                                -73.92729414099392,
                                40.69247804206734
                            ],
                            [
                                -73.93104105536649,
                                40.69296238786161
                            ],
                            [
                                -73.9276221832778,
                                40.6942415297674
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "46b6d082-46aa-424b-8a96-9169167bbb2d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92607175936936,
                                40.76111856313504
                            ],
                            [
                                -73.92616308120283,
                                40.76224724316158
                            ],
                            [
                                -73.92505343095992,
                                40.76237384906452
                            ],
                            [
                                -73.92473377714417,
                                40.76157483449863
                            ],
                            [
                                -73.92607175936936,
                                40.76111856313504
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "8332b56f-8a76-426a-bfb5-0b1ac07f84b4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92603345700228,
                                40.71557539001096
                            ],
                            [
                                -73.92681163878682,
                                40.71536663869904
                            ],
                            [
                                -73.92749776661094,
                                40.71518527206899
                            ],
                            [
                                -73.92603345700228,
                                40.71557539001096
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "507785f2-0795-4022-b144-c07d8f221ac5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92570557372021,
                                40.63301226852006
                            ],
                            [
                                -73.92811400730258,
                                40.630506067731865
                            ],
                            [
                                -73.93107759254403,
                                40.6326781936579
                            ],
                            [
                                -73.92750592511035,
                                40.634248921342085
                            ],
                            [
                                -73.92570557372021,
                                40.63301226852006
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b6503e12-cba1-4f8b-9f0e-9b13b0b335a3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92543550600982,
                                40.671222827906945
                            ],
                            [
                                -73.92558938023147,
                                40.672013177787555
                            ],
                            [
                                -73.92522825209736,
                                40.67199731302322
                            ],
                            [
                                -73.92543550600982,
                                40.671222827906945
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "89e3869d-5f13-4102-b75b-a3321a8b21b9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9243671090997,
                                40.69811867416732
                            ],
                            [
                                -73.92288185796514,
                                40.698336547874604
                            ],
                            [
                                -73.92282308838261,
                                40.69827298921953
                            ],
                            [
                                -73.92420450145687,
                                40.69796599399342
                            ],
                            [
                                -73.9243671090997,
                                40.69811867416732
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "583c6b10-26e0-40ec-9ed1-99b34fdb0bc9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92404230254748,
                                40.793109596732094
                            ],
                            [
                                -73.92397473582619,
                                40.79308389986209
                            ],
                            [
                                -73.92370797025998,
                                40.79249418038275
                            ],
                            [
                                -73.92318248366654,
                                40.792724180668344
                            ],
                            [
                                -73.92359923366371,
                                40.79294108946562
                            ],
                            [
                                -73.9230948844516,
                                40.7927492761603
                            ],
                            [
                                -73.92375286176967,
                                40.79244429115985
                            ],
                            [
                                -73.92404230254748,
                                40.793109596732094
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5740f63a-4573-4a15-8007-781bdafe2ffd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92397473582619,
                                40.79308389986209
                            ],
                            [
                                -73.92359923366371,
                                40.79294108946562
                            ],
                            [
                                -73.92318248366654,
                                40.792724180668344
                            ],
                            [
                                -73.92370797025998,
                                40.79249418038275
                            ],
                            [
                                -73.92397473582619,
                                40.79308389986209
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "43bc26f3-1f6f-4af8-8d76-64575ba6602e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92359923366371,
                                40.79294108946562
                            ],
                            [
                                -73.92397473582619,
                                40.79308389986209
                            ],
                            [
                                -73.9240058749644,
                                40.79315273691866
                            ],
                            [
                                -73.92359923366371,
                                40.79294108946562
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d472f778-5b4a-4af3-b73f-2475489d29de"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92389950904486,
                                40.75820216329582
                            ],
                            [
                                -73.91762789573595,
                                40.755266954335745
                            ],
                            [
                                -73.92306849733845,
                                40.75398811168642
                            ],
                            [
                                -73.92389950904486,
                                40.75820216329582
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c3e1f06a-44d3-4bf4-a76b-56003d9df10b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9234839951068,
                                40.70696237186857
                            ],
                            [
                                -73.9224865749856,
                                40.7063419485774
                            ],
                            [
                                -73.92319809908113,
                                40.70623604471043
                            ],
                            [
                                -73.9234839951068,
                                40.70696237186857
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "4e2a9fee-eb1f-49a6-9338-d53226ac6a80"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92341510240936,
                                40.66545505065738
                            ],
                            [
                                -73.92166923114625,
                                40.66385650340441
                            ],
                            [
                                -73.92376488482144,
                                40.6642746130565
                            ],
                            [
                                -73.92341510240936,
                                40.66545505065738
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "a8976424-1b47-4c97-a49f-fc634461b376"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92296475809282,
                                40.79491461074518
                            ],
                            [
                                -73.92284230078252,
                                40.79579883598558
                            ],
                            [
                                -73.92265662247563,
                                40.79516029797888
                            ],
                            [
                                -73.92268705110925,
                                40.7969198444362
                            ],
                            [
                                -73.92285057946204,
                                40.79701460984941
                            ],
                            [
                                -73.92268700978676,
                                40.79691996752557
                            ],
                            [
                                -73.92276795248543,
                                40.79755228482118
                            ],
                            [
                                -73.9222608454227,
                                40.79730405422022
                            ],
                            [
                                -73.92192746624772,
                                40.79707534557477
                            ],
                            [
                                -73.92268691097208,
                                40.796919910401215
                            ],
                            [
                                -73.9217856132812,
                                40.796489719130825
                            ],
                            [
                                -73.92178408075533,
                                40.79641802960886
                            ],
                            [
                                -73.92263236347138,
                                40.795002726297604
                            ],
                            [
                                -73.92296475809282,
                                40.79491461074518
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4c9d913c-ae9a-49b1-827e-eb792f56d866"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92284230078252,
                                40.79579883598558
                            ],
                            [
                                -73.92268705110925,
                                40.7969198444362
                            ],
                            [
                                -73.92265662247563,
                                40.79516029797888
                            ],
                            [
                                -73.92284230078252,
                                40.79579883598558
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9f2b602b-2e68-4f01-8093-daa26c0d8a78"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92268705110925,
                                40.7969198444362
                            ],
                            [
                                -73.92284230078252,
                                40.79579883598558
                            ],
                            [
                                -73.92326564926366,
                                40.79725470931726
                            ],
                            [
                                -73.92268705110925,
                                40.7969198444362
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "22614f94-b72e-4e31-a45e-a1eb90dfb604"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92268705110925,
                                40.7969198444362
                            ],
                            [
                                -73.92326564926366,
                                40.79725470931726
                            ],
                            [
                                -73.92326567101905,
                                40.79725478413291
                            ],
                            [
                                -73.92285057946204,
                                40.79701460984941
                            ],
                            [
                                -73.92268705110925,
                                40.7969198444362
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "0668aecf-3f0d-42eb-9d89-7413795f9c47"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92326564926366,
                                40.79725470931726
                            ],
                            [
                                -73.92369038124671,
                                40.79750052383274
                            ],
                            [
                                -73.92326567101905,
                                40.79725478413291
                            ],
                            [
                                -73.92326564926366,
                                40.79725470931726
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "aa810208-a63f-4b37-9021-4f0ccee23c62"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92285057946204,
                                40.79701460984941
                            ],
                            [
                                -73.92326567101905,
                                40.79725478413291
                            ],
                            [
                                -73.92326580151082,
                                40.797255232887295
                            ],
                            [
                                -73.92285057946204,
                                40.79701460984941
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "538c6537-5628-46e2-b5a4-d733befdea6e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92281821412388,
                                40.689931955915874
                            ],
                            [
                                -73.92169169980814,
                                40.68999174693641
                            ],
                            [
                                -73.92150413876334,
                                40.68928775364988
                            ],
                            [
                                -73.9227894330005,
                                40.689415798743454
                            ],
                            [
                                -73.92281821412388,
                                40.689931955915874
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "d53b05fe-8e0d-4d61-82bd-530a8ec18434"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92183132585107,
                                40.794672761624646
                            ],
                            [
                                -73.922368749258,
                                40.79425852745086
                            ],
                            [
                                -73.92245613916546,
                                40.79473166015678
                            ],
                            [
                                -73.92183132585107,
                                40.794672761624646
                            ]
                        ],
                        [
                            [
                                -73.92242399834292,
                                40.79471653938533
                            ],
                            [
                                -73.92232374905215,
                                40.79430841327029
                            ],
                            [
                                -73.92183132585107,
                                40.794672761624646
                            ],
                            [
                                -73.92242399834292,
                                40.79471653938533
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ab23dcb5-db6c-4b80-86f4-cb78f6bd7600"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92242399834292,
                                40.79471653938533
                            ],
                            [
                                -73.92183132585107,
                                40.794672761624646
                            ],
                            [
                                -73.92232374905215,
                                40.79430841327029
                            ],
                            [
                                -73.92242399834292,
                                40.79471653938533
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "600a08df-e243-402d-8b89-aec97d0ccde9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92176665703207,
                                40.67862735189195
                            ],
                            [
                                -73.9217440895555,
                                40.678513003989586
                            ],
                            [
                                -73.9217854776356,
                                40.67839719730384
                            ],
                            [
                                -73.92176665703207,
                                40.67862735189195
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "81022b6d-ea63-42e4-8d56-8d27b47da5d3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92073351450529,
                                40.79575232194911
                            ],
                            [
                                -73.91913660008595,
                                40.79722409958662
                            ],
                            [
                                -73.91915053864263,
                                40.79715570640345
                            ],
                            [
                                -73.9206903809986,
                                40.79576340902738
                            ],
                            [
                                -73.92047976109524,
                                40.79574632848764
                            ],
                            [
                                -73.92073351450529,
                                40.79575232194911
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f44e9535-747c-4f61-9eca-4b6793c33435"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91915053864263,
                                40.79715570640345
                            ],
                            [
                                -73.91921762583142,
                                40.79682652552081
                            ],
                            [
                                -73.91949490641663,
                                40.79572306697335
                            ],
                            [
                                -73.92047976109524,
                                40.79574632848764
                            ],
                            [
                                -73.9206903809986,
                                40.79576340902738
                            ],
                            [
                                -73.91915053864263,
                                40.79715570640345
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a67322a8-43a8-4a34-a8ed-a89f2cae5705"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91921762583142,
                                40.79682652552081
                            ],
                            [
                                -73.91915053864263,
                                40.79715570640345
                            ],
                            [
                                -73.91913031277727,
                                40.79717399426205
                            ],
                            [
                                -73.91921762583142,
                                40.79682652552081
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6e3d8f8a-0576-4c09-98b1-2b5f7eb7c2c1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91921762583142,
                                40.79682652552081
                            ],
                            [
                                -73.91944276210445,
                                40.79572183536458
                            ],
                            [
                                -73.91949490641663,
                                40.79572306697335
                            ],
                            [
                                -73.91921762583142,
                                40.79682652552081
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9e595485-e1b1-4008-bc0b-7538659005f1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92047976109524,
                                40.79574632848764
                            ],
                            [
                                -73.91949490641663,
                                40.79572306697335
                            ],
                            [
                                -73.91950884666834,
                                40.79566759071314
                            ],
                            [
                                -73.92047976109524,
                                40.79574632848764
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ca8da0b0-62ce-4e12-87b1-41c270e4bc36"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.92055849484017,
                                40.644995670532886
                            ],
                            [
                                -73.9196864840242,
                                40.645053535424545
                            ],
                            [
                                -73.9199759310902,
                                40.6450299559729
                            ],
                            [
                                -73.91994412983082,
                                40.64465330728594
                            ],
                            [
                                -73.92002700121242,
                                40.64464391471042
                            ],
                            [
                                -73.92055849484017,
                                40.644995670532886
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "97203d0a-a1d5-4e98-ada3-79d65d11b347"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91960704420698,
                                40.83794460678475
                            ],
                            [
                                -73.91961481133964,
                                40.837919548881096
                            ],
                            [
                                -73.9226022569068,
                                40.83585596742862
                            ],
                            [
                                -73.92234450732174,
                                40.83766183196644
                            ],
                            [
                                -73.91960704420698,
                                40.83794460678475
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "be156482-156b-49d0-93d9-fd018feba58e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91961481133964,
                                40.837919548881096
                            ],
                            [
                                -73.91960704420698,
                                40.83794460678475
                            ],
                            [
                                -73.92234450732174,
                                40.83766183196644
                            ],
                            [
                                -73.9226022569068,
                                40.83585596742862
                            ],
                            [
                                -73.92420476915481,
                                40.834749030255026
                            ],
                            [
                                -73.92237255541986,
                                40.83790324100982
                            ],
                            [
                                -73.9193042688371,
                                40.83813405647096
                            ],
                            [
                                -73.91961481133964,
                                40.837919548881096
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "2ce10f13-8eeb-46ce-8f36-9da32f26ad37"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91961481133964,
                                40.837919548881096
                            ],
                            [
                                -73.92091389262107,
                                40.833728523051136
                            ],
                            [
                                -73.92304874158873,
                                40.8327277728532
                            ],
                            [
                                -73.9226022569068,
                                40.83585596742862
                            ],
                            [
                                -73.91961481133964,
                                40.837919548881096
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d6db37d7-3a3b-4eb7-92e2-81d35c6da548"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9195723386943,
                                40.63453786171993
                            ],
                            [
                                -73.91885206860117,
                                40.63448295589092
                            ],
                            [
                                -73.91997284717382,
                                40.63375874977332
                            ],
                            [
                                -73.9195723386943,
                                40.63453786171993
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3b04d205-ef8b-4066-ba16-2bbe4445cea4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91951768878567,
                                40.69869246241852
                            ],
                            [
                                -73.91971256840509,
                                40.69867243729568
                            ],
                            [
                                -73.91992158930371,
                                40.69865106094883
                            ],
                            [
                                -73.91951768878567,
                                40.69869246241852
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 750
                        }
                    },
                    "id": "ef8a220e-eba0-4af5-bdc2-c5e30fd97e6c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91667510571467,
                                40.81848424419094
                            ],
                            [
                                -73.91445586451766,
                                40.81477100014358
                            ],
                            [
                                -73.91949145618275,
                                40.813727845322305
                            ],
                            [
                                -73.91667510571467,
                                40.81848424419094
                            ]
                        ],
                        [
                            [
                                -73.91450809705985,
                                40.81478858902908
                            ],
                            [
                                -73.91670268758716,
                                40.81842297033032
                            ],
                            [
                                -73.9194604562206,
                                40.81377010254373
                            ],
                            [
                                -73.91450809705985,
                                40.81478858902908
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "be64d064-7575-4845-91f0-130b42f7b847"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91948172922484,
                                40.63150776224056
                            ],
                            [
                                -73.91860666503523,
                                40.632068662934046
                            ],
                            [
                                -73.91845460720708,
                                40.63232486765491
                            ],
                            [
                                -73.91778583483426,
                                40.632596334017684
                            ],
                            [
                                -73.91773314145631,
                                40.63230581042936
                            ],
                            [
                                -73.91948172922484,
                                40.63150776224056
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "795a8e15-3e67-44f8-a0bd-f262d1eabaff"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9194604562206,
                                40.81377010254373
                            ],
                            [
                                -73.91670268758716,
                                40.81842297033032
                            ],
                            [
                                -73.91450809705985,
                                40.81478858902908
                            ],
                            [
                                -73.9194604562206,
                                40.81377010254373
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "44dac522-8696-42c1-b815-a2bd36bf8221"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91700196955657,
                                40.659920193428974
                            ],
                            [
                                -73.91700191461169,
                                40.65991997849774
                            ],
                            [
                                -73.91705433310514,
                                40.65988436574025
                            ],
                            [
                                -73.91735405060979,
                                40.66129694352051
                            ],
                            [
                                -73.91700201701963,
                                40.65992006708665
                            ],
                            [
                                -73.91700196955657,
                                40.659920193428974
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "38d0ed5b-eac4-46d0-ad39-39dee1d3f234"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91735405060979,
                                40.66129694352051
                            ],
                            [
                                -73.91807271182024,
                                40.66062705202785
                            ],
                            [
                                -73.91811275091065,
                                40.660663731906894
                            ],
                            [
                                -73.91735405060979,
                                40.66129694352051
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "da8fdabe-a2bc-439d-a5a7-4cf6a8f488e2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91853060687451,
                                40.66104653046846
                            ],
                            [
                                -73.91811275091065,
                                40.660663731906894
                            ],
                            [
                                -73.91811356540273,
                                40.660663052131426
                            ],
                            [
                                -73.91853060687451,
                                40.66104653046846
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ab4b2300-ccdc-4153-a04a-c7905f901176"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91748786095957,
                                40.68627788914631
                            ],
                            [
                                -73.91587623572914,
                                40.68635507269109
                            ],
                            [
                                -73.9157841862603,
                                40.68592409966264
                            ],
                            [
                                -73.91748786095957,
                                40.68627788914631
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "c4b97901-1775-4afd-b4f7-078ba593aea1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91737268616036,
                                40.84066218683399
                            ],
                            [
                                -73.91787959289877,
                                40.83950768472761
                            ],
                            [
                                -73.91832013762642,
                                40.83935412720036
                            ],
                            [
                                -73.91823893233205,
                                40.83946744437102
                            ],
                            [
                                -73.91737268616036,
                                40.84066218683399
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "40ad0f00-99b5-41f5-8ace-b8165ffe4a33"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91832013762642,
                                40.83935412720036
                            ],
                            [
                                -73.91787959289877,
                                40.83950768472761
                            ],
                            [
                                -73.91737268616036,
                                40.84066218683399
                            ],
                            [
                                -73.91823893233205,
                                40.83946744437102
                            ],
                            [
                                -73.9166989514939,
                                40.841616396268336
                            ],
                            [
                                -73.91705563296736,
                                40.83918977554843
                            ],
                            [
                                -73.91897967698222,
                                40.83843377915778
                            ],
                            [
                                -73.91832013762642,
                                40.83935412720036
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b4505c82-a77b-4fba-bb38-3d80efb702a7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91832013762642,
                                40.83935412720036
                            ],
                            [
                                -73.91832141579654,
                                40.839353681677736
                            ],
                            [
                                -73.91823893233205,
                                40.83946744437102
                            ],
                            [
                                -73.91832013762642,
                                40.83935412720036
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "1a92c0d2-f249-4891-b18d-1622d90ae17c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9167463825409,
                                40.66609253308573
                            ],
                            [
                                -73.91599028314268,
                                40.66314993292445
                            ],
                            [
                                -73.91722091219985,
                                40.66451001348251
                            ],
                            [
                                -73.9167463825409,
                                40.66609253308573
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "768dc5c9-da89-46a1-ac4b-4a021ad7b9e2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91664615121644,
                                40.63333217069862
                            ],
                            [
                                -73.91618827542456,
                                40.63371978125733
                            ],
                            [
                                -73.91604015760724,
                                40.63372278969138
                            ],
                            [
                                -73.91664615121644,
                                40.63333217069862
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8b941157-e4c1-4508-97d3-cee76fc47397"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91596878286467,
                                40.66305353300997
                            ],
                            [
                                -73.91585908778869,
                                40.662787987386565
                            ],
                            [
                                -73.91561779401518,
                                40.66166119358701
                            ],
                            [
                                -73.91596878286467,
                                40.66305353300997
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c52271e7-53cd-4932-87ba-f99b08bb6cdc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91561577460241,
                                40.825651887095304
                            ],
                            [
                                -73.91544879614348,
                                40.82575838329917
                            ],
                            [
                                -73.91544910837112,
                                40.825754020560694
                            ],
                            [
                                -73.91523105850557,
                                40.825761051959674
                            ],
                            [
                                -73.9153983842544,
                                40.82579053519978
                            ],
                            [
                                -73.91488483432033,
                                40.82611806917525
                            ],
                            [
                                -73.91524500035878,
                                40.82561816024533
                            ],
                            [
                                -73.91561577460241,
                                40.825651887095304
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4a1773e0-476a-4414-8b18-0c9ae11191fa"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91544879614348,
                                40.82575838329917
                            ],
                            [
                                -73.9153983842544,
                                40.82579053519978
                            ],
                            [
                                -73.91523105850557,
                                40.825761051959674
                            ],
                            [
                                -73.91544910837112,
                                40.825754020560694
                            ],
                            [
                                -73.91544879614348,
                                40.82575838329917
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "fcd7be0d-e382-46b6-938d-91d1836a6f12"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9153983842544,
                                40.82579053519978
                            ],
                            [
                                -73.91544879614348,
                                40.82575838329917
                            ],
                            [
                                -73.91544589599566,
                                40.82579890689443
                            ],
                            [
                                -73.9153983842544,
                                40.82579053519978
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c576d252-c72e-46da-a663-1132928dacdb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91422188572551,
                                40.685143210968654
                            ],
                            [
                                -73.91983778277591,
                                40.68600565956078
                            ],
                            [
                                -73.91485059323665,
                                40.688385813559634
                            ],
                            [
                                -73.91422188572551,
                                40.685143210968654
                            ]
                        ],
                        [
                            [
                                -73.9157841862603,
                                40.68592409966264
                            ],
                            [
                                -73.91587623572914,
                                40.68635507269109
                            ],
                            [
                                -73.91748786095957,
                                40.68627788914631
                            ],
                            [
                                -73.9157841862603,
                                40.68592409966264
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "6fcaded4-3e87-4d68-bb8f-f8e6811906c5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91387039831102,
                                40.84398408765693
                            ],
                            [
                                -73.91468199383087,
                                40.844432846602864
                            ],
                            [
                                -73.91438307852178,
                                40.84485873258651
                            ],
                            [
                                -73.91416861023924,
                                40.84515194868347
                            ],
                            [
                                -73.91387039831102,
                                40.84398408765693
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "7caba718-e15f-4cc3-a4fe-137638d67154"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91356956869204,
                                40.65659824791026
                            ],
                            [
                                -73.91355698598986,
                                40.65658782865158
                            ],
                            [
                                -73.91362393383473,
                                40.65654104589427
                            ],
                            [
                                -73.91356956869204,
                                40.65659824791026
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "314fa340-041c-472c-8d29-3c24e97d44b8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91348302948916,
                                40.76559725274784
                            ],
                            [
                                -73.91347435356013,
                                40.76560775974031
                            ],
                            [
                                -73.91349009563717,
                                40.76558853037114
                            ],
                            [
                                -73.91348302948916,
                                40.76559725274784
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "fd81fbbb-6df3-4e6c-a88f-68d38020f079"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91218487137712,
                                40.683867248675284
                            ],
                            [
                                -73.91208673801877,
                                40.68381202038195
                            ],
                            [
                                -73.9092180719636,
                                40.682084940702346
                            ],
                            [
                                -73.90921559945414,
                                40.682196177558346
                            ],
                            [
                                -73.90883286718324,
                                40.68198078036334
                            ],
                            [
                                -73.91191870325338,
                                40.68249290301742
                            ],
                            [
                                -73.91218487137712,
                                40.683867248675284
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "e10e3f0f-0195-46d3-9fc9-6b8e77684b1f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91208673801877,
                                40.68381202038195
                            ],
                            [
                                -73.90921559945414,
                                40.682196177558346
                            ],
                            [
                                -73.9092180719636,
                                40.682084940702346
                            ],
                            [
                                -73.91208673801877,
                                40.68381202038195
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "7cfee62f-5890-4e88-8da7-2cc36ff27a4b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90921559945414,
                                40.682196177558346
                            ],
                            [
                                -73.91208673801877,
                                40.68381202038195
                            ],
                            [
                                -73.91212195876452,
                                40.6838332250231
                            ],
                            [
                                -73.90918565535824,
                                40.68354334615367
                            ],
                            [
                                -73.90921559945414,
                                40.682196177558346
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "dfe0171d-d766-4cb9-b641-1e566f085504"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91182091085493,
                                40.72934501056303
                            ],
                            [
                                -73.91131277791615,
                                40.72947513116167
                            ],
                            [
                                -73.91121945733117,
                                40.72903673036711
                            ],
                            [
                                -73.91182091085493,
                                40.72934501056303
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "6d68ab64-c24c-467d-9265-07ff6b317792"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91166907850392,
                                40.84854703266573
                            ],
                            [
                                -73.9118614814681,
                                40.84840817818551
                            ],
                            [
                                -73.91167767448286,
                                40.84866086959738
                            ],
                            [
                                -73.91166907850392,
                                40.84854703266573
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "29e7b823-3c57-4795-b198-318e5cdc0009"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91160625931609,
                                40.75845793853912
                            ],
                            [
                                -73.91527677017712,
                                40.753898835239475
                            ],
                            [
                                -73.91590538067022,
                                40.75751978451053
                            ],
                            [
                                -73.91160625931609,
                                40.75845793853912
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "a6960aa5-4aea-4c3d-a44a-3ef6bdf59ffe"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9106960081168,
                                40.77615571490596
                            ],
                            [
                                -73.91220482834947,
                                40.77401201466871
                            ],
                            [
                                -73.91329238991787,
                                40.774005404325464
                            ],
                            [
                                -73.9106960081168,
                                40.77615571490596
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c2532cff-4383-4ac0-aa4e-944327592d9d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.91180472411182,
                                40.773729134655554
                            ],
                            [
                                -73.91369923870667,
                                40.7736733257393
                            ],
                            [
                                -73.91373420023922,
                                40.775094887091875
                            ],
                            [
                                -73.91039330551045,
                                40.77641017889951
                            ],
                            [
                                -73.91180472411182,
                                40.773729134655554
                            ]
                        ],
                        [
                            [
                                -73.91329238991787,
                                40.774005404325464
                            ],
                            [
                                -73.91220482834947,
                                40.77401201466871
                            ],
                            [
                                -73.9106960081168,
                                40.77615571490596
                            ],
                            [
                                -73.91329238991787,
                                40.774005404325464
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "78718e07-e87c-491c-b8ba-a0a2c25c6094"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90638917472594,
                                40.817804855173385
                            ],
                            [
                                -73.90566187801068,
                                40.81773032247504
                            ],
                            [
                                -73.9080434780172,
                                40.815034090522616
                            ],
                            [
                                -73.91004953552259,
                                40.815939997191286
                            ],
                            [
                                -73.90638917472594,
                                40.817804855173385
                            ]
                        ],
                        [
                            [
                                -73.90747813497126,
                                40.81591018274001
                            ],
                            [
                                -73.90649956330132,
                                40.81726292366434
                            ],
                            [
                                -73.90691616689581,
                                40.81730766617927
                            ],
                            [
                                -73.90904064661014,
                                40.81598385134623
                            ],
                            [
                                -73.90747813497126,
                                40.81591018274001
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3d40323a-16a6-4b3f-aac8-d9763f28fbd4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90904064661014,
                                40.81598385134623
                            ],
                            [
                                -73.90691616689581,
                                40.81730766617927
                            ],
                            [
                                -73.90649956330132,
                                40.81726292366434
                            ],
                            [
                                -73.90747813497126,
                                40.81591018274001
                            ],
                            [
                                -73.90904064661014,
                                40.81598385134623
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4ce90e2e-d4a0-4526-89e7-2a3b320d20b9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90839731183121,
                                40.71296514672962
                            ],
                            [
                                -73.90973547192283,
                                40.71239647119148
                            ],
                            [
                                -73.91028951056927,
                                40.713085292021056
                            ],
                            [
                                -73.90839731183121,
                                40.71296514672962
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "21d9657e-dd7a-4cb4-a285-07bc7ab60ca4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90339045996701,
                                40.68122212596314
                            ],
                            [
                                -73.90422851711948,
                                40.68111815582699
                            ],
                            [
                                -73.90436485177591,
                                40.68126801534046
                            ],
                            [
                                -73.90586237987544,
                                40.68091545732047
                            ],
                            [
                                -73.90767471240656,
                                40.68069061769055
                            ],
                            [
                                -73.90837278962059,
                                40.684044174656464
                            ],
                            [
                                -73.90339045996701,
                                40.68122212596314
                            ]
                        ],
                        [
                            [
                                -73.90687214675195,
                                40.68121655481809
                            ],
                            [
                                -73.90486237201793,
                                40.68204594045713
                            ],
                            [
                                -73.90719523213208,
                                40.68337455321525
                            ],
                            [
                                -73.90687214675195,
                                40.68121655481809
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "4033a65e-3978-4b2f-95de-507384e6b85e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90422851711948,
                                40.68111815582699
                            ],
                            [
                                -73.90586237987544,
                                40.68091545732047
                            ],
                            [
                                -73.90436485177591,
                                40.68126801534046
                            ],
                            [
                                -73.90422851711948,
                                40.68111815582699
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "c1abb583-a0ab-473f-8b63-4ce4bc8bf352"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90586237987544,
                                40.68091545732047
                            ],
                            [
                                -73.90422851711948,
                                40.68111815582699
                            ],
                            [
                                -73.90100726929104,
                                40.67757734936617
                            ],
                            [
                                -73.90564678272065,
                                40.68027079953307
                            ],
                            [
                                -73.9057094042791,
                                40.67863504879102
                            ],
                            [
                                -73.90078127011397,
                                40.67732893030965
                            ],
                            [
                                -73.9006669171459,
                                40.67720323314205
                            ],
                            [
                                -73.9079548870622,
                                40.675945326999
                            ],
                            [
                                -73.91587148543792,
                                40.67855904715355
                            ],
                            [
                                -73.90586237987544,
                                40.68091545732047
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "0fbc0d6b-9b46-478c-a55c-869ecf925b95"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9080139845298,
                                40.807701488988364
                            ],
                            [
                                -73.90853877942047,
                                40.809274274581036
                            ],
                            [
                                -73.90665138936413,
                                40.80888334907162
                            ],
                            [
                                -73.9080139845298,
                                40.807701488988364
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "552180cf-cc15-4ced-bd9a-6cf7ace79ced"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9078550878274,
                                40.72053548480469
                            ],
                            [
                                -73.90762367731864,
                                40.72063406808455
                            ],
                            [
                                -73.90748691690146,
                                40.7206592200806
                            ],
                            [
                                -73.9078550878274,
                                40.72053548480469
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "7ea9c67d-7a90-46ff-b99e-610426f22cb3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90719736383426,
                                40.690195552311984
                            ],
                            [
                                -73.90673858703212,
                                40.689813248307644
                            ],
                            [
                                -73.90738373820456,
                                40.69018133615631
                            ],
                            [
                                -73.90719736383426,
                                40.690195552311984
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "032ded71-fd58-4254-b094-22168903b389"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90719523213208,
                                40.68337455321525
                            ],
                            [
                                -73.90486237201793,
                                40.68204594045713
                            ],
                            [
                                -73.90687214675195,
                                40.68121655481809
                            ],
                            [
                                -73.90719523213208,
                                40.68337455321525
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "685135aa-6138-4a59-8de0-31e1f519378a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90318186319355,
                                40.689692406599406
                            ],
                            [
                                -73.90319172596857,
                                40.689681864932176
                            ],
                            [
                                -73.90319225837767,
                                40.68969373945671
                            ],
                            [
                                -73.90318186319355,
                                40.689692406599406
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "98bb4b80-f4da-404f-a100-5997b3181e99"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9030850935438,
                                40.687303601018634
                            ],
                            [
                                -73.90307747540427,
                                40.687308518311234
                            ],
                            [
                                -73.90308479831012,
                                40.68729701630853
                            ],
                            [
                                -73.9030850935438,
                                40.687303601018634
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "970faa1e-8d46-47a9-a6a6-d07075b98360"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90597957709238,
                                40.68937579059566
                            ],
                            [
                                -73.90599203313211,
                                40.68938298833132
                            ],
                            [
                                -73.90595805435649,
                                40.689396945819546
                            ],
                            [
                                -73.90597957709238,
                                40.68937579059566
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "c41bca62-65f7-4bb1-82dd-2ffffbf783b2"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90568073185186,
                                40.74539184266323
                            ],
                            [
                                -73.90469947287657,
                                40.745487212833865
                            ],
                            [
                                -73.9047274486555,
                                40.745470364460914
                            ],
                            [
                                -73.9011237410106,
                                40.7458273099432
                            ],
                            [
                                -73.90113938272388,
                                40.745833223840016
                            ],
                            [
                                -73.90021822842442,
                                40.74592275233854
                            ],
                            [
                                -73.9034031136361,
                                40.74421937920291
                            ],
                            [
                                -73.90568073185186,
                                40.74539184266323
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "d268e429-5438-4c21-8724-e628e8e60be4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90469947287657,
                                40.745487212833865
                            ],
                            [
                                -73.90113938272388,
                                40.745833223840016
                            ],
                            [
                                -73.9011237410106,
                                40.7458273099432
                            ],
                            [
                                -73.9047274486555,
                                40.745470364460914
                            ],
                            [
                                -73.90469947287657,
                                40.745487212833865
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "2abc52eb-f62f-4088-af31-d622110f6b06"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90113938272388,
                                40.745833223840016
                            ],
                            [
                                -73.90469947287657,
                                40.745487212833865
                            ],
                            [
                                -73.902973501538,
                                40.74652667661017
                            ],
                            [
                                -73.90113938272388,
                                40.745833223840016
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "1037bc49-a561-49ec-af03-64484e20cc92"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90564678272065,
                                40.68027079953307
                            ],
                            [
                                -73.90100726929104,
                                40.67757734936617
                            ],
                            [
                                -73.90078127011397,
                                40.67732893030965
                            ],
                            [
                                -73.9057094042791,
                                40.67863504879102
                            ],
                            [
                                -73.90564678272065,
                                40.68027079953307
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "e39221a8-71d1-4b3d-8ecd-a95fc04c83a9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90100726929104,
                                40.67757734936617
                            ],
                            [
                                -73.90040976002277,
                                40.67723046785309
                            ],
                            [
                                -73.90078127011397,
                                40.67732893030965
                            ],
                            [
                                -73.90100726929104,
                                40.67757734936617
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "aa94e44c-359a-4df3-ad50-9b1870d3dce8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.9079879594377,
                                40.80688085117255
                            ],
                            [
                                -73.90992360609279,
                                40.808636100511784
                            ],
                            [
                                -73.90708087659642,
                                40.810622789178616
                            ],
                            [
                                -73.90526580361539,
                                40.808588571905815
                            ],
                            [
                                -73.9079879594377,
                                40.80688085117255
                            ]
                        ],
                        [
                            [
                                -73.90665138936413,
                                40.80888334907162
                            ],
                            [
                                -73.90853877942047,
                                40.809274274581036
                            ],
                            [
                                -73.9080139845298,
                                40.807701488988364
                            ],
                            [
                                -73.90665138936413,
                                40.80888334907162
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b56323f4-f71e-4e27-8b8b-0f7943cf3093"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90424605317286,
                                40.812150083751604
                            ],
                            [
                                -73.90409316979296,
                                40.81204631458324
                            ],
                            [
                                -73.90423876514095,
                                40.81205362755285
                            ],
                            [
                                -73.90424605317286,
                                40.812150083751604
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "11742046-641f-471a-a42a-57cb22bc84e4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90401308588369,
                                40.688660620466734
                            ],
                            [
                                -73.90315971765726,
                                40.68896797127477
                            ],
                            [
                                -73.90315435273058,
                                40.688848315255115
                            ],
                            [
                                -73.90349440133359,
                                40.68789759816406
                            ],
                            [
                                -73.90401308588369,
                                40.688660620466734
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "c0c2262e-56a2-44f6-a643-7471ed97ba34"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90315971765726,
                                40.68896797127477
                            ],
                            [
                                -73.90310443278729,
                                40.68898788278417
                            ],
                            [
                                -73.90315435273058,
                                40.688848315255115
                            ],
                            [
                                -73.90315971765726,
                                40.68896797127477
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "fac74527-cd0d-4d79-b24d-3f73a7ce0981"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90362503164727,
                                40.81217906362127
                            ],
                            [
                                -73.90281047707687,
                                40.812522914411076
                            ],
                            [
                                -73.90516528393172,
                                40.81144748529313
                            ],
                            [
                                -73.90362503164727,
                                40.81217906362127
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9cbefdd4-81e2-4c20-92fc-dd25b31efe6b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90217708664814,
                                40.67047449678363
                            ],
                            [
                                -73.90205874499601,
                                40.66999454626805
                            ],
                            [
                                -73.90210185831172,
                                40.67016412509475
                            ],
                            [
                                -73.90217708664814,
                                40.67047449678363
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "d782a46f-6e00-4cb4-b91c-f15da2af3b0a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90205874499601,
                                40.66999454626805
                            ],
                            [
                                -73.90217708664814,
                                40.67047449678363
                            ],
                            [
                                -73.90210185831172,
                                40.67016412509475
                            ],
                            [
                                -73.90235596637403,
                                40.67116361553847
                            ],
                            [
                                -73.90000042739221,
                                40.66995934585973
                            ],
                            [
                                -73.90145645273732,
                                40.667625533056025
                            ],
                            [
                                -73.90205874499601,
                                40.66999454626805
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "cc9a121e-2e55-4683-ab10-0e7ed8f09845"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90205874499601,
                                40.66999454626805
                            ],
                            [
                                -73.90197371922346,
                                40.66964971279114
                            ],
                            [
                                -73.90204510526827,
                                40.6699302337819
                            ],
                            [
                                -73.90190656055965,
                                40.66937734178109
                            ],
                            [
                                -73.9019065284576,
                                40.669377211586884
                            ],
                            [
                                -73.90198208845109,
                                40.66966998717151
                            ],
                            [
                                -73.90210185831172,
                                40.67016412509475
                            ],
                            [
                                -73.90205874499601,
                                40.66999454626805
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "093b72f3-f67a-490c-9870-21a38b4a6ee5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90197371922346,
                                40.66964971279114
                            ],
                            [
                                -73.90190656055965,
                                40.66937734178109
                            ],
                            [
                                -73.90204510526827,
                                40.6699302337819
                            ],
                            [
                                -73.90197371922346,
                                40.66964971279114
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "b8295f69-052f-42d3-827d-11ba17332e71"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90190656055965,
                                40.66937734178109
                            ],
                            [
                                -73.90197371922346,
                                40.66964971279114
                            ],
                            [
                                -73.90175292103558,
                                40.66878205684252
                            ],
                            [
                                -73.9019065042031,
                                40.66937711687831
                            ],
                            [
                                -73.90190656055965,
                                40.66937734178109
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "b087e7a3-8230-4625-9fea-3e67ef776156"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90134291466856,
                                40.67552539106987
                            ],
                            [
                                -73.90476206792992,
                                40.67486193293932
                            ],
                            [
                                -73.90467938360833,
                                40.67491708530954
                            ],
                            [
                                -73.90302845559076,
                                40.675706926980766
                            ],
                            [
                                -73.90134291466856,
                                40.67552539106987
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "8b659eda-0291-4e10-8a42-cf7878752210"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90467938360833,
                                40.67491708530954
                            ],
                            [
                                -73.90476206792992,
                                40.67486193293932
                            ],
                            [
                                -73.90134291466856,
                                40.67552539106987
                            ],
                            [
                                -73.90302845559076,
                                40.675706926980766
                            ],
                            [
                                -73.90280895173751,
                                40.67581194263769
                            ],
                            [
                                -73.90064078964586,
                                40.675486303187675
                            ],
                            [
                                -73.90527192473574,
                                40.674633600100385
                            ],
                            [
                                -73.90467938360833,
                                40.67491708530954
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "feb7d342-6b9f-44ac-bf55-324dd9dc6a37"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90467938360833,
                                40.67491708530954
                            ],
                            [
                                -73.90343036043699,
                                40.67575021288465
                            ],
                            [
                                -73.90302845559076,
                                40.675706926980766
                            ],
                            [
                                -73.90467938360833,
                                40.67491708530954
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "c3896cb0-65ed-457a-b4aa-79d3e6402644"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90121997303541,
                                40.81988041030557
                            ],
                            [
                                -73.90202505127787,
                                40.81941305587676
                            ],
                            [
                                -73.90243574485609,
                                40.8192614202009
                            ],
                            [
                                -73.90210664166186,
                                40.81981570669739
                            ],
                            [
                                -73.90121997303541,
                                40.81988041030557
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ec4659a7-1ebf-47b9-928b-3c332b526264"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90038315102575,
                                40.663673981897766
                            ],
                            [
                                -73.90048632882434,
                                40.66405177906503
                            ],
                            [
                                -73.90060447704712,
                                40.664471246899524
                            ],
                            [
                                -73.90024531891844,
                                40.66383137152335
                            ],
                            [
                                -73.90038315102575,
                                40.663673981897766
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "e001b044-de4a-407f-b84a-7c927f12fe60"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.90082400014974,
                                40.665250196594414
                            ],
                            [
                                -73.89924115334777,
                                40.663979494940534
                            ],
                            [
                                -73.90018303332991,
                                40.66289347241547
                            ],
                            [
                                -73.90082400014974,
                                40.665250196594414
                            ]
                        ],
                        [
                            [
                                -73.90024531891844,
                                40.66383137152335
                            ],
                            [
                                -73.90060447704712,
                                40.664471246899524
                            ],
                            [
                                -73.90048632882434,
                                40.66405177906503
                            ],
                            [
                                -73.90038315102575,
                                40.663673981897766
                            ],
                            [
                                -73.90024531891844,
                                40.66383137152335
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "fe75d00f-7d61-4341-8d89-b8556dac1a5c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89990675197268,
                                40.77569739331701
                            ],
                            [
                                -73.9004737012064,
                                40.77523542501942
                            ],
                            [
                                -73.90062830575836,
                                40.775728668683364
                            ],
                            [
                                -73.89990675197268,
                                40.77569739331701
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6375ecf6-a210-4511-a2a4-b0d8842418e0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89922170572018,
                                40.74779682236454
                            ],
                            [
                                -73.89908359779055,
                                40.74963532734168
                            ],
                            [
                                -73.89736065516628,
                                40.74920378569362
                            ],
                            [
                                -73.89871152282592,
                                40.750607150576116
                            ],
                            [
                                -73.8990243862611,
                                40.75042355645996
                            ],
                            [
                                -73.89892360697966,
                                40.75176513917002
                            ],
                            [
                                -73.89653561635821,
                                40.74885817033564
                            ],
                            [
                                -73.89922170572018,
                                40.74779682236454
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "23b8b72f-de3d-4223-ba1c-1e1d5218a93e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89908359779055,
                                40.74963532734168
                            ],
                            [
                                -73.8990243862611,
                                40.75042355645996
                            ],
                            [
                                -73.89871152282592,
                                40.750607150576116
                            ],
                            [
                                -73.89736065516628,
                                40.74920378569362
                            ],
                            [
                                -73.89908359779055,
                                40.74963532734168
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9d38a40f-68c6-4b6f-b21f-861b1af938a8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8990243862611,
                                40.75042355645996
                            ],
                            [
                                -73.89908359779055,
                                40.74963532734168
                            ],
                            [
                                -73.89936128864396,
                                40.74970487995872
                            ],
                            [
                                -73.89953258299562,
                                40.750125337109864
                            ],
                            [
                                -73.8990243862611,
                                40.75042355645996
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "1bb521bb-2fa4-49db-918c-5e70a05154f9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8990984568632,
                                40.65865311621516
                            ],
                            [
                                -73.89923191148011,
                                40.65873301331057
                            ],
                            [
                                -73.8991424788037,
                                40.65882352548321
                            ],
                            [
                                -73.8990984568632,
                                40.65865311621516
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "fb818285-740c-49cb-9869-ad19dfe8317c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89897777449299,
                                40.77418049622514
                            ],
                            [
                                -73.89848785938817,
                                40.77416449932915
                            ],
                            [
                                -73.89847975209273,
                                40.77410633903191
                            ],
                            [
                                -73.89897777449299,
                                40.77418049622514
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ef41c228-688d-497b-b24f-f302998e15e9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89762948859148,
                                40.705523573850286
                            ],
                            [
                                -73.89432102214614,
                                40.705854146682356
                            ],
                            [
                                -73.89502460064298,
                                40.70467420512396
                            ],
                            [
                                -73.89762948859148,
                                40.705523573850286
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "4d20550f-88fe-446d-93ae-484d628baba0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89724527644951,
                                40.76664822544317
                            ],
                            [
                                -73.89594919883568,
                                40.767250941614286
                            ],
                            [
                                -73.89462183739208,
                                40.766194865497056
                            ],
                            [
                                -73.89724527644951,
                                40.76664822544317
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "423a1ba3-cd5d-47f4-89cc-c7370dfb6b29"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89697440744189,
                                40.677233680071254
                            ],
                            [
                                -73.89968712197056,
                                40.67696593383257
                            ],
                            [
                                -73.8979798099927,
                                40.67777854020317
                            ],
                            [
                                -73.89697440744189,
                                40.677233680071254
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "a01ed677-7203-4833-bc6f-152e85714a23"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89685655656145,
                                40.67434641257878
                            ],
                            [
                                -73.89662928279456,
                                40.674863052997516
                            ],
                            [
                                -73.89616237174754,
                                40.67444985244427
                            ],
                            [
                                -73.8964506042913,
                                40.674169076599426
                            ],
                            [
                                -73.89685655656145,
                                40.67434641257878
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "46c0ac33-85e0-41e9-94f6-d872552f3029"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.896435206269,
                                40.81610418310974
                            ],
                            [
                                -73.89661201897144,
                                40.81593338558258
                            ],
                            [
                                -73.89622185549072,
                                40.81631044678346
                            ],
                            [
                                -73.896435206269,
                                40.81610418310974
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3b0a23ed-9af1-4557-98a2-3a7f2cd857a5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89182108077904,
                                40.74933950367607
                            ],
                            [
                                -73.88824943490492,
                                40.745327482963994
                            ],
                            [
                                -73.89083859425111,
                                40.74377352026575
                            ],
                            [
                                -73.89640224823373,
                                40.74632459400667
                            ],
                            [
                                -73.89595909018527,
                                40.74637129328335
                            ],
                            [
                                -73.89640232995225,
                                40.74632463147665
                            ],
                            [
                                -73.89640298729302,
                                40.74632493288374
                            ],
                            [
                                -73.89182108077904,
                                40.74933950367607
                            ]
                        ],
                        [
                            [
                                -73.8950218338319,
                                40.7464721597082
                            ],
                            [
                                -73.89222075652029,
                                40.74583189429563
                            ],
                            [
                                -73.89232435383212,
                                40.74675264581688
                            ],
                            [
                                -73.89179529913038,
                                40.74899550871461
                            ],
                            [
                                -73.8950218338319,
                                40.7464721597082
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "895e6f9d-71dd-4ead-bece-2820814c07cc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89640224823373,
                                40.74632459400667
                            ],
                            [
                                -73.89640232995225,
                                40.74632463147665
                            ],
                            [
                                -73.89595909018527,
                                40.74637129328335
                            ],
                            [
                                -73.89640224823373,
                                40.74632459400667
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "bfb9e92e-a1d3-4741-9dcb-dc1c31a0a0f0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89640232995225,
                                40.74632463147665
                            ],
                            [
                                -73.89640224823373,
                                40.74632459400667
                            ],
                            [
                                -73.89698988721086,
                                40.74626266956396
                            ],
                            [
                                -73.89640298369977,
                                40.74632456265376
                            ],
                            [
                                -73.89640232995225,
                                40.74632463147665
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c6141613-d2c6-4f11-838a-e29757f00519"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89449143075892,
                                40.72632194359291
                            ],
                            [
                                -73.89434217567445,
                                40.726618159480985
                            ],
                            [
                                -73.89408234785999,
                                40.72657255118239
                            ],
                            [
                                -73.89449143075892,
                                40.72632194359291
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "925cd02c-995d-4db9-8fc1-3414cc46c8dc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89268373923457,
                                40.71229772114416
                            ],
                            [
                                -73.89079617400677,
                                40.70938933464977
                            ],
                            [
                                -73.8915422940205,
                                40.709446370120084
                            ],
                            [
                                -73.89268373923457,
                                40.71229772114416
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "05666252-54d3-4af7-8fb2-1680817f60b7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89179529913038,
                                40.74899550871461
                            ],
                            [
                                -73.89232435383212,
                                40.74675264581688
                            ],
                            [
                                -73.89222075652029,
                                40.74583189429563
                            ],
                            [
                                -73.8950218338319,
                                40.7464721597082
                            ],
                            [
                                -73.89179529913038,
                                40.74899550871461
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "d874b80a-9f0a-4745-a49d-4712ba047881"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.89158173904463,
                                40.6776731652399
                            ],
                            [
                                -73.89223130902995,
                                40.67795021749909
                            ],
                            [
                                -73.89114508774794,
                                40.67810953888813
                            ],
                            [
                                -73.89158173904463,
                                40.6776731652399
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "fcea7d28-a598-44f9-bb79-cf1ab2b33af6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88966190184023,
                                40.71185855215405
                            ],
                            [
                                -73.88985420419309,
                                40.71146821632644
                            ],
                            [
                                -73.89007085807668,
                                40.71103220206536
                            ],
                            [
                                -73.88966190184023,
                                40.71185855215405
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "47745a07-ed2a-40e7-b55f-ada60d8a0c5d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88811074310989,
                                40.67304305556965
                            ],
                            [
                                -73.89090793790284,
                                40.671393085827866
                            ],
                            [
                                -73.89260451950463,
                                40.67237777267403
                            ],
                            [
                                -73.89063898320508,
                                40.67398293285992
                            ],
                            [
                                -73.88811074310989,
                                40.67304305556965
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "8c6b4a19-0271-485f-9355-cdafadfe8729"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88731281276215,
                                40.69707267321447
                            ],
                            [
                                -73.88397988339501,
                                40.69556601514997
                            ],
                            [
                                -73.88170818238203,
                                40.69176587290744
                            ],
                            [
                                -73.88612894730372,
                                40.69333497136131
                            ],
                            [
                                -73.88731281276215,
                                40.69707267321447
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "c38b8bb8-b1fd-4961-acf3-0def0d1c91c7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88659303764074,
                                40.73047216189115
                            ],
                            [
                                -73.88750950697843,
                                40.73021136477479
                            ],
                            [
                                -73.88624280607104,
                                40.73062444351174
                            ],
                            [
                                -73.88659303764074,
                                40.73047216189115
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "ab2476cb-bab5-4a65-beb8-ee0e85da047e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88628338746399,
                                40.75547484217204
                            ],
                            [
                                -73.88613270226159,
                                40.75465697351346
                            ],
                            [
                                -73.88670642299591,
                                40.755045333752975
                            ],
                            [
                                -73.88628338746399,
                                40.75547484217204
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c749bb46-f595-4bb6-8f85-ae95055c1a29"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88594201687623,
                                40.753954992842864
                            ],
                            [
                                -73.88590741197487,
                                40.75375956758084
                            ],
                            [
                                -73.88613389163103,
                                40.75503619421786
                            ],
                            [
                                -73.88594201687623,
                                40.753954992842864
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "b8d197c7-0a68-4144-857b-455a28156087"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88534377752457,
                                40.73101677931419
                            ],
                            [
                                -73.88518837437032,
                                40.731157338285506
                            ],
                            [
                                -73.88513116156818,
                                40.731238971459284
                            ],
                            [
                                -73.88516065146233,
                                40.73110193269247
                            ],
                            [
                                -73.88534377752457,
                                40.73101677931419
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "17892ba1-fe96-4b0b-9afd-54170a3b63d7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8847046953702,
                                40.74380488143794
                            ],
                            [
                                -73.88097701123293,
                                40.74195896756837
                            ],
                            [
                                -73.87957015158163,
                                40.74350883394242
                            ],
                            [
                                -73.87924011952938,
                                40.740671664682665
                            ],
                            [
                                -73.8847046953702,
                                40.74380488143794
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "639e6509-34f1-4867-9ad1-ec7b8f198ec0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88456173298431,
                                40.650183005721345
                            ],
                            [
                                -73.88473692512596,
                                40.65038103738909
                            ],
                            [
                                -73.8848216272741,
                                40.650484592804965
                            ],
                            [
                                -73.88456173298431,
                                40.650183005721345
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f86d23ae-0121-4af2-ad39-9f80866c998c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88435597836005,
                                40.75556893426736
                            ],
                            [
                                -73.8843386130273,
                                40.75556141637684
                            ],
                            [
                                -73.8844184876291,
                                40.755546484025544
                            ],
                            [
                                -73.88435597836005,
                                40.75556893426736
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "566d2412-a826-4e74-b7e1-6367ce375e85"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88326355510934,
                                40.76719449860761
                            ],
                            [
                                -73.8818321067721,
                                40.767440651704995
                            ],
                            [
                                -73.88269468797253,
                                40.76689521536481
                            ],
                            [
                                -73.88326355510934,
                                40.76719449860761
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f02b341a-8c38-46ff-a7f8-f435bbc4971a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88320210944559,
                                40.70174806775483
                            ],
                            [
                                -73.88357565678851,
                                40.701718521444405
                            ],
                            [
                                -73.88298999025756,
                                40.702182715383
                            ],
                            [
                                -73.88320210944559,
                                40.70174806775483
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "eed6a820-68cb-4e07-86da-15a0f6c1eab1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.882396241584,
                                40.64846000386027
                            ],
                            [
                                -73.88194791757853,
                                40.64814950559569
                            ],
                            [
                                -73.88082108256422,
                                40.648136669520724
                            ],
                            [
                                -73.88102586610965,
                                40.64708100789704
                            ],
                            [
                                -73.88217230865165,
                                40.64810853211058
                            ],
                            [
                                -73.88238244885112,
                                40.64797784359156
                            ],
                            [
                                -73.8825804168785,
                                40.64789513302015
                            ],
                            [
                                -73.882938588657,
                                40.648100022659655
                            ],
                            [
                                -73.882396241584,
                                40.64846000386027
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "84745c16-f8da-481c-b93c-01424b9a23c8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88184885855551,
                                40.673971120313226
                            ],
                            [
                                -73.87992355689923,
                                40.67442149176259
                            ],
                            [
                                -73.87963205448786,
                                40.67428997045101
                            ],
                            [
                                -73.88060571057653,
                                40.67332708876042
                            ],
                            [
                                -73.88184885855551,
                                40.673971120313226
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "ddc5fb43-f95f-48e0-a20c-227e92685f8a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88107066060128,
                                40.70187037919324
                            ],
                            [
                                -73.88111001220064,
                                40.70186306771338
                            ],
                            [
                                -73.88115602031621,
                                40.70186177512865
                            ],
                            [
                                -73.88107066060128,
                                40.70187037919324
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "91cae5ce-3442-4e8b-abfb-33513e4e4981"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.88074229133065,
                                40.76754873396329
                            ],
                            [
                                -73.88047258294638,
                                40.76757829731705
                            ],
                            [
                                -73.88090822543498,
                                40.76752254038408
                            ],
                            [
                                -73.88074229133065,
                                40.76754873396329
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d58d274c-b26f-451a-9c42-f967036ce4d9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87902148036903,
                                40.646686678796314
                            ],
                            [
                                -73.88003148588736,
                                40.64547626285421
                            ],
                            [
                                -73.881332930935,
                                40.64563116736228
                            ],
                            [
                                -73.87902148036903,
                                40.646686678796314
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "e0605d5d-8a0f-4dd7-af2f-5102cdcdd787"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87889169716667,
                                40.6557710345319
                            ],
                            [
                                -73.87660495655919,
                                40.65684709590617
                            ],
                            [
                                -73.8764939256884,
                                40.656694018601286
                            ],
                            [
                                -73.87889169716667,
                                40.6557710345319
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "389ce624-a67b-4c5b-a48c-1bd3abf7f330"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8788180550763,
                                40.75643592887955
                            ],
                            [
                                -73.87886102329298,
                                40.75665696507132
                            ],
                            [
                                -73.8786416547006,
                                40.75639349498552
                            ],
                            [
                                -73.8788180550763,
                                40.75643592887955
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3021cbd0-6377-4b0a-8469-790306419f8e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87859553788886,
                                40.755223965785866
                            ],
                            [
                                -73.87776076583467,
                                40.754606114079415
                            ],
                            [
                                -73.8784700459386,
                                40.75453125455615
                            ],
                            [
                                -73.87859553788886,
                                40.755223965785866
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6d832878-e275-4a06-b741-b454d4e4e30b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87858547136777,
                                40.748196393914384
                            ],
                            [
                                -73.8767949170041,
                                40.750097348908774
                            ],
                            [
                                -73.87450154323659,
                                40.748628171963766
                            ],
                            [
                                -73.8768854231673,
                                40.747369352301085
                            ],
                            [
                                -73.87858547136777,
                                40.748196393914384
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "0f8772ca-291f-4a31-8d97-6351ddac62c3"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8783649214908,
                                40.657998033838744
                            ],
                            [
                                -73.87763965586637,
                                40.6584192406896
                            ],
                            [
                                -73.87743597814706,
                                40.658398966314124
                            ],
                            [
                                -73.87757895580431,
                                40.65833694103866
                            ],
                            [
                                -73.8783649214908,
                                40.657998033838744
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "08755d2b-22b3-49ac-812e-1125cb66f2dc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87831930504066,
                                40.64285180279198
                            ],
                            [
                                -73.87818136603384,
                                40.64304897896259
                            ],
                            [
                                -73.87828303376443,
                                40.64287010449063
                            ],
                            [
                                -73.87831930504066,
                                40.64285180279198
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "48dbc3f2-0fe1-440e-a3fb-fffcda39c020"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87831845613272,
                                40.64465421927906
                            ],
                            [
                                -73.87800806843912,
                                40.64437995621489
                            ],
                            [
                                -73.8776853774194,
                                40.64408824813551
                            ],
                            [
                                -73.87796616831933,
                                40.64433835605847
                            ],
                            [
                                -73.87831845613272,
                                40.64465421927906
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ec196fb6-40f9-4bd0-8cd6-ad94122a71fd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87776474267642,
                                40.65430211717695
                            ],
                            [
                                -73.8728603858912,
                                40.65313997054669
                            ],
                            [
                                -73.87583461616877,
                                40.65190576681251
                            ],
                            [
                                -73.87589351331205,
                                40.651960783032095
                            ],
                            [
                                -73.87776474267642,
                                40.65430211717695
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "40677847-3e2f-4534-b5b4-d0879f47083a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87375999654596,
                                40.772438267737904
                            ],
                            [
                                -73.8715891718478,
                                40.77208936483497
                            ],
                            [
                                -73.86972376356647,
                                40.771662361866085
                            ],
                            [
                                -73.86864616700952,
                                40.77109079861932
                            ],
                            [
                                -73.87608086504939,
                                40.76793501526009
                            ],
                            [
                                -73.87624898026324,
                                40.771800967493455
                            ],
                            [
                                -73.880210809381,
                                40.77053173205477
                            ],
                            [
                                -73.88039647048898,
                                40.77069875838722
                            ],
                            [
                                -73.88047307971472,
                                40.770779468819896
                            ],
                            [
                                -73.88056198957166,
                                40.770975904660965
                            ],
                            [
                                -73.87240528589354,
                                40.77447282683112
                            ],
                            [
                                -73.87048888923667,
                                40.77228280142462
                            ],
                            [
                                -73.87284655722571,
                                40.7728935477489
                            ],
                            [
                                -73.87609644183641,
                                40.77209365212377
                            ],
                            [
                                -73.87375999654596,
                                40.772438267737904
                            ]
                        ],
                        [
                            [
                                -73.87587356622116,
                                40.771251725206106
                            ],
                            [
                                -73.87458531383177,
                                40.772208984986904
                            ],
                            [
                                -73.8758363750701,
                                40.772016892468656
                            ],
                            [
                                -73.87587356622116,
                                40.771251725206106
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c53588b8-fc17-43ef-b24b-7751499db14a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87598098227127,
                                40.762966445636735
                            ],
                            [
                                -73.87420042705504,
                                40.76269077304936
                            ],
                            [
                                -73.87413397777514,
                                40.76264958080059
                            ],
                            [
                                -73.87503455950875,
                                40.76205212804714
                            ],
                            [
                                -73.87598098227127,
                                40.762966445636735
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "90e6e666-26d3-4c8e-bb25-e3c31b1c294c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8758363750701,
                                40.772016892468656
                            ],
                            [
                                -73.87458531383177,
                                40.772208984986904
                            ],
                            [
                                -73.87587356622116,
                                40.771251725206106
                            ],
                            [
                                -73.8758363750701,
                                40.772016892468656
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9692bc78-0158-4dd5-a242-31a8bdcc2897"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87557602174118,
                                40.73705797540888
                            ],
                            [
                                -73.87545507796122,
                                40.73618315972266
                            ],
                            [
                                -73.87948695590822,
                                40.737445503205514
                            ],
                            [
                                -73.87734722998688,
                                40.73867149927686
                            ],
                            [
                                -73.87557602174118,
                                40.73705797540888
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "49db7758-7070-4452-b0a0-a7abd64560e8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87479926468468,
                                40.65770728960492
                            ],
                            [
                                -73.87401825230867,
                                40.65786563987725
                            ],
                            [
                                -73.87483012540795,
                                40.6575315980782
                            ],
                            [
                                -73.87479926468468,
                                40.65770728960492
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8692b695-058c-4cfa-9dd8-1eeaa8260370"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87445410500307,
                                40.75897376059976
                            ],
                            [
                                -73.87448791130215,
                                40.75878501025985
                            ],
                            [
                                -73.87448389134126,
                                40.758979732144056
                            ],
                            [
                                -73.87445410500307,
                                40.75897376059976
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "d97a51fb-2b83-437d-8da8-f768e4b0a5f7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8738491237952,
                                40.71973533908304
                            ],
                            [
                                -73.87226005346493,
                                40.72051110823173
                            ],
                            [
                                -73.8722064267374,
                                40.72049541432736
                            ],
                            [
                                -73.8738491237952,
                                40.71973533908304
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "7663b470-c1eb-4775-acaf-01fa9a61108e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87330956239788,
                                40.762510749241855
                            ],
                            [
                                -73.87324811224255,
                                40.76217488117368
                            ],
                            [
                                -73.87389921655037,
                                40.76260901177559
                            ],
                            [
                                -73.87330956239788,
                                40.762510749241855
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6994820e-a07a-41e4-9ab6-a1b6fe637411"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87322551063001,
                                40.65833309550648
                            ],
                            [
                                -73.87301204396904,
                                40.658305059164604
                            ],
                            [
                                -73.87324720943569,
                                40.6583046468754
                            ],
                            [
                                -73.87322551063001,
                                40.65833309550648
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8080218e-ee32-472d-9244-961cf9fe764a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87274966763411,
                                40.68349069730986
                            ],
                            [
                                -73.87474048252574,
                                40.68292438530454
                            ],
                            [
                                -73.87422641082463,
                                40.68359145331967
                            ],
                            [
                                -73.87274966763411,
                                40.68349069730986
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "e8f4f555-0b09-40f6-ab95-9990aa4ea7b4"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87126306453504,
                                40.72094154433744
                            ],
                            [
                                -73.87110365040304,
                                40.72101539659679
                            ],
                            [
                                -73.87138218742993,
                                40.720886316105464
                            ],
                            [
                                -73.87126306453504,
                                40.72094154433744
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "d3e58b60-4b3c-41ca-a6e3-c7a876386d32"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8711563563574,
                                40.675517471028655
                            ],
                            [
                                -73.87257416467537,
                                40.67488801993446
                            ],
                            [
                                -73.8732256516655,
                                40.675224108632946
                            ],
                            [
                                -73.87202152919743,
                                40.675866166558265
                            ],
                            [
                                -73.8711563563574,
                                40.675517471028655
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 1500
                        }
                    },
                    "id": "6b3c08d5-09d3-4869-829d-115d297b666a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.87241018350848,
                                40.67423626849226
                            ],
                            [
                                -73.87408752858676,
                                40.67509781446202
                            ],
                            [
                                -73.87218260790765,
                                40.67649485355499
                            ],
                            [
                                -73.87051658339857,
                                40.675612895029
                            ],
                            [
                                -73.87241018350848,
                                40.67423626849226
                            ]
                        ],
                        [
                            [
                                -73.87202152919743,
                                40.675866166558265
                            ],
                            [
                                -73.8732256516655,
                                40.675224108632946
                            ],
                            [
                                -73.87257416467537,
                                40.67488801993446
                            ],
                            [
                                -73.8711563563574,
                                40.675517471028655
                            ],
                            [
                                -73.87202152919743,
                                40.675866166558265
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "1c58e880-16f1-40ba-b122-3dc7f7458d5f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86976720789025,
                                40.66394270278169
                            ],
                            [
                                -73.86945607818822,
                                40.6637269666567
                            ],
                            [
                                -73.86943712822729,
                                40.66350125934571
                            ],
                            [
                                -73.86976720789025,
                                40.66394270278169
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "343858fa-48e8-4a1f-b63c-6df042857df5"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86867593807635,
                                40.76098451853377
                            ],
                            [
                                -73.86637522507296,
                                40.7610212462221
                            ],
                            [
                                -73.86443911488718,
                                40.75972013619843
                            ],
                            [
                                -73.86754484684424,
                                40.75829550576082
                            ],
                            [
                                -73.86867593807635,
                                40.76098451853377
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "ea6dd013-be78-4006-b4b6-96ddf58a6e4e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86854083235593,
                                40.733003920159966
                            ],
                            [
                                -73.8696528667276,
                                40.733464455055035
                            ],
                            [
                                -73.8689686433319,
                                40.733426944993475
                            ],
                            [
                                -73.86854083235593,
                                40.733003920159966
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "898d25a2-f895-4cdf-9a93-24bcf6bb3e92"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8681903869872,
                                40.7492957504506
                            ],
                            [
                                -73.8699927227558,
                                40.748613745062386
                            ],
                            [
                                -73.87088116106338,
                                40.74900565219957
                            ],
                            [
                                -73.8681903869872,
                                40.7492957504506
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9e8f47a7-75f5-4703-9511-47922ac2307d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86556051778896,
                                40.736722087661796
                            ],
                            [
                                -73.86561403222706,
                                40.73667506369111
                            ],
                            [
                                -73.86568827169708,
                                40.73668464682304
                            ],
                            [
                                -73.86556051778896,
                                40.736722087661796
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "84702cce-1dfb-4c9a-98c1-8e0c1723d418"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86506289603732,
                                40.6770788421232
                            ],
                            [
                                -73.865050087858,
                                40.6770439865715
                            ],
                            [
                                -73.86502770184113,
                                40.67694396939058
                            ],
                            [
                                -73.86506289603732,
                                40.6770788421232
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": "place_1",
                            "bucket_in_s": 2250
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "a23b9a6f-521a-4619-a3bd-bf4079370699"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8650104155601,
                                40.772670000138824
                            ],
                            [
                                -73.86283436087038,
                                40.771453850466095
                            ],
                            [
                                -73.86193126924388,
                                40.7679318734101
                            ],
                            [
                                -73.86358179422105,
                                40.7678786721654
                            ],
                            [
                                -73.86504703268773,
                                40.769429554960226
                            ],
                            [
                                -73.86620858938439,
                                40.77163749447334
                            ],
                            [
                                -73.86589206799401,
                                40.77193554404986
                            ],
                            [
                                -73.8650104155601,
                                40.772670000138824
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8b3c1311-bc87-4cf9-9a99-2dd2063275fb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86270881681783,
                                40.66616199328629
                            ],
                            [
                                -73.86208988387529,
                                40.6664317855067
                            ],
                            [
                                -73.86178688841815,
                                40.66629166567067
                            ],
                            [
                                -73.86270881681783,
                                40.66616199328629
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "45645e26-8228-4b3a-aa05-14a5242178c8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86270109040807,
                                40.74987079764214
                            ],
                            [
                                -73.86274980514762,
                                40.749865486077745
                            ],
                            [
                                -73.86255901196442,
                                40.749887082126904
                            ],
                            [
                                -73.86270109040807,
                                40.74987079764214
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "34e87a7c-afe3-417c-9e51-a268452ce5e7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.86169560341561,
                                40.72982108141578
                            ],
                            [
                                -73.86160403903702,
                                40.72984593858402
                            ],
                            [
                                -73.86154785839915,
                                40.729767690378964
                            ],
                            [
                                -73.86169560341561,
                                40.72982108141578
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "96af8669-7a64-49ca-829e-0092f3634ae8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8608336752904,
                                40.692324978435934
                            ],
                            [
                                -73.8600065550858,
                                40.692417640268715
                            ],
                            [
                                -73.85917926240468,
                                40.69251022364379
                            ],
                            [
                                -73.8608336752904,
                                40.692324978435934
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "58137e6c-d7a2-4872-a216-80e37440dc3f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.85647993367205,
                                40.704630553955354
                            ],
                            [
                                -73.85638706044809,
                                40.704604949850896
                            ],
                            [
                                -73.85629731605624,
                                40.70450296168222
                            ],
                            [
                                -73.85647993367205,
                                40.704630553955354
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "85d14bd5-4c5e-4509-8c65-753f36737fd6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.85337358097912,
                                40.701056673164906
                            ],
                            [
                                -73.85520108664369,
                                40.70024771971703
                            ],
                            [
                                -73.85502861100746,
                                40.70124500844936
                            ],
                            [
                                -73.85337358097912,
                                40.701056673164906
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "304e5257-79ed-442b-89e6-494afb1b5595"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8512959672715,
                                40.736227651937014
                            ],
                            [
                                -73.85071778370686,
                                40.73535417376335
                            ],
                            [
                                -73.85159454391574,
                                40.735574202517974
                            ],
                            [
                                -73.8512959672715,
                                40.736227651937014
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c3d60f4b-c60b-4064-8452-0588ccb0dee0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84998440270216,
                                40.694368114596145
                            ],
                            [
                                -73.85276047361118,
                                40.69354648212175
                            ],
                            [
                                -73.85222313734084,
                                40.694680433518144
                            ],
                            [
                                -73.84998440270216,
                                40.694368114596145
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "bdeefe7f-1d66-43b2-8a1b-77e461407c9c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84943342910748,
                                40.662418632765394
                            ],
                            [
                                -73.84817348712427,
                                40.661562702918104
                            ],
                            [
                                -73.84795816184898,
                                40.66148603445456
                            ],
                            [
                                -73.84886905175044,
                                40.660402665095646
                            ],
                            [
                                -73.85017472876142,
                                40.66115636450873
                            ],
                            [
                                -73.84943342910748,
                                40.662418632765394
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "3bb461df-8d7a-4232-8810-dc49d36e1c3c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84937544555083,
                                40.67999168319881
                            ],
                            [
                                -73.85090985339606,
                                40.67843408299635
                            ],
                            [
                                -73.85364847533755,
                                40.679725136820025
                            ],
                            [
                                -73.85108031590775,
                                40.68075175700225
                            ],
                            [
                                -73.84937544555083,
                                40.67999168319881
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "663d5311-489d-4742-bb87-d154e10e54c1"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84803436323959,
                                40.731821830131274
                            ],
                            [
                                -73.8494951262995,
                                40.73138436717683
                            ],
                            [
                                -73.84908505794873,
                                40.73218960142366
                            ],
                            [
                                -73.84803436323959,
                                40.731821830131274
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9a8c687d-b1e0-4fe6-9c0f-e5102404c853"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84433856570163,
                                40.70933932379227
                            ],
                            [
                                -73.84432461217033,
                                40.70941598557381
                            ],
                            [
                                -73.84408894095216,
                                40.709495851120764
                            ],
                            [
                                -73.84366661239439,
                                40.70931066063489
                            ],
                            [
                                -73.84382345015815,
                                40.70933584346609
                            ],
                            [
                                -73.84365740106946,
                                40.70920273974968
                            ],
                            [
                                -73.84384197072437,
                                40.70926063541837
                            ],
                            [
                                -73.84406994338055,
                                40.70898814700105
                            ],
                            [
                                -73.84412299967785,
                                40.70897282967042
                            ],
                            [
                                -73.84433856570163,
                                40.70933932379227
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "fd5408df-8905-4760-a348-95227ae7109b"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84433019879307,
                                40.69519142468628
                            ],
                            [
                                -73.84483221420477,
                                40.695185842449284
                            ],
                            [
                                -73.84371636558639,
                                40.695198356848664
                            ],
                            [
                                -73.84433019879307,
                                40.69519142468628
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "72abfff3-1399-4ca0-b131-6f63ffd1061d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84410920694498,
                                40.66203783418132
                            ],
                            [
                                -73.84422201648005,
                                40.6619810668133
                            ],
                            [
                                -73.84434622473775,
                                40.662003565656974
                            ],
                            [
                                -73.84410920694498,
                                40.66203783418132
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6ad7be51-3a7a-41f7-90f1-0b7d2efe72bb"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84313815147905,
                                40.68046944628839
                            ],
                            [
                                -73.84369836604304,
                                40.67991965206436
                            ],
                            [
                                -73.8444922943962,
                                40.68031047797437
                            ],
                            [
                                -73.8437905215131,
                                40.680544965471825
                            ],
                            [
                                -73.84313815147905,
                                40.68046944628839
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "0076e151-2634-40f3-aaa8-fa93882af3c8"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84240856226317,
                                40.720679806406636
                            ],
                            [
                                -73.84667130723818,
                                40.722571639920595
                            ],
                            [
                                -73.84572007143933,
                                40.72244669873533
                            ],
                            [
                                -73.84241414872591,
                                40.72075091234468
                            ],
                            [
                                -73.84240856226317,
                                40.720679806406636
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "3632fa44-8a07-4d1e-bd1e-b8b62e77b9c0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.84572007143933,
                                40.72244669873533
                            ],
                            [
                                -73.84251429756536,
                                40.72202563259381
                            ],
                            [
                                -73.84241414872591,
                                40.72075091234468
                            ],
                            [
                                -73.84572007143933,
                                40.72244669873533
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "582ae301-f2c3-452d-9e26-d1b2d9efc29f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83777990023526,
                                40.71848629848468
                            ],
                            [
                                -73.8369797070313,
                                40.7181348036342
                            ],
                            [
                                -73.8377256249241,
                                40.71839803787969
                            ],
                            [
                                -73.83777990023526,
                                40.71848629848468
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "0dd3267f-03ed-49bf-9cdb-cbc9ffb38bdc"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83638951209302,
                                40.7077497627464
                            ],
                            [
                                -73.8366037674748,
                                40.708533344577816
                            ],
                            [
                                -73.8364923844644,
                                40.70859538580642
                            ],
                            [
                                -73.83638951209302,
                                40.7077497627464
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "509d1624-955b-42b2-8162-1f151cd5970d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83483541407507,
                                40.70953891757247
                            ],
                            [
                                -73.83486192425744,
                                40.70955403729855
                            ],
                            [
                                -73.8348707277472,
                                40.70958022579453
                            ],
                            [
                                -73.83483541407507,
                                40.70953891757247
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c422cf39-ae94-40dd-a8c9-aa3ba3451542"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8346883466326,
                                40.669016696408896
                            ],
                            [
                                -73.83477620702016,
                                40.66949365596311
                            ],
                            [
                                -73.83446227546561,
                                40.669467836729325
                            ],
                            [
                                -73.83469184960528,
                                40.66972790679565
                            ],
                            [
                                -73.83460954886655,
                                40.669867930336004
                            ],
                            [
                                -73.83435648985775,
                                40.669672995347845
                            ],
                            [
                                -73.83312057241851,
                                40.666803910211506
                            ],
                            [
                                -73.8346883466326,
                                40.669016696408896
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "32910b2b-c0a2-44d1-9f98-e80af44ae137"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83477620702016,
                                40.66949365596311
                            ],
                            [
                                -73.8346883466326,
                                40.669016696408896
                            ],
                            [
                                -73.83491874180082,
                                40.66934188050976
                            ],
                            [
                                -73.83482707474474,
                                40.66949783956828
                            ],
                            [
                                -73.83477620702016,
                                40.66949365596311
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "80db5a0b-e5e6-4aea-9e0d-59ad5159d4f9"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83477620702016,
                                40.66949365596311
                            ],
                            [
                                -73.8347889319144,
                                40.66956273441884
                            ],
                            [
                                -73.83469184960528,
                                40.66972790679565
                            ],
                            [
                                -73.83446227546561,
                                40.669467836729325
                            ],
                            [
                                -73.83477620702016,
                                40.66949365596311
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "7bedb8c8-d50e-4b38-b787-cccec090d887"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8347889319144,
                                40.66956273441884
                            ],
                            [
                                -73.83477620702016,
                                40.66949365596311
                            ],
                            [
                                -73.83482707474474,
                                40.66949783956828
                            ],
                            [
                                -73.8347889319144,
                                40.66956273441884
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "27acf308-66aa-4e80-9726-8ded1911e88a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8347889319144,
                                40.66956273441884
                            ],
                            [
                                -73.83485298347456,
                                40.66991044519535
                            ],
                            [
                                -73.83469184960528,
                                40.66972790679565
                            ],
                            [
                                -73.8347889319144,
                                40.66956273441884
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a0619e07-3400-4f4a-a076-d50ca9cb883c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83226175156585,
                                40.68425602758067
                            ],
                            [
                                -73.83204557917925,
                                40.684338906968215
                            ],
                            [
                                -73.83205451112812,
                                40.68429440868049
                            ],
                            [
                                -73.83226175156585,
                                40.68425602758067
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "463356c4-5eb2-402b-8bb6-8bc435d00d25"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83198608914789,
                                40.72782711144745
                            ],
                            [
                                -73.83084671544884,
                                40.727988725498584
                            ],
                            [
                                -73.83126828313358,
                                40.72758205815097
                            ],
                            [
                                -73.83198608914789,
                                40.72782711144745
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "287befd7-cdd5-45c9-bd02-1a015dc92b0a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83186691055745,
                                40.70604699829291
                            ],
                            [
                                -73.83185952910077,
                                40.70572913360096
                            ],
                            [
                                -73.8320405207659,
                                40.706160348984604
                            ],
                            [
                                -73.83186691055745,
                                40.70604699829291
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "2465a541-c4c6-4c31-88a9-a08b8f60d1dd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83146074808153,
                                40.71466965304488
                            ],
                            [
                                -73.83056442705733,
                                40.714264148315955
                            ],
                            [
                                -73.83056413061328,
                                40.71423361276602
                            ],
                            [
                                -73.8312152519891,
                                40.714497644786086
                            ],
                            [
                                -73.83146074808153,
                                40.71466965304488
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "5754a728-9c22-4018-bdee-e48866932d2a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83046388489535,
                                40.66045603386982
                            ],
                            [
                                -73.83068289925498,
                                40.66086551471049
                            ],
                            [
                                -73.83030108952505,
                                40.660476005251546
                            ],
                            [
                                -73.83046388489535,
                                40.66045603386982
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a0dd549d-b111-401f-88b0-26a136c5272e"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83062462496115,
                                40.66059852464583
                            ],
                            [
                                -73.83056467474965,
                                40.66044366918985
                            ],
                            [
                                -73.83057612312234,
                                40.66044226472841
                            ],
                            [
                                -73.83055190343751,
                                40.660410680025755
                            ],
                            [
                                -73.83047626729366,
                                40.6602153067443
                            ],
                            [
                                -73.83079124358173,
                                40.66048271137695
                            ],
                            [
                                -73.83062462496115,
                                40.66059852464583
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "9f06dd2b-67c3-4eca-96f5-aa36ae76dd2c"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.83056467474965,
                                40.66044366918985
                            ],
                            [
                                -73.83055190343751,
                                40.660410680025755
                            ],
                            [
                                -73.83057612312234,
                                40.66044226472841
                            ],
                            [
                                -73.83056467474965,
                                40.66044366918985
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "776e6417-d5af-4037-8938-a6c254a3de5d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.82947652502745,
                                40.701220306090796
                            ],
                            [
                                -73.82650351645866,
                                40.70079234199514
                            ],
                            [
                                -73.83025079554724,
                                40.700158807014134
                            ],
                            [
                                -73.82947652502745,
                                40.701220306090796
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": "place_2",
                            "bucket_in_s": 2250
                        }
                    },
                    "id": "139a5a85-cf85-451f-9309-1b960fce2a83"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8291569664331,
                                40.72830693730759
                            ],
                            [
                                -73.8290834456153,
                                40.72830430823495
                            ],
                            [
                                -73.82926864768417,
                                40.72824689816654
                            ],
                            [
                                -73.8291569664331,
                                40.72830693730759
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8ab11c5a-19bd-4eba-a3f9-d2d9bff4b44f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.82672208465213,
                                40.68584563566583
                            ],
                            [
                                -73.82510124180858,
                                40.68611653418722
                            ],
                            [
                                -73.8247993297197,
                                40.68608545508763
                            ],
                            [
                                -73.82568300515963,
                                40.68539869490758
                            ],
                            [
                                -73.82672208465213,
                                40.68584563566583
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6de0c3f6-b52c-4fa4-862e-173f3af73f8d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.82633695892352,
                                40.760445404792534
                            ],
                            [
                                -73.82690630037081,
                                40.75906132232879
                            ],
                            [
                                -73.83470801589979,
                                40.75579299123919
                            ],
                            [
                                -73.83162245291749,
                                40.762405313731335
                            ],
                            [
                                -73.82633695892352,
                                40.760445404792534
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "c13c51df-6138-451d-a74d-a88d9963117d"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.82199292450014,
                                40.676473337242754
                            ],
                            [
                                -73.82175701882169,
                                40.67644093331734
                            ],
                            [
                                -73.8213873863368,
                                40.676374986991775
                            ],
                            [
                                -73.82193046374012,
                                40.6763154671698
                            ],
                            [
                                -73.82199292450014,
                                40.676473337242754
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "86bf1d1e-6459-402d-8f9f-646d208f0b99"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.81721361320245,
                                40.612571040615286
                            ],
                            [
                                -73.8160798717859,
                                40.6084770846794
                            ],
                            [
                                -73.81632392623665,
                                40.608627038566155
                            ],
                            [
                                -73.8163952057578,
                                40.608693020489255
                            ],
                            [
                                -73.8164216180238,
                                40.60867781582556
                            ],
                            [
                                -73.81632407445866,
                                40.60862696150276
                            ],
                            [
                                -73.81605854054553,
                                40.607931896487834
                            ],
                            [
                                -73.81604003075908,
                                40.6079220984218
                            ],
                            [
                                -73.8159860206417,
                                40.60813818689963
                            ],
                            [
                                -73.81575001685613,
                                40.60728597396575
                            ],
                            [
                                -73.81635672103272,
                                40.60705966725312
                            ],
                            [
                                -73.81628941026848,
                                40.6065617041289
                            ],
                            [
                                -73.81627611340565,
                                40.60587058213003
                            ],
                            [
                                -73.81652285276641,
                                40.60556685796273
                            ],
                            [
                                -73.81628956118544,
                                40.60656164956906
                            ],
                            [
                                -73.81822305996869,
                                40.60614004931092
                            ],
                            [
                                -73.81962284179387,
                                40.60716458497139
                            ],
                            [
                                -73.81721361320245,
                                40.612571040615286
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "38255b09-c815-4e6e-a90b-cc7d8886b0cd"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8160798717859,
                                40.6084770846794
                            ],
                            [
                                -73.8159860206417,
                                40.60813818689963
                            ],
                            [
                                -73.81604003075908,
                                40.6079220984218
                            ],
                            [
                                -73.81605854054553,
                                40.607931896487834
                            ],
                            [
                                -73.81632407445866,
                                40.60862696150276
                            ],
                            [
                                -73.8164216180238,
                                40.60867781582556
                            ],
                            [
                                -73.8163952057578,
                                40.608693020489255
                            ],
                            [
                                -73.81632392623665,
                                40.608627038566155
                            ],
                            [
                                -73.8160798717859,
                                40.6084770846794
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "5359e0a4-d8af-487e-a4fb-6288f1d9761a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.8159860206417,
                                40.60813818689963
                            ],
                            [
                                -73.8160798717859,
                                40.6084770846794
                            ],
                            [
                                -73.8159250859286,
                                40.60838197991549
                            ],
                            [
                                -73.8159860206417,
                                40.60813818689963
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "8b635e6a-ad25-40bb-9056-5f4768069d4f"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.81544657313968,
                                40.76393741623565
                            ],
                            [
                                -73.81431523756581,
                                40.76149988688405
                            ],
                            [
                                -73.81628241239243,
                                40.76205845857073
                            ],
                            [
                                -73.81544657313968,
                                40.76393741623565
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9e974c94-54e9-4735-9460-d7dfd89a3a53"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.80259340618927,
                                40.76159177679833
                            ],
                            [
                                -73.80243368124017,
                                40.76186844326108
                            ],
                            [
                                -73.80180525490171,
                                40.76205094617696
                            ],
                            [
                                -73.80139735598233,
                                40.761866963367744
                            ],
                            [
                                -73.80208789812775,
                                40.761172070536084
                            ],
                            [
                                -73.80259340618927,
                                40.76159177679833
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "9b4c887a-8f5a-436f-af6e-c3dd31f22ffa"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.79597024348213,
                                40.591093428746255
                            ],
                            [
                                -73.79692555687102,
                                40.59097304722412
                            ],
                            [
                                -73.7979299110879,
                                40.590841011491115
                            ],
                            [
                                -73.79610231110026,
                                40.59116802449741
                            ],
                            [
                                -73.79597024348213,
                                40.591093428746255
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "84d9b0ff-8dc9-42cd-9581-e1a03d9b5c65"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.79434201738526,
                                40.7114080019779
                            ],
                            [
                                -73.793011636704,
                                40.70900376258495
                            ],
                            [
                                -73.7954057843784,
                                40.70982411967077
                            ],
                            [
                                -73.79434201738526,
                                40.7114080019779
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "a304b4f0-ce2d-4640-8575-8186abf73ec6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.78711949872056,
                                40.71106380473984
                            ],
                            [
                                -73.77993735663293,
                                40.7134651092669
                            ],
                            [
                                -73.78177840350207,
                                40.70895058160573
                            ],
                            [
                                -73.78711949872056,
                                40.71106380473984
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "fb24c0bd-e09d-4362-a06e-5a39adcc8d48"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.78373077139786,
                                40.7126700650844
                            ],
                            [
                                -73.78381828078126,
                                40.71264889682329
                            ],
                            [
                                -73.78396628181758,
                                40.71261330610302
                            ],
                            [
                                -73.78373077139786,
                                40.7126700650844
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "11e02418-a960-4de4-8923-dfc023bda885"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.77772799241245,
                                40.70979075821172
                            ],
                            [
                                -73.77700619154194,
                                40.708104214112495
                            ],
                            [
                                -73.77695891415499,
                                40.70773601952102
                            ],
                            [
                                -73.7768936004738,
                                40.70784113628957
                            ],
                            [
                                -73.77588725995365,
                                40.7054897430793
                            ],
                            [
                                -73.77892862312902,
                                40.70862094070984
                            ],
                            [
                                -73.77772799241245,
                                40.70979075821172
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "f92f6e5b-8858-4572-9f87-5b89a487996a"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.77700619154194,
                                40.708104214112495
                            ],
                            [
                                -73.7768936004738,
                                40.70784113628957
                            ],
                            [
                                -73.77695891415499,
                                40.70773601952102
                            ],
                            [
                                -73.77700619154194,
                                40.708104214112495
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "4f70c3d5-006a-491e-99be-dbeb35d84b76"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.7768936004738,
                                40.70784113628957
                            ],
                            [
                                -73.77700619154194,
                                40.708104214112495
                            ],
                            [
                                -73.77702508405882,
                                40.70825134835532
                            ],
                            [
                                -73.77686751416809,
                                40.707883119961444
                            ],
                            [
                                -73.7768936004738,
                                40.70784113628957
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "caa60ad2-c637-4a47-a958-f62848cd57d0"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.76776156881408,
                                40.71021339265696
                            ],
                            [
                                -73.76595430039625,
                                40.71062184190601
                            ],
                            [
                                -73.76675302934629,
                                40.71018206467846
                            ],
                            [
                                -73.76776156881408,
                                40.71021339265696
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "25a2ac68-6473-4181-b1d2-05f44d9c02c7"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.76605138312563,
                                40.69208079706906
                            ],
                            [
                                -73.76456812355606,
                                40.69039870180582
                            ],
                            [
                                -73.76507983807788,
                                40.69028733843165
                            ],
                            [
                                -73.76605138312563,
                                40.69208079706906
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "6f5a165a-a530-4303-b5c1-03c0615f54b6"
                }
            },
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
                                -73.76385649974753,
                                40.68834961930502
                            ],
                            [
                                -73.76773815871779,
                                40.693342654983084
                            ],
                            [
                                -73.76504829822825,
                                40.69380943760072
                            ],
                            [
                                -73.76385649974753,
                                40.68834961930502
                            ]
                        ],
                        [
                            [
                                -73.76507983807788,
                                40.69028733843165
                            ],
                            [
                                -73.76456812355606,
                                40.69039870180582
                            ],
                            [
                                -73.76605138312563,
                                40.69208079706906
                            ],
                            [
                                -73.76507983807788,
                                40.69028733843165
                            ]
                        ]
                    ]
                },
                "properties": {
                    "buckets_per_place_group": {
                        "group_1": {
                            "group_id": "group_1",
                            "place_id": null,
                            "bucket_in_s": 3000
                        },
                        "group_2": {
                            "group_id": "group_2",
                            "place_id": null,
                            "bucket_in_s": 3000
                        }
                    },
                    "id": "95c494e7-6651-404f-9e05-ae88c9d6372a"
                }
            }
        ]
    },
    "places": {
        "place_1": {
            "id": "place_1",
            "name": "place1",
            "points": [
                [
                    -73.9964,
                    40.7057
                ]
            ],
            "group_id": "group_1"
        },
        "place_2": {
            "id": "place_2",
            "name": "place2",
            "points": [
                [
                    -73.9413739,
                    40.696881
                ],
                [
                    -73.945583,
                    40.708068
                ]
            ],
            "group_id": "group_2"
        }
    },
    "groups": {
        "group_1": {
            "id": "group_1",
            "max_bucket_in_s": 3000,
            "all_buckets_in_s": [
                750,
                1500,
                2250,
                3000
            ],
            "transportation_mode": "public_transportation"
        },
        "group_2": {
            "id": "group_2",
            "max_bucket_in_s": 3000,
            "all_buckets_in_s": [
                750,
                1500,
                2250,
                3000
            ],
            "transportation_mode": "public_transportation"
        }
    }
}