import type { GeoMasterName } from "../geomasters/geomaster"
import type { Nullable } from "./utils"

export type Coordinates = {
  longitude: number,
  latitude: number
}

export type GeographicPlace = {
  // Some APIs give places dedicated IDs, which can be can provide better when used in subsequent API calls
  // Since Geomasters can use a combination of APIs, its not guaranteed that the geoMasterProvidedId will 
  // be a direct API place ID. It may, for example, have extra information to indicate what API the ID is for.
  geoMasterProvidedId?: string
  name: string,
  longAddress?: string
  coords: Coordinates,
  timestampInMillis: number,
  //Added so we can track if the user has changed geomasters since this was last used
  geoMasterName: GeoMasterName
}

export type PartialGeographicPlace = {
  geoMasterName: GeoMasterName
  geoMasterProvidedId?: string
  name?: string,
  subtext?: string,
  distanceToUsersInMeters?: number
  coords?: Coordinates,
}

export enum TransportationMode {
  WALKING = "walking",
  BIKING = "biking",
  DRIVING = "driving",
  PUBLIC_TRANSPORTATION = "public_transportation"
}

export enum PublicTransportationMode {
  BUS = "bus",
  SUBWAY = "subway",
  TRAIN = "train",
  OTHER = "other"
}

export type Route = {
  origin: GeographicPlace,
  destination: GeographicPlace,
  route: Nullable<RouteDirections>
}

export type RouteDirections = {
  warnings?: string[]
  copyright?: string
  legs: RouteLeg[]
}

export type RouteLeg = {
  modeOfTransport: TransportationMode
  distanceInMeters?: number
  durationInMillis?: number
  transitDetails?: TransitDetails
}

export type TransitDetails = {
  line: TransitLine
  startStation: TransitStation
  endStation: TransitStation
  numberOfStops: number
}

export type TransitStation = {
  name: string
}

export type TransitLine = {
  shortName: string;
  icon: string;
  color: string;
  vehicle?: PublicTransportationMode
}