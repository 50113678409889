import type { Optional } from "types/utils";

const _arrayReplaceFirst = <T>(
  array: Array<T>,
  predicate: (element: T) => boolean,
  replacement: T,
  errorOnNotFound: boolean
): Array<T> => {
  const newArray = [...array];
  const targetIndex = newArray.findIndex(predicate);
  if (targetIndex === -1) {
    if (errorOnNotFound) throw new Error("Couldn't find element for arrayReplaceFirst");
  } else {
    newArray.splice(targetIndex, 1, replacement);
  }
  return newArray;
};

const _arrayRemoveFirst = <T>(
  array: Array<T>,
  predicate: (element: T) => boolean,
  errorOnNotFound: boolean
): Array<T> => {
  const newArray = [...array];
  const targetIndex = newArray.findIndex(predicate);
  if (targetIndex === -1) {
    if (errorOnNotFound) throw new Error("Couldn't find element for arrayRemoveFirst");
  } else {
    newArray.splice(targetIndex, 1);
  }
  return newArray;
};

export const arrayRemoveFirstLenient = <T>(array: Array<T>, predicate: (element: T) => boolean) =>
  _arrayRemoveFirst(array, predicate, false);

export const arrayRemoveFirstStrict = <T>(array: Array<T>, predicate: (element: T) => boolean) =>
  _arrayRemoveFirst(array, predicate, true);

export const arrayReplaceFirstLenient = <T>(
  array: Array<T>,
  predicate: (element: T) => boolean,
  replacement: T
) => _arrayReplaceFirst(array, predicate, replacement, false);

export const arrayReplaceFirstStrict = <T>(
  array: Array<T>,
  predicate: (element: T) => boolean,
  replacement: T
) => _arrayReplaceFirst(array, predicate, replacement, true);

export const coalesceEmptyArray = <T>(array: Optional<Array<T>>): Array<T> => {
  return array ?? [];
};

export const range = (n: number) => [...Array(n).keys()];
