import type { Coordinates } from "../types/geo";

export const metersToMiles = (distanceInMeters: number): number => {
  return distanceInMeters * 0.000621371
}


export const getUserCoordinates = async (successCallback: (coords: Coordinates) => void, errorCallback: () => void) => {
  const successCallbackInner: PositionCallback = (pos) => {
    successCallback({
      latitude: pos.coords.latitude,
      longitude: pos.coords.longitude
    })
  }

  if (navigator.geolocation) {
    const permissions = await navigator.permissions.query({ name: "geolocation" })
    if (permissions.state === "granted" || permissions.state === "prompt") {
      navigator.geolocation.getCurrentPosition(successCallbackInner, errorCallback);
    } else if (permissions.state === "denied") {
      errorCallback()
    }
  } else {
    errorCallback()
  }
}
